import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import WROStatusTag from "./WROStatusTag";
import { Spin, notification } from "antd";
import BackButton from "components/fulfillment/_common/BackButton";
import NavigationPrompt from "../../../../_common/NavigationPrompt";

// Libs
import WRO from "lib/fulfillment/WRO";
import moment from "moment";
import { get } from "lodash-es";

// Hooks
import { useHistory, useParams } from "react-router";
import { useWroDetails } from "hooks/api/fulfillment/wro";
import WRODetailsSection from "./WRODetailsSection";
import OrderItemsSummaryTable from "./OrderItemsSummaryTable";
import ShipmentCardList from "./ShipmentCardList";
import WRODetailHeader from "./WRODetailHeader";

const Detail = ({
  getWROShippingLabel,
  fetchingLabels,
  labelError,
  labelsError,
  getWROShippingLabels,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSingleTracking, setIsSingleTracking] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [shipmentsToBeUpdated, setShipmentsToBeUpdated] = useState({});
  const [newShipments, setNewShipments] = useState([]);
  const [shipmentsToBeDeleted, setShipmentsToBeDeleted] = useState([]);
  const [updatingWRO, setUpdatingWRO] = useState(false);
  const [newEstimatedArrivalDate, setNewEstimatedArrivalDate] = useState();
  const [newDestinationWarehouse, setNewDestinationWarehouse] = useState();
  const history = useHistory();
  const { wroDetails, fetchWroDetails, updateWroDetails } = useWroDetails();
  const params = useParams();
  const { order_id } = params;
  const { data: order } = wroDetails;

  const completeOrder = async (order) => {
    const completeIncomingShipments = order?.incoming_shipments?.map(
      (shipment) => ({
        id: shipment.id,
        status: "completed",
        completed_date: moment().format("D/M/YYYY"),
      })
    );
    if (!completeIncomingShipments) return;
    await updateWroDetails(order.id, {
      warehouse_receiving_order: {
        completed_date: moment().format("D/M/YYYY"),
        incoming_shipments: completeIncomingShipments,
      },
    });
  };

  const checkIfOrderIsReadyForCompletion = async (order) => {
    const orderIsReadyToBeCompleted =
      order?.incoming_shipments.filter(
        (shipment) => shipment.status === "arrived"
      ).length === order?.incoming_shipments.length;

    if (order?.status !== "completed" && orderIsReadyToBeCompleted) {
      await completeOrder(order);
    }
  };

  const fetchOrder = async () => {
    const order = await fetchWroDetails(order_id);
    await checkIfOrderIsReadyForCompletion(order);
  };

  useEffect(() => {
    fetchOrder();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsSingleTracking(isSingleTrackingNumber);
    if (order) setTrackingNumber(order.incoming_shipments[0].tracking_info);
    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    const errorMsg = [wroDetails.error, labelError, labelsError];
    errorMsg.forEach((error) => {
      if (error)
        notification.error({
          message: "Error",
          description: error,
        });
    });
  }, [wroDetails.error, labelError, labelsError]);

  useEffect(() => {
    if (newShipments.length === 0) return;
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [newShipments.length]);

  if (!order) {
    return (
      <div className="loader">
        <Spin tip="Loading WRO..." />
      </div>
    );
  }

  const uniqueTrackingNumbers = order
    ? order.incoming_shipments.reduce((acc, shipment) => {
        const { tracking_info } = shipment;
        acc.add(tracking_info);
        return acc;
      }, new Set())
    : false;
  const isSingleTrackingNumber =
    uniqueTrackingNumbers && uniqueTrackingNumbers.size === 1;

  const shipmentSize = order?.shipment_size;
  const isBox = WRO.isBox(shipmentSize);

  const updateNewShipment = (key, value, index) => {
    const updatedNewShipments = [...newShipments];
    if (key === "quantity") {
      updatedNewShipments[
        index
      ].incoming_shipments_product_packaging_levels_attributes[0].quantity = value;
    } else {
      updatedNewShipments[index] = {
        ...updatedNewShipments[index],
        [key]: value,
      };
    }
    setNewShipments(updatedNewShipments);
  };

  const removeNewShipment = (index) => {
    const updatedShipments = newShipments.filter((_, i) => i !== index);
    setNewShipments(updatedShipments);
  };

  const deleteShipment = (shipmentID) => {
    setShipmentsToBeDeleted([...shipmentsToBeDeleted, shipmentID]);
  };

  const getNewShipmentFromPackagingLevel = (
    packagingLevel,
    product,
    quantity,
    trackingInfo
  ) => {
    const { id, sku, name } = packagingLevel;
    const { scid } = product;
    return {
      incoming_shipments_product_packaging_levels_attributes: [
        {
          fulfillment_product_packaging_level_id: id,
          quantity,
        },
      ],
      parcel_type: order?.incoming_shipments[0].parcel_type,
      tracking_info: trackingNumber ? trackingNumber : trackingInfo,
      id,
      sku,
      scid,
      name,
    };
  };

  const handleSelectProduct = (packagingLevel, product, index) => {
    const updatedNewShipments = [...newShipments];
    const existingNewShipment = updatedNewShipments[index];
    const { tracking_info: trackingInfo } = existingNewShipment;
    const quantity =
      existingNewShipment
        .incoming_shipments_product_packaging_levels_attributes[0].quantity;
    updatedNewShipments[index] = getNewShipmentFromPackagingLevel(
      packagingLevel,
      product,
      quantity,
      trackingInfo
    );

    setNewShipments(updatedNewShipments);
  };

  const currentPackagingLevels = order.incoming_shipments.reduce(
    (acc, shipment) => {
      if (shipment.shipment_contents) {
        shipment.shipment_contents.forEach((shipmentContents) => {
          if (!acc[shipmentContents.product.id]) {
            acc[shipmentContents.product.id] =
              shipmentContents.product_packaging_level.id;
          }
        });
      }
      return acc;
    },
    {}
  );

  const getShipmentsToBeUpdated = () => {
    const acc = [];

    if (
      Object.keys(shipmentsToBeUpdated).length ||
      shipmentsToBeDeleted.length
    ) {
      shipmentsToBeDeleted.forEach((shipmentID) => {
        acc.push({
          id: shipmentID,
          _destroy: true,
        });
      });

      Object.values(shipmentsToBeUpdated).forEach((shipment) => {
        if (!shipmentsToBeDeleted.includes(shipment.id)) acc.push(shipment);
      });
    }

    if (
      isSingleTracking &&
      trackingNumber !== order.incoming_shipments[0].tracking_info
    ) {
      order.incoming_shipments.forEach((shipment) => {
        if (shipmentsToBeDeleted.includes(shipment.id)) return;
        const existingShipmentIndex = acc.findIndex(
          (i) => i.id === shipment.id
        );
        if (existingShipmentIndex > -1) {
          acc[existingShipmentIndex].tracking_info = trackingNumber;
        } else {
          acc.push({
            id: shipment.id,
            tracking_info: trackingNumber,
          });
        }
      });
    }

    return acc;
  };

  const handleUpdateWRO = async () => {
    const shipmentsToBeUpdated = getShipmentsToBeUpdated();
    const shipmentsToBeCreated = newShipments.map((shipment) => {
      delete shipment.id;
      if (isSingleTracking) shipment.tracking_info = trackingNumber;
      return shipment;
    });

    const body = {
      warehouse_receiving_order: {
        incoming_shipments: [...shipmentsToBeUpdated, ...shipmentsToBeCreated],
      },
    };

    if (
      newEstimatedArrivalDate &&
      moment(newEstimatedArrivalDate) !== moment(order?.arrival_date)
    ) {
      body.warehouse_receiving_order.arrival_date = moment.utc(
        newEstimatedArrivalDate
      );
    }

    if (
      newDestinationWarehouse &&
      newDestinationWarehouse !== order?.destination_warehouse
    ) {
      body.warehouse_receiving_order.destination_warehouse = newDestinationWarehouse;
    }

    await updateWroDetails(order.id, body);
    await fetchOrder();
    setIsEditing(false);
    setShipmentsToBeUpdated([]);
    setShipmentsToBeDeleted([]);
    setNewShipments([]);
    setNewEstimatedArrivalDate(null);
    setNewDestinationWarehouse(null);
  };

  const handleUpdateShipment = (key, value, shipmentID, shipmentContentID) => {
    const shipmentToBeUpdated = shipmentsToBeUpdated[shipmentID]
      ? shipmentsToBeUpdated[shipmentID]
      : { id: shipmentID };

    if (key === "quantity_of_packages") {
      shipmentToBeUpdated.incoming_shipments_product_packaging_levels_attributes = [
        {
          id: shipmentContentID,
          quantity: value,
        },
      ];
      setShipmentsToBeUpdated({
        ...shipmentsToBeUpdated,
        [shipmentID]: shipmentToBeUpdated,
      });
    }

    if (key === "tracking_info") {
      const shipment = order.incoming_shipments.find(
        (shipment) => shipment.id === shipmentID
      );
      const quantity = get(
        shipmentToBeUpdated,
        "incoming_shipments_product_packaging_levels_attributes[0].quantity"
      )
        ? shipmentToBeUpdated
            .incoming_shipments_product_packaging_levels_attributes[0].quantity
        : shipment.shipment_contents[0].quantity_of_packages;

      shipmentToBeUpdated.incoming_shipments_product_packaging_levels_attributes = [
        {
          id: shipmentContentID,
          quantity,
        },
      ];
      setShipmentsToBeUpdated({
        ...shipmentsToBeUpdated,
        [shipmentID]: {
          ...shipmentToBeUpdated,
          [key]: value,
        },
      });
    }
  };

  const markAllAsReceived = async () => {
    setUpdatingWRO(true);
    const awaitingIncomingShipments = order.incoming_shipments
      .filter((shipment) => shipment.status === "awaiting")
      .map((shipment) => ({
        ...shipment,
        status: "arrived",
        arrival_date: moment().format("D/M/YYYY"),
      }));
    const body = {
      warehouse_receiving_order: {
        arrival_date: moment().format("D/M/YYYY"),
        incoming_shipments: awaitingIncomingShipments,
      },
    };
    await updateWroDetails(order.id, body);
    await completeOrder(order);
    fetchOrder();
    setUpdatingWRO(false);
  };

  const handleAddShipment = () => {
    setNewShipments([
      ...newShipments,
      {
        incoming_shipments_product_packaging_levels_attributes: [
          {
            fulfillment_product_packaging_level_id: "",
            quantity: 0,
          },
        ],
        parcel_type: order?.incoming_shipments[0].parcel_type,
        tracking_info: isSingleTracking ? trackingNumber : "",
        id: "",
        sku: "",
        scid: "",
        name: "",
        created_at: moment().format("D/M/YYYY"),
      },
    ]);
  };

  const handleOnCancelEdit = () => {
    setIsEditing(false);
    setShipmentsToBeDeleted([]);
    setNewShipments([]);
    setNewEstimatedArrivalDate(moment(order?.arrival_date));
    setNewDestinationWarehouse(order?.destination_warehouse);
  };

  const handleOnClickEditWRO = () => {
    setIsEditing(true);
  };

  const handleOnChangeSingleTrackingNumber = (isSingleTracking) => {
    setIsSingleTracking(isSingleTracking);
    if (!isSingleTracking) setTrackingNumber("");
  };

  const handleOnChangeTrackingInfo = (trackingNumber) => {
    setTrackingNumber(trackingNumber);
  };

  const orderIsCompleted =
    order?.incoming_shipments.filter(
      (shipment) => shipment.status !== "completed"
    ).length === 0;

  return (
    <div className="page-container wro-detail">
      <BackButton
        label="Back to WRO Overview"
        onClick={() =>
          get(window, "opener.location.pathname")
            ? history.push(window.opener.location.pathname)
            : history.goBack()
        }
      />
      <NavigationPrompt
        when={isEditing}
        resetWhen={setIsEditing}
        content="You have unsaved changes made to the WRO which will be lost if you navigate away. Are you sure you want to discard the changes?"
      />
      <div className="wro-detail-content">
        <div className="wro-detail-header">
          <div className="header-section">
            <span className="wro-id">{order.wro_id.toUpperCase()}</span>
            <WROStatusTag status={order.status} />
          </div>
          <WRODetailHeader
            isEditing={isEditing}
            fetchingLabels={fetchingLabels}
            getWROShippingLabels={getWROShippingLabels}
            handleOnCancelEdit={handleOnCancelEdit}
            loading={wroDetails.loading}
            handleUpdateWRO={handleUpdateWRO}
            handleOnClickEditWRO={handleOnClickEditWRO}
            orderIsCompleted={orderIsCompleted}
          />
        </div>
        <div className="top">
          <WRODetailsSection
            order={order}
            fetchOrder={fetchOrder}
            isSingleTracking={isSingleTracking}
            isEditing={isEditing}
            newEstimatedArrivalDate={newEstimatedArrivalDate}
            setNewEstimatedArrivalDate={(date) => {
              setNewEstimatedArrivalDate(date);
            }}
            newDestinationWarehouse={newDestinationWarehouse}
            setNewDestinationWarehouse={setNewDestinationWarehouse}
          />
        </div>
        <div className="bottom">
          <OrderItemsSummaryTable
            order={order}
            newShipments={newShipments}
            shipmentsToBeUpdated={shipmentsToBeUpdated}
            shipmentsToBeDeleted={shipmentsToBeDeleted}
          />
          <ShipmentCardList
            order={order}
            isEditing={isEditing}
            isBox={isBox}
            handleAddShipment={handleAddShipment}
            markAllAsReceived={markAllAsReceived}
            updatingWRO={updatingWRO}
            shipmentsToBeDeleted={shipmentsToBeDeleted}
            fetchOrder={fetchOrder}
            getWROShippingLabel={getWROShippingLabel}
            deleteShipment={deleteShipment}
            handleUpdateShipment={handleUpdateShipment}
            newShipments={newShipments}
            removeNewShipment={removeNewShipment}
            updateNewShipment={updateNewShipment}
            handleSelectProduct={handleSelectProduct}
            currentPackagingLevels={currentPackagingLevels}
            handleOnChangeSingleTrackingNumber={
              handleOnChangeSingleTrackingNumber
            }
            isSingleTracking={isSingleTracking}
            handleOnChangeTrackingInfo={handleOnChangeTrackingInfo}
          />
        </div>
      </div>
    </div>
  );
};

Detail.propTypes = {
  labelError: PropTypes.string,
  fetchingLabels: PropTypes.bool.isRequired,
  labelsError: PropTypes.string,
  getWROShippingLabel: PropTypes.func.isRequired,
  getWROShippingLabels: PropTypes.func.isRequired,
};

export default Detail;
