import React, { useEffect } from "react";

// Components
import { Button, Modal, notification } from "antd";
import { IssueDetails } from "@secondcloset/web-components";

// hooks
import { useAppointmentDetails } from "../../../../../../hooks/api/regular/appointment";

// Interfaces
import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type ShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: ShipmentType;
  visible: boolean;
  setVisible: (VisibilityState: boolean) => unknown;
  onRefetch: () => unknown;
}

const IssueDetailsModal: React.FC<Props> = ({
  shipment,
  visible,
  setVisible,
  onRefetch,
}) => {
  const appointment = shipment?.shipping_method as ShippingMethod;

  const {
    appointmentDetails,
    updateAppointmentDetails,
  } = useAppointmentDetails();

  useEffect(() => {
    if (appointmentDetails.error) {
      notification.error({
        message: "Update not successful",
        description: appointmentDetails.error,
      });
    }
  }, [appointmentDetails.error]);

  const onClaimNumberSubmit = async (newClaimNumber: string) => {
    const body = {
      claim_number: newClaimNumber,
    };

    const result = await updateAppointmentDetails(appointment?.id, body);
    if (result) onRefetch();
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title="Issue Details"
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button key="done" onClick={() => setVisible(false)} type="primary">
          Done
        </Button>,
      ]}
    >
      <IssueDetails
        appointment={appointment}
        isAdmin
        isLoading={appointmentDetails.loading}
        onSubmitClaimNumber={onClaimNumberSubmit}
      />
    </Modal>
  );
};

export default IssueDetailsModal;
