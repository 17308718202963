import React, { ReactNode } from "react";
import { EditOutlined } from "@ant-design/icons";

interface Props {
  [key: string]: any;
  children: ReactNode;
}

const BlueEditButton: React.FC<Props> = (props) => {
  return (
    <div className="blue-edit-button" {...props}>
      {props.children} <EditOutlined />
    </div>
  );
};

export default BlueEditButton;
