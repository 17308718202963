import React from "react";
import PropTypes from "prop-types";

const InputOptions = ({ options }) =>
  options && Array.isArray(options)
    ? options.map((option, index) => (
        <option key={index} value={option}>
          {option.replace(/_/g, " ")}
        </option>
      ))
    : "";

InputOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default InputOptions;
