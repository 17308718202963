import { useState, useEffect } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import { isEmpty } from "lodash-es";

//REQUESTS FUNCTIONS
const fetchClosetIndex_ = (userID) => {
  const api = getAPIFromDomain();
  const url = `/users/${userID}/closets`;
  return api.get(url);
};

const fetchClosetDetails_ = (closetID) => {
  const api = getAPIFromDomain();
  const url = `/closets/${closetID}`;
  return api.get(url);
};

// CUSTOM HOOKS
const useClosetIndex = () => {
  const [closetIndex, setClosetIndex] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const fetchClosetIndex = (userID) => {
    setClosetIndex({ ...closetIndex, loading: true, error: null });
    fetchClosetIndex_(userID)
      .then((res) => {
        const data = res.data.closets;
        setClosetIndex({ data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setClosetIndex({ ...closetIndex, loading: false, error });
        return false;
      });
  };

  return { closetIndex, fetchClosetIndex };
};

const useClosetDetails = () => {
  const [closetDetails, setClosetDetails] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const fetchClosetDetails = (closetID) => {
    setClosetDetails({ ...closetDetails, loading: true, error: null });
    fetchClosetDetails_(closetID)
      .then((res) => {
        const data = res.data.closet;
        setClosetDetails({ data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setClosetDetails({ ...closetDetails, loading: false, error });
        return false;
      });
  };
  return { closetDetails, fetchClosetDetails };
};

const useClosetIndexWithDetails = (userID) => {
  const [closetIndexWithDetails, setClosetIndexWithDetails] = useState({
    loading: true,
    loadingSingleCloset: false,
    data: null,
    error: null,
  });

  const fetchClosetIndexWithDetails = () => {
    fetchClosetIndex_(userID)
      .then((res) => {
        const { closets } = res.data;
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          data: closets,
          loading: false,
          error: null,
        });
      })
      .catch((error) =>
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          data: null,
          loading: false,
          error,
        })
      );
  };

  useEffect(fetchClosetIndexWithDetails, []);

  const fetchClosetDetails = (closetID) => {
    const { data: closetIndex } = closetIndexWithDetails;
    if (isEmpty(closetIndex)) {
      return setClosetIndexWithDetails({
        ...closetIndexWithDetails,
        loadingSingleCloset: false,
        error: "there is nothing in closet",
      });
    }

    setClosetIndexWithDetails({
      ...closetIndexWithDetails,
      loadingSingleCloset: true,
      error: null,
    });

    fetchClosetDetails_(closetID)
      .then((res) => {
        const { closet } = res.data;
        const closetID = closet.id;
        const targetCloset = closetIndex.find((c) => c.id === closetID);
        targetCloset.details = closet;
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          data: closetIndex,
          loadingSingleCloset: false,
          error: null,
        });
      })
      .catch((error) => {
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          loadingSingleCloset: false,
          error,
        });
      });
  };

  const updateClosetDetails = (closetID, body) => {
    const { data: closetIndex } = closetIndexWithDetails;
    if (isEmpty(closetIndex)) {
      return setClosetIndexWithDetails({
        ...closetIndexWithDetails,
        loadingSingleCloset: false,
        error: "there is nothing in closet",
      });
    }

    setClosetIndexWithDetails({
      ...closetIndexWithDetails,
      loadingSingleCloset: true,
      error: null,
    });

    const url = `/closets/${closetID}`;
    const api = getAPIFromDomain();
    return api
      .put(url, { closet: body })
      .then((res) => {
        const { closet } = res.data;
        const closetID = closet.id;
        const targetClosetIndex = closetIndex.findIndex(
          (c) => c.id === closetID
        );
        const newClosetDetail = { ...closet };
        newClosetDetail.details = closet;
        closetIndex[targetClosetIndex] = newClosetDetail;
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          data: closetIndex,
          loadingSingleCloset: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setClosetIndexWithDetails({
          ...closetIndexWithDetails,
          loadingSingleCloset: false,
          error,
        });
        return false;
      });
  };

  return {
    closetIndexWithDetails,
    fetchClosetIndexWithDetails,
    fetchClosetDetails,
    updateClosetDetails,
  };
};

const useItemPlanCloset = () => {
  const [itemPlanCloset, setItemPlanCloset] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const fetchItemPlanCloset = (userID) => {
    setItemPlanCloset({ ...itemPlanCloset, loading: true, error: null });
    const api = getAPIFromDomain();
    const url = `/users/${userID}/inventory_items?page=1&per_page=1000&item_only=true`;
    api
      .get(url)
      .then((res) => {
        const data = res.data.inventory_items;
        setItemPlanCloset({ data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setItemPlanCloset({ ...itemPlanCloset, loading: false, error });
        return false;
      });
  };

  return { itemPlanCloset, fetchItemPlanCloset };
};
export {
  useClosetIndex,
  useClosetDetails,
  useClosetIndexWithDetails,
  useItemPlanCloset,
};
