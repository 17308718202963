import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import reduxStore from "./config/reduxStore";
import notifyNewVersion from "./lib/notifyNewVersion";
import sentryInit, { ErrorBoundary } from "./lib/sentry";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "react-query";
import { notification } from "antd";

import "./css/App.scss";
import "./index.css";
import "./css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./css/styles.css";
import "react-toastify/dist/ReactToastify.min.css";

// initializing sentry before react
sentryInit();

const handleError = (e: string | Error | unknown) =>
  notification.error({
    message: "Error",
    description: typeof e === "string" ? e : (e as any).message,
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: handleError,
    },
    mutations: {
      onError: handleError,
    },
  },
});

ReactDOM.render(
  <Provider store={reduxStore}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
            <App />
          </div>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register({
  onUpdate: notifyNewVersion,
});
