interface COLORS {
  YELLOW: "#ffd33d";
  BLUE: "#4a90e2";
  BLUE_LIGHT: "#e6f7ff";
  BLUE_EXTRA_LIGHT: "#f0f7ff";
  RED: "#ff3a52";
  ORANGE: "#f0ad4e";
  ORANGE_STRONG: "#f5a623";
  ORANGE_LIGHT: "#fff6e8";
  GREEN: "#37bc9b";
  GREEN_LIGHT: "#6fcf97";
  GREEN_ANTD: "#52c41a";
  GREEN_ANTD_LIGHT: "#f6ffed";
  WHITE: "#ffffff";
  WHITE_OFF: "#f4f6f8";
  BLACK: "#000000";
  CHARCOAL: "#404041";
  GREY_DARK: "#828282";
  GREY: "#9b9b9b";
  GREY_LIGHT: "#e9e9e9";
  GREY_ULTRA_LIGHT: "#bdbdbd";
  GREY_BACKGROUND: "#fbfbfb";
}

const colors: COLORS = {
  YELLOW: "#ffd33d",
  BLUE: "#4a90e2",
  BLUE_LIGHT: "#e6f7ff",
  BLUE_EXTRA_LIGHT: "#f0f7ff",
  RED: "#ff3a52",
  ORANGE: "#f0ad4e",
  ORANGE_STRONG: "#f5a623",
  ORANGE_LIGHT: "#fff6e8",
  GREEN: "#37bc9b",
  GREEN_LIGHT: "#6fcf97",
  GREEN_ANTD: "#52c41a",
  GREEN_ANTD_LIGHT: "#f6ffed",
  WHITE: "#ffffff",
  WHITE_OFF: "#f4f6f8",
  BLACK: "#000000",
  CHARCOAL: "#404041",
  GREY_DARK: "#828282",
  GREY: "#9b9b9b",
  GREY_LIGHT: "#e9e9e9",
  GREY_ULTRA_LIGHT: "#bdbdbd",
  GREY_BACKGROUND: "#fbfbfb",
};

export default colors;
