import React, { useState, useEffect } from "react";

// Components
import { Button } from "./styles";
import { notification } from "antd";
import { UploadPhotoModal } from "@secondcloset/web-components";

// Hooks
import { useWroDetails } from "hooks/api/fulfillment/wro";

// Helpers
import { objectToFormData } from "object-to-formdata";
import { Order } from "interfaces/warehouseReceivingOrders";

interface ImageToBeCreated {
  file: File;
}

interface ImageToBeDeleted {
  id: string;
  _destroy: boolean;
}

interface Props {
  wro: Order;
  onUploadSuccess: () => void;
}

const BillOfLading: React.FC<Props> = ({ wro, onUploadSuccess }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<
    {
      uid: string;
      name: string;
      status: string;
      url: string;
    }[]
  >([]);
  const { wroDetails, updateWroDetails } = useWroDetails();
  const { id: wroID, bill_of_lading_images } = wro;

  useEffect(() => {
    if (wroDetails.error) {
      notification.error({
        message: "Error",
        description: wroDetails.error,
      });
    }
  }, [wroDetails.error]);

  useEffect(() => {
    const defaultFileList = bill_of_lading_images.map(
      (image: any, index: number) => ({
        uid: image.id,
        name: `bill_of_lading_${index}`,
        status: "done",
        url: image.url,
      })
    );

    setDefaultFileList(defaultFileList);
  }, [bill_of_lading_images]);

  const handleOnSubmit = async (
    fileList: any[],
    imageIDsToRemove: string[]
  ) => {
    const imagesToBeUpdated: (ImageToBeCreated | ImageToBeDeleted)[] = [];
    fileList.forEach((file) => {
      imagesToBeUpdated.push({ file });
    });
    imageIDsToRemove.forEach((id) => {
      imagesToBeUpdated.push({
        id,
        _destroy: true,
      });
    });
    const body = {
      warehouse_receiving_order: {
        bill_of_lading_images_attributes: imagesToBeUpdated,
      },
    };
    const response = await updateWroDetails(wroID, objectToFormData(body));
    if (response) onUploadSuccess();
  };

  const hasExistingBOL = wro.bill_of_lading_images.length !== 0;

  return (
    <div>
      {modalVisible ? (
        <UploadPhotoModal
          visible={modalVisible}
          title="Upload Bill of Lading"
          handleOnSubmit={async (
            imagesToAdd: File[],
            imageIDsToRemove: string[]
          ) => {
            await handleOnSubmit(imagesToAdd, imageIDsToRemove);
            setModalVisible(false);
          }}
          defaultFileList={defaultFileList}
          onCancel={() => setModalVisible(false)}
          uploading={wroDetails.loading}
        />
      ) : null}
      <Button onClick={() => setModalVisible(true)}>
        {hasExistingBOL ? "View" : "Upload"}
      </Button>
    </div>
  );
};

export default BillOfLading;
