import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useOrderDetails = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const api = getAPIFromDomain();

  const fetchOrderDetails = (userID, orderID) => {
    const url = `/users/${userID}/orders/${orderID}`;
    setError(null);
    setLoading(true);
    return api
      .get(url)
      .then((res) => {
        const order = res.data;
        setData(order);
        setLoading(false);
        return order;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return null;
      });
  };

  const updateOrderDetails = (userID, orderID, body) => {
    const url = `/users/${userID}/orders/${orderID}`;
    setError(null);
    setLoading(true);
    return api
      .put(url, body)
      .then((res) => {
        const order = res.data;
        setData(order);
        setLoading(false);
        return order;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return null;
      });
  };

  const orderDetails = { data, loading, error };
  return { orderDetails, fetchOrderDetails, updateOrderDetails };
};

export { useOrderDetails };
