import React from "react";
import moment from "moment";
import { capitalize } from "lodash-es";
import { FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Alert, Tooltip } from "antd";
import PropTypes from "prop-types";

const STATUS_TO_TEXT_AND_COLOR_MAP = {
  open: { text: "Open", type: "primary" },
  paid: { text: "Paid", type: "primary", ghost: true },
  uncollectible: { text: "Uncollectible", type: "danger" },
  void: { text: "Void" },
};

const InvoiceDetails = ({
  invoice,
  customer,
  updateInvoice,
  loading,
  error,
}) => {
  if (!invoice)
    return <div className="text-center">Invoice does not exist</div>;
  const buildHeader = () => {
    const { date, status, number, description } = invoice;
    const { first_name, last_name } = customer;

    return (
      <div className="invoiceHeader">
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <b>Invoice#:</b> {number}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <b>Date:</b>{" "}
                  {date && moment.unix(date).format("MMMM Do, YYYY")}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <b>Customer:</b> {` ${first_name} ${last_name}`}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <b>Status: </b>
                  {capitalize(status)}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="memo-title">
                  <b>Memo: </b>
                  {description ? "" : "N/A"}
                </p>
                {description ? (
                  <div className="memo-body">
                    <p style={{ whiteSpace: "pre-line" }}>{description}</p>
                  </div>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const buildSingleButton = (status) => {
    const { text, ...rest } = STATUS_TO_TEXT_AND_COLOR_MAP[status];
    const body = { invoice: { status } };
    if (status === "paid") {
      body.invoice.paid_out_of_band = true;
    }
    const onClick = () => updateInvoice(customer.id, invoice.id, body);

    return (
      <Button {...rest} size="small" onClick={onClick}>
        {status !== "paid" ? text : "Mark as paid"}
      </Button>
    );
  };

  const buildChargeButton = () => {
    const body = { invoice: { status: "paid" } };
    const onClick = () => updateInvoice(customer.id, invoice.id, body);
    return (
      <Button
        className="charge-button"
        type="primary"
        onClick={onClick}
        style={{ marginLeft: "10px" }}
      >
        Charge Invoice
      </Button>
    );
  };

  const buildStatusChangeButtons = () => {
    switch (invoice.status) {
      case "draft":
        return (
          <div className="status-change-buttons flex-row">
            {loading ? <LoadingOutlined /> : buildSingleButton("open")}
          </div>
        );
      case "open":
        return (
          <div className="status-change-buttons flex-row">
            {loading ? (
              <LoadingOutlined />
            ) : (
              <>
                {buildSingleButton("paid")}
                {buildSingleButton("uncollectible")}
                {buildSingleButton("void")}
              </>
            )}
          </div>
        );
      case "paid":
      case "uncollectible":
      case "void":
      default:
        return;
    }
  };

  const buildCostBreakdown = () => {
    const { invoice_items, description } = invoice;
    const lineItems = invoice_items
      .filter((o) => o.description)
      .map((o, i) => {
        const { description: item_description, amount } = o;
        const useInvoiceDescription = item_description.match(/.Moving Fee$/);

        return (
          <tr key={i}>
            <td>
              <p>{useInvoiceDescription ? description : item_description}</p>
            </td>
            <td>
              <p>${parseFloat(amount / 100).toFixed(2)}</p>
            </td>
          </tr>
        );
      });

    return (
      <>
        <hr />
        <table className="lineItemsTable">
          <tbody>
            <tr>
              <th>
                <p>Description</p>
              </th>
              <th>
                <p>Cost</p>
              </th>
            </tr>
            {lineItems}
          </tbody>
        </table>
      </>
    );
  };

  const buildDiscounts = () => {
    const { percent_off, amount_off } = invoice.coupon;

    let discount = 0;

    if (percent_off) {
      discount = `${percent_off}% Off`;
    } else if (amount_off) {
      discount = `-$${parseFloat(amount_off / 100).toFixed(2)}`;
    }

    return (
      <>
        <hr />
        <table className="lineItemsTable">
          <tbody>
            <tr>
              <td>
                <p>
                  <b>Discount:</b>
                </p>
              </td>
              <td>
                <p>{discount}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const buildTotals = () => {
    const { total, subtotal, tax, coupon } = invoice;

    let displaySubtotal = subtotal;
    if (coupon) {
      const { percent_off, amount_off } = coupon;
      if (percent_off) {
        displaySubtotal = ((subtotal * (100 - percent_off)) / 100).toFixed(2);
      } else if (amount_off) {
        displaySubtotal = (subtotal - amount_off).toFixed(2);
      }
    }

    return (
      <>
        <hr />
        <table className="totalsTable">
          <tbody>
            <tr>
              <td />
              <td>
                <p>Subtotal:</p>
              </td>
              <td>
                <p>${parseFloat(displaySubtotal / 100).toFixed(2)}</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>Taxes:</p>
              </td>
              <td>
                <p>${parseFloat(tax / 100).toFixed(2)}</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <p>
                  <b>Total:</b>
                </p>
              </td>
              <td>
                <p>
                  <b>${parseFloat(total / 100).toFixed(2)}</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const buildDownloadButton = () => {
    const { invoice_pdf } = invoice;

    if (invoice_pdf) {
      return (
        <Tooltip title="download invoice pdf">
          <Button
            href={invoice_pdf}
            target="_blank"
            icon={<FilePdfOutlined />}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="no associated pdf">
        <Button
          href={invoice_pdf}
          target="_blank"
          icon={<FilePdfOutlined />}
          disabled={true}
        />
      </Tooltip>
    );
  };

  return (
    <div className="singleInvoice">
      {error && <Alert type="error" message={error} />}
      {buildHeader()}
      {buildStatusChangeButtons()}
      {buildCostBreakdown()}
      {invoice.coupon && buildDiscounts()}
      {buildTotals()}
      <div className="button-row">
        {buildDownloadButton()}
        {invoice.status === "open" && buildChargeButton()}
      </div>
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  customer: PropTypes.object.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default InvoiceDetails;
