import * as actionTypes from "../../../actions/fulfillment/types";

const INITIAL_STATE = {
  fetching: false,
  data: null,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_LABEL_REQUESTED,
    WRO_LABEL_SUCCESS,
    WRO_LABEL_ERRORED,
  } = actionTypes;

  switch (action.type) {
    case WRO_LABEL_REQUESTED:
      return { ...state, fetching: true };
    case WRO_LABEL_SUCCESS:
      return { ...state, fetching: false, data: action.data, error: null };
    case WRO_LABEL_ERRORED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
