import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface Props {
  data: any[];
  page: number;
  setPage: (p: number) => any;
  pageSize: number;
}

const TablePagination: React.FC<Props> = ({
  data,
  page,
  setPage,
  pageSize,
}) => {
  return (
    <div className="table-pagination-control">
      <div className="control-buttons">
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            if (page === 1) return;
            setPage(page - 1);
          }}
          disabled={page === 1}
        />
        <span>{page}</span>
        <Button
          icon={<RightOutlined />}
          onClick={() => setPage(page + 1)}
          disabled={!data || data.length < pageSize}
        />
      </div>
    </div>
  );
};

export default TablePagination;
