import React from "react";
import PropTypes from "prop-types";
import { startCase, toLower, isEmpty } from "lodash-es";
import { Empty, Button, Spin, Input } from "antd";
import ErrorBox from "../../../../components/fulfillment/_common/ErrorBox";

const ReturnSummaryCard = (props) => {
  const getItemDisplayName = (item) => {
    const { stripe_plan_id, category_item } = item;
    const categoryName = category_item ? category_item.name : null;
    let displayName = categoryName || stripe_plan_id;
    displayName = startCase(toLower(displayName.replace(/_/g, " ")));
    return displayName;
  };

  const buildTable = () => {
    const groupedItems = props.returnCart.reduce((acc, cv) => {
      let displayName = getItemDisplayName(cv);
      if (!acc[displayName]) acc[displayName] = [cv];
      else acc[displayName].push(cv);
      return acc;
    }, {});

    const rows = Object.keys(groupedItems).map((displayName) => {
      const targetItems = groupedItems[displayName];
      return (
        <div className="table-row" key={displayName}>
          <div>{displayName}</div>
          <div>{targetItems.length}</div>
        </div>
      );
    });

    const itemCount = props.returnCart.length;

    return (
      <div className="return-summary-table">
        <div className="table-header">
          <div>Name</div>
          <div>Quantity</div>
        </div>
        {rows}

        <div className="table-footer">
          <p>{`${itemCount} ${itemCount === 1 ? "Item" : "Items"}`}</p>
        </div>
      </div>
    );
  };

  const buildNotesInput = () => {
    const { TextArea } = Input;
    const { onNotesChange, notes } = props;

    return (
      <div className="notes-input">
        <TextArea
          value={notes}
          onChange={(e) => onNotesChange(e.target.value)}
          placeholder="notes"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </div>
    );
  };

  const buildReturnFee = () => {
    const {
      loadingReturnOrder,
      hasReturnFee,
      returnFee,
      errors,
      onSubmit,
      onPreview,
    } = props;

    let content;
    if (hasReturnFee) {
      if (returnFee) {
        content = <div>${(returnFee / 100).toFixed(2)} + taxes</div>;
      } else {
        content = (
          <button className="return-fee-reveal" onClick={onPreview}>
            Reveal
          </button>
        );
      }
    } else {
      content = <p className="no-return-fee">no return fees</p>;
    }

    return (
      <div className="return-fee">
        <ErrorBox error={errors} key={errors} />
        <br />
        {loadingReturnOrder ? (
          <Spin tip="Loading..." />
        ) : (
          <>
            <div className="return-fee-title">Return Fee:</div>
            <div className="return-fee-content">{content}</div>
            {buildNotesInput()}
            <div className="return-buttons">
              <Button onClick={onSubmit} type="primary">
                Create Return Order
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="return-summary-card">
      <div className="title">Return Summary</div>
      {isEmpty(props.returnCart) ? (
        <div className="empty-row">
          <Empty description="No Items" />
        </div>
      ) : (
        <>
          {buildTable()}
          {buildReturnFee()}
        </>
      )}
    </div>
  );
};

ReturnSummaryCard.propTypes = {
  errors: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  returnCart: PropTypes.array.isRequired,
  notes: PropTypes.string.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  hasReturnFee: PropTypes.bool.isRequired,
  returnFee: PropTypes.number,
  loadingReturnOrder: PropTypes.bool.isRequired,
};

export default ReturnSummaryCard;
