import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// api
import { fetchCustomerIndex } from "../../../api/storage/customer";

// hooks
import { useQuery } from "react-query";
import useURLStateV2 from "../../../hooks/application/useURLStateV2";

// assets
import { UserAddOutlined } from "@ant-design/icons";

// components
import { Table, Alert, Tag, Button, Switch, Select } from "antd";
import TablePagination from "../../../components/_common/TablePagination";
import { SearchInput } from "@secondcloset/web-components";

// lib
import User from "../../../lib/regular/user";

const PAGE_SIZE = 15;

const CustomerIndexPage = (props) => {
  const [urlState, setUrlState] = useURLStateV2({
    search: "",
    page: 1,
  });

  const updateUrl = (key, value) => {
    setUrlState((state) => {
      const updatedState = { ...state };
      updatedState[key] = value;
      return updatedState;
    });
  };

  const getUrlValue = (option) => urlState[option];

  const queryOptions = useMemo(() => {
    const query = {
      field: "created_at",
      direction: "desc",
      per_page: PAGE_SIZE,
      page: urlState.page,
      ...(urlState.search && { q: String(urlState.search).trim() }),
      ...(urlState.role &&
        urlState.role !== "all" && { role: String(urlState.role) }),
      ...(urlState.subrole &&
        urlState.subrole !== "all" && { subrole: String(urlState.subrole) }),
      ...(urlState.delinquent && { delinquent: String(urlState.delinquent) }),
    };
    return query;
  }, [urlState]);

  const customerIndex = useQuery(
    ["customerIndex", queryOptions],
    () => fetchCustomerIndex(queryOptions),
    { keepPreviousData: true }
  );

  useEffect(() => {
    // reset to page 1 every time search changes
    setUrlState((state) => ({ ...state, page: 1 }));
    // eslint-disable-next-line
  }, [urlState.search, urlState.isDelinquent, urlState.role, urlState.subrole]);

  const buildSearchBar = () => {
    return (
      <SearchInput
        placeholder="Search for a Customer.."
        defaultValue={urlState.search}
        onSearch={(search) => setUrlState((state) => ({ ...state, search }))}
        onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
        style={{ margin: "10px 0" }}
      />
    );
  };

  const getRoleValue = (key, roles) => {
    const foundRole = roles.find((r) => r.value === getUrlValue(key));
    return foundRole?.name || "all";
  };

  const renderRoleSelect = () => {
    const roles = [{ name: "All", value: "all" }, ...User.getRoles()];
    return (
      <div className="role-selector">
        <div className="label">Role: </div>
        <Select
          value={getRoleValue("role", roles)}
          onChange={(value) => updateUrl("role", value)}
          size="small"
          style={{ width: 150 }}
        >
          {roles.map((role) => (
            <Select.Option value={role.value} key={role.value}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderSubroleSelect = () => {
    const subroles = [{ name: "All", value: "all" }, ...User.getSubroles()];
    return (
      <div className="role-selector">
        <div className="label">Subrole: </div>
        <Select
          value={getRoleValue("subrole", subroles)}
          onChange={(value) => updateUrl("subrole", value)}
          size="small"
          style={{ width: 180 }}
        >
          {subroles.map((subrole) => (
            <Select.Option value={subrole.value} key={subrole.value}>
              {subrole.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className="user-filters">
        <div className="delinquent-button">
          <div className="label">Delinquent Only: </div>
          <Switch
            checked={getUrlValue("delinquent")}
            onChange={(value) => updateUrl("delinquent", value)}
          />
        </div>
        {renderRoleSelect()}
        {renderSubroleSelect()}
      </div>
    );
  };

  const buildColumns = () => {
    const renderEmail = (text, record) => {
      const path = `/customers/${record.id}`;
      return <Link to={path}>{text}</Link>;
    };

    const renderCustomerName = (_, record) => {
      const { first_name, last_name } = record;
      return `${first_name} ${last_name}`;
    };

    const renderInventoryLink = (_, record) => {
      const path = `/customers/${record.id}/inventory`;
      return <Link to={path}>Inventory</Link>;
    };

    const renderRole = (role) => <Tag>{User.getRoleName(role)}</Tag>;

    const renderPaymentIssue = (payment_issue) => {
      if (payment_issue) return <Tag color="red">Failed</Tag>;
      return <Tag color="green">Good</Tag>;
    };

    return [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: renderEmail,
        ellipsis: true,
        width: 250,
      },
      {
        title: "Name",
        dataIndex: "first_name",
        key: "first_name",
        render: renderCustomerName,
        ellipsis: true,
        width: 200,
      },
      { title: "User Code", dataIndex: "user_code", key: "user_code" },
      {
        title: "Inventory",
        dataIndex: "id",
        key: "id",
        render: renderInventoryLink,
      },
      { title: "Role", dataIndex: "role", key: "role", render: renderRole },
      {
        title: "Payment",
        dataIndex: "payment_issue",
        key: "payment_issue",
        render: renderPaymentIssue,
      },
      { title: "Orders", dataIndex: "orders", key: "orders" },
    ];
  };

  const buildTable = () => {
    return (
      <div className="customers-table">
        <Table
          loading={customerIndex.isLoading}
          columns={buildColumns()}
          dataSource={customerIndex.data || []}
          size="small"
          pagination={false}
          scroll={{ x: 800 }}
          rowKey={(record) => record.id}
        />
        <TablePagination
          data={customerIndex.data}
          page={getUrlValue("page")}
          setPage={(page) => updateUrl("page", page)}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  };

  return (
    <div className="page-container" id="customer-index-page">
      <Helmet title="Sierra - Appointments" />
      {customerIndex.error && (
        <Alert message={customerIndex.error} type="error" />
      )}
      <div className="customers">
        <div className="header">
          <h1>Customers</h1>
          <Button
            icon={<UserAddOutlined />}
            onClick={() => props.history.push("/customers/new")}
          >
            Create Customer
          </Button>
        </div>
        {renderFilters()}
        {buildSearchBar()}
        {buildTable()}
      </div>
    </div>
  );
};

CustomerIndexPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(CustomerIndexPage);
