import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Modal, TimePicker, InputNumber, Checkbox, Alert } from "antd";
import moment from "moment";

const StartEndTimeModal = (props) => {
  const [numberOfMovers, setNumberOfMovers] = useState(1);
  const [skipMovingFee, setSkipMovingFee] = useState(false);
  const [startTime, setStartTime] = useState(
    props.appointment.start_time ? moment(props.appointment.start_time) : null
  );
  const [endTime, setEndTime] = useState(null);
  const [statusChangeError, setStatusChangeError] = useState(null);
  const [isConfirmOverwrite, setIsConfirmOverwrite] = useState(false);

  const onStartEndTimeChange = () => {
    setStatusChangeError(null);
  };

  useEffect(onStartEndTimeChange, [startTime, endTime]);

  const { start_time } = props.appointment;
  const originalStartTime = start_time && moment(start_time);

  const buildErrorBox = () => {
    const overwriteMessage = (
      <div>
        The new start time below will <b>overwrite</b> the original time
      </div>
    );

    const skipFeeMessage = (
      <div>
        Skip fee will set both start time and end time to now, this{" "}
        <b>will NOT</b> create an invoice
      </div>
    );

    const message =
      statusChangeError ||
      (skipMovingFee && skipFeeMessage) ||
      (isConfirmOverwrite && overwriteMessage);

    if (!message) return null;
    return (
      <div className="message-box">
        <Alert banner type="info" message={message} />
      </div>
    );
  };

  const buildStartTimePicker = () => {
    const showPicker = !originalStartTime || isConfirmOverwrite || startTime;
    return (
      <div className="time-picker">
        <div className="label">Start time:</div>
        {showPicker ? (
          <TimePicker
            disabled={skipMovingFee}
            value={startTime}
            format={"HH:mm"}
            onChange={(time) => {
              setStartTime(time);
              setEndTime(null);
            }}
          />
        ) : (
          <div>{moment(originalStartTime).format("HH:mm")}</div>
        )}
      </div>
    );
  };

  const buildEndTimePicker = () => {
    const time = startTime || originalStartTime;
    const disabledHours = () => {
      if (!time) return null;
      const hours = Array.apply(null, { length: 24 }).map(Number.call, Number);
      return hours.filter((h) => h < +time.format("HH"));
    };

    const disabledMinutes = (currentHour) => {
      if (!time) return null;
      const startHour = +time.format("HH");
      const startMin = +time.format("mm");
      if (startHour !== currentHour) return null;
      const mins = Array.apply(null, { length: 60 }).map(Number.call, Number);
      return mins.filter((m) => m <= startMin);
    };

    return (
      <div className="time-picker">
        <div className="label">End time:</div>
        <TimePicker
          disabled={skipMovingFee}
          value={endTime}
          format={"HH:mm"}
          onChange={(t) => setEndTime(t)}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
        />
      </div>
    );
  };

  const buildTotalMinus = () => {
    const starting = startTime || originalStartTime;
    const hide = !starting || !endTime;
    let numOfMins;

    try {
      const endHours = +endTime.format("HH");
      const endMinutes = +endTime.format("mm");
      const startHours = +starting.format("HH");
      const startMinutes = +starting.format("mm");
      const hours = endHours - startHours;
      const minutes = endMinutes - startMinutes;
      numOfMins = hours * 60 + minutes;
    } catch (_) {
      numOfMins = 0;
    }

    return (
      <div className={`num-of-mins ${hide ? "" : "expanded"}`}>
        <ClockCircleOutlined className="clock-icon" />
        {numOfMins} minutes
      </div>
    );
  };

  const validateAndSubmit = () => {
    const shouldEnterStartTime =
      !skipMovingFee && (isConfirmOverwrite || !originalStartTime);
    if (shouldEnterStartTime && !startTime)
      return setStatusChangeError("please select start time");
    if (!endTime) return setStatusChangeError("please select end time");
    const start_time = startTime || moment();
    const endTimeHour = endTime.get("hour");
    const endTimeMin = endTime.get("minute");
    const end_time = moment(start_time).set({
      hour: endTimeHour,
      minute: endTimeMin,
    });

    const getTimeString = (time) => time.format();
    const options = {
      start_time: getTimeString(start_time),
      end_time: getTimeString(end_time),
      num_movers: numberOfMovers,
    };
    if (skipMovingFee) options.waive_moving_fee = true;
    props.onSubmit(options);
  };

  const buildCustomAppointmentReminder = () => {
    if (props.appointment.job_type === "custom") {
      return (
        <div className="custom-appointment-alert-message">
          <Alert
            message="A custom appointment does not get billed automatically for moving fee. Please remember to invoice the client manually"
            banner
          />
        </div>
      );
    }
  };

  const buildSkipMovingFee = () => {
    if (props.appointment.job_type === "custom") return null;
    return (
      <div className="skip-moving-fee">
        <Checkbox
          checked={skipMovingFee}
          onChange={(e) => {
            const check = e.target.checked;
            setSkipMovingFee(check);
            if (check) {
              setEndTime(moment());
              setStartTime(moment());
              setIsConfirmOverwrite(false);
            } else {
              setEndTime(null);
              setStartTime(null);
            }
          }}
        >
          Skip Moving fee?
        </Checkbox>
      </div>
    );
  };

  if (!props.appointment) return null;

  return (
    <Modal
      title="Update Appointment Status"
      {...props.modalProps}
      onOk={validateAndSubmit}
    >
      <div className="start-end-time-modal">
        {buildErrorBox()}
        <div className="start-end-time">
          <div className="time">
            {originalStartTime && (
              <Checkbox
                checked={isConfirmOverwrite}
                onChange={(e) => {
                  setIsConfirmOverwrite(e.target.checked);
                  setStartTime(null);
                  setEndTime(null);
                }}
                disabled={skipMovingFee}
              >
                Overwrite start time
              </Checkbox>
            )}
            {buildStartTimePicker()}
            {buildEndTimePicker()}
          </div>
          {buildTotalMinus()}
          <div className="num-of-movers">
            <div className="label"># Movers:</div>
            <InputNumber
              value={numberOfMovers}
              onChange={(v) => setNumberOfMovers(v)}
              min={1}
              disabled={skipMovingFee}
            />
          </div>
          {buildSkipMovingFee()}
        </div>
        {buildCustomAppointmentReminder()}
      </div>
    </Modal>
  );
};

StartEndTimeModal.propTypes = {
  modalProps: PropTypes.object,
  appointment: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StartEndTimeModal;
