import React, { FC } from "react";
// Components
import { Button, Checkbox, Input } from "antd";
import { PlusOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import IncomingShipmentCard from "../IncomingShipmentCard";
import NewIncomingShipmentCard from "../NewIncomingShipmentCard";

// Interfaces
import { Order, IncomingShipment } from "interfaces/warehouseReceivingOrders";
import { APIResponse } from "@secondcloset/api-utils";
import { PackagingLevel, Product } from "interfaces/products";

export interface NewShipment {
  incoming_shipments_product_packaging_levels_attributes: [
    {
      fulfillment_product_packaging_level_id: string;
      quantity: number;
    }
  ];
  fulfillment_product_packaging_level_id: string;
  parcel_type: IncomingShipment["parcel_type"];
  tracking_info: IncomingShipment["tracking_info"];
  id: string;
  sku: string;
  scid: string;
  name: string;
}

interface Props {
  order: Order;
  isEditing: boolean;
  isBox: boolean;
  handleAddShipment: () => void;
  markAllAsReceived: () => void;
  updatingWRO: boolean;
  shipmentsToBeDeleted: string[];
  fetchOrder: () => void;
  getWROShippingLabel: (packageID: string) => Promise<APIResponse<any>>;
  deleteShipment: (shipmentID: string) => void;
  shipmentsToBeUpdated: {
    [key: string]: {
      [key: string]: any;
      shipment_contents?: { quantity_of_packages?: number };
    };
  };
  handleUpdateShipment: (
    key: string,
    value: string | number,
    shipmentID: string,
    shipmentContentID?: string
  ) => void;
  newShipments: NewShipment[];
  removeNewShipment: (index: number) => void;
  updateNewShipment: (key: string, value: string, index: number) => void;
  handleSelectProduct: (
    packagingLevel: PackagingLevel,
    product: Product,
    index: number
  ) => void;
  currentPackagingLevels: { [key: string]: string };
  handleOnChangeSingleTrackingNumber: (isSingleTrackingNumber: boolean) => void;
  isSingleTracking: boolean;
  handleOnChangeTrackingInfo: (trackingNumber: string) => void;
}

const ShipmentCardList: FC<Props> = ({
  order,
  isEditing,
  isBox,
  handleAddShipment,
  markAllAsReceived,
  updatingWRO,
  shipmentsToBeDeleted,
  fetchOrder,
  getWROShippingLabel,
  deleteShipment,
  handleUpdateShipment,
  newShipments,
  removeNewShipment,
  updateNewShipment,
  handleSelectProduct,
  currentPackagingLevels,
  handleOnChangeSingleTrackingNumber,
  isSingleTracking,
  handleOnChangeTrackingInfo,
}) => {
  const allShipmentsReceived =
    order.incoming_shipments.filter(
      (shipment) => shipment.status === "awaiting"
    ).length === 0;

  const isRemovable =
    order.incoming_shipments.length - shipmentsToBeDeleted.length > 1;

  const renderShipmentCards = () => {
    const incomingShipmentCards = order.incoming_shipments.map(
      (shipment, index) => {
        if (shipmentsToBeDeleted.includes(shipment.id)) return null;
        return (
          <IncomingShipmentCard
            key={shipment.id}
            shipment={shipment}
            index={index}
            getWROShippingLabel={getWROShippingLabel}
            isRemovable={isRemovable}
            isEditing={isEditing}
            fetchOrder={fetchOrder}
            handleDeleteShipment={(shipmentID) => {
              deleteShipment(shipmentID);
            }}
            handleUpdateShipment={(key, value, shipmentContentID) => {
              handleUpdateShipment(key, value, shipment.id, shipmentContentID);
            }}
            isSingleTracking={isSingleTracking}
          />
        );
      }
    );

    const newShipmentCards = newShipments.map((shipment, index) => (
      <NewIncomingShipmentCard
        key={`new shipment ${index}`}
        shipment={shipment}
        index={order.incoming_shipments.length + index}
        updateNewShipment={(key, value) => updateNewShipment(key, value, index)}
        removeNewShipment={() => {
          removeNewShipment(index);
        }}
        orderStatus={order.status}
        organizationID={order.organization_id}
        handleSelectProduct={(packagingLevel, product) =>
          handleSelectProduct(packagingLevel, product, index)
        }
        currentPackagingLevels={currentPackagingLevels}
        isSingleTracking={isSingleTracking}
      />
    ));

    return [...incomingShipmentCards, ...newShipmentCards];
  };

  return (
    <div className="order-items">
      <div className="package-items-header">
        <div className="header-section">
          <div className="flex-col">
            <label>{`${isBox ? "Box" : "Pallet"} Details`}</label>
          </div>
          <div className="checkbox-wrapper">
            <Checkbox
              disabled={!isEditing}
              onChange={(e) =>
                handleOnChangeSingleTrackingNumber(e.target.checked)
              }
              checked={isSingleTracking}
            >
              {`All ${
                isBox ? "boxes" : "pallets"
              } have the same tracking number`}
            </Checkbox>
          </div>
          {isSingleTracking ? (
            <div>
              <Input
                disabled={!isEditing}
                defaultValue={order.incoming_shipments[0].tracking_info}
                onChange={(e) => handleOnChangeTrackingInfo(e.target.value)}
              />
            </div>
          ) : null}
        </div>
        <div className="header-section">
          <div className="button-wrapper">
            {isEditing ? (
              <Button
                type="primary"
                onClick={handleAddShipment}
                icon={<PlusOutlined />}
              >
                Add {isBox ? "Box" : "Pallet"}
              </Button>
            ) : allShipmentsReceived ? null : (
              <Button
                type="primary"
                onClick={markAllAsReceived}
                icon={<VerticalAlignBottomOutlined />}
                loading={updatingWRO}
              >
                Receive All
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="flex-col">{renderShipmentCards()}</div>
    </div>
  );
};

export default ShipmentCardList;
