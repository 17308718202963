import { getAPIFromDomain } from "../../lib/api";
import { Common } from "@secondcloset/types";
import { stringify, StringifyOptions } from "query-string";
import { FetchOrganizationDetailsQuery } from "../../hooks/api/fulfillment/organization";

type MutateOrganizationBody = {
  organizationID: string;
  body: Record<string, unknown> | string[];
};

export const fetchOrganizationIndex = async (
  query: Record<string, unknown>
): Promise<Common.Organization[]> => {
  const api = getAPIFromDomain("accounts");
  const options: StringifyOptions = { arrayFormat: "bracket" };
  const url = `/organizations${query ? "?" + stringify(query, options) : ""}`;
  const res = await api.get(url);
  return res.data;
};

export const fetchOrganizationDetails = async (
  organizationID: string,
  query?: FetchOrganizationDetailsQuery
): Promise<Common.Organization> => {
  const api = getAPIFromDomain("accounts");
  const res = await api.get(`/organizations/${organizationID}`, {
    params: query,
  });
  return res.data.organization;
};

export const updateOrganizationFsaDetails = async (
  organizationID: string,
  body: any
): Promise<any> => {
  const api = getAPIFromDomain("accounts");
  const res = await api.put(
    `/organizations/${organizationID}/update_facility_service_area`,
    body
  );

  return res.data;
};

export const updateOrganizationDetails = async ({
  organizationID,
  body,
}: MutateOrganizationBody): Promise<Common.Organization> => {
  const api = getAPIFromDomain("accounts");
  const res = await api.put(`/organizations/${organizationID}`, body);
  return res.data.organization;
};

export const deleteOrganizationConfig = async ({
  organizationID,
  body: keys,
}: MutateOrganizationBody): Promise<Common.Organization> => {
  const api = getAPIFromDomain("accounts");
  const url = `/organizations/${organizationID}/delete_configuration`;
  const res = await api.delete(url, { data: { keys } });
  return res.data.organization;
};
