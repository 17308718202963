import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Tag,
  Select,
  Modal,
  InputNumber,
  Spin,
  notification,
} from "antd";
import Products from "../../../../lib/regular/products";
import PropTypes from "prop-types";
import { startCase, flatten } from "lodash-es";

const ClosetNameModifier = (props) => {
  const [isClosetNameEditMode, setIsClosetNameEditMode] = useState(false);
  const [newClosetName, setNewClosetName] = useState(null);
  const [modalVisible, setModalVisible] = useState(null);
  const [newPlanID, setNewPlanID] = useState(null);
  const [newQty, setNewQty] = useState(1);
  const { products, selectedCloset, selectedClosetID } = props;

  const getCity = (p) => {
    const serviceArea = p && p.metadata.pricing_segment;
    const city = serviceArea === "yvr" ? "Vancouver" : "Toronto";
    return city;
  };

  const buildCityTag = (city) => {
    const color = city === "Toronto" ? "purple" : "magenta";
    return <Tag color={color}>{city}</Tag>;
  };

  const sortPlansByServiceArea = (serviceArea, plans) => {
    const groupedPlansByServiceArea = {};

    for (let i = 0; i < plans.length; i++) {
      const serviceArea = plans[i].metadata.pricing_segment;
      if (!groupedPlansByServiceArea[serviceArea])
        groupedPlansByServiceArea[serviceArea] = [];
      groupedPlansByServiceArea[serviceArea].push(plans[i]);
    }

    let result = Object.keys(groupedPlansByServiceArea).reduce((acc, sa) => {
      const targetPlans = groupedPlansByServiceArea[sa].sort(
        (p1, p2) => p1.price - p2.price
      );
      if (sa === serviceArea) acc.unshift(targetPlans);
      else acc.push(targetPlans);
      return acc;
    }, []);

    return flatten(result);
  };

  const buildPlanSelector = () => {
    const targetProduct =
      products.data &&
      products.data.find((p) => p.id === selectedCloset.stripe_plan_id);
    const currentServiceArea =
      targetProduct && targetProduct.metadata.pricing_segment;
    const spacePlans = sortPlansByServiceArea(
      currentServiceArea,
      Products.getPlans(products.data || [])
    );
    return (
      <Select
        className="plans-dropdown"
        size="small"
        value="Change Plan"
        style={{ width: 250 }}
        onSelect={(id) => {
          setNewPlanID(id);
          setModalVisible(true);
          setNewQty(1);
        }}
      >
        {spacePlans.map((plan) => {
          const { metadata, name, price, id } = plan;
          const serviceArea = metadata.pricing_segment;
          const city = serviceArea === "yvr" ? "Vancouver" : "Toronto";
          const planPrice = (price / 100).toFixed(2);
          return (
            <Select.Option
              key={id}
              value={id}
              disabled={currentServiceArea !== serviceArea}
            >
              <div className="plan-row">
                {`${name} - $${planPrice} `}
                {buildCityTag(city)}
              </div>
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const onNameUpdate = () => {
    props
      .onUpdateClosetDetails(selectedClosetID, {
        closet_name: newClosetName,
        quantity: newQty,
      })
      .then((res) => {
        if (res) {
          notification.success({
            message: "Update Success",
            description: `successfully updated closet name`,
          });
        }
      });
    setIsClosetNameEditMode(false);
  };

  const buildEditModeControl = () => {
    const nameInput = (
      <Input
        size="small"
        value={newClosetName}
        onChange={(e) => setNewClosetName(e.target.value)}
      />
    );
    const saveEditBtn = (
      <Button size="small" onClick={onNameUpdate}>
        Save
      </Button>
    );
    const cancelEditBtn = (
      <Button
        size="small"
        style={{ margin: "0 10px" }}
        danger
        onClick={() => setIsClosetNameEditMode(false)}
      >
        Cancel
      </Button>
    );
    return (
      <>
        {nameInput}
        {cancelEditBtn}
        {saveEditBtn}
      </>
    );
  };

  const buildViewModeControl = () => {
    const nameDisplay = (
      <div className="closet-full-name">
        {selectedCloset.closet_name || selectedCloset.name}{" "}
        <span>- {selectedCloset.name}</span>
      </div>
    );

    const targetProduct =
      products.data &&
      products.data.find((p) => p.id === selectedCloset.stripe_plan_id);

    const city = getCity(targetProduct);
    const cityTag = buildCityTag(city);
    const quantity = (
      <span className="closet-quantity">Qty: {selectedCloset.quantity}</span>
    );
    const startEditBtn = (
      <Button
        size="small"
        style={{ margin: "0 10px 0 2px" }}
        onClick={() => {
          setIsClosetNameEditMode(true);
          setNewClosetName(selectedCloset.closet_name || selectedCloset.name);
        }}
      >
        Edit Name
      </Button>
    );
    return (
      <>
        {nameDisplay}
        {cityTag}
        {startEditBtn}
        {buildPlanSelector()}
        {quantity}
      </>
    );
  };

  const onUpdatePlan = () => {
    props
      .onUpdateClosetDetails(selectedClosetID, {
        quantity: newQty,
        stripe_plan_id: newPlanID,
      })
      .then((res) => {
        if (res) {
          notification.success({
            message: "Update Success",
            description: `successfully updated closet plan`,
          });
        }
      });
    setModalVisible(false);
  };

  const buildPlanSwitchModal = () => {
    if (!selectedClosetID) return null;
    const currentPlan =
      products.data &&
      products.data.find((p) => p.id === selectedCloset.stripe_plan_id);
    const currentCity = getCity(currentPlan);
    const currentPlanName = currentPlan && currentPlan.name;
    const currentPlanPrice = currentPlan && currentPlan.price;

    const newPlan =
      products.data && products.data.find((p) => p.id === newPlanID);
    const newPlanName = newPlan && newPlan.name;
    const newPlanPrice = newPlan && newPlan.price;
    const newCity = getCity(newPlan);

    return (
      <Modal
        title="Update Plan"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={onUpdatePlan}
      >
        <div className="update-plan-modal">
          <div className="plan-row">
            <label>Current Plan:</label>
            <div>
              {currentPlanName} - ${(currentPlanPrice / 100).toFixed(2)}{" "}
              {buildCityTag(currentCity)}
            </div>
          </div>
          <div className="plan-row">
            <label>New Plan:</label>
            <div className="new">
              {newPlanName} - ${(newPlanPrice / 100).toFixed(2)}{" "}
              {buildCityTag(newCity)}
            </div>
          </div>
          <div>
            Qty: <InputNumber value={newQty} onChange={(v) => setNewQty(v)} />
          </div>
        </div>
      </Modal>
    );
  };

  switch (selectedClosetID) {
    case "all-items":
    case "item-plan":
      return (
        <div className="closet-name-modifier">
          <div className="name">{startCase(selectedClosetID)}</div>
        </div>
      );
    default: {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <div className="closet-name-modifier">
          <Spin spinning={props.closetLoading} indicator={antIcon}>
            <div className="name">
              {isClosetNameEditMode
                ? buildEditModeControl()
                : buildViewModeControl()}
            </div>
          </Spin>
          {buildPlanSwitchModal()}
        </div>
      );
    }
  }
};

ClosetNameModifier.propTypes = {
  products: PropTypes.object.isRequired,
  selectedCloset: PropTypes.object,
  selectedClosetID: PropTypes.string.isRequired,
  onUpdateClosetDetails: PropTypes.func.isRequired,
  closetLoading: PropTypes.bool.isRequired,
};

export default ClosetNameModifier;
