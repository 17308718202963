import React, { useEffect } from "react";
import ManifestLayout from "./ManifestLayout/ManifestLayout";
import PageLoader from "../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import {
  useAppointmentDetails,
  useAppointmentNotes,
  useAppointmentTasks,
} from "../../../hooks/api/regular/appointment";
import useMount from "../../../hooks/useMount";
import { notification } from "antd";
import { get } from "lodash-es";
import moment from "moment";
import {
  getDisplayType,
  formatedAddress,
  formatNumber,
} from "../../../helperFunctions";

const ManifestPage = () => {
  const {
    appointmentDetails,
    fetchAppointmentDetails,
  } = useAppointmentDetails();
  const { appointmentNotes, fetchAppointmentNotes } = useAppointmentNotes();
  const { appointmentTasks, fetchAppointmentTasks } = useAppointmentTasks();

  const params = useParams();
  const apptID = get(params, "ApptID");

  useMount(() => {
    fetchAppointmentDetails(apptID);
    fetchAppointmentNotes(apptID);
    fetchAppointmentTasks(apptID);
  });

  const error =
    appointmentDetails.error ||
    appointmentNotes.error ||
    appointmentTasks.error;

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: error,
      });
    }
  }, [error]);

  if (
    appointmentDetails.loading ||
    appointmentNotes.loading ||
    appointmentTasks.loading
  )
    return <PageLoader />;

  const appointment = appointmentDetails.data;

  if (!appointment) return null;

  const { user_firstname, user_lastname, user_code } = appointment;
  const recipient = `${user_firstname} ${user_lastname} [${user_code}]`;
  const apptNumber = get(appointment, "number");
  const address = get(appointment, "address");
  const apptAddress = formatedAddress(address);
  const phone = formatNumber(get(appointment, "address.phone_number"));
  const jobType = getDisplayType(get(appointment, "job_type"));
  const apptItems = get(appointment, "items");
  const apptDate = moment(get(appointment, "date"), "YYYY-MM-DD").format(
    "MMMM Do YYYY"
  );
  const apptTime = get(appointment, "formatted_timeslot");
  const apptNotes = get(appointmentNotes, "data");
  const apptTasks = get(appointmentTasks, "data");

  return (
    <div id="manifest-page">
      <ManifestLayout
        recipient={recipient}
        address={apptAddress}
        date={apptDate}
        phone={phone}
        type={jobType}
        apptNum={apptNumber}
        time={apptTime}
        items={apptItems}
        customTask={apptTasks}
        notes={apptNotes}
      />
    </div>
  );
};

export default ManifestPage;
