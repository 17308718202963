import * as actionTypes from "../../../actions/fulfillment/types/product";

const INITIAL_STATE = {
  fetching: false,
  data: null,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.FULFILLMENT_PRODUCT_DETAILS_REQUESTED:
      return { ...state, fetching: true };
    case actionTypes.FULFILLMENT_PRODUCT_DETAILS_RECEIVED:
      return { ...state, fetching: false, data: action.data, error: null };
    case actionTypes.FULFILLMENT_PRODUCT_DETAILS_ERRORED:
      return { ...state, fetching: false, data: null, error: action.error };
    case actionTypes.FULFILLMENT_PRODUCT_DETAILS_RESET:
      return { ...INITIAL_STATE };
    case actionTypes.FULFILLMENT_PRODUCT_PUT_SUCCESS:
      return { ...state, fetching: false, data: action.data, error: null };
    default:
      return state;
  }
}
