import React, { useState } from "react";

// Components
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  DatePicker,
  Space,
  Tooltip,
  Modal,
  message,
} from "antd";
import {
  OrdersTable,
  CheckboxDropdown,
  DropdownButton,
  SearchOrganizationInput,
  SearchInput,
} from "@secondcloset/web-components";
import PrintPickSheetButton from "../../../components/fulfillment/_common/PrintPickSheetButton";
import {
  ArrowLeftOutlined,
  FilterOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// styles
import * as S from "./styles";

// Hooks
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import useOrderCreateFlow from "../../../hooks/reduxContainer/fulfillment/orderCreateFlow";
import useShipmentCreateFlow from "../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import useURLState from "../../../hooks/application/useURLStateV2";

// api
import {
  bulkUpdateOrders,
  fetchOrderIndex,
} from "../../../api/fulfillment/order";
import { fetchOrganizationIndex } from "../../../api/accounts/organization";
import { fetchShops } from "../../../api/integration/shopify";

// Libs
import moment, { Moment } from "moment";
import { debounce, get, isEmpty, startCase } from "lodash-es";
import {
  getShipmentTagOptions,
  getOrderStatusOptions,
  getStockStatusOptions,
  PAGE_LINKS,
  useOrderIndexQueryOptions,
} from "./helpers";
import { Order, Facility } from "@secondcloset/fulfillment-utils";

// Context
import { useOrganizationContext } from "../../../contextProviders/organization/OrganizationProvider";

// Interfaces
import { SorterResult } from "antd/lib/table/interface";
import { Fulfillment, Common } from "@secondcloset/types";
type FacilityCode = Common.FacilityCode;
type MarkAsTag = "unfulfilled_items" | "ready_to_fulfill";
const { RangePicker } = DatePicker;

const FulfillmentOrderIndexPage: React.FC = () => {
  const history = useHistory();
  const { organization_id: orgID } = useParams<{ organization_id?: string }>();
  const [urlState, setUrlState] = useURLState({
    search: "",
    page: 1,
    pageSize: 10,
    shipmentTags: [],
    orderStatus: [],
    stockStatus: [],
    dateFrom: "",
    dateTo: "",
    organizations: [],
  });
  const [searchedOrganization, setSearchedOrganization] = useState("");
  const [sort, setSort] = useState<{
    field?: any;
    direction?: string | null;
  }>({ field: "created_at", direction: "" });
  const [selectedOrders, setSelectedOrders] = useState<Fulfillment.Order[]>([]);
  const [isShowFilters, setIsShowFilters] = useState(true);
  const { dispatchSetAutoCreateDeliveryShipment } = useOrderCreateFlow();
  const {
    dispatchSetIsReturn,
    dispatchResetShipmentCreateFlow,
  } = useShipmentCreateFlow();
  const organizationDetails = useOrganizationContext();
  const queryClient = useQueryClient();
  const queryOptions = useOrderIndexQueryOptions({ urlState, sort, orgID });

  const orderIndex = useQuery(
    ["orderIndex", queryOptions],
    () => fetchOrderIndex(queryOptions),
    {
      keepPreviousData: true,
    }
  );

  const [isOrgConnectedToShopify, setIsOrgConnectedToShopify] = useState(false);
  useQuery(
    ["shopifyShops", organizationDetails.data?.id],
    () => fetchShops({ organization_id: organizationDetails.data?.id }),
    {
      enabled: !!organizationDetails.data?.id,
      onSettled: (shops) =>
        setIsOrgConnectedToShopify(!!shops?.some((shop) => shop.active)),
    }
  );

  const organizationIndex = useQuery(
    ["organizationIndex", searchedOrganization],
    () => fetchOrganizationIndex({ q: searchedOrganization }),
    {
      enabled: searchedOrganization.length >= 3 && !orgID,
    }
  );
  const [isBulkUpdatingOrders, setIsBulkUpdatingOrders] = useState(false);
  const { mutate: onBulkUpdateOrders, ...bulkUpdatedOrders } = useMutation(
    bulkUpdateOrders,
    {
      onSuccess: async (data, variables) => {
        setIsBulkUpdatingOrders(true);
        return await new Promise((resolve) => {
          // eslint-disable-next-line
          selectedOrders.map((o) => {
            if (variables?.shipment_tag)
              o.shipment_tags = [variables?.shipment_tag];
            if (variables?.fulfilled_from)
              o.fulfilled_from = variables?.fulfilled_from;
          });
          resolve(setSelectedOrders(selectedOrders));
        }).then(() => {
          setIsBulkUpdatingOrders(false);
          const updatedSegment = variables.shipment_tag
            ? "Shipment tag"
            : variables.fulfilled_from
            ? "Fulfilled from facility"
            : "";
          message.success(`${updatedSegment} updated successfully.`);
        });
      },
      onSettled: () => setIsBulkUpdatingOrders(false),
    }
  );

  const updateShipmentTag = (tag: MarkAsTag) => {
    const changeableOrders = selectedOrders.filter(
      (o) => !o.shipment_tags?.includes(tag) && !o.cancelled
    );
    const toBeUpdatedOrderIDs = changeableOrders.map((o) => o.id);
    onBulkUpdateOrders({
      order_ids: toBeUpdatedOrderIDs,
      shipment_tag: tag,
    });
  };

  const updateOrdersFulfilledFrom = (facility: FacilityCode) => {
    onBulkUpdateOrders({
      order_ids: selectedOrders.map((o) => o.id),
      fulfilled_from: facility,
    });
  };

  const handleMarkAsSelectionChange = (key: MarkAsTag | FacilityCode) => {
    const isMarkingShipmentTags = [
      "unfulfilled_items",
      "ready_to_fulfill",
    ].includes(key);
    const status = key === "unfulfilled_items" ? "Unfulfilled" : startCase(key);
    const newFacility = key.toUpperCase();
    const length = selectedOrders.length;
    Modal.confirm({
      title: `Are you sure you want to change ${length} ${
        length > 1 ? "orders" : "order"
      } to ${isMarkingShipmentTags ? status : newFacility}?`,
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { loading: bulkUpdatedOrders.isLoading },
      onOk: () => {
        if (isMarkingShipmentTags) updateShipmentTag(key as MarkAsTag);
        else updateOrdersFulfilledFrom(key as FacilityCode);
      },
    });
  };

  const redirectTo = (link: string) => {
    history.push(`/organizations/${orgID}${link}`);
  };

  const getDateString = (d?: Moment) => (d ? d.format("YYYY-MM-DD") : "");

  const updateDates = (dates: [Moment, Moment] | null) => {
    const [from, to] = dates ?? [];
    setUrlState((state) => ({
      ...state,
      dateFrom: getDateString(from),
      dateTo: getDateString(to),
    }));
  };

  const showOrderRecreateDisclaimer = (link: string) =>
    Modal.confirm({
      title: "Disclaimer",
      content: (
        <Space direction="vertical">
          <p>
            Recreate an order that has been fulfilled in your e-commerce site in
            order to create a return shipment.
          </p>
          <p>
            Please make sure all information is exactly the same as your order
            on your e-commerce site to prevent any data discrepancies.
          </p>
        </Space>
      ),
      okText: "I understand. Let's continue",
      onOk: () => {
        dispatchSetAutoCreateDeliveryShipment();
        redirectTo(link);
      },
    });

  const onCreateOrderDropdownClick = (key: string) => {
    if (key === "return") showOrderRecreateDisclaimer(PAGE_LINKS.reCreateOrder);
    else if (key === "manual") redirectTo(PAGE_LINKS.createOrder);
    else if (key === "csv") redirectTo(PAGE_LINKS.uploadCsv);
  };

  const buildCreateOrderDropdown = () => {
    if (!orgID) return null;
    const options = [
      { key: "manual", label: "Single Order" },
      { key: "csv", label: "Upload via CSV" },
      { key: "return", label: "Return Order" },
    ];

    return (
      <DropdownButton
        buttonText="Create Order"
        options={options}
        isDisabled={!!selectedOrders.length}
        onOptionSelect={onCreateOrderDropdownClick}
      />
    );
  };

  const checkIsShipmentTagUpdateDisabled = () => {
    const hasMultipleTags = selectedOrders.some(
      (o) => o?.shipment_tags?.length > 1
    );
    const hasNoneEditableTag = selectedOrders.some(
      (o) =>
        !o?.shipment_tags?.includes("unfulfilled_items") &&
        !o?.shipment_tags?.includes("ready_to_fulfill")
    );
    return hasNoneEditableTag || hasMultipleTags;
  };

  const renderMarkSelectedAsDropdown = () => {
    const isShipmentTagUpdateDisabled = checkIsShipmentTagUpdateDisabled();
    const isLoading = bulkUpdatedOrders.status === "loading";
    const hasNoSelectedOrder = selectedOrders.length <= 0;
    const hasCancelledOrder = selectedOrders.some((o) => !!o?.cancelled);
    const facilityList = Facility.getFulfillmentFacilityList();
    const facilitiesByProvince = Facility.getFacilityByProvince(facilityList);
    const subMenuOptions = [
      {
        title: "Shipment Tag",
        disabled: isShipmentTagUpdateDisabled,
        message:
          "Only active orders with Unfulfilled or Ready To Fulfill shipment tags can be updated",
        options: [
          { key: "unfulfilled_items", label: "Unfulfilled" },
          { key: "ready_to_fulfill", label: "Ready to Fulfill" },
        ],
      },
      {
        title: "Fulfilled From",
        options: Object.entries(facilitiesByProvince).map(
          ([province, facilities]) => {
            return {
              title: province,
              options: facilities.map((f) => {
                return {
                  key: f.code,
                  label: f.name,
                };
              }),
            };
          }
        ),
      },
    ];

    return (
      <DropdownButton
        buttonText="Mark Selected As"
        subMenuOptions={subMenuOptions}
        isLoading={isLoading || isBulkUpdatingOrders}
        isDisabled={hasNoSelectedOrder || hasCancelledOrder}
        tooltipMessage={
          hasNoSelectedOrder
            ? "Please select at least one order."
            : hasCancelledOrder
            ? "Cancelled orders cannot be updated."
            : undefined
        }
        onOptionSelect={(key) => handleMarkAsSelectionChange(key as MarkAsTag)}
      />
    );
  };

  const startCreateReturnShipment = () => {
    const selectedOrder = selectedOrders[0];
    dispatchResetShipmentCreateFlow();
    dispatchSetIsReturn(true);
    history.push(
      `/fulfillment/orders/${selectedOrder?.id}/create-shipment?isReturn=true`
    );
  };

  const getReturnShipmentDisabledMessage = () => {
    if (selectedOrders.length > 1)
      return "Please select only one order to return at a time";
    else if (
      selectedOrders.length === 1 &&
      isEmpty(Order.getToBeReturnedItems(selectedOrders[0]))
    )
      return "Selected order does not contain any returnable items.";
    else return "";
  };

  const buildCreateReturnShipmentButton = () => {
    if (!orgID) return null;
    const isOnlyOneOrderSelected = selectedOrders.length === 1;
    const selectedOrder = selectedOrders[0];
    const isCreateReturnShipmentDisabled =
      !isOnlyOneOrderSelected ||
      (isOnlyOneOrderSelected &&
        isEmpty(Order.getToBeReturnedItems(selectedOrder)));

    return (
      <Tooltip title={getReturnShipmentDisabledMessage()}>
        <Button
          onClick={startCreateReturnShipment}
          type="default"
          disabled={isCreateReturnShipmentDisabled}
        >
          Create Return Shipment
        </Button>
      </Tooltip>
    );
  };

  const renderTopButtons = () => {
    return (
      <Space direction="horizontal">
        <PrintPickSheetButton
          selectedOrders={selectedOrders}
          refetch={() => queryClient.invalidateQueries("orderIndex")}
        />
        {renderMarkSelectedAsDropdown()}
        {buildCreateReturnShipmentButton()}
        {buildCreateOrderDropdown()}
      </Space>
    );
  };

  const renderOrganizationName = () => {
    const organizationName = get(organizationDetails, "data.name", "");
    return (
      <Button
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => redirectTo(PAGE_LINKS.organizationDetails)}
      >
        {organizationName}
      </Button>
    );
  };

  const renderOrderSyncButton = () => {
    return (
      <Space style={{ marginLeft: "auto" }}>
        Order not in our system?
        <Tooltip title="We will need to re-create a fulfilled order to make a return shipment">
          <Button
            type="link"
            icon={<SyncOutlined />}
            onClick={() => redirectTo(PAGE_LINKS.syncShopifyOrder)}
          >
            Pull Order from Shopify
          </Button>
        </Tooltip>
      </Space>
    );
  };

  const renderFilterWithLabel = (
    label: string,
    options: { key: string; label: string; value: string }[],
    selectedOptionKeys: string[],
    setSelectedOptionKeys: (selectedKeys: string[]) => void
  ) => {
    return (
      <S.FlexContainer>
        <S.Label>{label}</S.Label>
        <CheckboxDropdown
          options={options}
          defaultText="All"
          selectedOptionKeys={selectedOptionKeys}
          setSelectedOptionKeys={setSelectedOptionKeys}
          loading={orderIndex.isLoading}
        />
      </S.FlexContainer>
    );
  };

  const renderOrderFilters = () => {
    const shipmentTagsOptions = getShipmentTagOptions();
    const orderStatusOptions = getOrderStatusOptions();
    const stockStatusOptions = getStockStatusOptions();

    return (
      <S.FiltersContainer>
        {isShowFilters && (
          <S.FlexContainer>
            {renderFilterWithLabel(
              "Shipment Tags",
              shipmentTagsOptions,
              urlState.shipmentTags,
              (shipmentTags) =>
                setUrlState((state) => ({ ...state, shipmentTags }))
            )}
            {renderFilterWithLabel(
              "Order Status",
              orderStatusOptions,
              urlState.orderStatus,
              (orderStatus) =>
                setUrlState((state) => ({ ...state, orderStatus }))
            )}
            {renderFilterWithLabel(
              "Order Stock",
              stockStatusOptions,
              urlState.stockStatus,
              (stockStatus) =>
                setUrlState((state) => ({
                  ...state,
                  stockStatus,
                  // shipmentTags: ["unfulfilled_items"],
                }))
            )}
            <S.FlexContainer>
              <S.Label>Date Created</S.Label>
              <RangePicker
                onChange={(dates) =>
                  updateDates(dates as [Moment, Moment] | null)
                }
                defaultValue={
                  urlState.dateFrom && urlState.dateTo
                    ? [moment(urlState.dateFrom), moment(urlState.dateTo)]
                    : null
                }
              />
            </S.FlexContainer>
          </S.FlexContainer>
        )}
      </S.FiltersContainer>
    );
  };

  const renderOrderHeader = () => {
    return (
      <S.OrderHeader>
        <S.FlexColumnContainer>
          {organizationDetails?.data && (
            <S.FlexRowContainer>
              {renderOrganizationName()}
              {isOrgConnectedToShopify && renderOrderSyncButton()}
            </S.FlexRowContainer>
          )}
          <S.FlexRowContainer>
            <S.PageTitle>Orders</S.PageTitle>
            {renderTopButtons()}
          </S.FlexRowContainer>
          <S.SearchContainer>
            <SearchInput
              placeholder="Search for orders"
              defaultValue={urlState.search}
              onSearch={(search) =>
                setUrlState((state) => ({ ...state, search, page: 1 }))
              }
              onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
            />
            {orgID ? null : (
              <SearchOrganizationInput
                mode="tags"
                placeholder="Search for clients"
                onSearch={debounce(setSearchedOrganization, 800)}
                loading={organizationIndex.isLoading}
                organizations={organizationIndex.data ?? []}
                onChange={(values: Record<"label" | "value", string>[]) =>
                  setUrlState((state) => ({
                    ...state,
                    organizations: values?.map((v) => v.label),
                    page: 1,
                  }))
                }
              />
            )}
            <Button
              type="text"
              onClick={() => setIsShowFilters(!isShowFilters)}
            >
              <FilterOutlined /> {isShowFilters ? "Hide" : "Show"} Filters
            </Button>
          </S.SearchContainer>
        </S.FlexColumnContainer>
        {renderOrderFilters()}
      </S.OrderHeader>
    );
  };

  const getSortDirection = (direction?: "ascend" | "descend" | null) => {
    if (direction === "ascend") return "ASC";
    if (direction === "descend") return "DESC";
    return "";
  };

  const handleTableChange = (sorter: SorterResult<any>) => {
    const { field, order } = sorter;
    setSort({ field: field, direction: getSortDirection(order) });
  };

  const buildOrdersTable = () => {
    return (
      <>
        {renderOrderHeader()}
        <OrdersTable
          orders={orderIndex.data?.data ?? []}
          isLoading={orderIndex.isPreviousData || orderIndex.isLoading}
          isFulfillmentClientVisible={!orgID}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          renderOrderLinkComponent={(displayText, orderID) => (
            <Link to={`/fulfillment/orders/${orderID}`}>{displayText}</Link>
          )}
          renderOrganizationLinkComponent={(displayText, orgID) => (
            <Link to={`/organizations/${orgID}`}>{displayText}</Link>
          )}
          onSort={handleTableChange}
          pagination={{
            size: "default",
            pageSize: urlState.pageSize,
            total: orderIndex.data?.meta.total_entries ?? 0,
            current: orderIndex.data?.meta.current_page ?? 0,
            showLessItems: true,
            showTotal: (total, [min, max]) =>
              `Showing ${min}-${max} of ${total}`,
            onChange: (page, pageSize = 0) => {
              setUrlState((state) => ({ ...state, pageSize, page }));
            },
          }}
        />
      </>
    );
  };

  return (
    <div className="page-container" id="order-index-page">
      {buildOrdersTable()}
      {orderIndex.error && (
        <Alert
          message={orderIndex.error as string}
          type="error"
          style={{ marginBottom: "16px" }}
          banner
        />
      )}
    </div>
  );
};

export default FulfillmentOrderIndexPage;
