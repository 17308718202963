import React, { useEffect, useState } from "react";

// components
import { Modal, notification } from "antd";
import {
  InventoryReceivingItemsTable,
  InventoryReceivingItemsTableTypes,
} from "@secondcloset/web-components";

// styles
import * as S from "./styles";

// api
import { useUpdateItemStatus } from "../../../../../../hooks/api/fulfillment/shipment";

// Helpers
import { isEmpty } from "lodash-es";
import * as shipmentCardLib from "../../ShipmentCard/helper";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type ShipmentItem = Fulfillment.ShipmentItem;
type TableItem = InventoryReceivingItemsTableTypes.Item;
type ItemStatus = InventoryReceivingItemsTableTypes.ItemStatus;

// enums
const ItemStatus = InventoryReceivingItemsTableTypes.ItemStatus;

interface Props {
  shipment: ShipmentType;
  visible: boolean;
  orderLoading: boolean;
  setVisible: (VisibilityState: boolean) => unknown;
  onRefetch: () => unknown;
}

const ProcessInventoryReceivingItemsModal: React.FC<Props> = ({
  shipment,
  visible,
  orderLoading,
  setVisible,
  onRefetch,
}) => {
  const [search, setSearch] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<
    ItemStatus[]
  >([]);
  const [selectedItemIDs, setSelectedItemIDs] = useState<string[]>([]);
  const { updateItemStatusData, updateItemStatus } = useUpdateItemStatus();

  const isShipmentCompleted = shipment?.shipping_method?.status === "completed";

  useEffect(() => {
    if (updateItemStatusData.error) {
      notification.error({
        message: "Update not successful",
        description: updateItemStatusData.error,
      });
    }
  }, [updateItemStatusData.error]);

  const getShipmentItems = () => {
    if (isEmpty(shipment?.shipment_items)) return [];
    return shipment.shipment_items.map((item: ShipmentItem) => {
      return {
        id: item.id,
        sku: item.item_identifier_value,
        name: item.description,
        itemStatus: shipmentCardLib.getItemStatus(item),
      } as TableItem;
    });
  };

  const resetFields = () => {
    setSearch("");
    setSelectedStatusFilter([]);
    setSelectedItemIDs([]);
  };

  const onUpdateItemStatus = (itemIDs: string[], newStatus: ItemStatus) => {
    const body = shipmentCardLib.constructUpdateItemStatusBody(
      itemIDs,
      newStatus
    );

    updateItemStatus(shipment.id, body).then((success: boolean) => {
      if (success) {
        resetFields();
        onRefetch();
      }
    });
  };

  const renderTable = () => {
    return (
      <S.TableContainer>
        <InventoryReceivingItemsTable
          viewOnly={isShipmentCompleted}
          search={search}
          selectedStatusFilter={selectedStatusFilter}
          selectedItemIDs={selectedItemIDs}
          shipmentItems={getShipmentItems()}
          onItemStatusUpdate={onUpdateItemStatus}
          tableDataLoading={updateItemStatusData.loading || orderLoading}
          setSearch={setSearch}
          setSelectedStatusFilter={setSelectedStatusFilter}
          setSelectedItemIDs={setSelectedItemIDs}
        ></InventoryReceivingItemsTable>
      </S.TableContainer>
    );
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={1000}
    >
      {renderTable()}
    </Modal>
  );
};

export default ProcessInventoryReceivingItemsModal;
