import * as actionTypes from "actions/fulfillment/types";

const INITIAL_STATE = {
  products_and_quantities: [],
  productsDetails: {},
};

const addProductToStore = (product, state) => {
  const { id, scid, sku, name, tracks_lot_numbers, packagingLevel } = product;

  if (state.productsDetails[id]) return state;

  const products_and_quantities = [
    ...state.products_and_quantities,
    { product_id: id, quantity: 1 },
  ];
  const productsDetails = {
    ...state.productsDetails,
    [id]: {
      scid,
      sku,
      name,
      tracks_lot_numbers,
      packagingLevel,
    },
  };

  return { products_and_quantities, productsDetails };
};

const modifyProductQuantity = (product, state) => {
  const { product_id, quantity } = product;
  const products_and_quantities = state.products_and_quantities.map((p) => {
    if (p.product_id === product_id) return { ...p, quantity };
    return p;
  });

  return { ...state, products_and_quantities };
};

const setProductLotNumber = (productID, lotNumber, state) => {
  const { productsDetails } = state;
  const newProductsDetails = { ...productsDetails };
  newProductsDetails[productID].lot_number = lotNumber;
  return { ...state, productsDetails: newProductsDetails };
};

const removeProductFromStore = (product_id, state) => {
  const products_and_quantities = state.products_and_quantities.filter(
    (p) => p.product_id !== product_id
  );
  const productsDetails = { ...state.productsDetails };
  delete productsDetails[product_id];

  return { products_and_quantities, productsDetails };
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_ADD_PRODUCT_TO_CART,
    WRO_MODIFY_PRODUCT_QUANTITY,
    WRO_SET_PRODUCT_LOT_NUMBER,
    WRO_REMOVE_PRODUCT_FROM_CART,
    WRO_REMOVE_ALL_PRODUCTS_FROM_CART,
    WRO_CREATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_ADD_PRODUCT_TO_CART:
      return addProductToStore(action.product, state);
    case WRO_MODIFY_PRODUCT_QUANTITY:
      return modifyProductQuantity(action.product, state);
    case WRO_SET_PRODUCT_LOT_NUMBER:
      return setProductLotNumber(action.productID, action.lotNumber, state);
    case WRO_REMOVE_PRODUCT_FROM_CART:
      return removeProductFromStore(action.product_id, state);
    case WRO_REMOVE_ALL_PRODUCTS_FROM_CART:
    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
