import React from "react";

function formatProvince(province) {
  switch (province) {
    case "Ontario":
      return "ON";
    case "Manitoba":
      return "MA";
    default:
      return "n/a";
  }
}

export function formatedAddress(address) {
  // address info
  let AddressArray = [
    (address.apartment_number ? `${address.apartment_number} - ` : "") +
      address.address,
    address.city +
      " " +
      formatProvince(address.province) +
      " " +
      address.postal_code,
    address.country,
  ];

  let result = AddressArray.map((obj, index) => {
    return (
      <span key={index}>
        {obj}
        <br />
      </span>
    );
  });

  return <div>{result}</div>;
}
