import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";
// types
type RecommendedShippingMethodType = SelectShipmentCarrierTypes.RecommendedShippingMethod;

// enums
const ServiceCategory = SelectShipmentCarrierTypes.ServiceCategory;
const RecommendedShippingMethod =
  SelectShipmentCarrierTypes.RecommendedShippingMethod;

export const mapRecommendedMethodToServiceCategory = (
  method: RecommendedShippingMethodType | null
) => {
  switch (method) {
    case RecommendedShippingMethod.parcel:
      return ServiceCategory.third_party;
    case RecommendedShippingMethod.freight:
      return ServiceCategory.freight;
    case RecommendedShippingMethod.sc_last_mile:
      return ServiceCategory.second_closet;
    default:
      return null;
  }
};
