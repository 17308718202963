import { getAPIFromDomain } from "../../lib/api";
import { Common } from "@secondcloset/types";
import { stringify, StringifyOptions } from "query-string";

export const fetchCustomerIndex = async (
  query?: Record<string, unknown>
): Promise<Common.User> => {
  const api = getAPIFromDomain();
  const options: StringifyOptions = { arrayFormat: "bracket" };
  const url = `/users${query ? "?" + stringify(query, options) : ""}`;
  const res = await api.get(url);
  return res.data;
};
