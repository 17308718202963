import { Fulfillment } from "@secondcloset/types";

import { getAPIFromDomain } from "../../lib/api";

export interface ProductIndexQuery {
  q?: string;
  page?: number;
  per_page?: number;
  organization_id?: string;
  product_type?: string;
  active?: boolean;
}

export const convertToVirtualKit = async ({ productID, body }: { productID: string; body: any }): Promise<void> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/products/${productID}/convert_to_virtual_kit`;
  return api.post(url, body);
};

export const convertToBaseProduct = async ({
  productID,
}: {
  productID: string;
}): Promise<{ recipient_email: string }> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/products/${productID}/convert_to_base_product`;
  const res = await api.post(url, null);
  return res.data;
};

export const fetchProductsIndex = async (query: ProductIndexQuery): Promise<Fulfillment.Product[]> => {
  const api = getAPIFromDomain("fulfillment");
  const res = await api.get("/products", { params: query });
  return res.data;
};

interface ProductLogsQuery {
  id: string;
  page?: number;
  per_page?: number;
  event?: Fulfillment.ActivityHistory["event"];
}

interface ExportProductLogsQuery {
  productID: string;
  date_from: string;
  date_to: string;
  facility?: string[];
}

export const fetchProductLogs = async (q: ProductLogsQuery): Promise<Fulfillment.ActivityHistory[]> => {
  const { id: productID, ...query } = q;
  const api = getAPIFromDomain("fulfillment");
  const res = await api.get(`/products/${productID}/logs`, { params: query });
  return res.data;
};

export const exportProductLogs = async (q: ExportProductLogsQuery): Promise<{ message: string }> => {
  const { productID, ...query } = q;
  const api = getAPIFromDomain("fulfillment");
  const res = await api.get(`/products/${productID}/email_logs`, {
    params: query,
  });
  return res.data;
};
