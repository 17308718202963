import { getAPIFromDomain } from "../../lib/api";
import { Storage } from "@secondcloset/types";
import { stringify, StringifyOptions } from "query-string";

export const fetchOrderIndex = async (
  query?: Record<string, unknown>
): Promise<Storage.Order[]> => {
  const api = getAPIFromDomain();
  const options: StringifyOptions = { arrayFormat: "bracket" };
  const url = `/orders${query ? "?" + stringify(query, options) : ""}`;
  const res = await api.get(url);
  return res.data.map((d: { order: Storage.Order }) => d.order);
};
