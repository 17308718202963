import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { get } from "lodash-es";
import MarkAsIncompleteModal from "../../../_common/MarkAsIncompleteModal";

const MarkAsIncompleteButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const renderModal = () => {
    return (
      <MarkAsIncompleteModal
        appointment={props.appointment}
        visible={modalVisible}
        setVisible={setModalVisible}
        onRefetch={props.onRefetchAppointment}
      />
    );
  };

  const status = get(props.appointment, "status");
  const disablingStatues = [
    "request_received",
    "confirmed",
    "scheduled",
    "staging",
    "ready",
    "completed",
    "done",
    "failed",
    "cancelled",
    "incomplete",
  ];
  const isDisabled = disablingStatues.includes(status);

  return (
    <>
      <Button
        danger
        onClick={() => setModalVisible(true)}
        disabled={isDisabled}
      >
        Mark As Incomplete
      </Button>
      {renderModal()}
    </>
  );
};

MarkAsIncompleteButton.propTypes = {
  appointment: PropTypes.object,
  onRefetchAppointment: PropTypes.func.isRequired,
};

export default MarkAsIncompleteButton;
