import React, { useState } from "react";
import PropTypes from "prop-types";
import { CopyOutlined } from "@ant-design/icons";
import { Table as AntTable, Tag, Tooltip } from "antd";
import { startCase, toLower, get } from "lodash-es";

const Table = (props) => {
  const [tooltipTxt, setTooltipTxt] = useState("Copy ID");

  const buildColumns = () => {
    const renderDefault = (s) => s || "-";
    const renderStatus = (status) => {
      let color = "red";
      if (status === "requested") color = "gold";
      if (status === "in_storage") color = "green";
      if (status === "returned") color = "blue";
      if (status === "with_client") color = "cyan";
      return <Tag color={color}>{startCase(toLower(status))}</Tag>;
    };
    const renderItemID = (id) => {
      const text = `..${id.slice(-4)}`;
      if (!props.selectable) return text;
      return (
        <Tooltip placement="top" title={tooltipTxt} trigger={["hover"]}>
          <span onMouseLeave={() => setTooltipTxt("Copy ID")}>
            {text}{" "}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(id);
                setTooltipTxt("Copied!");
              }}
              className="copy-icon-btn"
            />
          </span>
        </Tooltip>
      );
    };

    return [
      { title: "Name", dataIndex: "name", key: "name" },
      {
        title: "Status",
        width: 150,
        dataIndex: "status",
        key: "status",
        render: renderStatus,
      },
      {
        title: "Stripe ID",
        width: 150,
        dataIndex: "stripe_id",
        key: "stripe_id",
      },
      {
        title: "QR code",
        width: 150,
        dataIndex: "qr_code",
        key: "qr_code",
        render: renderDefault,
      },
      {
        title: "Item ID",
        width: 150,
        dataIndex: "item_id",
        key: "item_id",
        render: renderItemID,
      },
    ];
  };

  const rowSelection = props.selectable
    ? {
        selectedRowKeys: props.selectedItemIDs,
        onChange: (ids) => props.setSelectedItemIDs(ids),
        getCheckboxProps: (record) => {
          const disablingStatus = ["returned", "with_client"];
          const status = get(record, "status");
          const disabled = disablingStatus.includes(status);
          return { disabled };
        },
        hideDefaultSelections: true,
      }
    : undefined;

  return (
    <AntTable
      rowKey={(r) => r.item_id}
      dataSource={props.orderItems}
      columns={buildColumns()}
      size="small"
      rowSelection={rowSelection}
      pagination={false}
      scroll={{ y: 400 }}
    />
  );
};

Table.propTypes = {
  orderItems: PropTypes.array,
  selectable: PropTypes.bool.isRequired,
  selectedItemIDs: PropTypes.array,
  setSelectedItemIDs: PropTypes.func,
};

export default Table;
