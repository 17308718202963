const controlCenterApptStatusColorMapping = (status) => {
  switch (status) {
    case "scheduled":
      return "green";
    case "ready":
      return "green";
    case "confirmed":
      return "blue";
    case "fulfilled":
      return "blue";
    case "request_received":
      return "orange";
    case "on_the_way":
      return "orange";
    case "started":
      return "gold";
    case "in_progress":
      return "gold";
    case "done":
      return "purple";
    case "completed":
      return "cyan";
    case "no_show":
      return "red";
    case "cancelled":
      return "red";
    default:
      return "";
  }
};

export { controlCenterApptStatusColorMapping };
