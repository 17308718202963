import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { GoBackBtn } from "../../components/_common";
import StandardCard from "../../components/StandardCard";
import { CreateInvoiceForm } from "../../components/Invoice";
import { fetchCustomerDetails, invoiceCreate } from "../../actions";

class CreateInvoice extends React.Component {
  componentWillMount() {
    const { id } = this.props.match.params;

    if (this.props.selectedCustomer.details === undefined) {
      this.props.fetchCustomerDetails(id);
    }
  }

  render() {
    const { selectedCustomer, invoiceCreate, history } = this.props;

    return (
      <div className="page-container col-xs-12">
        <Helmet>
          <title>Sierra - Create Invoice</title>
        </Helmet>
        <GoBackBtn history={history} />

        <div className="row col-xs-12 marginBottom20">
          <h1
            className="text-capitalize heavy text-center noMargin"
            style={{ display: "inline-block" }}
          >
            NEW INVOICE
          </h1>
        </div>

        <StandardCard>
          <CreateInvoiceForm
            customer={selectedCustomer}
            invoiceCreate={invoiceCreate}
          />
        </StandardCard>
      </div>
    );
  }
}

const mapStateToProps = ({ selectedCustomer }) => ({ selectedCustomer });

export default withRouter(
  connect(mapStateToProps, {
    fetchCustomerDetails,
    invoiceCreate,
  })(CreateInvoice)
);

CreateInvoice.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
  fetchCustomerDetails: PropTypes.func.isRequired,
  fetchCustomerInvoice: PropTypes.func.isRequired,
  invoiceCreate: PropTypes.func.isRequired,
};
