import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  getAppointmentColor,
  getDisplayType,
} from "../../../../helperFunctions";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { appointmentTypes } from "../../../../backendTypes";

const HEAVY_THRESHOLD = 30;

const AppointmentOverview = ({
  setSelectedDate,
  activeDates,
  activeAppointments,
  getAppointmentsCount,
}) => {
  const renderAppointmentsPerTimeslot = (label, count) => {
    return (
      <div className="appointments-in-timeslot">
        <label className="time-label">{label}</label>
        <div className="timeslot-appointment-count">{count}</div>
      </div>
    );
  };

  const renderDailySummaryCard = (date) => {
    let _date = date?.format("MMM DD");
    let appointments = activeAppointments.filter((obj) => {
      return moment(obj.date).isSame(date, "day") && obj.status !== "cancelled";
    });
    let appointment_counts = appointments.length;
    let ten_to_one = appointments.filter((obj) => obj.timerange === "10-13")
      .length;
    let one_to_four = appointments.filter((obj) => obj.timerange === "13-16")
      .length;
    let four_to_seven = appointments.filter((obj) => obj.timerange === "16-19")
      .length;
    let seven_to_ten = appointments.filter((obj) => obj.timerange === "19-22")
      .length;

    return (
      <div
        key={date}
        className="daily-summary-card"
        onClick={() => setSelectedDate(date)}
      >
        <div className="date-appointment-count">
          <div className="date">{_date}</div>
          <div
            className={`appointment-count${
              appointment_counts >= HEAVY_THRESHOLD ? " heavy-threshold" : ""
            }`}
          >
            {appointment_counts}
          </div>
        </div>

        <div className="appointments-by-timeslot">
          {renderAppointmentsPerTimeslot("10 to 1", ten_to_one)}
          {renderAppointmentsPerTimeslot("1 to 4", one_to_four)}
          {renderAppointmentsPerTimeslot("4 to 7", four_to_seven)}
          {renderAppointmentsPerTimeslot("7 to 10", seven_to_ten)}
        </div>
      </div>
    );
  };

  const renderChart = () => {
    let width = window.innerWidth * 0.46;
    let height = width / 4.5;

    const data = activeDates.reduce((acc, cv) => {
      let appointmentData = getAppointmentsCount(cv);
      let template = { date: cv?.format("MMM,DD") };
      appointmentTypes.forEach((i) => {
        let type = getDisplayType(i);
        template[type] = appointmentData[i];
      });
      acc.push(template);
      return acc;
    }, []);

    const bars = appointmentTypes.map((type, index) => {
      let dataKey = getDisplayType(type);
      let fill = getAppointmentColor("type", type);
      return (
        <Bar
          dataKey={dataKey}
          fill={fill}
          background={{ color: "#eee" }}
          key={index}
        />
      );
    });

    return (
      <div className="barchart-wrapper">
        <ResponsiveContainer>
          <BarChart
            width={width}
            height={height}
            data={data}
            margin={{ right: 20, bottom: 5 }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Legend />
            {bars}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const summaryCards = activeDates.map((d) => renderDailySummaryCard(d));

  return (
    <div className="appointment-overview">
      <div className="summary-cards-with-barchart">
        <div className="summary-cards-container">{summaryCards}</div>
        {renderChart()}
      </div>
    </div>
  );
};

AppointmentOverview.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  activeDates: PropTypes.array.isRequired,
  activeAppointments: PropTypes.array.isRequired,
  getAppointmentsCount: PropTypes.func.isRequired,
};

export default AppointmentOverview;
