import React from "react";
import PropTypes from "prop-types";
import SetStatusButton from "../../../../components/_common/SetStatusButton";
import SetDoneButton from "./SetDoneButton";
import MarkAsIncompleteButton from "../../../../components/regular/appointment/MarkAsIncompleteButton";
import { get } from "lodash-es";
import {
  StopOutlined,
  CheckOutlined,
  UndoOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const AppointmentActionsButtons = (props) => {
  const getAppointmentStatus = () => get(props.appointment, "status");

  const renderCancelButton = () => {
    const disablingStatues = [
      "done",
      "signed",
      "completed",
      "incomplete",
      "failed",
      "cancelled",
    ];

    const status = getAppointmentStatus();
    const disabled = disablingStatues.includes(status);

    const antBtnProps = {
      icon: <StopOutlined />,
      ghost: true,
      type: "danger",
      disabled,
    };

    return (
      <div className="button-wrap">
        <SetStatusButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
          antBtnProps={antBtnProps}
          newStatus="cancelled"
        >
          Cancel Appointment
        </SetStatusButton>
      </div>
    );
  };

  const renderSetDoneButton = () => {
    return (
      <div className="button-wrap">
        <SetDoneButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
        />
      </div>
    );
  };

  const renderSetCompleteButton = () => {
    const disablingStatues = ["completed", "incomplete", "failed", "cancelled"];
    const status = getAppointmentStatus();
    const disabled = disablingStatues.includes(status);
    const antBtnProps = { type: "primary", disabled, icon: <CheckOutlined /> };

    return (
      <div className="button-wrap">
        <SetStatusButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
          antBtnProps={antBtnProps}
          newStatus="completed"
        >
          Set Completed
        </SetStatusButton>
      </div>
    );
  };

  const renderForceCompleteButton = () => {
    const status = get(props.appointment, "status");
    const isDoneOrSigned = status === "done" || status === "signed";
    return isDoneOrSigned ? renderSetCompleteButton() : renderSetDoneButton();
  };

  const renderMarkAsInCompleteButton = () => {
    const status = getAppointmentStatus();
    if (status === "incomplete") return null;
    return (
      <div className="button-wrap">
        <MarkAsIncompleteButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
        />
      </div>
    );
  };

  const renderResumeButton = () => {
    const status = getAppointmentStatus();
    if (status !== "incomplete") return null;
    const antBtnProps = { icon: <UndoOutlined /> };

    return (
      <div className="button-wrap">
        <SetStatusButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
          antBtnProps={antBtnProps}
          newStatus="arrive"
        >
          Resume
        </SetStatusButton>
      </div>
    );
  };

  const renderMarkAsFailButton = () => {
    const status = getAppointmentStatus();
    if (status !== "incomplete") return null;
    const antBtnProps = { icon: <CloseOutlined />, type: "danger" };

    return (
      <div className="button-wrap">
        <SetStatusButton
          appointment={props.appointment}
          onRefetchAppointment={props.onRefetchAppointment}
          antBtnProps={antBtnProps}
          newStatus="failed"
        >
          Mark As Failed
        </SetStatusButton>
      </div>
    );
  };

  return (
    <div className="appointment-action-buttons-wrap">
      {renderCancelButton()}
      {renderForceCompleteButton()}
      {renderMarkAsInCompleteButton()}
      {renderMarkAsFailButton()}
      {renderResumeButton()}
    </div>
  );
};

AppointmentActionsButtons.propTypes = {
  appointment: PropTypes.object.isRequired,
  onRefetchAppointment: PropTypes.func.isRequired,
};

export default AppointmentActionsButtons;
