import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, notification } from "antd";
import { useShipmentCsvDownload } from "../../../../hooks/api/fulfillment/shipment";

const DownloadCsvButton = (props) => {
  const { downloadedCsv, downloadShipmentCsv } = useShipmentCsvDownload();

  const onDownloadClick = async () => {
    const data = await downloadShipmentCsv(props.query);
    download("shipment.csv", data);
  };

  const download = (filename, text) => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    if (downloadedCsv.error) {
      notification.error({
        message: "Error",
        description: downloadedCsv.error,
      });
    }
  }, [downloadedCsv.error]);

  return (
    <Button
      className="upload-csv-btn"
      onClick={onDownloadClick}
      loading={downloadedCsv.loading}
    >
      Download Shipments CSV
    </Button>
  );
};

DownloadCsvButton.propTypes = {
  query: PropTypes.object.isRequired,
};

export default DownloadCsvButton;
