import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Components
import { Modal, notification, Radio,  Switch, DatePicker } from "antd";

//styles
import * as S from "./styles";

import ItemsTable from "./ItemsTable";
import DateTimeSelect from "../components/DateTimeSelect";
import CreatePackagesTable from "./CreatePackagesTable";
import AddressSelector from "./AddressSelector";
import { SelectServiceLevel } from "../components/SelectServiceLevel";

// Helpers
import { get, startCase } from "lodash-es";
import Shipment from "../../../../../lib/fulfillment/shipment";
import Organization from "../../../../../lib/organization";
import { ServiceLevel } from "@secondcloset/fulfillment-utils";

// Hooks
import { useCreateShipment } from "hooks/api/fulfillment/shipment";

const CREATE_TYPES = {
  "inventoryPickup-crossDockDelivery": {
    key: "inventoryPickup-crossDockDelivery",
    value: "Inv Pick - Cross Dock Delivery",
  },
  "warehouseReceiving-delivery": {
    key: "warehouseReceiving-delivery",
    value: "Warehouse Receiving - Delivery",
  },
  "reverseLogistics-returnToSender": {
    key: "reverseLogistics-returnToSender",
    value: "Reverse Logistic - Return To Sender",
  },
};

const { RangePicker } = DatePicker;

const CreateIkeaShipmentsModal = ({
  order,
  onRefetchOrder,
  modalVisible,
  setModalVisible,
}) => {
  const organizationID = get(order, "organization.id");
  const [
    selectedWarehouseAddressID,
    setSelectedWarehouseAddressID,
  ] = useState();
  const [selectedItemIDs, setSelectedItemIDs] = useState({});
  const [packages, setPackages] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState("09-13");
  const [createType, setCreateType] = useState(
    CREATE_TYPES["inventoryPickup-crossDockDelivery"].key
  );
  const [isRangeOfDays, setIsRangeOfDays] = useState(false);
  const [dateRange, setDateRange] = useState({
    rangeFrom: undefined,
    rangeTo: undefined,
  });
  const [selectedServiceLevel, setSelectedServiceLevel] = useState("second_closet_standard");
  const { shipment, createShipment } = useCreateShipment();
  const organization = get(order, "organization");
  const externalPlatformVersion = get(order, "external_platform_version");
  const isIKEA = Organization.isIKEA(organization);
  const isProduction = process.env.REACT_APP_ENV === "production";
  const shouldBeDisabled = !isIKEA || isProduction;
  const isIkeaV2 = externalPlatformVersion === 2;

  const error = shipment.error;

  const showError = (msg) => {
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  useEffect(() => {
    if (error) showError(error);
  }, [error]);

  if (!order) return null;

  const onCancelModal = () => {
    setModalVisible(false);
    setSelectedItemIDs({});
    setPackages([]);
    setSelectedWarehouseAddressID();
    setSelectedDate();
    setSelectedTime();
    onRefetchOrder();
  };

  const renderCreateTypeOptions = () => {
    return (
      <S.InOutBountSelect>
        <Radio.Group
          onChange={(e) => setCreateType(e.target.value)}
          value={createType}
        >
          {Object.values(CREATE_TYPES).map(({ key, value }) => {
            return (
              <Radio value={key} key="key">
                {value}
              </Radio>
            );
          })}
        </Radio.Group>
      </S.InOutBountSelect>
    );
  };

  const getFormattedPackages = () => {
    return packages.reduce((acc, pkg) => {
      const orderItemIDs = Object.keys(pkg).reduce((acc, key) => {
        if (key === "key" || key === "trackingNumber") return acc;
        return [...acc, ...pkg[key]];
      }, []);
      const packageDetails = orderItemIDs.reduce(
        (acc, orderItemID) => {
          const orderItem =
            order?.items?.find((item) => item.id === orderItemID) || {};
          const { product } = orderItem || {};
          acc.weight_value += product?.weight || 0;
          acc.length_value += product?.length || 0;
          acc.height_value += product?.height || 0;
          acc.width_value += product?.width || 0;
          return acc;
        },
        {
          tracking_number: pkg.trackingNumber,
          weight_value: 0,
          weight_unit: "lb",
          length_value: 0,
          length_unit: "in",
          height_value: 0,
          height_unit: "in",
          width_value: 0,
          width_unit: "in",
        }
      );
      return [
        ...acc,
        {
          order_item_ids: orderItemIDs,
          ...packageDetails,
        },
      ];
    }, []);
  };

  const getOrderItemIDsFromPackages = (packages) => {
    return packages.flatMap((pkg) => pkg.order_item_ids);
  };

  const createShipmentWithType = async (type, options_) => {
    let options = {
      timeSlot: selectedTime,
      serviceLevel: selectedServiceLevel,
      externalShipmentID: moment().format("hhmmssSSS"), // usually generated in by IKEA
      ...options_,
    };

    if (isRangeOfDays) {
      options = {
        ...options,
        rangeOfDaysStartDate: dateRange.rangeFrom,
        rangeOfDaysEndDate: dateRange.rangeTo,
      };
    } else {
      options = { ...options, date: selectedDate };
    }

    const body = Shipment.getCreateIkeaShipmentBody(order.id, type, options);

    if (options_.noDate) {
      delete body.date;
      delete body.time_rage;
    }

    const result = await createShipment(body);
    if (result) {
      notification.success({
        message: "Success!",
        description: `New ${startCase(type)} shipment created`,
      });
    }
  };

  const createInvPickCrossDockDeliveryPair = async () => {
    const formattedPackages = isIkeaV2
      ? getFormattedPackages(packages)
      : undefined;
    const orderItemIDs = isIkeaV2
      ? getOrderItemIDsFromPackages(formattedPackages)
      : Object.values(selectedItemIDs).flatMap((ids) => ids);

    await createShipmentWithType("inventory_pick_up", {
      orderItemIDs,
      packages: formattedPackages,
      addressID: selectedWarehouseAddressID,
      timeSlot: "07-10",
    });
    await createShipmentWithType("cross_dock_delivery", {
      orderItemIDs,
      packages: formattedPackages,
    });
  };

  const createReverseLogisticsReturnToSenderPair = async () => {
    const formattedPackages = isIkeaV2
      ? getFormattedPackages(packages)
      : undefined;
    const orderItemIDs = isIkeaV2
      ? getOrderItemIDsFromPackages(formattedPackages)
      : Object.values(selectedItemIDs).flatMap((ids) => ids);
    //create untracked delivery shipment first
    await createShipmentWithType("delivery", {
      orderItemIDs,
      untracked: true,
      consumeStock: false,
    });
    await createShipmentWithType("reverse_logistics", {
      orderItemIDs,
      packages: formattedPackages,
    });
    await createShipmentWithType("return_to_sender", {
      orderItemIDs,
      packages: formattedPackages,
      addressID: selectedWarehouseAddressID,
    });
  };

  const createWarehouseReceivingDeliveryPair = async () => {
    const formattedPackages = isIkeaV2
      ? getFormattedPackages(packages)
      : undefined;
    const orderItemIDs = isIkeaV2
      ? getOrderItemIDsFromPackages(formattedPackages)
      : Object.values(selectedItemIDs).flatMap((ids) => ids);

    await createShipmentWithType("inventory_warehouse_receiving", {
      orderItemIDs,
      packages: formattedPackages,
      addressID: selectedWarehouseAddressID,
      noDate: true,
    });
    await createShipmentWithType("delivery", {
      orderItemIDs,
      packages: formattedPackages,
    });
  };

  const onCreateShipment = async () => {
    const isDateAndTimeSelected = !selectedTime || !selectedDate;
    if (isDateAndTimeSelected && isRangeOfDays === false) {
      return showError("Please select a date and time");
    }
    if (!dateRange.rangeTo && isRangeOfDays) {
      return showError("Please select a date range");
    }

    if (!selectedWarehouseAddressID) {
      return showError("Please select a warehouse");
    }

    // if (!isIkeaV2 && isEmpty(orderItemIDs)) {
    //   return showError("Please select at least one item");
    // }

    if (isIkeaV2 && !packages.length) {
      return showError("Please add at least 1 package");
    }

    if (createType === CREATE_TYPES["inventoryPickup-crossDockDelivery"].key) {
      await createInvPickCrossDockDeliveryPair();
    } else if (
      createType === CREATE_TYPES["reverseLogistics-returnToSender"].key
    ) {
      await createReverseLogisticsReturnToSenderPair();
    } else {
      await createWarehouseReceivingDeliveryPair();
    }

    onCancelModal();
  };

  const getTitle = () => {
    return "Create Inventory Pickup and Delivery Shipments";
  };

  const renderIsRangeOfDaysSelections = () => {
    return (
      <div>
        Range of Days{" "}
        <Switch onChange={(checked) => setIsRangeOfDays(checked)} />
      </div>
    );
  };

  const renderDateRangeSelections = () => {
    const onDateRangeSelected = (datePair) => {
      // check if both dates are selected
      if (datePair.some((date) => date == null)) return;
      const DATE_FORMAT = "YYYY-MM-DD";
      setDateRange({
        rangeFrom: datePair[0].format(DATE_FORMAT),
        rangeTo: datePair[1].format(DATE_FORMAT),
      });
    };
    return (
      <S.SelectContainer>
        <S.SelectLabel>Select Delivery Date Range:</S.SelectLabel>

        <RangePicker onCalendarChange={onDateRangeSelected} />
      </S.SelectContainer>
    );
  };
  const renderItemsTable = () => {
    if (externalPlatformVersion === 2) {
      return (
        <CreatePackagesTable
          order={order}
          packages={packages}
          handleSetPackages={(p) => setPackages(p)}
        />
      );
    } else {
      return (
        <ItemsTable
          selectedItemIDs={selectedItemIDs}
          setSelectedItemIDs={setSelectedItemIDs}
          order={order}
        />
      );
    }
  };

  return (
    <Modal
      visible={modalVisible}
      onCancel={onCancelModal}
      title={getTitle()}
      onOk={onCreateShipment}
      width={600}
      confirmLoading={shipment.loading}
    >
      <S.CreateIkeaShipmentModal>
        {renderIsRangeOfDaysSelections()}
        {renderCreateTypeOptions()}
        <SelectServiceLevel serviceLevel={selectedServiceLevel} onSelectServiceLevel={setSelectedServiceLevel} isIkea/>
        <AddressSelector
          organizationID={organizationID}
          selectedWarehouseAddressID={selectedWarehouseAddressID}
          setSelectedWarehouseAddressID={setSelectedWarehouseAddressID}
          shouldBeDisabled={shouldBeDisabled}
        />
        {isRangeOfDays ? (
          renderDateRangeSelections()
        ) : (
          <DateTimeSelect
            order={order}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        )}

        {renderItemsTable()}
      </S.CreateIkeaShipmentModal>
    </Modal>
  );
};

CreateIkeaShipmentsModal.propTypes = {
  order: PropTypes.object,
  onRefetchOrder: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

export default CreateIkeaShipmentsModal;
