import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

const ErrorBox = ({ error }) => {
  if (!error) return null;
  return (
    <Alert
      className={`alert-smooth-box ${error ? "" : "disabled"}`}
      message={error}
      type="error"
      showIcon
    />
  );
};

ErrorBox.propTypes = {
  error: PropTypes.string,
};

export default ErrorBox;
