import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Checkbox, Alert, Input, InputNumber } from "antd";
import { get, isEmpty } from "lodash-es";
import Order from "lib/fulfillment/order";
import { useCreateShipment } from "hooks/api/fulfillment/shipment";

const CreateUntrackedShipmentButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [consumeStock, setConsumeStock] = useState(true);
  const [notes, setNotes] = useState("");
  const [toBeShippedItems, setToBeShippedItems] = useState({});
  const { shipment, createShipment } = useCreateShipment();

  const getMaxQuantity = (sku) => {
    const unshippedItems = Order.getUnshippedItems(props.order);
    const unshippedItem = Object.values(unshippedItems).find(
      (item) => item.sku === sku
    );
    if (!unshippedItem || !sku) return null;
    if (consumeStock) {
      return Math.min(unshippedItem.quantity, unshippedItem.availableStock);
    } else {
      return unshippedItem.quantity;
    }
  };

  const updateToBeShippedItems = () => {
    const unshippedItems = Order.getUnshippedItems(props.order);
    const updatedToBeShippedItems = Object.values(unshippedItems).map(
      (item) => {
        item.quantity = getMaxQuantity(item?.sku);
        return item;
      }
    );
    setToBeShippedItems(updatedToBeShippedItems);
  };

  useEffect(updateToBeShippedItems, [props.order, modalVisible, consumeStock]);

  const createDeliveryUntrackedShipment = async () => {
    const orderID = get(props.order, "id");
    const orderItems = Object.values(toBeShippedItems).reduce((acc, item) => {
      const itemIDs = get(item, "itemIDs", []).map((id) => ({
        id: id,
      }));
      const selectedItemIDs = itemIDs.slice(0, item.quantity);
      acc = [...acc, ...selectedItemIDs];
      return acc;
    }, []);

    const untrackedShipmentBody = {
      order_id: orderID,
      order_items: orderItems,
      untracked: true,
      consume_stock: consumeStock,
      job_type: "delivery",
    };

    if (notes.trim()) {
      untrackedShipmentBody.notes_attributes = [
        {
          text: notes,
        },
      ];
    }

    const respond = await createShipment(untrackedShipmentBody);
    if (respond) {
      setModalVisible(false);
      props.onReFetchOrder();
    }
  };

  const setQuantity = (newQuantity, id) => {
    Object.values(toBeShippedItems).map((item) => {
      if (item.id === id) {
        item.quantity = newQuantity;
      }
      return item;
    });
    const updatedToBeShippedItems = { ...toBeShippedItems };
    setToBeShippedItems(updatedToBeShippedItems);
  };

  const renderToBeShippedItemsTable = () => {
    const itemRows = Object.values(toBeShippedItems).map((item) => {
      const { name, quantity, sku, availableStock, id } = item;
      const maxInput = getMaxQuantity(sku);

      return (
        <tr key={id}>
          <td>
            <div>
              <div className="name">{name}</div>
              <div className="sku">SKU: {sku}</div>
            </div>
          </td>
          <td>
            <InputNumber
              className="input-number"
              value={quantity}
              min={0}
              max={maxInput}
              onChange={(value) => setQuantity(value, id)}
            />
            {" / "}
            {maxInput}
          </td>
          <td>
            <div className="stock">{availableStock}</div>
          </td>
        </tr>
      );
    });

    return (
      <div className="items-table">
        <table>
          <tbody>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Available Stock</th>
            </tr>
            {itemRows}
          </tbody>
        </table>
      </div>
    );
  };

  const renderShipmentNotes = () => {
    return (
      <div className="notes">
        <div className="title">Shipment Notes:</div>
        <Input.TextArea
          value={notes}
          placeholder="please enter reference/tracking number if possible"
          onChange={(e) => setNotes(e.target.value)}
          allowClear
        />
      </div>
    );
  };

  const renderError = () => {
    let error = shipment.error;

    const accumulatedQuantity = Object.values(toBeShippedItems).reduce(
      (acc, item) => {
        acc = acc + item.quantity;
        return acc;
      },
      0
    );
    if (accumulatedQuantity === 0) {
      error = "You need at least 1 item to create shipment.";
    }

    if (!error) return null;
    return (
      <Alert
        message={error}
        type="error"
        banner
        className="create-untracked-shipment-error"
      />
    );
  };

  const renderModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onOk={createDeliveryUntrackedShipment}
        onCancel={() => setModalVisible(false)}
        title="Create Untracked Shipment"
        confirmLoading={shipment.loading}
        destroyOnClose
      >
        <div className="create-untracked-shipment-modal">
          {renderError()}
          {renderToBeShippedItemsTable()}
          <Checkbox
            checked={consumeStock}
            onChange={(e) => setConsumeStock(e.target.checked)}
          >
            Consume Stock
          </Checkbox>
          {renderShipmentNotes()}
        </div>
      </Modal>
    );
  };

  if (isEmpty(toBeShippedItems)) return null;

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
        Create Untracked Delivery Shipment
      </Button>
      {renderModal()}
    </>
  );
};

CreateUntrackedShipmentButton.propTypes = {
  order: PropTypes.object,
  onReFetchOrder: PropTypes.func.isRequired,
};

export default CreateUntrackedShipmentButton;
