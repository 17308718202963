import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";
import { Empty } from "antd";

const OrderItems = (props) => {
  const { items } = props.order.data;
  const hasItems = !isEmpty(items);

  const renderItem = (item) => {
    const { name, price } = item;
    return (
      <div className="item">
        <div className="name">{name}</div>
        <div className="unit-price">
          Unit Price: ${(price / 100).toFixed(2)}{" "}
        </div>
      </div>
    );
  };

  const renderItemRow = (item) => {
    const { quantity, price } = item;
    const total = (quantity * price) / 100;
    return (
      <div key={item.item_id} className="item-row">
        <div className="item-row-cell">{renderItem(item)}</div>
        <div className="item-row-cell">Qty: {quantity}</div>
        <div className="item-row-cell">${total.toFixed(2)}</div>
      </div>
    );
  };

  const renderSummary = () => {
    const { total, subtotal, coupon, tax_percentage } = props.order.data;
    const taxes = subtotal * (tax_percentage / 100);
    const formatPrice = (p) => `$${(p / 100).toFixed(2)}`;
    let couponString;

    if (coupon) {
      const { amount_off, percent_off } = coupon;
      if (amount_off) couponString = `- ${formatPrice(amount_off)}`;
      else couponString = `${percent_off}% off`;
    }

    return (
      <div className="summary">
        <div className="price-label-value">
          <div className="price-label">Subtotal:</div>
          <div className="value">{formatPrice(subtotal)}</div>
        </div>
        {couponString && (
          <div className="price-label-value">
            <div className="price-label">Coupon:</div>
            <div className="value">{couponString}</div>
          </div>
        )}
        <div className="price-label-value">
          <div className="price-label">Taxes:</div>
          <div className="value">{formatPrice(taxes)}</div>
        </div>
        <div className="price-label-value total">
          <div className="price-label">Total:</div>
          <div className="value">{formatPrice(total)}</div>
        </div>
      </div>
    );
  };

  const isSameItem = (item1, item2) => {
    if (!item1.category_id || !item2.category_id) {
      return item1.stripe_id === item2.stripe_id;
    }

    return item1.category_id === item2.category_id;
  };

  const compressedItemsByStripeID = items.reduce((acc, cv) => {
    const foundItems = acc.find((i) => isSameItem(i, cv));
    if (cv.type === "One-off") cv.name += " (Product)";
    if (!foundItems) acc.push(cv);
    else foundItems.quantity += cv.quantity;
    return acc;
  }, []);

  return (
    <div className="order-items card">
      <div className="title">Order Items</div>
      {hasItems &&
        compressedItemsByStripeID.map((item) => {
          return renderItemRow(item);
        })}
      {hasItems && renderSummary()}
      {!hasItems && <Empty />}
    </div>
  );
};

OrderItems.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderItems;
