import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  useClosetIndexWithDetails,
  useItemPlanCloset,
} from "../../../hooks/api/regular/closets";
import { useInventoryIndex } from "../../../hooks/api/regular/inventory";
import useMount from "../../../hooks/useMount";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin, Alert, Tabs, Collapse, Empty, Button, Input } from "antd";
import { isEmpty, toLower, startCase, cloneDeep } from "lodash-es";
import moment from "moment";

const ReturnCustomerClosetCard = (props) => {
  const { userID } = props.match.params;
  const {
    closetIndexWithDetails,
    fetchClosetDetails,
  } = useClosetIndexWithDetails(userID);
  const { inventoryIndex, fetchInventoryIndex } = useInventoryIndex();
  const { itemPlanCloset, fetchItemPlanCloset } = useItemPlanCloset();
  const [selectedClosetID, setSelectedClosetID] = useState("all-items");
  const [closetSearch, setClosetSearch] = useState("");

  useMount(() => {
    fetchInventoryIndex(userID);
  });

  const { loading: loadingClosets } = closetIndexWithDetails;
  const { loading: loadingInventory } = inventoryIndex;

  if (loadingClosets || loadingInventory) {
    return (
      <div className="customer-closet-card">
        <Spin className="spinner" tip="fetching inventory..." />
      </div>
    );
  }

  const buildErrors = () => {
    const { error: closetsError } = closetIndexWithDetails;
    const { error: inventoryError } = inventoryIndex;
    const errors = [inventoryError, closetsError];
    if (!isEmpty) {
      return (
        <Alert
          message={errors.map((e) => (
            <p key={e}>{e}</p>
          ))}
          type="error"
        />
      );
    }

    return null;
  };

  const buildClosetSearch = () => {
    return (
      <div className="closet-search">
        <Input
          value={closetSearch}
          onChange={(e) => setClosetSearch(e.target.value)}
          suffix={<SearchOutlined />}
          placeholder="search closet names"
        />
      </div>
    );
  };

  const onClosetSelect = (closetID) => {
    setSelectedClosetID(closetID);
    switch (closetID) {
      case "all-items":
        return;
      case "item-plan": {
        if (!!itemPlanCloset.data) return;
        return fetchItemPlanCloset(userID);
      }
      default: {
        const targetCloset = closetIndexWithDetails.data.find(
          (c) => c.id === closetID
        );
        const itemFetched = !!targetCloset.details;
        if (itemFetched) return;
        return fetchClosetDetails(closetID);
      }
    }
  };

  const buildTabs = () => {
    const { data: closets } = closetIndexWithDetails;
    if (isEmpty(closets)) return null;
    const tabs = closets
      .filter((closet) => {
        const closetName = closet.closet_name || closet.name;
        return closetName.toLowerCase().includes(closetSearch.toLowerCase());
      })
      .map((closet) => {
        const { id, name, closet_name } = closet;
        return <Tabs.TabPane tab={closet_name || name} key={id} />;
      });

    return (
      <Tabs activeKey={selectedClosetID} onChange={onClosetSelect}>
        {(closetSearch.toLowerCase() === "all" || !closetSearch) && (
          <Tabs.TabPane tab="All" key="all-items" />
        )}
        {tabs}
        {!closetSearch && <Tabs.TabPane tab="Item Plan" key="item-plan" />}
      </Tabs>
    );
  };

  const onReturnItem = (item) => {
    const returnCart = cloneDeep(props.returnCart);
    returnCart.push(cloneDeep(item));
    return props.setReturnCart(returnCart);
  };

  const buildItemRows = (items) => {
    const rows = items.map((item) => {
      const { images, qr_code, id, nickname, created_at } = item;
      const imageUrl = isEmpty(images) ? null : images[0].url;
      const createdAt = created_at ? moment(created_at).format("ll") : "-";
      return (
        <div
          className="return-item-row"
          onClick={() => onReturnItem(item)}
          key={id}
        >
          <div>{imageUrl ? <img src={imageUrl} alt={imageUrl} /> : "-"}</div>
          <div>{id.slice(-4)}</div>
          <div>{qr_code || "-"}</div>
          <div>{nickname || "-"}</div>
          <div>{createdAt || "-"}</div>

          <div className="return-icon">
            <span>Return</span>
            <PlusOutlined />
          </div>
        </div>
      );
    });
    return (
      <div className="return-item-table">
        <div className="table-header">
          <div>Image</div>
          <div>ID (last four)</div>
          <div>QR code</div>
          <div>Nickname</div>
          <div>Created At</div>
        </div>
        {rows}
      </div>
    );
  };

  const getItemDisplayName = (item) => {
    const { stripe_plan_id, category_item } = item;
    const categoryName = category_item ? category_item.name : null;
    let displayName = categoryName || stripe_plan_id;
    displayName = startCase(toLower(displayName.replace(/_/g, " ")));
    return displayName;
  };

  const getReturnableClosetItems = () => {
    const { data: itemPlanInventory } = itemPlanCloset;
    const { data: closets } = closetIndexWithDetails;
    const { data: inventory } = inventoryIndex;
    let items;
    switch (selectedClosetID) {
      case "all-items":
        items = inventory;
        break;
      case "item-plan":
        items = itemPlanInventory;
        break;
      default: {
        const targetCloset = closets.find((c) => c.id === selectedClosetID);
        items = targetCloset.details.items;
      }
    }

    const returnableItems = items?.filter((i) => {
      const isInStorage = i.status === "in_storage";
      const notInReturnCart = !props.returnCart.find(
        (item) => item.id === i.id
      );
      return isInStorage && notInReturnCart;
    });

    return returnableItems || [];
  };

  const onReturnAllClick = () => {
    const closetItems = getReturnableClosetItems();
    const returnCart = cloneDeep(props.returnCart).concat(
      cloneDeep(closetItems)
    );
    return props.setReturnCart(returnCart);
  };

  const buildCloset = (items) => {
    if (isEmpty(items)) return <Empty description="No Returnable Items" />;

    const groupedItems = items.reduce((acc, cv) => {
      let displayName = getItemDisplayName(cv);
      if (!acc[displayName]) acc[displayName] = [cv];
      else acc[displayName].push(cv);
      return acc;
    }, {});

    const panels = Object.keys(groupedItems)
      .sort()
      .map((displayName) => {
        const targetItems = groupedItems[displayName];
        return (
          <Collapse.Panel
            header={`${displayName} x ${targetItems.length}`}
            key={displayName}
          >
            {buildItemRows(targetItems)}
          </Collapse.Panel>
        );
      });

    return (
      <>
        <Button onClick={onReturnAllClick} className="return-all-button">
          Return All
        </Button>
        <Collapse>{panels}</Collapse>
      </>
    );
  };

  const buildItems = () => {
    const { loading: loadingItemPlanInventory } = itemPlanCloset;
    const { loadingSingleCloset } = closetIndexWithDetails;

    if (loadingItemPlanInventory || loadingSingleCloset)
      return (
        <div className="spinner">
          <Spin tip="fetching items..." />
        </div>
      );

    return buildCloset(getReturnableClosetItems());
  };

  const onRestoreItem = (item) => {
    const returnCart = cloneDeep(props.returnCart);
    const targetIndex = returnCart.find((i) => i.id === item.id);
    returnCart.splice(targetIndex, 1);
    return props.setReturnCart(returnCart);
  };

  const buildRegretZone = () => {
    if (isEmpty(props.returnCart)) return null;
    const items = props.returnCart.map((item) => {
      return (
        <div className="return-item" key={item.id + "id"}>
          <div>{getItemDisplayName(item)}</div>
          <div>{item.qr_code || <i>no qr code</i>}</div>
          <button onClick={() => onRestoreItem(item)}>Restore</button>
        </div>
      );
    });

    return (
      <div className="regret-zone">
        <div className="title">
          Regret Zone{" "}
          <Button size="small" onClick={() => props.setReturnCart([])}>
            Clear
          </Button>
        </div>
        <div className="zone-scroll">
          <div className="return-items">{items}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="customer-closet-card">
      <div className="title">{"Customer's Closet"}</div>
      {buildErrors()}
      {buildClosetSearch()}
      {buildTabs(closetIndexWithDetails)}
      {buildItems()}
      {buildRegretZone()}
    </div>
  );
};

ReturnCustomerClosetCard.propTypes = {
  match: PropTypes.object.isRequired,
  returnCart: PropTypes.array.isRequired,
  setReturnCart: PropTypes.func.isRequired,
};

export default withRouter(ReturnCustomerClosetCard);
