import React, { useEffect, useState } from "react";

// components
import { Modal, Spin } from "antd";
import { UploadBillOfLading } from "@secondcloset/web-components";

// styles
import * as S from "./styles";

interface Props {
  isVisible?: boolean;
  onClose: () => any;
  onSubmit: (file: File, bolNumber: string, trackingNumber?: string) => any;
  isLoading?: boolean;
  shid: string;
}

const UploadBillOfLadingModal: React.FC<Props> = ({
  isVisible,
  onClose,
  onSubmit,
  isLoading,
  shid,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [bolNumber, setBolNumber] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");

  useEffect(() => {
    if (isVisible) {
      setUploadedFile(undefined);
      setBolNumber("");
      setTrackingNumber("");
    }
  }, [isVisible]);

  const isSubmitDisabled = !uploadedFile || !bolNumber || isLoading;

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <S.Container direction="vertical" size="large">
        <S.HeaderTitle>Upload Bill of Lading</S.HeaderTitle>
        <S.SHID>{shid}</S.SHID>
        <Spin spinning={isLoading}>
          <UploadBillOfLading
            uploadedFile={uploadedFile}
            bolNumber={bolNumber}
            trackingNumber={trackingNumber}
            setUploadedFile={setUploadedFile}
            setBolNumber={setBolNumber}
            setTrackingNumber={setTrackingNumber}
          />
        </Spin>
        <S.YellowButton
          isDisabled={isSubmitDisabled}
          onClick={
            isSubmitDisabled
              ? () => {}
              : () => onSubmit(uploadedFile as File, bolNumber, trackingNumber)
          }
        >
          UPLOAD
        </S.YellowButton>
      </S.Container>
    </Modal>
  );
};

export default UploadBillOfLadingModal;
