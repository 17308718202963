import { useState } from "react";
import { getAPIFromDomain } from "lib/api";
import queryString from "query-string";
import { PackagingLevel } from "interfaces/products";

const usePackagingLevels = () => {
  const [packagingLevels, setPackagingLevels] = useState<{
    loading: boolean;
    data: PackagingLevel[] | null;
    error: string | null;
  }>({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const fetchPackagingLevels = (query: {
    q: string;
    organization_id: string;
    product_type?: "base" | "kit";
  }): Promise<PackagingLevel[]> => {
    const url = `/product_packaging_levels?${queryString.stringify(query)}`;

    setPackagingLevels({ ...packagingLevels, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setPackagingLevels({ data: res.data, loading: false, error: null });
        return res.data;
      })
      .catch((error) => {
        setPackagingLevels({ ...packagingLevels, loading: false, error });
      });
  };

  const clearPackagingLevels = () => {
    setPackagingLevels({
      loading: false,
      data: null,
      error: null,
    });
  };

  return { packagingLevels, fetchPackagingLevels, clearPackagingLevels };
};

export { usePackagingLevels };
