import React, { useEffect } from "react";
import queryString from "query-string";

// Assets
import ShopifyLogo from "../../../../public/Icons/shopify.svg";

// Components
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Spin, Alert } from "antd";

// Hooks
import { useLocation } from "react-router";
import { useShopifyInstall } from "../../../../hooks/integrations/shopify";

const CompleteShopifyInstallPage = () => {
  const location = useLocation();
  const { installStatus, install } = useShopifyInstall();
  const localStorage = window.localStorage;
  const organizationID = localStorage.getItem("install_store_organizationID");

  useEffect(() => {
    const oauthParams = queryString.parse(location.search);
    install(oauthParams, organizationID);
    // eslint-disable-next-line
  }, []);

  const renderInstallProgress = () => {
    if (installStatus.loading) {
      return (
        <Spin
          style={{ margin: "0 auto", width: "100%" }}
          tip="Syncing orders and completing installation"
        />
      );
    }
  };

  const renderInstallComplete = () => {
    const { error } = installStatus;

    if (error) {
      if (installStatus.data) {
        return (
          <Alert
            className="alert-smooth-box"
            message="Installation failed"
            description={error}
            type="error"
            showIcon
            banner
          />
        );
      }

      return (
        <Alert
          className="alert-smooth-box"
          message="Installation completed with errors"
          description={error}
          type="warning"
          showIcon
          banner
        />
      );
    }

    return (
      <Alert
        className="alert-smooth-box"
        message="Installation complete"
        description={
          <p>
            Orders successfully synced.{" "}
            <Link to={`/organizations/${organizationID}/orders`}>
              View my orders
            </Link>
          </p>
        }
        type="success"
        showIcon
        banner
      />
    );
  };

  const fullPage = { span: 24 };
  const halfPage = { span: 12, offset: 6 };
  const thirdOfPage = { span: 10, offset: 7 };
  const COLUMN_PROPS = {
    xs: fullPage,
    sm: fullPage,
    md: halfPage,
    lg: thirdOfPage,
    xl: thirdOfPage,
  };

  return (
    <div className="page-container complete-shopify-install-page">
      <Row>
        <Col {...COLUMN_PROPS}>
          <img alt="Shopify Logo" className="shopify-logo" src={ShopifyLogo} />
        </Col>
      </Row>
      <Row>
        <Col {...COLUMN_PROPS}>
          {renderInstallProgress()}
          {renderInstallComplete()}
          <Divider />
          <Link to="/organizations" className="inl-flex-row back-button">
            <ArrowLeftOutlined />
            <p>Shopify Configuration</p>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default CompleteShopifyInstallPage;
