import React, { useState } from "react";
import PropTypes from "prop-types";
import { PictureOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import ImageBox from "./ImageBox";
import Cloudinary from "../../../../../lib/Cloudinary";
import { isEmpty } from "lodash-es";

const GridView = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  if (!props.items) return null;

  const buildModal = () => {
    if (!selectedItem) return null;
    const url = getImageUrl(selectedItem);
    return (
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <div className="image-wrapper" key={url}>
          {url ? (
            <img src={Cloudinary.getGravityAutoImage(url)} alt={url} />
          ) : (
            <PictureOutlined className="no-image-icon" />
          )}
        </div>
      </Modal>
    );
  };

  const getImageUrl = (item) => {
    const { images } = item;
    if (isEmpty(images)) return "";
    else {
      const lastImage = images[images.length - 1];
      const url = lastImage && lastImage.url;
      return Cloudinary.getResizedImage(url, 200, 200);
    }
  };

  return (
    <div className="inventory-grid">
      {props.items.map((item) => {
        return (
          <div
            className="image-box-wrapper"
            key={item.item_id || item.id}
            onClick={() => {
              setSelectedItem(item);
              setModalVisible(true);
            }}
          >
            <ImageBox imageUrl={getImageUrl(item)} name={item.name} />
          </div>
        );
      })}
      {buildModal()}
    </div>
  );
};

GridView.propTypes = {
  items: PropTypes.array.isRequired,
};

export default GridView;
