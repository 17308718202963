import { getAPIFromDomain } from "../lib/api";
import { toast } from "react-toastify";
import { FETCH_CUSTOMER_DETAILS, CLEAR_CUSTOMER_DETAILS } from "./types";

import { getErrorMsg } from "./actionHelperFunctions";

export function fetchCustomerDetails(id, cb = () => {}) {
  const api = getAPIFromDomain();
  const url = `/users/${id}`;
  return (dispatch) => {
    api
      .get(url)
      .then((response) => {
        // successful response from the server.
        dispatch({ type: FETCH_CUSTOMER_DETAILS, payload: response });
        cb(null);
      })
      .catch((error) => {
        // something went wrong with the request
        const message = getErrorMsg(error);
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
        cb(message);
      });
  };
}

export function clearCustomerDetails() {
  return {
    type: CLEAR_CUSTOMER_DETAILS,
    payload: null,
  };
}
