import React from "react";

// Components
import {
  SearchResultWrapper,
  Section,
  SectionText,
} from "./SearchResult.styles";
import { Product } from "../../../../../../interfaces/products";
import colors from "css/colors";

interface Props {
  searchResult: Product;
  handleOnSelect: (searchResult: Product) => void;
  width?: number;
  reverse?: boolean;
}

const SearchResult: React.FC<Props> = ({
  searchResult,
  handleOnSelect,
  width,
  reverse,
}) => {
  const { name, description, sku, scid } = searchResult;
  return (
    <SearchResultWrapper
      onClick={() => handleOnSelect(searchResult)}
      width={width}
      reverse={reverse}
    >
      <Section flex={2} column>
        <SectionText color={colors.CHARCOAL}>{name}</SectionText>
        <SectionText color={colors.GREY}>
          {description ? description : "No description"}
        </SectionText>
      </Section>
      <Section flex={1}>
        <SectionText>{sku}</SectionText>
      </Section>
      <Section flex={1}>
        <SectionText>{scid}</SectionText>
      </Section>
    </SearchResultWrapper>
  );
};

export default SearchResult;
