import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { fetchFacilityIndex, Facility } from "../../../api/common/facility";
import { Common } from "@secondcloset/types";

type UseFacilityIndexOptions = UseQueryOptions<Facility[], Error>;
export const useFacilityIndex = (options?: UseFacilityIndexOptions) =>
  useQuery(["facilityIndex"], fetchFacilityIndex, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });

/**
 * Return value for v1 and v2 are slightly different
 * This hook is for backwards compatibility
 */
export const useFacilitiesV2ToV1Adaptor = (facilities: Facility[]) =>
  useMemo(() => {
    return (facilities.map((facility) => {
      const { address, ...rest } = facility;
      return { ...rest, address: { table: address } };
    }) as unknown) as Common.Facility[];
  }, [facilities]);
