import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { Elements } from "react-stripe-elements";
import StripeElement from "./StripeElement";
import { useCustomerBilling } from "../../../../hooks/api/regular/customer";

const UpdateBillingButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { addCreditCard, reset, customerBilling } = useCustomerBilling();

  const onMount = () => {
    reset();
  };

  const onCustomerBillingUpdate = () => {
    if (customerBilling.data) {
      setModalVisible(false);
      props.onUpdateBillingSuccess();
    }
  };

  useEffect(onCustomerBillingUpdate, [customerBilling]);
  useEffect(onMount, []);

  const buildModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        destroyOnClose
        afterClose={reset}
      >
        <Elements>
          <StripeElement
            onCancel={() => {
              setModalVisible(false);
              reset();
            }}
            onAddCreditCard={(stripe) => addCreditCard(stripe, props.userID)}
            loading={customerBilling.loading}
            error={customerBilling.error}
          />
        </Elements>
      </Modal>
    );
  };

  const renderButton = () => {
    const onClick = () => setModalVisible(true);
    if (props.children) return React.cloneElement(props.children, { onClick });
    const btnPrefix = props.hasCreditCardOnFile ? "Update" : "Add";
    return (
      <Button onClick={onClick}>
        {`${btnPrefix} Card`}
        <EditOutlined />
      </Button>
    );
  };

  return (
    <div>
      {renderButton()}
      {buildModal()}
    </div>
  );
};

UpdateBillingButton.propTypes = {
  userID: PropTypes.string.isRequired,
  onUpdateBillingSuccess: PropTypes.func.isRequired,
  children: PropTypes.node,
  hasCreditCardOnFile: PropTypes.bool,
};

export default UpdateBillingButton;
