import { getAPIFromDomain } from "lib/api";

/**
 * @description Call to request a token be emailed to the account for password reset
 * @param {string} email The email used to sign up for the account in question
 * @param {function} cb A callback function, as defined by the caller
 */
export function requestPasswordReset(email, cb = () => {}) {
  const api = getAPIFromDomain();
  const url = `/users/reset_password/request`;
  const body = {
    user: {
      email: email,
    },
  };

  api
    .post(url, body)
    .then((response) => {
      // successful response from the server.
      cb(null, response.data);
    })
    .catch((error) => {
      // something went wrong with the request
      cb(error, null);
    });
}
