import { Fulfillment, Common } from "@secondcloset/types";

import moment from "moment";

import { APIRequestConfig, getAPIFromDomain } from "../../lib/api";
type FacilityCode = Common.FacilityCode;
type Order = Fulfillment.Order;
type CustomerAddress = Fulfillment.CustomerAddress;
type Address = Fulfillment.Order["address"];
export interface FetchOrderIndexQueryOption {
  q?: string;
  page?: number;
  per_page?: number;
  cancelled?: boolean;
  field?: any;
  direction?: string;
  organizations?: string[];
  date_from?: string;
  date_to?: string;
  stock_status?: Order["stock_status"][];
  organization_id?: string;
  tracking_number?: string;
  external_order_number?: string;
  shipment_tags_values?: Order["shipment_tags"];
  shipment_tags_condition?: string;
}

interface MetaData {
  total_entries: number;
  current_page: number;
  total_pages: number;
  count: number;
}

interface FulfillmentCustomer extends Partial<CustomerAddress> {
  id: string;
  user_id: string;
  customer_address: {
    id: string;
  } & Partial<Address>;
}

export const uploadOrderCSV = async (
  organization_id: string,
  orderDetailsCSV?: File,
  orderItemsCSV?: File,
  config?: APIRequestConfig
) => {
  const api = getAPIFromDomain("fulfillment");
  const formData = new FormData();
  const url = `/orders/csv_upload`;
  formData.set("orders_csv", orderDetailsCSV || "");
  formData.set("order_items_csv", orderItemsCSV || "");
  formData.set("organization_id", organization_id || "");
  const res = await api.post(url, formData, config);
  return res.data;
};

export const bulkUpdateOrders = async (body: {
  order_ids: string[];
  shipment_tag?: Fulfillment.ShipmentTag;
  fulfilled_from?: FacilityCode;
}) => {
  const api = getAPIFromDomain("fulfillment");
  const url = "/orders/bulk_update";
  const response = await api.put(url, body);
  return response.data;
};

export const customerUpdate = async ({
  orderID,
  body,
}: {
  orderID: string;
  body: {
    propagate_to_shipments: boolean;
    fulfillment_customer: FulfillmentCustomer;
  };
}) => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}/customer_update`;
  const res = await api.put(url, body);
  return res.data;
};

export const fetchOrderIndex = async (
  query: FetchOrderIndexQueryOption
): Promise<{
  data: Order[];
  meta: MetaData;
}> => {
  const { date_from, date_to, ...rest } = query;
  const startOfDayString = !!date_from ? moment(date_from)?.startOf("day").format("YYYY-MM-DD HH:mm Z") : undefined;
  const endOfDayDateString = !!date_to ? moment(date_to)?.endOf("day").format("YYYY-MM-DD HH:mm Z") : undefined;

  const api = getAPIFromDomain("fulfillment", 2);
  const url = "/orders/index";
  const formattedQuery = {
    ...rest,
    date_from: startOfDayString,
    date_to: endOfDayDateString,
  };
  const res = await api.get(url, {
    params: formattedQuery,
  });
  return res.data;
};

export const fetchOrderDetails = async (orderID: string): Promise<Fulfillment.Order> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}`;
  const res = await api.get(url);
  return res.data;
};

export const cancelOrder = async (orderID: string) => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}/cancel`;
  const res = await api.put(url, null);
  return res.data;
};

export const createOrder = async (body: any): Promise<Order> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/create`;
  const res = await api.post(url, body);
  return res.data;
};

export const updateOrder = async ({ orderID, body }: { orderID: string; body: any }) => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}`;
  const res = await api.put(url, body);
  return res.data;
};

export const deleteOrder = async (orderID: string) => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}/delete`;
  const res = await api.put(url, {});
  return res.data;
};

interface OrderLogsQuery {
  id: string;
  page?: number;
  per_page?: number;
  event?: Fulfillment.ActivityHistory["event"];
}

export const fetchOrderLogs = async (q: OrderLogsQuery): Promise<Fulfillment.ActivityHistory[]> => {
  const { id: orderID, ...query } = q;
  const api = getAPIFromDomain("fulfillment");
  const res = await api.get(`/orders/${orderID}/logs`, { params: query });
  return res.data;
};
