import React, { useState, useEffect } from "react";

// Components
import { Table } from "antd";
import { Quantity } from "./ItemSummaryTable.styles";

// Constants
import colors from "css/colors";

// Interfaces
import { ItemSummaryTableRowData } from "../OrderItemsSummaryTable/OrderItemsSummaryTable";
import { Order } from "interfaces/warehouseReceivingOrders";

interface Props {
  order: Order;
  data: ItemSummaryTableRowData[];
}

const ItemSummaryTable: React.FC<Props> = ({ order, data }) => {
  const [originalQuantities, setOriginalQuantites] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const quantitiesMap = data.reduce((acc, item) => {
      acc[item.id] = item.quantity;
      return acc;
    }, {} as { [key: string]: number });
    setOriginalQuantites(quantitiesMap);
    // eslint-disable-next-line
  }, [order]);

  const buildColumns = () => {
    const renderQuantity = (
      quantity: number,
      record: ItemSummaryTableRowData
    ) => (
      <Quantity
        color={
          quantity > originalQuantities[record.id] ||
          originalQuantities[record.id] === undefined
            ? colors.GREEN
            : quantity < originalQuantities[record.id]
            ? colors.RED
            : colors.CHARCOAL
        }
      >
        {quantity}
      </Quantity>
    );

    return [
      {
        title: "Qty",
        dataIndex: "quantity",
        key: "quantity",
        render: renderQuantity,
      },
      {
        title: "Product Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
      },
      {
        title: "SCID",
        dataIndex: "scid",
        key: "scid",
      },
    ];
  };

  return (
    <Table
      dataSource={data}
      columns={buildColumns()}
      pagination={false}
      rowKey="sku"
    />
  );
};

export default ItemSummaryTable;
