import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppointmentTasks } from "../../../../../hooks/api/regular/appointment";
import useMount from "../../../../../hooks/useMount";
import { CopyOutlined } from "@ant-design/icons";
import {
  Spin,
  Empty,
  Alert,
  Input,
  Modal,
  Drawer,
  Button,
  message,
} from "antd";
import { isEmpty } from "lodash-es";
import reduxStore from "../../../../../config/reduxStore";
import moment from "moment";
import DotButtonDropdown from "../DotButtonDropdown";

const VIEW_MODES = {
  view: "view",
  edit: "edit",
  new: "new",
};

const AppointmentTasksButton = (props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODES.view);
  const [task, setTask] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [updatingTaskID, setUpdatingTaskID] = useState(null);
  const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    appointmentTasks,
    fetchAppointmentTasks,
    createAppointmentTask,
    updateAppointmentTask,
    deleteAppointmentTask,
  } = useAppointmentTasks();

  useMount(() => {
    fetchAppointmentTasks(props.apptID);
  });

  const getFullName = () => {
    const {
      auth: {
        user: { first_name, last_name },
      },
    } = reduxStore.getState();

    return `${first_name} ${last_name}`;
  };

  const buildHeader = () => {
    let text, buttons;
    switch (viewMode) {
      case VIEW_MODES.edit:
        text = "EDIT TASK";
        buttons = (
          <>
            <button
              className="tasks-button-grey"
              onClick={() => setViewMode(VIEW_MODES.view)}
            >
              CANCEL
            </button>
            <button
              className="tasks-button-blue"
              onClick={() => {
                setLoading(true);
                updateAppointmentTask(props.apptID, updatingTaskID, {
                  notes: task,
                }).then((res) => {
                  if (res) {
                    setViewMode(VIEW_MODES.view);
                    message.success("update success");
                  }
                  setLoading(false);
                });
              }}
            >
              SAVE
            </button>
          </>
        );
        break;
      case VIEW_MODES.new:
        text = "NEW TASK";
        buttons = (
          <>
            <button
              className="tasks-button-grey"
              onClick={() => setViewMode(VIEW_MODES.view)}
            >
              CANCEL
            </button>
            <button
              className="tasks-button-blue"
              onClick={() => {
                setLoading(true);
                createAppointmentTask(props.apptID, {
                  notes: task,
                  task_type: "custom",
                  status: "to_do",
                }).then((res) => {
                  if (res) {
                    setViewMode(VIEW_MODES.view);
                    message.success("update success");
                  }
                  setLoading(false);
                });
              }}
            >
              SAVE
            </button>
          </>
        );
        break;
      case VIEW_MODES.view:
      default:
        text = "TASKS";
        buttons = (
          <button
            className="tasks-button-blue"
            onClick={() => {
              setViewMode(VIEW_MODES.new);
              setTask("");
            }}
          >
            ADD NEW
          </button>
        );
    }

    return (
      <div className="tasks-header">
        <div className="header-text">{text}</div>
        <div className="header-buttons">{buttons}</div>
      </div>
    );
  };

  const buildTask = (task) => {
    const options = ["edit", "delete"];
    const taskAuthor = `${task?.assignee?.first_name} ${task?.assignee?.last_name}`;
    const {
      auth: {
        user: { id },
      },
    } = reduxStore.getState();
    const isAuthor = id === task?.assignee?.id;
    return (
      <div className="appointment-task" key={task?.id}>
        <div className="action-button">
          <DotButtonDropdown
            options={options}
            disabled={!isAuthor}
            disabledText="You can only modify tasks that you created."
            onRowClick={(option) => {
              setUpdatingTaskID(task?.id);
              if (option === options[0]) {
                setViewMode(VIEW_MODES.edit);
                setTask(task?.notes);
              } else setModalVisible(true);
            }}
          />
        </div>
        <div className="task">{task?.notes}</div>
        <div className="task-footer">
          <div className="name-time">
            <span>{taskAuthor}</span>{" "}
            {moment(task?.updated_at).format("ll h:mm a")}
          </div>
          <div className="word-count">
            {task?.notes?.length}
            /1000
          </div>
        </div>
      </div>
    );
  };

  const buildTasksList = () => {
    const { data, loading } = appointmentTasks;
    if (loading) {
      return (
        <div className="appointment-tasks-list spinning">
          <Spin tip="fetching tasks..." />
        </div>
      );
    }

    const validData = data?.filter((task) => !!task.notes);

    const rows = isEmpty(validData) ? (
      <div className="no-data">
        <Empty
          description={
            <div className="no-data-text">
              Currently there are no tasks created. Create one by clicking ADD
              NEW button above.
            </div>
          }
        />
      </div>
    ) : (
      validData.map((task) => buildTask(task))
    );

    return <div className="appointment-tasks-list spinning">{rows}</div>;
  };

  const buildNewTask = () => {
    return (
      <div className="new-task">
        <Input.TextArea
          maxLength="1000"
          value={task}
          onChange={(e) => setTask(e.target.value)}
          placeholder="Type your tasks here"
          rows={15}
        />
        <div className="task-footer">
          <div className="name-time">
            <span>{getFullName()}</span> {moment().format("ll h:mm a")}
          </div>
          <div className="word-count">
            {task.length}
            /1000
          </div>
        </div>
      </div>
    );
  };

  const buildContent = () => {
    switch (viewMode) {
      case VIEW_MODES.edit:
      case VIEW_MODES.new:
        return buildNewTask();
      case VIEW_MODES.view:
      default:
        return buildTasksList();
    }
  };

  const buildModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          setModalVisible(false);
          deleteAppointmentTask(props.apptID, updatingTaskID);
        }}
      >
        <div>Are you sure you want to delete this task?</div>
      </Modal>
    );
  };

  const buildDrawer = () => {
    return (
      <Drawer
        placement="left"
        onClose={() => setTaskDrawerVisible(false)}
        visible={taskDrawerVisible}
        width={360}
      >
        <Spin spinning={loading} tip="Loading...">
          <div className="appointment-tasks-drawer">
            {buildHeader()}
            {appointmentTasks.error && (
              <Alert message={appointmentTasks.error} type="error" />
            )}
            {buildContent()}
            {buildModal()}
          </div>
        </Spin>
      </Drawer>
    );
  };

  const buildTaskCounter = () => {
    const { data } = appointmentTasks;
    const validData = data ? data.filter((t) => !!t.notes) : [];
    if (isEmpty(validData)) return null;
    return <div className="task-counter">{validData.length}</div>;
  };

  return (
    <div className="appointment-tasks-button">
      {buildTaskCounter()}
      {buildDrawer()}
      <Button onClick={() => setTaskDrawerVisible(true)}>
        Tasks <CopyOutlined />
      </Button>
    </div>
  );
};

AppointmentTasksButton.propTypes = {
  apptID: PropTypes.string.isRequired,
};

export default AppointmentTasksButton;
