import { useState, useRef } from "react";
import { get } from "lodash-es";
import { getAPIFromDomain, getPDFRequestConfig } from "../../../lib/api";
import Shipment from "../../../lib/fulfillment/shipment";
import Appointment from "../../../lib/regular/appointment";
import queryString from "query-string";
import moment from "moment";

const useShipmentRates = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("fulfillment");

  const fetchShipmentRates = (
    orderID,
    {
      order_item_ids,
      outbound = true,
      shipToAddressID,
      use_original_package = false,
      include_default_carriers,
      packages,
    }
  ) => {
    const url = `/orders/${orderID}/shipping_rates`;
    const body = {
      order_item_ids,
      job_type: outbound ? "delivery" : "reverse_logistics",
      ship_to_address_id: shipToAddressID,
      use_original_package,
      include_default_carriers,
    };
    if (packages?.length) body.packages = packages;

    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const shipmentRates = { data, loading, error };
  const resetShipmentRate = () => {
    setData(null);
  };

  return { shipmentRates, fetchShipmentRates, resetShipmentRate };
};

const useCreateShipment = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("fulfillment");

  const createShipment = (body) => {
    const url = `/shipments`;
    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const createShipmentsWithOrderIDs = (orderIDs) => {
    const url = `/shipments/batch_create`;
    setLoading(true);
    setError(null);
    return api
      .post(url, { order_ids: orderIDs })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const shipment = { data, loading, error };
  return { shipment, createShipment, createShipmentsWithOrderIDs };
};

const useScheduleShipment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const scheduleShipment = (shipmentID, date, time) => {
    const url = `/shipments/${shipmentID}/schedule_appointment`;
    const body = {
      date,
      time_range: time,
    };

    setLoading(true);
    setError(null);

    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const resetScheduleShipment = () => {
    setData(null);
    setLoading(false);
    setError(null);
  };

  const shipmentDetails = { data, loading, error };

  return { shipmentDetails, scheduleShipment, resetScheduleShipment };
};

const useSendBookingEmail = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const sendBookingEmail = (shipmentID) => {
    const url = `/shipments/${shipmentID}/send_booking_email`;
    const body = {};
    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const emailStatus = { data, loading, error };

  return { emailStatus, sendBookingEmail };
};

const useShipmentAvailabilities = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const fetchShipmentAvailabilities = (options) => {
    const url = "/shipments/availabilities";
    setLoading(true);
    setError(null);
    return api
      .post(url, options)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const shipmentAvailabilities = { data, loading, error };

  return { shipmentAvailabilities, fetchShipmentAvailabilities };
};

const useCompleteShipment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getShipmentItemsForCompletion = (shipment, jobType) => {
    if (jobType === "inventory_warehouse_receiving") {
      return [];
    }
    const shipmentItems = get(shipment, "shipment_items", []).map((item) => {
      return { id: item.id, completed_actions: item.shipment_actions };
    });

    return shipmentItems;
  };

  const completeAppointment = (shipment) => {
    const isSecondCloset = Shipment.isSecondCloset(shipment);
    const api = getAPIFromDomain("fulfillment");
    const shipmentID = get(shipment, "id");
    const apptStatus = get(shipment, "shipping_method.status");
    const jobType = get(shipment, "shipping_method.job_type");
    const shipmentItems = getShipmentItemsForCompletion(shipment, jobType);
    const setDoneUrl = `/shipments/${shipmentID}/done`;
    const setCompletedUrl = `/shipments/${shipmentID}/complete`;
    const setWithCarrierUrl = `/shipments/${shipmentID}/with_carrier`;
    const currentTime = moment().format();
    const setDoneBody = {
      start_time: currentTime,
      end_time: currentTime,
      num_movers: 0,
      waive_moving_fee: true,
      shipment_items: shipmentItems,
    };
    const setDone = () => api.put(setDoneUrl, setDoneBody);
    const setComplete = () => api.put(setCompletedUrl, {});
    const setWithCarrier = () =>
      api.put(setWithCarrierUrl, { shipment_items: shipmentItems });
    if (!isSecondCloset) return setWithCarrier();
    if (apptStatus === "done" || apptStatus === "signed") return setComplete();
    else return setDone().then(setComplete);
  };

  const completeShipment = (shipment) => {
    setLoading(true);
    setError(null);
    return completeAppointment(shipment)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const completeShipmentStatus = { data, loading, error };
  return { completeShipmentStatus, completeShipment };
};

const useCancelShipment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");
  const cancelShipment = (shipmentID) => {
    const url = `/shipments/${shipmentID}/cancel`;
    setError(null);
    setLoading(true);
    return api
      .put(url, {})
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const cancelShipmentStatus = { data, loading, error };
  return { cancelShipmentStatus, cancelShipment };
};

const useUpdateShipment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("logistics");

  const updateShipment = (shipmentID, body) => {
    const url = `/shipments/${shipmentID}`;
    setLoading(true);
    setError(null);
    return api
      .put(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const deleteFreightPackage = (shipmentID, packageID) => {
    const url = `/shipments/${shipmentID}/delete_freight_package`;
    setLoading(true);
    setError(null);
    return api
      .post(url, { package_id: packageID })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const updateShipmentStatus = { data, loading, error };
  return { updateShipmentStatus, updateShipment, deleteFreightPackage };
};

const useShipmentDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const updateShipmentStatus = (shipmentID, status, options) => {
    const action = Appointment.getAppointmentActionFromStatus(status);
    const url = `/shipments/${shipmentID}/${action}`;
    const body = options;
    setLoading(true);
    setError(null);
    return api
      .put(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const shipmentDetails = { data, loading, error };

  return {
    shipmentDetails,
    updateShipmentStatus,
  };
};

const useShipmentIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const lastPromise = useRef();

  const api = getAPIFromDomain("logistics");

  const fetchShipmentIndex = (q) => {
    const query = queryString.stringify(q, { arrayFormat: "bracket" });
    const url = `/shipments?${query}`;

    setLoading(true);
    setError(null);
    const currentPromise = api
      .get(url)
      .then((res) => {
        if (currentPromise === lastPromise.current) {
          setData(res.data);
          setLoading(false);
          return res.data;
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return null;
      });
    lastPromise.current = currentPromise;
    return currentPromise;
  };

  const shipmentIndex = { data, loading, error };

  return { shipmentIndex, fetchShipmentIndex };
};

const useShipmentCsvUpload = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("logistics");

  const uploadShipmentCsv = (file) => {
    setLoading(true);
    setError(null);
    const url = `/shipments/bulk_update`;
    const formData = new FormData();
    formData.set("file", file);

    return api
      .post(url, formData)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return null;
      });
  };
  const uploadedCsv = { data, loading, error };
  return { uploadedCsv, uploadShipmentCsv };
};

const useShipmentCsvDownload = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("logistics");

  const downloadShipmentCsv = (options) => {
    setLoading(true);
    setError(null);
    const query = queryString.stringify(options, { arrayFormat: "bracket" });
    const url = `/shipments.csv?${query}`;

    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return null;
      });
  };

  const downloadedCsv = { data, loading, error };
  return { downloadedCsv, downloadShipmentCsv };
};

const useVoidShippingLabels = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const api = getAPIFromDomain("logistics");

  const voidShippingLabels = (shipmentID, shippingLabelIDs) => {
    const url = `/shipments/${shipmentID}/void_shipping_labels`;
    const body = {
      shipping_label_ids: shippingLabelIDs,
    };
    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const voidShippingLabelsStatus = { data, loading, error };
  return { voidShippingLabelsStatus, voidShippingLabels };
};

const useShipmentPickSheet = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadPickSheetFile = (shipmentNumbers) => {
    const api = getAPIFromDomain("fulfillment");
    const url = `/shipments/pick_sheet.pdf`;
    setLoading(true);
    setError(null);
    return api
      .post(
        url,
        { shipment_numbers: shipmentNumbers },
        {
          ...getPDFRequestConfig(),
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        const newBlob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        const prefix =
          shipmentNumbers.length === 1 ? shipmentNumbers[0] : "shipments";
        link.href = url;
        link.download = `${prefix}-pick-sheet.pdf`;
        link.dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        setLoading(false);
        setData(res.data);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const downloadPickSheetFileV2 = (shipmentIds) => {
    const api = getAPIFromDomain("november");
    const url = `/pick-sheets/generate-pick-sheets`;
    setLoading(true);
    setError(null);
    return api
      .post(
        url,
        { shipment_ids: shipmentIds },
        {
          ...getPDFRequestConfig(),
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        const newBlob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        const suffix = shipmentIds.length === 1 ? shipmentIds[0] : "shipments";
        link.href = url;
        link.download = `pick-sheet-${suffix}.pdf`;
        link.dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        setLoading(false);
        setData(res.data);
        return true;
      })
      .catch((error) => {
        if (shipmentIds.length === 1) {
          api
            .get(`/picksheets/get-picksheet/${shipmentIds[0]}`, {
              ...getPDFRequestConfig(),
              headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
              const newBlob = new Blob([res.data], { type: "application/pdf" });
              const url = window.URL.createObjectURL(newBlob);
              const link = document.createElement("a");
              const suffix =
                shipmentIds.length === 1 ? shipmentIds[0] : "shipments";
              link.href = url;
              link.download = `pick-sheet-${suffix}.pdf`;
              link.dispatchEvent(
                new MouseEvent(`click`, {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
              setLoading(false);
              setData(res.data);
              return true;
            })
            .catch((error) => {
              setLoading(false);
              setError(error);
              return false;
            });
        }
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const pickSheet = { data, loading, error };
  return { pickSheet, downloadPickSheetFile, downloadPickSheetFileV2 };
};

const useUpdateItemStatus = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("logistics");

  const updateItemStatus = (shipmentID, body) => {
    const url = `/shipments/${shipmentID}/complete_shipment_actions`;
    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const updateItemStatusData = { data, loading, error };
  return { updateItemStatusData, updateItemStatus };
};

export {
  useShipmentRates,
  useCreateShipment,
  useScheduleShipment,
  useSendBookingEmail,
  useShipmentAvailabilities,
  useCompleteShipment,
  useCancelShipment,
  useUpdateShipment,
  useShipmentDetails,
  useShipmentIndex,
  useShipmentCsvUpload,
  useShipmentCsvDownload,
  useVoidShippingLabels,
  useShipmentPickSheet,
  useUpdateItemStatus,
};
