import React from "react";
import PropTypes from "prop-types";

const ItemColor = ({ name, hexCode }) => {
  return (
    <div className="inventory-item-color-tag inl-flex-row">
      <div className="square" style={{ backgroundColor: hexCode }} />
      <p>{name}</p>
    </div>
  );
};

ItemColor.propTypes = {
  name: PropTypes.string.isRequired,
  hexCode: PropTypes.string.isRequired,
};

export default ItemColor;
