import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

const getQueryValues = (search: string) => {
  return queryString.parse(search);
};

const useUrlState = (options: { name: string; initialValue?: any }) => {
  const { name, initialValue } = options;
  const location = useLocation();
  const history = useHistory();
  let initialValueFromUrl;
  const queryValues = getQueryValues(location.search);
  const v = queryValues[name] as string;
  try {
    initialValueFromUrl = JSON.parse(v);
  } catch (e) {
    // return e
  }

  const [value, setValue] = useState(initialValueFromUrl || initialValue);

  // Update the url search param on state update
  useEffect(() => {
    const newQueryValues = getQueryValues(location.search);
    if (value !== undefined && value !== null) {
      newQueryValues[name] = JSON.stringify(value);
    }
    const query = queryString.stringify(newQueryValues);
    history.replace(`${location.pathname}?${query}`);
    // eslint-disable-next-line
  }, [value]);

  return [value, setValue];
};

export default useUrlState;
