import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Spin, notification, Empty, Modal } from "antd";
import {
  EditButton,
  EditButtonText,
  Wrapper,
  Row,
  InputWrapper,
  Label,
  Input,
  Phone,
  Name,
} from "./CustomerAddresses.styles";
import NewAddressButton from "../../../../components/regular/_common/address/NewAddressButton";

// Helpers
import { isEmpty } from "lodash-es";
import Address from "../../../../lib/regular/address";

// Hooks
import { useCustomerAddresses } from "../../../../hooks/api/regular/customer";
import { EditOutlined } from "@ant-design/icons";

const CustomerAddresses = (props) => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const {
    customerAddresses,
    fetchCustomerAddresses,
    createAddress,
    updateCustomerAddress,
  } = useCustomerAddresses();

  const getAddresses = () => {
    fetchCustomerAddresses(props.userID);
  };

  const onError = () => {
    if (customerAddresses.error) {
      notification.error({
        message: "Error",
        description: customerAddresses.error,
      });
    }
  };

  useEffect(getAddresses, []);
  useEffect(onError, [customerAddresses]);

  const handleUpdateAddress = async (address) => {
    await updateCustomerAddress(
      props.userID,
      address.id,
      fullName,
      extension ? `${phoneNumber} x${extension}` : phoneNumber
    );
    setShowEditAddressModal(false);
  };

  const renderEditAddressModal = (address) => (
    <Modal
      title="Edit Address"
      visible={showEditAddressModal}
      onOk={() => handleUpdateAddress(address)}
      onCancel={() => setShowEditAddressModal(false)}
      width={666}
    >
      <Wrapper>
        <Row>
          <InputWrapper>
            <Label>Recipient Name:</Label>
            <Input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              width={360}
            />
          </InputWrapper>
        </Row>

        <Row>
          <InputWrapper>
            <Label>Address:</Label>
            <Input
              disabled
              value={Address.getAddressLines(address).join(", ")}
              width={420}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Unit #:</Label>
            <Input disabled value={address.apartment_number} width={60} />
          </InputWrapper>
        </Row>

        <Row>
          <InputWrapper>
            <Label>Phone Number:</Label>
            <Phone
              value={phoneNumber}
              onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
              country="ca"
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Extension:</Label>
            <Input
              value={extension}
              onChange={(event) => setExtension(event.target.value)}
              placeholder="Ext."
            />
          </InputWrapper>
        </Row>
      </Wrapper>
    </Modal>
  );

  const renderAddress = (address) => {
    const { id, full_name, phone_number } = address;
    const addressLines = Address.getAddressLines(address);

    return (
      <div className="address-block" key={address.id}>
        <Name>{full_name}</Name>
        {addressLines.map((line) => (
          <div key={line + id}>{line}</div>
        ))}
        <div>{phone_number}</div>
        <EditButton
          onClick={() => {
            const phoneNumberParts = phone_number.split(" x");
            setFullName(full_name);
            setPhoneNumber(phoneNumberParts[0]);
            setExtension(phoneNumberParts[1] || "");
            setShowEditAddressModal(true);
          }}
        >
          <EditButtonText>Edit</EditButtonText> <EditOutlined />
        </EditButton>
        {renderEditAddressModal(address)}
      </div>
    );
  };

  return (
    <Spin tip="Fetching addresses..." spinning={customerAddresses.loading}>
      <div className="customer-addresses">
        <div className="address-header">
          <div className="title">Addresses</div>
          {customerAddresses.loading ? (
            <Spin />
          ) : (
            <NewAddressButton
              createAddress={(address) => createAddress(props.userID, address)}
              createAddressError={customerAddresses.error}
            />
          )}
        </div>
        <div className="address-blocks">
          {isEmpty(customerAddresses.data) ? (
            <Empty description="No addresses" className="no-address" />
          ) : (
            customerAddresses.data.map((address) => renderAddress(address))
          )}
        </div>
      </div>
    </Spin>
  );
};

CustomerAddresses.propTypes = {
  userID: PropTypes.string.isRequired,
};

export default CustomerAddresses;
