import { stringify } from "query-string";
import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const useCategoryIndex = () => {
  const [categoryIndex, setCategoryIndex] = useState({
    data: null,
    loading: true,
    error: false,
  });

  const fetchCategoryIndex = (query) => {
    setCategoryIndex({ ...categoryIndex, loading: true, error: null });
    const urlBuilder = new UrlBuilder("/category_items");
    const url = urlBuilder.applyQueryStringFromObject(query);
    const api = getAPIFromDomain();
    return api
      .get(url)
      .then((res) => {
        setCategoryIndex({
          data: res.data.category_items,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setCategoryIndex({ ...categoryIndex, loading: false, error });
        return false;
      });
  };

  const updateCategoryItem = (categoryID, body) => {
    setCategoryIndex({ ...categoryIndex, loading: true, error: null });
    const url = `/category_items/${categoryID}`;
    const api = getAPIFromDomain();
    return api
      .put(url, body)
      .then(() => {
        fetchCategoryIndex();
        return true;
      })
      .catch((error) => {
        setCategoryIndex({ ...categoryIndex, loading: false, error });
        return false;
      });
  };

  const createCategoryItem = (body) => {
    setCategoryIndex({ ...categoryIndex, loading: true, error: null });
    const url = `/category_items`;
    const api = getAPIFromDomain();
    return api
      .post(url, body)
      .then(() => {
        fetchCategoryIndex();
        return true;
      })
      .catch((error) => {
        setCategoryIndex({ ...categoryIndex, loading: false, error });
        return false;
      });
  };

  const setCategoryIndexLoading = (loading) => {
    setCategoryIndex({ ...categoryIndex, loading });
  };

  return {
    categoryIndex,
    fetchCategoryIndex,
    updateCategoryItem,
    createCategoryItem,
    setCategoryIndexLoading,
  };
};

const useCategoryLookup = () => {
  const [categoryList, setCategoryList] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const searchCategory = (searchString) => {
    if (!searchString) {
      setCategoryList({ data: null, loading: false, error: null });
      return;
    }
    setCategoryList({ ...categoryList, loading: true, error: null });
    const api = getAPIFromDomain();
    const queryString = stringify({ q: searchString });
    const url = `/category_items/lookup?${queryString || {}}`;

    return api
      .get(url)
      .then((res) => {
        setCategoryList({
          data: res.data.category_items,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setCategoryList({ ...categoryList, loading: false, error });
        return false;
      });
  };

  return { categoryList, searchCategory };
};

export { useCategoryLookup, useCategoryIndex };
