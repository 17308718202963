import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import { notification } from "antd";

const useOrder = () => {
  const [order, setOrder] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchOrder = (userID, orderID) => {
    const url = `/users/${userID}/orders/${orderID}`;
    return api
      .get(url)
      .then((res) => {
        setOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setOrder({ ...order, loading: false, error });
        return false;
      });
  };

  const createOrder = (userID, body) => {
    setOrder({ ...order, loading: true, error: null });
    const url = `/users/${userID}/orders`;
    return api
      .post(url, body)
      .then((res) => {
        setOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setOrder({ ...order, loading: false, error });
        return false;
      });
  };

  const previewOrder = (userID, body) => {
    setOrder({ ...order, loading: true, error: null });
    const url = `/users/${userID}/orders/preview`;
    return api
      .post(url, body)
      .then((res) => {
        setOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setOrder({ ...order, loading: false, error });
        return false;
      });
  };

  const updateOrder = (userID, orderID, body) => {
    setOrder({ ...order, loading: true, error: null });
    const url = `/users/${userID}/orders/${orderID}`;
    return api
      .put(url, body)
      .then((res) => {
        setOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setOrder({ ...order, loading: false, error });
        return false;
      });
  };

  const cancelOrder = (userID, orderID) => {
    setOrder({ ...order, loading: true, error: null });
    const url = `/users/${userID}/orders/${orderID}/cancel?skip_cancellation_fee=true`;
    return api
      .post(url)
      .then((res) => {
        setOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setOrder({ ...order, loading: false, error });
        return false;
      });
  };

  const reset = () => setOrder({ data: null, loading: false, error: null });

  return {
    order,
    fetchOrder,
    createOrder,
    previewOrder,
    updateOrder,
    cancelOrder,
    reset,
  };
};

const useReturnOrder = () => {
  const [returnOrder, setReturnOrder] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const chargeReturnOrder = (userID, orderID) => {
    const url = `/users/${userID}/orders/${orderID}/charge`;
    return api
      .post(url, null)
      .then((res) => {
        notification.success({
          message: "Order charged",
          description: "charged successfully",
          duration: 3,
        });
        setReturnOrder({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        notification.error({
          message: "Order charge failed",
          description: error,
          duration: null,
        });
        setReturnOrder({ ...returnOrder, loading: false, error });
        return true;
      });
  };

  const createReturnOrder = (userID, body, skipFee = false) => {
    setReturnOrder({ ...returnOrder, loading: true, error: null });
    const url = `/users/${userID}/orders/return?skip_fee=${skipFee}`;
    return api
      .post(url, body)
      .then((res) => {
        notification.success({
          message: "Order created",
          description: "create successfully",
          duration: 3,
        });
        const orderID = res.data.id;
        return chargeReturnOrder(userID, orderID);
      })
      .catch((error) => {
        setReturnOrder({ ...returnOrder, loading: false, error });
        return false;
      });
  };

  const previewReturnOrder = (userID, body) => {
    setReturnOrder({ ...returnOrder, loading: true, error: null });
    const url = `/users/${userID}/orders/return/preview`;
    return api
      .post(url, body)
      .then((res) =>
        setReturnOrder({ data: res.data, loading: false, error: null })
      )
      .catch((error) =>
        setReturnOrder({ ...returnOrder, loading: false, error })
      );
  };

  const reset = () =>
    setReturnOrder({ data: null, loading: false, error: null });

  return { returnOrder, createReturnOrder, previewReturnOrder, reset };
};

export { useReturnOrder, useOrder };
