import { useState } from "react";
import { getAPIFromDomain } from "../../lib/api";
import UrlBuilder from "../../lib/UrlBuilder";

const useShopifyStoreIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const fetchStores = (query) => {
    setLoading(true);
    setError(null);
    const api = getAPIFromDomain("shopify");
    const urlBuilder = new UrlBuilder("/shops");
    const url = urlBuilder.applyQueryStringFromObject(query);

    return api
      .get(url)
      .then((res) => {
        setData(res.data.shops);
        setLoading(false);
        return true;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return false;
      });
  };

  const storeIndex = { data, loading, error };
  return { storeIndex, fetchStores };
};

const useShopifySyncOrder = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const syncOrder = (shopifyDomain, orderID, organizationID) => {
    const api = getAPIFromDomain("shopify");
    const url = "/orders/sync";

    const body = {
      shopify_domain: shopifyDomain,
      id: +orderID,
      organization_id: organizationID,
    };

    setLoading(true);
    setError(null);

    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return false;
      });
  };

  const syncOrderDetails = { data, loading, error };
  return { syncOrderDetails, syncOrder };
};

const useShopifyInstallLink = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const generateInstallLink = (shopifyDomain) => {
    const api = getAPIFromDomain("shopify");
    const url = `/shops/new`;

    const location = window.location;
    const port = location.port ? `:${location.port}` : "";
    const currentUrl = `${location.protocol}//${location.hostname}${port}`;
    const redirect = currentUrl + "/configuration/shopify/complete-install";
    const body = {
      shopify_domain: shopifyDomain,
      redirect_uri: redirect,
    };

    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const installLink = { data, loading, error };
  return { installLink, generateInstallLink };
};

const useShopifyInstall = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const install = (opts, organizationID) => {
    const api = getAPIFromDomain("shopify");
    const url = `/shops`;
    const body = (({ hmac, code, shop, host, timestamp }) => ({
      hmac,
      code,
      shop,
      host,
      timestamp,
    }))(opts);
    if (organizationID) body.organization_id = organizationID;

    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const installStatus = { data, loading, error };
  return { installStatus, install };
};

const useShopifyShopSync = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const syncShop = (name, organizationID) => {
    const api = getAPIFromDomain("shopify");
    const url = `/shops/sync`;
    const body = { shopify_domain: name, organization_id: organizationID };

    return api
      .post(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const shopSync = { data, loading, error };
  return { shopSync, syncShop };
};

export {
  useShopifyStoreIndex,
  useShopifySyncOrder,
  useShopifyInstallLink,
  useShopifyInstall,
  useShopifyShopSync,
};
