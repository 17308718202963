import { parse, stringify, ParseOptions } from "query-string";
import { useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

export interface Options {
  navigateMode?: "push" | "replace";
}

const parseConfig = {
  skipNull: true,
  skipEmptyString: true,
  parseNumbers: true,
  parseBooleans: true,
  arrayFormat: "comma",
} as ParseOptions;
interface UrlState {
  [key: string]: any;
}

export const useURLStateV2 = <S extends UrlState = UrlState>(
  initialState?: S | (() => S),
  options?: Options
) => {
  type State = Partial<{ [key in keyof S]: any }>;
  const { navigateMode = "push" } = options || {};
  const location = useLocation();
  const history = useHistory();

  const update = useState(false)[1];

  const initialStateRef = useRef(
    typeof initialState === "function"
      ? (initialState as () => S)()
      : initialState || {}
  ).current as State;

  const queryFromUrl = useMemo(() => {
    return parse(location.search, parseConfig);
  }, [location.search]);

  const targetQuery: State = useMemo(
    () => ({
      ...initialStateRef,
      ...Object.entries(queryFromUrl).reduce(
        (acc, [name, value]) => ({
          ...acc,
          [name]: Array.isArray(initialStateRef[name as string])
            ? [value].flat()
            : value,
        }),
        {}
      ),
    }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [queryFromUrl]
  );

  const setState = (s: React.SetStateAction<State>) => {
    const newQuery = typeof s === "function" ? (s as Function)(targetQuery) : s;
    update((v) => !v);
    history[navigateMode]({
      hash: location.hash,
      search: stringify({ ...queryFromUrl, ...newQuery }, parseConfig) || "?",
    });
  };

  return [targetQuery, setState] as const;
};

export default useURLStateV2;
