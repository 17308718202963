import React, { useEffect, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Popover, Alert, Tag } from "antd";
import { getAppointmentColor } from "../../../helperFunctions";
import { toLower, startCase } from "lodash-es";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Availabilities } from "@secondcloset/logistics-utils";
import withFacility from "../../../hoc/withFacility";
import TablePagination from "../../../components/_common/TablePagination";
import { SearchInput } from "@secondcloset/web-components";
import useURLStateV2 from "../../../hooks/application/useURLStateV2";
import { useQuery } from "react-query";
import { fetchAppointmentIndex } from "../../../api/storage/appointment";

const PAGE_SIZE = 15;

const AppointmentsPage = (props) => {
  const [urlState, setUrlState] = useURLStateV2({
    search: "",
    page: 1,
  });
  const queryOptions = useMemo(() => {
    return {
      field: "created_at",
      direction: "DESC",
      per_page: PAGE_SIZE,
      page: urlState.page,
      service_area: props.serviceArea,
      ...(urlState.search && { q: String(urlState.search).trim() }),
    };
  }, [urlState, props.serviceArea]);

  const appointmentIndex = useQuery(
    ["appointmentIndex", queryOptions],
    () => fetchAppointmentIndex(queryOptions),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    // reset to page 1 every time search changes
    setUrlState((state) => ({ ...state, page: 1 }));
    // eslint-disable-next-line
  }, [urlState.search, props.serviceArea]);

  const buildSearchBar = () => {
    return (
      <Popover
        placement="bottomLeft"
        title="You Can Search"
        content={
          <div className="appointments-popover-list">
            <p>Customer Name</p>
            <p>Address</p>
            <p>Appointment #</p>
            <p>Appointment Type</p>
            <p>Appointment Status</p>
          </div>
        }
        trigger="focus"
      >
        <SearchInput
          placeholder="Search..."
          defaultValue={urlState.search}
          onSearch={(search) => setUrlState((state) => ({ ...state, search }))}
          onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
          enterButton
        />
      </Popover>
    );
  };

  const buildColumns = () => {
    const renderApptNum = (text, record) => {
      const path = `/appointments/${record.id}`;
      return <Link to={path}>{text}</Link>;
    };
    const renderStatus = (status) => (
      <Tag color={getAppointmentColor("status", status)}>
        {startCase(toLower(status))}
      </Tag>
    );
    const renderType = (type) => (
      <Tag color={getAppointmentColor("type", type)}>
        {startCase(toLower(type))}
      </Tag>
    );
    const renderDate = (date) => (date ? moment(date).format("ll") : "-");
    const renderCustomerName = (id, record) => (
      <Link
        to={`/customers/${record.user_id}`}
      >{`${record.user_firstname} ${record.user_lastname}`}</Link>
    );

    const renderOrderID = (id, record) => {
      const isDelivery = record.type === "fulfillment";
      const orderID = isDelivery ? record.source.id : id;
      const path = isDelivery
        ? `/fulfillment/orders/${record.source.id}`
        : `/orders/${id}/user/${record.user_id}`;
      return <Link to={path}>{orderID ? "..." + orderID.slice(-4) : "-"}</Link>;
    };

    const renderETC = (time) => {
      return time ? `${Availabilities.msToMin(time)}min` : "-";
    };

    return [
      {
        title: "Appointment #",
        dataIndex: "number",
        key: "number",
        render: renderApptNum,
        width: 120,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: renderStatus,
      },
      {
        title: "Type",
        dataIndex: "job_type",
        key: "job_type",
        render: renderType,
        width: 150,
      },
      { title: "Date", dataIndex: "date", key: "date", render: renderDate },
      {
        title: "Timeslot",
        dataIndex: "formatted_timeslot",
        key: "formatted_timeslot",
      },
      {
        title: "Customer",
        dataIndex: "id",
        key: "customer_name",
        render: renderCustomerName,
        width: 200,
        ellipsis: true,
      },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        render: renderOrderID,
      },
      {
        title: "ETC",
        dataIndex: "estimated_time",
        key: "estimated_time",
        render: renderETC,
      },
    ];
  };

  const buildTable = () => {
    return (
      <div className="appointments-table">
        <Table
          loading={
            appointmentIndex.isLoading || appointmentIndex.isPreviousData
          }
          columns={buildColumns()}
          dataSource={appointmentIndex.data || []}
          size="small"
          pagination={false}
          scroll={{ x: 1000 }}
          rowKey={(record) => record.id}
        />
        <TablePagination
          data={appointmentIndex.data}
          page={urlState.page}
          setPage={(page) => setUrlState((state) => ({ ...state, page }))}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  };

  return (
    <div className="page-container">
      <Helmet title="Sierra - Appointments" />
      {appointmentIndex.error && (
        <Alert message={appointmentIndex.error} type="error" />
      )}
      <div className="appointments" id="appointment-index-page">
        <h1>Appointments</h1>
        {buildSearchBar()}
        {buildTable()}
      </div>
    </div>
  );
};

AppointmentsPage.propTypes = {
  serviceArea: PropTypes.string.isRequired,
};

export default withRouter(withFacility(AppointmentsPage));
