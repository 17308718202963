/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useCustomerAddresses } from "../../../../hooks/api/regular/customer";
import { useAppointmentAvailability } from "../../../../hooks/api/logistics/availabilities";
import { useAppointment } from "../../../../hooks/api/regular/appointment";
import { Typography } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Modal,
  Select,
  Button,
  DatePicker,
  Alert,
  Spin,
  notification,
} from "antd";
import Address from "../../../../lib/regular/address";
import {
  AppointmentsAvailability,
  AvailabilitySlot,
} from "@secondcloset/logistics-utils";
import { isSupportEscalated } from "../../../../helperFunctions/user";
import useUser from "../../../../hooks/reduxContainer/_common/useUser";

import moment from "moment";
import { startCase } from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NewCustomAppointmentButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const customerSegment = props.customer_segment || "business";
  const [newDate, setNewDate] = useState(null);
  const [newTime, setNewTime] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedSubtype, setSelectedSubtype] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const { customerAddresses, fetchCustomerAddresses } = useCustomerAddresses();
  const [availabilityInstance, setAvailabilityInstance] = useState(null);
  const {
    appointmentAvailability,
    fetchAvailableDates,
  } = useAppointmentAvailability("storage");
  const { appointment, createAppointment } = useAppointment();
  const { user } = useUser();

  const role = user?.role || null;
  const subrole = user?.subrole || null;

  const isAdmin = isSupportEscalated(role, subrole);

  const error =
    appointmentAvailability.error ||
    appointment.error ||
    customerAddresses.error;

  useEffect(() => {
    if (error) notification.error({ message: error });
  }, [error]);

  const onMount = () => {
    const { userID } = props;
    fetchCustomerAddresses(userID);
  };

  const clearError = () => {
    if (submitError) setSubmitError(null);
  };

  const re_fetchAvailability = () => {
    if (selectedAddress) {
      const customer_segment = customerSegment;
      const address_id = selectedAddress.id;
      const today = moment().format("YYYY-MM-DD");
      const dateTo = moment().add(90, "days").format("YYYY-MM-DD");

      const options = {
        date_from: today,
        date_to: dateTo,
        customer_segment,
        address_id,
      };
      fetchAvailableDates(options);
    }
    setNewDate(null);
    setNewTime(null);
    clearError();
  };

  const onAppointmentCreateSuccess = () => {
    if (appointment.data) {
      const newAppointmentID = appointment.data.id;
      props.history.push(`/appointments/${newAppointmentID}`);
    }
  };

  const onAvailabilityChange = () => {
    if (appointmentAvailability.data) {
      const instance = new AppointmentsAvailability(
        appointmentAvailability.data,
        {
          isAdmin,
          serviceArea: selectedAddress?.service_area || "yyz",
        }
      );
      setAvailabilityInstance(instance);
    }
  };

  useEffect(clearError, [newDate, newTime, selectedAddress]);
  useEffect(re_fetchAvailability, [customerSegment, selectedAddress]);
  useEffect(onAppointmentCreateSuccess, [appointment.data]);
  useEffect(onAvailabilityChange, [appointmentAvailability.data]);

  const buildCustomerSegmentSelect = () => {
    return (
      <div className="appointment-customer-segment-select">
        <label>Customer Segment:</label>
        <Typography.Text>{startCase(customerSegment)}</Typography.Text>
      </div>
    );
  };

  const buildDatePicker = () => {
    const { data, loading } = appointmentAvailability;

    const isDateDisabled = (date) => {
      const currentDate = date && date.format("YYYY-MM-DD");
      return (
        availabilityInstance &&
        availabilityInstance.isDateOneDisabled(currentDate)
      );
    };

    return (
      <div className="appointment-date">
        <label>Date:</label>
        <Spin spinning={loading} indicator={antIcon}>
          <DatePicker
            value={newDate ? moment(newDate) : null}
            disabledDate={isDateDisabled}
            onChange={(date) => {
              setNewDate(date ? date.format("YYYY-MM-DD") : null);
              setNewTime(undefined);
            }}
            disabled={!data}
            className="new-appointment-dropdown"
          />
        </Spin>
      </div>
    );
  };

  const buildTimeSelect = () => {
    const { data, loading } = appointmentAvailability;
    const slots = availabilityInstance
      ? availabilityInstance.getTimeslots(newDate)
      : [];
    const isSlotDisabled = (timeslot) =>
      availabilityInstance
        ? availabilityInstance.isSlotOneDisabled(newDate, timeslot)
        : true;
    const currentValue = {
      key: newTime || "----------",
      label: AvailabilitySlot.getDisplaySlot(newTime) || "----------",
    };
    return (
      <div className="appointment-time">
        <label>Timeslot:</label>
        <Spin spinning={loading} indicator={antIcon}>
          <Select
            onChange={(labeledValue) => setNewTime(labeledValue.key)}
            value={currentValue}
            disabled={!data}
            className="new-appointment-dropdown"
            labelInValue={true}
          >
            {slots.map((s) => {
              const timeslot = s.timeslot;
              return (
                <Select.Option
                  key={timeslot}
                  value={timeslot}
                  disabled={isSlotDisabled(timeslot)}
                >
                  {AvailabilitySlot.getDisplaySlot(timeslot)}
                </Select.Option>
              );
            })}
          </Select>
        </Spin>
      </div>
    );
  };

  const buildAddressSelect = () => {
    const { data, loading } = customerAddresses;
    const validAddresses = data || [];

    const selectAddress = (addressID) => {
      const addr = validAddresses.find((a) => a.id === addressID);
      setSelectedAddress(addr);
    };
    return (
      <div className="appointment-address">
        <label>Address:</label>
        <Select
          onChange={selectAddress}
          value={
            selectedAddress
              ? Address.getOneLineAddress(selectedAddress)
              : "----------"
          }
          disabled={!data || loading}
          className="new-appointment-dropdown"
        >
          {validAddresses.map((address) => (
            <Select.Option value={address.id} key={address.id}>
              {Address.getOneLineAddress(address)}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const buildSubtypeSelect = () => {
    const subtypeOptions = [
      {
        label: "Mid Mile Pick Up",
        value: "mid_mile_pick_up",
      },
      {
        label: "Mid Mile Drop Off",
        value: "mid_mile_drop_off",
      },
      {
        label: "Other",
        value: "other",
      },
    ];

    return (
      <div className="appointment-subtype">
        <label>Appointment Type:</label>
        <Select
          onChange={(value) => setSelectedSubtype(value)}
          value={selectedSubtype || "----------"}
          className="new-appointment-dropdown"
        >
          {subtypeOptions.map((subtype) => (
            <Select.Option value={subtype.value} key={subtype.value}>
              {subtype.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const reset = () => {
    setNewDate(null);
    setNewTime(null);
    setSelectedAddress(null);
    setSubmitError(null);
    setSelectedSubtype(null);
  };

  const onCreateAppointment = () => {
    if (!selectedAddress) return setSubmitError("Please select address");
    if (!newDate) return setSubmitError("Please select date");
    if (!newTime) return setSubmitError("Please select time");
    if (!selectedSubtype)
      return setSubmitError("Please select an appointment type");

    const body = {
      appointment: {
        status: "request_received",
        job_type: "custom",
        date: newDate,
        time_range: newTime,
        customer_segment: customerSegment,
        address_id: selectedAddress.id,
        user_id: props.userID,
      },
    };

    body.appointment.job_subtype =
      selectedSubtype === "other" ? null : selectedSubtype;

    if (props.orderID) body.appointment.order_id = props.orderID;
    createAppointment(body);
  };

  const buildModal = () => {
    const error = submitError || appointment.error;
    return (
      <Modal
        title="Create Custom Appointment"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          reset();
        }}
        onOk={() => onCreateAppointment()}
      >
        <Spin tip="creating appointment..." spinning={appointment.loading}>
          <div className="new-appointment-modal">
            {error && <Alert message={error} type="error" showIcon />}
            <div className="modal-row">
              {buildCustomerSegmentSelect()}
              {buildAddressSelect()}
            </div>
            <div className="modal-row">
              {buildDatePicker()}
              {buildTimeSelect()}
            </div>
            <div className="modal-row">{buildSubtypeSelect()}</div>
          </div>
        </Spin>
      </Modal>
    );
  };

  return (
    <div className="new-appointment-button">
      {buildModal()}
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          setModalVisible(true);
          onMount();
        }}
      >
        Custom
      </Button>
    </div>
  );
};

NewCustomAppointmentButton.propTypes = {
  userID: PropTypes.string.isRequired,
  orderID: PropTypes.string,
  customerSegment: PropTypes.string,
  onCreateSuccess: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(NewCustomAppointmentButton);
