import React from "react";
import PropTypes from "prop-types";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";

const ViewToggleButton = (props) => {
  return (
    <div className="view-toggle">
      <BarsOutlined
        className={`box-view-icon ${props.isListView ? "selected" : ""}`}
        onClick={() => props.setIsListView(true)}
      />
      <AppstoreOutlined
        className={`box-view-icon ${props.isListView ? "" : "selected"}`}
        onClick={() => props.setIsListView(false)}
      />
    </div>
  );
};

ViewToggleButton.propTypes = {
  isListView: PropTypes.bool.isRequired,
  setIsListView: PropTypes.func.isRequired,
};

export default ViewToggleButton;
