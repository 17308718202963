export interface ShippingServiceOption {
  label: string;
  account: string;
}

export const getVessiShippingServiceOptions = (): ShippingServiceOption[] => {
  return [
    {
      label: "USA Express Shipping",
      account: "FedEx - 756347292",
    },
    {
      label: "CA Ground Shipping",
      account: "FedEx - 756347292",
    },
    {
      label: "USA Ground Shipping",
      account: "FedEx - 756632035",
    },
    {
      label: "Purolator",
      account: "FedEx - 756347292",
    },
    {
      label: "CA Express Shipping",
      account: "FedEx - 756347292",
    },
    {
      label: "empty",
      account: "-",
    },
  ];
};
