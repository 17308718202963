import { getAPIFromDomain } from "../../lib/api";
import { stringify } from "query-string";
import { Storage } from "@secondcloset/types";

export const fetchCategoryIndex = async (
  query: Record<string, unknown>
): Promise<Storage.CategoryItem[]> => {
  const api = getAPIFromDomain();
  const url = `/category_items${query ? "?" + stringify(query) : ""}`;
  const res = await api.get(url);
  return res.data.category_items;
};

export const createCategoryItem = async (
  body: Record<string, unknown>
): Promise<Storage.CategoryItem[]> => {
  const api = getAPIFromDomain();
  const url = `/category_items`;
  const res = await api.post(url, body);
  return res.data.category_items;
};

export const updateCategoryItem = async ({
  body,
  categoryID,
}: {
  body: Record<string, unknown>;
  categoryID: string;
}): Promise<Storage.CategoryItem[]> => {
  const api = getAPIFromDomain();
  const url = `/category_items/${categoryID}`;
  const res = await api.put(url, body);
  return res.data.category_items;
};
