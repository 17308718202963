import React, { useState, useEffect } from "react";

// components
import { Modal, notification } from "antd";
import SelectingView from "./SelectingView";
import SchedulingView from "./SchedulingView";
import ConfirmAppointmentView from "./ConfirmAppointmentView";
import ConfirmEmailSentView from "./ConfirmEmailSentView";

// hooks
import { useSendBookingEmail } from "../../../../../../hooks/api/fulfillment/shipment";
import useUser from "../../../../../../hooks/reduxContainer/_common/useUser";

// helpers
import { isSupportEscalated } from "../../../../../../helperFunctions/user";
import Organization from "../../../../../../lib/organization";

// types
import { Fulfillment } from "@secondcloset/types";
type Shipment = Fulfillment.Shipment;
type Order = Fulfillment.Order;

const MODAL_VIEWS = {
  selecting: "selecting",
  scheduling: "scheduling",
  confirmAppointment: "confirmAppointment",
  confirmSentEmail: "confirmSentEmail",
};

interface Props {
  shipment: Shipment;
  order: Order;
  onCancel: () => any;
  visible: boolean;
  onRefetch: () => any;
  setActiveShipment?: (shipment: Shipment) => any;
}

const RescheduleShipmentModal: React.FC<Props> = ({
  shipment,
  order,
  onCancel,
  visible,
  onRefetch,
  setActiveShipment,
}) => {
  const [modalView, setModalView] = useState(MODAL_VIEWS.selecting);
  const { user } = useUser();
  const { emailStatus, sendBookingEmail } = useSendBookingEmail();

  const ikeaOrgID = Organization.getIkeaOrgID();
  const isIkea = order?.organization?.id === ikeaOrgID;

  const role = user?.role || null;
  const subrole = user?.subrole || null;

  // when it is ikea, all user will have admin ability to reschedule
  const isAdmin = isSupportEscalated(role, subrole) || isIkea;

  const resetModal = () => {
    setModalView(MODAL_VIEWS.selecting);
  };

  const onModalVisibleChange = () => {
    if (visible) resetModal();
  };

  const onRequestError = () => {
    const error = emailStatus.error;
    if (error) notification.error({ message: error });
  };

  useEffect(onModalVisibleChange, [visible]);
  useEffect(onRequestError, [emailStatus.error]);

  const onSendEmailClick = () => {
    sendBookingEmail(shipment.id).then((res) => {
      if (res) setModalView(MODAL_VIEWS.confirmSentEmail);
    });
  };

  const buildModalSelectingView = () => {
    return (
      <SelectingView
        shipment={shipment}
        loading={!!emailStatus.loading}
        onScheduleNowClick={() => setModalView(MODAL_VIEWS.scheduling)}
        onSendEmailClick={onSendEmailClick}
      />
    );
  };

  const buildModalSchedulingView = () => {
    return (
      <SchedulingView
        shipment={shipment}
        order={order}
        isAdmin={isAdmin}
        onScheduleSuccess={() => {
          setModalView(MODAL_VIEWS.confirmAppointment);
          onRefetch();
        }}
        setActiveShipment={setActiveShipment}
      />
    );
  };

  const buildModalConfirmAppointmentView = () => {
    return (
      <ConfirmAppointmentView
        shipment={shipment}
        order={order}
        onDoneClick={onCancel}
      />
    );
  };

  const buildConfirmSentEmailView = () => {
    return (
      <ConfirmEmailSentView
        shipment={shipment}
        order={order}
        onDoneClick={onCancel}
      />
    );
  };

  let content;

  switch (modalView) {
    case MODAL_VIEWS.selecting:
      content = buildModalSelectingView();
      break;
    case MODAL_VIEWS.scheduling:
      content = buildModalSchedulingView();
      break;
    case MODAL_VIEWS.confirmAppointment:
      content = buildModalConfirmAppointmentView();
      break;
    case MODAL_VIEWS.confirmSentEmail:
      content = buildConfirmSentEmailView();
      break;
    default:
      content = buildModalSelectingView();
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel();
        resetModal();
      }}
      footer={null}
    >
      {content}
    </Modal>
  );
};

export default RescheduleShipmentModal;
