import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router";

import { Order as OrderLib } from "@secondcloset/fulfillment-utils";

import { Spin, Alert } from "antd";
import { isEmpty } from "lodash-es";

import * as S from "./styles";

import { fetchOrderDetails } from "../../../api/fulfillment/order";
import { fetchShops, syncOrder } from "../../../api/integration/shopify";
import ActivityHistory, { ActivityHistoryType } from "../../../components/ActivityHistory";
import Order from "../../../lib/fulfillment/order";
import Organization from "../../../lib/organization";

import CreateInventoryReceivingShipmentButton from "./CreateInventoryReceivingShipmentButton";
import CreateUntrackedShipmentButton from "./CreateUntrackedShipmentButton";
import CreateIkeaShipmentsButton from "./devtools/CreateIkeaShipmentsButton";
import { CreateShipmentDevTool } from "./devtools/CreateShipmentDevTool";
import OrderHeader from "./OrderHeader";
import OrderInfo from "./OrderInfo";
import OrderShipments from "./OrderShipments";

const FulfillmentOrderDetailsPage: React.FC = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { orderID, shipmentID = "" } = useParams<{
    orderID: string;
    shipmentID?: string;
  }>();

  const orderDetails = useQuery(["orderDetails", orderID], () => fetchOrderDetails(orderID), {
    enabled: !!orderID,
    onSuccess: () => {
      queryClient.invalidateQueries("orderActivityHistory");
    },
  });

  const shopifyShops = useQuery(
    ["shopifyShops", orderDetails.data?.organization.id],
    () =>
      fetchShops({
        organization_id: orderDetails.data?.organization.id,
      }),
    {
      enabled: orderDetails.data?.platform === "shopify" && !!orderDetails.data?.organization.id,
    }
  );

  useEffect(() => {
    if (!shipmentID && orderDetails.isLoading) return;
    const shipment = document.getElementById(shipmentID);
    if (shipment)
      shipment.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }, [shipmentID, orderDetails.isLoading]);

  const redirectToCreateShipment = (orderID: string) => history.push(`/fulfillment/orders/${orderID}/create-shipment`);

  const { mutate: doSyncOrder, isLoading: isSyncing } = useMutation(syncOrder, {
    onSuccess: (order) => {
      const toBeDeliveredItems = OrderLib.getToBeDeliveredItems(order);
      if (toBeDeliveredItems.length >= 1) redirectToCreateShipment(order.id);
      else orderDetails.refetch();
    },
  });

  const syncShopifyOrder = () => {
    const { external_order_id, organization } = orderDetails.data ?? {};
    const orgID = organization?.id;
    const [orderID, shopID] = (external_order_id || "").split("___");
    const shop = shopifyShops.data?.find((s) => s.id === shopID);
    if (orgID && shop)
      doSyncOrder({
        shopify_domain: shop.name,
        id: orderID,
        organization_id: orgID,
      });
  };

  const goToCreateShipmentPage = () => {
    const { id, platform } = orderDetails.data ?? {};
    if (platform === "shopify") {
      syncShopifyOrder();
    } else if (id) {
      redirectToCreateShipment(id);
    }
  };

  const renderOrderInfo = () => {
    return (
      <OrderInfo order={orderDetails.data} loading={orderDetails.isLoading} onUpdateSuccess={orderDetails.refetch} />
    );
  };

  const validateHasAnyUnshippedItems = () => {
    if (!orderDetails.data) return false;
    // TODO move getUnshippedItems to Order fulfillment-utils
    const unshippedItems = Order.getUnshippedItems(orderDetails.data) || [];
    return !isEmpty(unshippedItems);
  };

  const renderCreateUntrackedShipmentButton = () => {
    if (!validateHasAnyUnshippedItems()) return null;
    return (
      <S.CreateShipmentsButtonWrapper>
        <CreateUntrackedShipmentButton order={orderDetails.data} onReFetchOrder={orderDetails.refetch} />
      </S.CreateShipmentsButtonWrapper>
    );
  };

  const organization = orderDetails?.data?.organization;
  const isIKEA = Organization.isIKEA(organization);
  const isProduction = process.env.REACT_APP_ENV === "production";

  const renderIkeaShipmentsButton = () => {
    const isCreateIkeaShipmentsButtonDisabled = !isIKEA || isProduction;
    if (isCreateIkeaShipmentsButtonDisabled) return null;
    return (
      <S.CreateShipmentsButtonWrapper>
        <CreateIkeaShipmentsButton order={orderDetails.data} onRefetchOrder={orderDetails.refetch} />
      </S.CreateShipmentsButtonWrapper>
    );
  };

  const renderCreateShipmentDevTool = () => {
    const isCreateShipmentDevToolDisabled = !validateHasAnyUnshippedItems() || isProduction || isIKEA;
    if (isCreateShipmentDevToolDisabled) return null;
    return (
      <S.CreateShipmentsButtonWrapper>
        {!!orderDetails.data && (
          <CreateShipmentDevTool order={orderDetails.data} onRefetchOrder={orderDetails.refetch} />
        )}
      </S.CreateShipmentsButtonWrapper>
    );
  };

  const renderInventoryReceivingShipmentButton = () => {
    if (!validateHasAnyUnshippedItems()) return null;
    return (
      <S.CreateShipmentsButtonWrapper>
        <CreateInventoryReceivingShipmentButton order={orderDetails.data} reFetchOrder={orderDetails.refetch} />
      </S.CreateShipmentsButtonWrapper>
    );
  };

  const renderShipments = () => {
    const order = orderDetails.data;
    return (
      <OrderShipments
        order={order as any}
        orderLoading={orderDetails.isLoading}
        onRefetch={orderDetails.refetch}
        onShipItemsClick={goToCreateShipmentPage}
      />
    );
  };

  const buildAlertBox = () => {
    if (!orderDetails?.data?.on_hold) return null;
    return <Alert type="warning" message="This order is currently on hold" style={{ marginBottom: 14 }} showIcon />;
  };

  return (
    <div id="fulfillment-order-details-page">
      <Spin
        tip={isSyncing ? "Syncing order with shopify..." : "Fetching order details..."}
        spinning={isSyncing || orderDetails.isLoading}
      >
        <S.OrderInfoWrap>
          {buildAlertBox()}
          {orderDetails.data && <OrderHeader order={orderDetails.data} onRefetchOrder={orderDetails.refetch} />}
          {renderOrderInfo()}
          <S.CreateButtonsWrapper>
            {renderCreateUntrackedShipmentButton()}
            {renderIkeaShipmentsButton()}
            {renderInventoryReceivingShipmentButton()}
            {renderCreateShipmentDevTool()}
          </S.CreateButtonsWrapper>
        </S.OrderInfoWrap>
        {renderShipments()}
        <ActivityHistory
          id={orderID}
          orgID={orderDetails.data?.organization.id}
          type={ActivityHistoryType.ORDER}
          enableFetch={!isEmpty(orderDetails.data)}
          title="Order Activity Log"
        />
      </Spin>
    </div>
  );
};

export default FulfillmentOrderDetailsPage;
