import styled from "styled-components";
import colors from "../../../css/colors";

export const Container = styled.div`
  background: ${colors.WHITE};
  /* min-height: 100vh; */
  width: 100%;
  padding: 30px;
  border: 1px solid ${colors.GREY_LIGHT};
  overflow: hidden;
  h3 {
    font-weight: normal;
    margin: 0;
  }

  button.back-btn {
    padding: 0;
  }

  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
  .ant-dropdown-trigger > button {
    width: 200px;
  }
  .search-input {
    flex-basis: 350px;
  }
`;
