import React from "react";
const getErrorArray = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  let message_array = [];
  Object.keys(obj).forEach((key) => {
    message_array = message_array.concat(getErrorArray(obj[key]));
  });
  return message_array;
};

const getErrorMsg = (err, withElement = true) => {
  if (err.response) {
    const { errors, error_message, error } = err.response.data;
    if (errors) {
      const message_array = getErrorArray(errors);
      if (withElement) {
        let message = message_array.map((msg, index) => {
          return (
            <li key={index} style={{ color: "white" }}>
              {msg}
            </li>
          );
        });
        return <ul>{message}</ul>;
      } else {
        return message_array;
      }
    } else if (error_message) {
      return error_message;
    } else if (error) {
      return error;
    } else {
      return "call failed, check errors";
    }
  } else {
    return err.message;
  }
};

export default getErrorMsg;
