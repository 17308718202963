import React, { useState, useEffect } from "react";

//hooks
import { useAppointmentIndex } from "../../../hooks/api/storage/appointment";
import { useActiveServiceArea } from "../../../hooks/reduxContainer/_common/activeServiceArea";

//components
import AppointmentOverview from "./AppointmentOverview";
import SummarySheet from "./SummarySheet";
import { Spin, notification, DatePicker, Button, Space } from "antd";

//functions
import moment from "moment";
import { appointmentTypes } from "../../../backendTypes";

const DashboardPage = () => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { appointmentIndex, fetchAppointmentIndex } = useAppointmentIndex();
  const { activeServiceArea } = useActiveServiceArea();
  const [selectedDate, setSelectedDate] = useState(moment());

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (appointmentIndex.error) {
      notification.error({
        message: "Error",
        description: appointmentIndex.error,
      });
    }
  }, [appointmentIndex.error]);

  useEffect(() => {
    if (!shouldFetch) return;
    const service_area = activeServiceArea;
    const date_from = selectedDate.format("YYYY-MM-DD");
    const date_to = date_from;
    const per_page = "ALL";
    fetchAppointmentIndex({
      service_area,
      date_from,
      date_to,
      per_page,
    });
  }, [activeServiceArea, selectedDate, shouldFetch]);

  if (!appointmentIndex.data && !appointmentIndex.loading)
    return (
      <Space align="start" direction="vertical" size="middle">
        You are going to slow down the server. Are sure you want to see?
        <Button onClick={() => setShouldFetch(true)}>
          Yes, I don&apos;t care
        </Button>
      </Space>
    );

  const getActiveDates = () => {
    let dates = [selectedDate];
    return dates;
  };

  const getAppointmentsCount = (date) => {
    let appointments = appointmentIndex.data.filter((obj) => {
      return moment(obj.date).isSame(date, "day");
    });

    let initialState = appointmentTypes.reduce((acc, cv) => {
      acc[cv] = 0;
      return acc;
    }, {});

    const appointmentCountsByType = appointments.reduce((acc, cv) => {
      if (cv.status !== "cancelled") {
        acc[cv.job_type]++;
      }
      return acc;
    }, initialState);

    return appointmentCountsByType;
  };

  const renderDatePicker = () => {
    return (
      <DatePicker onChange={(d) => setSelectedDate(d)} value={selectedDate} />
    );
  };

  return (
    <Spin spinning={appointmentIndex.loading} tip="Fetching dashboard data...">
      <div id="dashboard-page" className="page-container">
        <div className="page-title">Dashboard</div>
        <hr />
        {renderDatePicker()}
        <div className="page-contents">
          <div className="section-title">Appointments</div>
          {appointmentIndex.data && (
            <div className="appointments-info-container">
              <SummarySheet
                getAppointmentsCount={getAppointmentsCount}
                selectedDate={selectedDate}
              />
              <AppointmentOverview
                activeDates={getActiveDates()}
                activeAppointments={appointmentIndex.data}
                getAppointmentsCount={getAppointmentsCount}
                setSelectedDate={setSelectedDate}
              />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default DashboardPage;
