import { Fulfillment } from "@secondcloset/types";
import { UploadFile } from "antd/lib/upload/interface";
import { Organization as OrganizationLib } from "@secondcloset/fulfillment-utils";

// Action types
const SET_ACTIVE_STEP_KEY = "ORDER_CREATE_FLOW/SET_ACTIVE_STEP_KEY";
const SET_ACTIVE_LOCATION = "ORDER_CREATE_FLOW/SET_ACTIVE_LOCATION";
const SET_EXTERNAL_PLATFORM_VERSION =
  "ORDER_CREATE_FLOW/SET_EXTERNAL_PLATFORM_VERSION";
const SET_ACTIVE_CUSTOMER = "ORDER_CREATE_FLOW/SET_ACTIVE_CUSTOMER";
const SET_ACTIVE_ADDRESS = "ORDER_CREATE_FLOW/SET_ACTIVE_ADDRESS";
const SET_ITEM_LIST = "ORDER_CREATE_FLOW/SET_ITEM_LIST";
const SET_EXTERNAL_ORDER_NUMBER = "ORDER_CREATE_FLOW/SET_EXTERNAL_ORDER_NUMBER";
const SET_NOTES = "ORDER_CREATE_FLOW/SET_NOTES";
const SET_AUTO_CREATE_DELIVERY_SHIPMENT =
  "ORDER_CREATE_FLOW/SET_AUTO_CREATE_DELIVERY_SHIPMENT";
const RESET = "ORDER_CREATE_FLOW/RESET";
const SET_INSURANCE_VALUE = "ORDER_CREATE_FLOW/SET_INSURANCE_VALUE";
const SET_FILES = "ORDER_CREATE_FLOW/SET_FILES";
const ADD_FILE = "ORDER_CREATE_FLOW/ADD_FILE";
const REMOVE_FILE = "ORDER_CREATE_FLOW/REMOVE_FILE";

export type StepKey =
  | "selectLocation"
  | "searchCustomer"
  | "selectAddress"
  | "selectProducts"
  | "provideExternalInfo"
  | "orderOverview";

export interface State {
  activeStepKey: StepKey | null;
  activeLocation: string;
  externalPlatformVersion: number;
  activeCustomer?: Fulfillment.Customer | null;
  activeAddress?: Fulfillment.CustomerAddress | null;
  itemList: any[];
  externalOrderNumber: string;
  notes: string;
  isAutoCreateDeliveryShipment: boolean;
  insuranceValue: number;
  files: UploadFile[];
}

export interface Step {
  [index: string]: any;
  key: State["activeStepKey"];
  seq: number;
  title: string;
}

// Action Creators
export function actionSetActiveStepKey(stepKey: State["activeStepKey"]) {
  return { type: SET_ACTIVE_STEP_KEY, stepKey };
}

export function actionSetActiveLocation(location: State["activeLocation"]) {
  return { type: SET_ACTIVE_LOCATION, location };
}

export function actionSetExternalPlatformVersion(
  version: State["externalPlatformVersion"]
) {
  return { type: SET_EXTERNAL_PLATFORM_VERSION, version };
}

export function actionSetActiveCustomer(customer: State["activeCustomer"]) {
  return { type: SET_ACTIVE_CUSTOMER, customer };
}

export function actionSetActiveAddress(address: State["activeAddress"]) {
  return { type: SET_ACTIVE_ADDRESS, address };
}

export function actionSetItemList(items: State["itemList"]) {
  return { type: SET_ITEM_LIST, items };
}

export function actionSetExternalOrderNumber(
  externalOrderNumber: State["externalOrderNumber"]
) {
  return { type: SET_EXTERNAL_ORDER_NUMBER, externalOrderNumber };
}

export function actionSetNotes(notes: State["notes"]) {
  return { type: SET_NOTES, notes };
}

export function actionSetAutoCreateDeliveryShipment() {
  return { type: SET_AUTO_CREATE_DELIVERY_SHIPMENT };
}

export function actionReset() {
  return { type: RESET };
}

export function actionSetInsuranceValue(
  insuranceValue: State["insuranceValue"]
) {
  return { type: SET_INSURANCE_VALUE, insuranceValue };
}

export function actionSetFiles(files: UploadFile[]) {
  return { type: SET_FILES, files };
}

export function actionAddFile(file: UploadFile) {
  return { type: ADD_FILE, file };
}

export function actionRemoveFile(uid: string) {
  return { type: REMOVE_FILE, uid };
}

// Reducer
export default function reducer(state: State, action: any) {
  const INITIAL_STATE: State = {
    activeStepKey: null,
    activeLocation: OrganizationLib.DEFAULT_FALLBACK_FACILITY_CODE,
    externalPlatformVersion: 0,
    activeCustomer: null,
    activeAddress: null,
    itemList: [],
    externalOrderNumber: "",
    notes: "",
    isAutoCreateDeliveryShipment: false,
    insuranceValue: 0,
    files: [],
  };
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case SET_ACTIVE_STEP_KEY:
      return { ...state, activeStepKey: action.stepKey };
    case SET_ACTIVE_LOCATION:
      return { ...state, activeLocation: action.location };
    case SET_EXTERNAL_PLATFORM_VERSION:
      return { ...state, externalPlatformVersion: action.version };
    case SET_ACTIVE_CUSTOMER:
      return { ...state, activeCustomer: action.customer, activeAddress: null };
    case SET_ACTIVE_ADDRESS:
      return { ...state, activeAddress: action.address };
    case SET_ITEM_LIST:
      return { ...state, itemList: action.items };
    case SET_EXTERNAL_ORDER_NUMBER:
      return { ...state, externalOrderNumber: action.externalOrderNumber };
    case SET_NOTES:
      return { ...state, notes: action.notes };
    case SET_AUTO_CREATE_DELIVERY_SHIPMENT:
      return { ...state, isAutoCreateDeliveryShipment: true };
    case RESET:
      return INITIAL_STATE;
    case SET_INSURANCE_VALUE:
      return { ...state, insuranceValue: action.insuranceValue };
    case SET_FILES:
      return { ...state, files: action.files };
    case ADD_FILE:
      return { ...state, files: [...state.files, action.file] };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((file) => file.uid !== action.uid),
      };
    default:
      return state;
  }
}
