import { get } from "lodash-es";

const isShipmentType = (shipment, type) => {
  const actionSummary = get(shipment, "action_summary", []);
  return actionSummary.includes(type);
};

export default class Shipment {
  static getShipmentLabel = (shipment) => {
    const shippingLabels = get(shipment, "shipping_method.shipping_labels", []);
    return shippingLabels[0];
  };

  static isReturnShipment = (shipment) => {
    return isShipmentType(shipment, "reverse_logistics");
  };

  static isReturnToSender = (shipment) => {
    return isShipmentType(shipment, "return_to_sender");
  };

  static isSecondCloset = (shipment) => {
    return get(shipment, "shipping_method_type") === "appointment";
  };

  static getEndyReturnReasons = () => [
    "COVID-19",
    "Duplicate",
    "Size",
    "Error Exchange",
    "Competitor",
    "Price",
    "Damage",
    "Timing",
    "Medical",
    "Smell",
    "Heat",
    "Allergic",
    "No Reason Given",
    "Pain",
    "Soft",
    "Firm",
    "Thin",
    "Thick",
    "Edge Support",
    "Bounce",
    "Colour",
    "Fabric Feel",
    "Lumpy",
    "Noisy",
    "Fit",
    "Height",
    "No Headboard",
    "Shipping Delay",
    "Defect",
    "Heavy",
    "Warranty",
    "Variation",
    "Courtesy",
    "Courtesy Defect",
    "Seat Depth",
    "Back Height",
    "Non-Reversible",
    "Assembly",
    "Style",
  ];

  static getLastWrittenNote = (notes) => {
    if (!notes) return null;
    const sortedNotes = notes.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    );
    return get(sortedNotes, "[0].text", null);
  };

  static getCreateIkeaShipmentBody = (orderID, jobType, options) => {
    const {
      date,
      rangeOfDaysStartDate,
      rangeOfDaysEndDate,
      timeSlot,
      orderItemIDs,
      packages,
      addressID,
      externalShipmentID,
      untracked,
      consumeStock,
      serviceLevel,
    } = options;
    const orderItems = orderItemIDs.map((id) => ({ id }));
    const body = {
      order_id: orderID,
      date,
      range_of_days_start_date: rangeOfDaysStartDate,
      range_of_days_end_date: rangeOfDaysEndDate,
      time_range: timeSlot,
      service_code: serviceLevel,
      job_type: jobType,
      skip_customer_notification: true,
      order_items: orderItems,
      packages,
      appointment_address_id: addressID,
      external_shipment_id: externalShipmentID,
      untracked,
      consume_stock: consumeStock,
    };
    return body;
  };
}
