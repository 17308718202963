import { combineReducers } from "redux";

/**
 * Import reducers
 */
import authReducer from "./reducer_auth";
import productsReducer from "./reducer_products";
import productsAllReducer from "./reducer_productsAll";
import selectedCustomerReducer from "./reducer_selected_customer";
import loadingReducer from "./reducer_loading";
import errorReducer from "./reducer_errors";
import fetchingReducer from "./reducer_fetching";
import fetchedReducer from "./reducer_fetched";
import updateTimeReducer from "./reducer_updateTime";
import currentSearchReducer from "./reducer_search";
import regular from "./regular";
import fulfillment from "./fulfillment";
import activeFacility from "./activeFacility";
import facilityList from "./facilityList";

/**
 * Import sample data
 */

const rootReducer = combineReducers({
  auth: authReducer,
  selectedCustomer: selectedCustomerReducer,
  loading: loadingReducer,
  error: errorReducer,
  products: productsReducer,
  productsAll: productsAllReducer,
  fetching: fetchingReducer,
  fetched: fetchedReducer,
  updateTime: updateTimeReducer,
  currentSearch: currentSearchReducer,
  regular,
  fulfillment,
  activeFacility,
  facilityList,
});

export default rootReducer;
