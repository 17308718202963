import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const useCustomerIndex = (organizationID) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("fulfillment");

  const fetchCustomerIndex = (query) => {
    const baseUrl = `/customers?organization_id=${organizationID}`;
    const url = new UrlBuilder(baseUrl).applyQueryStringFromObject(query);

    setLoading(true);
    setError(null);
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const customerIndex = { data, loading, error };

  return { customerIndex, fetchCustomerIndex };
};

const useCustomerCreate = (organizationID) => {
  const [customerCreateDetails, setCustomerCreateDetails] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const createCustomer = (name, email, phone) => {
    const url = `/customers`;
    const body = {
      fulfillment_customer: {
        organization_id: organizationID,
        name,
        email_address: email,
        phone_number: phone,
      },
    };

    setCustomerCreateDetails({
      ...customerCreateDetails,
      loading: true,
      error: null,
    });
    return api
      .post(url, body)
      .then((res) => {
        setCustomerCreateDetails({
          data: res.data,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setCustomerCreateDetails({
          ...customerCreateDetails,
          loading: false,
          error,
        });
        return false;
      });
  };

  const resetCustomerCreateDetails = () => {
    setCustomerCreateDetails({
      loading: false,
      data: null,
      error: null,
    });
  };

  return { customerCreateDetails, createCustomer, resetCustomerCreateDetails };
};

const useCustomerAddresses = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const fetchCustomerAddresses = (userID) => {
    const url = `/customers/${userID}/customer_addresses`;
    setLoading(true);
    setError(null);
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const customerAddresses = { data, loading, error };
  return { customerAddresses, fetchCustomerAddresses };
};

const useCustomerAddressCreate = () => {
  const [customerAddress, setCustomerAddress] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const createCustomerAddress = (customerID, address) => {
    const url = `/customers/${customerID}`;
    const body = {
      fulfillment_customer: {
        user_id: customerID,
        fulfillment_customer_addresses_attributes: [
          {
            ...address,
          },
        ],
      },
    };

    setCustomerAddress({ ...customerAddress, loading: true, error: null });
    return api
      .put(url, body)
      .then((res) => {
        setCustomerAddress({ data: res.data, loading: false, error: null });
        return res.data;
      })
      .catch((error) => {
        setCustomerAddress({ ...customerAddress, loading: false, error });
        return error;
      });
  };

  const resetCustomerAddress = () => {
    setCustomerAddress({
      loading: false,
      data: null,
      error: null,
    });
  };

  return {
    customerAddress,
    createCustomerAddress,
    resetCustomerAddress,
  };
};

export {
  useCustomerIndex,
  useCustomerCreate,
  useCustomerAddresses,
  useCustomerAddressCreate,
};
