import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CreditCardOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ErrorBox from "../../../fulfillment/_common/ErrorBox";
import UpdateBillingButton from "./UpdateBillingButton";
import { useCustomerBilling } from "../../../../hooks/api/regular/customer";
import Billing from "../../../../lib/regular/billing";

const BillingDetailCard = (props) => {
  const { customerBilling, fetchCustomerBilling } = useCustomerBilling();
  const getBillingInfo = () => {
    fetchCustomerBilling(props.userID);
  };

  useEffect(getBillingInfo, []);

  const renderCardInfo = () => {
    const { data, loading, error } = customerBilling;
    if (loading)
      return (
        <div className="card-info spinning">
          <Spin tip="fetching billing info..." />
        </div>
      );
    if (error) return <ErrorBox error={error} />;
    if (!data) return null;
    if (!Billing.hasCreditCardOnFile(data))
      return (
        <div className="card-info no-data">
          <span>No Card on File</span>
        </div>
      );
    return (
      <div className="card-info">
        <div className="info-line">
          <CreditCardOutlined /> {Billing.getCreditCardString(data)}
        </div>
        <div className="info-line">
          Exp: {Billing.getExpiryDateString(data)}
        </div>
        <div className="info-line">Type: {Billing.getBrand(data)}</div>
      </div>
    );
  };

  return (
    <div className="billing-details-card">
      <div className="title">Billing Details</div>
      {renderCardInfo()}
      <UpdateBillingButton
        userID={props.userID}
        hasCreditCardOnFile={Billing.hasCreditCardOnFile(customerBilling.data)}
        onUpdateBillingSuccess={() => {
          getBillingInfo();
          props.onUpdateBillingSuccess();
        }}
      />
    </div>
  );
};

BillingDetailCard.propTypes = {
  userID: PropTypes.string.isRequired,
  onUpdateBillingSuccess: PropTypes.func.isRequired,
};

export default BillingDetailCard;
