import React, { useState } from "react";
import { Table, Popover, Alert, Tag, Button, Modal } from "antd";
import { Helmet } from "react-helmet";
import CategoryForm from "./CategoryForm";
import Products from "../../../lib/regular/products";
import TablePagination from "../../../components/_common/TablePagination";
import { SearchInput } from "@secondcloset/web-components";
import { isEmpty } from "lodash-es";
import useURLStateV2 from "../../../hooks/application/useURLStateV2";
import { useQuery, useMutation } from "react-query";
import {
  fetchCategoryIndex,
  updateCategoryItem,
  createCategoryItem,
} from "../../../api/storage/category";
import { fetchProductIndex } from "../../../api/storage/product";

const PAGE_SIZE = 15;

const CategoryManagementPage = (props) => {
  const [urlState, setUrlState] = useURLStateV2({ search: "" });
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const categoryIndex = useQuery(
    ["categoryIndex", urlState.search, page],
    () =>
      fetchCategoryIndex({
        per_page: PAGE_SIZE,
        page,
        q: String(urlState.search).trim(),
      }),
    { keepPreviousData: true }
  );

  const {
    mutate: doUpdateCategoryItem,
    isLoading: isUpdatingCategory,
  } = useMutation(updateCategoryItem, {
    onSuccess: () => {
      categoryIndex.refetch();
      setEditModalVisible(false);
    },
  });
  const {
    mutate: doCreateCategoryItem,
    isLoading: isCreatingCategory,
  } = useMutation(createCategoryItem, {
    onSuccess: () => {
      categoryIndex.refetch();
      setCreateModalVisible(false);
    },
  });

  const productsIndex = useQuery("productIndex", fetchProductIndex, {
    select: (data) => {
      const sierraProducts = Products.getAdminVisibleProducts(data);
      const boxes = Products.getBoxes(sierraProducts);
      const bulkys = Products.getBulkyItems(sierraProducts);
      const pallets = Products.getPallets(sierraProducts);
      return [...boxes, ...bulkys, ...pallets];
    },
  });

  const isLoading =
    productsIndex.isLoading ||
    categoryIndex.isLoading ||
    isCreatingCategory ||
    isUpdatingCategory;

  const buildHeader = () => {
    return (
      <div className="header">
        <h1>Category Management</h1>
        <Button type="primary" onClick={() => setCreateModalVisible(true)}>
          Create Category
        </Button>
      </div>
    );
  };

  const buildSearchBar = () => {
    return (
      <Popover
        placement="topLeft"
        title="You Can Search"
        content={
          <div className="category-popover-list">
            <p>Category Name</p>
            <p>Synonyms</p>
          </div>
        }
        trigger="focus"
      >
        <SearchInput
          placeholder="Search..."
          defaultValue={urlState.search}
          onSearch={(search) => setUrlState((state) => ({ ...state, search }))}
          onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
          enterButton
        />
      </Popover>
    );
  };

  const buildColumns = () => {
    const renderSynonyms = (synonyms) => {
      if (isEmpty(synonyms)) return "-";
      return synonyms.map((s) => <Tag key={s}>{s}</Tag>);
    };

    const renderPublic = (p) => {
      let color = "red";
      if (p) color = "green";
      return <Tag color={color}>{p ? "YES" : "NO"}</Tag>;
    };

    const renderAction = (_, record) => {
      return (
        <button
          className="edit-button"
          onClick={() => {
            setSelectedCategory(record);
            setEditModalVisible(true);
          }}
        >
          Edit
        </button>
      );
    };

    return [
      { title: "Name", dataIndex: "name", key: "name" },
      {
        title: "Synonyms",
        dataIndex: "synonyms",
        key: "synonyms",
        render: renderSynonyms,
      },
      {
        title: "Stripe ID",
        dataIndex: "stripe_plan_id",
        key: "stripe_plan_id",
      },
      {
        title: "Public",
        dataIndex: "public",
        key: "public",
        render: renderPublic,
      },
      { title: "Volume", dataIndex: "volume", key: "volume" },
      { title: "Action", dataIndex: "_", key: "_", render: renderAction },
    ];
  };

  const buildTable = () => {
    return (
      <div className="category-table">
        <Table
          loading={categoryIndex.isLoading}
          columns={buildColumns()}
          dataSource={categoryIndex.data || []}
          size="small"
          pagination={false}
          scroll={{ x: 600 }}
          rowKey={(record) => record.id}
        />
        <TablePagination
          data={categoryIndex.data}
          page={page}
          setPage={setPage}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  };

  const buildEditModal = () => {
    return (
      <Modal
        title="Edit Category"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        {categoryIndex.error && (
          <Alert message={categoryIndex.error} type="error" />
        )}
        <CategoryForm
          category={selectedCategory}
          loading={isLoading}
          products={productsIndex.data ?? []}
          onCancel={() => setEditModalVisible(false)}
          onSave={(body) =>
            doUpdateCategoryItem({
              categoryID: selectedCategory.id,
              body,
            })
          }
        />
      </Modal>
    );
  };

  const buildCreateModal = () => {
    return (
      <Modal
        title="Create Category"
        visible={createModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        {categoryIndex.error && (
          <Alert message={categoryIndex.error} type="error" />
        )}
        <CategoryForm
          loading={isLoading}
          products={productsIndex.data ?? []}
          onCancel={() => setCreateModalVisible(false)}
          onSave={(body) => doCreateCategoryItem(body)}
        />
      </Modal>
    );
  };

  return (
    <div className="page-container" id="category-management">
      <Helmet title="Sierra - Appointments" />
      {categoryIndex.error && (
        <Alert message={categoryIndex.error} type="error" />
      )}
      <div className="category">
        {buildHeader()}
        {buildSearchBar()}
        {buildTable()}
        {buildEditModal()}
        {buildCreateModal()}
      </div>
    </div>
  );
};

CategoryManagementPage.propTypes = {};

export default CategoryManagementPage;
