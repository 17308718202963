export function getDisplayType(type) {
  switch (type) {
    case "pick_up":
      return "Pick Up";
    case "drop_off":
      return "Drop Off";
    case "immediate_pick_up":
      return "Drop Off / Pick Up";
    case "return":
      return "Return";
    case "retrieve":
      return "Retrieve";
    case "immediate_retrieve":
      return "Return / Retrieve";
    case "reverse_logistics":
      return "Reverse Logistics";
    case "delivery":
      return "Delivery";
    case "custom":
      return "Custom";
    default:
      return type;
  }
}
