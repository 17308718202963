import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .ant-btn {
    &:not(:last-child) {
      margin: 0 0 0 16px;
    }
  }
`;
