import React, { useEffect } from "react";
import reduxStore from "../../../../config/reduxStore";
import PropTypes from "prop-types";

// assets
import {
  BellFilled,
  BlockOutlined,
  CreditCardOutlined,
  DollarOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

// hooks
import { useCustomerBilling } from "hooks/api/regular/customer";
import { useOrganizationDetails } from "hooks/api/fulfillment/organization";
import { useHistory } from "react-router";
import { useQuery, queryCache } from "react-query-legacy";

// components
import { Button, Tag, Spin, notification, Popover } from "antd";
import EditContactButton from "./EditContactButton";
import EditUserDetailsButton from "./EditUserDetailsButton";
import EditRolesButton from "./EditRolesButton";
import BlueEditButton from "./BlueEditButton";
import DownloadQrCodesButton from "components/regular/_common/DownloadQrCodesButton";
import UpdateBillingButton from "components/regular/_common/billing/UpdateBillingButton";

// styles
import * as S from "./styles";

// lib
import Billing from "lib/regular/billing";
import User from "lib/regular/user";
import { isGod, isSuperAdmin } from "../../../../helperFunctions/user";
import { get } from "lodash-es";

// api
import {
  fetchItemCoveragePlanIndex,
  fetchUserCoveragePlanDetails,
} from "../../../../api/storage/itemCoverage";

const CustomerInfoSection = (props) => {
  const { customerBilling, fetchCustomerBilling } = useCustomerBilling();
  const {
    organizationDetails,
    fetchOrganizationDetails,
  } = useOrganizationDetails();
  const history = useHistory();

  const displayError = (error) => {
    return notification.error({
      message: "Error",
      description: error,
    });
  };

  const itemCoveragePlanIndex = useQuery(
    "itemCoveragePlanIndex",
    fetchItemCoveragePlanIndex,
    { onError: displayError }
  );
  const userCoveragePlanDetails = useQuery(
    ["userCoveragePlanDetails", props.user],
    () => fetchUserCoveragePlanDetails(props.user?.id),
    { onError: displayError, enabled: !!props.user?.id }
  );

  const userSubscriptionID =
    userCoveragePlanDetails?.data?.coverage_product_id || "";
  const userPlanPreferenceID =
    userCoveragePlanDetails?.data?.coverage_product_id_user_choice || "";
  const activeCoverageID = userSubscriptionID || userPlanPreferenceID;

  const getBilling = () => {
    fetchCustomerBilling(props.user.id);
  };

  const onMount = () => {
    getBilling();
    const orgID = get(props.user, "organization_id");
    if (orgID) fetchOrganizationDetails(orgID);
  };

  useEffect(onMount, []);

  const renderDelinquentBanner = () => {
    if (props.user.payment_issue) {
      return (
        <div className="delinquent-banner">
          <BellFilled className="bell-icon" /> DELINQUENT
        </div>
      );
    }
  };

  const renderName = () => {
    const { first_name, last_name, user_code } = props.user;
    return (
      <div className="customer-name">
        {`${first_name} ${last_name}`} <span>[{user_code}]</span>
      </div>
    );
  };

  const renderButtons = () => {
    const role = get(props.user, "role");
    const isStorageUser =
      role !== "is_fulfillment_client" && role !== "is_admin";
    if (!isStorageUser) return null;
    const userID = props.user.id;
    const invoicePath = `/customers/${userID}/invoice`;
    const inventoryPath = `/customers/${userID}/inventory`;

    return (
      <div className="link-buttons">
        <DownloadQrCodesButton userID={props.user.id} />
        <Button href={inventoryPath}>
          Inventory <BlockOutlined />
        </Button>
        <Button href={invoicePath}>
          Invoice <DollarOutlined />
        </Button>
      </div>
    );
  };

  const renderSectionTitle = (title, button) => {
    return (
      <div className="title">
        {title} {button}
      </div>
    );
  };

  const goToOrganizationDetailsPage = () => {
    const orgID = get(props.user, "organization_id");
    if (orgID) {
      history.push(`/organizations/${orgID}`);
    }
  };

  const renderContactInformation = () => {
    const button = (
      <EditContactButton
        user={props.user}
        onUpdateSuccess={props.onUpdateContactSuccess}
      />
    );
    const { phone_number, email } = props.user;
    const organizationName = get(organizationDetails.data, "name");
    return (
      <div className="contact-information">
        {renderSectionTitle("Contact Information", button)}
        <div className="label-value">
          <div className="label">
            Email <MailOutlined />:
          </div>
          <div className="value">{email || "-"}</div>
        </div>
        <div className="label-value">
          <div className="label">
            Phone <PhoneOutlined />:
          </div>
          <div className="value">{phone_number || "-"}</div>
        </div>
        <div className="label-value">
          <div className="label">
            Organization <GlobalOutlined />:
          </div>
          <div
            className="value organization-link"
            onClick={goToOrganizationDetailsPage}
          >
            {organizationName || "-"}
          </div>
        </div>
      </div>
    );
  };

  const buildPendingTip = () => {
    if (!!userSubscriptionID || !activeCoverageID) return null;
    return (
      <Popover
        title="Coverage Pending"
        content="Coverage will begin on the customer's initial pickup appointment date."
      >
        <S.QuestionMarkIcon />
      </Popover>
    );
  };

  const renderUserDetailsInfo = () => {
    const { business_user, business_name, business_type, closets } = props.user;
    const userType = business_user ? "Business Client" : "Client";
    const availablePlans = itemCoveragePlanIndex.data || [];
    const activeCoveragePlan = availablePlans?.find(
      (p) => p?.id === activeCoverageID
    );
    if (business_user) {
      return (
        <>
          <div className="label-value">
            <div className="label">User Type:</div>
            <Tag>{userType}</Tag>
          </div>
          <div className="label-value">
            <div className="label">Business Name:</div>
            <div className="value">{business_name || "-"}</div>
          </div>
          <div className="label-value">
            <div className="label"> Business Type:</div>
            <div className="value">{business_type || "-"}</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="label-value">
          <div className="label">User Type:</div>
          <Tag>{userType}</Tag>
        </div>
        <div className="label-value">
          <div className="label">Space plan customer:</div>
          <Tag color={closets ? "green" : "red"}>{closets ? "YES" : "NO"}</Tag>
        </div>
        <div className="label-value">
          <div className="label">Number of closets / space plans:</div>{" "}
          <div className="value">{closets}</div>
        </div>
        <div className="label-value">
          <div className="label">Protection Plan:</div>
          <div className="value">
            {activeCoveragePlan?.name || "N/A"} {buildPendingTip()}
          </div>
        </div>
      </>
    );
  };

  const renderUserDetails = () => {
    const { role } = props.user;
    const isFulfillmentClient = role === "is_fulfillment_client";
    if (isFulfillmentClient) return null;
    const button = (
      <EditUserDetailsButton
        user={props.user}
        onUpdateUserSuccess={props.onUpdateContactSuccess}
        userSubscriptionID={userSubscriptionID}
        userPlanPreferenceID={userPlanPreferenceID}
        itemCoveragePlanIndex={itemCoveragePlanIndex.data}
        onUpdateProtectionPlanSuccess={() =>
          queryCache.refetchQueries("userCoveragePlanDetails")
        }
      />
    );
    const title = <div>User Details</div>;
    return (
      <Spin
        tip="Loading..."
        spinning={
          userCoveragePlanDetails.isFetching || itemCoveragePlanIndex.isFetching
        }
      >
        <div className="user-type">
          {renderSectionTitle(title, button)}
          {renderUserDetailsInfo()}
        </div>
      </Spin>
    );
  };

  const renderBilling = () => {
    const button = (
      <UpdateBillingButton
        userID={props.user.id}
        onUpdateBillingSuccess={getBilling}
      >
        <BlueEditButton>
          {Billing.hasCreditCardOnFile(customerBilling.data) ? "Update" : "Add"}
        </BlueEditButton>
      </UpdateBillingButton>
    );
    return (
      <Spin tip="Loading..." spinning={customerBilling.loading}>
        <div className="billing">
          {renderSectionTitle("Billing", button)}
          <div className="credit-card-display">
            <CreditCardOutlined className="credit-card-icon" />{" "}
            {Billing.getCreditCardString(customerBilling.data)}
          </div>
          <div className="label-value">
            <div className="label"> Exp:</div>
            <div className="value">
              {Billing.getExpiryDateString(customerBilling.data)}
            </div>
          </div>
          <div className="label-value">
            <div className="label"> Type:</div>
            <div className="value">
              {Billing.getBrand(customerBilling.data)}
            </div>
          </div>
        </div>
      </Spin>
    );
  };

  const isSuperOrGod = () => {
    const { role, subrole } = reduxStore.getState().auth.user;
    return isGod(role, subrole) || isSuperAdmin(role, subrole);
  };

  const renderRoleChange = () => {
    if (!isSuperOrGod()) return null;
    const { role, subrole } = props.user;
    const button = (
      <EditRolesButton
        user={props.user}
        onUpdateSuccess={props.onUpdateContactSuccess}
      />
    );

    const roleName = User.getRoleName(role);
    const subroleName = User.getSubroleName(subrole);

    return (
      <div className="user-roles">
        {renderSectionTitle("Role Change", button)}
        <div className="label-value">
          <div className="label"> Role:</div>
          <div className="value">{roleName}</div>
        </div>
        <div className="label-value">
          <div className="label"> Subrole:</div>
          <div className="value">{subroleName}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="customer-info-section">
      <div className="name-buttons">
        {renderName()}
        {renderButtons()}
      </div>

      {renderDelinquentBanner()}

      <div className="customer-information">
        <div className="left">
          {renderContactInformation()}
          {renderUserDetails()}
        </div>
        <div className="right">
          {renderBilling()}
          {renderRoleChange()}
        </div>
      </div>
    </div>
  );
};

CustomerInfoSection.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateContactSuccess: PropTypes.func.isRequired,
};

export default CustomerInfoSection;
