import * as actionTypes from "actions/fulfillment/types";

const INITIAL_STATE = {
  shipmentSize: null,
  shipmentPackage: null,
  shipmentDate: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_STEP_ADD_PRODUCTS_MODIFIED,
    WRO_SELECT_SHIPMENT_SIZE,
    WRO_SELECT_SHIPMENT_PACKAGE,
    WRO_SELECT_SHIPMENT_DATE,
    WRO_CREATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_STEP_ADD_PRODUCTS_MODIFIED:
      return INITIAL_STATE;

    case WRO_SELECT_SHIPMENT_SIZE:
      return {
        ...state,
        shipmentSize: action.shipmentSize,
        shipmentPackage: null,
      };

    case WRO_SELECT_SHIPMENT_PACKAGE:
      return { ...state, shipmentPackage: action.shipmentPackage };

    case WRO_SELECT_SHIPMENT_DATE:
      return { ...state, shipmentDate: action.shipmentDate };

    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
