import * as actionTypes from "actions/fulfillment/types";
import { dropRight, remove } from "lodash-es";

const INITIAL_STATE = {
  productList: [],
  shipmentPackage: "",
  shipmentSize: "",
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_ADD_PRODUCT_TO_CART,
    WRO_MODIFY_PRODUCT_QUANTITY,
    WRO_UPDATE_PRODUCT_LOT_NUMBER,
    WRO_REMOVE_PRODUCT_FROM_CART,
    WRO_REMOVE_ALL_PRODUCTS_FROM_CART,

    WRO_SELECT_SHIPMENT_SIZE,
    WRO_SELECT_SHIPMENT_PACKAGE,

    WRO_UPDATE_BOX_QUANTITY,
    WRO_UPDATE_PRODUCT_BOX_QUANTITY,
    WRO_UPDATE_PRODUCT_SPECIFIC_BOX_QUANTITY,

    WRO_CREATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_ADD_PRODUCT_TO_CART: {
      const {
        id: product_id,
        sku,
        name,
        scid,
        tracks_lot_numbers,
        packagingLevel,
      } = action.product;
      const productList = [...state.productList];
      const productExisted = productList.find(
        (p) => p.product_id === product_id
      );
      if (!productExisted) {
        productList.push({
          product_id,
          sku,
          name,
          quantity: 1,
          scid,
          tracks_lot_numbers,
          lot_number: "",
          packagingLevel,
        });
      }

      return { ...state, productList };
    }

    case WRO_MODIFY_PRODUCT_QUANTITY: {
      const {
        product: { product_id, quantity },
      } = action;
      const productList = [...state.productList];
      const targetProduct = productList.find(
        (p) => p.product_id === product_id
      );
      targetProduct.quantity = +quantity;

      return { ...state, productList };
    }

    case WRO_UPDATE_PRODUCT_LOT_NUMBER: {
      const { product_id, lot_number } = action;
      const productList = [...state.productList];
      const targetProduct = productList.find(
        (p) => p.product_id === product_id
      );
      targetProduct.lot_number = lot_number;

      return { ...state, productList };
    }

    case WRO_REMOVE_PRODUCT_FROM_CART: {
      let productList = state.productList;
      remove(productList, (p) => p.product_id === action.product_id);
      return { ...state, productList };
    }

    case WRO_REMOVE_ALL_PRODUCTS_FROM_CART: {
      return { ...state, productList: [] };
    }

    case WRO_SELECT_SHIPMENT_SIZE:
      return { ...state, shipmentSize: action.shipmentSize };

    case WRO_SELECT_SHIPMENT_PACKAGE: {
      const productList = state.productList.map((product) => {
        return { ...product, quantityPerBox: 0 };
      });
      return { ...state, shipmentPackage: action.shipmentPackage, productList };
    }

    case WRO_UPDATE_BOX_QUANTITY: {
      const productList = [...state.productList];
      let maxSize = 0;
      for (let i = 0; i < productList.length; i++) {
        const size = productList[i].boxQty.length;
        if (size > maxSize) maxSize = size;
      }
      const currentQuantity = maxSize;
      let quantity = action.quantity > 20 ? 20 : action.quantity;
      const difference = quantity - currentQuantity;
      if (difference > 0) {
        for (let i = 0; i < productList.length; i++) {
          const product = productList[i];
          const { boxQty } = product;
          product.boxQty = boxQty.concat(Array(difference).fill(0));
        }
      } else if (difference < 0) {
        for (let i = 0; i < productList.length; i++) {
          const product = productList[i];
          const { boxQty } = product;
          product.boxQty = dropRight(boxQty, Math.abs(difference));
        }
      }
      return { ...state, productList };
    }

    case WRO_UPDATE_PRODUCT_BOX_QUANTITY: {
      const { product_id, quantity } = action;
      const productList = [...state.productList];
      const targetProduct = productList.find(
        (p) => p.product_id === product_id
      );
      targetProduct.quantityPerBox = +quantity;
      return { ...state, productList };
    }

    case WRO_UPDATE_PRODUCT_SPECIFIC_BOX_QUANTITY: {
      const { product_id, box_index, quantity } = action;
      const productList = [...state.productList];
      const targetProduct = productList.find(
        (p) => p.product_id === product_id
      );
      targetProduct.boxQty[box_index] = +quantity;
      return { ...state, productList };
    }

    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
