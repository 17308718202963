import styled from "styled-components";
import colors from "css/colors";

interface SearchResultWrapperProps {
  width?: number;
  reverse?: boolean;
}

export const SearchResultWrapper = styled.button<SearchResultWrapperProps>`
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  min-width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  min-height: 51px;
  padding: 0;

  &:not(:last-child) {
    border-bottom: ${(props) => (props.reverse ? 0 : "1px solid #e9e9e9")};
    border-top: ${(props) => (props.reverse ? "1px solid #e9e9e9" : 0)};
  }

  &:active {
    border: none;

    &:not(:last-child) {
      border-bottom: ${(props) => (props.reverse ? 0 : "1px solid #e9e9e9")};
      border-top: ${(props) => (props.reverse ? "1px solid #e9e9e9" : 0)};
    }
  }

  &:hover {
    background-color: ${colors.GREY_BACKGROUND};
  }
`;

interface SectionProps {
  flex?: number;
  column?: boolean;
}

export const Section = styled.div<SectionProps>`
  flex: ${(props) => (props.flex ? props.flex : "initial")};
  flex-direction: ${(props) => (props.column ? "column" : "initial")};
  padding: 8px;
  text-align: start;
  font-size: 12px;
`;

interface SectionTextProps {
  color?: string;
}

export const SectionText = styled.div<SectionTextProps>`
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : colors.CHARCOAL)};
  text-align: start;
`;
