import React from "react";
import PropTypes from "prop-types";
import ItemFilterButton from "../../../../../components/regular/customer/dependencies/ItemFilterButton";
import { isEmpty } from "lodash-es";

const ItemFilters = (props) => {
  const { nonSupplyItems, setSelectedFilter, selectedFilter } = props;
  if (isEmpty(nonSupplyItems)) return null;
  const itemsStripeIDs = nonSupplyItems.reduce((acc, cv) => {
    const foundID = acc.find((id) => id === cv.stripe_id);
    const isSpacePlanItem = cv.item_type === "Closet";
    if (!foundID && !isSpacePlanItem) acc.push(cv.stripe_id);
    return acc;
  }, []);

  if (itemsStripeIDs.length === 1) return null;
  const filterButtons = itemsStripeIDs.map((stripe_id) => {
    const count = nonSupplyItems.filter((i) => i.stripe_id === stripe_id)
      .length;
    return (
      <ItemFilterButton
        key={stripe_id}
        text={stripe_id}
        selected={selectedFilter === stripe_id}
        count={count}
        onClick={() => setSelectedFilter(stripe_id)}
      />
    );
  });

  return (
    <div className="item-filters">
      <ItemFilterButton
        key="All"
        text="All"
        selected={selectedFilter === "All"}
        count={nonSupplyItems.filter((i) => i.item_type !== "Closet").length}
        onClick={() => setSelectedFilter("All")}
      />
      {filterButtons}
    </div>
  );
};

ItemFilters.propTypes = {
  nonSupplyItems: PropTypes.array,
  selectedFilter: PropTypes.string.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
};

export default ItemFilters;
