import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

// Components
import { Select, Spin, Empty } from "antd";
import NewAddressButton from "./NewAddressButton";
import ErrorBox from "../../../fulfillment/_common/ErrorBox";

// Libs
import Address from "../../../../lib/regular/address";
import { isEmpty, findLastIndex } from "lodash-es";

// Hooks
import { useCustomerAddresses } from "../../../../hooks/api/regular/customer";

const SelectAddressCard = ({
  selectedAddress,
  onSelectAddress,
  selectedServiceArea,
}) => {
  const params = useParams();
  const { userID } = params;
  const {
    customerAddresses,
    createAddress,
    fetchCustomerAddresses,
  } = useCustomerAddresses();
  const { loading, data: addressList, error } = customerAddresses;

  const onMount = () => {
    fetchCustomerAddresses(userID);
  };

  const onAddressListChange = () => {
    const lastAddressIndex = findLastIndex(
      addressList,
      (a) => a.service_area === selectedServiceArea
    );

    if (lastAddressIndex > -1) onSelectAddress(addressList[lastAddressIndex]);
    else onSelectAddress(null);
  };

  useEffect(onMount, []);
  useEffect(onAddressListChange, [addressList, selectedServiceArea]);

  const selectAddress = (addressID) => {
    const selectedAddress =
      addressList && addressList.find((a) => a.id === addressID);
    onSelectAddress(selectedAddress);
  };

  if (loading) {
    return (
      <div className="select-address-card">
        <Spin tip="Fetching addresses..." />
      </div>
    );
  }

  if (!selectedServiceArea) {
    return (
      <div className="select-address-card">
        <div className="title">Select Address</div>
        <div className="instruction">Please select service area</div>
      </div>
    );
  }

  const getAddressesWithMatchingServiceArea = () => {
    return addressList.filter((a) => a.service_area === selectedServiceArea);
  };

  const validAddresses = getAddressesWithMatchingServiceArea();

  return (
    <div className="select-address-card">
      <div className="title">Select Address</div>
      <ErrorBox error={error} /> <br />
      {isEmpty(validAddresses) ? (
        <Empty
          description={`No addresses found within the ${selectedServiceArea?.toUpperCase()} service area`}
        />
      ) : (
        <Select
          placeholder="Please select an address"
          onChange={selectAddress}
          value={
            selectedAddress
              ? Address.getOneLineAddress(selectedAddress)
              : undefined
          }
        >
          {validAddresses.map((address, index) => (
            <Select.Option value={address.id} key={address.id + index}>
              {Address.getOneLineAddress(address)}
            </Select.Option>
          ))}
        </Select>
      )}
      <NewAddressButton
        createAddress={(address) => createAddress(userID, address)}
        createAddressError={error}
      />
    </div>
  );
};

SelectAddressCard.propTypes = {
  selectedAddress: PropTypes.object,
  onSelectAddress: PropTypes.func.isRequired,
  selectedServiceArea: PropTypes.string,
};

export default SelectAddressCard;
