import React from "react";
import PropTypes from "prop-types";
import {
  getAppointmentColor,
  getDisplayType,
} from "../../../../helperFunctions";
import { appointmentTypes } from "../../../../backendTypes";

const SummarySheet = ({ getAppointmentsCount, selectedDate }) => {
  const appointmentData = getAppointmentsCount(selectedDate);
  const data = appointmentTypes.map((type, index) => {
    let color = getAppointmentColor("type", type);
    let count = appointmentData[type];

    return (
      <div className="appointment-type-total" key={index}>
        <div className="appointment-type-tag" style={{ background: color }}>
          {getDisplayType(type)}
        </div>
        <div className="appointment-stat">{count}</div>
      </div>
    );
  });

  return (
    <div className="summary-sheet">
      <div className="selected-date">{selectedDate?.format("LL")}</div>
      {data}
    </div>
  );
};

SummarySheet.propTypes = {
  getAppointmentsCount: PropTypes.func.isRequired,
  selectedDate: PropTypes.object.isRequired,
};

export default SummarySheet;
