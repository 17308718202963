import styled from "styled-components";

export const OrderInfoWrap = styled.div`
  padding: 20px;
  background: white;
  border-radius: 2px;
  margin-bottom: 20px;
`;

export const CreateButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 0;
`;

export const CreateShipmentsButtonWrapper = styled.div`
  margin-right: 16px;
`;
