import React from "react";
import { useParams } from "react-router";
import { get } from "lodash-es";
import {
  FetchOrganizationDetailsQuery,
  useOrganizationDetails,
} from "hooks/api/fulfillment/organization";

import { Common } from "@secondcloset/types";
type Organization = Common.Organization;

interface OrganizationState {
  data?: Organization;
  loading: boolean;
  error?: any;
  createOrganization: (body: any) => Promise<any>;
  updateOrganization: (organizationID: string, body: any) => Promise<any>;
  fetchOrganizationDetails: (
    organizationID: string,
    query?: FetchOrganizationDetailsQuery
  ) => Promise<any>;
  reset: () => void;
}

const OrganizationContext = React.createContext<OrganizationState>({
  data: undefined,
  loading: false,
  error: undefined,
  createOrganization: () => Promise.resolve(),
  updateOrganization: () => Promise.resolve(),
  fetchOrganizationDetails: () => Promise.resolve(),
  reset: () => undefined,
});

OrganizationContext.displayName = "OrganizationContext";

/**
 * Fetches organization based on :organization_id in router
 * @param {React.ReactNode} children
 */
const OrganizationProvider: React.FC = ({ children }) => {
  const params = useParams();
  const organizationID = get(params, "organization_id");

  const {
    organizationDetails,
    fetchOrganizationDetails,
    createOrganization,
    updateOrganization,
    reset,
  } = useOrganizationDetails();

  React.useEffect(() => {
    if (organizationID) {
      fetchOrganizationDetails(organizationID, {
        with_facility_service_area: true,
      });
    }
    //eslint-disable-next-line
  }, [organizationID]);

  return (
    <OrganizationContext.Provider
      value={{
        ...organizationDetails,
        createOrganization,
        updateOrganization,
        fetchOrganizationDetails,
        reset,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

/**
 * Organization context helper
 * @returns {OrganizationContext} Organization context
 */
function useOrganizationContext() {
  const context = React.useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      "useOrganizationContext can be used only inside <OrganizationProvider />"
    );
  }

  return context;
}

export { OrganizationProvider, useOrganizationContext };
