import { setupAPI, getCancellableRequest, APIRequestConfig, APIDomain, APIVersion } from "@secondcloset/api-utils";

import { signOut } from "../actions/action_auth";
import BASE_URL, { BFAPI_URL, NOVEMBER_URL } from "../config/baseURL";
import store from "../config/reduxStore";

import Cookie from "./Cookies";

const { getAPIFromDomain: getAPI } = setupAPI({
  baseURL: String(BASE_URL).replace("/api/v1", ""),
  getToken: Cookie.getUserToken,
  interceptors: {
    response: {
      onRejected: (error) => {
        //clear cache when user is unauthorized
        if (error.response?.status === 401) {
          Cookie.deleteAll();
          store.dispatch(signOut());
        }
        throw error;
      },
    },
  },
});

export const getAPIFromDomain = (domain?: APIDomain, version?: APIVersion) => {
  const instance = getAPI(domain, version);
  if (domain === "november") instance.defaults.baseURL = NOVEMBER_URL;
  if (domain === "bfapi") instance.defaults.baseURL = BFAPI_URL;
  return instance;
};

export const getPDFRequestConfig = () =>
  ({
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "blob",
  } as Partial<APIRequestConfig>);

export { getCancellableRequest };
export * from "@secondcloset/api-utils/dist/types";
