import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Tooltip } from "antd";
import { Facility } from "@secondcloset/fulfillment-utils";

interface UpdateFulfilledFromButtonProps {
  orderID: string;
  updateOrder: (order_id: string, body: any) => any;
  fulfilledFrom: string;
  refetchOrder: () => unknown;
  loading: boolean;
  shipmentTags: string[];
}

const UpdateFulfilledFromButton: React.FC<UpdateFulfilledFromButtonProps> = ({
  orderID,
  updateOrder,
  fulfilledFrom,
  refetchOrder,
  loading,
  shipmentTags,
}) => {
  const [currentWarehouseSelection, setCurrentWarehouseSelection] = useState<
    string | null
  >(null);
  const [modalVisible, setModalVisible] = useState(false);
  const isEditButtonDisabled = !shipmentTags.find(
    (i) => i === "unfulfilled_items" || i === "ready_to_fulfill"
  );

  const updateFulfilledFrom = async () => {
    const body = { orders: { fulfilled_from: currentWarehouseSelection } };
    const order = await updateOrder(orderID, body);
    if (order) {
      refetchOrder();
    }
    setModalVisible(false);
  };

  const renderWarehouseSelect = () => {
    const { Option } = Select;
    const facilityList = Facility.getFulfillmentFacilityList();
    return (
      <div className="warehouse-select-wrapper">
        <div className="warehouse-select">
          <div className="select-label">Fulfilled from:</div>
          <Select
            defaultValue={fulfilledFrom}
            onChange={setCurrentWarehouseSelection}
          >
            {facilityList.map((facility) => {
              return (
                <Option value={facility.code} key={facility.code}>
                  {facility.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <Button
          className="warehouse-select-button"
          type="primary"
          onClick={updateFulfilledFrom}
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        title="Edit Fulfilled From"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {renderWarehouseSelect()}
      </Modal>
    );
  };

  return (
    <div className="update-fulfilled-from">
      <Tooltip
        overlayInnerStyle={{ minWidth: "300px" }}
        placement="bottom"
        title={
          isEditButtonDisabled
            ? "Edit Fulfilled From is disabled when a shipment exists"
            : ""
        }
      >
        <Button
          disabled={isEditButtonDisabled}
          className="edit-fulfilled-from-button"
          onClick={() => setModalVisible(true)}
          icon={<EditOutlined />}
        >
          Edit Fulfilled From
        </Button>
      </Tooltip>
      {renderModal()}
    </div>
  );
};

export default UpdateFulfilledFromButton;
