import { SET_ACTIVE_FACILITY } from "../actions/facility/types";
import { Facility } from "@secondcloset/fulfillment-utils";

const INITIAL_STATE = Facility.getFacilityList()[0];
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_FACILITY:
      return action.data;
    default:
      return state;
  }
}
