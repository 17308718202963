import { getAPIFromDomain } from "../../lib/api";
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";

export const fetchRecommendedShippingMethod = async (
  orderItemIDs: string[]
): Promise<SelectShipmentCarrierTypes.RecommendedShippingMethod | null> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments/recommended_method`;
  const body = {
    order_item_ids: orderItemIDs,
  };

  const response = await api.post(url, body);
  return response.data.recommended_shipping_method;
};
