import React from "react";
import PropTypes from "prop-types";

const StandardCard = ({ className, children }) => (
  <div className={className ? className : "standardCard col-xs-12"}>
    {children}
  </div>
);

StandardCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default StandardCard;
