import React, { useEffect } from "react";

//Hooks
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import {
  useOrderCancel,
  useOrderUpdate,
} from "../../../../hooks/api/fulfillment/orders";

//components
import { Tag, Popconfirm, Button, notification, Spin, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ShipmentTagDropdown } from "@secondcloset/web-components";
import FulfillmentBackButton from "../../FulfillmentBackButton";
import UpdateFulfilledFromButton from "./UpdateFulfilledFromButton";

// libs
import ShipmentTags from "../../../../lib/fulfillment/shipmentTags";
import Order from "../../../../lib/fulfillment/order";
import { isEmpty } from "lodash-es";
import Organization from "../../../../lib/organization";
import { Shipment } from "@secondcloset/fulfillment-utils";
import { isValidBulkOrderShipmentTag, ShipmentTag } from "./helpers";

//API
import { bulkUpdateOrders } from "../../../../api/fulfillment/order";
import UpdateShippingServiceButton from "./UpdateShippingServiceButton";
import { Fulfillment } from "@secondcloset/types";
interface OrderHeaderProps {
  order: Fulfillment.Order;
  onRefetchOrder: () => unknown;
}

const OrderHeader: React.FC<OrderHeaderProps> = ({ order, onRefetchOrder }) => {
  const history = useHistory();
  const orderID = order.id;
  const organizationID = order.organization.id;
  const isIKEA = Organization.isIKEA(order.organization);
  const isOrderCancelled = !!order?.cancelled;
  const { cancelOrder, orderCancelDetails } = useOrderCancel();
  const { updateOrder, orderUpdateDetails } = useOrderUpdate();
  const onError = (error: string) => {
    notification.error({
      message: "Error",
      description: error,
    });
  };

  const {
    mutateAsync: onUpdateShipmentReadyStatus,
    ...updateShipmentReadyStatus
  } = useMutation(bulkUpdateOrders, {
    onSuccess: () => {
      onRefetchOrder();
    },
    onError,
  });

  const error = orderCancelDetails.error || orderUpdateDetails.error;
  useEffect(() => {
    if (error) onError(error);
  }, [error]);

  const onCancelOrder = () => {
    cancelOrder(orderID).then(onRefetchOrder);
  };

  const renderCancelButton = () => {
    if (isOrderCancelled)
      return (
        <Tag style={{ minHeight: "auto" }} color="red">
          Cancelled
        </Tag>
      );
    return (
      <Popconfirm
        placement="topLeft"
        title="Are you sure you want to cancel the order?"
        onConfirm={onCancelOrder}
        okText="Yes"
        cancelText="No"
      >
        <Button loading={orderCancelDetails.loading} danger>
          Cancel Order
        </Button>
      </Popconfirm>
    );
  };

  const renderReturnItemsButton = () => {
    const returnableItems = Order.getReturnableItems(order) || {};
    const link = `/fulfillment/orders/${orderID}/create-shipment?isReturn=true`;
    return (
      <Button
        type="primary"
        onClick={() => history.push(link)}
        style={{ marginRight: "10px" }}
        disabled={isEmpty(returnableItems) || isIKEA}
      >
        Return Items
      </Button>
    );
  };

  const onReadyStatusUpdate = (
    shipmentTag: Exclude<ShipmentTag, "customer_booking" | "internal_booking">
  ) => {
    if (!isValidBulkOrderShipmentTag(shipmentTag) || !orderID) return;
    const body = { order_ids: [orderID], shipment_tag: shipmentTag };
    onUpdateShipmentReadyStatus(body);
  };

  const renderShipmentTag = (shipmentTag: ShipmentTag) => {
    return (
      <Tag
        key={shipmentTag}
        color={ShipmentTags.getShipmentTagColor(shipmentTag)}
      >
        {ShipmentTags.getShipmentTagText(shipmentTag)}
      </Tag>
    );
  };

  const renderShipmentTagDropdown = (shipmentTag: ShipmentTag) => {
    return (
      <ShipmentTagDropdown
        shipmentTag={
          shipmentTag as Exclude<
            ShipmentTag,
            "customer_booking" | "internal_booking"
          >
        }
        loading={updateShipmentReadyStatus.status === "loading"}
        onReadyStatusUpdate={onReadyStatusUpdate}
      />
    );
  };

  const renderShipmentTags = () => {
    const shipmentTags = [...order.shipment_tags] as Array<ShipmentTag>;
    if (Shipment.hasInternalBooking(order.shipments))
      shipmentTags.push("internal_booking");

    return (
      <div className="shipment-tags">
        Shipment Tags:
        {shipmentTags.map((t) =>
          !isOrderCancelled && isValidBulkOrderShipmentTag(t)
            ? renderShipmentTagDropdown(t)
            : renderShipmentTag(t)
        )}
      </div>
    );
  };

  const renderEditFulfilledFrom = () => {
    const shipmentTags = [...order.shipment_tags] as Array<ShipmentTag>;
    if (orderUpdateDetails.loading) {
      const antIcon = <LoadingOutlined spin />;
      return <Spin indicator={antIcon} />;
    }
    return (
      <UpdateFulfilledFromButton
        refetchOrder={onRefetchOrder}
        updateOrder={updateOrder}
        fulfilledFrom={order?.fulfilled_from}
        orderID={orderID}
        loading={orderUpdateDetails.loading}
        shipmentTags={shipmentTags}
      />
    );
  };

  return (
    <div className="order-header">
      <div className="header-buttons">
        <FulfillmentBackButton
          urlPath={`/organizations/${organizationID}`}
          label={`Back to ${order.organization.name}`}
        />
        <div>
          {renderReturnItemsButton()}
          {renderCancelButton()}
        </div>
      </div>
      <div className="order-id-with-edit-btn">
        <h1 className="foid">Order ID: {order?.order_id}</h1>
        <Space>
          {renderEditFulfilledFrom()}
          <UpdateShippingServiceButton
            order={order}
            onUpdateShippingServiceSuccess={onRefetchOrder}
          />
        </Space>
      </div>
      {renderShipmentTags()}
    </div>
  );
};

export default OrderHeader;
