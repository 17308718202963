import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Spin, DatePicker, Checkbox, Button, Tag } from "antd";
import ErrorBox from "../../../fulfillment/_common/ErrorBox";
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";
import { AvailabilitySlot } from "@secondcloset/logistics-utils";
import { cloneDeep, isEmpty } from "lodash-es";
import moment from "moment";
import { useAppointmentAvailability } from "../../../../hooks/api/logistics/availabilities";

const SelectAppointmentDatesCard = (props) => {
  const {
    appointmentAvailability,
    fetchAvailableDates,
  } = useAppointmentAvailability(
    props.isFulfillment ? "fulfillment" : "storage"
  );
  const [availabilityInstance, setAvailabilityInstance] = useState(null);

  const getOrderItemsString = (orderItems) => {
    if (!Array.isArray(orderItems)) return "";
    return orderItems
      .map((item) => `${item.stripe_plan_id}${item.quantity}`)
      .join("");
  };

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  const getAvailabilities = useCallback(() => {
    if (
      props.selectedConsumerSegment &&
      props.addressID &&
      !isEmpty(props.orderItems)
    ) {
      const address_id = props.addressID;
      const customer_segment = props.selectedConsumerSegment;
      const order_items = props.orderItems;
      const today = moment().format("YYYY-MM-DD");
      const daysAhead = moment().add(90, "days").format("YYYY-MM-DD");
      const option = {
        address_id,
        customer_segment,
        order_items,
        date_from: today,
        date_to: daysAhead,
      };
      fetchAvailableDates(option);
    }
  }, [
    props.selectedConsumerSegment,
    props.addressID,
    getOrderItemsString(props.orderItems),
  ]);

  useEffect(() => {
    getAvailabilities();
  }, [getAvailabilities]);

  useEffect(() => {
    props.onDateTimeSelect({
      date1: null,
      date2: null,
      time1: null,
      time2: null,
      immediate: false,
    });
    if (appointmentAvailability.data) {
      const instance = new AppointmentsAvailability(
        appointmentAvailability.data
      );
      setAvailabilityInstance(instance);
    }
  }, [appointmentAvailability.data]);

  const onDatePickerChange = (date, isDayOne) => {
    const appointmentDates = cloneDeep(props.appointmentDates);
    const targetDate = isDayOne ? "date1" : "date2";
    const targetTime = isDayOne ? "time1" : "time2";
    appointmentDates[targetDate] = date ? date.format("YYYY-MM-DD") : null;
    appointmentDates[targetTime] = null;
    if (isDayOne) appointmentDates.date2 = null;
    props.onDateTimeSelect(appointmentDates);
  };

  const renderDatePicker = (isDayOne) => {
    const { date1, date2, time1 } = props.appointmentDates;
    const disabledDatePicker = !isDayOne && !date1;
    const datePickerValue = isDayOne ? date1 : date2;

    const isDayOneDisabled = (date) => {
      /**
       * Business requirements : Return orders
       * Block weekends if we are booking returns on Friday
       */
      const getWeekday = (m) => m.format("dddd");
      const isTodayFriday = getWeekday(moment()) === "Friday";
      const weekday = getWeekday(moment(date, "YYYY-MM-DD"));
      const isSelectedDateWeekend =
        weekday === "Sunday" || weekday === "Saturday";
      if (isTodayFriday && isSelectedDateWeekend && props.isReturn) return true;

      const formatedDate = date ? date.format("YYYY-MM-DD") : null;
      return availabilityInstance.isDateOneDisabled(formatedDate);
    };

    const isDayTwoDisabled = (date) => {
      const formatedDate = date ? date.format("YYYY-MM-DD") : null;
      return availabilityInstance.isDateTwoDisabled(date1, time1, formatedDate);
    };

    const disabledDate = isDayOne ? isDayOneDisabled : isDayTwoDisabled;

    return (
      <DatePicker
        value={datePickerValue ? moment(datePickerValue, "YYYY-MM-DD") : null}
        disabled={disabledDatePicker}
        disabledDate={disabledDate}
        onChange={(date) => onDatePickerChange(date, isDayOne)}
      />
    );
  };

  const onSlotChange = (slot, isDayOne) => {
    const appointmentDates = cloneDeep(props.appointmentDates);
    const targetTime = isDayOne ? "time1" : "time2";
    appointmentDates[targetTime] = slot;
    if (isDayOne) appointmentDates["time2"] = null;
    props.onDateTimeSelect(appointmentDates);
  };

  const renderTimeSlotsButtons = (isDayOne) => {
    const { date1, date2, time1, time2 } = props.appointmentDates;
    const date = isDayOne ? date1 : date2;
    const slots = availabilityInstance.getTimeslots(date);

    const isSlotOneDisabled = (timeslot) =>
      availabilityInstance.isSlotOneDisabled(date1, timeslot);
    const isSlotTwoDisabled = (timeslot) =>
      availabilityInstance.isSlotTwoDisabled(date1, time1, date2, timeslot);

    const isSlotDisabled = isDayOne ? isSlotOneDisabled : isSlotTwoDisabled;

    return (
      <div className="time-slot-btns">
        {slots.map((slot) => {
          const timeslot = slot.timeslot;
          const selected = isDayOne ? time1 === timeslot : time2 === timeslot;
          return (
            <Button
              key={date + timeslot}
              type={selected ? "primary" : "default"}
              onClick={() => onSlotChange(timeslot, isDayOne)}
              disabled={isSlotDisabled(timeslot)}
            >
              {AvailabilitySlot.getDisplaySlot(timeslot)}
            </Button>
          );
        })}
      </div>
    );
  };

  const buildSingleDateTime = (title, isDayOne) => {
    return (
      <div className="single-date">
        <div className="date-title">{title}</div>
        {renderDatePicker(isDayOne)}
        {renderTimeSlotsButtons(isDayOne)}
      </div>
    );
  };

  const buildDateTime = () => {
    let title1, title2;
    const { isBulky, isStorage } = props;
    const oneDateOnly = props.appointmentDates.immediate || isBulky;
    if (oneDateOnly && isStorage) {
      title1 = isBulky ? "Pick Up" : "Immediate Pick Up";
    } else if (oneDateOnly && isStorage) {
      title1 = isBulky ? "Return" : "Immediate Retrieve";
    } else if (!oneDateOnly && isStorage) {
      title1 = "Drop off";
      title2 = "Pick Up";
    } else {
      title1 = "Return";
      title2 = "Retrieve";
    }

    if (oneDateOnly) {
      return (
        <div className="appointment-dates">
          {buildSingleDateTime(title1, true)}
        </div>
      );
    }

    return (
      <div className="appointment-dates">
        {buildSingleDateTime(title1, true)}
        {buildSingleDateTime(title2, false)}
      </div>
    );
  };

  const onImmediateChange = (immediate) => {
    const appointmentDates = cloneDeep(props.appointmentDates);
    appointmentDates.immediate = immediate;
    props.onDateTimeSelect(appointmentDates);
  };

  if (
    !props.addressID ||
    !props.selectedConsumerSegment ||
    isEmpty(props.orderItems)
  ) {
    return (
      <div className="select-appointment-date empty">
        <div className="title">Appointment Dates</div>
        <div className="instruction">
          Please Select Address, Customer Segment and Items
        </div>
      </div>
    );
  }

  if (appointmentAvailability.loading || props.externalLoading) {
    return (
      <div className="select-appointment-date spinning">
        <Spin tip="fetching..." />
      </div>
    );
  }

  return (
    <div className="select-appointment-date">
      <div className="title">
        Appointment Dates {props.isBulky && <Tag color="red">Bulky Only</Tag>}
      </div>
      <div className="description">
        Blocked date/time slots will be hidden or disabled
      </div>

      <Checkbox
        checked={props.appointmentDates.immediate}
        onChange={(e) => onImmediateChange(e.target.checked)}
        disabled={props.isBulky || props.disableImmediate}
      >
        Immediate
      </Checkbox>
      <hr />
      <ErrorBox error={appointmentAvailability.error} />
      {availabilityInstance && buildDateTime()}
    </div>
  );
};

SelectAppointmentDatesCard.propTypes = {
  isBulky: PropTypes.bool.isRequired,
  isStorage: PropTypes.bool.isRequired,
  onDateTimeSelect: PropTypes.func.isRequired,
  appointmentDates: PropTypes.object.isRequired,
  externalLoading: PropTypes.bool,
  disableImmediate: PropTypes.bool,
  selectedConsumerSegment: PropTypes.string,
  addressID: PropTypes.string,
  orderItems: PropTypes.array.isRequired,
  isFulfillment: PropTypes.bool,
  isReturn: PropTypes.bool,
};

export default SelectAppointmentDatesCard;
