import React from "react";
import { connect } from "react-redux";

const mapStateToProps = ({ activeFacility: { name, service_area } }) => {
  return { activeFacilityName: name, serviceArea: service_area };
};

const withFacility = (Component) => {
  const newComponent = connect(mapStateToProps)((props) => (
    <Component {...props} />
  ));

  return newComponent;
};

export default withFacility;
