import {
  CUSTOMERS_UPDATE_TIME,
  ORDERS_UPDATE_TIME,
  APPOINTMENT_UPDATE_TIME,
} from "../actions";

const INITIAL_STATE = {
  customerUpdatedTime: undefined,
  orderUpdatedTime: undefined,
  appointmentUpdatedTime: undefined,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case CUSTOMERS_UPDATE_TIME:
      return { ...state, customerUpdatedTime: payload };
    case ORDERS_UPDATE_TIME:
      return { ...state, orderUpdatedTime: payload };
    case APPOINTMENT_UPDATE_TIME:
      return { ...state, appointmentUpdatedTime: payload };
    default:
      return state;
  }
}
