import React from "react";

// components
import { Modal, Button } from "antd";

// styles
import * as S from "./styles";

// lib
import Shipment from "../../../../../../lib/fulfillment/shipment";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type ShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: ShipmentType;

  visible: boolean;
  setVisible: (visible: boolean) => unknown;
}

const FieldOpsNotesModal: React.FC<Props> = ({
  shipment,
  visible,
  setVisible,
}) => {
  const renderNotes = () => {
    const shippingMethod = shipment?.shipping_method as ShippingMethod;

    const latestNotes = Shipment.getLastWrittenNote(
      shippingMethod?.field_ops_notes
    );

    if (!latestNotes) return <S.EmptyNotes>No Notes</S.EmptyNotes>;
    return <S.NoteContainer>{latestNotes}</S.NoteContainer>;
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title="Field Ops Notes"
      footer={[
        <Button key="done" onClick={() => setVisible(false)} type="primary">
          OK
        </Button>,
      ]}
    >
      {renderNotes()}
    </Modal>
  );
};

export default FieldOpsNotesModal;
