// interface
import { Storage, Fulfillment } from "@secondcloset/types";
type AppointmentType = Storage.AppointmentType;
type AppointmentStatus = Storage.AppointmentStatus;
type FulfillmentAppointmentType = Fulfillment.AppointmentType;
type FulfillmentAppointmentStatus = Fulfillment.AppointmentStatus;

const colors = {
  red: "#da4453",
  green: "#8cc152",
  orange: "#f90",
  mint: "#37bc9b",
  yellow: "#f6bb42",
  skyBlue: "#3bafda",
  oceanBlue: "#4A89DC",
  purple: "#ac92ec",
  pink: "#ffc0cb",
  navy: "#001f3f",
};

const {
  red,
  green,
  orange,
  mint,
  yellow,
  skyBlue,
  oceanBlue,
  purple,
  pink,
  navy,
} = colors;

const getAppointmentColor = (
  kind: "type" | "status",
  data:
    | Fulfillment.ShipmentActionType
    | FulfillmentAppointmentStatus
    | AppointmentStatus
) => {
  if (kind === "type") {
    switch (data as FulfillmentAppointmentType | AppointmentType) {
      case "pick_up":
      case "inventory_pick_up":
        return orange;
      case "drop_off":
        return skyBlue;
      case "immediate_pick_up":
        return oceanBlue;
      case "return":
        return purple;
      case "retrieve":
        return yellow;
      case "immediate_retrieve":
        return mint;
      case "return_to_sender":
      case "reverse_logistics":
        return green;
      case "cross_dock_delivery":
      case "delivery":
        return navy;
      default:
        return pink;
    }
  } else {
    //kind === status
    switch (data as FulfillmentAppointmentStatus | AppointmentStatus) {
      case "request_received":
        return yellow;
      case "confirmed":
      case "shipment_created":
        return green;
      case "fulfillment":
        return orange;
      case "ready":
        return orange;
      case "loaded":
        return purple;
      case "cancelled":
        return red;
      case "scheduled":
        return skyBlue;
      case "fulfilled":
        return purple;
      case "on_the_way":
        return oceanBlue;
      case "active":
        return pink;
      case "done":
        return yellow;
      case "staging":
        return purple;
      case "completed":
        return oceanBlue;
      case "started":
        return mint;
      case "arrived":
        return mint;
      case "incomplete":
      case "failed":
      case "no_show":
        return red;
      default:
        return "black";
    }
  }
};

const getOrderColor = (kind: any, data: any) => {
  if (kind === "type") {
    switch (data) {
      case "storage":
        return green;
      case "return":
        return skyBlue;
      case "cancellation":
        return red;
      default:
        return "black";
    }
  } else {
    //kind === status
    switch (data) {
      case "fulfilled":
        return green;
      case "processing":
        return yellow;
      case "waiting_for_payment":
      case "cancelled_by_user":
        return red;
      case "placed":
        return skyBlue;
      case "uploaded":
      default:
        return "black";
    }
  }
};

const getInventoryColor = (data: any) => {
  switch (data) {
    case "requested":
      return yellow;
    case "in_storage":
      return green;
    case "being_returned":
      return red;
    case "returned":
      return oceanBlue;
    default:
      return pink;
  }
};

export { getAppointmentColor, getInventoryColor, getOrderColor };
