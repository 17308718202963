import { Fulfillment } from "@secondcloset/types";
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";
import { ServiceLevel } from "@secondcloset/fulfillment-utils";
const { ServiceCategory } = SelectShipmentCarrierTypes;
type Carrier = Fulfillment.Carrier;

export const isSecondCloset = (carrier: Carrier) => {
  return carrier?.carrier_code === ServiceCategory.second_closet;
};

export const getSecondClosetCarrier = () => {
  const serviceLevels = ServiceLevel.getServiceLevelList("second_closet");
  return {
    carrierCode: "second_closet",
    options: serviceLevels,

    logo:
      "https://res.cloudinary.com/second-closet-develop/image/upload/v1572898407/jckuo1ekfubflnty8hok.png",
  };
};
