import { get, isEmpty } from "lodash-es";
import { Product } from "@secondcloset/fulfillment-utils";

export default class Item {
  static getName = (item) => get(item, "product.name");
  static getProductID = (item) => get(item, "product.id");
  static getSku = (item) => get(item, "product.sku");
  static isVirtualKit = (item) => get(item, "product.product_type") === "kit";
  static isBaseProduct = (item) => get(item, "product.product_type") === "base";
  static belongsToVirtualKit = (item) => !!get(item, "virtual_kit_id");
  static isPartOfVirtualKit = (item) =>
    this.isVirtualKit(item) || this.belongsToVirtualKit(item);

  static getStructuredItems = (orderItems, facility) => {
    if (isEmpty(orderItems)) return {};

    return orderItems.reduce((acc, cv) => {
      const product = get(cv, "product");
      const availableStockForBooking = Product.getFacilityStockQuantity(
        product,
        facility,
        {
          isCalculateAvailableStockForBooking: true,
        }
      );
      const productID = get(product, "id");
      if (!productID) return acc;
      if (!acc[productID]) {
        acc[productID] = {
          id: productID,
          quantity: 0,
          availableStock: availableStockForBooking,
          itemIDs: [],
          productType: get(product, "product_type"),
          name: Item.getName(cv),
          sku: Item.getSku(cv),
        };
      }

      const quantity = get(cv, "quantity", 0);
      const itemID = get(cv, "id", null);

      acc[productID].quantity += quantity;
      acc[productID].itemIDs.push(itemID);

      return acc;
    }, {});
  };
}
