import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useOrder } from "../../../hooks/api/regular/order";
import { Spin, Tag, notification } from "antd";
import OrderInfo from "./OrderInfo";
import OrderItems from "./OrderItems/OrderItems";
import AppointmentDetails from "./AppointmentDetails/AppointmentDetails";

const StorageOrderDetailsPage = ({ match }) => {
  const { order, fetchOrder, cancelOrder } = useOrder();
  const { userID, orderID } = match.params;

  const onMount = () => {
    fetchOrder(userID, orderID);
  };

  const onCancelError = () => {
    if (order.error) {
      notification.error({
        message: "Error",
        description: order.error,
      });
    }
  };

  useEffect(onMount, []);
  useEffect(onCancelError, [order]);

  if (!order.data && order.loading) {
    return (
      <div className="order-details-page loading">
        <Spin tip="fetching order..." />
      </div>
    );
  }

  if (!order.data) return null;

  const renderUserInfo = () => {
    const { user_firstname, user_lastname, user_email, user_id } = order.data;
    return (
      <div className="user-info card">
        <div className="title"> Customer</div>
        <div className="name">
          Name:{" "}
          <Link
            to={`/customers/${user_id}`}
          >{`${user_firstname} ${user_lastname}`}</Link>
        </div>
        <div className="email">Email: {user_email}</div>
      </div>
    );
  };

  const renderOtherInfo = () => {
    const { immediate, bulky_order_only, notes } = order.data;
    return (
      <div className="other-info card">
        <div className="title">Other Information</div>
        <div className="option-row notes">
          Notes: <div className="order-notes">{notes}</div>
        </div>
        <div className="option-row">
          Bulky Only:{" "}
          <Tag color={bulky_order_only ? "green" : "red"}>
            {bulky_order_only ? "YES" : "NO"}
          </Tag>
        </div>
        <div className="option-row">
          Immediate:{" "}
          <Tag color={immediate ? "green" : "red"}>
            {immediate ? "YES" : "NO"}
          </Tag>
        </div>
      </div>
    );
  };
  return (
    <div className="page-container" id="storage-order-details-page">
      <OrderInfo
        order={order}
        onCancelOrder={() => cancelOrder(userID, orderID)}
      />

      <div className="order-content">
        <div className="left">
          {renderUserInfo()}
          <OrderItems order={order} />
        </div>
        <div className="right">
          <AppointmentDetails
            order={order}
            onCreateAppointmentSuccess={() => fetchOrder(userID, orderID)}
          />
          {renderOtherInfo()}
        </div>
      </div>
    </div>
  );
};

StorageOrderDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(StorageOrderDetailsPage);
