import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash-es";
import { EditOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";

const OrderSummary = (props) => {
  const [newCoupon, setNewCoupon] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const groupedItems = props.order.items.reduce((acc, cv) => {
    const { name, item_type, quantity, stripe_id } = cv;
    let keyName = `${name} - ${stripe_id}`;
    if (item_type === "Product") keyName += " (Product)";
    if (!acc[keyName]) acc[keyName] = cloneDeep(cv);
    else acc[keyName].quantity += quantity;
    return acc;
  }, {});

  const onOrderUpdated = () => {
    setNewCoupon("");
    setIsEditMode(false);
  };

  useEffect(onOrderUpdated, [props.order]);

  const buildItems = () => {
    return (
      <div className="summary-items">
        {Object.keys(groupedItems).map((key) => {
          const { name, item_type, quantity, stripe_id } = groupedItems[key];
          let displayName = name;
          if (item_type === "Product") displayName += " (Product)";
          return (
            <div key={key} className="item">
              {quantity} x {displayName} <span>{stripe_id}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const buildCouponDisplay = () => {
    const { coupon, status } = props.order;
    if (!coupon) return <i>none</i>;
    const couponValue = coupon.percent_off
      ? `- ${coupon.percent_off}%`
      : `- $${(coupon.amount_off / 100).toFixed(2)}`;
    return (
      <div className="coupon-display">
        <div className="coupon-value">{couponValue}</div>
        <Button
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          className="close-btn"
          onClick={() => {
            setIsEditMode(true);
            setNewCoupon(coupon.stripe_coupon_id);
          }}
          disabled={status === "fulfilled"}
        />
      </div>
    );
  };

  const updateCoupon = () => {
    const body = { order: { stripe_coupon_id: newCoupon } };
    props.updateOrder(props.order.user_id, props.order.id, body);
  };

  const buildCouponEdit = () => {
    return (
      <div className="coupon-action-group">
        <Input
          size="small"
          value={newCoupon}
          onChange={(e) => setNewCoupon(e.target.value)}
        />
        <Button
          size="small"
          className="apply-button"
          disabled={!newCoupon.trim()}
          onClick={updateCoupon}
          loading={props.order.loading}
        >
          Apply
        </Button>
      </div>
    );
  };

  const buildCouponField = () => {
    const { status, coupon } = props.order;
    const showEdit = status !== "fulfilled" && (isEditMode || !coupon);
    return (
      <div className="coupon-field">
        <div className="coupon-wrap">
          <div className="label">Coupon:</div>
          {showEdit ? buildCouponEdit() : buildCouponDisplay()}
        </div>
      </div>
    );
  };

  const buildMoneyBreakdown = () => {
    const { subtotal, total, tax_percentage } = props.order;
    const tax = subtotal * (tax_percentage / 100);
    const formatMoney = (s) => `$${(s / 100).toFixed(2)}`;
    return (
      <div className="money-breakdown">
        <div>
          <div className="money-row">
            <div className="label">Subtotal:</div>
            <div className="value">{formatMoney(subtotal)}</div>
          </div>
          <div className="money-row">
            <div className="label tax">Tax:</div>
            <div className="value">{formatMoney(tax)}</div>
          </div>
          <div className="money-row">
            <div className="label">Total:</div>
            <div className="value">{formatMoney(total)}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="order-edit-summary">
      <div className="title">Order Summary</div>
      {buildItems()}
      {buildCouponField()}
      {buildMoneyBreakdown()}
    </div>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.object.isRequired,
  updateOrder: PropTypes.func.isRequired,
};

export default OrderSummary;
