export function compressSpaceItems(array) {
  /**
   * function(array) -> array
   *
   * compressing items with the same name into
   * one item and add up their quantities
   */

  if (!array || !Array.isArray(array)) {
    return [];
  }

  let result = array.reduce((acc, obj) => {
    let proper_name = !obj.category_name ? obj.name : obj.category_name;
    let item_type = obj.item_type;
    let isProduct = item_type === "Product";

    if (isProduct) {
      proper_name = `${proper_name} (Product)`;
    }

    if (!acc[proper_name]) {
      acc[proper_name] = JSON.parse(JSON.stringify(obj));
      acc[proper_name].inventoryItemIDs = [];
      acc[proper_name].barcodes = [];
      acc[proper_name].quantity = 0;
      acc[proper_name].item_type = item_type;
    }

    acc[proper_name].quantity += obj.quantity;
    acc[proper_name].inventoryItemIDs.push(obj.item_id);
    if (obj.barcode && obj.barcode !== "") {
      acc[proper_name].barcodes.push(obj.barcode);
    }
    return acc;
  }, {});

  return result;
}
