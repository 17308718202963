import * as actionTypes from "../../../actions/fulfillment/types/product";

const INITIAL_STATE = {
  fetching: false,
  data: null,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    FULFILLMENT_PRODUCTS_REQUESTED,
    FULFILLMENT_PRODUCTS_RECEIVED,
    FULFILLMENT_PRODUCTS_ERRORED,
    FULFILLMENT_PRODUCTS_CLEAR_DATA,
  } = actionTypes;

  switch (action.type) {
    case FULFILLMENT_PRODUCTS_REQUESTED:
      return { ...state, fetching: true };
    case FULFILLMENT_PRODUCTS_RECEIVED:
      return { ...state, fetching: false, data: action.data, error: null };
    case FULFILLMENT_PRODUCTS_ERRORED:
      return { ...state, fetching: false, data: null, error: action.error };
    case FULFILLMENT_PRODUCTS_CLEAR_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
