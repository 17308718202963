import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Select, Input, Button, InputNumber } from "antd";
import { Spin, Tag, Alert, Upload, Tooltip } from "antd";
import { objectToFormData } from "object-to-formdata";

const CategoryForm = (props) => {
  const [newName, setNewName] = useState("");
  const [newSynonyms, setNewSynonyms] = useState([]);
  const [newStripeMapping, setNewStripeMapping] = useState(null);
  const [newPublic, setNewPublic] = useState(true);
  const [newVolume, setNewVolume] = useState(0);
  const [showTagInput, setShowTagInput] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [error, setError] = useState(null);
  const [newImageUrl, setNewImageUrl] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const inputEl = useRef(null);

  const getDefaultImageUrl = () => {
    let defaultImage;
    try {
      defaultImage = props.category.default_image.url;
    } catch (_) {
      defaultImage = null;
    }
    return defaultImage;
  };

  const onMount = () => {
    if (props.category) {
      const {
        name,
        synonyms,
        volume,
        public: p,
        stripe_plan_id,
      } = props.category;
      setNewName(name);
      setNewImageUrl(getDefaultImageUrl());
      setNewSynonyms(synonyms || []);
      setNewVolume(volume);
      setNewPublic(p);
      setNewStripeMapping(stripe_plan_id);
    }
  };

  const clearError = () => {
    setError(null);
  };

  const focusTagInput = () => {
    if (showTagInput && inputEl.current) {
      inputEl.current.focus();
    }
  };

  useEffect(onMount, []);
  useEffect(clearError, [newName, newStripeMapping]);
  useEffect(focusTagInput, [showTagInput]);

  const buildRemoveImageBtn = () => {
    if (!newImageUrl) return null;
    return (
      <div
        className="remove-image-btn"
        onClick={() => {
          setNewImageUrl(null);
          setNewImageFile(null);
        }}
      >
        <Tooltip trigger={["hover"]} title="remove image">
          <DeleteOutlined />
        </Tooltip>
      </div>
    );
  };

  const buildImageUpload = () => {
    return (
      <div className="image-display">
        {newImageUrl ? (
          <img src={newImageUrl} alt="category" />
        ) : (
          <Upload
            customRequest={() => {}}
            showUploadList={false}
            action={(file) => {
              setNewImageFile(file);
              setNewImageUrl(URL.createObjectURL(file));
            }}
          >
            <div className="add-img-bubble">
              <PlusOutlined />
            </div>
          </Upload>
        )}
        {buildRemoveImageBtn()}
      </div>
    );
  };

  const buildSynonymsControl = () => {
    const removeTag = (index) => {
      const temp = [...newSynonyms];
      temp.splice(index, 1);
      setNewSynonyms(temp);
    };

    const handleInputConfirm = () => {
      setShowTagInput(false);
      if (tagValue) setNewSynonyms([...newSynonyms, tagValue]);
      setTagValue("");
    };

    const tags = newSynonyms.map((s, index) => (
      <Tag
        closable
        key={s}
        onClose={(e) => {
          e.preventDefault();
          removeTag(index);
        }}
      >
        {s}
      </Tag>
    ));

    return (
      <div className="tags">
        {tags}
        {showTagInput ? (
          <Input
            ref={inputEl}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={tagValue}
            onChange={(e) => setTagValue(e.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag
            onClick={() => {
              setShowTagInput(true);
            }}
            style={{ background: "#fff", borderStyle: "dashed" }}
          >
            <PlusOutlined /> Add
          </Tag>
        )}
      </div>
    );
  };

  const buildFields = () => {
    return (
      <div className="category-fields">
        <div className="name-row">
          <div className="label">Name:</div>
          <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
        </div>

        <div className="synonyms-row">
          <div className="label">Synonyms:</div>
          {buildSynonymsControl()}
        </div>

        <div className="stripe-row">
          <div className="label">Stripe ID:</div>
          <Select
            value={newStripeMapping || "-----------"}
            style={{ width: 200 }}
            onSelect={(v) => setNewStripeMapping(v)}
          >
            {props.products.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.id}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="public-volume-row">
          <div className="public-select">
            <div className="label">Public ID:</div>
            <Button.Group>
              <Button
                size="small"
                type={newPublic ? "primary" : "default"}
                onClick={() => setNewPublic(true)}
              >
                YES
              </Button>
              <Button
                size="small"
                type={newPublic ? "default" : "primary"}
                onClick={() => setNewPublic(false)}
              >
                NO
              </Button>
            </Button.Group>
          </div>

          <div className="volume">
            <div className="label">Volume (Cubic feet):</div>
            <InputNumber
              size="small"
              value={newVolume}
              min={0}
              step={1}
              onChange={(value) => setNewVolume(value)}
            />
          </div>
        </div>
      </div>
    );
  };

  if (props.loading) {
    return (
      <div id="category-form" className="spinning">
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (!props.products) return null;

  const onSave = () => {
    if (!newName) return setError("Please enter Name");
    if (!newStripeMapping) return setError("please select stripe ID");

    const body = {
      category_item: {
        name: newName,
        stripe_plan_id: newStripeMapping,
        public: newPublic,
        volume: newVolume,
        synonyms: newSynonyms,
      },
    };

    const hasOldImage = !!getDefaultImageUrl();
    const isImageRemoved = hasOldImage && !newImageUrl;
    const hasNewImage = !!newImageFile;

    if (hasNewImage) {
      body.category_item.image_attributes = { file: newImageFile };
    } else if (isImageRemoved) {
      const imageID = props.category.default_image.id;
      body.category_item.image_attributes = { id: imageID, _destroy: true };
    }

    const formData = objectToFormData(body);
    props.onSave(formData);
  };

  return (
    <div id="category-form">
      {error && <Alert message={error} type="warning" />}
      <div className="form-content">
        {buildImageUpload()}
        {buildFields()}
      </div>
      <div className="form-actions">
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

CategoryForm.propTypes = {
  category: PropTypes.object,
  products: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CategoryForm;
