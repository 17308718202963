import { get } from "lodash-es";
export default class Appointment {
  static getAppointmentStatusList = () => {
    return [
      "request_received",
      "confirmed",
      "scheduled",
      "cancelled",
      "ready",
      "loaded",
      "on_the_way",
      "arrived",
      "no_show",
      "started",
      "done",
      "signed",
      "completed",
    ];
  };

  static getAppointmentStatusChangeList = () => {
    return [
      "request_received",
      "confirmed",
      "scheduled",
      "cancelled",
      "ready",
      "loaded",
      "on_the_way",
      "arrived",
      "no_show",
    ];
  };

  static getStorageAppointmentItems = (appointment) => {
    return appointment.items;
    //all logic here has been moved to backend;
  };

  static getDeliveryAppointmentItems = (appointment) => {
    const { appointment_actions } = appointment;
    if (!appointment_actions) return [];
    const items = appointment_actions.map((action) => action.item);
    return items.map((item) => {
      const { product, quantity } = item;
      return {
        name: product.name,
        quantity,
        fulfillment_product_id: product.id,
        sku: product.sku,
      };
    });
  };

  static getAppointmentItems = (appointment) => {
    if (!appointment) return [];
    const isDelivery = appointment.type === "fulfillment";
    if (!isDelivery) return Appointment.getStorageAppointmentItems(appointment);
    return Appointment.getDeliveryAppointmentItems(appointment);
  };

  static getAppointmentActionFromStatus = (status) => {
    const map = {
      request_received: "receive_request",
      confirmed: "confirm",
      scheduled: "schedule",
      ready: "ready",
      loaded: "on_load",
      staging: "stage",
      on_the_way: "on_the_way",
      arrived: "arrive",
      started: "start",
      signed: "sign",
      done: "done",
      completed: "complete",
      no_show: "no_show",
      cancelled: "cancel",
      incomplete: "incomplete",
      failed: "failed",
    };
    return map[status] || status;
  };

  static canAppointmentBeModify = (appointment) => {
    const statuses = [
      "done",
      "signed",
      "completed",
      "incomplete",
      "failed",
      "cancelled",
    ];
    const status = get(appointment, "status");
    return !statuses.includes(status);
  };
}
