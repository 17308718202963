import React, { useEffect, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import withFacility from "../../../hoc/withFacility";

// hooks
import { useQuery } from "react-query";
import { useURLStateV2 } from "../../../hooks/application/useURLStateV2";

// components
import { Table, Popover, Alert, Tag } from "antd";
import { getOrderColor } from "../../../helperFunctions";
import { SearchInput } from "@secondcloset/web-components";
import TablePagination from "../../../components/_common/TablePagination";

// lib, helpers
import { toLower, startCase } from "lodash-es";
import { Helmet } from "react-helmet";
import moment from "moment";

// api
import { fetchOrderIndex } from "../../../api/storage/order";

const PAGE_SIZE = 15;

const StorageOrderIndexPage = (props) => {
  const [urlState, setUrlState] = useURLStateV2({
    search: "",
    page: 1,
  });

  const queryOptions = useMemo(() => {
    return {
      field: "created_at",
      direction: "DESC",
      per_page: PAGE_SIZE,
      page: urlState.page,
      service_area: props.serviceArea,
      ...(urlState.search && { q: String(urlState.search).trim() }),
    };
  }, [urlState, props.serviceArea]);

  const orderIndex = useQuery(
    ["orderIndex", queryOptions],
    () => fetchOrderIndex(queryOptions),
    { keepPreviousData: true }
  );

  useEffect(() => {
    // reset to page 1 every time search changes
    setUrlState((state) => ({ ...state, page: 1 }));
    // eslint-disable-next-line
  }, [props.serviceArea, urlState.search]);

  const buildSearchBar = () => {
    return (
      <Popover
        placement="bottomLeft"
        title="You Can Search"
        content={
          <div className="orders-popover-list">
            <p>Customer Name</p>
            <p>Customer Email</p>
            <p>Order #</p>
          </div>
        }
        trigger="focus"
      >
        <SearchInput
          placeholder="Search..."
          defaultValue={urlState.search}
          onSearch={(search) => setUrlState((state) => ({ ...state, search }))}
          onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
          enterButton
        />
      </Popover>
    );
  };

  const buildColumns = () => {
    const renderOrderNum = (text, record) => (
      <Link to={`/orders/${record.id}/user/${record.user_id}`}>{text}</Link>
    );
    const renderStatus = (status) => (
      <Tag color={getOrderColor("status", status)}>
        {startCase(toLower(status))}
      </Tag>
    );
    const renderType = (type, record) => (
      <>
        <Tag color={getOrderColor("type", type)}>
          {startCase(toLower(type))}
        </Tag>
        {record.space_order && !record?.closet_extension && (
          <Tag color="purple">Space</Tag>
        )}
        {record?.closet_extension && <Tag color="pink">Closet Extension</Tag>}
      </>
    );
    const renderDate = (date) => <span>{moment(date).format("ll")}</span>;
    const renderCustomerName = (id, record) => (
      <Link
        to={`/customers/${record.user_id}`}
      >{`${record.user_firstname} ${record.user_lastname}`}</Link>
    );
    const renderCustomerEmail = (email) => (
      <a href={`mailto:${email}`}>{email}</a>
    );
    return [
      {
        title: "Order #",
        dataIndex: "number",
        key: "number",
        render: renderOrderNum,
      },
      { title: "Type", dataIndex: "kind", key: "job_type", render: renderType },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: renderStatus,
      },
      {
        title: "Created date",
        dataIndex: "created_at",
        key: "date",
        render: renderDate,
      },
      {
        title: "Customer",
        dataIndex: "id",
        key: "customer_name",
        render: renderCustomerName,
      },
      {
        title: "Email",
        dataIndex: "user_email",
        key: "user_email",
        render: renderCustomerEmail,
        ellipsis: true,
        width: 250,
      },
    ];
  };

  const buildTable = () => {
    return (
      <div className="orders-table">
        <Table
          loading={orderIndex.isLoading}
          columns={buildColumns()}
          dataSource={orderIndex.data || []}
          size="small"
          pagination={false}
          rowKey={(record) => record.id}
          scroll={{ x: 600 }}
        />
        <TablePagination
          data={orderIndex.data}
          page={urlState.page}
          setPage={(page) => setUrlState((state) => ({ ...state, page }))}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  };

  return (
    <div className="page-container">
      <Helmet title="Sierra - Orders" />
      {orderIndex.error && <Alert message={orderIndex.error} type="error" />}
      <div className="orders" id="storage-order-index-page">
        <h1>Orders</h1>
        {buildSearchBar()}
        {buildTable()}
      </div>
    </div>
  );
};

StorageOrderIndexPage.propTypes = {
  serviceArea: PropTypes.string.isRequired,
};

export default withRouter(withFacility(StorageOrderIndexPage));
