import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useAppointmentNotes } from "../../../../../hooks/api/regular/appointment";
import { DiffOutlined } from "@ant-design/icons";
import {
  Spin,
  Empty,
  Alert,
  Input,
  Modal,
  Button,
  Drawer,
  message,
} from "antd";
import { isEmpty } from "lodash-es";
import moment from "moment";
import DotButtonDropdown from "../DotButtonDropdown";

const VIEW_MODES = {
  view: "view",
  edit: "edit",
  new: "new",
};

const AppointmentNotes = (props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODES.view);
  const [note, setNote] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [updatingNoteID, setUpdatingNoteID] = useState(null);
  const [noteDrawerVisible, setNoteDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    appointmentNotes,
    fetchAppointmentNotes,
    createAppointmentNote,
    updateAppointmentNote,
    deleteAppointmentNote,
    resetAppointmentNoteError,
  } = useAppointmentNotes();

  const onMount = () => {
    fetchAppointmentNotes(props.apptID);
  };

  const onEditModeChange = () => {
    resetAppointmentNoteError();
  };

  useEffect(onMount, []);
  useEffect(onEditModeChange, [viewMode]);

  const getFullName = () => {
    return `${props.user.first_name} ${props.user.last_name}`;
  };

  const buildHeader = () => {
    let text, buttons;
    switch (viewMode) {
      case VIEW_MODES.edit:
        text = "EDIT NOTES";
        buttons = (
          <>
            <button
              className="notes-button-grey"
              onClick={() => setViewMode(VIEW_MODES.view)}
            >
              CANCEL
            </button>
            <button
              className="notes-button-blue"
              onClick={() => {
                setLoading(true);
                updateAppointmentNote(props.apptID, updatingNoteID, note).then(
                  (res) => {
                    if (res) {
                      setViewMode(VIEW_MODES.view);
                      message.success("update success");
                    }
                    setLoading(false);
                  }
                );
              }}
            >
              SAVE
            </button>
          </>
        );
        break;
      case VIEW_MODES.new:
        text = "NEW NOTES";
        buttons = (
          <>
            <button
              className="notes-button-grey"
              onClick={() => setViewMode(VIEW_MODES.view)}
            >
              CANCEL
            </button>
            <button
              className="notes-button-blue"
              onClick={() => {
                setLoading(true);
                createAppointmentNote(props.apptID, note).then((res) => {
                  if (res) {
                    setViewMode(VIEW_MODES.view);
                    message.success("update success");
                  }
                  setLoading(false);
                });
              }}
            >
              SAVE
            </button>
          </>
        );
        break;
      case VIEW_MODES.view:
      default:
        text = "NOTES";
        buttons = (
          <button
            className="notes-button-blue"
            onClick={() => {
              setViewMode(VIEW_MODES.new);
              setNote("");
            }}
          >
            ADD NEW
          </button>
        );
    }

    return (
      <div className="notes-header">
        <div className="header-text">{text}</div>
        <div className="header-buttons">{buttons}</div>
      </div>
    );
  };

  const buildNote = (note) => {
    const options = ["edit", "delete"];
    const noteOwner = `${note.user.first_name} ${note.user.last_name}`;
    const isOwner = props.user.id === note.user.id;
    return (
      <div className="appointment-note" key={note.id}>
        <div className="action-button">
          <DotButtonDropdown
            options={options}
            onRowClick={(option) => {
              setUpdatingNoteID(note.id);
              if (option === options[0]) {
                setViewMode(VIEW_MODES.edit);
                setNote(note.text);
              } else setModalVisible(true);
            }}
            disabled={!isOwner}
            disabledText="You can only modify notes that you created."
          />
        </div>
        <div className="note">{note.text}</div>
        <div className="note-footer">
          <div className="name-time">
            <span>{noteOwner}</span>{" "}
            {moment(note.updated_at).format("ll h:mm a")}
          </div>
          <div className="word-count">
            {note.text.length}
            /1000
          </div>
        </div>
      </div>
    );
  };

  const buildNotesList = () => {
    const { data, loading } = appointmentNotes;
    if (loading) {
      return (
        <div className="appointment-notes-list spinning">
          <Spin tip="fetching notes..." />
        </div>
      );
    }

    const rows = isEmpty(data) ? (
      <div className="no-data">
        <Empty
          description={
            <div className="no-data-text">
              Currently there are no notes created. Create one by clicking ADD
              NEW button above.
            </div>
          }
        />
      </div>
    ) : (
      data
        .sort((a, b) => moment(a).diff(b, "seconds"))
        .map((note) => buildNote(note))
    );

    return <div className="appointment-notes-list spinning">{rows}</div>;
  };

  const buildNewNote = () => {
    return (
      <div className="new-note">
        <Input.TextArea
          maxLength="1000"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Type your notes here"
          rows={15}
        />
        <div className="note-footer">
          <div className="name-time">
            <span>{getFullName()}</span> {moment().format("ll h:mm a")}
          </div>
          <div className="word-count">
            {note.length}
            /1000
          </div>
        </div>
      </div>
    );
  };

  const buildContent = () => {
    switch (viewMode) {
      case VIEW_MODES.edit:
      case VIEW_MODES.new:
        return buildNewNote();
      case VIEW_MODES.view:
      default:
        return buildNotesList();
    }
  };

  const buildModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          setModalVisible(false);
          deleteAppointmentNote(props.apptID, updatingNoteID);
        }}
      >
        <div>Are you sure you want to delete this note?</div>
      </Modal>
    );
  };

  const buildDrawer = () => {
    return (
      <Drawer
        placement="right"
        onClose={() => setNoteDrawerVisible(false)}
        visible={noteDrawerVisible}
        width={360}
      >
        <Spin spinning={loading} tip="Loading...">
          <div className="appointment-notes-drawer">
            {buildHeader()}
            {appointmentNotes.error && (
              <Alert message={appointmentNotes.error} type="error" />
            )}
            {buildContent()}
            {buildModal()}
          </div>
        </Spin>
      </Drawer>
    );
  };

  const buildNoteCounter = () => {
    const { data } = appointmentNotes;
    if (isEmpty(data)) return null;
    return <div className="note-counter">{data.length}</div>;
  };

  return (
    <div className="appointment-notes-button">
      {buildNoteCounter()}
      {buildDrawer()}
      <Button onClick={() => setNoteDrawerVisible(true)}>
        Notes <DiffOutlined />
      </Button>
    </div>
  );
};

AppointmentNotes.propTypes = {
  apptID: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(AppointmentNotes);
