import { useState, useEffect } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useCustomerSegment = () => {
  const [customerSegment, setCustomerSegment] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();
  const url = "/customer_segments";

  const fetchCustomerSegment = () => {
    setCustomerSegment({ ...customerSegment, loading: true, error: null });
    return api
      .get(url)
      .then((res) =>
        setCustomerSegment({ data: res.data, loading: false, error: null })
      )
      .catch((error) =>
        setCustomerSegment({ ...customerSegment, loading: false, error })
      );
  };

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchCustomerSegment();
  }, []);

  return { customerSegment, fetchCustomerSegment };
};

export { useCustomerSegment };
