import React, { Component } from "react";

const Authorize = (
  WrappedComponent,
  allowedRoles,
  accessDeniedMessage = "Error 401: Unauthorized Access"
) => {
  return class WithAuthorization extends Component {
    render() {
      //eslint-disable-next-line
      const { subrole, ...rest } = this.props;

      if (allowedRoles.includes(subrole)) {
        return <WrappedComponent {...rest} />;
      } else {
        return <h1>{accessDeniedMessage}</h1>;
      }
    }
  };
};

export default Authorize;
