import React, { useState } from "react";
import PropTypes from "prop-types";

// components
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import StorageAddItemButton from "./StorageAddItemButton";
import ReturnAddItemButton from "./ReturnAddItemButton";
import RemoveItemsButton from "./RemoveItemsButton";
import ItemFilters from "./ItemFilters";
import Table from "./Table";

// lib, helpers
import { isEmpty, cloneDeep } from "lodash-es";
import Order from "../../../../lib/regular/order";
import { get } from "lodash-es";

const ItemsTable = (props) => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedItemIDs, setSelectedItemIDs] = useState([]);
  const [searchText, setSearchText] = useState("");

  const order = props.orderDetails.data;
  const isSpaceOrder = get(order, "space_order");
  const orderID = get(order, "id");
  const userID = get(order, "user_id");
  const isStorage = get(order, "kind") === "storage";
  const orderItems = get(order, "items", []);
  const nonSupplyItems = orderItems.filter((i) => i.item_type !== "Product");

  const onRemoveStorageItems = () => {
    const removingIDs = cloneDeep(selectedItemIDs);
    const removingItems = removingIDs.map((id) =>
      orderItems.find((item) => item.item_id === id)
    );
    const getBody = isSpaceOrder
      ? Order.getSpaceStorageRemoveItemsBody
      : Order.getStorageRemoveItemsBody;
    const body = getBody(order, removingItems);

    return props.updateOrderDetails(userID, orderID, body).then((res) => {
      if (res) {
        setSelectedFilter("All");
        setSelectedItemIDs([]);
        return true;
      } else return false;
    });
  };

  const onAddStorageItems = (addedProducts) => {
    const getBody = isSpaceOrder
      ? Order.getSpaceStorageAddItemsBody
      : Order.getStorageAddItemsBody;
    const body = getBody(order, addedProducts);
    return props.updateOrderDetails(userID, orderID, body);
  };

  const onRemoveReturnItems = () => {
    const body = Order.getReturnRemoveItemsBody(order, selectedItemIDs);
    return props.updateOrderDetails(userID, orderID, body).then((res) => {
      if (res) {
        setSelectedFilter("All");
        setSelectedItemIDs([]);
        return true;
      } else return false;
    });
  };

  const onAddReturnItems = (newReturnItems) => {
    const newReturnItemIDs = newReturnItems.map((i) => i.id);
    const body = Order.getReturnAddItemsBody(order, newReturnItemIDs);
    return props.updateOrderDetails(userID, orderID, body);
  };

  const renderAddItemsButton = () => {
    if (isStorage) {
      return (
        <StorageAddItemButton
          orderDetails={props.orderDetails}
          productIndex={props.productIndex}
          onAddItems={onAddStorageItems}
        />
      );
    }

    return (
      <ReturnAddItemButton
        orderDetails={props.orderDetails}
        productIndex={props.productIndex}
        onAddReturnItems={onAddReturnItems}
      />
    );
  };

  const buildSearchAndButtons = () => {
    return (
      <div className="order-items-action">
        <div className="order-items-search">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            suffix={<SearchOutlined />}
            placeholder="search items..."
          />
        </div>

        <div className="order-item-buttons">
          <RemoveItemsButton
            orderDetails={props.orderDetails}
            selectedItemIDs={selectedItemIDs}
            onRemoveItems={
              isStorage ? onRemoveStorageItems : onRemoveReturnItems
            }
          />

          {renderAddItemsButton()}
        </div>
      </div>
    );
  };

  const getItems = () => {
    if (isEmpty(nonSupplyItems)) return [];
    return nonSupplyItems.filter((item) => {
      const { name, stripe_id, qr_code, item_type } = item;
      if (item_type === "Closet") return false;
      const dataString = [name, stripe_id, qr_code].filter(Boolean).join(" ");
      const isFilterMatching =
        selectedFilter === "All" || selectedFilter === stripe_id;
      const isSearchMatching =
        !searchText ||
        dataString.toLowerCase().includes(searchText.trim().toLowerCase());
      return isSearchMatching && isFilterMatching;
    });
  };

  const buildSpacePlanName = () => {
    const spacePlanItem = nonSupplyItems.find((i) => i.item_type === "Closet");
    if (!spacePlanItem) return null;
    return (
      <div className="space-plan-display">
        <div className="name-wrap">
          <div className="name">{spacePlanItem.stripe_id}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="order-edit-items-table">
      {buildSpacePlanName()}
      <ItemFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        nonSupplyItems={nonSupplyItems}
      />
      {buildSearchAndButtons()}
      <Table
        orderItems={getItems()}
        selectedItemIDs={selectedItemIDs}
        setSelectedItemIDs={setSelectedItemIDs}
        selectable
      />
    </div>
  );
};

ItemsTable.propTypes = {
  updateOrderDetails: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  productIndex: PropTypes.object.isRequired,
};

export default ItemsTable;
