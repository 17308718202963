import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import Products from "../../../../lib/regular/products";
import { cloneDeep, isFinite } from "lodash-es";
import ErrorBox from "../../../../components/fulfillment/_common/ErrorBox";
import { useProducts } from "../../../../hooks/api/regular/products";
import PlusMinusInput from "../../../../components/regular/order/dependencies/PlusMinusInput";
import SelectNewSuppliesCard from "../../../../components/regular/order/dependencies/SelectNewSuppliesCard";
import SelectNewSpaceItemsCard from "../../../../components/regular/order/dependencies/SelectNewSpaceItemsCard";

const SelectPlanCard = (props) => {
  const { products, fetchProducts } = useProducts();
  const onServiceAreaChange = () => {
    if (props.selectedServiceArea) {
      fetchProducts({ service_area: props.selectedServiceArea });
      props.onProductChange({});
      props.onItemsChange({});
    }
  };

  useEffect(onServiceAreaChange, [props.selectedServiceArea]);

  const attachProductIfNotExist = (product) => {
    const addedProducts = cloneDeep(props.addedProducts);
    if (!addedProducts[product.id]) {
      addedProducts[product.id] = cloneDeep(product);
      addedProducts[product.id].quantity = 0;
    }
    return addedProducts;
  };

  const addProduct = (product, quantity) => {
    const addedProducts = attachProductIfNotExist(product);
    addedProducts[product.id].quantity += +quantity;
    if (addedProducts[product.id].quantity <= 0)
      delete addedProducts[product.id];
    props.onProductChange(addedProducts);
  };

  const setProductQuantity = (product, quantity) => {
    if (!isFinite(+quantity)) quantity = 0;
    const addedProducts = attachProductIfNotExist(product);
    addedProducts[product.id].quantity = +quantity;
    if (addedProducts[product.id].quantity <= 0)
      delete addedProducts[product.id];
    props.onProductChange(addedProducts);
  };

  const buildPlusMinusInput = (product) => {
    const { name, id } = product;
    const { addedProducts } = props;
    const quantity = addedProducts[id] ? addedProducts[id].quantity : 0;
    const price = (Products.getUnitPrice(product) / 100).toFixed(2);
    return (
      <div className="product-controller" key={id}>
        <PlusMinusInput
          onMinusClick={() => addProduct(product, -1)}
          onQuantityChange={(quantity) => setProductQuantity(product, quantity)}
          onPlusClick={() => addProduct(product, 1)}
          value={quantity}
        />
        <div className="product-detail">
          <div className="product-name">{name}</div>
          <div className="product-price">${price}</div>
        </div>
      </div>
    );
  };

  const buildColumn = (list, title) => {
    return (
      <div className="product-column">
        <div className="column-title">{title}</div>
        {list.map((item) => buildPlusMinusInput(item))}
      </div>
    );
  };

  const buildPlansCard = () => {
    const visibleProducts = Products.getAdminVisibleProducts(products.data);
    const plans = Products.getPlans(visibleProducts);
    const halfLength = Math.floor(plans.length / 2) + 1;
    const plansOnLeft = plans.slice(0, halfLength);
    const plansOnRight = plans.slice(halfLength);
    return (
      <div className="product-card">
        <div className="title">Select Plan</div>
        <div className="product-table ">
          {buildColumn(plansOnLeft, "PLANS")}
          {buildColumn(plansOnRight, "-")}
        </div>
      </div>
    );
  };

  const buildSuppliesCard = () => {
    return (
      <div className="supplies-card">
        <div className="title">Add-ons</div>
        <SelectNewSuppliesCard
          products={products.data}
          addedProducts={props.addedProducts}
          onProductChange={props.onProductChange}
        />
      </div>
    );
  };

  const buildItemCard = () => {
    return (
      <div className="item-card">
        <SelectNewSpaceItemsCard
          addedItems={props.addedItems}
          onItemsChange={props.onItemsChange}
        />
      </div>
    );
  };

  const { data, loading, error } = products;

  if (!props.selectedServiceArea) {
    return (
      <div className="select-product-cards empty">
        <div className="title">Select Plan</div>
        <div className="instruction">Please Select Service Area</div>
      </div>
    );
  }

  if (!data || loading || error) {
    return (
      <div className="select-product-cards spinning">
        <ErrorBox error={error} />
        {!error && <Spin tip="Fetching products..." />}
      </div>
    );
  }

  return (
    <div className="select-product-cards">
      {buildPlansCard()}
      {buildItemCard()}

      {buildSuppliesCard()}
    </div>
  );
};

SelectPlanCard.propTypes = {
  onProductChange: PropTypes.func.isRequired,
  addedProducts: PropTypes.object.isRequired,
  addedItems: PropTypes.object.isRequired,
  onItemsChange: PropTypes.func.isRequired,
  selectedServiceArea: PropTypes.string,
};

export default SelectPlanCard;
