import React from "react";
import PropTypes from "prop-types";
import { isEmpty, startCase } from "lodash-es";

const ManifestNotesAndTasks = ({ notes, tasks }) => {
  const renderSection = (title, content) => {
    if (isEmpty(content)) return null;
    return (
      <div className="notes-section">
        <label>{startCase(title)}:</label>
        {content.map((i) => {
          return (
            <div className="notes" key={Math.random()}>
              {i.notes || i.text}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="manifest-notes-tasks">
      {renderSection("tasks", tasks)}
      {renderSection("notes", notes)}
    </div>
  );
};

ManifestNotesAndTasks.propTypes = {
  notes: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default ManifestNotesAndTasks;
