import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useInventoryItemDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain();
  const getURL = (userID, inventoryID) =>
    `/users/${userID}/inventory_items/${inventoryID}`;

  const fetchInventoryItemDetails = (userID, inventoryID) => {
    setLoading(true);
    setError(null);

    const url = getURL(userID, inventoryID);

    return api
      .get(url)
      .then((res) => {
        setData(res.data.inventory_item);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const updateInventoryItemDetails = (userID, inventoryID, body) => {
    setLoading(true);
    setError(null);

    const url = getURL(userID, inventoryID);

    return api
      .put(url, body)
      .then((res) => {
        setData(res.data.inventory_item);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const inventoryItemDetails = { data, error, loading };

  return {
    inventoryItemDetails,
    fetchInventoryItemDetails,
    updateInventoryItemDetails,
  };
};

export { useInventoryItemDetails };
