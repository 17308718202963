import React from "react";
import { featureFlags } from "../config/featureFlags";

interface ContextState {
  features: Features;
  toggleFeature: (feature: keyof Features) => void;
  isActive: (feature: keyof Features) => boolean;
}

/**
 * Creates context with default functionality
 */
const FeatureFlagsContext = React.createContext<ContextState | undefined>(
  undefined
);

/**
 * Provider that provides access to feature flags
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Component children
 */
const FeatureFlagsProvider: React.FC = ({ children }) => {
  const [features, setFeatures] = React.useState<Features>(featureFlags);

  const toggleFeature = (key: keyof Features) => {
    setFeatures((features) => {
      return {
        ...features,
        [key]: !features[key],
      };
    });
  };

  const isActive = (key: keyof Features): boolean => {
    return features[key];
  };

  return (
    <FeatureFlagsContext.Provider
      value={{
        features,
        toggleFeature,
        isActive,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

/**
 * Helper hook to use FeatureFlagsContext
 * @returns Context object
 */
const useFeatureFlags = (): ContextState => {
  const context = React.useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      "useFeatureFlags is allowed only inside <FeatureFlagsProvider />"
    );
  }

  return context;
};

export { FeatureFlagsProvider, useFeatureFlags };
