import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const NavigationMenu = (props) => {
  const { links, isDesktop, currentPathname } = props;

  const isActiveTab = (toPathString) => {
    const currentPathSlice = currentPathname.slice(0, toPathString.length);
    return toPathString === currentPathSlice;
  };

  const renderMenuItems = (item, index) => {
    const { to, text, icon } = item;
    return (
      <Menu.Item
        key={index + text}
        className={
          isActiveTab(to)
            ? `navigationMenuLink active ${to.substring(1)}`
            : `navigationMenuLink ${to.substring(1)}`
        }
      >
        <Link to={to}>
          <i className={`navigationMenuLinkIcon ${icon}`} />
          <span className="navigationMenuLinkText">{text}</span>
        </Link>
      </Menu.Item>
    );
  };

  const renderSubMenu = (item, index) => {
    const { icon, text, items } = item;
    if (items && !items.length) return null;
    if (items?.length > 0) {
      return (
        <Menu.SubMenu
          key={index}
          className="navigationMenuLink navigationSubMenu"
          title={
            <span>
              <i className={`navigationMenuLinkIcon ${icon}`} />
              <span className="navigationMenuLinkText submenu">{text}</span>
            </span>
          }
        >
          {items.map(renderMenuItems)}
        </Menu.SubMenu>
      );
    } else {
      return renderMenuItems(item, index);
    }
  };

  return (
    <div className={isDesktop ? "col-xs-2 navigationMenu" : "navigationMenu"}>
      <Menu onClick={() => props.handleNavbarToggle()} mode="inline">
        {links.map(renderSubMenu)}
      </Menu>
    </div>
  );
};

NavigationMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  isDesktop: PropTypes.bool,
  currentPathname: PropTypes.string,
  handleNavbarToggle: PropTypes.func,
};

export default NavigationMenu;
