import { Product } from "../interfaces/products";

export interface ProductCreateState {
  isEditing: boolean;
  product: Partial<Product>;
}

// Action types
const SET_IS_EDITING = "PRODUCT_CREATE_FLOW/SET_IS_EDITING";
const SET_PRODUCT = "PRODUCT_CREATE_FLOW/SET_PRODUCT";
const RESET_PRODUCT = "PRODUCT_CREATE_FLOW/RESET_PRODUCT";
const SET_NAME = "PRODUCT_CREATE_FLOW/SET_NAME";
const SET_IMAGE = "PRODUCT_CREATE_FLOW/SET_IMAGE";
const SET_IMAGE_URL = "PRODUCT_CREATE_FLOW/SET_IMAGE_URL";
const SET_PRODUCT_TYPE = "PRODUCT_CREATE_FLOW/SET_PRODUCT_TYPE";
const SET_ACTIVE = "PRODUCT_CREATE_FLOW/SET_ACTIVE";
const SET_DESCRIPTION = "PRODUCT_CREATE_FLOW/SET_DESCRIPTION";
const SET_SKU = "PRODUCT_CREATE_FLOW/SET_SKU";
const SET_UPC = "PRODUCT_CREATE_FLOW/SET_UPC";
const SET_LENGTH = "PRODUCT_CREATE_FLOW/SET_LENGTH";
const SET_WIDTH = "PRODUCT_CREATE_FLOW/SET_WIDTH";
const SET_HEIGHT = "PRODUCT_CREATE_FLOW/SET_HEIGHT";
const SET_WEIGHT = "PRODUCT_CREATE_FLOW/SET_WEIGHT";
const SET_LOT_PRODUCT = "PRODUCT_CREATE_FLOW/SET_LOT_PRODUCT";
const SET_DANGEROUS = "PRODUCT_CREATE_FLOW/SET_DANGEROUS";
const SET_FRAGILE = "PRODUCT_CREATE_FLOW/SET_FRAGILE";
const SET_CONTAINS_BATTERY = "PRODUCT_CREATE_FLOW/SET_CONTAINS_BATTERY";
const SET_LIQUID_PRODUCT = "PRODUCT_CREATE_FLOW/SET_LIQUID_PRODUCT";
const SET_PACKING_DETAILS = "PRODUCT_CREATE_FLOW/SET_PACKING_DETAILS";
const SET_TARIFF_CODE = "PRODUCT_CREATE_FLOW/SET_TARIFF_CODE";
const SET_CUSTOMS_DESCRIPTION = "PRODUCT_CREATE_FLOW/SET_CUSTOMS_DESCRIPTION";
const SET_COST = "PRODUCT_CREATE_FLOW/SET_COST";
const SET_WHOLESALE_PRICE = "PRODUCT_CREATE_FLOW/SET_WHOLESALE_PRICE";
const SET_RETAIL_PRICE = "PRODUCT_CREATE_FLOW/SET_RETAIL_PRICE";
const SET_PACKAGE_REQUIREMENTS = "PRODUCT_CREATE_FLOW/SET_PACKAGE_REQUIREMENTS";
const SET_BASE_PRODUCTS = "PRODUCT_CREATE_FLOW/SET_BASE_PRODUCTS";
const SET_REQUIRED_PARTS = "PRODUCT_CREATE_FLOW/SET_REQUIRED_PARTS";
const SET_RETURN_DAYS_CONSTRAINT =
  "PRODUCT_CREATE_FLOW/SET_RETURN_DAYS_CONSTRAINT";
const SET_INSURANCE_REQUIRED = "PRODUCT_CREATE_FLOW/SET_INSURANCE_REQUIRED";
const SET_INSURANCE_VALUE = "PRODUCT_CREATE_FLOW/SET_INSURANCE_VALUE";

// Action Creators
export const actionSetIsEditing = (isEditing: boolean) => ({
  type: SET_IS_EDITING,
  isEditing,
});
export const actionSetProduct = (product: Product) => ({
  type: SET_PRODUCT,
  product,
});
export const actionResetProduct = () => ({
  type: RESET_PRODUCT,
});
export const actionSetName = (name: Product["name"]) => ({
  type: SET_NAME,
  name,
});
export const actionSetImage = (image: Product["image"]) => ({
  type: SET_IMAGE,
  image,
});
export const actionSetImageURL = (imageURL: Product["image_url"]) => ({
  type: SET_IMAGE_URL,
  imageURL,
});
export const actionSetProductType = (productType: Product["product_type"]) => ({
  type: SET_PRODUCT_TYPE,
  productType,
});
export const actionSetActive = (active: Product["active"]) => ({
  type: SET_ACTIVE,
  active,
});
export const actionSetDescription = (description: Product["description"]) => ({
  type: SET_DESCRIPTION,
  description,
});
export const actionSetSku = (sku: Product["sku"]) => ({
  type: SET_SKU,
  sku,
});
export const actionSetUPC = (upc: Product["upc"]) => ({
  type: SET_UPC,
  upc,
});
export const actionSetLength = (length: Product["length"]) => ({
  type: SET_LENGTH,
  length,
});
export const actionSetWidth = (width: Product["width"]) => ({
  type: SET_WIDTH,
  width,
});
export const actionSetHeight = (height: Product["height"]) => ({
  type: SET_HEIGHT,
  height,
});
export const actionSetWeight = (weight: Product["weight"]) => ({
  type: SET_WEIGHT,
  weight,
});
export const actionSetLotProduct = (
  isLotProduct: Product["tracks_lot_numbers"]
) => ({
  type: SET_LOT_PRODUCT,
  isLotProduct,
});
export const actionSetDangerous = (dangerous: Product["dangerous"]) => ({
  type: SET_DANGEROUS,
  dangerous,
});
export const actionSetFragile = (fragile: Product["fragile"]) => ({
  type: SET_FRAGILE,
  fragile,
});
export const actionSetContainsBattery = (
  containsBattery: Product["contains_battery"]
) => ({
  type: SET_CONTAINS_BATTERY,
  containsBattery,
});
export const actionSetLiquidProduct = (liquid: Product["liquid"]) => ({
  type: SET_LIQUID_PRODUCT,
  liquid,
});
export const actionSetPackingDetails = (
  packingDetails: Product["packaging_levels"]
) => ({
  type: SET_PACKING_DETAILS,
  packingDetails,
});
export const actionSetTariffCode = (tariffCode: Product["hs_tariff_code"]) => ({
  type: SET_TARIFF_CODE,
  tariffCode,
});
export const actionSetCustomsDescription = (
  customsDescription: Product["customs_description"]
) => ({
  type: SET_CUSTOMS_DESCRIPTION,
  customsDescription,
});
export const actionSetCost = (cost: Product["cost"]) => ({
  type: SET_COST,
  cost,
});
export const actionSetWholesaleCost = (
  wholesalePrice: Product["wholesale_price"]
) => ({
  type: SET_WHOLESALE_PRICE,
  wholesalePrice,
});
export const actionSetRetailPrice = (retailCost: Product["retail_price"]) => ({
  type: SET_RETAIL_PRICE,
  retailCost,
});
export const actionSetPackageRequirements = (
  packagingCriteria: Product["packaging_criteria"]
) => ({
  type: SET_PACKAGE_REQUIREMENTS,
  packagingCriteria,
});
export const actionSetBaseProducts = (
  baseProducts: Product["base_products"]
) => ({
  type: SET_BASE_PRODUCTS,
  baseProducts,
});
export const actionSetRequiredParts = (
  requiredParts: Product["required_parts"]
) => ({
  type: SET_REQUIRED_PARTS,
  requiredParts,
});
export const actionSetReturnDaysConstraint = (
  returnDays: Product["return_days"]
) => ({
  type: SET_RETURN_DAYS_CONSTRAINT,
  returnDays,
});
export const actionSetInsuranceRequired = (
  insurance_required: Product["insurance_required"]
) => ({
  type: SET_INSURANCE_REQUIRED,
  insurance_required,
});
export const actionSetInsuranceValue = (
  insurance_value: Product["insurance_value"]
) => ({
  type: SET_INSURANCE_VALUE,
  insurance_value,
});

// Initial State
export const initialState: ProductCreateState = {
  isEditing: false,
  product: {
    id: "",
    name: "",
    image: null,
    product_type: undefined,
    active: true,
    description: "",
    sku: "",
    scid: "",
    upc: "",
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
    weight_unit: "lb",
    tracks_lot_numbers: false,
    dangerous: false,
    fragile: false,
    contains_battery: false,
    liquid: false,
    return_days: 60,
    packaging_levels: [
      {
        active: false,
        level: "inner_pack",
        base_product_quantity: 0,
        sku: "",
        description: "",
        weight_value: undefined,
        weight_unit: "lb",
        length_value: undefined,
        length_unit: "in",
        height_value: undefined,
        height_unit: "in",
        width_value: undefined,
        width_unit: "in",
      },
      {
        active: false,
        level: "master_pack",
        base_product_quantity: 0,
        sku: "",
        description: "",
        weight_value: undefined,
        weight_unit: "lb",
        length_value: undefined,
        length_unit: "in",
        height_value: undefined,
        height_unit: "in",
        width_value: undefined,
        width_unit: "in",
      },
      {
        active: false,
        level: "pallet",
        base_product_quantity: 0,
        sku: "",
        description: "",
        weight_value: undefined,
        weight_unit: "lb",
        length_value: undefined,
        length_unit: "in",
        height_value: undefined,
        height_unit: "in",
        width_value: undefined,
        width_unit: "in",
      },
    ],
    hs_tariff_code: "",
    customs_description: "",
    cost: 0,
    wholesale_price: 0,
    retail_price: 0,
    packaging_criteria: "no_requirements",
    base_products: [],
    required_parts: {},
    virtual_kits: [],
    image_url: "",
    insurance_required: false,
    insurance_value: null,
    under_conversion: false,
  },
};

// Reducer
export default function reducer(
  state: ProductCreateState,
  action: any
): ProductCreateState {
  if (!state) state = initialState;

  switch (action.type) {
    case SET_IS_EDITING:
      return { ...state, isEditing: action.isEditing };
    case SET_PRODUCT:
      return { ...state, product: action.product };
    case RESET_PRODUCT:
      return { ...state, product: initialState.product };
    case SET_NAME:
      return {
        ...state,
        product: { ...state.product, name: action.name },
      };
    case SET_IMAGE:
      return {
        ...state,
        product: { ...state.product, image: action.image },
      };
    case SET_IMAGE_URL:
      return {
        ...state,
        product: { ...state.product, image_url: action.imageURL },
      };
    case SET_PRODUCT_TYPE:
      return {
        ...state,
        product: {
          ...state.product,
          product_type: action.productType,
          base_products:
            action.product_type === "base" ? [] : state.product.base_products,
          required_parts:
            action.product_type === "base" ? {} : state.product.required_parts,
          virtual_kits:
            action.product_type === "kit" ? [] : state.product.virtual_kits,
        },
      };
    case SET_ACTIVE:
      return {
        ...state,
        product: { ...state.product, active: action.active },
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        product: { ...state.product, description: action.description },
      };
    case SET_SKU:
      return {
        ...state,
        product: { ...state.product, sku: action.sku },
      };
    case SET_UPC:
      return {
        ...state,
        product: { ...state.product, upc: action.upc },
      };
    case SET_LENGTH:
      return {
        ...state,
        product: { ...state.product, length: action.length },
      };
    case SET_WIDTH:
      return {
        ...state,
        product: { ...state.product, width: action.width },
      };
    case SET_HEIGHT:
      return {
        ...state,
        product: { ...state.product, height: action.height },
      };
    case SET_WEIGHT:
      return {
        ...state,
        product: { ...state.product, weight: action.weight },
      };
    case SET_LOT_PRODUCT:
      return {
        ...state,
        product: { ...state.product, tracks_lot_numbers: action.isLotProduct },
      };
    case SET_DANGEROUS:
      return {
        ...state,
        product: { ...state.product, dangerous: action.dangerous },
      };
    case SET_FRAGILE:
      return {
        ...state,
        product: { ...state.product, fragile: action.fragile },
      };
    case SET_CONTAINS_BATTERY:
      return {
        ...state,
        product: { ...state.product, contains_battery: action.containsBattery },
      };
    case SET_LIQUID_PRODUCT:
      return {
        ...state,
        product: { ...state.product, liquid: action.liquid },
      };
    case SET_PACKING_DETAILS:
      return {
        ...state,
        product: {
          ...state.product,
          packaging_levels: action.packingDetails,
        },
      };
    case SET_TARIFF_CODE:
      return {
        ...state,
        product: { ...state.product, hs_tariff_code: action.tariffCode },
      };
    case SET_CUSTOMS_DESCRIPTION:
      return {
        ...state,
        product: {
          ...state.product,
          customs_description: action.customsDescription,
        },
      };
    case SET_COST:
      return {
        ...state,
        product: { ...state.product, cost: action.cost },
      };
    case SET_WHOLESALE_PRICE:
      return {
        ...state,
        product: { ...state.product, wholesale_price: action.wholesalePrice },
      };
    case SET_RETAIL_PRICE:
      return {
        ...state,
        product: { ...state.product, retail_price: action.retailCost },
      };
    case SET_PACKAGE_REQUIREMENTS:
      return {
        ...state,
        product: {
          ...state.product,
          packaging_criteria: action.packagingCriteria,
        },
      };
    case SET_BASE_PRODUCTS:
      return {
        ...state,
        product: { ...state.product, base_products: action.baseProducts },
      };
    case SET_REQUIRED_PARTS:
      return {
        ...state,
        product: { ...state.product, required_parts: action.requiredParts },
      };
    case SET_RETURN_DAYS_CONSTRAINT:
      return {
        ...state,
        product: { ...state.product, return_days: action.returnDays },
      };
    case SET_INSURANCE_REQUIRED:
      return {
        ...state,
        product: {
          ...state.product,
          insurance_required: action.insurance_required,
        },
      };
    case SET_INSURANCE_VALUE:
      return {
        ...state,
        product: {
          ...state.product,
          insurance_value: action.insurance_value,
        },
      };
    default:
      return state;
  }
}
