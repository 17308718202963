import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import ErrorBox from "../../../../components/fulfillment/_common/ErrorBox";
import { useProducts } from "../../../../hooks/api/regular/products";
import SelectNewItemsCard from "../../../../components/regular/order/dependencies/SelectNewItemsCard";
import SelectNewSuppliesCard from "../../../../components/regular/order/dependencies/SelectNewSuppliesCard";

const SelectProductCard = (props) => {
  const { products, fetchProducts } = useProducts();

  const onServiceAreaChange = () => {
    if (props.selectedServiceArea) {
      fetchProducts({ service_area: props.selectedServiceArea });
      props.onProductChange({});
    }
  };

  useEffect(onServiceAreaChange, [props.selectedServiceArea]);

  const buildProductCard = () => {
    return (
      <div className="product-card">
        <div className="title">Add Products </div>
        <SelectNewItemsCard
          products={products.data}
          addedProducts={props.addedProducts}
          onProductChange={props.onProductChange}
        />
      </div>
    );
  };

  const buildSuppliesCard = () => {
    return (
      <div className="supplies-card">
        <div className="title">Add-ons</div>
        <SelectNewSuppliesCard
          products={products.data}
          addedProducts={props.addedProducts}
          onProductChange={props.onProductChange}
        />
      </div>
    );
  };

  const { data, loading, error } = products;

  if (!props.selectedServiceArea) {
    return (
      <div className="select-product-cards empty">
        <div className="title">Select Products</div>
        <div className="instruction">Please Select Service Area</div>
      </div>
    );
  }

  if (!data || loading || error) {
    return (
      <div className="select-product-cards spinning">
        <ErrorBox error={error} />
        {!error && <Spin tip="Fetching products..." />}
      </div>
    );
  }

  return (
    <div className="select-product-cards">
      {buildProductCard()}
      {buildSuppliesCard()}
    </div>
  );
};

SelectProductCard.propTypes = {
  onProductChange: PropTypes.func.isRequired,
  addedProducts: PropTypes.object.isRequired,
  selectedServiceArea: PropTypes.string,
  productCardTitle: PropTypes.string,
};

export default SelectProductCard;
