import { stringify } from "query-string";
import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useAvailabilitiesIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("logistics");

  const fetchAvailabilitiesIndex = (queryOptions) => {
    const url = `/availabilities?${stringify(queryOptions || {})}`;
    setLoading(true);
    setError(null);
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const availabilitiesIndex = { data, loading, error };

  return { availabilitiesIndex, fetchAvailabilitiesIndex };
};

const useAvailabilitySlotUpdate = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("logistics");

  const updateAvailabilitySlot = ({
    date,
    timeslot,
    customer_segment,
    delivery_service_level,
    service_area,
    total_milliseconds,
  }) => {
    const url = `/availabilities/${date}`;
    const body = {
      date,
      timeslots: [
        {
          timeslot,
          customer_segment,
          delivery_service_level,
          service_area,
          total_milliseconds,
        },
      ],
    };
    setLoading(true);
    setError(null);
    return api
      .put(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const availabilitySlotUpdate = { data, loading, error };

  return { availabilitySlotUpdate, updateAvailabilitySlot };
};

const useAppointmentAvailability = (env) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain(env || "storage");

  const fetchAvailableDates = (options) => {
    const url = "/appointments/availabilities";
    setLoading(true);
    setError(null);
    return api
      .post(url, options)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const appointmentAvailability = { data, loading, error };

  return { appointmentAvailability, fetchAvailableDates };
};

const useShipmentAvailabilities = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const fetchShipmentAvailabilities = (options) => {
    const url = "/shipments/availabilities";
    setLoading(true);
    setError(null);
    return api
      .post(url, options)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const shipmentAvailabilities = { data, loading, error };

  return { shipmentAvailabilities, fetchShipmentAvailabilities };
};

export {
  useAvailabilitiesIndex,
  useAvailabilitySlotUpdate,
  useAppointmentAvailability,
  useShipmentAvailabilities,
};
