import { Fulfillment } from "@secondcloset/types";
export type ShipmentTag =
  | Fulfillment.ShipmentTag
  | "customer_booking"
  | "internal_booking";

export const isValidBulkOrderShipmentTag = (shipmentTag: ShipmentTag) => {
  const validTags = ["unfulfilled_items", "ready_to_fulfill"];
  return validTags.includes(shipmentTag);
};
