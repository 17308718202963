import { useSelector, useDispatch } from "react-redux";
import { action_setActiveFacility } from "../../../actions/facility/facility";

//selectors
const selectActiveServiceArea = (state) => {
  return state.activeFacility.service_area;
};

const useActiveServiceArea = () => {
  const dispatch = useDispatch();

  const activeServiceArea = useSelector(selectActiveServiceArea);

  const dispatchSetActiveServiceArea = (facility_) =>
    dispatch(action_setActiveFacility(facility_));

  return {
    activeServiceArea,
    dispatchSetActiveServiceArea,
  };
};

export { useActiveServiceArea };
