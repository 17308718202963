import styled from "styled-components";
export const Container = styled.div``;

export const ShipmentCardWrap = styled.div`
  margin-top: 20px;
`;

export const ShipmentSectionWrap = styled.div`
  margin-top: 40px;
`;

export const RemovedItemsWrap = styled.div`
  margin: 40px 0;
`;
