import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { capitalize, isEmpty } from "lodash-es";
import { Helmet } from "react-helmet";
import { Button, Modal, Spin, Alert, Empty } from "antd";
import PropTypes from "prop-types";

import Filter from "../../../components/Filter/Filter";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";

import { useInvoicesList } from "../../../hooks/api/regular/invoice";
import { useCustomerDetails } from "../../../hooks/api/regular/customer";
import {
  isSupportGroup,
  getInvoiceStatusColor,
} from "../../../helperFunctions";

import logo from "../../../public/logo.png";

const ALL = "all";
const FILTER_OPTIONS = [ALL, "draft", "open", "paid", "uncollectible", "void"];

const InvoiceListPage = ({
  history,
  match: {
    params: { id: userId },
  },
  user,
}) => {
  const {
    invoices: {
      loading: invoicesLoading,
      data: invoices,
      error: invoicesError,
    },
    fetchInvoices,
    updateInvoice,
  } = useInvoicesList();

  const {
    customerDetails: {
      loading: customerLoading,
      data: customer,
      error: customerError,
    },
    fetchCustomerDetails,
  } = useCustomerDetails();

  const fetchInitialData = () => {
    fetchInvoices(userId);
    fetchCustomerDetails(userId);
  };

  useEffect(fetchInitialData, []);

  const [selectedFilter, setSelectedFilter] = useState(FILTER_OPTIONS[0]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const hasAccessToCreate = () => {
    const { role, subrole } = user;
    return isSupportGroup(role, subrole);
  };

  const getFilteredInvoices = () => {
    if (selectedFilter === ALL) return invoices;
    return invoices.filter((o) => o.status === selectedFilter);
  };

  const viewInvoiceDetails = (invoice) => {
    setSelectedInvoiceId(invoice.id);
    setShowModal(true);
  };

  const buildHeader = () => (
    <>
      <div className="inline-block w-50 m-align">
        <a href={`/customers/${userId}`}> {"< User Detail"}</a>
      </div>
      <div className="inline-block w-50 text-right m-align">
        <Button
          disabled={!hasAccessToCreate()}
          type="primary"
          onClick={() => history.push(`/customers/${userId}/invoice/new`)}
          ghost
        >
          CREATE INVOICE
        </Button>
      </div>
      <div className="marginBottom20">
        <h1 className="text-capitalize inline-block">INVOICE</h1>
        <Filter
          filterOptions={FILTER_OPTIONS}
          btnPressed={setSelectedFilter}
          txtColor="#404041"
          bgColor="#ffd33d"
          activeBtn={selectedFilter}
        />
      </div>
    </>
  );

  const buildLoader = () => (
    <div className="flex-row" style={{ justifyContent: "center" }}>
      <Spin />
    </div>
  );

  const buildError = (error) => <Alert type="error" message={error} />;

  const buildInvoiceRow = (invoice) => {
    const { id, number, total, status } = invoice;
    const date = moment.unix(invoice.date).format("MMMM Do, YYYY");
    const amount = parseFloat(total / 100).toFixed(2);

    return (
      <tr key={id} onClick={() => viewInvoiceDetails(invoice)}>
        <td>{number}</td>
        <td>
          <span className={`invoiceBtn ${getInvoiceStatusColor(status)}`}>
            {capitalize(status)}
          </span>
        </td>
        <td>{date}</td>
        <td>${amount}</td>
        <td className="hidden-xs">
          <button className="invoiceBtn">View</button>
        </td>
      </tr>
    );
  };

  const buildInvoicesList = () => {
    const invoices = getFilteredInvoices();
    if (isEmpty(invoices)) {
      return (
        <div className="empty-invoice">
          <Empty description="no invoices" />
        </div>
      );
    }

    return (
      <div>
        <table className="InvoiceTable">
          <tbody className="text-left">
            <tr>
              <th>
                <span className="hidden-xs">Invoice</span>#
              </th>
              <th>Status</th>
              <th>Date</th>
              <th>Amount</th>
              <th className="hidden-xs">Details</th>
            </tr>
            {invoices.map(buildInvoiceRow)}
          </tbody>
        </table>
      </div>
    );
  };

  const buildInvoiceDetailsModal = () => {
    const selectedInvoice =
      invoices && invoices.find((i) => i.id === selectedInvoiceId);

    return (
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title={<img width="230px" src={logo} alt="Second Closet Logo" />}
        footer={null}
      >
        {customerError && buildError(customerError)}
        {customerLoading ? (
          buildLoader()
        ) : (
          <InvoiceDetails
            invoice={selectedInvoice}
            customer={customer}
            updateInvoice={updateInvoice}
            loading={invoicesLoading}
            error={invoicesError}
          />
        )}
      </Modal>
    );
  };

  return (
    <div id="invoice-list-page" className="page-container">
      <Helmet title="Sierra - Invoice List" />
      {buildHeader()}
      <hr />
      {invoicesLoading && buildLoader()}
      {invoicesError && buildError(invoicesError)}
      {invoices && buildInvoicesList()}
      {buildInvoiceDetailsModal()}
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default withRouter(connect(mapStateToProps)(InvoiceListPage));

InvoiceListPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
