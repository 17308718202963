import React, { useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import {
  SelectShipmentCarrier,
  SelectShipmentCarrierTypes,
} from "@secondcloset/web-components";

// hooks
import { useQuery, useMutation } from "react-query";
import useShipmentCreateFlow from "../../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import useUser from "../../../../hooks/reduxContainer/_common/useUser";

//lib
import moment from "moment";
import { getSecondClosetCarrier } from "../../../../lib/fulfillment/shipmentCarrier";
import { isSupportEscalated } from "../../../../helperFunctions/user";
import { fetchRecommendedShippingMethod } from "../../../../api/fulfillment/recommendedShippingMethod";
import { fetchOrganizationDetails } from "../../../../api/accounts/organization";
import { mapRecommendedMethodToServiceCategory } from "./helpers";
import {
  fetchShipmentAvailabilities,
  fetchShipmentRates,
} from "../../../../api/fulfillment/shipment";

// styles
import * as S from "./styles";

// types
import { Logistics, Common, Fulfillment } from "@secondcloset/types";

type ShippingRate = Logistics.ShippingRate;
type Carrier = Fulfillment.Carrier;
type Address = Common.OrganizationAddress;
type SCServiceCodeType = Fulfillment.ScServiceCode;
type ServiceCategoryType = SelectShipmentCarrierTypes.ServiceCategory;
type SCBookingMethodType = SelectShipmentCarrierTypes.SCBookingMethod;

// enums
const SCBookingMethod = SelectShipmentCarrierTypes.SCBookingMethod;
const ServiceCategory = SelectShipmentCarrierTypes.ServiceCategory;

interface Props {
  isCreatingDeliveryShipment?: boolean;
  createDeliveryShipmentError?: string;
  isOtherCarrierSelected: boolean;
  onOtherCarrierSelected: (value: boolean) => unknown;
}

const SelectShipmentCarrierStep: React.FC<Props> = ({
  isCreatingDeliveryShipment,
  createDeliveryShipmentError,
  isOtherCarrierSelected,
  onOtherCarrierSelected,
}) => {
  const {
    // redux state
    activeStepKey,
    order,
    isReturn,
    serviceCategory,
    isEndy,
    shipmentCarrier,
    isSendEmail,
    shipmentDate,
    shipmentTime,
    selectedOrderItemIDs,
    packageDetails,
    isReturnWithOriginalPackage,
    isPartialReturn,
    isHamuq,
    // event dispatcher
    dispatchSetPartner,
    dispatchSetShipmentCarrier,
    dispatchSetServiceCategory,
    dispatchSetIsSendEmail,
    dispatchSetShipmentDate,
    dispatchSetShipmentTime,
  } = useShipmentCreateFlow();
  const { user } = useUser();
  const [selectedOrgAddress, setSelectedOrgAddress] = useState<Address>();
  const [selectedPickupPartner, setSelectedPickupPartner] = useState<Carrier>();
  const [selectedShippingRate, setSelectedShippingRate] = useState<
    ShippingRate
  >();
  const [disabledServiceCategory, setDisabledServiceCategory] = useState<{
    serviceCategory?: ServiceCategoryType;
    message: string;
  }>();

  const {
    mutateAsync: doFetchShipmentAvailabilities,
    ...shipmentAvailabilities
  } = useMutation(fetchShipmentAvailabilities);

  const ratesQueryOptions = useMemo(() => {
    const isEndyReturn = isEndy && isReturn;
    const body = {
      order_item_ids: selectedOrderItemIDs,
      job_type: !isReturn ? "delivery" : "reverse_logistics",
      use_original_package: isReturnWithOriginalPackage,
      include_default_carriers: !isEndy && !isHamuq,
      ...(!isReturnWithOriginalPackage &&
        packageDetails.length && {
          packages: packageDetails,
        }),
      ...(isEndyReturn && {
        ship_to_address_id:
          selectedOrgAddress?.id !== "second_closet"
            ? selectedOrgAddress?.id
            : undefined,
      }),
      ...(serviceCategory === ServiceCategory.freight && {
        provider_scope: ["frontier"],
      }),
    };
    return { orderID: order.id, body };
  }, [
    serviceCategory,
    selectedOrderItemIDs,
    isReturn,
    isReturnWithOriginalPackage,
    packageDetails,
    selectedOrgAddress,
    isHamuq,
    isEndy,
    order.id,
  ]);

  const isFetchRatesEnabled = useMemo(() => {
    const isThirdPartySelected =
      serviceCategory === ServiceCategory.third_party;
    const isFreightSelected = serviceCategory === ServiceCategory.freight;
    const isStepActive = activeStepKey === "selectShipmentCarrier";
    const isEnabled = !!isReturn && (isThirdPartySelected || isFreightSelected);
    if (isEndy) {
      return isEnabled && (isFreightSelected || !!selectedOrgAddress);
    }
    return isEnabled && isStepActive;
  }, [serviceCategory, isReturn, isEndy, selectedOrgAddress, activeStepKey]);

  const shipmentRates = useQuery(
    ["shipmentRates", ratesQueryOptions],
    () => fetchShipmentRates(ratesQueryOptions),
    {
      enabled: isFetchRatesEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const organizationDetails = useQuery(
    ["organizationDetails", order?.organization?.id],
    () => fetchOrganizationDetails(order?.organization?.id),
    {
      enabled: !!order?.organization?.id,
      refetchOnWindowFocus: false,
    }
  );

  const recommendedShippingMethod = useQuery(
    ["recommendedShippingMethod", selectedOrderItemIDs],
    () => fetchRecommendedShippingMethod(selectedOrderItemIDs),
    {
      enabled: !isReturn,
      onSuccess: (method) => {
        const serviceCategory = mapRecommendedMethodToServiceCategory(method);
        dispatchSetServiceCategory(serviceCategory);
      },
    }
  );

  const { carriers: orgCarriers, addresses: orgAddresses } =
    organizationDetails.data || {};

  // error handling side effect
  useEffect(() => {
    const shipmentRatesError = shipmentRates.data?.errors?.join(",");
    if (!shipmentRatesError) return;
    notification.error({
      message: "Error",
      description: shipmentRatesError,
    });
    if (shipmentRates.isError) shipmentRates.remove();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [shipmentRates]);

  const handleSelectSCServiceCarrier = (code: SCServiceCodeType) => {
    const { carrierCode, options, logo } = getSecondClosetCarrier();
    const option = options.find((o) => code === o.key);
    dispatchSetShipmentCarrier({
      serviceCode: option?.key,
      serviceType: option?.label,
      carrierCode,
      logo,
    });
  };

  const handleSelectSCBookingMethod = (method: SCBookingMethodType) => {
    dispatchSetIsSendEmail(method === SCBookingMethod.email);
  };

  const handleFetchAvailabilities = async () => {
    const today = moment().format("YYYY-MM-DD");
    const daysAhead = moment().add(60, "days").format("YYYY-MM-DD");
    const order_item_ids = selectedOrderItemIDs;
    const service_code = shipmentCarrier?.serviceCode;
    const options = {
      date_from: today,
      date_to: daysAhead,
      order_id: order.id,
      order_item_ids,
      service_code,
    };
    return await doFetchShipmentAvailabilities(options);
  };

  const handleSelectShippingRate = (rate?: ShippingRate) => {
    setSelectedShippingRate(rate);
    if (!rate) return dispatchSetShipmentCarrier(undefined);
    return dispatchSetShipmentCarrier({
      carrierCode: rate.carrier?.carrier_code,
      rate: {
        rateID: rate.rate_id,
        shipmentID: rate.shipment_id,
        externalPlatform: rate.external_platform,
      },
      serviceType: rate.service_type,
      logo: rate.carrier?.logo?.url,
    });
  };

  const handleSelectPickupPartner = (carrier?: Carrier) => {
    if (!carrier) return;
    const { id, name } = carrier;
    dispatchSetPartner({ partnerID: id, name });
    dispatchSetShipmentCarrier(null);
    setSelectedPickupPartner(carrier);
  };

  useEffect(() => {
    if (isReturnWithOriginalPackage && !isPartialReturn) {
      const hasFreightShipment = (order.shipments as Fulfillment.Shipment[]).some(
        (s) => s.freight
      );
      if (hasFreightShipment)
        setDisabledServiceCategory({
          serviceCategory: ServiceCategory.third_party,
          message: "You can't return freight original package using Parcel",
        });
    } else {
      const isMultiplePackage = packageDetails?.length > 1;
      const hasPallets = packageDetails.some(
        (p: Fulfillment.Package) => p.package_type !== "box"
      );
      if (isEndy && isReturnWithOriginalPackage && !hasPallets)
        setDisabledServiceCategory(undefined);
      else
        setDisabledServiceCategory({
          serviceCategory:
            isMultiplePackage || hasPallets
              ? ServiceCategory.third_party
              : undefined,
          message: isMultiplePackage
            ? "You can't return more than one package using Parcel"
            : "You can't return pallets using Parcel",
        });
    }

    return () => {
      setDisabledServiceCategory(undefined);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    packageDetails,
    isReturnWithOriginalPackage,
    isPartialReturn,
    selectedOrderItemIDs,
    isEndy,
  ]);

  // reset when toggle service category
  useEffect(() => {
    //reset local component state
    setSelectedOrgAddress(undefined);
    setSelectedShippingRate(undefined);
    setSelectedPickupPartner(undefined);
    onOtherCarrierSelected(false);
    //reset redux state
    dispatchSetShipmentCarrier(undefined);
    dispatchSetIsSendEmail(true);
    dispatchSetPartner("");
    dispatchSetShipmentDate(null);
    dispatchSetShipmentTime(null);

    if (serviceCategory === disabledServiceCategory?.serviceCategory)
      dispatchSetServiceCategory(undefined);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [serviceCategory, packageDetails, disabledServiceCategory]);

  const scServiceProps = {
    selectedSCService: shipmentCarrier?.serviceCode,
    onSelectSCService: handleSelectSCServiceCarrier,
    recommendedServiceLevel: order?.selected_service_code,
    scBookingMethod: isSendEmail
      ? SCBookingMethod.email
      : SCBookingMethod.book_now,
    onSelectScBookingMethod: handleSelectSCBookingMethod,
    isScBookingMethodDisabled: !shipmentCarrier,
    customerEmailAddress: order?.customer?.email_address,
    shipmentDate: shipmentDate,
    shipmentTime: shipmentTime,
    onSelectShipmentDate: dispatchSetShipmentDate,
    onSelectShipmentTime: dispatchSetShipmentTime,
    onFetchAvailabilities: handleFetchAvailabilities,
    availabilities: shipmentAvailabilities.data,
    SCServiceTier:
      organizationDetails.data?.recommended_shipping_methods?.sc_service_tier,
    isAvailabilityAdmin: isSupportEscalated(user?.role, user?.subrole),
  };

  const parcelServiceProps = {
    shouldFetchShippingRates: isReturn,
    shippingRates: shipmentRates.data?.rates || [],
    selectedShippingRate: selectedShippingRate,
    onSelectShippingRate: handleSelectShippingRate,
    isFetchingShippingRates: shipmentRates.isFetching,
    isSelectAddressVisible: isEndy && isReturn,
    organizationAddresses: orgAddresses,
    selectedOrgAddress: selectedOrgAddress,
    onSelectOrgAddress: setSelectedOrgAddress,
    isCreatingDeliveryShipment,
    createDeliveryShipmentError,
  };

  const freightServiceProps = {
    shippingRates: shipmentRates.data?.rates || [],
    selectedShippingRate: selectedShippingRate,
    onSelectShippingRate: handleSelectShippingRate,
    isFetchingShippingRates: shipmentRates.isFetching,
    onOtherCarrierSelected,
    isOtherCarrierSelected,
    isCreatingDeliveryShipment,
  };

  const otherPartnerServiceProps = {
    organizationCarriers: orgCarriers,
    onSelectPickupPartner: handleSelectPickupPartner,
    selectedPickupPartner: selectedPickupPartner,
  };

  const isLoading =
    recommendedShippingMethod?.isLoading || organizationDetails.isLoading;

  return (
    <S.Container>
      <SelectShipmentCarrier
        selectedServiceCategory={serviceCategory}
        onSelectServiceCategory={dispatchSetServiceCategory}
        recommendedShippingMethod={recommendedShippingMethod?.data || null}
        isInitialPropsLoading={isLoading}
        otherPartnersVisible={isEndy && isReturn}
        scServiceProps={scServiceProps}
        parcelServiceProps={parcelServiceProps}
        freightServiceProps={freightServiceProps}
        otherPartnerServiceProps={otherPartnerServiceProps}
        disabledServiceCategory={disabledServiceCategory}
        isReturn={isReturn}
        isAdmin={true}
      />
    </S.Container>
  );
};

export default SelectShipmentCarrierStep;
