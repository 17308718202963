import React, { useState, useEffect } from "react";
import { Button, notification, Tooltip } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

// components
import PickSheetStatusModal, {
  ShipmentsPreview,
} from "../PickSheetStatusModal";

// hooks
import {
  useCreateShipment,
  useShipmentPickSheet,
} from "../../../../hooks/api/fulfillment/shipment";

// interface
import { Fulfillment } from "@secondcloset/types";
interface Props {
  selectedOrders: Fulfillment.Order[];
  className?: string;
  refetch: () => void;
}

const VALID_ORDER_TAG = "ready_to_fulfill";
const ORDER_MIN_COUNT = 1;
const ORDER_MAX_COUNT = 20;

const PrintPickSheetButton: React.FC<Props> = ({
  selectedOrders,
  className = "",
  refetch,
}) => {
  const {
    shipment: createShipments,
    createShipmentsWithOrderIDs,
  } = useCreateShipment();
  const { pickSheet, downloadPickSheetFileV2 } = useShipmentPickSheet();
  const [previewListItems, setPreviewListItems] = useState<ShipmentsPreview[]>(
    []
  );
  const [showPickSheetStatusModal, setShowPickSheetStatusModal] = useState(
    false
  );
  const [shipmentIDs, setShipmentIDs] = useState<string[]>([]);

  useEffect(() => {
    const error = createShipments.error || pickSheet.error;
    if (!error) return;

    notification.error({ message: "Error", description: error });
  }, [createShipments.error, pickSheet.error]);

  const getPrintableState = () => {
    const ordersCount = selectedOrders.length;
    const shipmentTags = selectedOrders.map((order) => order?.shipment_tags);
    const shipmentsReady = shipmentTags.every((tags) =>
      tags.find((tag) => tag === VALID_ORDER_TAG)
    );
    const disabled = !(
      ordersCount >= ORDER_MIN_COUNT &&
      ordersCount <= ORDER_MAX_COUNT &&
      shipmentsReady
    );
    let tooltip = "";
    if (!shipmentsReady)
      tooltip = 'Pick sheet can be printed for "Ready To Fulfill" orders';
    else if (ordersCount < ORDER_MIN_COUNT)
      tooltip = "You need to select at least one order.";
    else if (ordersCount > ORDER_MAX_COUNT)
      tooltip = `You can print pick sheet for up to ${ORDER_MAX_COUNT} orders only at once.`;

    return { disabled, tooltip };
  };

  const handlePrintPickSheet = async () => {
    const orderIDs = selectedOrders.map((order) => order?.id);
    const shipmentItems = (await createShipmentsWithOrderIDs(
      orderIDs
    )) as ShipmentsPreview[];
    if (!shipmentItems || shipmentItems.length === 0) return;

    setPreviewListItems(shipmentItems);
    setShowPickSheetStatusModal(true);
    const shipmentIDs = shipmentItems.reduce(
      (acc, preview: ShipmentsPreview) => {
        if (preview?.shipment_id) acc.push(preview.shipment_id);
        return acc;
      },
      [] as string[]
    );
    if (shipmentIDs.length === 0) return;

    setShipmentIDs(shipmentIDs);
    await downloadPickSheetFileV2(shipmentIDs);
    refetch();
  };

  const handlePickSheetReDownload = async () => {
    await downloadPickSheetFileV2(shipmentIDs);
  };

  const renderPickSheetButton = () => {
    const { disabled, tooltip } = getPrintableState();
    return (
      <Tooltip title={tooltip}>
        <Button
          {...{ className }}
          disabled={disabled}
          loading={createShipments.loading || pickSheet.loading}
          onClick={handlePrintPickSheet}
          icon={<PrinterOutlined />}
        >
          {createShipments.loading
            ? "Creating Shipment"
            : pickSheet.loading
            ? `Generating`
            : `Print Pick Sheets`}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {renderPickSheetButton()}
      <PickSheetStatusModal
        visible={showPickSheetStatusModal}
        isLoading={createShipments.loading || pickSheet.loading}
        data={previewListItems}
        onCancel={() => setShowPickSheetStatusModal(false)}
        onReDownload={handlePickSheetReDownload}
      />
    </>
  );
};

export default PrintPickSheetButton;
