import styled from "styled-components";

// Components
import { Input as InputBase } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Constants
import colors from "../../../../css/colors";

export const EditButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0 0;
  cursor: pointer;
  color: ${colors.BLUE};
`;

export const EditButtonText = styled.div`
  padding: 0 4px 0 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  &:not(:last-child) {
    padding: 0 0 16px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin: 0 24px 0 0;
  }

  .react-tel-input {
    min-width: 200px;

    input.form-control {
      width: 100%;
      max-height: 32px;
      color: ${colors.CHARCOAL};
      border: 1px solid #d9d9d9;

      &:hover {
        border-color: #40a9ff;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.CHARCOAL};
`;

export const Input = styled(InputBase)`
  min-width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  background-color: ${(props) =>
    props.disabled ? colors.GREY_LIGHT : "initial"};
  color: ${colors.CHARCOAL} !important;
`;

export const Phone = styled(PhoneInput)`
  min-width: 300px;
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.CHARCOAL};
`;
