import React, { useState, useEffect } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { Input, Button, Alert, notification } from "antd";
import { useCustomerCreate } from "../../../hooks/api/regular/customer";
import { withRouter, useHistory } from "react-router";

const CreateCustomerPage = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitError, setSubmitError] = useState("");
  const history = useHistory();

  const { customerCreateStatus, createCustomer } = useCustomerCreate();
  const clearError = () => {
    setSubmitError("");
  };

  const onCreateSuccess = () => {
    if (customerCreateStatus.data) {
      notification.success({
        message: "Create Success",
      });
      history.push("/customers");
    }

    if (customerCreateStatus.error) {
      notification.error({
        message: "Error",
        description: customerCreateStatus.error,
      });
    }
  };

  useEffect(clearError, [firstName, lastName, email, password, phone]);
  useEffect(onCreateSuccess, [customerCreateStatus]);

  const renderLabeledInput = (label, value, onChange) => {
    return (
      <div className="label-input">
        <div className="label">{label}</div>
        <Input value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
    );
  };

  const onSubmit = () => {
    const first_name = firstName.trim();
    const last_name = lastName.trim();
    const email_ = email.trim();
    const password_ = password.trim();
    const phone_number = phone.trim();
    const emailRegex = /^\S+@\S+$/;
    if (first_name.length < 2)
      return setSubmitError("First name must be 2 characters or more");
    if (last_name.length < 2)
      return setSubmitError("Last name must be 2 characters or more");
    if (phone.length < 10)
      return setSubmitError("Please enter a valid phone number");
    if (!emailRegex.test(email_)) return setSubmitError("invalid email format");
    if (password_.length < 6)
      return setSubmitError("Password must be at least 6 characters long");
    const body = {
      user: {
        first_name,
        last_name,
        email: email_,
        password: password_,
        phone_number,
      },
    };
    createCustomer(body);
  };

  const renderAlertBanner = () => {
    return (
      <div className="warning-bar">
        <Alert
          type="warning"
          message={
            <div>
              This is <b>NOT</b> the place to create a <b>Second Closet</b>{" "}
              staff account.
              <div>
                <div className="suggestion-title">To do so:</div>
                <ul>
                  <li>
                    Go to{" "}
                    <span
                      className="organization-link"
                      onClick={() => history.push("/organizations")}
                    >
                      organizations
                    </span>
                  </li>
                  <li>Click Second Closet</li>
                  <li>Click add users</li>
                </ul>
              </div>
            </div>
          }
          banner
        />
      </div>
    );
  };

  return (
    <div id="create-customer-page" className="page-container">
      {renderAlertBanner()}
      <div className="title">
        <UserAddOutlined /> Create Customer
      </div>
      {renderLabeledInput("First name", firstName, (v) => setFirstName(v))}
      {renderLabeledInput("Last name", lastName, (v) => setLastName(v))}
      {renderLabeledInput("Phone number", phone, (v) => setPhone(v))}
      {renderLabeledInput("Email", email, (v) => setEmail(v))}
      {renderLabeledInput("Password", password, (v) => setPassword(v))}

      <div className="action-buttons">
        <div className="error-bar">
          {submitError && <Alert type="error" message={submitError} banner />}
        </div>

        <Button loading={customerCreateStatus.loading} onClick={onSubmit}>
          Create Customer
        </Button>
      </div>
    </div>
  );
};

export default withRouter(CreateCustomerPage);
