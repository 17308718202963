import React, { FC } from "react";

// Components
import { Button } from "antd";
import {
  DownloadOutlined,
  CloseOutlined,
  SaveOutlined,
  EditOutlined,
} from "@ant-design/icons";

interface Props {
  isEditing: boolean;
  fetchingLabels: boolean;
  getWROShippingLabels: () => void;
  handleOnCancelEdit: () => void;
  loading: boolean;
  handleUpdateWRO: () => void;
  handleOnClickEditWRO: () => void;
  orderIsCompleted: boolean;
}

const WRODetailHeader: FC<Props> = ({
  isEditing,
  fetchingLabels,
  getWROShippingLabels,
  handleOnCancelEdit,
  loading,
  handleUpdateWRO,
  handleOnClickEditWRO,
  orderIsCompleted,
}) => {
  return (
    <div className="header-section">
      <div className="button-wrapper">
        <Button
          loading={fetchingLabels}
          onClick={() => getWROShippingLabels()}
          icon={<DownloadOutlined />}
        >
          Download All Labels
        </Button>
      </div>
      {isEditing ? (
        <>
          <div className="button-wrapper">
            <Button
              danger
              type="primary"
              onClick={handleOnCancelEdit}
              icon={<CloseOutlined />}
            >
              Cancel Edit
            </Button>
          </div>
          <div className="button-wrapper green">
            <Button
              type="primary"
              loading={loading}
              onClick={handleUpdateWRO}
              icon={<SaveOutlined />}
            >
              Save Order
            </Button>
          </div>
        </>
      ) : orderIsCompleted ? null : (
        <div className="button-wrapper">
          <Button onClick={handleOnClickEditWRO} icon={<EditOutlined />}>
            Edit WRO
          </Button>
        </div>
      )}
    </div>
  );
};

export default WRODetailHeader;
