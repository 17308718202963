import { Typography } from "antd";
import styled from "styled-components";

export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
`;

export const SelectLabel = styled(Typography.Text)`
  grid-column-start: auto;
  font-weight: 600;
`;
