import styled from "styled-components";
import colors from "css/colors";

interface WrapperProps {
  backgroundColor?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 8px 24px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.WHITE};
  border: 1px solid
    ${(props) => (props.color ? props.color : colors.GREY_ULTRA_LIGHT)};
  border-radius: 37px;
`;

export const StatusText = styled.div`
  color: ${(props) => (props.color ? props.color : colors.GREY_DARK)};
  font-size: 16px;
`;
