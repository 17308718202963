import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spin } from "antd";
import ReturnCustomerClosetCard from "../../../../../components/storage/ReturnCustomerClosetCard";
// import Order from "../../../../../lib/regular/order";

const ReturnAddItemButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [returnCart, setReturnCart] = useState([]);
  const { orderDetails } = props;
  if (!orderDetails) return null;

  // const hasAppointmentWithin48hours = Order.hasAppointmentWithinHours(
  //   orderDetails?.data,
  //   48
  // );

  const renderCustomerCloset = () => {
    return (
      <div className="add-storage-items">
        <ReturnCustomerClosetCard
          returnCart={returnCart}
          setReturnCart={setReturnCart}
        />
      </div>
    );
  };

  const buildAddItemsModal = () => {
    return (
      <Modal
        title="Add Items"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() =>
          props.onAddReturnItems(returnCart).then((res) => {
            if (res) setModalVisible(false);
          })
        }
        width={800}
        destroyOnClose
      >
        <div className="storage-add-items-modal">
          <Spin spinning={orderDetails.loading} tip="updating...">
            {renderCustomerCloset()}
          </Spin>
        </div>
      </Modal>
    );
  };

  const onAddItemsClick = () => {
    setReturnCart([]);
    setModalVisible(true);
  };

  // if (hasAppointmentWithin48hours) {
  //   return (
  //     <Tooltip title="disabled when appointments within 48 hours">
  //       <Button className="add-item-button" disabled={true}>
  //         Add Item
  //       </Button>
  //     </Tooltip>
  //   );
  // }

  return (
    <>
      <Button
        className="add-item-button"
        onClick={onAddItemsClick}
        disabled={props.disabled}
      >
        Add Item
      </Button>
      {buildAddItemsModal()}
    </>
  );
};

ReturnAddItemButton.propTypes = {
  onAddReturnItems: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  productIndex: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default ReturnAddItemButton;
