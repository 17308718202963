import {
  cancelPackageDelivery,
  redeliverPackage,
} from "api/logistics/shipment";
import { useMutation } from "react-query-legacy";

const useCancelPackageDelivery = () => {
  return useMutation((packageID: string) => cancelPackageDelivery(packageID));
};

const useRedeliverPackage = () => {
  return useMutation((packageID: string) => redeliverPackage(packageID));
};

export { useCancelPackageDelivery, useRedeliverPackage };
