import React, { useState, useEffect } from "react";
import { get } from "lodash-es";
import PropTypes from "prop-types";
import { Select, notification } from "antd";
import * as S from "../styles";

// hooks
import {
  useCustomerAddresses,
  useCustomerIndex,
} from "../../../../../../hooks/api/fulfillment/customer";

const AddressSelector = ({
  organizationID,
  selectedWarehouseAddressID,
  setSelectedWarehouseAddressID,
  shouldBeDisabled,
}) => {
  const [warehouseAddressList, setWarehouseAddressList] = useState();
  const { customerAddresses, fetchCustomerAddresses } = useCustomerAddresses();
  const { customerIndex, fetchCustomerIndex } = useCustomerIndex(
    organizationID
  );

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getIkeaWarehouseAddresses = async () => {
      const query = { q: "Ikea Warehouse" };
      const customers = await fetchCustomerIndex(query);
      if (!customers) return;
      const user_id = get(customers, "[0].id");
      const addresses = await fetchCustomerAddresses(user_id);
      if (addresses) {
        setWarehouseAddressList(addresses);

        // set default value, usually the last one is a toronto warehouse
        const addressesLength = addresses.length;
        const defaultWarehouse =
          addresses.find(
            (address) => address.address === "1475 The Queensway"
          ) || addresses[addressesLength - 2];
        setSelectedWarehouseAddressID(defaultWarehouse.id);
      }
    };
    if (!shouldBeDisabled) getIkeaWarehouseAddresses();
  }, []);

  const error = customerAddresses.error || customerIndex.error;

  const showError = (msg) => {
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  useEffect(() => {
    if (error) showError(error);
  }, [error]);

  const loading = customerAddresses.loading || customerIndex.loading;

  return (
    <S.SelectContainer>
      <S.SelectLabel>Select Warehouse Address:</S.SelectLabel>
      <Select
        value={
          selectedWarehouseAddressID || "Please select a warehouse address"
        }
        onChange={(id) => setSelectedWarehouseAddressID(id)}
        loading={loading}
        disabled={loading}
      >
        {warehouseAddressList?.map((a) => {
          return (
            <Select.Option key={a.id} value={a.id}>
              {a.address}
            </Select.Option>
          );
        })}
      </Select>
    </S.SelectContainer>
  );
};

AddressSelector.propTypes = {
  organizationID: PropTypes.string,
  shouldBeDisabled: PropTypes.bool,
  selectedWarehouseAddressID: PropTypes.string,
  setSelectedWarehouseAddressID: PropTypes.func,
};

export default AddressSelector;
