import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Filter.css";
import { getFormatedString } from "../../helperFunctions";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(item) {
    this.props.btnPressed(item);
  }

  render() {
    const {
      filterOptions,
      txtColor,
      bgColor,
      activeBtn,
      className,
      qtyOfItemsPerStatus,
    } = this.props;
    const style = {
      color: txtColor || "#ffffff",
      background: bgColor || "#404041",
    };

    const btns = filterOptions.map((item, index) => {
      let qtyOfItems = 0;
      if (qtyOfItemsPerStatus) {
        let foundItem = qtyOfItemsPerStatus.find(
          (q) => getFormatedString(q.status) === item
        );
        if (foundItem) qtyOfItems = foundItem.qty;
      }
      let noQtyClass = "";
      if (item === "all" || item === "All") noQtyClass = "noQtyBox";
      return (
        <button
          style={activeBtn === item ? style : undefined}
          key={index}
          onClick={() => this.handleClick(item)}
          className={
            activeBtn === item
              ? `btn active ${noQtyClass}`
              : `btn ${noQtyClass}`
          }
        >
          {item}
          {qtyOfItemsPerStatus && <span>{qtyOfItems}</span>}
        </button>
      );
    });

    return (
      <div className={className ? className + " closetFilter" : "closetFilter"}>
        {btns}
      </div>
    );
  }
}

export default Filter;

Filter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  btnPressed: PropTypes.func.isRequired,
  txtColor: PropTypes.string,
  bgColor: PropTypes.string,
  activeBtn: PropTypes.string,
  className: PropTypes.string,
  qtyOfItemsPerStatus: PropTypes.array,
};
