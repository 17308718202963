import React, { lazy, Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import { StripeProvider } from "react-stripe-elements";
import { userTokenCookieName } from "./lib/Cookies";
import { userInfoCookieName } from "./lib/Cookies";

// Actions
import { setUserToken, setUserInformation, signOut } from "./actions";

// Order components
import ItemPlanOrderCreatePage from "./pages/storage/ItemPlanOrderCreatePage";
import SpacePlanOrderCreatePage from "./pages/storage/SpacePlanOrderCreatePage";
import ReturnOrderCreatePage from "./pages/storage/ReturnOrderCreatePage";
import StorageOrderDetailsPage from "./pages/storage/StorageOrderDetailsPage";
import OrderEditPage from "./pages/storage/OrderEditPage";

// Customers
import CustomerIndexPage from "./pages/_common/CustomerIndexPage";
import CreateCustomerPage from "./pages/_common/CreateCustomerPage";
import CustomerInventoryPage from "./pages/storage/CustomerInventoryPage";
import CustomerDetailsPage from "./pages/_common/CustomerDetailsPage";

// Appointments
import AppointmentIndexPage from "./pages/_common/AppointmentIndexPage";
import AppointmentDetailsPage from "./pages/_common/AppointmentDetailsPage";

import Dashboard from "./pages/_common/DashboardPage";
import Login from "./containers/Login";
import Header from "./containers/regular/header/Header";
import { NavigationMenu } from "./components/NavMenu";
import ControlCenterPage from "./pages/_common/ControlCenterPage";
import InvoiceListPage from "./pages/storage/InvoiceListPage";
import ManifestPage from "./pages/storage/ManifestPage";
import Account from "./containers/Account";
import StorageOrderIndexPage from "./pages/storage/StorageOrderIndexPage";
import CategoryManagementPage from "./pages/storage/CategoryManagementPage";

import { InventoryShowAuth, CreateInvoiceAuth } from "./authorization";

// Fulfillment
import { WRODetail } from "./containers/fulfillment";
import FulfillmentOrderIndexPage from "./pages/fulfillment/FulfillmentOrderIndexPage";
import FulfillmentOrderDetailsPage from "./pages/fulfillment/FulfillmentOrderDetailsPage";
import FulfillmentWROIndexPage from "./pages/fulfillment/FulfillmentWROIndexPage";
import FulfillmentASNIndexPage from "./pages/fulfillment/FulfillmentASNIndexPage";
import FulfillmentASNDetailsPage from "./pages/fulfillment/FulfillmentASNDetailsPage";
import ShipmentCreatePage from "./pages/fulfillment/ShipmentCreatePage";
import ShipmentIndexPage from "./pages/fulfillment/ShipmentIndexPage";
import CompleteShopifyInstallPage from "./pages/_common/organization/CompleteShopifyInstallPage";

// Components
import { Spin } from "antd";
import PageNotFound from "./components/_common/PageNotFound";

// Config
import { navigationLinks } from "./config/navigationConfig";
import {
  FeatureFlagsProvider,
  useFeatureFlags,
} from "./contextProviders/FeatureFlagsProvider";
import DevToolsDrawer from "./devtools/DevToolsDrawer";
import { hot } from "react-hot-loader/root";
import notifyNewVersion from "./lib/notifyNewVersion";
import { withProfiler } from "./lib/sentry";
import { getAPIFromDomain } from "lib/api";
import FeatureFlagWrapper from "components/_common/FeatureFlagWrapper";
import { ReactQueryConfigProvider } from "react-query-legacy";

const OrganizationsRouter = lazy(() => import("routes/OrganizationsRouter"));
const WarehouseRouter = lazy(() => import("routes/WarehouseRouter"));
const ShippingCostsPage = lazy(() => import("pages/_common/ShippingCostsPage"));

const stripeAPIkey =
  process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_LIVE_KEY;

function filterBillingEngineLinks(links, billingEngineActive) {
  let filteredLinks = [];

  for (let i = 0; i < links.length; i++) {
    const link = { ...links[i] };

    if (link.items) {
      link.items = link.items.filter((l) =>
        l.billingEngine ? billingEngineActive : true
      );
    }

    if (link.billingEngine && !billingEngineActive) {
      continue;
    }

    filteredLinks.push(link);
  }

  return filteredLinks;
}

const App = ({
  history,
  setUserToken,
  setUserInformation,
  authenticated,
  signOut,
  subrole,
}) => {
  const [showHeaderAndNav, setShowHeaderAndNav] = useState(true);

  useEffect(() => {
    // load the cookie information into the application state if it exists.
    if (cookie.load(userTokenCookieName)) {
      const token = cookie.load(userTokenCookieName);
      const information = cookie.load(userInfoCookieName);
      setUserToken(token);
      setUserInformation(information);
      if (token && information && information.id) {
        const api = getAPIFromDomain();
        api.get(`/users/${information.id}`).catch(() => signOut());
      }
    }

    const checkForUpdate = async () => {
      const registration = await navigator?.serviceWorker?.getRegistration();
      if (!registration) return;
      // no update pending; check swr for updates
      else if (!registration.waiting) return await registration.update();
      // // update is pending for installation; show notification
      else return notifyNewVersion(registration);
    };

    // persistent notification when update is available
    checkForUpdate();

    window.addEventListener("focus", checkForUpdate);
    return () => window.removeEventListener("focus", checkForUpdate);
    // eslint-disable-next-line
  }, []);

  const { isActive } = useFeatureFlags();
  const billingEngineActive = isActive("billingEngine");

  if (!authenticated) return <Login />;

  const filteredLinks = filterBillingEngineLinks(
    navigationLinks.filter(
      (link) => !link.subroles.length || link.subroles.includes(subrole)
    ),
    billingEngineActive
  );

  const currentPathname = history.location.pathname;

  return (
    <>
      <div className="container-fluid fill sierraWrapper">
        {showHeaderAndNav && <Header />}

        <div className="row fill desktopNavigation">
          {showHeaderAndNav && (
            <NavigationMenu
              links={filteredLinks}
              isDesktop={true}
              currentPathname={currentPathname}
              handleNavbarToggle={() => {}}
              history={history}
            />
          )}

          <div className="col-xs-12 col-lg-10 content">
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15vh",
                  }}
                >
                  <Spin size="small" tip="Please wait..." />
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path="/orders/:orderID/user/:userID/order_edit"
                  component={OrderEditPage}
                />
                <Route
                  exact
                  path="/orders/:orderID/user/:userID"
                  component={StorageOrderDetailsPage}
                />

                <Route
                  exact
                  path="/customers/:userID/order/new"
                  render={(props) => <ItemPlanOrderCreatePage {...props} />}
                />

                <Route
                  exact
                  path="/customers/:userID/space-order/new"
                  render={(props) => <SpacePlanOrderCreatePage {...props} />}
                />

                <Route
                  exact
                  path="/customers/:userID/order/return"
                  render={(props) => <ReturnOrderCreatePage {...props} />}
                />

                <Route
                  exact
                  path="/customers/new"
                  component={CreateCustomerPage}
                />

                <Route
                  exact
                  path="/customers/:userID/inventory"
                  component={CustomerInventoryPage}
                />
                <Route
                  exact
                  path="/customers/:user_id/inventory-show/:inventory_id"
                  render={(props) => <InventoryShowAuth {...props} />}
                />
                <Route
                  exact
                  path="/customers/:id/invoice/new"
                  component={CreateInvoiceAuth}
                />
                <Route
                  exact
                  path="/customers/:id/invoice"
                  component={InvoiceListPage}
                />

                <Route
                  exact
                  path="/customers/:userID"
                  render={(props) => <CustomerDetailsPage {...props} />}
                />
                <Route
                  exact
                  path="/manifest/appointment/:ApptID"
                  component={ManifestPage}
                />
                <Route
                  exact
                  path="/appointments/:apptID"
                  component={AppointmentDetailsPage}
                />
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/dashboard" status={301} />}
                />
                <Route exact path="/customers" component={CustomerIndexPage} />
                <Route exact path="/orders" component={StorageOrderIndexPage} />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/control-center"
                  component={ControlCenterPage}
                />
                <Route
                  exact
                  path="/appointments"
                  component={AppointmentIndexPage}
                />
                <Route exact path="/account" component={Account} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route
                  exact
                  path="/category-management"
                  component={CategoryManagementPage}
                />

                <Route
                  exact
                  path="/fulfillment/orders"
                  component={FulfillmentOrderIndexPage}
                  key="all-fulfillment-orders"
                />
                <Route
                  exact
                  path={["/fulfillment/orders/:orderID", "/fulfillment/orders/:orderID/shipment/:shipmentID"]}
                  component={FulfillmentOrderDetailsPage}
                />

                <Route
                  exact
                  path="/fulfillment/orders/:orderID/create-shipment"
                  component={ShipmentCreatePage}
                />

                <Route
                  exact
                  path="/fulfillment/shipments"
                  component={ShipmentIndexPage}
                  key="all-shipment"
                />

                <Route
                  exact
                  path="/fulfillment/wros"
                  component={FulfillmentWROIndexPage}
                />

                <Route
                  exact
                  path="/fulfillment/asns"
                  component={FulfillmentASNIndexPage}
                />

                <Route
                  exact
                  path="/fulfillment/wros/:order_id"
                  component={WRODetail}
                />

                <Route
                  exact
                  path="/fulfillment/asns/:asnID"
                  component={FulfillmentASNDetailsPage}
                />

                <Route path="/organizations" component={OrganizationsRouter} />

                <Route
                  exact
                  path="/configuration/shopify/complete-install"
                  component={CompleteShopifyInstallPage}
                />
                <Route path="/warehouse" component={WarehouseRouter} />

                <FeatureFlagWrapper feature="billingEngine">
                  <Route path="/shipping-costs" component={ShippingCostsPage} />
                </FeatureFlagWrapper>
                <Route
                  path="*"
                  component={() => (
                    <PageNotFound setShowHeaderAndNav={setShowHeaderAndNav} />
                  )}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
      <DevToolsDrawer />
    </>
  );
};

function AppProviders({ children }) {
  return (
    <StripeProvider apiKey={stripeAPIkey}>
      <FeatureFlagsProvider>
        <ReactQueryConfigProvider
          config={{ retry: false, refetchAllOnWindowFocus: false }}
        >
          {children}
        </ReactQueryConfigProvider>
      </FeatureFlagsProvider>
    </StripeProvider>
  );
}

function AppContainer(props) {
  return (
    <AppProviders>
      <App {...props} />
    </AppProviders>
  );
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
    authenticated: state.auth.authenticated,
    subrole: state.auth.user.subrole,
  };
}

export default withRouter(
  connect(mapStateToProps, { setUserToken, setUserInformation, signOut })(
    process.env.NODE_ENV === "development"
      ? hot(AppContainer)
      : withProfiler(AppContainer)
  )
);

export { App };

App.propTypes = {
  history: PropTypes.object.isRequired,
  setUserToken: PropTypes.func.isRequired,
  setUserInformation: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  subrole: PropTypes.string,
};
