export const appointmentTypes = [
  "pick_up",
  "drop_off",
  "immediate_pick_up",
  "return",
  "retrieve",
  "immediate_retrieve",
  "reverse_logistics",
  "delivery",
  "custom",
];
