import React from "react";
import useShipmentCreateFlow from "../../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import {
  SelectItem,
  SelectItemInventoryWarehouseReceiving,
} from "@secondcloset/web-components";

const SelectItemsStep = () => {
  const {
    order,
    selectedOrderItemIDs,
    returnReasons,
    isEndy,
    isFullRefund,
    isExchange,
    isUnopenedCancellation,
    isReturnWarningVisible,
    isInventoryWarehouseReceiving,
    dispatchSetSelectedOrderItemIDs,
    dispatchSetReturnReasons,
    dispatchSetIsFullRefund,
    dispatchSetIsExchange,
    dispatchSetIsUnopenedCancellation,
    dispatchSetIsPartialReturn,
    isReturn,
  } = useShipmentCreateFlow();

  if (isInventoryWarehouseReceiving && !isReturn) {
    return (
      <SelectItemInventoryWarehouseReceiving
        order={order}
        selectedOrderItemIDs={selectedOrderItemIDs}
        setSelectedOrderItemIDs={dispatchSetSelectedOrderItemIDs}
      />
    );
  }

  return (
    <SelectItem
      order={order}
      selectedOrderItemIDs={selectedOrderItemIDs}
      setSelectedOrderItemIDs={dispatchSetSelectedOrderItemIDs}
      isReturn={isReturn}
      returnReasons={returnReasons}
      setReturnReasons={dispatchSetReturnReasons}
      isEndy={isEndy}
      isFullRefund={isFullRefund}
      isExchange={isExchange}
      isUnopenedCancellation={isUnopenedCancellation}
      isReturnWarningVisible={isReturnWarningVisible}
      setIsFullRefund={dispatchSetIsFullRefund}
      setIsExchange={dispatchSetIsExchange}
      setIsUnopenedCancellation={dispatchSetIsUnopenedCancellation}
      setIsPartialReturn={dispatchSetIsPartialReturn}
    />
  );
};

export default SelectItemsStep;
