// Inventory items
export const INVENTORY_ITEM_DETAILS_REQUESTED =
  "INVENTORY_ITEM_DETAILS_REQUESTED";
export const INVENTORY_ITEM_DETAILS_RECEIVED =
  "INVENTORY_ITEM_DETAILS_RECEIVED";
export const INVENTORY_ITEM_DETAILS_ERRORED = "INVENTORY_ITEM_DETAILS_ERRORED";

// Appointment details assign QR codes
export const ASSIGN_QR_MODAL_UPDATE_REQUEST = "ASSIGN_QR_MODAL_UPDATE_REQUEST";
export const ASSIGN_QR_MODAL_UPDATE_RESPONSE =
  "ASSIGN_QR_MODAL_UPDATE_RESPONSE";
export const ASSIGN_QR_MODAL_CLEAR_DATA = "ASSIGN_QR_MODAL_CLEAR_DATA";

// Appointment details edit name and nickname
export const EDIT_NAME_MODAL_UPDATE_REQUEST = "EDIT_NAME_MODAL_UPDATE_REQUEST";
export const EDIT_NAME_MODAL_UPDATE_RESPONSE =
  "EDIT_NAME_MODAL_UPDATE_RESPONSE";
export const EDIT_NAME_MODAL_CLEAR_DATA = "EDIT_NAME_MODAL_CLEAR_DATA";
