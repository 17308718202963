import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, notification } from "antd";
import { useShipmentCsvUpload } from "../../../../hooks/api/fulfillment/shipment";
import { get } from "lodash-es";

const UploadCsvButton = (props) => {
  const { uploadedCsv, uploadShipmentCsv } = useShipmentCsvUpload();

  useEffect(() => {
    if (uploadedCsv.error) {
      notification.error({
        message: "Error",
        description: uploadedCsv.error,
      });
    }
  }, [uploadedCsv.error]);

  const openFileUploader = () => {
    const uploader = document.getElementById("file-upload");
    uploader.click();
  };

  const uploadFile = async (event) => {
    const file = get(event, "target.files[0]");
    const res = await uploadShipmentCsv(file);
    if (res) {
      notification.success({
        message: "Success!",
        description: "Shipments updated.",
      });
      props.onRefetchShipments();
    }
  };

  const renderButton = () => {
    return (
      <Button
        className="upload-csv-btn"
        loading={uploadedCsv.loading}
        onClick={openFileUploader}
      >
        Upload CSV for Billing
      </Button>
    );
  };

  return (
    <>
      {renderButton()}
      <input
        type="file"
        id="file-upload"
        onChange={(e) => uploadFile(e)}
        key={`uploader ${uploadedCsv.loading}`}
      />
    </>
  );
};

UploadCsvButton.propTypes = {
  onRefetchShipments: PropTypes.func.isRequired,
};

export default UploadCsvButton;
