import React, { FC } from "react";

// Components
import { Modal, Button, Table } from "antd";
import { Footer } from "./ConfirmDeleteModal.styles";
import { Quantity } from "../ItemSummaryTable/ItemSummaryTable.styles";

// Interfaces
import {
  IncomingShipment,
  ShipmentContent,
} from "interfaces/warehouseReceivingOrders";

// Libs
import { startCase } from "lodash-es";

interface Props {
  handleDeleteShipment: () => void;
  palletNumber: number;
  shipment: IncomingShipment;
  visible: boolean;
  onCloseModal: () => void;
}

const ConfirmDeleteModal: FC<Props> = ({
  handleDeleteShipment,
  palletNumber,
  shipment,
  visible,
  onCloseModal,
}) => {
  const footer = (
    <Footer>
      <Button onClick={onCloseModal}>Cancel</Button>
      <Button danger type="primary" onClick={handleDeleteShipment}>
        Delete
      </Button>
    </Footer>
  );

  const renderName = (_: any, record: ShipmentContent) =>
    `${record.product.name}-${startCase(
      record?.product_packaging_level?.level?.replace("_", " ")
    ).replace(" ", "")}-QTY-${
      record.product_packaging_level.products_per_package
    }`;

  return (
    <Modal
      visible={visible}
      title="Delete Pallet"
      footer={footer}
      closable
      onCancel={onCloseModal}
    >
      {`Are you sure you want to delete Pallet #${palletNumber}?`}
      <Table
        dataSource={shipment.shipment_contents}
        columns={[
          {
            title: "Qty",
            dataIndex: "quantity_of_packages",
            key: "quantity_of_packages",
            render: (quantity) => <Quantity>{quantity}</Quantity>,
          },
          {
            title: "Product Name",
            key: "name",
            dataIndex: ["product", "name"],
            render: renderName,
          },
        ]}
        pagination={false}
        rowKey="id"
      />
    </Modal>
  );
};

export default ConfirmDeleteModal;
