import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spin } from "antd";
import SelectNewItemsCard from "../../../../../components/regular/order/dependencies/SelectNewItemsCard";
import SelectNewSpaceItemsCard from "../../../../../components/regular/order/dependencies/SelectNewSpaceItemsCard";
import { get } from "lodash-es";

const StorageAddItemButton = (props) => {
  const [addItemsModalVisible, setAddItemsModalVisible] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const { orderDetails, productIndex } = props;
  if (!orderDetails || !productIndex) return null;

  const isSpaceOrder = get(orderDetails, "data.space_order");
  const serviceArea = get(
    orderDetails,
    "data.appointments[0].address.service_area",
    "yyz"
  );

  const buildAddStorageItems = () => {
    if (!productIndex.data) return null;
    const filteredProducts = productIndex.data.filter(
      (p) =>
        p.category !== "product" && p.metadata.pricing_segment === serviceArea
    );

    return (
      <div className="add-storage-items">
        <SelectNewItemsCard
          products={filteredProducts}
          addedProducts={addedProducts}
          onProductChange={(newAddedProducts) =>
            setAddedProducts(newAddedProducts)
          }
        />
      </div>
    );
  };

  const buildAddSpaceItems = () => {
    if (!productIndex.data) return null;
    return (
      <div className="add-space-items">
        <SelectNewSpaceItemsCard
          addedItems={addedProducts}
          onItemsChange={(newAddedProducts) =>
            setAddedProducts(newAddedProducts)
          }
        />
      </div>
    );
  };

  const buildAddItemsModal = () => {
    if (!productIndex.data) return null;
    const content = isSpaceOrder
      ? buildAddSpaceItems()
      : buildAddStorageItems();

    return (
      <Modal
        title="Add Items"
        visible={addItemsModalVisible}
        onCancel={() => setAddItemsModalVisible(false)}
        onOk={() =>
          props.onAddItems(addedProducts).then((res) => {
            if (res) setAddItemsModalVisible(false);
          })
        }
        width={800}
      >
        <div className="storage-add-items-modal">
          <Spin spinning={orderDetails.loading} tip="updating...">
            {content}
          </Spin>
        </div>
      </Modal>
    );
  };

  const onAddItemsClick = () => {
    if (!productIndex.data) return;
    setAddedProducts({});
    setAddItemsModalVisible(true);
  };

  return (
    <>
      <Button
        className="add-item-button"
        onClick={onAddItemsClick}
        loading={productIndex.loading}
      >
        Add Item
      </Button>
      {buildAddItemsModal()}
    </>
  );
};

StorageAddItemButton.propTypes = {
  onAddItems: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  productIndex: PropTypes.object.isRequired,
};

export default StorageAddItemButton;
