import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  GODS_ONLY,
  SUPPORT_GROUP,
  SUPER_ONLY,
  MANAGEMENT,
} from "../config/roleConfig";

const GOD_PERMISSIONS = (
  <ul>
    <li>Set status of order on Order Details page</li>
  </ul>
);

const SUPER_PERMISSIONS = (
  <ul>
    <li>Full access to Appointment Details</li>
    <li>Select New Status</li>
    <li>Full access to Customer Details</li>
    <li>Change roles of users</li>
  </ul>
);

const MANAGEMENT_PERMISSIONS = (
  <ul>
    <li>Category Management</li>
    <li>Route Planning</li>
  </ul>
);

const SUPPORT_PERMISSIONS = (
  <ul>
    <li>View Customer Details</li>
    <li>Create Order</li>
    <li>Return Items</li>
    <li>Create Appointment</li>
    <li>Update Contact Info</li>
    <li>Update User Type</li>
    <li>Add and Edit Address</li>
    <li>View Appointment Detail</li>
    <li>Force Completion</li>
    <li>Edit Order Item</li>
    <li>Add Tasks</li>
    <li>Charge Moving Fee</li>
    <li>Reschedule</li>
    <li>View Order Details</li>
    <li>Edit Items</li>
    <li>Cancel Order</li>
    <li>Availability</li>
  </ul>
);

const BASIC_PERMISSIONS = (
  <ul>
    <li>View Dashboard</li>
    <li>View Customers Table</li>
    <li>View Customer Detail</li>
    <li>View Inventory</li>
    <li>View Invoices and Single Invoice</li>
    <li>Download Available QR code </li>
    <li>View Control Center </li>
    <li>View Appointment Detail</li>
    <li>View Manifest</li>
    <li>Add Tasks</li>
    <li>View Appointment Planner</li>
    <li>View Orders Table</li>
    <li>View Order Detail</li>
    <li>View Route Planning </li>
    <li>View QR code lookup </li>
  </ul>
);

class Account extends React.Component {
  buildPermissions = (subrole) => {
    switch (true) {
      case GODS_ONLY.includes(subrole):
        return (
          <div>
            <p>
              You are <b>{subrole.replace(/_/g, " ")}</b>, you can change
              people&apos;s lives, and:
            </p>
            {GOD_PERMISSIONS}
          </div>
        );
      case SUPER_ONLY.includes(subrole):
        return (
          <div>
            <p>
              As a(n) <b>{subrole.replace(/_/g, " ")}</b>, you have all
              permissions, including:
            </p>
            {SUPER_PERMISSIONS}
          </div>
        );
      case MANAGEMENT.includes(subrole):
        return (
          <div>
            <p>
              As a(n) <b>{subrole.replace(/_/g, " ")}</b>, you have all basic
              and support&apos;s permissions, as well as management&apos;s,
              including:
            </p>
            {MANAGEMENT_PERMISSIONS}
          </div>
        );
      case SUPPORT_GROUP.includes(subrole):
        return (
          <div>
            <p>
              As a(n) <b>{subrole.replace(/_/g, " ")}</b>, you have all basic
              and support&apos;s permissions, including:
            </p>
            {SUPPORT_PERMISSIONS}
          </div>
        );
      default:
        return (
          <div>
            <p>
              As a(n) <b>{subrole.replace(/_/g, " ")}</b>, you have basic
              permissions:
            </p>
            {BASIC_PERMISSIONS}
          </div>
        );
    }
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      role,
      subrole,
    } = this.props.auth.user;
    let name = `${first_name} ${last_name}`;
    let _subrole = subrole ? subrole.replace(/_/g, " ") : "n/a";
    const output = subrole ? this.buildPermissions(subrole) : "-";
    return (
      <div className="page-container">
        <h1>{name}</h1>
        <hr />
        <div>
          <p>Email: {email}</p>
          <p>Role: {role.replace(/_/g, " ")}</p>
          <p>Subrole: {_subrole}</p>
        </div>
        <hr />
        {output}
      </div>
    );
  }
}

Account.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(Account));
