import { FETCH_PRODUCTS_ALL } from "../actions";

export default function (state = [], { type, payload }) {
  switch (type) {
    case FETCH_PRODUCTS_ALL:
      return payload.data || [];
    default:
      return state;
  }
}
