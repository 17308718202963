import { startCase } from "lodash-es";
import { useMemo } from "react";
import { FetchOrderIndexQueryOption } from "../../../api/fulfillment/order";

export enum StockStatus {
  in_stock = "in_stock",
  partially_in_stock = "partially_in_stock",
  out_of_stock = "out_of_stock",
  unknown_stock = "unknown_stock",
}

export const ORDER_STATUS = {
  active: "Active",
  cancelled: "Cancelled",
};

export const PAGE_LINKS = {
  createOrder: "/orders/create",
  uploadCsv: "/orders/create-via-csv",
  reCreateOrder: "/orders/create?isAutoCreateShipment=true",
  syncShopifyOrder: "/sync-order-with-shopify",
  organizationDetails: "",
};

export const getShipmentTagOptions = () => [
  {
    key: "unfulfilled_items",
    label: "Unfulfilled",
    value: "unfulfilled_items",
  },
  {
    key: "ready_to_fulfill",
    label: "Ready to Fulfill",
    value: "ready_to_fulfill",
  },
  {
    key: "customer_booking",
    label: "Customer Booking",
    value: "customer_booking",
  },
  {
    key: "processing_items",
    label: "Processing Delivery",
    value: "processing_items",
  },
  { key: "items_fulfilled", label: "Fulfilled", value: "items_fulfilled" },
  {
    key: "processing_return_items",
    label: "Processing Return",
    value: "processing_return_items",
  },
  { key: "items_returned", label: "Returned", value: "items_returned" },
  {
    key: "pending_inventory_receiving",
    label: "Pending Inventory Receiving",
    value: "pending_inventory_receiving",
  },
];

export const getOrderStatusOptions = () =>
  Object.values(ORDER_STATUS).map((s) => ({ key: s, value: s, label: s }));

export const getStockStatusOptions = () =>
  Object.values(StockStatus).map((status) => ({
    key: status,
    label: startCase(status),
    value: status,
  }));

// todo type this params (move to hooks folder?)
export const useOrderIndexQueryOptions = ({
  urlState = {},
  sort = {},
  orgID = "",
}: Record<any, any>): FetchOrderIndexQueryOption => {
  return useMemo(() => {
    const {
      page,
      pageSize,
      search,
      shipmentTags,
      orderStatus,
      stockStatus,
      dateFrom,
      dateTo,
      organizations,
    } = urlState;
    const { field, direction } = sort;
    const is1OrderStatusSelected =
      orderStatus?.length && orderStatus?.length < 2;
    return {
      page: page,
      per_page: pageSize,
      // optional query params
      ...(orgID && { organization_id: orgID }),
      ...(search && { q: String(search).trim() }),
      ...(shipmentTags.length > 0 && {
        shipment_tags_values: shipmentTags,
        shipment_tags_condition: "any",
      }),
      ...(is1OrderStatusSelected && {
        cancelled: orderStatus?.includes(ORDER_STATUS.cancelled),
      }),
      ...(stockStatus?.length && { stock_status: stockStatus }),
      ...(dateFrom && { date_from: dateFrom }),
      ...(dateTo && { date_to: dateTo }),
      ...(organizations?.length && { organization_names: organizations }),
      ...(field && direction && { field, direction }),
    };
  }, [urlState, sort, orgID]);
};