import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useInvoicesList = () => {
  const [invoices, setInvoices] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchInvoices = (userId) => {
    setInvoices({ ...invoices, loading: true, error: null });

    const url = `/users/${userId}/invoices`;
    return api
      .get(url)
      .then((res) =>
        setInvoices({ loading: false, data: res.data.invoices, error: null })
      )
      .catch((error) => setInvoices({ ...invoices, loading: false, error }));
  };

  const updateInvoice = (userId, invoiceId, body) => {
    setInvoices({ ...invoices, loading: true, error: null });

    const url = `/users/${userId}/invoices`;
    return api
      .put(`${url}/${invoiceId}`, body)
      .then(() => api.get(url))
      .then((res) =>
        setInvoices({ loading: false, data: res.data.invoices, error: null })
      )
      .catch((error) => setInvoices({ ...invoices, loading: false, error }));
  };

  return { invoices, fetchInvoices, updateInvoice };
};

export { useInvoicesList };
