import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { DateTimeSelector } from "@secondcloset/web-components";
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";
import moment from "moment";
import { Spin } from "antd";
import { fetchShipmentAvailabilities } from "api/fulfillment/shipment";
import { useMutation } from "react-query";

const DateTimeSelect = ({
  order,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
}) => {
  const {
    mutate: doFetchShipmentAvailabilities,
    ...shipmentAvailabilities
  } = useMutation(fetchShipmentAvailabilities);

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const daysAhead = moment().add(30, "days").format("YYYY-MM-DD");
    const orderItemIDs = order?.items?.map((item) => item.id);
    const options = {
      date_from: today,
      date_to: daysAhead,
      order_id: order?.id,
      order_item_ids: orderItemIDs,
      service_code: "second_closet_standard",
    };
    doFetchShipmentAvailabilities(options);
    // eslint-disable-next-line
  }, []);

  const availabilityInstance = useMemo(() => {
    return new AppointmentsAvailability(shipmentAvailabilities.data || [], {
      isAdmin: true,
    });
    // eslint-disable-next-line
  }, [shipmentAvailabilities.data]);

  return (
    <div className="date-picker">
      <Spin spinning={shipmentAvailabilities.isLoading}>
        <DateTimeSelector
          selectedDate={selectedDate || moment().format("YYYY-MM-DD")}
          selectedTime={selectedTime}
          onSelectDate={setSelectedDate}
          onSelectTime={setSelectedTime}
          disabledDate={() => false}
          disabledTime={() => false}
          slots={availabilityInstance.getTimeslots(selectedDate)}
        />
      </Spin>
    </div>
  );
};

DateTimeSelect.propTypes = {
  order: PropTypes.object,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  selectedTime: PropTypes.string,
  setSelectedTime: PropTypes.func,
};

export default DateTimeSelect;
