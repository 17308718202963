import React from "react";
import PropTypes from "prop-types";
import { EditOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button } from "antd";
import AppointmentTasksButton from "./AppointmentTasksButton";
import AppointmentNotesButton from "./AppointmentNotesButton";
import DownloadQrCodesButton from "../../../../components/regular/_common/DownloadQrCodesButton";
import { withRouter } from "react-router-dom";
import Appointment from "../../../../lib/regular/appointment";
import { isEmpty } from "lodash-es";

const ButtonsRow = (props) => {
  if (!props.appointment) return null;
  const appointmentItems = Appointment.getAppointmentItems(props.appointment);
  const numOfItems = isEmpty(appointmentItems) ? 0 : appointmentItems.length;

  const { order_id, user_id, id, items, status } = props.appointment;
  const manifestURL = `/manifest/appointment/${id}`;
  const orderEditURL = `/orders/${order_id}/user/${user_id}/order_edit`;
  const editable =
    status !== "done" &&
    status !== "completed" &&
    status !== "cancelled" &&
    !!items;

  return (
    <div className="appointment-buttons">
      <AppointmentTasksButton apptID={id} />
      <AppointmentNotesButton apptID={id} />
      {items && (
        <DownloadQrCodesButton
          userID={user_id}
          initialNumberOfCodes={numOfItems}
        />
      )}
      <Button href={manifestURL} target="_blank">
        Manifest <PaperClipOutlined />
      </Button>
      {editable && (
        <Button onClick={() => props.history.push(orderEditURL)}>
          Edit Order Items <EditOutlined />
        </Button>
      )}
    </div>
  );
};

ButtonsRow.propTypes = {
  history: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
  onRefetchAppointment: PropTypes.func.isRequired,
};

export default withRouter(ButtonsRow);
