// types
import { Fulfillment } from "@secondcloset/types";
import { InventoryReceivingItemsTableTypes } from "@secondcloset/web-components";
type Shipment = Fulfillment.Shipment;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;
type ShippingMethodStatus = Fulfillment.ShippingMethodStatus;
type ShipmentItem = Fulfillment.ShipmentItem;
type InventoryReceivingItemStatus = InventoryReceivingItemsTableTypes.ItemStatus;

// enums
const InventoryReceivingItemStatus =
  InventoryReceivingItemsTableTypes.ItemStatus;

export const isInventoryWarehouseReceiving = (shipment: Shipment) => {
  const shippingMethod = shipment.shipping_method as SecondClosetShippingMethod;
  return shippingMethod.job_type === "inventory_warehouse_receiving";
};

export const getItemStatus = (item: ShipmentItem) => {
  const actionStatus =
    item?.shipment_actions_status?.inventory_warehouse_receiving;
  if (!actionStatus) return null;
  if (actionStatus.completed) return InventoryReceivingItemStatus.received;
  if (!actionStatus.failed) return InventoryReceivingItemStatus.toBeProcessed; // both complete and fail is false
  // below means item is failed
  switch (actionStatus.failed_reason?.toLocaleLowerCase()) {
    case InventoryReceivingItemStatus.missingItem.toLocaleLowerCase():
      return InventoryReceivingItemStatus.missingItem;
    case InventoryReceivingItemStatus.damaged.toLocaleLowerCase():
      return InventoryReceivingItemStatus.damaged;
    case InventoryReceivingItemStatus.wrongItem.toLocaleLowerCase():
      return InventoryReceivingItemStatus.wrongItem;
    case InventoryReceivingItemStatus.other.toLocaleLowerCase():
    default:
      return InventoryReceivingItemStatus.other;
  }
};

export const hasAnyIdleItem = (shipment: Shipment) => {
  if (!isInventoryWarehouseReceiving(shipment)) return undefined;
  return shipment?.shipment_items?.some(
    (item) => getItemStatus(item) === InventoryReceivingItemStatus.toBeProcessed
  );
};

export const constructUpdateItemStatusBody = (
  itemIDs: string[],
  newStatus: InventoryReceivingItemStatus
) => {
  const isFailedStatus = (status: InventoryReceivingItemStatus) => {
    const failedStatus = [
      InventoryReceivingItemStatus.damaged,
      InventoryReceivingItemStatus.missingItem,
      InventoryReceivingItemStatus.wrongItem,
      InventoryReceivingItemStatus.other,
    ];
    return failedStatus.some((fail) => fail === status);
  };

  const action = {
    action: "inventory_warehouse_receiving",
    completed: newStatus === InventoryReceivingItemStatus.received,
    failed: isFailedStatus(newStatus),
    failed_reason: isFailedStatus(newStatus) ? newStatus.toString() : null,
  };

  const shipmentItems = itemIDs.reduce((acc, itemID) => {
    acc.push({
      id: itemID,
      actions: [action],
    });
    return acc;
  }, [] as any[]);

  return { shipment_items: shipmentItems };
};
