import React, { useState } from "react";

// Components
import { OrderDetails, Notes } from "@secondcloset/web-components";
import EditShippingInfo from "./EditShippingInfo";
import { Button } from "antd";

// Types
import { Common, Fulfillment } from "@secondcloset/types";
import { UploadFile } from "antd/lib/upload/interface";
import { includes, some } from "lodash";

type File = Common.File;
type Customer = Fulfillment.Customer;
type CustomerAddress = Fulfillment.CustomerAddress;

interface Props {
  orderID: string;
  orderPlatform?: Fulfillment.Order["platform"];
  shipmentTags?: Fulfillment.Order["shipment_tags"];
  address: CustomerAddress;
  customer: Customer;
  externalOrderNumber: string;
  shippingService: string;
  internalNotes: string;
  marketPlaceNotes: string;
  marketplaceNotesTitle: string;
  fulfilledFrom: string;
  signatureRequired?: boolean;
  isLoading: boolean;
  insuranceValue: number;
  organizationID: string;
  createdDate?: string;
  files?: File[];
  onUpdateInternalNotes: (notes: string) => void;
  onFileUpload: (file: UploadFile) => unknown;
  onFileRemove: (file: File) => unknown;
  onFileDownload: (file: File, fileName: string) => unknown;
  onUpdateSuccess: () => any;
}

const DetailsAndNotes: React.FC<Props> = ({
  orderID,
  orderPlatform,
  address,
  customer,
  shipmentTags = [],
  externalOrderNumber,
  shippingService,
  internalNotes,
  marketPlaceNotes,
  marketplaceNotesTitle,
  fulfilledFrom,
  signatureRequired,
  isLoading,
  insuranceValue,
  createdDate,
  organizationID,
  files,
  onUpdateInternalNotes,
  onFileUpload,
  onFileRemove,
  onFileDownload,
  onUpdateSuccess,
}) => {
  const [
    isEditShippingInfoModalVisible,
    setIsEditShippingInfoModalVisible,
  ] = useState(false);

  const notEditableShipmentTags = [
    "processing_items",
    "items_fulfilled",
    "items_returned",
    "processing_return_items",
  ];

  const name = address?.contact_name || customer?.name || "-";
  const email = customer?.email_address || "-";
  const phone = address?.phone_number || customer?.phone_number || "-";
  const customerPhoneNumber = customer?.phone_number || "-";
  const eveningPhoneNumber = address?.evening_phone_number || "-";
  const cellPhoneNumber = address?.cell_phone_number || "-";
  const isChangeCustomerDisabled = some(shipmentTags, (tags) =>
    includes(notEditableShipmentTags, tags)
  );

  const renderEditShippingInfoBtn = () => {
    return (
      <Button
        style={{ margin: "1em 0" }}
        onClick={() => setIsEditShippingInfoModalVisible(true)}
        type="primary"
        ghost
      >
        Edit Shipping Information
      </Button>
    );
  };

  return (
    <div className="details-and-notes">
      <div className="details">
        <OrderDetails
          address={address}
          name={name}
          email={email}
          phone={phone}
          customerPhoneNumber={customerPhoneNumber}
          eveningPhoneNumber={eveningPhoneNumber}
          cellPhoneNumber={cellPhoneNumber}
          externalOrderNumber={externalOrderNumber}
          shippingService={shippingService}
          fulfilledFrom={fulfilledFrom}
          signatureRequired={signatureRequired}
          insuranceValue={insuranceValue}
          createdDate={createdDate}
          renderEditShippingInfoBtn={renderEditShippingInfoBtn}
        />

        {orderID && orderPlatform ? (
          <EditShippingInfo
            orderID={orderID}
            orgID={organizationID}
            customer={customer}
            address={address}
            orderPlatform={orderPlatform}
            isChangeCustomerDisabled={isChangeCustomerDisabled}
            isModalVisible={isEditShippingInfoModalVisible}
            onIsModalVisibleChange={setIsEditShippingInfoModalVisible}
            onUpdateSuccessCallback={onUpdateSuccess}
          />
        ) : null}
      </div>

      <div className="order-notes">
        <Notes
          internalNotes={internalNotes}
          marketplaceNotes={marketPlaceNotes}
          notesTitle="Internal Notes"
          marketplaceNotesTitle={marketplaceNotesTitle}
          isUploadDisabled={false}
          noteLoading={isLoading}
          showMarketPlaceNotes={true}
          size="large"
          onUpdateNotes={onUpdateInternalNotes}
          files={files}
          onFileUpload={onFileUpload}
          onFileRemove={onFileRemove}
          onFileDownload={onFileDownload}
        />
      </div>
    </div>
  );
};

export default DetailsAndNotes;
