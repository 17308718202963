import { SET_CUSTOMER_SEARCH } from "../actions";

const INITIAL_STATE = {
  customerSearch: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_CUSTOMER_SEARCH:
      return { ...state, customerSearch: payload };
    default:
      return state;
  }
}
