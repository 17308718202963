import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { get } from "lodash-es";
import { Button, Modal, notification } from "antd";
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";
import { DateTimeSelector } from "@secondcloset/web-components";
import { useAppointmentAvailability } from "../../../../hooks/api/logistics/availabilities";
import { useRecreateAppointment } from "../../../../hooks/api/storage/appointment";
import moment from "moment";

const FailedAppointmentBanner = ({ appointment, onRefetchAppointment }) => {
  const [availabilityInstance, setAvailabilityInstance] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [newDate, setNewDate] = useState(null);
  const [newTime, setNewTime] = useState(null);
  const history = useHistory();
  const {
    appointmentAvailability,
    fetchAvailableDates,
  } = useAppointmentAvailability();
  const {
    recreatedAppointmentStatus,
    recreateAppointment,
  } = useRecreateAppointment();
  const status = get(appointment, "status");
  const rescheduledApptID = get(appointment, "rescheduled_appointment_id", "");

  const groupItemsByStripeID = (items) => {
    const groupedItems = items.reduce((acc, cv) => {
      const stripeID = get(cv, "stripe_id");
      if (!stripeID) return acc;
      const quantity = Math.abs(get(cv, "quantity", 0));
      if (!acc[stripeID]) acc[stripeID] = 0;
      acc[stripeID] += quantity;
      return acc;
    }, {});

    return Object.entries(groupedItems).map(([stripe_plan_id, quantity]) => ({
      stripe_plan_id,
      quantity,
    }));
  };

  const error =
    appointmentAvailability.error || recreatedAppointmentStatus.error;

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: error,
      });
    }
  }, [error]);

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getAvailabilities = async () => {
      const address_id = get(appointment, "address.id");
      const customer_segment = "consumer";
      const appointmentItems = get(appointment, "items", []);
      const serviceArea = get(appointment, "address.service_area");
      const order_items = groupItemsByStripeID(appointmentItems);
      const today = moment().format("YYYY-MM-DD");
      const daysAhead = moment().add(90, "days").format("YYYY-MM-DD");
      const option = {
        address_id,
        customer_segment,
        order_items,
        date_from: today,
        date_to: daysAhead,
      };
      const data = await fetchAvailableDates(option);
      if (data) {
        const instance = new AppointmentsAvailability(data, {
          serviceArea: serviceArea || "yyz",
        });
        setAvailabilityInstance(instance);
      }
    };

    if (modalVisible) getAvailabilities();
  }, [modalVisible]);

  const renderFailedMessage = () => {
    const failedReason = get(appointment, "failed_reason", "-");
    const failedNotes = get(appointment, "failed_notes", "-");
    const appointmentRescheduledMsg = "Your appointment has been rescheduled!";

    return (
      <>
        <div>Reason: {failedReason}</div>
        <div> Notes: {failedNotes}</div>
        {rescheduledApptID && (
          <div className="reschedule-message">{appointmentRescheduledMsg}</div>
        )}
      </>
    );
  };

  const reset = () => {
    setModalVisible(false);
    setNewTime(null);
    setNewDate(null);
    setAvailabilityInstance(null);
  };

  const onSubmit = async () => {
    if (!newDate) {
      return notification.warning({ message: "Please select a date" });
    }
    if (!newTime) {
      return notification.warning({ message: "Please select a timeslot" });
    }

    const appt = await recreateAppointment(appointment, newDate, newTime);

    if (appt) {
      notification.success({
        message: "Successfully recreated the appointment",
      });

      onRefetchAppointment();
      reset();
    }
  };

  const renderModal = () => {
    if (!availabilityInstance) return null;
    const slots = availabilityInstance.getTimeslots(newDate);
    return (
      <Modal
        visible={modalVisible}
        onCancel={reset}
        onOk={onSubmit}
        title="Recreate appointment"
        width={600}
        confirmLoading={recreatedAppointmentStatus.loading}
      >
        <div className="recreate-appointment-modal">
          <DateTimeSelector
            selectedDate={newDate}
            selectedTime={newTime}
            onSelectDate={(d) => setNewDate(d)}
            onSelectTime={(t) => setNewTime(t)}
            disabledDate={(d) => availabilityInstance.isDateOneDisabled(d)}
            disabledTime={(t) =>
              availabilityInstance.isSlotOneDisabled(newDate, t)
            }
            slots={slots}
          />
        </div>
      </Modal>
    );
  };

  const renderGoToRescheduledApptBtn = () => {
    if (!rescheduledApptID) return;
    return (
      <div>
        <Button
          className="rescheduled-appt-btn"
          onClick={() => history.push(`/appointments/${rescheduledApptID}`)}
        >
          View Rescheduled Appointment
        </Button>
      </div>
    );
  };

  const renderCreateRescheduleApptBtn = () => {
    if (status !== "failed" || !!rescheduledApptID) return null;
    return (
      <Button
        danger
        ghost
        onClick={() => setModalVisible(true)}
        loading={appointmentAvailability.loading}
      >
        Recreate Appointment
      </Button>
    );
  };

  if (status !== "failed" && status !== "incomplete") return null;
  return (
    <div className="failed-appointment-banner">
      <div className="banner-title">Appointment {status}</div>
      {renderFailedMessage()}
      <div className="recreate-button-row">
        {renderCreateRescheduleApptBtn()}
        {renderGoToRescheduledApptBtn()}
      </div>
      {renderModal()}
    </div>
  );
};

FailedAppointmentBanner.propTypes = {
  appointment: PropTypes.object.isRequired,
  onRefetchAppointment: PropTypes.func.isRequired,
};

export default FailedAppointmentBanner;
