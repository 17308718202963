const filterProductsByGroup = (products, group) => {
  if (!products) return [];
  return products.filter(
    (p) => p.metadata && p.metadata.group && p.metadata.group === group
  );
};

const isGroupEqual = (product, group) =>
  product &&
  product.metadata &&
  product.metadata.group &&
  product.metadata.group === group;

export default class Products {
  static getClientVisibleProducts = (products) => {
    if (!products) return [];
    return products.filter((p) => p.metadata.webHidden === "false");
  };

  static getAdminVisibleProducts = (products) => {
    if (!products) return [];
    return products.filter((p) => p.metadata.sierraHidden === "false");
  };

  static getBoxes = (products) =>
    filterProductsByGroup(products, "Boxes")
      .concat(filterProductsByGroup(products, "Your Boxes"))
      .concat(filterProductsByGroup(products, "Student Special"));

  static getBulkyItems = (products) =>
    filterProductsByGroup(products, "Bulky Items");

  static getPallets = (products) => filterProductsByGroup(products, "Pallets");

  static getSupplies = (products) =>
    filterProductsByGroup(products, "Supplies");

  static getFees = (products) => filterProductsByGroup(products, "Fees");

  static getPlans = (products) =>
    products.filter((p) => p.metadata && p.metadata.spacePlan === "true");

  static isBox = (product) =>
    isGroupEqual(product, "Boxes") || isGroupEqual(product, "Your Boxes");

  static isBulkyItem = (product) => isGroupEqual(product, "Bulky Items");

  static isSupply = (product) => isGroupEqual(product, "Supplies");

  static isFee = (product) => isGroupEqual(product, "Fees");

  static isBulkyOnly = (products) => {
    if (!products || !Array.isArray(products) || products.length === 0)
      return false;
    return products.every((p) => p.metadata.dropoffable === "false");
  };

  static getUnitPrice = (product) => product.price;

  static getPriceBreakdown = (products, amount_off, percent_off) => {
    let breakdown = {
      subtotal: 0,
      monthlyRecurring: 0,
      coupon: 0,
    };
    if (!products) return breakdown;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const isSupply = Products.isSupply(product);
      const unitPrice = Products.getUnitPrice(product);
      const quantity = product.quantity || 0;
      const price = unitPrice * +quantity;
      breakdown.subtotal += price;
      if (!isSupply) {
        breakdown.monthlyRecurring += price;
      }
    }

    if (amount_off) {
      breakdown.subtotal -= amount_off;
      if (breakdown.subtotal < 0) breakdown.subtotal = 0;
      breakdown.coupon = -amount_off;
    }

    if (percent_off) {
      const couponAmount = breakdown.subtotal * (percent_off / 100);
      breakdown.coupon = -couponAmount;
      breakdown.subtotal -= couponAmount;
    }

    return Object.keys(breakdown).reduce((acc, cv) => {
      acc[cv] = (breakdown[cv] / 100).toFixed(2);
      return acc;
    }, {});
  };
}
