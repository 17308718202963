import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DownOutlined } from "@ant-design/icons";
import { Table, Tag, Menu, Button, Dropdown } from "antd";
import { useAppointmentIndex } from "../../../../hooks/api/regular/appointment";
import Address from "../../../../lib/regular/address";
import { Link, withRouter } from "react-router-dom";
import { getAppointmentColor } from "../../../../helperFunctions";
import NewCustomAppointmentButton from "../../../../components/regular/appointment/NewCustomAppointmentButton";
import ItemFilterButton from "../../../../components/regular/customer/dependencies/ItemFilterButton";
import { startCase, toLower, isEmpty } from "lodash-es";
import moment from "moment";
import { Availabilities } from "@secondcloset/logistics-utils";

const CustomerAppointmentsTable = (props) => {
  const [appointmentsGroupedByCity, setAppointmentsGroupedByCity] = useState(
    null
  );
  const [selectedCity, setSelectedCity] = useState("all");
  const { appointmentIndex, fetchAppointmentIndex } = useAppointmentIndex();
  const getAppointments = () => {
    fetchAppointmentIndex({
      user_id: props.userID,
      per_page: "ALL",
      field: "job_time",
    });
  };
  const onAppointmentsFetched = () => {
    if (appointmentIndex.data) {
      const cityAppts = appointmentIndex.data.reduce((acc, cv) => {
        const isDelivery = cv.type === "fulfillment";
        const addressObj = isDelivery ? cv.location : cv.address;
        const city = addressObj.city.toLowerCase();
        if (!acc[city]) acc[city] = [];
        acc[city].push(cv);
        return acc;
      }, {});
      setAppointmentsGroupedByCity(cityAppts);
    }
  };

  useEffect(getAppointments, []);
  useEffect(onAppointmentsFetched, [appointmentIndex]);

  const buildColumns = () => {
    const renderApptNum = (text, record) => {
      const path = `/appointments/${record.id}`;
      return <Link to={path}>{text}</Link>;
    };
    const renderStatus = (status) => (
      <Tag color={getAppointmentColor("status", status)}>
        {startCase(toLower(status))}
      </Tag>
    );
    const renderType = (type) => (
      <Tag color={getAppointmentColor("type", type)}>
        {startCase(toLower(type))}
      </Tag>
    );
    const renderDate = (date) => <span>{moment(date).format("ll")}</span>;

    const renderOrderID = (id, record) => {
      const isDelivery = record.type === "fulfillment";
      const orderID = isDelivery ? record.source.id : id;
      const path = isDelivery
        ? `/fulfillment/orders/${record.source.id}`
        : `/orders/${id}/user/${record.user_id}`;
      return <Link to={path}>{orderID ? ".." + orderID.slice(-4) : "-"}</Link>;
    };

    const renderAddress = (a, record) => {
      const isDelivery = record.type === "fulfillment";
      const address = isDelivery ? record.location : a;
      return <span>{Address.getOneLineAddress(address)}</span>;
    };

    const renderETC = (time) => {
      return time ? `${Availabilities.msToMin(time)}min` : "-";
    };

    return [
      {
        title: "Appointment #",
        dataIndex: "number",
        key: "number",
        render: renderApptNum,
      },
      {
        title: "Type",
        dataIndex: "job_type",
        key: "job_type",
        render: renderType,
        width: 150,
      },
      { title: "Date", dataIndex: "date", key: "date", render: renderDate },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        render: renderAddress,
        ellipsis: true,
        width: 150,
      },
      {
        title: "Timeslot",
        dataIndex: "formatted_timeslot",
        key: "formatted_timeslot",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: renderStatus,
      },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        render: renderOrderID,
      },
      {
        title: "ETC",
        dataIndex: "estimated_time",
        key: "estimated_time",
        render: renderETC,
      },
    ];
  };

  const renderOrderCreateTypeDropdown = () => {
    const options = [
      { name: "Item Plan", path: `/customers/${props.userID}/order/new` },
      {
        name: "Space Plan",
        path: `/customers/${props.userID}/space-order/new`,
      },
      { name: "Return", path: `/customers/${props.userID}/order/return` },
    ];
    const menu = (
      <Menu onClick={({ key }) => props.history.push(key)}>
        {options.map((opt) => (
          <Menu.Item key={opt.path}>{opt.name}</Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Dropdown overlay={menu} className="create-order-dropdown">
        <Button>
          Create Order <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const renderCityFilter = () => {
    if (
      !appointmentIndex.data ||
      isEmpty(appointmentsGroupedByCity) ||
      Object.keys(appointmentsGroupedByCity).length === 1
    ) {
      return null;
    }

    const cityList = Object.keys(appointmentsGroupedByCity);
    return (
      <div className="city-filter">
        <ItemFilterButton
          key={"all"}
          text={"all"}
          selected={selectedCity === "all"}
          count={appointmentIndex.data.length}
          onClick={() => setSelectedCity("all")}
        />
        {cityList.map((city) => {
          const count = appointmentsGroupedByCity[city].length;
          return (
            <ItemFilterButton
              key={city}
              text={city}
              selected={selectedCity === city}
              count={count}
              onClick={() => setSelectedCity(city)}
            />
          );
        })}
      </div>
    );
  };

  const getDataSource = () => {
    if (!appointmentIndex.data) return [];
    if (selectedCity === "all") return appointmentIndex.data;
    return appointmentsGroupedByCity[selectedCity];
  };

  return (
    <div className="customer-appointments-table">
      <div className="table-title">
        <div className="title">Appointment History</div>
        <div className="action-buttons">
          <NewCustomAppointmentButton
            userID={props.userID}
            onCreateSuccess={getAppointments}
          />
          {renderOrderCreateTypeDropdown()}
        </div>
      </div>
      {renderCityFilter()}
      <Table
        loading={appointmentIndex.loading}
        columns={buildColumns()}
        dataSource={getDataSource()}
        size="small"
        scroll={{ x: 950 }}
        rowKey={(record) => record.id}
      />
    </div>
  );
};

CustomerAppointmentsTable.propTypes = {
  userID: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CustomerAppointmentsTable);
