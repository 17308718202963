import React, { useState } from "react";

// Assets
import checkmark from "./assets/check-black.png";
import { DownOutlined } from "@ant-design/icons";

// Components
import { Dropdown as DropdownBase, Menu } from "antd";

//styles
import {
  DropdownTriggerButton,
  MenuItemWrapper,
  MenuItemIconWrapper,
  MenuItemIcon,
} from "./styles";

export interface Option {
  key: string;
  value: any;
  label: string;
}

type OptionKey = Option["key"];

interface Props {
  options: Option[];
  selectedOptionKeys: OptionKey[];
  setSelectedOptionKeys: (optionKeys: OptionKey[]) => any;
}

const Dropdown: React.FC<Props> = (props) => {
  const { options, selectedOptionKeys, setSelectedOptionKeys } = props;
  const [visible, setVisible] = useState(false);

  const handleOnClick = ({ key }: { key: string }) => {
    if (key === "all") {
      if (selectedOptionKeys.length === options.length) {
        setSelectedOptionKeys([]);
      } else {
        const allOptionKeys = options.map((option: Option) => option.key);
        setSelectedOptionKeys(allOptionKeys);
      }
    } else if (selectedOptionKeys.includes(key)) {
      const newSelectedOptionKeys = selectedOptionKeys.filter(
        (optKey: OptionKey) => optKey !== key
      );
      setSelectedOptionKeys(newSelectedOptionKeys);
    } else {
      const newSelectedOptionKeys = [...selectedOptionKeys, key];
      setSelectedOptionKeys(newSelectedOptionKeys);
    }
  };

  const renderCheckMarkIcon = (active: boolean) => {
    return (
      <MenuItemIconWrapper active={active}>
        {active && <MenuItemIcon src={checkmark} alt="checkmark" />}
      </MenuItemIconWrapper>
    );
  };

  const renderOptions = options.map((option: Option) => {
    const active = selectedOptionKeys.includes(option.key);
    return (
      <MenuItemWrapper key={option.key}>
        {renderCheckMarkIcon(active)}
        {option.label}
      </MenuItemWrapper>
    );
  });

  const handleVisibleChange = (isVisible: boolean) => setVisible(isVisible);

  const renderMenu = () => {
    const isAllSelected = selectedOptionKeys.length === options.length;
    return (
      <Menu onClick={handleOnClick as any}>
        <MenuItemWrapper key="all">
          {renderCheckMarkIcon(isAllSelected)}
          All
        </MenuItemWrapper>
        {renderOptions}
      </Menu>
    );
  };

  const dropdownText = () => {
    if (!selectedOptionKeys.length) return "None Selected";
    else if (selectedOptionKeys.length === options.length) return "All";
    else if (selectedOptionKeys.length === 1) {
      return `${
        options?.find((filter) => filter.value === selectedOptionKeys[0])?.label
      }`;
    } else return `${selectedOptionKeys.length} Selected`;
  };

  return (
    <DropdownBase
      overlay={renderMenu()}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <DropdownTriggerButton>
        {dropdownText()} <DownOutlined />
      </DropdownTriggerButton>
    </DropdownBase>
  );
};

export default Dropdown;
