import React, { useState } from "react";

// Styles
import { Fulfillment, Common } from "@secondcloset/types";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Alert, Table, Input } from "antd";
import { startCase } from "lodash-es";

import * as S from "./styles";

import { useCreateShipment } from "hooks/api/fulfillment/shipment";

import Order from "lib/fulfillment/order";

type OrderType = Fulfillment.Order;
type OrderItem = Fulfillment.OrderItem;
type BaseNote = Common.BaseNote;

interface Props {
  order?: OrderType;
  reFetchOrder: () => unknown;
}

enum tableColumns {
  name = "name",
  sku = "sku",
  quantity = "quantity",
}

interface ShipmentBody {
  order_id: string;
  order_items: OrderItem[];
  service_code: string;
  job_type: string;
  skip_customer_notification: boolean;
  notes_attributes: BaseNote[];
}

const CreateInventoryReceivingShipmentButton: React.FC<Props> = ({ order, reFetchOrder }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { shipment, createShipment } = useCreateShipment();
  const [notes, setNotes] = useState("");

  if (!order) return null;

  const createInventoryReceivingShipment = async () => {
    const unshippedItems = Order.getUnshippedItems(order);
    const orderItems = Object.values(unshippedItems).reduce((acc: any, item: any) => {
      const itemIDs = item.itemIDs.map((id: string) => ({
        id: id,
      }));
      const selectedItemIDs = itemIDs.slice(0, item.quantity);
      acc = [...acc, ...selectedItemIDs];
      return acc;
    }, [] as OrderItem[]);

    const inventoryReceivingShipmentBody = {
      order_id: order.id,
      order_items: orderItems,
      service_code: "second_closet_standard",
      job_type: "inventory_warehouse_receiving",
      skip_customer_notification: true,
    } as ShipmentBody;

    if (notes.trim()) {
      inventoryReceivingShipmentBody.notes_attributes = [
        {
          text: notes,
        } as BaseNote,
      ];
    }

    const respond = await createShipment(inventoryReceivingShipmentBody);
    if (respond) {
      setModalVisible(false);
      reFetchOrder();
    }
  };

  const renderError = () => {
    const error = shipment.error;
    if (!error) return null;
    return <Alert message={error} type="error" banner />;
  };

  const getColumns = () => {
    return Object.values(tableColumns).map((column) => {
      return { title: startCase(column), dataIndex: column, key: column };
    });
  };

  const getDataSource = () => {
    const unshippedItems = Order.getUnshippedItems(order);
    return Object.values(unshippedItems).map((item: any, index) => {
      const { name, quantity, sku } = item;

      return {
        key: index,
        name: name,
        sku: sku,
        quantity: quantity,
      };
    });
  };

  const renderToBeReceivedItemsTable = () => {
    const unshippedItems = Order.getUnshippedItems(order);
    const paginationConfig = Object.values(unshippedItems).length > 10 ? {} : false;
    return <Table columns={getColumns()} dataSource={getDataSource()} pagination={paginationConfig}></Table>;
  };
  const renderShipmentNotes = () => {
    return (
      <S.ShipmentNote>
        <S.ShipmentNoteTitle>Shipment Notes:</S.ShipmentNoteTitle>
        <Input.TextArea
          value={notes}
          placeholder="please enter reference/tracking number if possible"
          onChange={(e) => setNotes(e.target.value)}
          allowClear
        />
      </S.ShipmentNote>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onOk={createInventoryReceivingShipment}
        onCancel={() => setModalVisible(false)}
        title="Create Inventory Warehouse Receiving Shipments"
        confirmLoading={shipment.loading}
        destroyOnClose
      >
        {renderError()}
        {renderToBeReceivedItemsTable()}
        {renderShipmentNotes()}
        <div></div>
      </Modal>
    );
  };

  return (
    <div>
      <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
        Create Inventory Warehouse Receiving Shipments
      </Button>
      {renderModal()}
    </div>
  );
};

export default CreateInventoryReceivingShipmentButton;
