import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BackButton from "../../../components/fulfillment/_common/BackButton";
import SelectServiceAreaCard from "../../../components/regular/order/dependencies/SelectServiceAreaCard";
import SelectAddressCard from "../../../components/regular/_common/address/SelectAddressCard";
import SelectAppointmentDatesCard from "../../../components/regular/_common/dates/SelectAppointmentDatesCard";
import SelectPlanCard from "./SelectPlanCard";
import BillingDetailCard from "../../../components/regular/_common/billing/BillingDetailCard";
import SummaryCard from "../../../components/regular/order/dependencies/SummaryCard";
import { Button, Affix, Modal, Spin } from "antd";
import Products from "../../../lib/regular/products";
import Order from "../../../lib/regular/order";
import { isEmpty, get } from "lodash-es";
import ErrorBox from "../../../components/fulfillment/_common/ErrorBox";
import { useCustomerDetails } from "../../../hooks/api/regular/customer";
import { useOrder } from "../../../hooks/api/regular/order";
import { useCustomerBilling } from "../../../hooks/api/regular/customer";

const SpacePlanOrderCreatePage = (props) => {
  const { userID } = props.match.params;
  const [selectedServiceArea, setSelectedServiceArea] = useState(null);
  const [selectedConsumerSegment, setSelectedConsumerSegment] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [appointmentDates, setAppointmentDates] = useState({
    date1: null,
    date2: null,
    time1: null,
    time2: null,
    immediate: false,
  });
  const [isSendInvoice, setIsSendInvoice] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState(null);
  const [addedProducts, setAddedProducts] = useState({});
  const [addedItems, setAddedItems] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const { customerDetails, fetchCustomerDetails } = useCustomerDetails();
  const { order, createOrder, previewOrder, reset: resetOrder } = useOrder();
  const { customerBilling, fetchCustomerBilling } = useCustomerBilling();

  const onMount = () => {
    fetchCustomerDetails(userID);
    fetchCustomerBilling(userID);
    window.scrollTo(0, 0);
  };

  const onCustomerBillingChange = () => {
    if (hasCreditCardOnFile(customerBilling.data) && isSendInvoice)
      setIsSendInvoice(false);
  };

  const onResetOrderAndError = () => {
    resetOrder();
    setError(null);
    setIsCouponValid(false);
  };

  useEffect(onMount, []);
  useEffect(onCustomerBillingChange, [customerBilling.data]);
  useEffect(onResetOrderAndError, [
    selectedAddress,
    addedProducts,
    appointmentDates,
    addedItems,
    selectedConsumerSegment,
    selectedServiceArea,
  ]);

  const hasCreditCardOnFile = (billing) =>
    billing && billing.sources.data.length > 0;

  const getError = () => {
    const { date1, date2, time1, time2, immediate } = appointmentDates;
    const isBulkyOnly = true;
    const hasAddress = !!selectedAddress;
    const requiredTwoDates = !isBulkyOnly && !immediate;
    const hasDates = requiredTwoDates
      ? date1 && date2 && time1 && time2
      : date1 && time1;
    const hasPlans = !isEmpty(Products.getPlans(Object.values(addedProducts)));
    const hasItems = !isEmpty(Object.values(addedItems));
    const hasCreditCard = hasCreditCardOnFile(customerBilling.data);
    const isBusinessUser =
      customerDetails.data && customerDetails.data.business_user;
    if (!selectedServiceArea) return "please select service area";
    if (!selectedConsumerSegment) return "please select customer segment";
    if (!hasPlans) return "please select a space plan";
    if (!hasItems) return "please add items";
    if (!hasAddress) return "please select an address";
    if (!hasDates) return "please select appointment date & time";
    if (!isBusinessUser && !hasCreditCard) return "please add billing info";
    return null;
  };

  const buildSelectServiceAreaCard = () => {
    return (
      <div className="select-service-area">
        <SelectServiceAreaCard
          selectedServiceArea={selectedServiceArea}
          setSelectedServiceArea={setSelectedServiceArea}
          selectedConsumerSegment={selectedConsumerSegment}
          setSelectedConsumerSegment={setSelectedConsumerSegment}
        />
      </div>
    );
  };

  const buildAddressCard = () => {
    return (
      <div className="select-address">
        <SelectAddressCard
          onSelectAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          selectedServiceArea={selectedServiceArea}
        />
      </div>
    );
  };

  const buildAppointmentDatesCard = () => {
    const addressID = get(selectedAddress, "id", null);
    const compressedItems = Object.values(addedItems).reduce((acc, item) => {
      const stripe_plan_id = item.stripe_plan_id;
      if (!acc[stripe_plan_id]) acc[stripe_plan_id] = 0;
      acc[stripe_plan_id] += item.quantity;
      return acc;
    }, {});

    const orderItems = Object.keys(compressedItems).map((stripe_plan_id) => ({
      stripe_plan_id,
      quantity: compressedItems[stripe_plan_id],
    }));

    return (
      <div className="select-dates">
        <SelectAppointmentDatesCard
          onDateTimeSelect={setAppointmentDates}
          isStorage={true}
          isBulky={true}
          appointmentDates={appointmentDates}
          selectedConsumerSegment={selectedConsumerSegment}
          addressID={addressID}
          orderItems={orderItems}
        />
      </div>
    );
  };

  const buildProductsCard = () => {
    return (
      <div className="select-products">
        <SelectPlanCard
          onProductChange={setAddedProducts}
          addedProducts={addedProducts}
          addedItems={addedItems}
          onItemsChange={setAddedItems}
          selectedServiceArea={selectedServiceArea}
        />
      </div>
    );
  };

  const buildBillingDetailCard = () => {
    return (
      <div className="billing-details">
        <BillingDetailCard
          userID={userID}
          onUpdateBillingSuccess={() => fetchCustomerBilling(userID)}
        />
      </div>
    );
  };

  const buildBillingTypeCard = () => {
    const { data, loading } = customerDetails;
    if (loading) {
      return (
        <div className="billing-type spinning">
          <Spin tip="fetching customer detail..." />
        </div>
      );
    }

    if (!data || !data.business_user) return null;

    return (
      <div className="billing-type">
        <div className="title">Billing Types</div>
        <div className="button-group">
          <Button
            type={isSendInvoice ? "primary" : "default"}
            onClick={() => setIsSendInvoice(true)}
          >
            Send Invoice
          </Button>
          <Button
            type={isSendInvoice ? "default" : "primary"}
            onClick={() => setIsSendInvoice(false)}
          >
            Charge Automatically
          </Button>
        </div>
      </div>
    );
  };

  const buildLeftPanel = () => {
    return (
      <div className="left-panel">
        {buildSelectServiceAreaCard()}
        {buildProductsCard()}
        {buildAddressCard()}
        {buildAppointmentDatesCard()}
        {buildBillingDetailCard()}
        {buildBillingTypeCard()}
      </div>
    );
  };

  const prepareOrderBody = (isPreview) => {
    const itemList = Object.values(addedProducts);
    const spaceItems = Object.values(addedItems);
    const billingType = isSendInvoice ? "send_invoice" : "charge_automatically";
    const { date1, date2, time1, time2, immediate } = appointmentDates;
    const isBulkyOnly = true;
    const couponCode = isCouponValid || isPreview ? coupon.trim() : null;
    const requiredFields = {
      addressID: selectedAddress.id,
      date1,
      date2,
      time1,
      time2,
      billingType,
      immediate,
      itemList,
      isBulkyOnly,
      spaceItems,
      customerSegment: selectedConsumerSegment,
      serviceArea: selectedServiceArea,
    };
    const optionalFields = {
      coupon: couponCode,
      notes,
    };
    return { requiredFields, optionalFields };
  };

  const onSubmit = (isPreview) => {
    const error = getError();
    if (!isEmpty(error)) return setError(error);
    const { requiredFields, optionalFields } = prepareOrderBody(isPreview);
    const body = Order.getSpacePlanOrderBody(requiredFields, optionalFields);

    if (isPreview) {
      return previewOrder(userID, body).then((res) => {
        if (res) setIsCouponValid(true);
      });
    }

    if (isSendInvoice) {
      return setModalVisible(true);
    }

    createOrder(userID, body).then((res) => {
      if (res) goToCustomerDetails();
    });
  };

  const buildSummary = () => {
    const { data, loading, error: orderError } = order;
    return (
      <Affix offsetTop={50}>
        <div className="summary">
          <SummaryCard
            productList={Object.values(addedProducts)}
            itemList={Object.values(addedItems)}
            coupon={coupon}
            onCouponChange={setCoupon}
            onApplyCoupon={() => {
              onSubmit(true);
            }}
            onDeleteCoupon={() => {
              setCoupon("");
              setIsCouponValid(false);
              resetOrder();
            }}
            notes={notes}
            onNotesChange={setNotes}
            error={error || orderError}
            loading={loading}
            previewOrder={data}
            onSubmit={() => onSubmit(false)}
            isCouponValid={isCouponValid}
          />
        </div>
      </Affix>
    );
  };

  const buildRightPanel = () => {
    return <div className="right-panel">{buildSummary()}</div>;
  };

  const buildModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onOk={() => {
          const { requiredFields, optionalFields } = prepareOrderBody(false);
          const body = Order.getSpacePlanOrderBody(
            requiredFields,
            optionalFields
          );
          setModalVisible(false);
          createOrder(userID, body).then((res) => {
            if (res) goToCustomerDetails();
          });
        }}
        onCancel={() => setModalVisible(false)}
      >
        <ErrorBox error={error} />
        <div>
          <br />
          Are you sure you want to proceed with <b>Send Invoice</b>?
        </div>
      </Modal>
    );
  };

  const goToCustomerDetails = () => props.history.push(`/customers/${userID}`);

  return (
    <div className="page-container">
      <BackButton onClick={goToCustomerDetails}>Customer Detail</BackButton>
      <h1>Create Space Plan Order</h1>
      <div className="order-create">
        {buildLeftPanel()}
        {buildRightPanel()}
        {buildModal()}
      </div>
    </div>
  );
};

SpacePlanOrderCreatePage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(SpacePlanOrderCreatePage);
