const STEPS = {
  selectLocation: {
    key: "selectLocation",
    sequence: "one",
    name: "Select Location",
  },
  addProducts: {
    key: "addProducts",
    sequence: "two",
    name: "Add Products",
  },
  shipmentDetails: {
    key: "shipmentDetails",
    sequence: "three",
    name: "Shipment Details",
  },
  packingDetails: {
    key: "packingDetails",
    sequence: "four",
    name: "Packing Details",
  },
  packingSlips: {
    key: "packingSlips",
    sequence: "five",
    name: "Labeling",
  },
  orderReview: {
    key: "orderReview",
    sequence: "six",
    name: "Order Review",
  },
};

const SELECT_LOCATION_ERROR_MESSAGE = "Please choose one of the options below.";
const SHIPMENT_DETAILS_BTNS_ERROR_MESSAGE =
  "Please select both shipment size and package.";
const SHIPMENT_DETAILS_DATE_ERROR_MESSAGE =
  "Please choose the date of shipment arrival.";
const ERROR_MESSAGES = {
  SELECT_LOCATION_ERROR_MESSAGE,
  SHIPMENT_DETAILS_BTNS_ERROR_MESSAGE,
  SHIPMENT_DETAILS_DATE_ERROR_MESSAGE,
};

export { STEPS, ERROR_MESSAGES };
