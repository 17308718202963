/**
 * @description Validates a potential email address string
 * @param {string} email An email address string
 * @returns {bool} Returns true if the email address is in a valid format, false if not
 */
export const validateEmail = (email) => {
  let pattern = new RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>() \]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return pattern.test(email);
};
