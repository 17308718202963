import { stringify } from "query-string";
import { getAPIFromDomain } from "../../lib/api";
import * as actionTypes from "./types/wro";

export function fetchWros(query) {
  const api = getAPIFromDomain("fulfillment");
  let url = `/warehouse_receiving_orders?`;

  if (query) url += stringify(query || {});

  const { WROS_REQUESTED, WROS_RECEIVED, WROS_ERRORED } = actionTypes;

  return (dispatch) => {
    dispatch({ type: WROS_REQUESTED });

    return api
      .get(url)
      .then((res) => dispatch({ type: WROS_RECEIVED, data: res.data }))
      .catch((error) =>
        dispatch({
          type: WROS_ERRORED,
          error,
        })
      );
  };
}

export const fetchWroDetails = (wroId) => (dispatch) => {
  const {
    WRO_DETAILS_REQUESTED,
    WRO_DETAILS_RECEIVED,
    WRO_DETAILS_ERRORED,
  } = actionTypes;

  const api = getAPIFromDomain("fulfillment");
  const url = `/warehouse_receiving_orders/${wroId}`;

  dispatch({ type: WRO_DETAILS_REQUESTED });

  return api
    .get(url)
    .then((res) => dispatch({ type: WRO_DETAILS_RECEIVED, data: res.data }))
    .catch((error) => dispatch({ type: WRO_DETAILS_ERRORED, error }));
};

export const updateWroDetails = (wroId, body) => (dispatch) => {
  const {
    WRO_UPDATE_REQUESTED,
    WRO_UPDATE_SUCCESS,
    WRO_UPDATE_ERRORED,
  } = actionTypes;

  const api = getAPIFromDomain("fulfillment");
  const url = `/warehouse_receiving_orders/${wroId}`;

  dispatch({ type: WRO_UPDATE_REQUESTED });

  return api
    .put(url, body)
    .then(() => dispatch({ type: WRO_UPDATE_SUCCESS }))
    .catch((error) => dispatch({ type: WRO_UPDATE_ERRORED, error }));
};

export const updateWroDetailsReset = () => (dispatch) =>
  dispatch({ type: actionTypes.WRO_UPDATE_RESET });

export function deleteWarehouseReceivingOrder(order_id) {
  const url = `/warehouse_receiving_orders/${order_id}`;
  const api = getAPIFromDomain("fulfillment");
  const {
    WRO_DELETE_REQUESTED,
    WRO_DELETE_SUCCESS,
    WRO_DELETE_ERRORED,
  } = actionTypes;

  return (dispatch) => {
    dispatch({ type: WRO_DELETE_REQUESTED });

    return api
      .delete(url)
      .then(() => dispatch({ type: WRO_DELETE_SUCCESS }))
      .catch(() =>
        dispatch({
          type: WRO_DELETE_ERRORED,
          error: "Error deleting order",
        })
      );
  };
}

export const getWROShippingLabel = (order_id, package_id) => {
  const url = `/warehouse_receiving_orders/${order_id}/incoming_shipments/${package_id}.pdf`;
  const api = getAPIFromDomain("fulfillment");
  const {
    WRO_LABEL_REQUESTED,
    WRO_LABEL_SUCCESS,
    WRO_LABEL_ERRORED,
  } = actionTypes;

  return (dispatch) => {
    dispatch({ type: WRO_LABEL_REQUESTED });

    return api
      .getPDF(url)
      .then((res) => {
        dispatch({ type: WRO_LABEL_SUCCESS });
        const newBlob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "ShippingLabel.pdf";
        link.dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      })
      .catch(() =>
        dispatch({
          type: WRO_LABEL_ERRORED,
          error:
            "Error getting a PDF label for the parcel with the given Warehouse Receiving Order",
        })
      );
  };
};

export const getWROShippingLabels = (order_id) => {
  const url = `/warehouse_receiving_orders/${order_id}/incoming_shipments.pdf`;
  const api = getAPIFromDomain("fulfillment");

  const {
    WRO_LABELS_REQUESTED,
    WRO_LABELS_SUCCESS,
    WRO_LABELS_ERRORED,
  } = actionTypes;

  return (dispatch) => {
    dispatch({ type: WRO_LABELS_REQUESTED });

    return api
      .getPDF(url)
      .then((res) => {
        dispatch({ type: WRO_LABELS_SUCCESS });
        const newBlob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "AllShippingLabels.pdf";
        link.dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      })
      .catch(() =>
        dispatch({
          type: WRO_LABELS_ERRORED,
          error:
            "Error getting a PDF label for the parcels with the given Warehouse Receiving Order",
        })
      );
  };
};
