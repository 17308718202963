import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const useWroIndex = () => {
  const [wroIndex, setWroIndex] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const fetchWroIndex = (query) => {
    const baseUrl = `/warehouse_receiving_orders`;
    const url = new UrlBuilder(baseUrl).applyQueryStringFromObject(query);

    setWroIndex({ ...wroIndex, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setWroIndex({
          data: res.data,
          loading: false,
          error: null,
        });
      })
      .catch((error) => setWroIndex({ loading: false, data: null, error }));
  };

  return { wroIndex, fetchWroIndex };
};

const useWroDetails = () => {
  const [wroDetails, setWroDetails] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const fetchWroDetails = (wroID) => {
    const url = `/warehouse_receiving_orders/${wroID}`;
    setWroDetails({ ...wroDetails, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setWroDetails({
          data: res.data,
          loading: false,
          error: null,
        });
        return res.data;
      })
      .catch((error) => setWroDetails({ loading: false, data: null, error }));
  };

  const updateWroDetails = (wroID, body) => {
    const url = `/warehouse_receiving_orders/${wroID}`;
    setWroDetails({ ...wroDetails, loading: true, error: null });
    return api
      .put(url, body)
      .then((res) => {
        setWroDetails({
          data: res.data,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setWroDetails({ loading: false, data: null, error });
        return false;
      });
  };

  const resetWroDetails = () => {
    setWroDetails({ data: null, loading: true, error: null });
  };

  return { wroDetails, fetchWroDetails, resetWroDetails, updateWroDetails };
};

export { useWroIndex, useWroDetails };
