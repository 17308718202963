import { getAPIFromDomain } from "../../lib/api";

// interface
export interface ItemCoveragePlan {
  id: string;
  name: string;
  price: number;
  description?: string;
}

export const fetchItemCoveragePlanIndex = async (): Promise<
  ItemCoveragePlan[]
> => {
  const api = getAPIFromDomain("storage");
  const url = "/coverage_plans/products";

  const response = await api.get(url);
  return response.data;
};

export const fetchUserCoveragePlanDetails = async (
  userID?: string
): Promise<any> => {
  const api = getAPIFromDomain("storage");
  const url = `/user/coverage_plan?user_id=${userID}`;

  const response = await api.get(url);
  return response?.data;
};

export const updateCoveragePlanSubscription = async (options: {
  userID: string;
  coveragePlanID: string;
}): Promise<any> => {
  const { userID, coveragePlanID } = options;
  const api = getAPIFromDomain("storage");
  const url = "/coverage_plans";
  const body = { user_id: userID, coverage_product_id: coveragePlanID };

  const response = await api.put(url, body);
  return response.data;
};

export const updateCoveragePlanPreference = async (options: {
  userID: string;
  coveragePlanID: string;
}): Promise<any> => {
  const { userID, coveragePlanID } = options;
  const api = getAPIFromDomain("storage");
  const url = "/coverage_plans/schedule";
  const body = {
    user_id: userID,
    coverage_product_id_user_choice: coveragePlanID,
  };

  const response = await api.post(url, body);
  return response.data;
};
