/**
 * Authorization HOC
 */
import Authorize from "./Authorize";
import { connect } from "react-redux";
import { withRouter } from "react-router";

/**
 * Containers
 * pages that are commented out have 'full' access by everyone
 * may restrict child components further but access to the actual page is unrestricted for now
 */
import InventoryItemDetailsPage from "../pages/storage/InventoryItemDetailsPage";
import CreateInvoice from "../containers/invoice/CreateInvoice";

/**
 * Authorization subrole groups
 */
import { SUPPORT_GROUP } from "../config/roleConfig";

const mapStateToProps = (state) => ({
  subrole: state.auth.user.subrole,
});

/**
 * Exported containers with auth
 */
const InventoryShowAuth = withRouter(
  connect(mapStateToProps)(Authorize(InventoryItemDetailsPage, SUPPORT_GROUP))
);
const CreateInvoiceAuth = withRouter(
  connect(mapStateToProps)(Authorize(CreateInvoice, SUPPORT_GROUP))
);

/**
 * Exported components with auth
 */
export { InventoryShowAuth, CreateInvoiceAuth };
