import React, { FC, ChangeEvent, useState } from "react";

// Components
import {
  CardWrapper,
  Header,
  HeaderSection,
  PalletNumber,
  StatusWrapper,
  TrackingNumberWrapper,
  Text,
  TableWrapper,
} from "../IncomingShipmentCard/IncomingShipmentCard.styles";
import {
  QuantityWrapper,
  ProductNameSearchWrapper,
} from "./NewIncomingShipmentCard.styles";
import { Button, Table, Input, InputNumber } from "antd";
import StatusTag from "../IncomingShipmentCard/StatusTag";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import SearchResults from "pages/fulfillment/FulfillmentProductCreatePage/BaseProducts/SearchResults";

// Hooks
import { useProducts } from "hooks/api/fulfillment/products";
import { usePackagingLevels } from "hooks/api/fulfillment/packagingLevels";

// Interfaces
import { Product, PackagingLevel } from "interfaces/products";
import { OrderStatus } from "interfaces/warehouseReceivingOrders";
import { NewShipment } from "../ShipmentCardList/ShipmentCardList";

// Libs
import { debounce } from "lodash-es";

const { Search } = Input;

interface Props {
  shipment: NewShipment;
  index: number;
  updateNewShipment: (key: string, value: any) => void;
  removeNewShipment: () => void;
  orderStatus: OrderStatus;
  organizationID: string;
  handleSelectProduct: (
    packagingLevel: PackagingLevel,
    product: Product
  ) => void;
  currentPackagingLevels: { [key: string]: string };
  isSingleTracking: boolean;
}

const NewIncomingShipmentCard: FC<Props> = ({
  shipment,
  index,
  updateNewShipment,
  removeNewShipment,
  organizationID,
  handleSelectProduct,
  currentPackagingLevels,
  isSingleTracking,
}) => {
  const { products, fetchProducts } = useProducts();
  const { packagingLevels, fetchPackagingLevels } = usePackagingLevels();
  const [showResults, setShowResults] = useState(true);
  const [searchResults, setSearchResults] = useState<PackagingLevel[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { parcel_type: parcelType } = shipment;

  const getFilteredPackagingLevels = (
    products: Product[],
    packagingLevels: PackagingLevel[]
  ) => {
    return packagingLevels.reduce((acc, packagingLevel) => {
      const product = products.find(
        (product) => product.id === packagingLevel.product_id
      );

      if (
        product &&
        (parcelType === "pallet" ||
          (parcelType === "box" && packagingLevel.level !== "pallet"))
      ) {
        // Product exists, all packaging levels for pallet shipments, and all but pallets for parcel shipments
        acc.push({ ...packagingLevel, scid: product?.scid });
        return acc;
      }

      return acc;
    }, [] as any[]);
  };

  const searchForProducts = debounce(async (search: string) => {
    const response = await Promise.all([
      fetchPackagingLevels({
        q: search.trim(),
        organization_id: organizationID,
      }),
      fetchProducts({
        q: search.trim(),
        product_type: "base",
        organization_id: organizationID,
      }),
    ]);
    const packagingLevels = response[0];
    const products = response[1];

    if (products && packagingLevels) {
      const searchResults = getFilteredPackagingLevels(
        products,
        packagingLevels
      );
      setSearchResults(searchResults);
    }
  }, 500);

  const handleOnSelect = (packagingLevelFromSearch: PackagingLevel) => {
    if (packagingLevels.data && products.data) {
      const packagingLevel = packagingLevels.data.find(
        (i) => i.name === packagingLevelFromSearch.name
      ) as PackagingLevel;
      const product = products.data.find((product) => {
        return product.id === packagingLevel.product_id;
      }) as Product;
      handleSelectProduct(packagingLevel, product);
      setSearchValue(packagingLevel.name || "");
      setShowResults(false);
    }
  };

  const setShowResultsValue = (value: boolean) => {
    setShowResults(value);
  };

  const buildColumns = () => {
    const renderQuantity = () => (
      <QuantityWrapper>
        <InputNumber
          style={{ width: "auto" }}
          onChange={(quantity) => {
            updateNewShipment("quantity", Number(quantity));
          }}
          min={1}
          max={
            parcelType === "pallet" && shipment.name.includes("Pallet")
              ? 1
              : undefined
          }
        />
      </QuantityWrapper>
    );

    const renderName = (_: string) => (
      <ProductNameSearchWrapper id="sub-component-search">
        <Search
          placeholder="Search for Products"
          onSearch={(value: string) => searchForProducts(value)}
          onChange={(e) => {
            searchForProducts(e.target.value);
            setSearchValue(e.target.value);
          }}
          loading={products.loading || packagingLevels.loading}
          value={searchValue}
          id={`${index}`}
        />
        <SearchResults
          searchResults={searchResults}
          showResults={showResults}
          setShowResultsValue={(value) => setShowResultsValue(value)}
          handleOnSelect={handleOnSelect}
          inputID={`${index}`}
          width={
            document.getElementById("sub-component-search")
              ? document.getElementById("sub-component-search")?.offsetWidth
              : 560
          }
          maxHeight={600}
          reverse
          bottom={56}
        />
      </ProductNameSearchWrapper>
    );
    const renderSku = (text: string) => (text ? text : "-");
    const renderScid = (text: string) => (text ? text : "-");

    return [
      {
        title: "Qty",
        render: renderQuantity,
        width: "10%",
      },
      {
        title: "Product Name",
        key: "name",
        render: renderName,
        width: "50%",
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        render: renderSku,
        width: "20%",
      },
      {
        title: "SCID",
        dataIndex: "scid",
        key: "scid",
        render: renderScid,
        width: "20%",
      },
    ];
  };

  const renderButton = () => (
    <Button
      danger
      type="primary"
      onClick={() => removeNewShipment()}
      icon={<DeleteOutlined />}
    >
      Delete {parcelType === "pallet" ? "Pallet" : "Box"}
    </Button>
  );

  return (
    <CardWrapper className="new-shipment">
      <Header>
        <HeaderSection>
          <PalletNumber>
            {parcelType === "pallet" ? "Pallet" : "Box"} #{index + 1}
          </PalletNumber>
          <StatusWrapper>
            <StatusTag status="awaiting" />
          </StatusWrapper>
          {isSingleTracking ? null : (
            <TrackingNumberWrapper>
              <Text minWidth={62}>Tracking # </Text>
              <Input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateNewShipment("tracking_info", e.target.value);
                }}
                value={shipment.tracking_info}
              />
            </TrackingNumberWrapper>
          )}
        </HeaderSection>
        <HeaderSection>
          {renderButton()}
          <Button disabled icon={<DownloadOutlined />}>
            Label
          </Button>
        </HeaderSection>
      </Header>
      <TableWrapper>
        <Table
          dataSource={[shipment]}
          rowKey="scid"
          columns={buildColumns()}
          pagination={false}
          bordered
        />
      </TableWrapper>
    </CardWrapper>
  );
};

export default NewIncomingShipmentCard;
