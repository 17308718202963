export default class UrlBuilder {
  constructor(baseUrl) {
    this.url = baseUrl;
  }

  applyQueryStringFromObject = (option) => {
    if (!option || Object.keys(option).length === 0) return this.url;

    // const buildArrayQueryString = (key, valueArray) => {
    //   const optionsArray = valueArray.map(value => `${key}[]=${value}`);
    //   return optionsArray.join("&");
    // };

    const keyEqualsValue = Object.keys(option).map((key) => {
      const value = option[key];
      // if (Array.isArray(value)) {
      //   return buildArrayQueryString(key, value);
      // } else return `${key}=${value}`;
      return `${key}=${value}`;
    });

    const urlHasQuery = this.url.includes("?");
    const firstCharacter = urlHasQuery ? "&" : "?";

    this.url += `${firstCharacter}${keyEqualsValue.join("&")}`;

    return this.url;
  };

  applyServiceArea = (service_area) => {
    if (!service_area) return this.url;
    return this.applyQueryStringFromObject({ service_area });
  };

  applyFacility = (facility) => {
    if (!facility) return this.url;
    return this.applyQueryStringFromObject({ facility });
  };

  applyCustomerSegment = (customer_segment) => {
    if (!customer_segment) return this.url;
    return this.applyQueryStringFromObject({ customer_segment });
  };
}
