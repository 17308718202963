import { useState } from "react";
import { getAPIFromDomain, getPDFRequestConfig } from "../../../lib/api";

export const useASNLabelDownloadURL = () => {
  const [data, setData] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("fulfillment");

  const fetchASNLabelDownloadURL = (asnID: string) => {
    setLoading(true);
    setError(null);
    const url = `/asns/${asnID}/label.pdf`;

    return api
      .get(url, getPDFRequestConfig())
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const downloadLink = window.URL.createObjectURL(blob);
        setData(downloadLink);
        setLoading(false);
        return downloadLink;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return error;
      });
  };

  const ASNLabelDownloadURL = { data, loading, error };

  return { fetchASNLabelDownloadURL, ASNLabelDownloadURL };
};
