import styled from "styled-components";
import colors from "css/colors";

interface QuantityProps {
  color?: string;
}

export const Quantity = styled.div<QuantityProps>`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : colors.CHARCOAL)};
`;
