import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowLeftOutlined } from "@ant-design/icons";

const FulfillmentBackButton = ({
  urlPath = "/",
  label = "Go Back",
  onClick,
}) => {
  if (onClick) {
    return (
      <div onClick={onClick} className="inl-flex-row back-button">
        <ArrowLeftOutlined />
        <p>{label}</p>
      </div>
    );
  }

  return (
    <Link to={urlPath} className="inl-flex-row back-button">
      <ArrowLeftOutlined />
      <p>{label}</p>
    </Link>
  );
};

FulfillmentBackButton.propTypes = {
  urlPath: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default FulfillmentBackButton;
