import {
  updateServiceLevel,
  UpdateServiceLevelMutation,
} from "api/logistics/appointment";
import { useMutation } from "react-query-legacy";

const useAppointmentUpdateServiceLevel = () => {
  return useMutation((data: UpdateServiceLevelMutation) =>
    updateServiceLevel(data)
  );
};

export { useAppointmentUpdateServiceLevel };
