import React from "react";
import moment from "moment";

// libs
import Shipment from "../../../../../../../lib/fulfillment/shipment";
import { ServiceLevel } from "@secondcloset/fulfillment-utils";

// components
import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";

// styles
import {
  Container,
  Title,
  OrderIDWrap,
  IconWrap,
  SuccessMsg,
  ButtonWrap,
  AppointmentDetailsWrap,
  ChunkWrap,
  LeftChunk,
  AppointmentAddressWrap,
  Footer,
} from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentI = Fulfillment.Shipment;
type Order = Fulfillment.Order;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;
type ServiceLevelInterface = ServiceLevel.ServiceLevelInterface;

interface Props {
  shipment: ShipmentI;
  order: Order;
  onDoneClick: () => any;
}

const ConfirmAppointmentView: React.FC<Props> = ({
  shipment,
  order,
  onDoneClick,
}) => {
  const shippingMethod = shipment?.shipping_method as SecondClosetShippingMethod;
  const isReturn = shippingMethod?.job_type === "reverse_logistics";
  const orderNumber = order?.external_order_number;
  const serviceLevel = shippingMethod.delivery_service_level;

  const serviceLevelLabel =
    ServiceLevel.getServiceLevelList().find(
      (sl: ServiceLevelInterface) => sl.key === serviceLevel
    )?.label || "-";

  const buildAppointmentDetails = () => {
    const date = shippingMethod?.date || "-";
    const time = shippingMethod?.formatted_timeslot || "-";
    return (
      <AppointmentDetailsWrap>
        <LeftChunk>
          <div>Delivery Date:</div>
          <div>Time Slot:</div>
          <div>Service Level:</div>
        </LeftChunk>

        <ChunkWrap>
          <div>{moment(date, "YYYY-MM-DD").format("LL")}</div>
          <div>{time}</div>
          <div>{serviceLevelLabel}</div>
        </ChunkWrap>
      </AppointmentDetailsWrap>
    );
  };

  const buildAppointmentAddress = () => {
    const location = shippingMethod?.location;
    const city = location?.city || "-";
    const province = location?.province || "-";
    const address = location?.address || "-";
    const postal_code = location?.postal_code || "-";

    return (
      <AppointmentAddressWrap>
        <div className="line1">{address}</div>
        <div className="line2">{`${city}, ${province}, ${postal_code}`}</div>
      </AppointmentAddressWrap>
    );
  };

  const buildFooter = () => {
    return (
      <Footer>
        We will send a confirmation email to your customer shortly.
      </Footer>
    );
  };

  return (
    <Container>
      <Title>Schedule {isReturn ? "Return" : "Delivery"} Appointment</Title>
      <OrderIDWrap>ORDER NUMBER: {orderNumber}</OrderIDWrap>
      <IconWrap>
        <CheckOutlined className="check-icon" />
      </IconWrap>
      <SuccessMsg>
        We have <span>successfully</span> booked your{" "}
        {isReturn ? "return" : "delivery"} appointment!
      </SuccessMsg>
      {buildAppointmentDetails()}
      {buildAppointmentAddress()}
      {buildFooter()}
      <ButtonWrap>
        <Button onClick={onDoneClick} size="large" type="primary">
          Done
        </Button>
      </ButtonWrap>
    </Container>
  );
};

export default ConfirmAppointmentView;
