import React from "react";
import PropTypes from "prop-types";
import { Select, Alert, Spin } from "antd";
import { useServiceArea } from "../../../../hooks/api/common/useServiceArea";
import { useCustomerSegment } from "../../../../hooks/api/common/customerSegment";
import { uniq } from "lodash-es";

const SelectServiceAreaCard = (props) => {
  const { serviceArea } = useServiceArea();
  const { customerSegment } = useCustomerSegment();

  const getServiceAreaList = () => {
    if (!serviceArea.data) return [];
    return uniq(serviceArea.data.map((sa) => sa.name));
  };

  const getCustomerSegmentList = () => {
    if (!customerSegment.data) return [];
    return uniq(customerSegment.data.map((cs) => cs.name));
  };

  const getServiceAreaFullName = (serviceArea) => {
    switch (serviceArea.toLowerCase()) {
      case "yyz":
        return "Toronto (YYZ)";
      case "yvr":
        return "Vancouver (YVR)";
      case "yow":
        return "Ottawa (YOW)";
      case "yul":
        return "Montreal (YUL)";
      default:
        return null;
    }
  };

  const buildRows = () => {
    return (
      <>
        <div className="card-row">
          <div className="row-title">Service Area:</div>
          <Select
            value={props.selectedServiceArea || "-----------------"}
            onChange={(v) => props.setSelectedServiceArea(v)}
            style={{ width: 150 }}
          >
            {getServiceAreaList().map((s) => (
              <Select.Option key={s} value={s}>
                {getServiceAreaFullName(s)}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="card-row">
          <div className="row-title">Customer Segment:</div>
          <Select
            value={props.selectedConsumerSegment || "------------"}
            onChange={(v) => props.setSelectedConsumerSegment(v)}
            style={{ width: 120, textTransform: "capitalize" }}
            dropdownStyle={{ textTransform: "capitalize" }}
          >
            {getCustomerSegmentList().map((c) => (
              <Select.Option key={c} value={c}>
                {c}
              </Select.Option>
            ))}
          </Select>
        </div>
      </>
    );
  };

  if (serviceArea.loading || customerSegment.loading)
    return (
      <div className="service-area-card">
        <div className="card-spin">
          <Spin tip="Fetching service area and customer segment..." />
        </div>
      </div>
    );

  return (
    <div className="service-area-card">
      <div className="title">{"Service Area & Customer Segment"}</div>
      {serviceArea.error || customerSegment.error ? (
        <Alert
          message={serviceArea.error || customerSegment.error}
          type="error"
        />
      ) : (
        buildRows()
      )}
    </div>
  );
};

SelectServiceAreaCard.propTypes = {
  selectedServiceArea: PropTypes.string,
  selectedConsumerSegment: PropTypes.string,
  setSelectedServiceArea: PropTypes.func.isRequired,
  setSelectedConsumerSegment: PropTypes.func.isRequired,
};

export default SelectServiceAreaCard;
