import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";
import Products from "../../../../lib/regular/products";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Button, Spin } from "antd";
import ErrorBox from "../../../fulfillment/_common/ErrorBox";

const SummaryCard = (props) => {
  const buildItemRows = () => {
    if (isEmpty(props.productList) && isEmpty(props.itemList)) {
      return (
        <tr>
          <td colSpan="3" className="no-data">
            <p>There are no items in cart</p>
          </td>
        </tr>
      );
    }

    const products = props.productList.map((item) => {
      let unitPrice = Products.getUnitPrice(item);
      unitPrice = (unitPrice / 100).toFixed(2);
      const { name, quantity } = item;
      const price = (unitPrice * quantity).toFixed(2);
      return (
        <tr key={item.id}>
          <td className="name">
            <div>{name}</div>
            <div>Unit Price: ${unitPrice}</div>
          </td>
          <td className="quantity">{quantity}</td>
          <td className="price">${price}</td>
        </tr>
      );
    });

    const items =
      props.itemList &&
      props.itemList.map((item) => {
        let unitPrice = 0;
        unitPrice = (unitPrice / 100).toFixed(2);
        const { name, quantity } = item;
        const price = (unitPrice * quantity).toFixed(2);
        return (
          <tr key={item.id}>
            <td className="name">
              <div>{name}</div>
              <div>Unit Price: ${unitPrice}</div>
            </td>
            <td className="quantity">{quantity}</td>
            <td className="price">${price}</td>
          </tr>
        );
      });

    return (
      <>
        {products}
        {items}
      </>
    );
  };

  const buildTable = () => {
    return (
      <div className="summary-table">
        <table cellPadding="5">
          <tbody>
            <tr>
              <th>Item type</th>
              <th>Qty</th>
              <th>Price</th>
            </tr>
            {buildItemRows()}
          </tbody>
        </table>
      </div>
    );
  };

  const getSubTotal = () => {
    const subtotal = props.productList.reduce(
      (acc, cv) => acc + Products.getUnitPrice(cv) * cv.quantity,
      0
    );
    return (subtotal / 100).toFixed(2);
  };

  const buildCouponInput = () => {
    const { onApplyCoupon, coupon, onDeleteCoupon, onCouponChange } = props;
    const { previewOrder, isCouponValid } = props;

    if (!isCouponValid) {
      return (
        <div className="coupon">
          <Input
            value={coupon}
            onChange={(e) => onCouponChange(e.target.value)}
            placeholder="use coupon"
          />
          <Button
            onClick={onApplyCoupon}
            disabled={!coupon.trim()}
            style={{ marginLeft: "5px" }}
          >
            Apply
          </Button>
        </div>
      );
    }

    const { amount_off, percent_off } = previewOrder.coupon;
    const couponValue = amount_off
      ? `- $${(amount_off / 100).toFixed(2)}`
      : `- ${percent_off}%`;

    return (
      <div className="coupon">
        <div className="coupon-control">
          <span>
            <b>Coupon:</b> {coupon}
          </span>
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            onClick={onDeleteCoupon}
            size="small"
          />
        </div>
        <div className="coupon-value">{couponValue}</div>
      </div>
    );
  };

  const buildPrices = () => {
    const { previewOrder } = props;
    let amount_off, percent_off;
    if (previewOrder && previewOrder.coupon) {
      amount_off = previewOrder.coupon.amount_off;
      percent_off = previewOrder.coupon.percent_off;
    }
    const priceBreakDown = Products.getPriceBreakdown(
      props.productList,
      amount_off,
      percent_off
    );

    const { subtotal, monthlyRecurring, coupon } = priceBreakDown;
    return (
      <div className="price-breakdown">
        <div className="coupon">
          <span>Coupon:</span>
          <span>${coupon}</span>
        </div>
        <div className="subtotal">
          <span>Subtotal:</span>
          <span>${subtotal}</span>
        </div>
        <div className="total">
          <span>Total:</span>
          <span>${subtotal} + Taxes</span>
        </div>
        <hr />
        <div className="monthly-recurring">
          <span>Monthly Recurring:</span>
          <span>${monthlyRecurring} + Taxes</span>
        </div>
        <hr />
      </div>
    );
  };

  const buildNotesInput = () => {
    const { TextArea } = Input;
    const { onNotesChange, notes } = props;

    return (
      <div className="notes-input">
        <TextArea
          value={notes}
          onChange={(e) => onNotesChange(e.target.value)}
          placeholder="notes"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </div>
    );
  };

  const buildSubmitButton = () => {
    return (
      <div className="submit-btn">
        <Button type="primary" onClick={props.onSubmit}>
          Submit Order
        </Button>
      </div>
    );
  };

  return (
    <div className="summary-card">
      <div className="title">Order summary</div>
      {buildTable()}
      {props.loading ? (
        <div className="card-loading">
          <Spin tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="subtotal">Subtotal: ${getSubTotal()}</div>
          <ErrorBox error={props.error} />
          {buildCouponInput()}
          {buildPrices()}
          {buildNotesInput()}
          {buildSubmitButton()}
        </>
      )}
    </div>
  );
};

SummaryCard.propTypes = {
  productList: PropTypes.array,
  coupon: PropTypes.string.isRequired,
  onCouponChange: PropTypes.func.isRequired,
  onApplyCoupon: PropTypes.func.isRequired,
  onDeleteCoupon: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  previewOrder: PropTypes.object,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isCouponValid: PropTypes.bool.isRequired,
  itemList: PropTypes.array,
};

SummaryCard.defaultProps = {
  productList: [],
};

export default SummaryCard;
