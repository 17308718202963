import React, { useEffect } from "react";
import { PageNotFound as Page404 } from "@secondcloset/web-components";
import styled from "styled-components";

interface PageNotFoundProps {
  setShowHeaderAndNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ setShowHeaderAndNav }) => {
  useEffect(() => {
    setShowHeaderAndNav(false);
    return () => {
      setShowHeaderAndNav(true);
    };
  }, [setShowHeaderAndNav]);

  return (
    <StyledContainer>
      <Page404 />
    </StyledContainer>
  );
};

// to negate app container padding
const StyledContainer = styled.div`
  margin: -95px auto;
`;
export default PageNotFound;
