import React, { useState } from "react";
import OrganizationLib from "../../../../../lib/organization";
import { Button, Modal, Select, Descriptions } from "antd";
import { HighlightOutlined } from "@ant-design/icons";
import {
  getVessiShippingServiceOptions,
  ShippingServiceOption,
} from "./helpers";
import { updateOrder } from "../../../../../api/fulfillment/order";
import * as S from "./styles";
import { useMutation } from "react-query";
import { Fulfillment } from "@secondcloset/types";
import { ErrorAlert } from "@secondcloset/web-components";
type Order = Fulfillment.Order;

interface Props {
  order?: Order;
  onUpdateShippingServiceSuccess: () => void;
}
const UpdateShippingServiceButton: React.FC<Props> = ({
  order,
  onUpdateShippingServiceSuccess,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState("");
  const [
    selectedShippingServiceLabel,
    setSelectedShippingServiceLabel,
  ] = useState<ShippingServiceOption["label"]>();
  const organization = order?.organization;
  const { mutate: onUpdateOrder, isLoading } = useMutation(
    () => {
      return updateOrder({
        orderID: order?.id || "",
        body: {
          orders: {
            shipping_service:
              selectedShippingServiceLabel === "empty"
                ? ""
                : selectedShippingServiceLabel,
          },
        },
      });
    },
    {
      onSuccess: () => {
        setModalVisible(false);
        setSelectedShippingServiceLabel(undefined);
        onUpdateShippingServiceSuccess();
      },
      onError: (e: string) => {
        setError(e);
      },
    }
  );

  if (!order || !OrganizationLib.isVessi(organization)) return null;

  const renderShippingServiceSelect = () => {
    const options = getVessiShippingServiceOptions();
    return (
      <Select
        value={selectedShippingServiceLabel}
        style={{ width: 250 }}
        placeholder="Select a shipping service"
        onSelect={(v) => {
          setSelectedShippingServiceLabel(v);
          setError("");
        }}
      >
        {options.map((o) => {
          return (
            <Select.Option key={o.label} value={o.label}>
              {o.label}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const renderSelectedShippingServiceDetails = () => {
    const options = getVessiShippingServiceOptions();
    const selectedShippingService = options.find(
      (o) => o.label === selectedShippingServiceLabel
    );
    if (!selectedShippingServiceLabel) return null;

    return (
      <S.SelectedShippingServiceWrap>
        <Descriptions bordered size="small" layout="vertical">
          <Descriptions.Item label="Shipping Service">
            {selectedShippingService?.label}
          </Descriptions.Item>
          <Descriptions.Item label="Account">
            {selectedShippingService?.account}
          </Descriptions.Item>
        </Descriptions>
      </S.SelectedShippingServiceWrap>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setSelectedShippingServiceLabel(undefined);
        }}
        okButtonProps={{
          loading: isLoading,
          onClick: () => onUpdateOrder(),
        }}
      >
        <ErrorAlert error={error} />
        {renderShippingServiceSelect()}
        {renderSelectedShippingServiceDetails()}
      </Modal>
    );
  };
  return (
    <>
      <Button
        icon={<HighlightOutlined />}
        onClick={() => setModalVisible(true)}
      >
        Edit Shipping Service
      </Button>
      {renderModal()}
    </>
  );
};

export default UpdateShippingServiceButton;
