const BASE_URL =
  process.env.REACT_APP_ENV === "staging"
    ? process.env.REACT_APP_STAGING_API
    : process.env.REACT_APP_PROD_API;

export const BFAPI_URL = process.env.REACT_APP_BFAPI || "";

export const NOVEMBER_URL = process.env.REACT_APP_WAREHOUSE_API || "";

export default BASE_URL;
