import * as actionTypes from "actions/fulfillment/types";

const INITIAL_STATE = {
  locationCode: null,
};

export default function (state = INITIAL_STATE, action) {
  const { WRO_SELECT_LOCATION, WRO_CREATE_RESET } = actionTypes;

  switch (action.type) {
    case WRO_SELECT_LOCATION:
      return { ...state, locationCode: action.locationCode };

    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
