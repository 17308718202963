import React, { useEffect, useState } from "react";

// hooks
import useShipmentCreateFlow from "../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import { useParams, useHistory, useLocation } from "react-router";
import { useOrderDetails } from "../../../hooks/api/fulfillment/orders";
import { useCreateShipment } from "../../../hooks/api/fulfillment/shipment";

// components
import PageLoader from "../../../components/Loader/Loader";
import BackButton from "../FulfillmentBackButton";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Collapse, Modal, notification } from "antd";

// lib
import ShipmentCreate from "../../../lib/fulfillment/shipmentCreate";
import { isEmpty, get } from "lodash-es";
import queryString from "query-string";

// steps
import SelectItemsStep from "./SelectItemsStep";
import SelectReturnReason from "./SelectReturnReason";
import SelectShipmentCarrierStep from "./SelectShipmentCarrierStep";
import ShipmentOverviewStep from "./ShipmentOverviewStep";
import PackageDetailsStep from "./PackageDetailsStep";

// types
import { SelectShipmentCarrierTypes } from "@secondcloset/web-components";

// enums
const ServiceCategory = SelectShipmentCarrierTypes.ServiceCategory;

const ShipmentCreatePage = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { shipment, createShipment } = useCreateShipment();
  const {
    activeStepKey,
    dispatchSetActiveStepKey,
    dispatchSetOrder,
    dispatchSetIsReturn,
    dispatchResetShipmentCreateFlow,
    dispatchSetIsReturnWarningVisible,
    selectedOrderItemIDs,
    order,
    isReturnWithOriginalPackage,
    packageDetails,
    shipmentCarrier,
    shipmentDate,
    shipmentTime,
    isSendEmail,
    buildSteps,
    isReturn,
    returnReasons,
    isFullRefund,
    isExchange,
    isUnopenedCancellation,
    serviceCategory,
    partner,
    isEndy,
    notes,
  } = useShipmentCreateFlow();
  const { orderDetails, fetchOrderDetails } = useOrderDetails();
  const [isOtherCarrierSelected, setIsOtherCarrierSelected] = useState(false);
  const steps = buildSteps(isReturn);
  const isEndyReturnFlow = isEndy && isReturn;

  const orderID = params.orderID;

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const isReturnShipmentFlow = get(parsed, "isReturn", false);
    if (!isReturn && isReturnShipmentFlow) {
      dispatchSetIsReturn(true);
    }
    fetchOrderDetails(orderID);
    return () => dispatchResetShipmentCreateFlow();
  }, []);

  useEffect(() => {
    if (orderDetails.data) {
      dispatchSetOrder(orderDetails.data);
      dispatchSetActiveStepKey(steps.selectItem.key);
    }
  }, [orderDetails.data]);

  useEffect(() => {
    const error = orderDetails.error || shipment.error;
    if (error) {
      notification.error({
        message: "Error",
        description: error,
      });
    }
  }, [orderDetails.error, shipment.error]);

  if (!orderDetails.data && orderDetails.loading)
    return <PageLoader text="loading order" />;

  const renderHeader = () => {
    return (
      <div className="header">
        <BackButton onClick={showDiscardModal}>Order Details</BackButton>
        <div className="title">
          Create {isReturn ? "Return" : "Delivery"} Shipment
        </div>
        <div className="header-button" onClick={showDiscardModal}>
          Exit
        </div>
      </div>
    );
  };

  const renderStepHeader = (step) => {
    const { title, seq, key } = step;
    const completed = isStepCompleted(key);
    const icon = completed ? (
      <div className="complete-icon">
        <CheckOutlined />
      </div>
    ) : (
      <div className="step-number">Step {seq}</div>
    );

    return (
      <div className="step-header">
        {icon}
        <div className="step-title">{title}</div>
      </div>
    );
  };

  const getNextStep = (stepKey) => {
    const step = steps[stepKey];
    const sequence = step.seq;
    const nextSeq = sequence + 1;
    return Object.values(steps).find((step) => step.seq === nextSeq);
  };

  const handle3rdParty = () => {
    // create empty shipment
    // redirect to providence app with shipment id
    const items = selectedOrderItemIDs.map((id) => {
      return { id };
    });
    const isFreight = serviceCategory === ServiceCategory.freight;
    const body = {
      order_id: order.id,
      job_type: "delivery",
      order_items: items,
      rate: {},
      freight: isFreight,
    };
    createShipment(body)
      .then(({ id }) => {
        if (!id) return null;
        window.open(
          process.env.REACT_APP_PROVIDENCE_WEB + `packing-scan-shipment/${id}`,
          "_blank"
        );
      })
      .finally(() => goBackToOrderDetailsPage());
  };

  const goToNextStep = (stepKey) => {
    const nextStep = getNextStep(stepKey);

    const proceed = () => {
      if (nextStep) dispatchSetActiveStepKey(nextStep.key);
    };

    switch (stepKey) {
      case steps.selectItem.key: {
        if (isEndyReturnFlow && !isExchange && !isFullRefund)
          dispatchSetIsReturnWarningVisible(true);
        else proceed();
        break;
      }
      case steps.selectShipmentCarrier.key: {
        if (
          !isReturn &&
          (serviceCategory === ServiceCategory.third_party ||
            serviceCategory === ServiceCategory.freight)
        )
          handle3rdParty();
        else proceed();
        break;
      }
      default:
        proceed();
    }
  };

  const isStepCompleted = (stepKey) => {
    if (
      isEndyReturnFlow &&
      stepKey === steps.selectReturnReason.key &&
      !isEmpty(returnReasons) &&
      returnReasons.every((returnReason) => !!returnReason.reason)
    ) {
      return true;
    }

    const isDimensionsInvalid =
      !packageDetails.length ||
      packageDetails.some(
        (p) =>
          !p.weight_value ||
          !p.length_value ||
          !p.height_value ||
          !p.width_value
      );

    switch (stepKey) {
      case steps?.selectItem?.key:
        const hasSelectedOrderItem = !isEmpty(selectedOrderItemIDs);
        return isEndyReturnFlow
          ? (isFullRefund || isExchange) && hasSelectedOrderItem
          : hasSelectedOrderItem;
      case steps.packageDetails?.key: {
        if (
          isEndyReturnFlow ||
          isReturnWithOriginalPackage ||
          (!isReturnWithOriginalPackage && !isDimensionsInvalid)
        )
          return true;
        return false;
      }
      case steps.selectShipmentCarrier.key: {
        const isSecondCloset =
          shipmentCarrier?.carrierCode === ServiceCategory.second_closet;
        const isFreight = serviceCategory === ServiceCategory.freight;
        const isParcel = serviceCategory === ServiceCategory.third_party;
        if (partner) return true;
        if (isFreight)
          return isReturn
            ? !!shipmentCarrier || isOtherCarrierSelected
            : !shipment.loading;
        if (isSecondCloset) {
          const hasDateTime = !!shipmentDate && !!shipmentTime;
          return isSendEmail || hasDateTime;
        }
        if (isReturn) return !!shipmentCarrier;
        return isParcel;
      }
      default:
        return false;
    }
  };

  const isStepDisable = (stepKey) => {
    const currentSeq = steps[stepKey].seq;
    if (currentSeq === 1) return false;
    const prevSeq = currentSeq - 1;
    const prevStep = Object.values(steps).find((step) => step.seq === prevSeq);
    return !isStepCompleted(prevStep.key);
  };

  const renderStepContent = (stepKey) => {
    if (stepKey === steps.selectItem.key) return <SelectItemsStep />;
    if (
      isEndyReturnFlow &&
      steps.selectReturnReason &&
      stepKey === steps.selectReturnReason.key
    )
      return <SelectReturnReason />;
    if (stepKey === steps.packageDetails?.key) return <PackageDetailsStep />;
    if (stepKey === steps.selectShipmentCarrier.key)
      return (
        <SelectShipmentCarrierStep
          isCreatingDeliveryShipment={shipment.loading}
          createDeliveryShipmentError={shipment.error_message}
          isOtherCarrierSelected={isOtherCarrierSelected}
          onOtherCarrierSelected={setIsOtherCarrierSelected}
        />
      );
    if (stepKey === steps.shipmentOverview.key) return <ShipmentOverviewStep />;
  };

  const renderNextButton = (stepKey) => {
    const disabled = !isStepCompleted(stepKey);
    const className = `step-next-button ${disabled && "disabled"}`;
    const onClick = disabled ? () => {} : () => goToNextStep(stepKey);
    return (
      <div className="step-next-button-wrap">
        <div className={className} onClick={onClick}>
          Next
        </div>
      </div>
    );
  };

  const isAllStepCompleted = () => {
    return Object.keys(steps).every((key) =>
      key === steps.shipmentOverview.key ? true : isStepCompleted(key)
    );
  };

  const onCreateShipment = () => {
    const orderItems = ShipmentCreate.constructOrderItems(
      selectedOrderItemIDs,
      {
        isFullRefund,
        isExchange,
        isUnopenedCancellation,
        isReturn,
        returnReasons,
      }
    );

    const isFreight = serviceCategory === ServiceCategory.freight;
    const isSecondCloset = serviceCategory === "second_closet";
    const isPickupPartner = !isSecondCloset && !!partner;

    const fields = {
      serviceCode: get(shipmentCarrier, "serviceCode"),
      rate: get(shipmentCarrier, "rate"),
      orderID: get(order, "id"),
      date: shipmentDate,
      time: shipmentTime,
      orderItems,
      partnerID: get(partner, "partnerID"),
      notes,
      useOriginalPackage: isReturnWithOriginalPackage,
      packageDetails:
        !isReturnWithOriginalPackage && !isSecondCloset ? packageDetails : null,
    };

    if (isFreight) {
      // for freight we always send packages details
      if (isReturnWithOriginalPackage) fields.packageDetails = packageDetails;
      else {
        // when returning freight alternative package equally distribute order items to packages
        const itemsToMove = Math.floor(
          selectedOrderItemIDs.length / packageDetails.length
        );
        fields.packageDetails = packageDetails.map((p, i) => ({
          ...p,
          order_item_ids:
            packageDetails.length !== i + 1
              ? selectedOrderItemIDs.splice(0, itemsToMove)
              : selectedOrderItemIDs,
        }));
      }
    }

    const body = ShipmentCreate.getCreateShipmentBody(fields, {
      isReturn,
      isPickupPartner,
      isSecondCloset,
      isFreight,
    });

    createShipment(body).then((success) => {
      if (success) {
        notification.success({
          message: "Success",
          description: "Shipment Created!",
        });
        goBackToOrderDetailsPage();
      }
    });
  };

  const renderCreateShipmentButton = () => {
    if (shipment.loading) {
      return (
        <div className="loading-button">
          <LoadingOutlined />
        </div>
      );
    }
    const disabled = !isAllStepCompleted();
    const className = `step-next-button ${disabled && "disabled"}`;
    const onClick = disabled ? () => {} : onCreateShipment;
    return (
      <div className="step-next-button-wrap">
        <div className={className} onClick={onClick}>
          Create Shipment
        </div>
      </div>
    );
  };

  const renderAllSteps = () => {
    return Object.values(steps)
      .sort((s1, s2) => s1.seq - s2.seq)
      .map((step) => {
        const { key } = step;
        const stepHeader = renderStepHeader(step);
        const isActive = step.key === activeStepKey;
        const isFinalStep = step.key === steps.shipmentOverview.key;
        return (
          <Collapse.Panel
            header={stepHeader}
            showArrow={false}
            className={`collapse-panel-wrap ${isActive && "active"}`}
            disabled={isStepDisable(step.key)}
            key={key}
          >
            {renderStepContent(key)}
            {isFinalStep ? renderCreateShipmentButton() : renderNextButton(key)}
          </Collapse.Panel>
        );
      });
  };

  const goBackToOrderDetailsPage = () => {
    history.push(`/fulfillment/orders/${orderID}`);
  };

  const showDiscardModal = () => {
    Modal.confirm({
      title: "Are you sure you want to discard the shipment?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        goBackToOrderDetailsPage();
      },
    });
  };

  if (!orderID || orderID === "undefined") return null;

  return (
    <div id="shipment-create-page" className="page-container">
      {renderHeader()}
      <Collapse
        className="collapse-container"
        activeKey={[activeStepKey]}
        onChange={(activeKeys) => {
          const lastKey = activeKeys[activeKeys.length - 1] || null;
          dispatchSetActiveStepKey(lastKey);
        }}
      >
        {renderAllSteps()}
      </Collapse>
    </div>
  );
};

export default ShipmentCreatePage;
