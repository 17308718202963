import { getAPIFromDomain } from "../../lib/api";

// lib
import UrlBuilder from "../../lib/UrlBuilder";

// types
import { Fulfillment } from "@secondcloset/types";
type WRO = Fulfillment.WarehouseReceivingOrder;

export interface WROQueryOption {
  q?: string;
  organization_id?: string;
  page?: number;
  per_page?: number;
  sku?: string;
  status?: string;
  arrival_date?: string;
  organization_name?: string;
  field?: string | number | React.ReactText[] | undefined;
  direction?: "asc" | "desc" | "";
}

export const fetchWROIndex = async (query: WROQueryOption): Promise<WRO[]> => {
  const api = getAPIFromDomain("fulfillment");
  const baseURL = `/warehouse_receiving_orders`;
  const url = new UrlBuilder(baseURL).applyQueryStringFromObject(query);

  const response = await api.get(url);
  return response.data;
};
