import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Spin, notification } from "antd";
import { useCustomerNotes } from "../../../../hooks/api/regular/customer";
import CustomerNotesTable from "./CustomerNotesTable";
import NewNote from "./NewNote";

const CustomerNotes = ({ userID }) => {
  const {
    customerNotes,
    fetchCustomerNotes,
    createNote,
    editNote,
    deleteNote,
  } = useCustomerNotes();

  const getNotes = () => {
    fetchCustomerNotes(userID);
  };

  const onUpdateError = () => {
    if (customerNotes.error) {
      notification.error({
        message: "Error",
        description: customerNotes.error,
      });
    }
  };

  useEffect(getNotes, []);
  useEffect(onUpdateError, [customerNotes.error]);

  const onEditNote = async (note) => {
    await editNote(userID, note.id, note.text);
    fetchCustomerNotes(userID);
  };

  const onDeleteNote = async (note) => {
    await deleteNote(userID, note.id);
    fetchCustomerNotes(userID);
  };

  const onCreateNote = async (note) => {
    await createNote(userID, note);
    fetchCustomerNotes(userID);
  };

  return (
    <Spin tip="Fetching notes..." spinning={customerNotes.loading}>
      <div className="customer-notes">
        <div className="notes-header">
          <div className="title">Notes</div>
          {customerNotes.loading ? (
            <Spin />
          ) : (
            <NewNote
              createNote={onCreateNote}
              createNoteError={customerNotes.error}
            />
          )}
        </div>
        <CustomerNotesTable
          notes={customerNotes.data}
          loading={customerNotes.loading}
          onSubmit={onEditNote}
          onDelete={onDeleteNote}
        />
      </div>
    </Spin>
  );
};

CustomerNotes.propTypes = {
  userID: PropTypes.string.isRequired,
};

export default CustomerNotes;
