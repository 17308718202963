import styled from "styled-components";
import colors from "../../../css/colors";

export const Container = styled.div`
  .reason-notes {
    margin-top: 20px;
  }
`;
export const FailedTitle = styled.div`
  color: ${colors.GREY};
`;
