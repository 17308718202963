import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import DownloadOtifReport from "./DownloadOtifReport/DownloadOtifReport";
import { SearchInput } from "@secondcloset/web-components";
import {
  LeftOutlined,
  RightOutlined,
  UserOutlined,
  DollarOutlined,
  LaptopOutlined,
} from "@ant-design/icons";
import {
  Spin,
  DatePicker,
  Button,
  Popover,
  Tag,
  List,
  Checkbox,
  Space,
} from "antd";
import Appointment from "../../../lib/regular/appointment";
import { startCase, toLower, isEmpty } from "lodash-es";
import { formatedAddress, formatNumber } from "../../../helperFunctions";
import {
  getFullAddressString,
  compressSpaceItems,
  compressItems,
} from "../../../helperFunctions";
import { controlCenterApptStatusColorMapping } from "../../../constants/badgeColors";
import { Helmet } from "react-helmet";
import AppointmentManifestPrintButton from "./AppointmentManifestPrintButton";
import { AvailabilitySlot } from "@secondcloset/logistics-utils";
import Organization from "../../../lib/organization";
import Dropdown from "../../../components/Dropdown";
import useURLStateV2 from "../../../hooks/application/useURLStateV2";
import { useQuery } from "react-query";
import { fetchAppointmentIndex } from "../../../api/storage/appointment";

const getFormatedString = (str) => startCase(toLower(str));

const ControlCenterPage = (props) => {
  const [urlState, setUrlState] = useURLStateV2({
    search: "",
    date: "",
  });
  const [showItems, setShowItems] = useState(false);
  const [timeslots, setTimeslots] = useState([]);
  const [isIkea, setIsIkea] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);

  const getCurrentDate = () => {
    return urlState.date ? urlState.date : moment().format("YYYY-MM-DD");
  };

  const queryOptions = useMemo(() => {
    const ikeaOrgID = Organization.getIkeaOrgID();
    const date = getCurrentDate();
    return {
      date_from: date,
      date_to: date,
      show_items: showItems,
      per_page: "ALL",
      service_area: props.serviceArea,
      ...(isIkea
        ? { organization_id: ikeaOrgID }
        : { excluded_organization_id: ikeaOrgID }),
    };
    // eslint-disable-next-line
  }, [urlState, showItems, props.serviceArea, isIkea]);

  const appointmentIndex = useQuery(["appointmentIndex", queryOptions], () =>
    fetchAppointmentIndex(queryOptions)
  );

  const getTimeSlotsFromAppointments = (appointments) => {
    if (!Array.isArray(appointments)) return [];
    const allSlots = appointments.reduce((acc, appt) => {
      const timeslot = appt.timerange;
      if (!acc.includes(timeslot)) acc.push(timeslot);
      return acc;
    }, []);
    return AvailabilitySlot.getSortedSlots(allSlots);
  };

  useEffect(() => {
    setSelectedFilter([]);
    // eslint-disable-next-line
  }, [props.serviceArea, showItems, isIkea]);

  useEffect(() => {
    if (appointmentIndex.data) {
      setTimeslots(getTimeSlotsFromAppointments(appointmentIndex.data));
    }
  }, [appointmentIndex.data]);

  const onDateChange = (date) => {
    const d = date.format("YYYY-MM-DD");
    setUrlState((state) => ({ ...state, date: d }));
  };

  const buildDateController = () => {
    const currentDate = getCurrentDate();
    const nextDate = moment(currentDate).add(1, "days");
    const previousDate = moment(currentDate).add(-1, "days");

    return (
      <div className="date-controller">
        <Button type="primary" onClick={() => onDateChange(previousDate)}>
          <LeftOutlined />
        </Button>
        <div className="date-display">{moment(currentDate).format("LL")}</div>
        <Button type="primary" onClick={() => onDateChange(nextDate)}>
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const buildPrintManifestButton = () => {
    const currentDate = getCurrentDate();

    return (
      <div style={{ marginRight: "10px" }}>
        <AppointmentManifestPrintButton
          appointments={appointmentIndex}
          date={currentDate}
        />
      </div>
    );
  };

  const buildAppointmentSummary = () => {
    const appointments = (appointmentIndex.data ?? []).filter(
      (a) => a.status !== "cancelled"
    );
    const total = appointments.length;
    if (total.length === 0) return null;
    const appointmentBreakdown = appointments.reduce((acc, cv) => {
      if (!acc[cv.job_type]) acc[cv.job_type] = 1;
      else acc[cv.job_type] += 1;
      return acc;
    }, {});

    const options = Object.keys(appointmentBreakdown).map((type) => {
      const count = appointmentBreakdown[type];
      return {
        key: type,
        value: type,
        label: `${getFormatedString(type)}: ${count}`,
      };
    });

    return (
      <div className="appointment-summary">
        <Dropdown
          options={options}
          selectedOptionKeys={selectedFilter}
          setSelectedOptionKeys={setSelectedFilter}
        />
      </div>
    );
  };

  const buildHeader = () => {
    const currentDate = getCurrentDate();
    return (
      <div className="control-center-header">
        <div className="left">
          {buildDateController()}
          {buildPrintManifestButton()}
          {buildAppointmentSummary()}
        </div>
        <div className="right">
          <DatePicker
            onChange={(date) => onDateChange(date || moment())}
            value={moment(currentDate)}
          />
          <Button
            onClick={() => setShowItems(!showItems)}
            style={{ marginLeft: "10px" }}
          >
            {showItems ? "Hide Items" : "Show Items"}
          </Button>
          <Checkbox
            checked={isIkea}
            onClick={() => setIsIkea(!isIkea)}
            style={{ marginLeft: "10px" }}
          >
            IKEA
          </Checkbox>
        </div>
      </div>
    );
  };

  const buildSearchBar = () => {
    return (
      <Popover
        placement="bottomLeft"
        title="You Can Search"
        content={
          <div className="control-center-popover-list">
            <p>Customer Name</p>
            <p>Address</p>
            <p>Appointment #</p>
            <p>Appointment Type</p>
            <p>Appointment Status</p>
          </div>
        }
        trigger="focus"
      >
        <SearchInput
          placeholder="Search..."
          defaultValue={urlState.search}
          onSearch={(search) => setUrlState((state) => ({ ...state, search }))}
          onClear={() => setUrlState((state) => ({ ...state, search: "" }))}
          enterButton
        />
      </Popover>
    );
  };

  const renderItems = (items, isSpace) => {
    if (items.length === 0) return <p>There are no items</p>;
    const compressed_items = isSpace
      ? compressSpaceItems(items)
      : compressItems(items);
    const itemArray = Object.keys(compressed_items).map(
      (key) => compressed_items[key]
    );
    return (
      <div className="control-center-item-list">
        <List
          size="small"
          dataSource={itemArray}
          renderItem={(item) => {
            const { name, quantity, item_type } = item;
            let display_name = name;
            if (item_type && item_type.toLowerCase() === "product") {
              display_name = `${name} (Product)`;
            }
            return (
              <p className="item-row">
                {display_name} x {quantity}
              </p>
            );
          }}
        />
      </div>
    );
  };

  const matchingSearch = (appt) => {
    if (!urlState.search) return true;
    const {
      address: addressObj,
      user_firstname,
      user_lastname,
      job_type,
      number,
      status,
    } = appt;
    const addressString = getFullAddressString(addressObj);
    const full_name = `${user_firstname} ${user_lastname}`;
    const formatedType = getFormatedString(job_type);
    const appt_type = `${job_type} ${formatedType} ${formatedType.toLowerCase()}}`;
    const apptString =
      `${addressString} ${addressString.toLowerCase()}` +
      ` ${full_name} ${full_name.toLowerCase()} ${appt_type}` +
      ` ${number} ${number.toLowerCase()}` +
      ` ${status} ${getFormatedString(status).toLowerCase()}`;
    return apptString.includes(urlState.search);
  };

  const buildAppointmentItems = (appt) => {
    const { space_order, items, job_type, status } = appt;
    const filteredItems = Appointment.getAppointmentItems(appt);
    const item_count = filteredItems
      ? filteredItems.reduce((acc, cv) => acc + Math.abs(cv.quantity), 0)
      : 0;

    const showItems =
      !isEmpty(filteredItems) &&
      job_type !== "custom" &&
      status !== "cancelled" &&
      status !== "no_show";

    if (!showItems) return null;
    const isSpace =
      space_order || (items && items.find((i) => !!i.category_name));
    return (
      <div className="control-center-appt-right">
        <label>{`${item_count} Items:`}</label>
        {renderItems(filteredItems, isSpace)}
      </div>
    );
  };

  const buildSingleAppointment = (appt) => {
    if (!matchingSearch(appt)) return null;
    const isDelivery = appt.type === "fulfillment";

    const {
      job_type,
      status,
      address: addressObj,
      user_firstname,
      user_lastname,
    } = appt;
    const { user_email, number: apptNum, id, user_id } = appt;

    const userEmail = isDelivery
      ? appt.source.customer.email_address
      : user_email;
    const userName = `${user_firstname} ${user_lastname}`;
    const customerName = isDelivery ? appt.source.customer.name : "";

    const phone = isDelivery
      ? appt.source.customer.phone_number
      : addressObj.phone_number;
    const displayAddress = isDelivery ? appt.location : addressObj;
    const appointmentDetailPath = `/appointments/${id}`;

    const getIcon = (customerSegment) => {
      switch (customerSegment) {
        case "consumer":
          return <UserOutlined />;
        case "business":
          return <DollarOutlined />;
        case "student":
        default:
          return <LaptopOutlined />;
      }
    };

    return (
      <div key={id} className="control-center-slot-appt">
        <div className="control-center-appt-left">
          <div className="type-status">
            <p>{getFormatedString(job_type)}</p>
            <Tag color={controlCenterApptStatusColorMapping(status)}>
              {getFormatedString(status)}
            </Tag>
          </div>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/customers/${user_id}`}
          >
            {userName}
          </a>
          <br />
          <div>{customerName}</div>
          <div>{userEmail}</div>
          {formatNumber(phone)}
          <div className="customer-segment">
            {appt.customer_segment} {getIcon(appt.customer_segment)}
          </div>
        </div>
        <div className="control-center-appt-mid">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={appointmentDetailPath}
          >
            Appt#: {apptNum}
          </a>
          <div className="address-block">{formatedAddress(displayAddress)}</div>
        </div>
        {buildAppointmentItems(appt)}
      </div>
    );
  };

  const buildSlot = (slot, appts) => {
    const validAppts = appts.filter((a) => a.status !== "cancelled");
    if (isEmpty(validAppts)) return null;
    const rows = validAppts.map((appt) => buildSingleAppointment(appt));
    return (
      <div className="control-center-slot" key={slot}>
        <div className="control-center-slot-tag">
          <label>
            {AvailabilitySlot.getDisplaySlot(slot)}{" "}
            <span>({validAppts.length} appts)</span>
          </label>
        </div>
        {rows}
      </div>
    );
  };

  const buildAppointments = () => {
    const appointments = (appointmentIndex.data ?? []).filter((a) => {
      const isCancelled = a.status === "cancelled";
      const isInSelectedFilter =
        selectedFilter.length === 0 || selectedFilter.includes(a.job_type);
      return !isCancelled && isInSelectedFilter;
    });
    const rows = timeslots.map((slot) => {
      const targetAppointments = appointments.filter((a) => {
        return a.timerange === slot;
      });
      return buildSlot(slot, targetAppointments);
    });
    return <div className="control-center-appts">{rows}</div>;
  };

  return (
    <Spin spinning={appointmentIndex.isLoading} tip="Fetching appointments...">
      <div className="page-container">
        <Helmet title="Sierra - Control Center" />
        <div id="control-center-page">
          <Space>
            <h1>Control Center</h1>
            <DownloadOtifReport
              showItems={showItems}
              isIkea={isIkea}
              serviceArea={props.serviceArea}
            />
          </Space>
          {buildHeader()}
          {buildSearchBar()}
          {buildAppointments()}
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = ({ activeFacility: { service_area } }) => ({
  serviceArea: service_area,
});
export default connect(mapStateToProps)(ControlCenterPage);

ControlCenterPage.propTypes = {
  serviceArea: PropTypes.string.isRequired,
};
