import React, { useEffect } from "react";
import { useOrderDetails } from "../../../hooks/api/storage/order";
import { useProductIndex } from "../../../hooks/api/storage/products";
import { useParams } from "react-router";
import PageLoader from "../../../components/Loader/Loader";
import CustomerInfo from "./CustomerInfo";
import OrderSummary from "./OrderSummary";
import ItemsTable from "./ItemsTable";
import SuppliesTable from "./SuppliesTable";
import { get } from "lodash-es";
import { notification } from "antd";

const OrderEditPage = (props) => {
  const {
    orderDetails,
    fetchOrderDetails,
    updateOrderDetails,
  } = useOrderDetails();
  const { productIndex, fetchProductIndex } = useProductIndex();
  const params = useParams();
  const userID = get(params, "userID");
  const orderID = get(params, "orderID");

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchOrderDetails(userID, orderID);
    fetchProductIndex();
  }, []);

  useEffect(() => {
    if (orderDetails.error) {
      notification.error({
        message: "Error",
        description: orderDetails.error,
      });
    }
  }, [orderDetails.error]);

  if (!orderDetails.data && orderDetails.loading) return <PageLoader />;
  if (!orderDetails.data) return null;

  const isStorage = get(orderDetails, "data.kind") === "storage";

  return (
    <div id="storage-order-edit-page" className="page-container">
      <CustomerInfo order={orderDetails.data} />
      <OrderSummary
        order={orderDetails.data}
        updateOrder={updateOrderDetails}
      />
      <ItemsTable
        orderDetails={orderDetails}
        productIndex={productIndex}
        updateOrderDetails={updateOrderDetails}
      />
      {isStorage && (
        <SuppliesTable
          orderDetails={orderDetails}
          productIndex={productIndex}
          updateOrderDetails={updateOrderDetails}
        />
      )}
    </div>
  );
};

export default OrderEditPage;
