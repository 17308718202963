import styled from "styled-components";
import { Space } from "antd";

export const Content = styled.div`
  display: flex;
`;

export const Instructions = styled(Space)`
  margin-right: 15px;
  flex: 1;
`;

export const Container = styled.div`
  flex: 3;
`;

export const InfoText = styled.div``;
