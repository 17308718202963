import * as actionTypes from "../../../actions/fulfillment/types/product";

const INITIAL_STATE = {
  fetching: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    FULFILLMENT_PRODUCT_PUT_REQUESTED,
    FULFILLMENT_PRODUCT_PUT_SUCCESS,
    FULFILLMENT_PRODUCT_PUT_ERRORED,
    FULFILLMENT_PRODUCT_PUT_RESET,
  } = actionTypes;

  switch (action.type) {
    case FULFILLMENT_PRODUCT_PUT_REQUESTED:
      return { ...state, fetching: true };
    case FULFILLMENT_PRODUCT_PUT_SUCCESS:
      return { ...state, fetching: false, error: null };
    case FULFILLMENT_PRODUCT_PUT_ERRORED:
      return { ...state, fetching: false, error: action.error };
    case FULFILLMENT_PRODUCT_PUT_RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
