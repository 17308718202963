import * as actionTypes from "../../../actions/fulfillment/types";

const INITIAL_STATE = {
  fetching: false,
  data: null,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const { WROS_REQUESTED, WROS_RECEIVED, WROS_ERRORED } = actionTypes;

  switch (action.type) {
    case WROS_REQUESTED:
      return { ...state, fetching: true };
    case WROS_RECEIVED:
      return { ...state, fetching: false, data: action.data, error: null };
    case WROS_ERRORED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
