import styled from "styled-components";
import { Modal as ModalAntd } from "antd";
import colors from "../../../../css/colors";

export const Modal = styled(ModalAntd)`
  .good > svg {
    fill: ${colors.GREEN};
  }
  .error > svg {
    fill: ${colors.RED};
  }

  .warning > svg {
    fill: ${colors.YELLOW};
  }

  .message-icon > svg {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }

  .status-icon > svg {
    width: 30px;
    height: 30px;
  }
`;
