import { useState } from "react";
import { useQuery } from "react-query-legacy";
import { fetchFeatures } from "../../api/bfapi";
import useUser from "../reduxContainer/_common/useUser";

export const useFeatureAccess = (featureName: string) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { user } = useUser();
  useQuery(
    [`${featureName}-fetchFeatures`, user],
    () => fetchFeatures(featureName),
    {
      onSuccess: (features) => {
        const permissions = features?.[0]?.permissions || [];
        const emailFound = !!permissions.find(
          (p) => p.email.toLowerCase() === user?.email.toLowerCase()
        );
        setIsEnabled(emailFound);
      },
    }
  );

  return isEnabled;
};
