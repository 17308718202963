import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spin } from "antd";
import { get, isEmpty } from "lodash-es";
import Table from "../Table";
// import Order from "../../../../../lib/regular/order";

const RemoveItemsButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { orderDetails } = props;

  // const hasAppointmentWithin48hours = Order.hasAppointmentWithinHours(
  //   orderDetails?.data,
  //   48
  // );

  const buildRemovingItemsTable = () => {
    const orderItems = get(orderDetails, "data.items", []);
    const removingItems = props.selectedItemIDs
      .map((id) => orderItems.find((item) => item.item_id === id))
      .filter(Boolean);

    return (
      <div className="removing-items-table">
        <Table orderItems={removingItems} selectable={false} />
      </div>
    );
  };

  const buildRemoveItemsModal = () => {
    return (
      <Modal
        title="Are you sure you want to remove the following items?"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() =>
          props.onRemoveItems().then((res) => {
            if (res) setModalVisible(false);
          })
        }
        width={800}
        destroyOnClose
      >
        <Spin spinning={orderDetails.loading} tip="deleting...">
          {buildRemovingItemsTable()}
        </Spin>
      </Modal>
    );
  };

  //
  // if (hasAppointmentWithin48hours) {
  //   return (
  //     <Tooltip title="disabled when appointments within 48 hours">
  //       <Button danger disabled={true}>
  //         Remove Items
  //       </Button>
  //     </Tooltip>
  //   );
  // }

  return (
    <>
      <Button
        danger
        disabled={props.disabled || isEmpty(props.selectedItemIDs)}
        onClick={() => setModalVisible(true)}
      >
        Remove Items
      </Button>
      {buildRemoveItemsModal()}
    </>
  );
};

RemoveItemsButton.propTypes = {
  onRemoveItems: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  selectedItemIDs: PropTypes.array,
  disabled: PropTypes.bool,
};

export default RemoveItemsButton;
