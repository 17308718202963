import React, { Component } from "react";
import { Modal, Button, Spin, Alert } from "antd";
import { cloneDeep, last } from "lodash-es";
import PropTypes from "prop-types";

import qrImage from "./images/barcode-qr.svg";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  focusedInput: null,
  inputValues: {},
};

class AssignQrModal extends Component {
  constructor(props) {
    super(props);

    const inputValues = this.getEmptyInputValues(props.items);

    this.state = { ...DEFAULT_STATE, inputValues };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const inputValues = this.getEmptyInputValues(this.props.items);
      this.setState({ inputValues });
    }

    if (!prevProps.visible && this.props.visible) {
      const inputValues = this.getEmptyInputValues(this.props.items);
      this.setState({ inputValues }, () => this.focusNextLine(-1));
    }
  }

  closeModal = () => {
    const { onCancel, onDone, clearModalData, updateComplete } = this.props;

    clearModalData();
    if (updateComplete) return onDone();
    onCancel();
  };

  getEmptyInputValues = (items) =>
    items.reduce((acc, i) => {
      acc[i.item_id] = { userCode: "", itemCode: "" };
      return acc;
    }, {});

  focusNextLine = (currentLine) => {
    const targetClass = "assign-qr-user-code";
    const targetInput = document.getElementsByClassName(targetClass)[
      currentLine + 1
    ];
    targetInput && targetInput.focus();
  };

  focusItemCode = (currentLine) => {
    const targetClass = "assign-qr-item-code";
    const targetInput = document.getElementsByClassName(targetClass)[
      currentLine
    ];
    targetInput && targetInput.focus();
  };

  setUserCode = (itemId, value, line) => {
    if (last(value) === "-") return this.focusItemCode(line);

    const inputValues = cloneDeep(this.state.inputValues);
    inputValues[itemId].userCode = value;
    this.setState({ inputValues });
  };

  setItemCode = (itemId, value, line) => {
    const inputValues = cloneDeep(this.state.inputValues);
    inputValues[itemId].itemCode = value;
    this.setState({ inputValues });

    if (value.length >= 4) return this.focusNextLine(line);
  };

  buildScanNotifier = () => {
    return (
      <div className="scan-notifier flex-col">
        <p>Start scanning QR code you want to attach or manually input below</p>
        <div>
          <img src={qrImage} alt={"QR"} />
        </div>
        <p>Listening for scan</p>
      </div>
    );
  };

  buildItemInputs = (item, line) => {
    const { focusedInput, inputValues } = this.state;
    const { item_id, name } = item;
    if (!inputValues[item_id]) return null;

    const { userCode: placeholder, updateResults, updateComplete } = this.props;
    const { userCode, itemCode } = inputValues[item_id];
    const selected = focusedInput === item_id;
    const successful =
      updateResults[item_id] && updateResults[item_id] === "success";
    const errored = updateResults[item_id] && !successful;
    const invalidUserCode = !selected && userCode && userCode !== placeholder;

    let borderClass = "";
    let labelText = "Scanning Now";
    if (selected) borderClass = "selected";
    if (successful) {
      borderClass = "successful";
      labelText = "Success";
    }
    if (errored || invalidUserCode) {
      borderClass = "errored";
      labelText = "Error";
    }

    return (
      <div key={item_id} className="assign-qr-single-item-input flex-col">
        <div className="flex-row">
          <label className={`${borderClass} unselectable`}>{labelText}</label>
          {errored && <span>{updateResults[item_id]}</span>}
          {invalidUserCode && <span>{`User code must be ${placeholder}`}</span>}
        </div>
        <div className={`${borderClass} inl-flex-row`}>
          <p className="item-name">{name}</p>
          <input
            className="assign-qr-user-code"
            placeholder={placeholder}
            value={userCode}
            onChange={(e) =>
              this.setUserCode(item.item_id, e.target.value, line)
            }
            onFocus={() => this.setState({ focusedInput: item.item_id })}
            onBlur={() => this.setState({ focusedInput: null })}
            disabled={updateComplete}
          />
          <p>-</p>
          <input
            ref={(n) => (this.itemCodeInput = n)}
            className="assign-qr-item-code"
            maxLength="4"
            value={itemCode}
            onChange={(e) =>
              this.setItemCode(item.item_id, e.target.value, line)
            }
            disabled={updateComplete}
          />
        </div>
      </div>
    );
  };

  buildItemList = () => {
    const {
      userId,
      items,
      assignQrToMultipleItems,
      updateLoading,
      updateComplete,
    } = this.props;
    const { inputValues } = this.state;
    const isFullyFilled = Object.values(inputValues).every(
      (v) => v.userCode && v.itemCode
    );
    const itemIds = items.map((i) => i.item_id);
    const onClick = () => assignQrToMultipleItems(userId, itemIds, inputValues);

    const submitButton = (
      <Button disabled={!isFullyFilled} onClick={onClick}>
        {updateLoading ? <Spin /> : "Submit"}
      </Button>
    );

    const doneButton = (
      <Button type="primary" onClick={this.closeModal}>
        Done
      </Button>
    );

    if (!items.length)
      return (
        <div className="modal-footer flex-col">
          <Alert type="error" message="You have no items selected" />
        </div>
      );

    return (
      <div className="modal-footer flex-col">
        <p className="reminder">Please ensure you have the right USER ID</p>
        {items.map(this.buildItemInputs)}
        {updateComplete ? doneButton : submitButton}
      </div>
    );
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        className="appointment-assign-qr-modal"
        visible={visible}
        onCancel={this.closeModal}
        title="Add or Edit QR Code"
        footer={this.buildItemList()}
      >
        {this.buildScanNotifier()}
      </Modal>
    );
  }
}

AssignQrModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  userCode: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  updateLoading: PropTypes.bool.isRequired,
  updateResults: PropTypes.object.isRequired,
  updateComplete: PropTypes.bool.isRequired,

  assignQrToMultipleItems: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  clearModalData: PropTypes.func.isRequired,
};

export default AssignQrModal;
