import styled from "styled-components";
import colors from "css/colors";

interface WrapperProps {
  reverse?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  border-bottom: ${(props) => (props.reverse ? 0 : "1px solid #e9e9e9")};
  border-top: ${(props) => (props.reverse ? "1px solid #e9e9e9" : 0)};
`;

interface HeaderSectionProps {
  flex?: number;
}

export const HeaderSection = styled.div<HeaderSectionProps>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : "initial")};
  padding: 8px;
  color: ${colors.GREY};
`;
