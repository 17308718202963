import React, { useState, useEffect } from "react";

// hooks
import {
  useUpdateShipment,
  useCompleteShipment,
  useCancelShipment,
  useShipmentDetails,
  useVoidShippingLabels,
  useShipmentPickSheet,
} from "../../../../../hooks/api/fulfillment/shipment";
import { useAppointmentUpdateServiceLevel } from "hooks/api/logistics/appointment";
import {
  useCancelPackageDelivery,
  useRedeliverPackage,
} from "hooks/api/logistics/shipment";
import { useQueryClient, useMutation } from "react-query";

// components
import {
  ShipmentCard as FulfillmentShipmentCard,
  PackageIssueDetailsModal,
} from "@secondcloset/web-components";
import MarkAsIncompleteModal from "../../../../../components/_common/MarkAsIncompleteModal";
import RescheduleShipmentModal from "./RescheduleShipmentModal";
import NotesModal from "./NotesModal";
import FieldOpsNotesModal from "./FieldOpsNotesModal";
import RecreateShipmentModal from "./RecreateShipmentModal";
import ProcessInventoryReceivingItemsModal from "./ProcessInventoryReceivingItemsModal";
import PackageSummaryModal from "./PackageSummaryModal";
import UploadBillOfLadingModal from "./UploadBillOfLadingModal";
import IssueDetailsModal from "./IssueDetailsModal";
import ProofOfDeliveryModal from "./ProofOfDeliveryModal";
import { notification, message } from "antd";

// libs
import Organization from "../../../../../lib/organization";
import * as shipmentCardLib from "./helper";
import { objectToFormData } from "object-to-formdata";
import Cookies from "../../../../../lib/Cookies";
import {
  isSuperAdmin,
  isSupportEscalated,
} from "../../../../../helperFunctions";

// api
import { fetchShipmentProductsTrackings } from "../../../../../api/warehouse/shipmentProductsTracking";
import { updateShipmentMethod } from "../../../../../api/fulfillment/shipment";
import { updateShipment } from "../../../../../api/logistics/shipment";

// styles
import { Container } from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
import { SecondClosetShippingMethod } from "@secondcloset/types/src/fulfillment";
import {
  DeliveryMethodUpdateBody,
  Props,
} from "@secondcloset/web-components/dist/components/fulfillment/ShipmentCard/ShipmentCard";
type Shipment = Fulfillment.Shipment;
type Order = Fulfillment.Order;
type ExternalCarrierShippingMethod = Fulfillment.ExternalCarrierShippingMethod;

interface ShipmentCardProps extends Props {
  shipment: Shipment;
  order: Order;
  onRefetch: () => any;
}

const ShipmentCard: React.FC<ShipmentCardProps> = ({
  shipment,
  order,
  onRefetch,
  ...shipmentProps
}) => {
  const [
    markAsIncompleteModalVisible,
    setMarkAsIncompleteModalVisible,
  ] = useState(false);
  const [activeShipment, setActiveShipment] = useState(shipment);
  const [
    rescheduleShipmentModalVisible,
    setRescheduleShipmentModalVisible,
  ] = useState(false);
  const [
    recreateShipmentModalVisible,
    setRecreateShipmentModalVisible,
  ] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const [fieldOpsNotesModalVisible, setFieldOpsNotesModalVisible] = useState(
    false
  );
  const [
    processInventoryReceivingItemsModalVisible,
    setProcessInventoryReceivingItemsModalVisible,
  ] = useState(false);
  const [packageSummaryModalVisible, setPackageSummaryModalVisible] = useState(
    false
  );
  const [uploadBOLModalVisible, setUploadBOLModalVisible] = useState(false);
  const [issueDetailsModalVisible, setIssueDetailsModalVisible] = useState(
    false
  );
  const [
    packageIssueDetailsModalVisible,
    setPackageIssueDetailsModalVisible,
  ] = useState(false);
  const [packageWithIssue, setPackageWithIssue] = useState("");
  const [
    proofOfDeliveryModalVisible,
    setProofOfDeliveryModalVisible,
  ] = useState(false);
  const [verificationImageIndex, setVerificationImageIndex] = useState(0);
  const [deletePackageLoading, setDeletePackageLoading] = useState<{
    [packageID: string]: boolean;
  }>({});
  const [isReturnToSender, setIsReturnToSender] = useState(false);
  const [returnToSenderAddressID, setReturnToSenderAddressID] = useState<
    string
  >();
  const [isBolCreated, setIsBolCreated] = useState(false);
  const { cancelShipmentStatus, cancelShipment } = useCancelShipment();
  const {
    updateShipmentStatus: updatedShipment,
    deleteFreightPackage,
  } = useUpdateShipment();
  const { completeShipmentStatus, completeShipment } = useCompleteShipment();
  const { shipmentDetails, updateShipmentStatus } = useShipmentDetails();
  const {
    pickSheet,
    downloadPickSheetFile,
    downloadPickSheetFileV2,
  } = useShipmentPickSheet();
  const {
    voidShippingLabelsStatus,
    voidShippingLabels,
  } = useVoidShippingLabels();

  const { mutateAsync: doUpdateShipment, isLoading: isUpdating } = useMutation(
    updateShipment,
    {
      onSuccess: onRefetch,
    }
  );

  const onShipmentMethodUpdateSuccess = (
    shipment: Shipment,
    variables: Record<string, unknown>
  ) => {
    message.success("Shipment method updated.");
    if (variables.isPrintPickSheet)
      downloadPickSheetFile([shipment.shipment_number]);
    if (shipment.shipping_method_type !== "appointment") onRefetch();
    if (shipment.shipping_method_type === "appointment") {
      setActiveShipment(shipment);
      setRescheduleShipmentModalVisible(true);
    }
  };

  const {
    mutate: onUpdateShipmentMethod,
    ...updatedShipmentMethod
  } = useMutation(updateShipmentMethod, {
    onSuccess: onShipmentMethodUpdateSuccess,
  });

  const error =
    updatedShipment.error ||
    cancelShipmentStatus.error ||
    completeShipmentStatus.error ||
    cancelShipmentStatus.error ||
    shipmentDetails.error ||
    voidShippingLabelsStatus.error ||
    updatedShipmentMethod.error;

  const queryClient = useQueryClient();
  const handleFetchShipmentProductsTracking = async (q: {
    orderId: string;
    shipmentId: string;
  }) => {
    try {
      return await queryClient.fetchQuery(
        ["shipmentProductsTrackings", q],
        () =>
          fetchShipmentProductsTrackings({
            external_order_id: q.orderId,
            external_shipment_id: q.shipmentId,
            limit: 100,
          })
      );
    } catch (e) {
      notification.error({ message: "Error", description: e });
      return [];
    }
  };

  const isEndy = Organization.isEndy(order?.organization);
  const isIkea = Organization.getIkeaOrgID() === order?.organization?.id;
  const user = Cookies.getUserInfo();

  useEffect(() => {
    if (error) notification.error({ message: error as string });
  }, [error]);

  const renderMarkAsIncompleteModal = () => {
    return (
      <MarkAsIncompleteModal
        shipment={shipment}
        visible={markAsIncompleteModalVisible}
        setVisible={setMarkAsIncompleteModalVisible}
        onRefetch={onRefetch}
      />
    );
  };

  const onRescheduleShipmentCancel = () => {
    setRescheduleShipmentModalVisible(false);
    onRefetch();
  };

  const renderRescheduleShipmentModal = () => {
    return (
      <RescheduleShipmentModal
        shipment={activeShipment}
        order={order}
        visible={rescheduleShipmentModalVisible}
        onCancel={() => onRescheduleShipmentCancel()}
        onRefetch={onRefetch}
        setActiveShipment={setActiveShipment}
      />
    );
  };

  const renderNotesModal = () => {
    return (
      <NotesModal
        shipment={shipment}
        visible={notesModalVisible}
        setVisible={setNotesModalVisible}
        onRefetch={onRefetch}
      />
    );
  };

  const renderFieldOpsNotesModal = () => {
    return (
      <FieldOpsNotesModal
        shipment={shipment}
        visible={fieldOpsNotesModalVisible}
        setVisible={setFieldOpsNotesModalVisible}
      ></FieldOpsNotesModal>
    );
  };

  const renderRecreateShipmentModal = () => {
    return (
      <RecreateShipmentModal
        visible={recreateShipmentModalVisible}
        setVisible={setRecreateShipmentModalVisible}
        shipment={shipment}
        order={order}
        onRefetch={onRefetch}
        isNewReturnToSender={isReturnToSender}
        returnToSenderAddressID={returnToSenderAddressID}
      />
    );
  };

  const renderProcessInventoryReceivingItemsModal = () => {
    return (
      <ProcessInventoryReceivingItemsModal
        shipment={shipment}
        visible={processInventoryReceivingItemsModalVisible}
        orderLoading={!!shipmentProps.isLoading}
        setVisible={setProcessInventoryReceivingItemsModalVisible}
        onRefetch={onRefetch}
      />
    );
  };

  const renderPackageSummaryModal = () => {
    return (
      <PackageSummaryModal
        packages={shipment?.packages || []}
        isVisible={packageSummaryModalVisible}
        onSubmit={() => {
          setPackageSummaryModalVisible(false);
          setIsBolCreated(true);
        }}
        onClose={() => setPackageSummaryModalVisible(false)}
      />
    );
  };

  const onBOLSubmit = async (
    file: File,
    bolNumber: string,
    trackingNumber?: string
  ) => {
    const body = objectToFormData({
      bol_file: file,
      label_id: bolNumber?.trim(),
      tracking_number: trackingNumber?.trim(),
    });
    await doUpdateShipment({ shipmentID: shipment.id, body });
    setUploadBOLModalVisible(false);
  };

  const renderUploadBOLModal = () => {
    return (
      <UploadBillOfLadingModal
        isVisible={uploadBOLModalVisible}
        onClose={() => setUploadBOLModalVisible(false)}
        onSubmit={onBOLSubmit}
        isLoading={updatedShipment.loading}
        shid={shipment?.shipment_number || "-"}
      />
    );
  };

  const renderIssueDetailsModal = () => {
    return (
      <IssueDetailsModal
        shipment={shipment}
        visible={issueDetailsModalVisible}
        setVisible={setIssueDetailsModalVisible}
        onRefetch={onRefetch}
      />
    );
  };

  const renderPackageIssueDetailsModal = () => {
    return (
      <PackageIssueDetailsModal
        shipment={shipment}
        packageId={packageWithIssue}
        visible={packageIssueDetailsModalVisible}
        setVisible={setPackageIssueDetailsModalVisible}
        isIkea={isIkea}
        isLoading={false}
      />
    );
  };

  const renderProofOfDeliveryModal = () => {
    return (
      <ProofOfDeliveryModal
        shipment={shipment}
        verificationImageIndex={verificationImageIndex}
        visible={proofOfDeliveryModalVisible}
        setVisible={setProofOfDeliveryModalVisible}
        setVerificationImageIndex={setVerificationImageIndex}
      />
    );
  };

  const updateStatusAndRefetch = async (status: string) => {
    const res = await updateShipmentStatus(shipment?.id || "", status);
    if (res) onRefetch();
  };

  const onCancelShipment = async () => {
    const isAppointment = shipment?.shipping_method_type === "appointment";
    if (!isAppointment) {
      const shippingMethod = shipment?.shipping_method as ExternalCarrierShippingMethod;
      const shippingLabels = shippingMethod?.shipping_labels || [];
      const allLabelsVoided = shippingLabels.every((l) => l.voided);
      if (!allLabelsVoided)
        return notification.warn({
          message: "Please void all shipping labels",
        });
    }
    await cancelShipment(shipment?.id);
    onRefetch();
  };

  const goToProductDetailsPage = (productID: string) => {
    window.open(
      `/organizations/${order?.organization.id}/products/${productID}`
    );
  };

  const onDeletePackage = async (packageID: string) => {
    const packageLoading = { ...deletePackageLoading };
    packageLoading[packageID] = true;
    setDeletePackageLoading(packageLoading);
    if (shipment?.freight) {
      await deleteFreightPackage(shipment.id, packageID);
    } else {
      const package_ = shipment?.packages?.find((p) => p.id === packageID);
      const labelID = package_?.logistics_external_carrier_shipping_label_id;
      if (labelID) await voidShippingLabels(shipment.id, [labelID]);
    }
    packageLoading[packageID] = false;
    setDeletePackageLoading(packageLoading);
    onRefetch();
  };

  const onClickPackageIssueModal = (packageId: string) => {
    setPackageWithIssue(packageId);
    setPackageIssueDetailsModalVisible(true);
  };

  const isMarkAsCompletedButtonDisabled = () => {
    if (shipmentCardLib.isInventoryWarehouseReceiving(shipment))
      return shipmentCardLib.hasAnyIdleItem(shipment);
    const isExternalCarrierShipment =
      shipment?.shipping_method_type === "external_carrier_shipment";
    const isOutbound = shipment?.action_summary?.includes("delivery");
    const notWithCarrier = shipment?.shipping_method?.status !== "with_carrier";
    return isOutbound && notWithCarrier && isExternalCarrierShipment;
  };

  const onBOLButtonClick = async () => {
    const shippingMethod = shipment?.shipping_method as ExternalCarrierShippingMethod;
    const shippingLabel = shippingMethod?.shipping_labels?.find(
      (s) => !s.voided
    );
    const isBOLValid = shippingLabel?.label_id && !shippingLabel?.voided;

    if (isBOLValid) {
      window.open(shippingLabel?.label_download, "_blank");
      await completeShipment(shipment);
      return onRefetch();
    }
    if (isBolCreated) return setUploadBOLModalVisible(true);
    window.open("https://app.freightcom.com", "_blank");
    setPackageSummaryModalVisible(true);
  };

  const [
    updateServiceLevelMutation,
    updateServiceLevelResult,
  ] = useAppointmentUpdateServiceLevel();

  const updateServiceLevelObject = {
    onUpdate: async (appointmentID: string, serviceLevel: string) => {
      const data = {
        appointmentID,
        body: {
          delivery_service_level: serviceLevel,
        },
      };
      await updateServiceLevelMutation(data);
      onRefetch();
    },
    loading: updateServiceLevelResult.status === "loading",
  };

  const onUpdateShippingMethod = (
    body: DeliveryMethodUpdateBody,
    isPrintPickSheet?: boolean
  ) =>
    onUpdateShipmentMethod({
      shipmentID: shipment.id,
      body,
      isPrintPickSheet: !!isPrintPickSheet,
    });
  const [
    cancelPackageDeliveryMutation,
    cancelPackageDeliveryResult,
  ] = useCancelPackageDelivery();

  const cancelPackageDelivery = {
    onUpdate: async (packageID: string) => {
      await cancelPackageDeliveryMutation(packageID);
      onRefetch();
    },
    loading: cancelPackageDeliveryResult.status === "loading",
  };

  const [
    redeliverPackageMutation,
    redeliverPackageResult,
  ] = useRedeliverPackage();

  const redeliverPackage = {
    onUpdate: async (packageID: string) => {
      await redeliverPackageMutation(packageID);
      onRefetch();
    },
    loading: redeliverPackageResult.status === "loading",
  };

  return (
    <Container>
      <FulfillmentShipmentCard
        isSuperAdmin={isSuperAdmin(user?.role, user?.subrole)}
        isSupportEscalated={isSupportEscalated(user?.role, user?.subrole)}
        isIkea={isIkea}
        shipment={shipment}
        order={order}
        fetchShipmentProductTrackings={handleFetchShipmentProductsTracking}
        uploadBOLButtonProps={{
          onClick: onBOLButtonClick,
          loading: completeShipmentStatus.loading || isUpdating,
          // disabled the button until all shipment items are packed
          disabled: shipment?.shipping_method?.status === "shipment_created",
        }}
        updateServiceLevel={updateServiceLevelObject}
        isBolCreated={isBolCreated}
        markAsCompletedButtonProps={{
          onClick: async () => {
            await completeShipment(shipment);
            onRefetch();
          },
          loading: completeShipmentStatus.loading,
          disabled: isMarkAsCompletedButtonDisabled(),
        }}
        viewIssueDetailsButtonProps={{
          onClick: () => setIssueDetailsModalVisible(true),
        }}
        markAsIncompleteButtonProps={{
          onClick: () => setMarkAsIncompleteModalVisible(true),
        }}
        cancelShipmentButtonProps={{
          onClick: onCancelShipment,
          loading: cancelShipmentStatus.loading,
        }}
        rescheduleButtonProps={{
          onClick: () => setRescheduleShipmentModalVisible(true),
        }}
        processItemsButtonProps={{
          onClick: () => setProcessInventoryReceivingItemsModalVisible(true),
        }}
        notesButtonProps={{
          onClick: () => setNotesModalVisible(true),
        }}
        fieldOpsNotesButtonProps={{
          onClick: () => setFieldOpsNotesModalVisible(true),
        }}
        isAdmin
        packItemsButtonProps={{
          onClick: () => {
            window.open(
              process.env.REACT_APP_PROVIDENCE_WEB +
                `packing-scan-shipment/${shipment?.id}`,
              "_blank"
            );
          },
        }}
        markAsFailedButtonProps={{
          loading: shipmentDetails.loading,
          onClick: () => updateStatusAndRefetch("failed"),
        }}
        resumeAppointmentButtonProps={{
          loading: shipmentDetails.loading,
          onClick: () => updateStatusAndRefetch("arrive"),
        }}
        recreateShipmentButtonProps={{
          onClick: () => {
            setRecreateShipmentModalVisible(true);
            setIsReturnToSender(false);
          },
        }}
        returnToSenderButtonProps={{
          onClick: () => {
            /**
             * Need to find the original address ID for return to sender
             */
            const inventoryPickupShipment = order?.shipments?.find((s) => {
              const shippingMethod = s?.shipping_method as SecondClosetShippingMethod;
              return shippingMethod?.job_type === "inventory_pick_up";
            });
            const inventoryPickupAppointment = inventoryPickupShipment?.shipping_method as SecondClosetShippingMethod;
            const addressID = inventoryPickupAppointment?.location?.id;
            setRecreateShipmentModalVisible(true);
            setIsReturnToSender(true);
            setReturnToSenderAddressID(addressID);
          },
        }}
        cancelPackageButtonProps={{
          onClick: (packageId: string) =>
            cancelPackageDelivery.onUpdate(packageId),
          loading: cancelPackageDelivery.loading,
        }}
        redeliverPackageButtonProps={{
          onClick: (packageId: string) => redeliverPackage.onUpdate(packageId),
          loading: redeliverPackage.loading,
        }}
        viewPackageIssueDetailsButtonProps={{
          onClick: (packageId: string) => onClickPackageIssueModal(packageId),
        }}
        onDeletePackage={onDeletePackage}
        deletePackageLoading={deletePackageLoading}
        onProductClick={goToProductDetailsPage}
        onVerificationImageClick={(index) => {
          setProofOfDeliveryModalVisible(true);
          setVerificationImageIndex(index);
        }}
        printPickSheetButtonProps={{
          onClick: () => downloadPickSheetFileV2([shipment.id]),
          loading: pickSheet.loading,
        }}
        isEndy={isEndy}
        onUpdateShippingMethod={onUpdateShippingMethod}
        isDeliveryMethodLoading={
          updatedShipmentMethod.isLoading || pickSheet.loading
        }
        updateShipmentStatus={{
          onUpdate: (value: string) => updateStatusAndRefetch(value),
          loading: shipmentDetails.loading,
        }}
        onUpdateFulfilledFrom={(facilityID) =>
          doUpdateShipment({
            shipmentID: shipment.id,
            body: { facility_id: facilityID },
          })
        }
        {...shipmentProps}
      />
      {renderMarkAsIncompleteModal()}
      {renderRescheduleShipmentModal()}
      {renderNotesModal()}
      {renderFieldOpsNotesModal()}
      {renderRecreateShipmentModal()}
      {renderProcessInventoryReceivingItemsModal()}
      {renderPackageSummaryModal()}
      {renderUploadBOLModal()}
      {renderIssueDetailsModal()}
      {renderPackageIssueDetailsModal()}
      {renderProofOfDeliveryModal()}
    </Container>
  );
};

export default ShipmentCard;
