import React from "react";
import { Typography, Space, Table, Button, Row, Col } from "antd";
import {
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Modal } from "./styles";

interface Props {
  visible: boolean;
  data: ShipmentsPreview[];
  isLoading?: boolean;
  onCancel: () => void;
  onReDownload: () => void;
}

export interface ShipmentsPreview {
  order_id: string;
  order_number: string;
  shipment_id: string;
  shipment_number: string;
  status: "good" | "error" | "warning";
  message: string;
}

const { Text, Title } = Typography;

const PickSheetStatusModal: React.FC<Props> = ({
  visible,
  data,
  onCancel,
  onReDownload,
  isLoading,
}) => {
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "order_id",
      render(idNumber: string, record: any) {
        return (
          <Link to={`/fulfillment/orders/${record.order_id}`}>{idNumber}</Link>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render(status: string) {
        return status === "good" ? (
          <CheckCircleFilled className="status-icon good" />
        ) : status === "error" || status === "failed" ? (
          <CloseCircleFilled className="status-icon error" />
        ) : (
          <WarningFilled className="status-icon warning" />
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "SHID",
      dataIndex: "shipment_number",
      key: "shipment_number",
      render: (number: string) => (number ? number : "-"),
    },
  ];

  const totalCount = data.length;
  const availCount = data.filter(
    (value: ShipmentsPreview) => !!value.shipment_id
  ).length;

  const renderTableFooter = () => (
    <Row>
      <Col>
        <Typography.Text type="secondary">
          {" "}
          {`${availCount} of ${totalCount} ${
            totalCount === 1 ? "pick sheet" : "pick sheets"
          } ready for printing`}
        </Typography.Text>
      </Col>
    </Row>
  );

  const title = <Title level={4}>PICK SHEET STATUS</Title>;
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      width={650}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          disabled={!data.find((item) => item.shipment_number)}
          onClick={onReDownload}
        >
          Re-Download
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <Text>
          <CloseCircleFilled className="message-icon error" />
          Was <strong>not</strong> able to generate and print pick sheet
        </Text>
        <Text>
          <WarningFilled className="message-icon warning" />
          Was <strong>only</strong> able to generate pick sheet with items able
          to be picked
        </Text>
      </Space>
      <Table
        style={{ marginTop: 20 }}
        columns={columns}
        footer={renderTableFooter}
        dataSource={data}
        rowKey="order_id"
        pagination={false}
      />
    </Modal>
  );
};

export default PickSheetStatusModal;
