import React, { useEffect } from "react";

import { Fulfillment } from "@secondcloset/types";
import { SelectItem } from "@secondcloset/web-components";

import { Modal, notification, Typography } from "antd";
import moment from "moment";

import * as S from "./styles";

import { useCreateShipment } from "hooks/api/fulfillment/shipment";
import useShipmentCreateFlow from "hooks/reduxContainer/fulfillment/shipmentCreateFlow";

import ShipmentCreate from "lib/fulfillment/shipmentCreate";

import DateTimeSelect from "../../components/DateTimeSelect";
import { SelectServiceLevel } from "../../components/SelectServiceLevel";

interface CreateShipmentDevToolModalProps {
  order: Fulfillment.Order;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onRefetchOrder: () => void;
}

export const CreateShipmentDevToolModal: React.FC<CreateShipmentDevToolModalProps> = ({
  order,
  isVisible,
  setIsVisible,
  onRefetchOrder,
}) => {
  const {
    selectedOrderItemIDs,
    serviceCategory,
    shipmentDate,
    shipmentTime,
    dispatchSetOrder,
    dispatchSetSelectedOrderItemIDs,
    dispatchSetServiceCategory,
    dispatchSetIsSendEmail,
    dispatchSetShipmentDate,
    dispatchSetShipmentTime,
    dispatchResetShipmentCreateFlow,
  } = useShipmentCreateFlow();

  const { shipment, createShipment } = useCreateShipment();

  useEffect(() => {
    if (shipment.error) {
      notification.error({
        message: "Error",
        description: `she doesn't even go here. (${shipment.error})`,
      });
    }
  }, [shipment.error]);

  useEffect(() => {
    dispatchSetOrder(order);
    dispatchSetSelectedOrderItemIDs(order.items.map((item) => item.id));
    dispatchSetIsSendEmail(false);
    dispatchSetServiceCategory("second_closet_standard");
    dispatchSetShipmentDate(moment().format("YYYY-MM-DD"));
    dispatchSetShipmentTime("10-13");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, isVisible]);

  const handleCancelCreateShipment = () => {
    dispatchResetShipmentCreateFlow();
    setIsVisible(false);
  };

  const handleCreateShipment = () => {
    const orderItems = ShipmentCreate.constructOrderItems(selectedOrderItemIDs, {
      isExchange: false,
      isFullRefund: false,
      isReturn: false,
      isUnopenedCancellation: false,
      returnReasons: null,
    });

    const fields = {
      serviceCode: serviceCategory,
      orderID: order.id,
      date: shipmentDate,
      time: shipmentTime,
      orderItems,
    };

    const body = ShipmentCreate.getCreateShipmentBody(fields, {
      isPickupPartner: false,
      isSecondCloset: true,
      isFreight: false,
    });

    createShipment(body).then((success) => {
      if (success) {
        notification.success({
          message: "Success",
          description: "You rock, don't ever change!(Shipment created btw)",
        });
        onRefetchOrder();
        setIsVisible(false);
      }
    });
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancelCreateShipment}
      title="Create Shipment Dev Tool"
      width={600}
      onOk={handleCreateShipment}
      okText="Ship it"
    >
      <S.Container>
        <Typography.Text italic>Order ID: {order.id}</Typography.Text>
        <SelectServiceLevel serviceLevel={serviceCategory} onSelectServiceLevel={dispatchSetServiceCategory} />
        <SelectItem
          order={order}
          selectedOrderItemIDs={selectedOrderItemIDs}
          setSelectedOrderItemIDs={dispatchSetSelectedOrderItemIDs}
        />
        <DateTimeSelect
          order={order}
          selectedDate={shipmentDate}
          setSelectedDate={dispatchSetShipmentDate}
          selectedTime={shipmentTime}
          setSelectedTime={dispatchSetShipmentTime}
        />
      </S.Container>
    </Modal>
  );
};
