import { useState, useRef } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import queryString from "query-string";

// interface
import { APIPromise } from "@secondcloset/api-utils/dist/types/index";
import { Storage } from "@secondcloset/types";
type Appointment = Storage.Appointment;

const useAppointmentIndex = () => {
  const lastPromise = useRef<APIPromise>();

  const [data, setData] = useState<Appointment[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchAppointmentIndex = (query: any) => {
    setLoading(true);
    setError("");

    const url = `/appointments?${queryString.stringify(query, {
      arrayFormat: "bracket",
    })}`;
    const api = getAPIFromDomain();
    const currentPromise = api.get(url);
    lastPromise.current = currentPromise;

    return currentPromise
      .then((res) => {
        const appointments: Appointment[] = res.data;
        if (currentPromise === lastPromise.current) {
          setData(appointments);
          return appointments;
        }
        return false;
      })
      .catch((error) => {
        setError(error);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const appointmentIndex = { data, loading, error };
  return { appointmentIndex, fetchAppointmentIndex };
};

const useRecreateAppointment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const url = "/appointments";
  const api = getAPIFromDomain();

  const updateAppointmentRescheduledID = (
    appointment: Appointment,
    rescheduledID: string
  ) => {
    const url = `/appointments/${appointment.id}`;
    const body = {
      appointment: { rescheduled_appointment_id: rescheduledID },
    };
    return api.put(url, body);
  };

  const recreateAppointment = (
    oldAppointment: any,
    date: string,
    time: string
  ) => {
    setLoading(true);
    setError(null);

    const body = {
      appointment: {
        status: "request_received",
        job_type: oldAppointment.job_type,
        date: date,
        time_range: time,
        order_id: oldAppointment.order_id,
        address_id: oldAppointment.address.id,
        user_id: oldAppointment.user_id,
        customer_segment: "consumer",
      },
    };

    return api
      .post(url, body)
      .then((res) => {
        const newAppointmentID = res.data.id;
        return updateAppointmentRescheduledID(oldAppointment, newAppointmentID);
      })
      .then((res) => {
        setLoading(false);
        setData(res.data);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const recreatedAppointmentStatus = { data, loading, error };
  return { recreatedAppointmentStatus, recreateAppointment };
};

export { useAppointmentIndex, useRecreateAppointment };
