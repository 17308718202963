import React from "react";
import PropTypes from "prop-types";

import { InputTextWithOptions } from "./";
import colorSelection from "../../config/colors.json";
import "./InputTextWithColors.css";

const InputTextWithColors = (props) => {
  const { value, onChange } = props;

  const selection = colorSelection.reduce((acc, obj) => {
    if (!value || obj.name.includes(value)) {
      acc.push(
        <li
          className="colorRow"
          onClick={() => onChange({ target: { value: obj.name } })}
        >
          <p>{obj.name}</p>
          <div style={{ backgroundColor: obj.hexcode }} />
        </li>
      );
    }
    return acc;
  }, []);

  return <InputTextWithOptions {...props} selectOptions={selection} />;
};

InputTextWithColors.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

InputTextWithColors.defaultProps = {
  onChange: () => {},
};

export default InputTextWithColors;
