import { getAPIFromDomain } from "../../lib/api";
import { Fulfillment, Integration } from "@secondcloset/types";
import UrlBuilder from "../../lib/UrlBuilder";

export interface FetchShopsQuery {
  per_page?: number;
  organization_id?: string;
}

export const fetchShops = async (
  query: FetchShopsQuery
): Promise<Integration.Shop[]> => {
  const api = getAPIFromDomain("shopify");
  const urlBuilder = new UrlBuilder("/shops");
  const url = urlBuilder.applyQueryStringFromObject(query);
  const response = await api.get(url);
  return response.data.shops;
};

export const syncOrder = async (body: {
  shopify_domain: string;
  id: string;
  organization_id: string;
}): Promise<Fulfillment.Order> => {
  const api = getAPIFromDomain("shopify");
  const url = "/orders/sync";
  const response = await api.post(url, body);
  return response.data;
};
