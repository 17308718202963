import React, { useRef } from "react";
import { Location } from "history";
import { useHistory, Prompt } from "react-router";
import { Modal } from "antd";

interface Props {
  when: boolean;
  resetWhen: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
  title?: string;
  okText?: string;
  cancelText?: string;
}

const NavigationPrompt: React.FC<Props> = ({
  when,
  resetWhen,
  title,
  content,
  okText,
  cancelText,
}) => {
  const history = useHistory();
  const promptRef = useRef(false);

  const confirmModal = (location: Location) => {
    if (when && !promptRef.current) {
      promptRef.current = true;
      Modal.confirm({
        content,
        title: title || "Are you sure you want to leave this page?",
        cancelText: cancelText || "No",
        okText: okText || "Yes",
        okType: "danger",
        onOk() {
          resetWhen((state) => !state);
          history.push(location);
        },
        onCancel() {
          promptRef.current = false;
        },
      });
      return false;
    } else return true;
  };

  return <Prompt when={when} message={confirmModal} />;
};

export default NavigationPrompt;
