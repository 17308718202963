import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Button, Alert, notification } from "antd";
import { useCustomerDetails } from "../../../../../hooks/api/regular/customer";
import reduxStore from "../../../../../config/reduxStore";
import { isGod, isSuperAdmin } from "../../../../../helperFunctions/user";
import BlueEditButton from "../BlueEditButton";

const EditContactButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [submitError, setSubmitError] = useState("");
  const {
    customerDetails,
    updateCustomerDetails,
    setCustomerDetailsLoading,
  } = useCustomerDetails();

  const onMount = () => {
    setCustomerDetailsLoading(false);
  };

  const onFieldChange = () => {
    setSubmitError("");
  };

  const onCustomerDetailsUpdate = () => {
    if (customerDetails.data) {
      setModalVisible(false);
      props.onUpdateSuccess();
    }

    if (customerDetails.error) {
      notification.error({
        message: "Error",
        description: customerDetails.error,
      });
    }
  };

  useEffect(onMount, []);
  useEffect(onFieldChange, [newFirstName, newLastName, newPhone, newEmail]);
  useEffect(onCustomerDetailsUpdate, [customerDetails]);

  const renderField = (label, onChange, value, disabled = false) => {
    return (
      <div className="field-input">
        <div className="label">{label}</div>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      </div>
    );
  };

  const onSave = () => {
    const first_name = newFirstName?.trim();
    const last_name = newLastName?.trim();
    const email = newEmail?.trim();
    const phone_number = newPhone?.trim();
    if (!first_name) return setSubmitError("First name cannot be empty");
    if (!last_name) return setSubmitError("Last name cannot be empty");
    if (!email) return setSubmitError("Email cannot be empty");
    if (!phone_number) return setSubmitError("Phone number cannot be empty");
    const body = { user: { first_name, last_name, email, phone_number } };
    updateCustomerDetails(props.user.id, body);
  };

  const renderError = () => {
    if (submitError) {
      return <Alert message={submitError} type="error" banner />;
    }
  };

  const renderModal = () => {
    const { role, subrole } = reduxStore.getState().auth.user;
    const isEmailEditEnabled =
      isGod(role, subrole) || isSuperAdmin(role, subrole);
    return (
      <Modal
        title="Contact Information"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="edit-contact-info-modal">
          {renderError()}
          {renderField("First name", (v) => setNewFirstName(v), newFirstName)}
          {renderField("Last name", (v) => setNewLastName(v), newLastName)}
          {renderField(
            "Email",
            (v) => setNewEmail(v),
            newEmail,
            !isEmailEditEnabled
          )}
          {renderField("Phone number", (v) => setNewPhone(v), newPhone)}
          <div className="action-buttons">
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button
              onClick={onSave}
              type="primary"
              loading={customerDetails.loading}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const onEditClick = () => {
    const { first_name, last_name, email, phone_number } = props.user;
    setNewFirstName(first_name);
    setNewLastName(last_name);
    setNewPhone(phone_number);
    setNewEmail(email);
    setSubmitError("");
    setModalVisible(true);
  };

  return (
    <div>
      <BlueEditButton onClick={onEditClick}>Edit</BlueEditButton>
      {renderModal()}
    </div>
  );
};

EditContactButton.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default EditContactButton;
