import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import { getAppointmentColor } from "../../../../helperFunctions";
import { isSuperAdmin } from "../../../../helperFunctions/user";
import { startCase } from "lodash-es";
import { Select, Modal, Alert } from "antd";
import moment from "moment";
import Appointment from "../../../../lib/regular/appointment";
import reduxStore from "../../../../config/reduxStore";
import { Availabilities } from "@secondcloset/logistics-utils";

const AppointmentInfo = (props) => {
  const [newStatus, setNewStatus] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const {
    status,
    job_type,
    job_subtype,
    number,
    space_order,
    signature_image,
    order_id,
    user_id,
    customer_segment,
    address,
    estimated_time,
  } = props.appointment;

  const isCustomAppointment = job_type === "custom";

  const mins = estimated_time
    ? `${Availabilities.msToMin(estimated_time)} mins`
    : "-";

  const buildSignature = () => {
    const signatureExist = !!signature_image;
    if (!signatureExist) return null;
    const { url, created_at } = signature_image;
    return (
      <div className="appointment-signature">
        <img src={url} alt={url} />
        <div className="appointment-signature-description">
          {moment(created_at).format("ll h:mm a")}
        </div>
      </div>
    );
  };

  const buildMovingTime = () => {
    const {
      start_time,
      end_time,
      incomplete_at,
      num_movers,
      movers,
    } = props.appointment;
    const checkInTime = start_time;
    const checkOutTime = end_time || incomplete_at;

    const checkInTimeDisplay = checkInTime
      ? moment(checkInTime).format("HH:mm")
      : "-";
    const checkOutTimeDisplay = checkOutTime
      ? moment(checkOutTime).format("HH:mm")
      : "-";
    const hasDuration = checkInTime && checkOutTime;
    const minutes = hasDuration
      ? Math.ceil(
          moment
            .duration(moment(checkOutTime).diff(moment(checkInTime)))
            .asMinutes()
        )
      : "-";

    const moverNames = movers ? movers.join(", ") : "-";

    const renderDuration = () => {
      const minuteText = minutes > 1 ? "minutes" : "minute";
      return hasDuration ? `${minutes} ${minuteText}` : "-";
    };

    return (
      <div className="appointment-moving-time">
        <div className="time-row">
          <div className="label">Check in time</div>
          <div className="value">{checkInTimeDisplay}</div>
        </div>
        <div className="time-row">
          <div className="label">Check out time</div>
          <div className="value">{checkOutTimeDisplay}</div>
        </div>
        <div className="time-row">
          <div className="label">Duration</div>
          <div className="value">{renderDuration()}</div>
        </div>
        <div className="time-row">
          <div className="label">Num movers</div>
          <div className="value">{num_movers || "-"}</div>
        </div>
        <div className="mover-row">
          <div className="label">Movers: </div>
          <div className="value">{moverNames}</div>
        </div>
      </div>
    );
  };

  const buildAppointmentStatus = (status) => {
    const { role, subrole } = reduxStore.getState().auth.user;
    if (isSuperAdmin(role, subrole)) {
      return (
        <Select
          size="small"
          style={{ width: 150 }}
          value={status}
          onSelect={(s) => {
            setModalVisible(true);
            setNewStatus(s);
          }}
        >
          {Appointment.getAppointmentStatusChangeList().map((s) => (
            <Select.Option value={s} key={s}>
              {s}
            </Select.Option>
          ))}
        </Select>
      );
    }

    return (
      <Tag color={getAppointmentColor("status", status)}>
        {" "}
        {startCase(status)}
      </Tag>
    );
  };

  const buildCustomAppointmentReminder = () => {
    if (isCustomAppointment) {
      return (
        <div className="custom-appointment-alert-message">
          <Alert
            message="A custom appointment does not get billed automatically for moving fee. Please remember to invoice the client manually"
            banner
          />
        </div>
      );
    }
  };

  const buildAppointmentSubType = () => {
    if (!job_subtype) return null;
    const label = `Type of ${startCase(job_type)} Appointment: `;
    return (
      <div className="appointment-subtype">
        {label} <Tag color="black">{startCase(job_subtype)}</Tag>
      </div>
    );
  };

  const buildModal = () => {
    return (
      <Modal
        confirmLoading={props.loading}
        visible={modalVisible}
        title="Appointment Status Update"
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          props.onUpdateAppointmentStatus(props.appointment.id, newStatus);
          setModalVisible(false);
        }}
      >
        <div className="status-change-modal">
          <div className="question">
            Are you sure you want to change status to <b>{newStatus}</b>?
          </div>
        </div>
        {buildCustomAppointmentReminder()}
      </Modal>
    );
  };

  if (!props.appointment) return null;

  return (
    <div className="appointment-detail-info-card card">
      <div className="header">
        <div className="appointment-number">Appointment: #{number}</div>
      </div>

      <div className="appointment-controls">
        <div className="left">
          <div className="appointment-type">
            Type:{" "}
            <Tag color={getAppointmentColor("type", job_type)}>
              {" "}
              {startCase(job_type)}
            </Tag>{" "}
            {space_order && <span>Space Order</span>}
          </div>
          <div className="appointment-status">
            Status: {buildAppointmentStatus(status)}
          </div>
          <div className="appointment-order">
            Order:{" "}
            {order_id ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/orders/${order_id}/user/${user_id}`}
              >
                #{order_id.slice(-8)}
              </a>
            ) : (
              <i> no order </i>
            )}
          </div>
          <div className="appointment-service-area">
            Service Area:{" "}
            <Tag color="blue"> {address.service_area || "yyz"}</Tag>
          </div>
          <div className="appointment-customer-segment">
            Customer Segment:{" "}
            <Tag color="cyan">{customer_segment.replace(/_/g, " ")}</Tag>
          </div>
          {buildAppointmentSubType()}
        </div>

        <div className="mid">
          <div className="big-text">Estimated Time To Complete</div>
          <div className="value">{mins}</div>
        </div>

        <div className="right">
          {buildSignature()}
          {buildMovingTime()}
        </div>
      </div>
      {buildModal()}
    </div>
  );
};

AppointmentInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
  onUpdateAppointmentStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AppointmentInfo;
