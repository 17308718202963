import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const BackButton = (props) => {
  const { onClick, children } = props;

  return (
    <div
      onClick={onClick}
      className="inl-flex-row rowMargin noPadding back-button"
    >
      <LeftOutlined />
      <span>{children}</span>
    </div>
  );
};

export default BackButton;

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
};
