import React, { useEffect, useState } from "react";

// components
import { Link, useHistory, useParams } from "react-router-dom";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import {
  ASNTable,
  CheckboxDropdown,
  SearchInput,
} from "@secondcloset/web-components";
import { Space, Typography, DatePicker, Button, Select } from "antd";

// hooks & utils
import { useOrganizationContext } from "../../../contextProviders/organization/OrganizationProvider";
import useUrlState from "hooks/application/useUrlState";
import { useQuery } from "react-query";
import { Facility } from "@secondcloset/fulfillment-utils";

// api
import { fetchASNIndex } from "../../../api/fulfillment/asn";

// types
import { SorterResult } from "antd/lib/table/interface";
import { Fulfillment } from "@secondcloset/types";

// styles
import * as S from "./styles";

const STATUS_OPTIONS = [
  { key: "draft", value: "draft", label: "Draft" },
  { key: "awaiting", value: "awaiting", label: "Awaiting" },
  { key: "arrived", value: "arrived", label: "Arrived" },
  { key: "completed", value: "completed", label: "Completed" },
  { key: "on_hold", value: "on_hold", label: "On Hold" },
];

const EXCEPTION_OPTIONS = [
  { key: "missing_date", value: "missing_date", label: "Missing Date" },
  { key: "overdue", value: "overdue", label: "Overdue" },
  { key: "under_received", value: "under_received", label: "Under Received" },
  { key: "over_received", value: "over_received", label: "Over Received" },
];

const WAREHOUSE_OPTIONS = Facility.getFulfillmentFacilityList().map((f) => ({
  key: f.code,
  value: f.code,
  label: f.name,
}));

const PAGE_SIZE = 10;
type ASN = Fulfillment.ASN;

const FulfillmentASNIndexPage: React.FC = () => {
  const { data: orgDetails, loading: isOrgLoading } = useOrganizationContext();
  const history = useHistory();
  const { organization_id } = useParams<{ organization_id: string }>();
  const orgID = orgDetails?.id ?? organization_id;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [status, setStatus] = useUrlState({
    name: "status",
    initialValue: [],
  });
  const [exceptions, setExceptions] = useUrlState({
    name: "exceptions",
    initialValue: [],
  });
  const [facility, setFacility] = useUrlState({
    name: "facility",
    initialValue: "all",
  });
  const [searchASN, setSearchASN] = useUrlState({
    name: "search",
    initialValue: "",
  });
  const [searchSKU, setSearchSKU] = useUrlState({
    name: "sku",
    initialValue: "",
  });
  const [searchOrgName, setSearchOrgName] = useUrlState({
    name: "org",
    initialValue: "",
  });
  const [arrivalDate, setArrivalDate] = useUrlState({
    name: "arrival_date",
    initialValue: "",
  });
  const [page, setPage] = useUrlState({ name: "page", initialValue: 1 });
  const [sort, setSort] = useState<{
    order_by?: string;
    direction?: "asc" | "desc";
  }>();

  const queryOptions = {
    per_page: pageSize,
    page: page || 1,
    ...(orgDetails
      ? { organization_id: orgID }
      : { requires: ["organization_name"] }),
    ...(searchASN && { q: String(searchASN).trim() }),
    ...(searchSKU && { sku: String(searchSKU).trim() }),
    ...(searchOrgName && { organization_name: String(searchOrgName).trim() }),
    ...(facility && facility !== "all" && { facility }),
    ...(arrivalDate && { arrival_date: arrivalDate }),
    ...(status?.length && { status }),
    ...(exceptions?.length && { exception: exceptions }),
    ...(sort?.order_by && sort?.direction && { ...sort }),
  };

  const ASNIndex = useQuery(
    ["ASNIndex", queryOptions],
    () => fetchASNIndex(queryOptions),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setPage(1);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [searchASN, searchSKU, searchOrgName]);

  const handleSort = ({ columnKey, order }: SorterResult<ASN>) => {
    const key = columnKey as keyof ASN;
    // status is not supported in server side sorting
    if (key !== "status") {
      setSort({
        order_by: key,
        direction: order ? (order === "ascend" ? "asc" : "desc") : undefined,
      });
    }
    ASNIndex.data?.sort((a: any, b: any) => {
      if (!a[key] || !b[key] || !order) return 0;
      const isAscending = order === "ascend";
      return (isAscending ? a[key] > b[key] : a[key] < b[key]) ? 1 : -1;
    });
  };

  const renderHeaderSection = () => {
    const link = orgDetails
      ? `/organizations/${orgDetails.id}/wros`
      : `/fulfillment/wros`;
    return (
      <Space size="large" align="center">
        <Typography.Title level={3}>Advance Shipping Notice</Typography.Title>
        <Link to={link}>Switch to WRO (legacy)</Link>
      </Space>
    );
  };

  const renderMainSearchSection = () => (
    <S.FilterWrapper>
      <SearchInput
        className="search-input"
        placeholder="Search for ASN, Tracking, Container or PO #"
        defaultValue={searchASN}
        onSearch={setSearchASN}
        onClear={() => setSearchASN("")}
      />
      {!orgDetails && (
        <SearchInput
          className="search-input"
          placeholder="Search for Organization"
          defaultValue={searchOrgName}
          onSearch={setSearchOrgName}
          onClear={() => setSearchOrgName("")}
        />
      )}
      <Button
        icon={<FilterOutlined />}
        onClick={() => setIsFilterVisible((state) => !state)}
        style={{ marginLeft: "auto" }}
        type="text"
      >
        {isFilterVisible ? "Hide" : "Show"} Filter
      </Button>
    </S.FilterWrapper>
  );

  const renderFiltersSection = () => (
    <S.FilterWrapper>
      <SearchInput
        placeholder="Search for SKU"
        defaultValue={searchSKU}
        onSearch={setSearchSKU}
        onClear={() => setSearchSKU("")}
        style={{ width: 200 }}
      />
      <CheckboxDropdown
        options={STATUS_OPTIONS}
        defaultText="All Status"
        selectedOptionKeys={status ?? []}
        setSelectedOptionKeys={setStatus}
      />
      <CheckboxDropdown
        options={EXCEPTION_OPTIONS}
        defaultText="All Exceptions"
        selectedOptionKeys={exceptions ?? []}
        setSelectedOptionKeys={setExceptions}
      />
      <Select
        options={[
          { key: "all", value: "all", label: "All Warehouse" },
          ...WAREHOUSE_OPTIONS,
        ]}
        value={facility}
        onChange={setFacility}
        style={{ width: "200px" }}
      />
      <DatePicker
        placeholder="Arrival Date"
        onChange={(_, dateString: string) => setArrivalDate(dateString)}
        style={{ width: "200px" }}
      />
    </S.FilterWrapper>
  );

  return (
    <S.Container>
      <Space direction="vertical" style={{ width: "100%" }}>
        {orgDetails && (
          <Button
            className="back-btn"
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push(`/organizations/${orgDetails.id}`)}
          >
            {orgDetails.name}
          </Button>
        )}
        {renderHeaderSection()}
        {renderMainSearchSection()}
        {isFilterVisible && renderFiltersSection()}
      </Space>
      <ASNTable
        isAdmin
        showOrganizationColumn={!orgDetails?.id}
        ASNList={ASNIndex.data ?? []}
        isLoading={
          ASNIndex.isPreviousData || ASNIndex.isLoading || isOrgLoading
        }
        onSort={handleSort}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        renderASNLinkComponent={(displayText: string, id: string) => (
          <Link to={`/fulfillment/asns/${id}`}>{displayText}</Link>
        )}
        renderOrgLinkComponent={(displayText: string, id: string) => (
          <Link to={`/organizations/${id}`}>{displayText}</Link>
        )}
      />
    </S.Container>
  );
};

export default FulfillmentASNIndexPage;
