import styled from "styled-components";

export const ImageContainer = styled.div`
  height: 350px;
`;

export const EmptyImageContainer = styled.div`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
