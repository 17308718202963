import React from "react";
import PropTypes from "prop-types";

const InputText = ({
  modClass,
  placeholder,
  value,
  onChange,
  onKeyPress,
  autoFocus,
  validationCheck,
}) => (
  <div className="form-group">
    <input
      type="text"
      autoFocus={autoFocus ? autoFocus : false}
      className={modClass ? "form-control " + modClass : "form-control"}
      placeholder={placeholder}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
    />
    {validationCheck}
  </div>
);

InputText.propTypes = {
  modClass: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  validationCheck: PropTypes.element,
};

export default InputText;
