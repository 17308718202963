import { getAPIFromDomain } from "../../lib/api";

export interface UpdateServiceLevelMutation {
  appointmentID: string;
  body: {
    delivery_service_level: string;
  };
}

export const updateServiceLevel = (data: UpdateServiceLevelMutation) => {
  const api = getAPIFromDomain("logistics");
  const url = `/appointments/${data.appointmentID}/service_level`;
  return api.put(url, data.body);
};
