import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Select, Spin, Alert, DatePicker, notification } from "antd";
import Address from "../../../../lib/regular/address";
import Appointment from "../../../../lib/regular/appointment";
import reduxStore from "../../../../config/reduxStore";
import NewAddressButton from "../../../../components/regular/_common/address/NewAddressButton";
import { isGod, isSuperAdmin } from "../../../../helperFunctions/user";
import { useCustomerAddresses } from "../../../../hooks/api/regular/customer";
import { useAppointmentAvailability } from "../../../../hooks/api/logistics/availabilities";
import moment from "moment";
import { Link } from "react-router-dom";
import { last, get } from "lodash-es";
import { AvailabilitySlot } from "@secondcloset/logistics-utils";
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";

const CustomerInfo = (props) => {
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isEditingDateTime, setIsEditingDateTime] = useState(false);
  const [submitDateTimeError, setSubmitDateTimeError] = useState(null);
  const [newDateTime, setNewDateTime] = useState({});
  const [availabilityInstance, setAvailabilityInstance] = useState(null);

  const {
    appointmentAvailability,
    fetchAvailableDates,
  } = useAppointmentAvailability("storage");
  const {
    customerAddresses,
    fetchCustomerAddresses,
    createAddress,
  } = useCustomerAddresses();

  const {
    user_firstname,
    user_lastname,
    user_id,
    user_email,
    address,
  } = props.appointment;
  const fullName = `${user_firstname} ${user_lastname}`;

  const onAppointmentDetailChange = () => {
    if (!customerAddresses.data && props.appointment) {
      fetchCustomerAddresses(props.appointment.user_id);
    }
  };

  const onCustomerAddressChange = () => {
    if (customerAddresses.data) {
      setSelectedAddress(last(customerAddresses.data));
    }
  };

  const onNewDateTimeChange = () => {
    if (submitDateTimeError) setSubmitDateTimeError(null);
  };

  const onCreateAddressError = () => {
    if (customerAddresses.error) {
      notification.error({
        message: "Error creating addresses",
        description: customerAddresses.error,
      });
    }
  };

  const onAvailabilityChange = () => {
    if (appointmentAvailability.data) {
      const { role, subrole } = reduxStore.getState().auth.user;
      const apptAddress = props.appointment.address;
      const isSuperEscalated_ =
        isGod(role, subrole) || isSuperAdmin(role, subrole);
      const instance = new AppointmentsAvailability(
        appointmentAvailability.data,
        {
          isAdmin: isSuperEscalated_,
          serviceArea: apptAddress?.service_area || "yyz",
        }
      );
      setAvailabilityInstance(instance);
    }
  };

  useEffect(onCustomerAddressChange, [customerAddresses.data]);
  useEffect(onNewDateTimeChange, [
    newDateTime.date,
    newDateTime.timerange,
    isEditingDateTime,
  ]);
  useEffect(onCreateAddressError, [customerAddresses.error]);
  useEffect(onAppointmentDetailChange, [props.appointment]);
  useEffect(onAvailabilityChange, [appointmentAvailability.data]);

  const buildAppointmentAddressDisplay = () => {
    const apptAddress = props.appointment.address;
    return (
      <>
        <div className="appointment-address">
          {Address.getAddressLines(apptAddress).map((line) => (
            <div key={line}>
              {line} <br />
            </div>
          ))}
        </div>
        <Button
          onClick={() => {
            setIsEditingAddress(true);
            setSelectedAddress(apptAddress);
          }}
          style={{ marginTop: "10px" }}
        >
          Edit Appointment Address
        </Button>
      </>
    );
  };

  const buildAppointmentAddressEdit = () => {
    const { data, error } = customerAddresses;
    const addressList = data;

    const selectAddress = (addressID) => {
      const selectedAddress =
        addressList && addressList.find((a) => a.id === addressID);
      setSelectedAddress(selectedAddress);
    };

    return (
      <>
        <div className="address-select-title">Select New Address:</div>
        <div className="address-select">
          <Select
            onChange={selectAddress}
            value={
              selectedAddress
                ? Address.getOneLineAddress(selectedAddress)
                : undefined
            }
          >
            {addressList.map((address, index) => (
              <Select.Option value={address.id} key={address.id + index}>
                {Address.getOneLineAddress(address)}
              </Select.Option>
            ))}
          </Select>
          <NewAddressButton
            createAddress={(address) =>
              createAddress(props.appointment.user_id, address)
            }
            createAddressError={error}
          />
        </div>

        <div className="address-confirm-buttons">
          <Button danger onClick={() => setIsEditingAddress(false)}>
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={() => {
              setIsEditingAddress(false);
              props.onUpdateAppointmentDetails(props.appointment.id, {
                address_id: selectedAddress.id,
              });
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const buildAppointmentAddress = () => {
    const { loading } = customerAddresses;
    if (loading) return <Spin tip="fetching addresses..." />;

    if (!isEditingAddress) return buildAppointmentAddressDisplay();
    else return buildAppointmentAddressEdit();
  };

  const buildAppointmentDateTimeEdit = () => {
    const { loading, error } = appointmentAvailability;
    if (loading || !availabilityInstance)
      return <Spin tip="Fetching availability..." />;
    if (error) return <Alert message={error} type="error" />;

    const isDateDisabled = (date) => {
      return availabilityInstance.isDateOneDisabled(date.format("YYYY-MM-DD"));
    };

    const selectedDate = get(
      newDateTime,
      "date",
      moment().format("YYYY-MM-DD")
    );
    const slots = availabilityInstance.getTimeslots(selectedDate);

    const isSlotDisabled = (timeslot) => {
      return availabilityInstance.isSlotOneDisabled(newDateTime.date, timeslot);
    };

    const onDateChange = (date) => {
      setNewDateTime({
        date: date ? date.format("YYYY-MM-DD") : null,
        time: null,
      });
    };

    const currentSelectedSlot =
      AvailabilitySlot.getDisplaySlot(newDateTime.timerange) || "--------";

    return (
      <>
        {submitDateTimeError && (
          <Alert message={submitDateTimeError} type="warning" />
        )}
        <br />

        <DatePicker
          onChange={onDateChange}
          value={newDateTime.date ? moment(newDateTime.date) : null}
          disabledDate={isDateDisabled}
        />

        <Select
          onChange={(labeledValue) =>
            setNewDateTime({ ...newDateTime, timerange: labeledValue.key })
          }
          value={{ key: newDateTime.timerange, label: currentSelectedSlot }}
          className="time-select"
          disabled={!newDateTime.date}
          labelInValue={true}
        >
          {slots.map((s) => {
            const timeslot = s.timeslot;
            return (
              <Select.Option
                key={timeslot}
                value={timeslot}
                disabled={isSlotDisabled(timeslot)}
              >
                {AvailabilitySlot.getDisplaySlot(timeslot)}
              </Select.Option>
            );
          })}
        </Select>

        <div className="date-confirm-buttons">
          <Button danger onClick={() => setIsEditingDateTime(false)}>
            Cancel
          </Button>

          <Button
            type="primary"
            onClick={() => {
              const { date, timerange } = newDateTime;
              if (!date || !timerange) {
                setSubmitDateTimeError("DATE and TIME must be selected");
                return;
              }
              setIsEditingDateTime(false);
              props.onUpdateAppointmentDetails(props.appointment.id, {
                date,
                time_range: timerange,
              });
            }}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const buildAppointmentDateTime = () => {
    const { date, timerange, id } = props.appointment;
    const isDisabled = !Appointment.canAppointmentBeModify(props.appointment);
    if (!isEditingDateTime) {
      return (
        <>
          <div className="appointment-date">
            Date: <span>{moment(date).format("LL")}</span>
          </div>
          <div className="appointment-time">
            Time: <span>{AvailabilitySlot.getDisplaySlot(timerange)}</span>
          </div>
          <Button
            onClick={() => {
              setIsEditingDateTime(true);
              const today = moment().format("YYYY-MM-DD");
              const daysAhead = moment().add(90, "days").format("YYYY-MM-DD");
              const options = {
                date_from: today,
                date_to: daysAhead,
                appointment_id: id,
              };
              fetchAvailableDates(options);
              setNewDateTime({ date, timerange });
            }}
            style={{ marginTop: "10px" }}
            disabled={isDisabled}
          >
            Reschedule
          </Button>
        </>
      );
    }

    return buildAppointmentDateTimeEdit();
  };

  if (!props.appointment) return null;

  return (
    <div className="customer-info-card card">
      <div className="header">
        <div className="customer-name">
          <Link to={`/customers/${user_id}`}>{fullName}</Link>
        </div>
      </div>
      <div className="customer-info-details">
        <div className="left">
          <div className="customer-phone">
            Phone:{" "}
            <a href={`tel:${address.phone_number}`}>{address.phone_number}</a>
          </div>
          <div className="customer-email">
            Email: <a href={`mailto:${user_email}`}>{user_email}</a>
          </div>
          <div className="customer-address">{buildAppointmentAddress()}</div>
        </div>

        <div className="right">
          <div className="appointment-date-time">
            {buildAppointmentDateTime()}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
  onUpdateAppointmentDetails: PropTypes.func.isRequired,
};

export default CustomerInfo;
