import React, { useEffect, useState } from "react";

// components
import { Modal, Spin } from "antd";
import { UploadBillOfLading } from "@secondcloset/web-components";

// styles
import * as S from "./styles";

interface Props {
  isVisible: boolean;
  onClose: () => any;
  onSubmit: (file: File) => unknown;
  isLoading?: boolean;
}

const UploadBolModal: React.FC<Props> = ({
  isVisible,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File>();

  useEffect(() => {
    if (isVisible) setUploadedFile(undefined);
  }, [isVisible]);

  const isSubmitDisabled = !uploadedFile || isLoading;

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <S.Container direction="vertical" size="large">
        <S.HeaderTitle>Upload Bill of Lading</S.HeaderTitle>
        <Spin spinning={isLoading}>
          <UploadBillOfLading
            hideInputFields
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
          />
        </Spin>
        <S.YellowButton
          isDisabled={isSubmitDisabled}
          onClick={() => {
            if (!isSubmitDisabled) onSubmit(uploadedFile as File);
          }}
        >
          UPLOAD
        </S.YellowButton>
      </S.Container>
    </Modal>
  );
};

export default UploadBolModal;
