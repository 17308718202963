import { useSelector, useDispatch } from "react-redux";
import {
  actionSetIsReturn,
  actionSetActiveStepKey,
  actionSetOrder,
  actionSetSelectedOrderItemIDs,
  actionSetShipmentCarrier,
  actionSetShipmentDate,
  actionSetShipmentTime,
  actionSetIsSendEmail,
  actionReset,
  actionSetReturnReasons,
  actionSetIsFullRefund,
  actionSetIsExchange,
  actionSetIsUnopenedCancellation,
  actionSetIsReturnWarningVisible,
  actionSetServiceCategory,
  actionSetPartner,
  actionSetNotes,
  actionSetPackageDetails,
  actionResetPackageDetails,
  actionSetIsReturnWithOriginalPackage,
  actionSetIsPartialReturn,
} from "../../../ducks/shipmentCreateFlow";
import Organization from "../../../lib/organization";
import { get } from "lodash-es";

// Selectors
const selectIsReturn = (state) => state.fulfillment.shipmentCreateFlow.isReturn;
const selectActiveStepKey = (state) =>
  state.fulfillment.shipmentCreateFlow.activeStepKey;
const selectOrder = (state) => state.fulfillment.shipmentCreateFlow.order;
const selectSelectedOrderItemIDs = (state) =>
  state.fulfillment.shipmentCreateFlow.selectedOrderItemIDs;
const selectShipmentCarrier = (state) =>
  state.fulfillment.shipmentCreateFlow.shipmentCarrier;
const selectShipmentDate = (state) =>
  state.fulfillment.shipmentCreateFlow.shipmentDate;
const selectShipmentTime = (state) =>
  state.fulfillment.shipmentCreateFlow.shipmentTime;
const selectIsSendEmail = (state) =>
  state.fulfillment.shipmentCreateFlow.isSendEmail;
const selectReturnReasons = (state) =>
  state.fulfillment.shipmentCreateFlow.returnReasons;
const selectIsFullRefund = (state) =>
  state.fulfillment.shipmentCreateFlow.isFullRefund;
const selectIsExchange = (state) =>
  state.fulfillment.shipmentCreateFlow.isExchange;
const selectIsUnopenedCancellation = (state) =>
  state.fulfillment.shipmentCreateFlow.isUnopenedCancellation;
const selectIsReturnWarningVisible = (state) =>
  state.fulfillment.shipmentCreateFlow.isReturnWarningVisible;
const selectServiceCategory = (state) =>
  state.fulfillment.shipmentCreateFlow.serviceCategory;
const selectPartner = (state) => state.fulfillment.shipmentCreateFlow.partner;
const selectNotes = (state) => state.fulfillment.shipmentCreateFlow.notes;
const selectPackageDetails = (state) =>
  state.fulfillment.shipmentCreateFlow.packageDetails;
const selectIsReturnWithOriginalPackage = (state) => {
  return state.fulfillment.shipmentCreateFlow.isReturnWithOriginalPackage;
};
const selectIsPartialReturn = (state) =>
  state.fulfillment.shipmentCreateFlow.isPartialReturn;

const useShipmentCreateFlow = () => {
  const dispatch = useDispatch();

  const isReturn = useSelector(selectIsReturn);
  const activeStepKey = useSelector(selectActiveStepKey);
  const order = useSelector(selectOrder);
  const selectedOrderItemIDs = useSelector(selectSelectedOrderItemIDs);
  const shipmentCarrier = useSelector(selectShipmentCarrier);
  const shipmentDate = useSelector(selectShipmentDate);
  const shipmentTime = useSelector(selectShipmentTime);
  const isSendEmail = useSelector(selectIsSendEmail);
  const returnReasons = useSelector(selectReturnReasons);
  const isFullRefund = useSelector(selectIsFullRefund);
  const isExchange = useSelector(selectIsExchange);
  const isUnopenedCancellation = useSelector(selectIsUnopenedCancellation);
  const isReturnWarningVisible = useSelector(selectIsReturnWarningVisible);
  const serviceCategory = useSelector(selectServiceCategory);
  const partner = useSelector(selectPartner);
  const notes = useSelector(selectNotes);
  const packageDetails = useSelector(selectPackageDetails);
  const isReturnWithOriginalPackage = useSelector(
    selectIsReturnWithOriginalPackage
  );
  const isPartialReturn = useSelector(selectIsPartialReturn);

  const organization = get(order, "organization");
  const isEndy = Organization.isEndy(organization);
  const isHamuq = Organization.isHamuq(organization);

  const isInventoryWarehouseReceiving = order?.shipments?.some((shipment) => {
    return shipment?.action_summary?.includes("inventory_warehouse_receiving");
  });

  const dispatchSetIsReturn = (isReturn_) =>
    dispatch(actionSetIsReturn(isReturn_));
  const dispatchSetActiveStepKey = (stepKey) =>
    dispatch(actionSetActiveStepKey(stepKey));
  const dispatchSetOrder = (order_) => dispatch(actionSetOrder(order_));
  const dispatchSetSelectedOrderItemIDs = (itemIDs) => {
    dispatch(actionSetSelectedOrderItemIDs(itemIDs));
  };
  const dispatchSetShipmentCarrier = (carrier) =>
    dispatch(actionSetShipmentCarrier(carrier));
  const dispatchSetShipmentDate = (date) =>
    dispatch(actionSetShipmentDate(date));
  const dispatchSetShipmentTime = (time) =>
    dispatch(actionSetShipmentTime(time));
  const dispatchSetIsSendEmail = (isSendEmail_) =>
    dispatch(actionSetIsSendEmail(isSendEmail_));
  const dispatchResetShipmentCreateFlow = () => dispatch(actionReset());
  const dispatchSetIsFullRefund = (isFullRefund) =>
    dispatch(actionSetIsFullRefund(isFullRefund));
  const dispatchSetIsExchange = (isExchange) =>
    dispatch(actionSetIsExchange(isExchange));
  const dispatchSetIsUnopenedCancellation = (isUnopenedCancellation) =>
    dispatch(actionSetIsUnopenedCancellation(isUnopenedCancellation));
  const dispatchSetIsReturnWarningVisible = (isVisible) =>
    dispatch(actionSetIsReturnWarningVisible(isVisible));
  const dispatchSetReturnReasons = (returnReasons) =>
    dispatch(actionSetReturnReasons(returnReasons));
  const dispatchSetServiceCategory = (serviceCategory) =>
    dispatch(actionSetServiceCategory(serviceCategory));
  const dispatchSetPartner = (partner) => dispatch(actionSetPartner(partner));
  const dispatchSetNotes = (notes) => dispatch(actionSetNotes(notes));
  const dispatchSetPackageDetails = (packageDetails) =>
    dispatch(actionSetPackageDetails(packageDetails));
  const dispatchResetPackageDetails = () =>
    dispatch(actionResetPackageDetails());
  const dispatchSetReturnWithOriginalPackage = (isReturnWithOriginalPackage) =>
    dispatch(actionSetIsReturnWithOriginalPackage(isReturnWithOriginalPackage));
  const dispatchSetIsPartialReturn = (isPartialReturn) =>
    dispatch(actionSetIsPartialReturn(isPartialReturn));

  const buildSteps = (isReturn) => {
    const selectItem = {
      key: "selectItem",
      title: isReturn ? "Select Return Item" : "Select Delivery Item",
    };

    const selectReturnReason = {
      key: "selectReturnReason",
      title: "Select Return Reason",
    };

    const packageDetails = {
      key: "packageDetails",
      title: "Enter Package Details",
    };

    const selectShipmentCarrier = {
      key: "selectShipmentCarrier",
      title: "Select Delivery Method",
    };

    const shipmentOverview = {
      key: "shipmentOverview",
      title: "Shipment Overview",
    };

    const s = [];
    s.push(selectItem);
    const isEndyReturn = isReturn & isEndy;
    if (isEndyReturn) s.push(selectReturnReason);
    if (isReturn) s.push(packageDetails);
    s.push(selectShipmentCarrier, shipmentOverview);
    const result = s.reduce((acc, cv, index) => {
      acc[cv.key] = cv;
      acc[cv.key].seq = index + 1;
      return acc;
    }, {});
    return result;
  };

  return {
    activeStepKey,
    order,
    selectedOrderItemIDs,
    shipmentCarrier,
    shipmentDate,
    shipmentTime,
    isSendEmail,
    isReturn,
    returnReasons,
    isFullRefund,
    isExchange,
    isUnopenedCancellation,
    isReturnWarningVisible,
    serviceCategory,
    partner,
    isEndy,
    isHamuq,
    notes,
    packageDetails,
    isReturnWithOriginalPackage,
    isInventoryWarehouseReceiving,
    isPartialReturn,

    dispatchSetIsReturn,
    dispatchSetActiveStepKey,
    dispatchSetOrder,
    dispatchSetSelectedOrderItemIDs,
    dispatchSetShipmentCarrier,
    dispatchSetShipmentDate,
    dispatchSetShipmentTime,
    dispatchSetIsSendEmail,
    dispatchResetShipmentCreateFlow,
    dispatchSetReturnReasons,
    dispatchSetIsFullRefund,
    dispatchSetIsExchange,
    dispatchSetIsUnopenedCancellation,
    dispatchSetIsReturnWarningVisible,
    dispatchSetServiceCategory,
    dispatchSetPartner,
    dispatchSetNotes,
    dispatchSetPackageDetails,
    dispatchResetPackageDetails,
    dispatchSetReturnWithOriginalPackage,
    dispatchSetIsPartialReturn,

    buildSteps,
  };
};

export default useShipmentCreateFlow;
