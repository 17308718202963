import styled from "styled-components";
import colors from "../../../../../../../css/colors";

export const Container = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 25px;
`;

export const Description = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const ButtonWrap = styled.div`
  margin-top: 60px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const ButtonBase = styled.div`
  width: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-in;
  text-align: center;
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const BlueButton = styled(ButtonBase)`
  background: ${colors.BLUE};
  color: white;
`;

export const YellowButton = styled(ButtonBase)`
  background: ${colors.YELLOW};
  color: ${colors.CHARCOAL};
`;
