import * as actionTypes from "../../../actions/regular/types";

const INITIAL_STATE = {
  updateLoading: false,
  updateResults: {},
  updateComplete: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.EDIT_NAME_MODAL_UPDATE_REQUEST:
      return { ...state, updateLoading: true };
    case actionTypes.EDIT_NAME_MODAL_UPDATE_RESPONSE:
      return {
        ...state,
        updateLoading: false,
        updateResults: action.data,
        updateComplete: true,
      };
    case actionTypes.EDIT_NAME_MODAL_CLEAR_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
}
