import {
  FETCH_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_ADDRESSES,
  CLEAR_CUSTOMER_DETAILS,
  FETCH_CUSTOMER_APPOINTMENTS,
} from "../actions";

const INITIAL_STATE = {
  details: undefined,
  billing: undefined,
  orders: undefined,
  addresses: undefined,
  inventory: undefined,
  appointments: undefined,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS:
      return { ...state, details: action.payload.data || {} };
    case FETCH_CUSTOMER_ADDRESSES:
      return { ...state, addresses: action.payload.data || [] };
    case CLEAR_CUSTOMER_DETAILS:
      return { ...INITIAL_STATE };
    case FETCH_CUSTOMER_APPOINTMENTS:
      return { ...state, appointments: action.payload };
    default:
      return state;
  }
}
