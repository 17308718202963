import {
  SIGNIN_SUCCESS,
  SET_USER_INFORMATION,
  SET_AUTH_ERROR,
  SET_AUTH_LOADING,
  SET_TOKEN,
  SIGNOUT,
} from "../actions";

export default function (
  state = {
    authenticated: false,
    token: null,
    error_message: "",
    loading: false,
    user: {},
  },
  action
) {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.payload.token.token,
        loading: false,
        error_message: "",
        user: action.payload.user,
      };
    case SET_USER_INFORMATION:
      return {
        ...state,
        user: action.payload,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        error_message: action.payload,
        loading: false,
      };
    case SET_AUTH_LOADING:
      return { ...state, loading: true };
    case SET_TOKEN:
      return { ...state, token: action.payload, authenticated: true };
    case SIGNOUT:
      return { ...state, authenticated: false, token: null };
    default:
      return state;
  }
}
