export default class Cloudinary {
  static getThumbnailLink = (imageLink, width = 50, height = 50) => {
    if (!imageLink || !imageLink.includes("cloudinary")) return imageLink;
    const splitLink = imageLink.split("upload/");
    return (
      splitLink[0] +
      `upload/w_${width},h_${height},c_fill,g_auto/` +
      splitLink[1]
    );
  };

  static isCloudinaryUrl = (url) => url.includes("cloudinary");

  static getResizedImage(imageLink, width = 50, height = 50) {
    if (!Cloudinary.isCloudinaryUrl(imageLink)) return imageLink;

    const splitLink = imageLink.split("upload/");
    return (
      splitLink[0] +
      `upload/w_${width},h_${height},c_fill,g_auto/` +
      splitLink[1]
    );
  }

  static getGravityAutoImage = (imageLink) => {
    if (!Cloudinary.isCloudinaryUrl(imageLink)) return imageLink;
    const splitLink = imageLink.split("upload/");
    return splitLink[0] + `upload/g_auto/` + splitLink[1];
  };
}
