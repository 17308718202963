import { combineReducers } from "redux";
import {
  wros,
  warehouseReceivingOrderCreate,
  wroDetails,
  warehouseReceivingOrderPost,
  wroDetailsUpdate,
  warehouseReceivingOrderDelete,
} from "./wro";
import orderCreateFlow from "ducks/orderCreateFlow";
import shipmentCreateFlow from "ducks/shipmentCreateFlow";
import productCreateFlow from "ducks/productCreateFlow";

import {
  products,
  productDetails,
  productActivityHistory,
  productPost,
  productPut,
  productDelete,
} from "./product";

import { wroLabelGet, wroLabelsGet } from "./shippingLabels";

const fulfillment = combineReducers({
  wros,
  warehouseReceivingOrderCreate,
  warehouseReceivingOrderDelete,
  wroDetails,
  wroDetailsUpdate,
  warehouseReceivingOrderPost,
  wroLabelGet,
  wroLabelsGet,

  products,
  productDetails,
  productPut,
  productActivityHistory,
  productPost,
  productDelete,

  orderCreateFlow,
  shipmentCreateFlow,
  productCreateFlow,
});

export default fulfillment;
