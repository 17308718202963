import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Alert, Input } from "antd";

const NewNote = ({ createNote }) => {
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const onCancel = () => {
    setError(null);
    setModalVisible(false);
  };

  const renderFooter = [
    <Button key="back" type="default" onClick={onCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        createNote(note);
        setModalVisible(false);
      }}
    >
      Submit
    </Button>,
  ];

  return (
    <div className="new-note">
      <Button className="new-note-button" onClick={() => setModalVisible(true)}>
        + Add Note
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={onCancel}
        title="Create New Note"
        footer={renderFooter}
        destroyOnClose
        width="528px"
      >
        <div className="new-address-modal">
          {error ? (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ margin: "0 0 8px 0" }}
            />
          ) : null}
          <Input
            placeholder="Enter note here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

NewNote.propTypes = {
  createNote: PropTypes.func.isRequired,
};

export default NewNote;
