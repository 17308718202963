import React, { useEffect } from "react";

//components
import DetailsAndNotes from "../../../../components/fulfillment/order/DetailsAndNotes";
import { notification } from "antd";

//hooks
import { useOrderUpdate } from "../../../../hooks/api/fulfillment/orders";

// Libs
import { objectToFormData } from "object-to-formdata";
import Axios from "axios";

//types
import { Fulfillment, Common } from "@secondcloset/types";
import { UploadFile } from "antd/lib/upload/interface";
type Order = Fulfillment.Order;
type File = Common.File;

interface Props {
  order?: Order;
  loading: boolean;
  onUpdateSuccess: () => any;
}

const OrderInfo: React.FC<Props> = ({ order, loading, onUpdateSuccess }) => {
  const { orderUpdateDetails, updateOrder } = useOrderUpdate();
  const error = orderUpdateDetails.error;

  useEffect(() => {
    if (error) notification.error({ message: error });
  }, [error]);

  const orderID = order?.id || "";
  const address = order?.address;
  const customer = order?.customer;
  const externalOrderNumber = order?.external_order_number || "-";
  const shippingService = order?.shipping_service || "-";
  const marketplaceNotes = order?.notes;
  const internalNotes = order?.internal_notes;
  const fulfilledFrom = order?.fulfilled_from || "-";
  const insuranceValue = +(order?.insurance_value || 0);
  const organizationID = order?.organization?.id;
  const createdDate = order?.created_at;
  const files = order?.files;

  const updateCurrentOrder = async (body: object) => {
    if (orderID) {
      const order = await updateOrder(orderID, body);
      if (order) onUpdateSuccess();
    }
  };

  const onUpdateInternalNotes = (newNotes: string) => {
    const body = {
      orders: { internal_notes: newNotes },
    };
    updateCurrentOrder(body);
  };

  const onFileUpload = (file: UploadFile) => {
    const body = objectToFormData({
      orders: {
        files_attributes: { file: file?.originFileObj },
      },
    });
    updateCurrentOrder(body);
  };

  const onFileRemove = (file: File) => {
    const body = {
      orders: {
        files_attributes: [{ id: file.id, _destroy: true }],
      },
    };
    updateCurrentOrder(body);
  };

  const onFileDownload = async (file: File, fileName: string) => {
    if (!file) return;
    const response = await Axios.get(file.url, {
      headers: {
        "Content-Type": "application/octet-stream",
        "Content-Disposition": `attachment; filename="${fileName}"`,
      },
      responseType: "blob",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  const getMarketplaceNotesTitle = () => {
    switch (order?.platform) {
      case "shopify":
        return "Shopify Notes";
      case "woo_commerce":
        return "WooCommerce Notes";
      case "shipstaion":
        return "Shipstation Notes";
      default:
        return "Marketplace Notes";
    }
  };

  return (
    <DetailsAndNotes
      orderID={orderID}
      orderPlatform={order?.platform}
      address={address as Fulfillment.CustomerAddress}
      customer={customer as Fulfillment.Customer}
      shipmentTags={order?.shipment_tags}
      externalOrderNumber={externalOrderNumber}
      shippingService={shippingService}
      internalNotes={internalNotes || ""}
      marketPlaceNotes={marketplaceNotes || ""}
      marketplaceNotesTitle={getMarketplaceNotesTitle()}
      fulfilledFrom={fulfilledFrom}
      signatureRequired={order?.signature_required}
      isLoading={loading || orderUpdateDetails.loading}
      insuranceValue={insuranceValue || 0}
      organizationID={organizationID || ""}
      createdDate={createdDate}
      files={files}
      onUpdateInternalNotes={onUpdateInternalNotes}
      onFileUpload={onFileUpload}
      onFileRemove={onFileRemove}
      onFileDownload={onFileDownload}
      onUpdateSuccess={onUpdateSuccess}
    />
  );
};

export default OrderInfo;
