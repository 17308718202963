import React, { FC } from "react";

// Interfaces
import { Order } from "interfaces/warehouseReceivingOrders";
import ItemSummaryTable from "../ItemSummaryTable";

export interface ItemSummaryTableRowData {
  quantity: number;
  name: string;
  sku: string;
  scid: string;
  id: string;
}

interface Props {
  order: Order;
  newShipments: {
    incoming_shipments_product_packaging_levels_attributes: [
      {
        fulfillment_product_packaging_level_id: string;
        quantity: number;
      }
    ];
    parcel_type: "box" | "pallet";
    tracking_info: string;
    id: string;
    sku: string;
    scid: string;
    name: string;
  }[];
  shipmentsToBeUpdated: {
    [key: string]: {
      incoming_shipments_product_packaging_levels_attributes: {
        id: string;
        quantity: number;
      }[];
      tracking_info: string;
    };
  };
  shipmentsToBeDeleted: string[];
}

const OrderItemsSummaryTable: FC<Props> = ({
  order,
  newShipments,
  shipmentsToBeUpdated,
  shipmentsToBeDeleted,
}) => {
  const getQuantity = (
    id: string,
    currentQuantity: number,
    quantityOfPackages: number
  ) => {
    // Update the quantity if updating a shipment that exists in the order
    if (Object.keys(shipmentsToBeUpdated).includes(id)) {
      const shipment = order.incoming_shipments.find(
        (shipment) => shipment.id === id
      );
      if (!shipment) return currentQuantity + quantityOfPackages;
      const existingShipmentQuantity =
        shipment?.shipment_contents[0].quantity_of_packages;
      const difference = shipmentsToBeUpdated[id]
        ?.incoming_shipments_product_packaging_levels_attributes
        ? existingShipmentQuantity -
          shipmentsToBeUpdated[id]
            ?.incoming_shipments_product_packaging_levels_attributes[0].quantity
        : existingShipmentQuantity;
      return currentQuantity + quantityOfPackages - difference;
    }
    return currentQuantity + quantityOfPackages;
  };

  // Get the total quantity of each unique product in the WRO
  // Reduce all incoming shipments into a product hash
  // { [sku]: { quantity: number; name: string; sku: string; scid: string } }[]
  const itemSummaryMap = order.incoming_shipments.reduce(
    (acc, incomingShipment) => {
      const { id } = incomingShipment;
      // Each incoming shipment has shipment contents which reduces into
      // quantity here is products per packaging level
      // { [sku]: { quantity: number; name: string; sku: string; scid: string } }[]
      if (shipmentsToBeDeleted.includes(id)) return acc;

      const items = incomingShipment.shipment_contents.reduce(
        (acc, shipmentContent) => {
          const {
            product,
            quantity_of_packages,
            product_packaging_level,
          } = shipmentContent;
          const { scid } = product;
          const { sku, name } = product_packaging_level;
          const item = {
            quantity: acc[product_packaging_level.id]
              ? getQuantity(
                  id,
                  acc[product_packaging_level.id].quantity,
                  quantity_of_packages
                )
              : getQuantity(id, 0, quantity_of_packages),
            sku,
            scid,
            name,
            id: product_packaging_level.id,
          };
          acc[product_packaging_level.id] = item;
          return acc;
        },
        {} as {
          [key: string]: ItemSummaryTableRowData;
        }
      );

      // The quantity of each product in the shipment_contents is summed for all incoming_shipments
      Object.entries(items).forEach(([key, item]) => {
        if (Object.keys(acc).includes(key)) {
          acc[key] = {
            ...acc[key],
            quantity: acc[key].quantity + item.quantity,
          };
          return acc;
        }
        acc[key] = item;
      });
      return acc;
    },
    {} as {
      [key: string]: ItemSummaryTableRowData;
    }
  );

  if (newShipments.length) {
    newShipments.forEach((shipment) => {
      const { sku } = shipment;
      if (sku) {
        const id =
          shipment.incoming_shipments_product_packaging_levels_attributes[0]
            .fulfillment_product_packaging_level_id;
        const quantity =
          shipment.incoming_shipments_product_packaging_levels_attributes[0]
            .quantity;
        if (itemSummaryMap[id]) {
          itemSummaryMap[id].quantity += quantity;
        } else {
          const { scid, name } = shipment;
          itemSummaryMap[id] = {
            quantity,
            sku,
            scid,
            name,
            id,
          };
        }
      }
    });
  }

  const itemSummaryData = Object.values(itemSummaryMap);

  return (
    <div className="order-items">
      <div className="flex-col">
        <label>Item Summary</label>
      </div>
      <div className="flex-col">
        <ItemSummaryTable order={order} data={itemSummaryData} />
      </div>
    </div>
  );
};

export default OrderItemsSummaryTable;
