import { createStore, applyMiddleware, compose } from "redux";
import ReduxPromise from "redux-promise";
import ReduxThunk from "redux-thunk";
import reducers from "../reducers";
import * as Sentry from "@sentry/react";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // See options here https://tinyurl.com/y2rm73vk
});

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk, ReduxPromise),
  sentryReduxEnhancer
);

const reduxStore = createStore(reducers, enhancer);

export default reduxStore;
