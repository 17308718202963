import { useState } from "react";

// types
import { Fulfillment } from "@secondcloset/types";

//libs
import { getAPIFromDomain } from "lib/api";
import { stringify } from "query-string";
type Order = Fulfillment.Order;

const useOrderCreate = () => {
  const [data, setData] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const api = getAPIFromDomain("fulfillment");

  const createOrder = (body: any) => {
    const url = "/orders/create";
    setLoading(true);
    setError("");

    return api
      .post(url, body)
      .then((res) => {
        const order: Order = res?.data;
        setLoading(false);
        setData(order);
        return order;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const orderCreateDetails = { data, loading, error };

  return { orderCreateDetails, createOrder };
};

const useOrderUpdate = () => {
  const [data, setData] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const api = getAPIFromDomain("fulfillment");

  const updateOrder = (orderID: string, body: any) => {
    const url = `/orders/${orderID}`;
    setLoading(true);
    setError("");

    return api
      .put(url, body)
      .then((res) => {
        const order: Order = res?.data;
        setData(order);
        setLoading(false);
        return order;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        throw error;
      });
  };

  const updateLoading = (loading: boolean) => {
    setLoading(loading);
  };

  const resetOrderUpdate = () => {
    setLoading(false);
    setError("");
    setData(undefined);
  };

  const orderUpdateDetails = { data, loading, error };

  return {
    orderUpdateDetails,
    updateOrder,
    updateLoading,
    resetOrderUpdate,
  };
};

const useOrderDetails = () => {
  const [data, setData] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const api = getAPIFromDomain("fulfillment");

  const fetchOrderDetails = (orderID: string) => {
    const url = `/orders/${orderID}`;
    setLoading(true);
    setError("");

    return api
      .get(url)
      .then((res) => {
        const order: Order = res?.data;
        setData(order);
        setLoading(false);
        return order;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const resetOrderDetails = () => {
    setData(undefined);
    setLoading(false);
    setError("");
  };

  const orderDetails = { data, loading, error };

  return {
    orderDetails,
    fetchOrderDetails,
    resetOrderDetails,
  };
};

const useOrderDelete = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const api = getAPIFromDomain("fulfillment");

  const deleteOrder = (orderID: string) => {
    const url = `/orders/${orderID}/delete`;
    setLoading(true);
    setError("");

    return api
      .put(url, {})
      .then((res) => {
        setData(res?.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const orderDeleteDetails = { data, loading, error };

  return { orderDeleteDetails, deleteOrder };
};

const useOrderCancel = () => {
  const [data, setData] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const api = getAPIFromDomain("fulfillment");

  const cancelOrder = (orderID: string) => {
    const url = `/orders/${orderID}/cancel`;
    setLoading(true);
    setError("");

    return api
      .put(url, {})
      .then((res) => {
        const order: Order = res?.data;
        setData(order);
        setLoading(false);
        return order;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const orderCancelDetails = { data, loading, error };

  return { orderCancelDetails, cancelOrder };
};

interface V2OrderQuery {
  q?: string;
  organization_id?: string;
  tracking_number?: string;
  external_order_number?: string;
  direction?: string;
  field?: string;
  per_page?: number;
  page?: number;
}

const useOrdersV2 = () => {
  const [ordersV2, setOrdersV2] = useState<Order>();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [allOrdersLoading, setAllOrdersLoading] = useState(false);
  const [fetchAllOrdersSuccess, setFetchAllOrdersSuccess] = useState(false);
  const [error, setError] = useState("");

  const fetchOrdersV2 = (query: V2OrderQuery) => {
    const api = getAPIFromDomain("fulfillment", 2);
    const url = `/orders${query ? "?" + stringify(query) : ""}`;
    setLoading(true);
    return api
      .get(url)
      .then((res) => {
        if (res?.data?.data?.length === 1) {
          setOrdersV2(res?.data?.data?.[0] as Order);
          setLoading(false);
          return res?.data;
        } else {
          setError("Something went wrong: multiple orders were found");
          setLoading(false);
          return false;
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const searchOrderV2 = (query: V2OrderQuery) => {
    const api = getAPIFromDomain("fulfillment", 2);
    const url = `/orders${query ? "?" + stringify(query) : ""}`;
    setSearchLoading(true);
    return api
      .get(url)
      .then((res) => {
        setSearchLoading(false);
        if (res?.data?.data?.length === 1) {
          return res?.data?.data?.[0];
        } else {
          setError("Something went wrong: multiple orders were found");
          return false;
        }
      })
      .catch((error) => {
        setSearchLoading(false);
        setError(error);
        return false;
      });
  };

  const fetchAllOrdersV2 = (query: V2OrderQuery) => {
    const api = getAPIFromDomain("fulfillment", 2);
    const url = `/orders${query ? "?" + stringify(query) : ""}`;
    setAllOrdersLoading(true);
    setError("");
    return api
      .get(url)
      .then((res) => {
        setAllOrdersLoading(false);
        setFetchAllOrdersSuccess(true);
        return res?.data?.data;
      })
      .catch((error) => {
        setFetchAllOrdersSuccess(false);
        setAllOrdersLoading(false);
        setError(error);
        return false;
      });
  };

  return {
    ordersV2,
    loading,
    error,
    fetchOrdersV2,
    searchOrderV2,
    searchLoading,
    allOrdersLoading,
    fetchAllOrdersSuccess,
    setFetchAllOrdersSuccess,
    fetchAllOrdersV2,
  };
};

export {
  useOrderCreate,
  useOrderUpdate,
  useOrderDetails,
  useOrderDelete,
  useOrderCancel,
  useOrdersV2,
};
