import styled from "styled-components";
import colors from "css/colors";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin: 0 0 32px;
  }

  &.new-shipment {
    animation: blinkingBox 500ms 350ms;
  }

  @keyframes blinkingBox {
    0% {
      box-shadow: ${colors.YELLOW} 0px 0px 10px 0px;
    }
    49% {
      box-shadow: ${colors.YELLOW} 0px 0px 5px 0px;
    }
    50% {
      box-shadow: ${colors.YELLOW} 0px 0px 0px 0px;
    }
    99% {
      box-shadow: ${colors.YELLOW} 0px 0px 5px 0px;
    }
    100% {
      box-shadow: ${colors.YELLOW} 0px 0px 0px 0px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;
  overflow: auto;
  background-color: ${colors.WHITE_OFF};
  border-top: 1px solid ${colors.GREY_LIGHT};
  border-left: 1px solid ${colors.GREY_LIGHT};
  border-right: 1px solid ${colors.GREY_LIGHT};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

interface HeaderSectionProps {
  justifyContent?: string;
}

export const HeaderSection = styled.div<HeaderSectionProps>`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin: 0 16px 0 0;
  }

  &:first-child {
    flex: 1;
    flex-wrap: wrap;

    @media screen and (max-width: 425px) {
      margin-bottom: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    &:last-child {
      justify-content: flex-end;
    }
  }

  .ant-btn {
    padding: 8px 16px;
    height: auto;
    border-radius: 6px;

    &:not(:last-child) {
      margin: 0 16px 0 0;
    }
  }
`;

export const PalletNumber = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin: 0 48px 0 0;

  @media screen and (max-width: 426px) {
    margin-bottom: 8px;
  }
`;

export const StatusWrapper = styled.div`
  margin: 0 48px 0 0;
`;

export const TrackingNumberWrapper = styled.div`
  display: flex;

  .ant-input {
    margin: 0 0 0 4px;
    border-color: ${colors.GREY_LIGHT};
    border-radius: 6px;
  }
`;

interface TextProps {
  color?: string;
  fontWeight?: number;
  minWidth?: number;
}

export const Text = styled.div<TextProps>`
  display: flex;
  align-items: center;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "initial")};
  color: ${(props) => (props.color ? props.color : colors.CHARCOAL)};
  font-size: 12px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "initial")};
`;

interface TableWrapperProps {
  overflowAuto?: boolean;
}

export const TableWrapper = styled.div<TableWrapperProps>`
  .ant-table-wrapper {
    overflow: ${(props) => (props.overflowAuto ? "auto" : "initial")};
  }

  .ant-table-container {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .ant-table-tbody {
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 6px;
        }

        td:last-child {
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
`;

interface QuantityWrapperProps {
  color?: string;
}

export const QuantityWrapper = styled.div<QuantityWrapperProps>`
  .ant-input-number {
    width: 56px;
    border-color: ${colors.GREY_LIGHT};
    border-radius: 6px;
    background-color: ${colors.BLUE_EXTRA_LIGHT};
    font-weight: 600;
    color: ${(props) => (props.color ? props.color : colors.CHARCOAL)};
  }
`;
