import React from "react";
import { useFeatureFlags } from "../../../contextProviders/FeatureFlagsProvider";

interface Props {
  feature: Feature;
}

const FeatureFlagWrapper: React.FC<Props> = ({ feature, children }) => {
  const { isActive } = useFeatureFlags();
  return isActive(feature) ? <>{children}</> : null;
};

export default FeatureFlagWrapper;
