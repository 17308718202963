import { getAPIFromDomain } from "../lib/api";
import queryString from "query-string";

export interface Permission {
  id: string;
  feature_id: string;
  email: string;
}

export interface Feature {
  id: string;
  app_name: "SIERRA" | "BVR";
  name: string;
  permissions: Permission[];
}

export const fetchFeatures = async (
  featureName: string
): Promise<Feature[]> => {
  const api = getAPIFromDomain("bfapi");
  const query = { app_name: "SIERRA", name: featureName };
  const url = `/features?${queryString.stringify(query)}`;
  const respond = await api.get(url);
  return respond.data as Feature[];
};
