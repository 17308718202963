import React from "react";
import { Spin, Alert, Tabs } from "antd";
import { isEmpty } from "lodash-es";
import PropTypes from "prop-types";

const ClosetSelector = (props) => {
  const { selectedClosetID, onClosetSelect } = props;
  const { closetIndexWithDetails } = props;

  const { data, loading, error } = closetIndexWithDetails;

  const tabs =
    data &&
    data.map((closet) => {
      const { id, name, closet_name } = closet;
      return <Tabs.TabPane tab={closet_name || name} key={id} />;
    });

  if (!data && loading) {
    return (
      <div className="closet-filter spinning">
        <Spin tip="fetching customer closets..." />
      </div>
    );
  }

  return (
    <Spin tip="fetching customer closets..." spinning={loading}>
      <div className="closet-filter">
        {error && <Alert type="error" message={error} />}
        {!isEmpty(data) && (
          <Tabs
            activeKey={selectedClosetID}
            onChange={onClosetSelect}
            size="large"
          >
            <Tabs.TabPane tab="All" key="all-items" />
            {tabs}
            <Tabs.TabPane tab="Item Plan" key="item-plan" />
          </Tabs>
        )}
      </div>
    </Spin>
  );
};

ClosetSelector.propTypes = {
  closetIndexWithDetails: PropTypes.object.isRequired,
  selectedClosetID: PropTypes.string.isRequired,
  onClosetSelect: PropTypes.func.isRequired,
};

export default ClosetSelector;
