import React from "react";
import PropTypes from "prop-types";

const ItemFilterButton = (props) => {
  const { text, selected, count, onClick } = props;
  return (
    <div
      className={`item-filter-button ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className="button-text">{text}</div>
      <div className="button-count">{count}</div>
    </div>
  );
};

ItemFilterButton.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ItemFilterButton;
