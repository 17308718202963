import React from "react";
import PropTypes from "prop-types";

const GoBackBtn = (props) => {
  return (
    <div className="col-xs-12 rowMargin goBackBtn noPadding">
      <span onClick={props.history.goBack}>{"< Go Back"}</span>
    </div>
  );
};

export default GoBackBtn;

GoBackBtn.propTypes = {
  history: PropTypes.object.isRequired,
};
