import { getAPIFromDomain } from "../../lib/api";
import { stringify } from "query-string";
import { Fulfillment } from "@secondcloset/types";
type Shipment = Fulfillment.Shipment;

export interface ShipmentIndexQuery {
  per_page: number;
  organization_id?: string;
  page?: number;
  status?: string;
  date_to?: string;
  date_from?: string;
  billed?: boolean;
  shipment_item_actions?: string[];
  shipping_methods?: string[];
  organizations?: string[];
  with_organization?: boolean;
  with_external_order?: boolean;
  freight?: boolean;
}

interface ShipmentUpdateBody {
  label_id: string;
  tracking_number: string;
  bol_file: File;
  freight: boolean;
  facility_id: string;
}

export const fetchShipmentIndex = async (
  query: ShipmentIndexQuery
): Promise<Shipment[]> => {
  const api = getAPIFromDomain("logistics");
  const q = stringify(query, { arrayFormat: "bracket" });
  const url = `/shipments?${q}`;
  const res = await api.get(url);
  return res.data;
};

export const cancelPackageDelivery = (packageID: string) => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipment_packages/${packageID}/cancelled`;
  return api.put(url);
};

export const redeliverPackage = (packageID: string) => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipment_packages/${packageID}/redelivered`;
  return api.put(url);
};

export const updateShipment = ({
  shipmentID,
  body,
}: {
  shipmentID: string;
  body: Partial<ShipmentUpdateBody> | FormData;
}) => {
  const api = getAPIFromDomain("logistics");
  const url = `/shipments/${shipmentID}`;
  return api.put(url, body);
};
