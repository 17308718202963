import Cookies from "../lib/Cookies";
import { getAPIFromDomain } from "../lib/api";

import {
  SIGNIN_SUCCESS,
  SET_USER_INFORMATION,
  SET_AUTH_ERROR,
  SET_AUTH_LOADING,
  SET_TOKEN,
  SIGNOUT,
} from "./types";

export function signIn(email, password, new_path) {
  let data = JSON.stringify({
    user: {
      password: password,
      email: email,
    },
  });

  return function (dispatch) {
    const api = getAPIFromDomain();
    api
      .post(`/tokens`, data)
      .then((response) => {
        // successful response from the server, now check if the user is an admin.

        if (response.data.user.role === "is_admin") {
          // save the token in the cookie
          Cookies.setUserToken(response.data.token.token);
          Cookies.setUserInfo(response.data.user);
          // user is an admin
          dispatch({ type: SIGNIN_SUCCESS, payload: response.data });
        } else {
          // not an admin, call error
          dispatch(authError("Access Denied. Only Admins are allowed to access this page."));
        }
      })
      .catch((error) => {
        // something went wrong with the request
        dispatch(authError("Invalid email and/or password."));
      });
  };
}

export function signOut() {
  return {
    type: SIGNOUT,
  };
}

export function authError(message) {
  return {
    type: SET_AUTH_ERROR,
    payload: message,
  };
}

export function setAuthLoading() {
  return {
    type: SET_AUTH_LOADING,
  };
}

export function setUserToken(token) {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export function setUserInformation(info) {
  return {
    type: SET_USER_INFORMATION,
    payload: info,
  };
}
