import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";

// libs
import Organization from "../../../../../../../lib/organization";

// components
import { DateTimeSelector } from "@secondcloset/web-components";
import { Button, Spin } from "antd";

// styles
import {
  Container,
  Title,
  OrderIDWrap,
  Description,
  ButtonWrap,
} from "./styles";

// hooks
import { useShipmentAvailabilities } from "../../../../../../../hooks/api/fulfillment/shipment";
import { useScheduleShipment } from "../../../../../../../hooks/api/fulfillment/shipment";

// helpers
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";

// types
import { Fulfillment } from "@secondcloset/types";
import { notification } from "antd";
type Shipment = Fulfillment.Shipment;
type Order = Fulfillment.Order;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: Shipment;
  order: Order;
  isAdmin: boolean;
  onScheduleSuccess: () => any;
  setActiveShipment?: (shipment: Shipment) => any;
}

const SchedulingView: React.FC<Props> = ({
  shipment,
  order,
  isAdmin,
  onScheduleSuccess,
  setActiveShipment,
}) => {
  const [newDate, setNewDate] = useState<string>();
  const [newTime, setNewTime] = useState<string>();
  const {
    shipmentAvailabilities,
    fetchShipmentAvailabilities,
  } = useShipmentAvailabilities();
  const { shipmentDetails, scheduleShipment } = useScheduleShipment();
  const error = shipmentAvailabilities.error || shipmentDetails.error;

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const daysAhead = moment().add(90, "days").format("YYYY-MM-DD");
    const shipmentID = shipment?.id;
    const options = {
      shipment_id: shipmentID,
      date_from: today,
      date_to: daysAhead,
    };
    fetchShipmentAvailabilities(options);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) notification.error({ message: error });
  }, [error]);

  const shippingMethod = shipment?.shipping_method as SecondClosetShippingMethod;
  const hasAppointmentDate = !!shippingMethod?.date;
  const isReturn = shippingMethod?.job_type === "reverse_logistics";
  const orderNumber = order?.external_order_number;
  const ikeaOrgID = Organization.getIkeaOrgID();
  const isIkea = order?.organization?.id === ikeaOrgID;
  const availabilityInstance = useMemo(() => {
    return new AppointmentsAvailability(shipmentAvailabilities.data || [], {
      isAdmin,
      serviceArea: shippingMethod?.location?.service_area || "yyz",
    });
    // eslint-disable-next-line
  }, [shipmentAvailabilities.data]);

  const onSelectDate = (date: string | null) => {
    setNewDate(date || undefined);
    setNewTime(undefined);
  };

  const onSelectTime = (time: string) => {
    setNewTime(time);
  };

  const isDateDisabled = (date: string) => {
    return availabilityInstance.isDateOneDisabled(date);
  };

  const isSlotDisabled = (timeslot: string) => {
    return availabilityInstance.isSlotOneDisabled(newDate || "", timeslot);
  };

  const renderDateTimeSelector = () => {
    /**
     * The 10-22 timeslot is being filtered here
     * because it is only available for Ikea's Quebec warehouse
     */
    const fulfilledFrom = order?.fulfilled_from;
    const slots =
      fulfilledFrom === "yul1" && isIkea
        ? availabilityInstance.getTimeslots(newDate || null)
        : availabilityInstance
            .getTimeslots(newDate || null)
            .filter((slot) => slot.timeslot !== "10-22");

    return (
      <DateTimeSelector
        selectedDate={newDate}
        selectedTime={newTime}
        onSelectDate={onSelectDate}
        onSelectTime={onSelectTime}
        disabledDate={isDateDisabled}
        disabledTime={(slot) => {
          if (isAdmin && isIkea) return false;
          return isSlotDisabled(slot || "");
        }}
        slots={slots as any}
        vertical
      />
    );
  };

  const onBookDeliveryClick = async () => {
    const res = await scheduleShipment(shipment.id, newDate, newTime);
    if (res) {
      onScheduleSuccess();
      setActiveShipment && setActiveShipment(res);
    }
  };

  const renderSubmitButton = () => {
    return (
      <ButtonWrap>
        <Button
          disabled={!newDate || !newTime}
          type="primary"
          size="large"
          onClick={onBookDeliveryClick}
          loading={!!shipmentDetails.loading}
        >
          Book {isReturn ? "Return" : "Delivery"}
        </Button>
      </ButtonWrap>
    );
  };

  if (shipmentAvailabilities.loading) {
    return <Spin tip="Fetching availability..." />;
  }

  return (
    <Container>
      <Title>
        {hasAppointmentDate ? "Reschedule" : "Schedule"}{" "}
        {isReturn ? "Return" : "Delivery"} Appointment
      </Title>
      <OrderIDWrap>ORDER NUMBER: {orderNumber}</OrderIDWrap>
      <Description>Select an available date and timeslot below</Description>
      {renderDateTimeSelector()}
      {renderSubmitButton()}
    </Container>
  );
};

export default SchedulingView;
