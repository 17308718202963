import React from "react";
import PropTypes from "prop-types";
import PlusMinusInput from "./PlusMinusInput";
import { cloneDeep, isFinite } from "lodash-es";
import Products from "../../../../lib/regular/products";

const SelectNewItemsCard = (props) => {
  const attachProductIfNotExist = (product) => {
    const addedProducts = cloneDeep(props.addedProducts);
    if (!addedProducts[product.id]) {
      addedProducts[product.id] = cloneDeep(product);
      addedProducts[product.id].quantity = 0;
    }
    return addedProducts;
  };

  const addProduct = (product, quantity) => {
    const addedProducts = attachProductIfNotExist(product);
    addedProducts[product.id].quantity += +quantity;
    if (addedProducts[product.id].quantity <= 0)
      delete addedProducts[product.id];
    props.onProductChange(addedProducts);
  };

  const setProductQuantity = (product, quantity) => {
    if (!isFinite(+quantity)) quantity = 0;
    const addedProducts = attachProductIfNotExist(product);
    addedProducts[product.id].quantity = +quantity;
    if (addedProducts[product.id].quantity <= 0)
      delete addedProducts[product.id];
    props.onProductChange(addedProducts);
  };

  const buildPlusMinusInput = (product) => {
    const { name, id } = product;
    const { addedProducts } = props;
    const quantity = addedProducts[id] ? addedProducts[id].quantity : 0;
    const price = (Products.getUnitPrice(product) / 100).toFixed(2);
    return (
      <div className="product-controller" key={id}>
        <PlusMinusInput
          onMinusClick={() => addProduct(product, -1)}
          onQuantityChange={(quantity) => setProductQuantity(product, quantity)}
          onPlusClick={() => addProduct(product, 1)}
          value={quantity}
        />

        <div className="product-detail">
          <div className="product-name">{name}</div>
          <div className="product-price">${price}</div>
        </div>
      </div>
    );
  };

  const buildColumn = (list, title) => {
    return (
      <div className="product-column">
        <div className="column-title">{title}</div>
        {list.map((item) => buildPlusMinusInput(item))}
      </div>
    );
  };

  const visibleProducts = Products.getAdminVisibleProducts(props.products);
  const boxes = Products.getBoxes(visibleProducts);
  const bulkyItems = Products.getBulkyItems(visibleProducts);
  const pallets = Products.getPallets(visibleProducts);

  return (
    <div className="select-new-items-card">
      {buildColumn(boxes, "BOXES")}
      {buildColumn(bulkyItems, "BULKY ITEMS")}
      {buildColumn(pallets, "PALLETS")}
    </div>
  );
};

SelectNewItemsCard.propTypes = {
  products: PropTypes.array.isRequired,
  addedProducts: PropTypes.object.isRequired,
  onProductChange: PropTypes.func.isRequired,
};

export default SelectNewItemsCard;
