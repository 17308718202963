import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../../../components/regular/header/Header";
import { signOut } from "../../../actions";
import { setActiveFacility } from "../../../actions/facility/facility";

const mapStateToProps = ({ auth, activeFacility, facilityList }) => ({
  auth,
  activeFacility,
  facilityList: facilityList.data,
});

export default withRouter(
  connect(mapStateToProps, { signOut, setActiveFacility })(Header)
);
