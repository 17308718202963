import React, { useState, useEffect, useRef, useMemo } from "react";

// Components
import { DatePicker, Select, Button } from "antd";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import {
  ShipmentsTable,
  CheckboxDropdown,
  SearchOrganizationInput,
} from "@secondcloset/web-components";
import UploadCsvButton from "./UploadCsvButton";
import DownloadCsvButton from "./DownloadCsvButton";

// Hooks
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import useUrlState from "hooks/application/useUrlState";
import { useFeatureAccess } from "../../../hooks/application/useFeatureAccess";
import { useOrganizationContext } from "../../../contextProviders/organization/OrganizationProvider";

// Helpers
import { debounce } from "lodash-es";
import moment, { Moment } from "moment";
import { Link } from "react-router-dom";
import {
  fetchShipmentIndex,
  ShipmentIndexQuery,
} from "../../../api/logistics/shipment";
import { fetchOrganizationIndex } from "../../../api/accounts/organization";

enum ShipmentType {
  delivery = "Delivery",
  cross_dock_delivery = "Cross Dock Delivery",
  reverse_logistics = "Reverse Logistics",
  inventory_pick_up = "Inventory Pick Up",
  return_to_sender = "Return To Sender",
}

enum ShipmentMethod {
  external_carrier_shipment = "Parcel",
  appointment = "Bolt Logistics Last Mile",
  freight = "3rd Party Freight",
  untracked_shipment = "Untracked Shipment",
}

const ShipmentIndexPage: React.FC = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const organizationDetails = useOrganizationContext();
  const isCsvAccessEnabled = useFeatureAccess("Shipment CSV Download");
  const isFirstMountRef = useRef(true);
  const { organizationID } = useParams<{ organizationID?: string }>();
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [searchedOrganization, setSearchedOrganization] = useState("");
  const [selectedStatus, setSelectedStatus] = useUrlState({
    name: "status",
  });
  const [dateFromTo, setDateFromTo] = useUrlState({
    name: "date_from_to",
    initialValue: [],
  });
  const [page, setPage] = useUrlState({ name: "page", initialValue: 1 });
  const [pageSize, setPageSize] = useUrlState({
    name: "page_size",
    initialValue: 10,
  });
  const [appointmentTypesFilter, setAppointmentTypesFilter] = useUrlState({
    name: "appointmentTypes",
    initialValue: [],
  });
  const [shippingMethodsFilter, setShippingMethodsFilter] = useUrlState({
    name: "shippingMethods",
    initialValue: [],
  });

  const [organizations, setOrganizations] = useUrlState({
    name: "organizations",
  });

  const queryOptions = useMemo(() => {
    const [dateFrom, dateTo] = dateFromTo;
    const queryOption: ShipmentIndexQuery = { per_page: pageSize };
    if (organizationID) queryOption.organization_id = organizationID;
    if (page) queryOption.page = page;
    if (selectedStatus) queryOption.status = selectedStatus;
    if (dateTo) queryOption.date_to = dateTo;
    if (dateFrom) queryOption.date_from = dateFrom;
    if (appointmentTypesFilter.length > 0)
      queryOption.shipment_item_actions = appointmentTypesFilter;
    if (shippingMethodsFilter.length > 0) {
      const isFreight = shippingMethodsFilter.includes("freight");
      const isParcel = shippingMethodsFilter.includes(
        "external_carrier_shipment"
      );
      const selectedMethods = shippingMethodsFilter.filter(
        (f: string) => f !== "freight"
      );

      if (isFreight && !isParcel) {
        selectedMethods.push("external_carrier_shipment");
        queryOption.freight = true;
      }
      if (isParcel && !isFreight) queryOption.freight = false;

      queryOption.shipping_methods = selectedMethods;
    }
    if (searchedOrganization?.length > 0)
      queryOption.organizations = organizations;
    queryOption.with_organization = true;
    queryOption.with_external_order = true;

    return queryOption;
  }, [
    dateFromTo,
    pageSize,
    page,
    organizationID,
    selectedStatus,
    appointmentTypesFilter,
    shippingMethodsFilter,
    searchedOrganization,
    organizations,
  ]);

  const shipmentIndex = useQuery(
    ["shipmentIndex", queryOptions],
    () => fetchShipmentIndex(queryOptions),
    { keepPreviousData: true }
  );

  const organizationIndex = useQuery(
    ["organizationIndex", searchedOrganization],
    () => fetchOrganizationIndex({ q: searchedOrganization }),
    {
      enabled: searchedOrganization.length >= 3 && !organizationID,
    }
  );

  useEffect(() => {
    setPage(isFirstMountRef.current ? page : 1);
    isFirstMountRef.current = false;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    dateFromTo.length,
    pageSize,
    selectedStatus,
    searchedOrganization,
    JSON.stringify(appointmentTypesFilter),
    JSON.stringify(shippingMethodsFilter),
  ]);

  const loading = shipmentIndex.isLoading || organizationDetails.loading;

  const renderShipmentTypesFilter = () => {
    const options = Object.entries(ShipmentType).map(([key, label]) => {
      return {
        key,
        label,
        value: key,
      };
    });

    return (
      <div className="filter">
        <div className="label">Shipment Type:</div>
        <CheckboxDropdown
          options={options}
          selectedOptionKeys={appointmentTypesFilter}
          setSelectedOptionKeys={setAppointmentTypesFilter}
        />
      </div>
    );
  };

  const renderShipmentMethodFilter = () => {
    const options = Object.entries(ShipmentMethod).map(([key, label]) => {
      return {
        key,
        label,
        value: key,
      };
    });

    return (
      <div className="filter">
        <div className="label">Shipment Methods:</div>
        <CheckboxDropdown
          options={options}
          selectedOptionKeys={shippingMethodsFilter}
          setSelectedOptionKeys={setShippingMethodsFilter}
        />
      </div>
    );
  };

  const renderStatusFilter = () => {
    const options = [
      { label: "All", value: "all" },
      { label: "Label Required", value: "label_required" },
      { label: "Apply Label", value: "apply_label" },
      { label: "Completed", value: "completed" },
      { label: "Cancelled", value: "cancelled" },
      { label: "Failed", value: "failed" },
    ];

    return (
      <div className="filter">
        <div className="label">Shipment Status:</div>
        <Select
          value={selectedStatus || "All"}
          onChange={(v) => setSelectedStatus(v !== "all" && v)}
          style={{ minWidth: 120 }}
        >
          {options.map((opt) => {
            const { label, value } = opt;
            return (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const updateDates = (dates: any) => {
    if (!dates) return setDateFromTo([]);
    const [from, to] = dates as [Moment, Moment];
    const getDateString = (d: Moment) => d && d.format("YYYY-MM-DD");
    setDateFromTo([getDateString(from), getDateString(to)]);
  };

  const renderDatePicker = () => {
    const [dateFrom, dateTo] = dateFromTo;
    const from = dateFrom && moment(dateFrom);
    const to = dateTo && moment(dateTo);
    return (
      <div className="filter">
        <DatePicker.RangePicker
          value={[from, to]}
          format={"YYYY/MM/DD"}
          onChange={updateDates}
        />
      </div>
    );
  };

  const renderHeaderBtns = () => {
    if (!isCsvAccessEnabled) return null;
    return (
      <div className="header-btns">
        <DownloadCsvButton query={queryOptions} />
        <UploadCsvButton
          onRefetchShipments={() =>
            queryClient.invalidateQueries("shipmentIndex")
          }
        />
      </div>
    );
  };

  const renderPageSizeSelect = () => {
    const options = [
      { label: "15", value: 15 },
      { label: "30", value: 30 },
      { label: "60", value: 60 },
      { label: "100", value: 100 },
      { label: "800", value: 800 },
    ];

    return (
      <div className="filter">
        <div className="label">Page Size:</div>
        <Select value={pageSize} onChange={(v) => setPageSize(v)}>
          {options.map((opt) => {
            const { label, value } = opt;
            return (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const buildSearchOrganizationFilter = () => {
    if (organizationID) return;
    return (
      <div className="filter" style={{ width: 300 }}>
        <SearchOrganizationInput
          mode="tags"
          placeholder="Search Organization"
          loading={organizationIndex.isLoading}
          organizations={organizationIndex.data ?? []}
          defaultValue={organizations}
          onSearch={debounce(setSearchedOrganization, 800)}
          onChange={(values: Record<"label" | "value", string>[]) =>
            setOrganizations(values?.map((v) => v.label))
          }
        />
      </div>
    );
  };

  const renderFilterToggleButton = () => (
    <div className="filter" style={{ marginLeft: "auto", padding: "0" }}>
      <Button type="text" onClick={() => setIsShowFilters(!isShowFilters)}>
        <FilterOutlined /> {isShowFilters ? "Hide" : "Show"} Filters
      </Button>
    </div>
  );

  const renderHeader = () => {
    return (
      <div className="shipments-header">
        <div className="org-name-with-btns">
          {organizationDetails.data && (
            <Button
              className="organization-name"
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.push(`/organizations/${organizationID}`)}
            >
              {organizationDetails.data.name}
            </Button>
          )}
        </div>
        <div className="title-wrapper">
          <div className="title">Shipments</div>
          {renderHeaderBtns()}
        </div>
        <div className="shipment-filters">
          <div className="filter-row">
            {buildSearchOrganizationFilter()}
            {renderDatePicker()}
            {renderFilterToggleButton()}
          </div>
          {isShowFilters && (
            <div className="filter-row">
              {renderStatusFilter()}
              {renderShipmentMethodFilter()}
              {renderShipmentTypesFilter()}
              {renderPageSizeSelect()}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="page-container" id="shipment-index-page">
      {renderHeader()}
      <ShipmentsTable
        shipments={shipmentIndex.data ?? []}
        page={page}
        isLoading={loading || shipmentIndex.isPreviousData}
        renderOrderIDLinkComponent={(displayText, orderID) => (
          <Link to={`/fulfillment/orders/${orderID}`}>{displayText}</Link>
        )}
        renderOrganizationLinkComponent={(displayText, orgID) => (
          <Link to={`/organizations/${orgID}`}>{displayText}</Link>
        )}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        isAdmin
      />
    </div>
  );
};

export default ShipmentIndexPage;
