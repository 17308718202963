import * as actionTypes from "actions/fulfillment/types";
import WRO from "lib/fulfillment/WRO";

const INITIAL_STATE = {
  shipment_contents: [],
  singleTrackingNum: false,
};

const checkOneTrackingNumber = (check, state) => {
  return { ...state, singleTrackingNum: check };
};

const modifyTrackingNumber = (box, state) => {
  const { index: box_number, tracking_info } = box;
  const shipment_contents = state.shipment_contents.map((b, index) => {
    if (index === box_number) {
      return { ...b, tracking_info };
    }
    return b;
  });

  return { ...state, shipment_contents };
};

const modifyContainerNumber = (box, state) => {
  const { index: box_number, container_number } = box;
  const shipment_contents = state.shipment_contents.map((b, index) => {
    if (index === box_number) {
      return { ...b, container_number };
    }
    return b;
  });

  return { ...state, shipment_contents };
};

const modifyCarrierName = (box, state) => {
  const { index: box_number, carrier_name } = box;
  const shipment_contents = state.shipment_contents.map((b, index) => {
    if (index === box_number) {
      return { ...b, carrier_name };
    }
    return b;
  });

  return { ...state, shipment_contents };
};

const onModifyTrackingNumberForAll = (tracking_info, state) => {
  const shipment_contents = state.shipment_contents.map((box) => {
    return { ...box, tracking_info };
  });
  return { ...state, shipment_contents };
};

const onModifyContainerNumberForAll = (container_number, state) => {
  const shipment_contents = state.shipment_contents.map((box) => {
    return { ...box, container_number };
  });
  return { ...state, shipment_contents };
};

const onModifyCarrierNameForAll = (carrier_name, state) => {
  const shipment_contents = state.shipment_contents.map((box) => {
    return { ...box, carrier_name };
  });
  return { ...state, shipment_contents };
};

const constructOneProductPerBoxStructure = (productList = [], parcelType) => {
  return productList.reduce((acc, product) => {
    const {
      quantity,
      quantityPerBox,
      name,
      product_id,
      sku,
      scid,
      tracks_lot_numbers,
      lot_number,
      packagingLevel,
    } = product;
    const box = {
      parcel_type: parcelType,
      tracking_info: "",
      shipment_contents: [
        {
          name: name,
          id: product_id,
          qty: quantityPerBox,
          sku,
          scid,
          tracks_lot_numbers,
          lot_number,
          packagingLevel,
        },
      ],
      container_number: "",
      carrier_name: "",
    };
    let remainder = quantity % quantityPerBox;
    let numOfBox = 0;
    if (remainder !== 0) {
      numOfBox = Math.floor(quantity / quantityPerBox);
      const partiallyFullBox = {
        ...box,
        shipment_contents: [
          {
            name: name,
            id: product_id,
            qty: remainder,
            sku,
            scid,
            tracks_lot_numbers,
            lot_number,
            packagingLevel,
          },
        ],
      };
      const result = acc.concat(Array(numOfBox).fill(box));
      result.push(partiallyFullBox);
      return result;
    } else {
      numOfBox = quantity / quantityPerBox;
      return acc.concat(Array(numOfBox).fill(box));
    }
  }, []);
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_STEP_ADD_PRODUCTS_MODIFIED,
    WRO_STEP_SHIPMENT_DETAILS_MODIFIED,
    WRO_STEP_PACKING_DETAILS_MODIFIED,

    WRO_CHECK_TRACKING_NUMBER_ALL,
    WRO_MODIFY_PRODUCT_TRACKING_NUMBER,
    WRO_MODIFY_PRODUCT_CONTAINER_NUMBER,
    WRO_MODIFY_PRODUCT_CARRIER_NAME,
    WRO_MODIFY_PRODUCT_TRACKING_NUMBER_ALL,
    WRO_MODIFY_PRODUCT_CONTAINER_NUMBER_ALL,
    WRO_MODIFY_PRODUCT_CARRIER_NAME_ALL,
    WRO_SETUP_DATA_FOR_PACKING_SLIP,

    WRO_CREATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_STEP_ADD_PRODUCTS_MODIFIED:
    case WRO_STEP_SHIPMENT_DETAILS_MODIFIED:
    case WRO_STEP_PACKING_DETAILS_MODIFIED:
      return { ...state, shipment_contents: [] };

    case WRO_CHECK_TRACKING_NUMBER_ALL:
      return checkOneTrackingNumber(action.check, state);

    case WRO_MODIFY_PRODUCT_TRACKING_NUMBER:
      return modifyTrackingNumber(action.box, state);

    case WRO_MODIFY_PRODUCT_CONTAINER_NUMBER:
      return modifyContainerNumber(action.box, state);

    case WRO_MODIFY_PRODUCT_CARRIER_NAME:
      return modifyCarrierName(action.box, state);

    case WRO_MODIFY_PRODUCT_TRACKING_NUMBER_ALL:
      return onModifyTrackingNumberForAll(action.trackingNumber, state);

    case WRO_MODIFY_PRODUCT_CONTAINER_NUMBER_ALL:
      return onModifyContainerNumberForAll(action.containerNumber, state);

    case WRO_MODIFY_PRODUCT_CARRIER_NAME_ALL:
      return onModifyCarrierNameForAll(action.carrierName, state);

    case WRO_SETUP_DATA_FOR_PACKING_SLIP: {
      const { productList, shipmentSize } = action;
      const parcelType = WRO.isBox(shipmentSize) ? "box" : "pallet";
      const shipment_contents = constructOneProductPerBoxStructure(
        productList,
        parcelType
      );
      return { ...state, shipment_contents };
    }

    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
