import React, { Component } from "react";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Modal, Input, Button, Spin, Alert } from "antd";
import { cloneDeep } from "lodash-es";
import PropTypes from "prop-types";

import CategorySearchBar from "./CategorySearchBar";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  focusedInput: null,
  inputValues: {},
};

class EditNameModal extends Component {
  constructor(props) {
    super(props);

    const inputValues = this.getInitialInputValues(props.items);

    this.state = { ...DEFAULT_STATE, inputValues };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const inputValues = this.getInitialInputValues(this.props.items);
      this.setState({ inputValues });
    }
  }

  closeModal = () => {
    const { onCancel, onDone, clearModalData, updateComplete } = this.props;

    clearModalData();
    if (updateComplete) return onDone();
    onCancel();
  };

  getInitialInputValues = (items) =>
    items.reduce((acc, i) => {
      const { description } = i;

      acc[i.item_id] = { categoryItemId: "", description: description || "" };
      return acc;
    }, {});

  setCategoryId = (itemId, categoryId) => {
    const inputValues = cloneDeep(this.state.inputValues);
    inputValues[itemId].categoryItemId = categoryId;
    this.setState({ inputValues });
  };

  setDescription = (itemId, description) => {
    const inputValues = cloneDeep(this.state.inputValues);
    inputValues[itemId].description = description;
    this.setState({ inputValues });
  };

  buildTableRow = (item) => {
    const { item_id, qr_code } = item;
    const { inputValues } = this.state;
    const { updateComplete, updateResults } = this.props;
    if (!inputValues[item_id]) return null;

    const updateSuccess = updateResults[item_id] === "success";

    return (
      <tr key={item_id}>
        <td>
          <CategorySearchBar
            selectedCategoryItemId={inputValues[item_id].categoryItemId}
            onSelectItem={(categoryItemId) =>
              this.setCategoryId(item_id, categoryItemId)
            }
            clearSelectedItem={() => this.setCategoryId(item_id, null)}
          />
        </td>
        <td>{qr_code || "-"}</td>
        <td className="description-column">
          <Input
            value={inputValues[item_id].description}
            onChange={(e) => this.setDescription(item_id, e.target.value)}
          />
        </td>
        {updateComplete && (
          <td className="status-column">
            {updateSuccess ? (
              <CheckCircleFilled className="green" />
            ) : (
              <CloseCircleFilled className="red" />
            )}
          </td>
        )}
      </tr>
    );
  };

  buildTable = () => {
    const { items, updateComplete } = this.props;

    return (
      <table>
        <colgroup>
          <col />
          <col width="100px" />
          <col />
          {updateComplete && <col />}
        </colgroup>
        <thead>
          <tr>
            <th>Category Name</th>
            <th>QR Code</th>
            <th className="description-column">Description</th>
            {updateComplete && <th>Status</th>}
          </tr>
        </thead>
        <tbody>{items.map(this.buildTableRow)}</tbody>
      </table>
    );
  };

  buildItemList = () => {
    const {
      userId,
      items,
      editNames,
      updateLoading,
      updateComplete,
      updateResults,
    } = this.props;
    const { inputValues } = this.state;
    const itemIds = items.map((i) => i.item_id);
    const onSubmitClick = updateLoading
      ? () => {}
      : () => editNames(userId, itemIds, inputValues);
    const resultsHaveError = Object.values(updateResults).some(
      (r) => r !== "success"
    );

    const submitButton = (
      <Button onClick={onSubmitClick}>
        {updateLoading ? <Spin /> : "Submit"}
      </Button>
    );

    const doneButton = (
      <Button type="primary" onClick={this.closeModal}>
        Done
      </Button>
    );

    return (
      <>
        {this.buildTable()}
        {resultsHaveError && (
          <Alert
            type="error"
            message="Some items failed to update, please check the status column"
          />
        )}
        <div className="flex-row">
          {updateComplete ? doneButton : submitButton}
        </div>
      </>
    );
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        className="appointment-edit-name-modal"
        visible={visible}
        onCancel={this.closeModal}
        title="Edit Item Name"
        footer={null}
        destroyOnClose
      >
        {this.buildItemList()}
      </Modal>
    );
  }
}

EditNameModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  updateLoading: PropTypes.bool.isRequired,
  updateResults: PropTypes.object.isRequired,
  updateComplete: PropTypes.bool.isRequired,

  editNames: PropTypes.func.isRequired,
  clearModalData: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default EditNameModal;
