import styled from "styled-components";
import colors from "../../../../../../../css/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 25px;
`;

export const OrderIDWrap = styled.div`
  color: ${colors.GREY};
  margin-top: 10px;
`;

export const IconWrap = styled.div`
  margin-top: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.GREEN};
  border-radius: 100%;

  .check-icon {
    color: white;
    font-size: 25px;
  }
`;

export const SuccessMsg = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  span {
    color: ${colors.GREEN};
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 20px;
`;

export const AppointmentDetailsWrap = styled.div`
  width: 100%;
  margin-top: 20px;
  background: #fbfbfb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 150px;
`;

export const ChunkWrap = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const LeftChunk = styled(ChunkWrap)`
  align-items: flex-end;
  color: ${colors.GREY};
`;

export const AppointmentAddressWrap = styled.div`
  margin-top: 20px;
  text-align: center;

  .line1 {
    color: ${colors.GREEN};
    font-size: 16px;
  }

  .line2 {
    color: ${colors.GREY};
  }
`;

export const Footer = styled.div`
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  color: ${colors.GREY};
`;
