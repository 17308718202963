import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useCustomerItemCsv } from "../../../hooks/api/regular/customer";
import { QrcodeOutlined } from "@ant-design/icons";
import { Button, Spin, InputNumber, Modal } from "antd";

const DownloadQrCodesButton = (props) => {
  const [qrCodeModalVisible, setQRcodeModalVisible] = useState(false);
  const [numOfCodes, setNumOfCodes] = useState(0);
  const { customerItemCsv, fetchCustomerItemCsv } = useCustomerItemCsv();

  const downloadCsv = (csvData) => {
    const csvString = csvData
      .split("\n")
      .filter(Boolean)
      .map((s) => `"${s}"`)
      .join("\n");

    const csvContent = "data:text/csv;charset=utf-8," + csvString;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "qrcodes.csv");
    document.body.appendChild(link);
    link.click();
  };

  const onCustomerItemCsvChange = () => {
    if (customerItemCsv.data) {
      setQRcodeModalVisible(false);
      downloadCsv(customerItemCsv.data);
    }
  };

  const resetNumOfCodes = () => {
    const _numOfCodes = props.initialNumberOfCodes || 1;
    setNumOfCodes(_numOfCodes);
  };

  useEffect(resetNumOfCodes, []);
  useEffect(onCustomerItemCsvChange, [customerItemCsv.data]);

  const buildQRcodeModal = () => {
    return (
      <Modal
        title="Download QR codes"
        visible={qrCodeModalVisible}
        onCancel={() => {
          resetNumOfCodes();
          setQRcodeModalVisible(false);
        }}
        onOk={() => fetchCustomerItemCsv(props.userID, numOfCodes)}
      >
        <Spin spinning={customerItemCsv.loading}>
          <div>Number of Codes:</div>
          <InputNumber
            value={numOfCodes}
            onChange={(value) => setNumOfCodes(value)}
          />
        </Spin>
      </Modal>
    );
  };

  return (
    <div>
      <Button
        onClick={() => setQRcodeModalVisible(true)}
        loading={customerItemCsv.loading}
      >
        Download QR Codes <QrcodeOutlined />
      </Button>
      {buildQRcodeModal()}
    </div>
  );
};

DownloadQrCodesButton.propTypes = {
  initialNumberOfCodes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  userID: PropTypes.string.isRequired,
};

export default DownloadQrCodesButton;
