import { get } from "lodash-es";

const ENDY_ORG_IDS = [
  "b3db63e4-d3eb-11e9-9873-cbd4e05ea08f", // demo
  "ee31c32c-0fc2-11ea-bc79-47205e425e53", // staging
  "896584ca-0f35-11ea-95b2-e7cdcc9932f6", // production
];

const IKEA_ORG_IDS = {
  staging: "1cc0c40a-43a2-11ea-94b5-df8fd7904ab8",
  demo: "b6761236-4e0a-11ea-bb43-bb7704a595f2",
  production: "4eb2d36c-4e70-11ea-8ee7-f39aa09266cc",
};

const HAMUQ_ORG_IDS = [
  "49f2b92e-a0ea-11ea-9def-cb356a000d64", // production
  "99612bd8-a11e-11ea-a6dc-eb9a42afb5f8", // staging
];

const SAMARA_ORG_IDS = [
  "300bec90-0e29-11eb-96ce-a7597249f34f", // staging2
  "954b4962-fe79-11ea-8961-abc8b231e3da", // production
];

export default class Organization {
  static isSamara = (organization) => {
    const orgID = get(organization, "id");
    return SAMARA_ORG_IDS.includes(orgID);
  };

  static isSamaraId = (organizationId) => {
    return SAMARA_ORG_IDS.includes(organizationId);
  };

  static isEndy = (organization) => {
    const orgID = get(organization, "id");
    return ENDY_ORG_IDS.includes(orgID);
  };

  static isIKEA = (organization) => {
    const organizationID = get(organization, "id");
    return Organization.isIkeaOrgID(organizationID);
  };

  static isSecondCloset = (organization) => {
    return get(organization, "name", "") === "Second Closet";
  };

  static isBoltLogistics = (organization) => {
    return get(organization, "name", "").toLowerCase() === "bolt logistics";
  };

  static isIkeaOrgID = (organizationID) => {
    return Object.values(IKEA_ORG_IDS).includes(organizationID);
  };

  static getIkeaOrgID = () => {
    const baseURL = process.env.REACT_APP_PROD_API;
    if (baseURL?.includes("staging")) {
      return IKEA_ORG_IDS.staging;
    } else if (baseURL?.includes("preprod")) {
      return IKEA_ORG_IDS.demo;
    } else if (baseURL?.includes("secondcloset")) {
      return IKEA_ORG_IDS.production;
    } else return IKEA_ORG_IDS.staging;
  };

  static isHamuq = (organization) => {
    const organizationID = get(organization, "id");
    return HAMUQ_ORG_IDS.includes(organizationID);
  };

  static isVessi = (organization) => {
    return organization?.name?.toLowerCase().includes("vessi");
    // TODO: drop string check once we have org IDs
    // const organizationID = get(organization, "id");
    // return VESSI_ORG_IDS.includes(organizationID);
  };

  static getDefaultFulfilledFromFacility = (organization) => {
    const configurations = get(organization, "configurations");
    const fulfilledFromObj = configurations.find(
      (config) => config.key === "default_fulfilled_from"
    );
    return fulfilledFromObj ? fulfilledFromObj.value : "yyz3";
  };
}
