import { getAPIFromDomain } from "../../lib/api";
import { stringify } from "query-string";
import { Storage } from "@secondcloset/types";

export const fetchAppointmentIndex = async (
  query: Record<string, unknown>
): Promise<Storage.Appointment[]> => {
  const api = getAPIFromDomain();
  const url = `/appointments${query ? "?" + stringify(query) : ""}`;
  const res = await api.get(url);
  return res.data;
};
