import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Alert, notification } from "antd";
import { get } from "lodash-es";
import { AddressLookup } from "@secondcloset/web-components";

const NewAddressButton = ({ createAddress, createAddressError }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setError(null);
  }, [selectedAddress]);

  useEffect(() => {
    if (error) document.querySelector(".new-address-modal").scrollTop = 0;
  }, [error]);

  useEffect(() => {
    if (createAddressError)
      notification.error({
        message: "Error",
        description: createAddressError,
      });
  }, [createAddressError]);

  const onClear = () => {
    setSelectedAddress(null);
    setError(null);
    setModalVisible(false);
  };

  const onFormSubmit = () => {
    if (
      !get(selectedAddress, "phoneNumber") ||
      get(selectedAddress, "phoneNumber.length") === 0
    ) {
      return setError("A phone number is required");
    }

    if (
      get(selectedAddress, "phoneNumber.length") > 0 &&
      get(selectedAddress, "phoneNumber.length") < 10
    ) {
      return setError("Phone number must be up to 10 digits");
    }

    if (!get(selectedAddress, "buildingType")) {
      return setError("Please select residence type");
    }

    const phoneNumber = get(selectedAddress, "")
      ? `${get(selectedAddress, "phoneNumber")} x${get(selectedAddress)}`
      : get(selectedAddress, "phoneNumber");

    const address = {
      country: get(selectedAddress, "country"),
      province: get(selectedAddress, "province"),
      city: get(selectedAddress, "city"),
      address: `${get(selectedAddress, "streetNumber")} ${get(
        selectedAddress,
        "streetName"
      )}`,
      postal_code: get(selectedAddress, "postalCode"),
      building_type: get(selectedAddress, "buildingType"),
      elevator_access: get(selectedAddress, "elevatorAccess", false),
      is_primary: false,
      apartment_number: get(selectedAddress, "unit"),
      phone_number: phoneNumber,
    };

    createAddress(address);
  };

  const renderFooter = selectedAddress
    ? [
        <Button key="back" type="default" onClick={onClear}>
          Clear
        </Button>,
        <Button key="submit" type="primary" onClick={onFormSubmit}>
          Submit
        </Button>,
      ]
    : null;

  return (
    <div id="new-address-button">
      <Button
        className="new-address-button"
        onClick={() => setModalVisible(true)}
      >
        + Add Address
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          onClear();
        }}
        title="Create New Address"
        footer={renderFooter}
        destroyOnClose
        width="528px"
      >
        <div className="new-address-modal">
          {error ? (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ margin: "0 0 8px 0" }}
            />
          ) : null}
          <AddressLookup
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
          />
        </div>
      </Modal>
    </div>
  );
};

NewAddressButton.propTypes = {
  createAddress: PropTypes.func.isRequired,
  createAddressError: PropTypes.string,
  serviceArea: PropTypes.string,
};

export default NewAddressButton;
