import React, { FC, ReactText } from "react";

// Components
import { DatePicker, Select } from "antd";
import BillOfLading from "../BillOfLading";

// Interfaces
import { Order } from "interfaces/warehouseReceivingOrders";
import { FacilityCode } from "@secondcloset/types/src/_common";

// Libs
import moment, { Moment } from "moment";
import WRO from "lib/fulfillment/WRO";
import { Facility } from "@secondcloset/fulfillment-utils";

interface Props {
  order: Order;
  fetchOrder: () => void;
  isSingleTracking: boolean;
  isEditing: boolean;
  newEstimatedArrivalDate: Moment | null;
  setNewEstimatedArrivalDate: (newEstimatedArrivalDate: Moment | null) => void;
  newDestinationWarehouse: FacilityCode | null;
  setNewDestinationWarehouse: (warehouseCode: FacilityCode) => void;
}

const WRODetailsSection: FC<Props> = ({
  order,
  fetchOrder,
  isSingleTracking,
  isEditing,
  newEstimatedArrivalDate,
  setNewEstimatedArrivalDate,
  newDestinationWarehouse,
  setNewDestinationWarehouse,
}) => {
  const shipmentSize = order.shipment_size;
  const isBox = WRO.isBox(shipmentSize);

  const buildPackageAndProductColumn = () => {
    const numOfBoxesPallets = order.incoming_shipments.length;
    const totalNumberOfProducts = order.incoming_shipments.reduce(
      (acc, incomingShipment) => {
        const productsInIncomingShipment = incomingShipment.shipment_contents.reduce(
          (acc, shipment) => {
            return acc + shipment.quantity_of_packages;
          },
          0
        );
        return acc + productsInIncomingShipment;
      },
      0
    );

    return [
      {
        label: "TOTAL # OF PRODUCTS",
        value: totalNumberOfProducts,
      },
      {
        label: `TOTAL # OF ${isBox ? "BOXES" : "PALLETS"}`,
        value: numOfBoxesPallets,
      },
    ];
  };

  const renderTrackingNumber = () => {
    if (isSingleTracking) return order.incoming_shipments[0].tracking_info;
    else return "Multiple";
  };

  const columnDetails = [
    [
      {
        label: "WAREHOUSE",
        value: (
          <Select
            disabled={!isEditing}
            value={
              isEditing
                ? newDestinationWarehouse || order.destination_warehouse
                : order.destination_warehouse
            }
            onChange={(value) =>
              setNewDestinationWarehouse(value as FacilityCode)
            }
          >
            {Facility.getFulfillmentFacilityList().map((facility) => (
              <Select.Option key={facility.code} value={facility.code}>
                {facility.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      // {
      //   label: "DELIVERY TYPE",
      //   value: startCase(order.shipment_size),
      // },
      {
        label: "BILL OF LADING",
        value: (
          <BillOfLading
            wro={order}
            onUploadSuccess={() => {
              fetchOrder();
            }}
          />
        ),
      },
    ],
    [
      {
        label: "ESTIMATED ARRIVAL DATE",
        value: (
          <DatePicker
            disabled={!isEditing}
            value={
              isEditing
                ? newEstimatedArrivalDate || moment(order.arrival_date)
                : moment(order.arrival_date)
            }
            onChange={(date) => setNewEstimatedArrivalDate(date)}
          />
        ),
      },
      {
        label: "TRACKING NUMBER",
        value: renderTrackingNumber(),
      },
    ],
    buildPackageAndProductColumn(),
    [
      {
        label: "ORDER TYPE",
        value: "Manual",
      },
    ],
  ];

  const buildWRODetail = (
    row: {
      label: string;
      value: JSX.Element | ReactText;
    },
    index: number
  ) => (
    <div key={index} className="shipment-detail-wrapper">
      <label className="shipment-detail-label">{row.label}</label>
      <span className="shipment-detail">{row.value}</span>
    </div>
  );

  const buildColumn = (
    rows: { label: string; value: JSX.Element | ReactText }[],
    index: number
  ) => (
    <div key={index} className="shipment-summary-column">
      {rows.map(buildWRODetail)}
    </div>
  );

  return <>{columnDetails.map(buildColumn)}</>;
};

export default WRODetailsSection;
