/**
 * function (array) -> array
 *
 * this function remove products that no need to show on the client side.
 * eg: "dummy-plan-that-costs-00" item should be excluded
 */

export const excludeProducts = (ProductList) => {
  let result = ProductList;
  if (ProductList && ProductList.length) {
    result = ProductList.filter((obj) => obj.metadata.hidden === "false");
  }

  return result;
};
