import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { LeftOutlined } from "@ant-design/icons";
import { get } from "lodash-es";

const CustomerInfo = (props) => {
  const history = useHistory();
  const orderNumber = get(props.order, "number", "-");
  const userID = get(props.order, "user_id");
  const firstName = get(props.order, "user_firstname", "-");
  const lastName = get(props.order, "user_lastname", "-");
  const email = get(props.order, "user_email", "-");

  if (!props.order) return null;

  const goBack = () => {
    history.goBack();
  };

  const goToCustomerDetails = () => {
    history.push(`/customers/${userID}`);
  };

  return (
    <div className="order-edit-customer-info">
      <div className="back-btn" onClick={goBack}>
        <LeftOutlined /> go back
      </div>

      <div className="order-number">Order #: {orderNumber}</div>
      <div className="customer-info">
        <div
          className="name"
          onClick={goToCustomerDetails}
        >{`${firstName} ${lastName}`}</div>
        <div className="email">Email: {email}</div>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default CustomerInfo;
