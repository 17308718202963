import React from "react";
import PropTypes from "prop-types";
import NewCustomAppointmentButton from "../../../../components/regular/appointment/NewCustomAppointmentButton";
import { isEmpty, startCase } from "lodash-es";
import Address from "../../../../lib/regular/address";
import { AvailabilitySlot } from "@secondcloset/logistics-utils";
import { getAppointmentColor } from "../../../../helperFunctions";
import { PaperClipOutlined } from "@ant-design/icons";
import { Tag, Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const AppointmentDetails = (props) => {
  const { appointments } = props.order.data;
  if (isEmpty(appointments))
    return <div className="empty-appointments">No Appointments</div>;

  const renderTitle = () => {
    const { id: orderID, appointments, user_id: userID } = props.order.data;
    const { customer_segment } = appointments[0];
    return (
      <div className="title">
        Appointment Details{" "}
        <NewCustomAppointmentButton
          userID={userID}
          orderID={orderID}
          customerSegment={customer_segment}
          onCreateSuccess={props.onCreateAppointmentSuccess}
        />
      </div>
    );
  };

  const renderAppointment = (appt) => {
    const {
      number,
      status,
      job_type,
      address,
      date,
      timerange,
      id: apptID,
    } = appt;
    const jobTypeColor = getAppointmentColor("type", job_type);
    const statusColor = getAppointmentColor("status", status);

    return (
      <div className="appointment" key={apptID}>
        <div className="info">
          <div className="label-value-row">
            <div className="appt-label">Appt No: </div>
            <div className="appt-value">
              <Link to={`/appointments/${apptID}`}>#{number}</Link>
            </div>
          </div>
          <div className="label-value-row">
            <div className="appt-label">Type: </div>
            <div className="appt-value">
              <Tag color={jobTypeColor}>{startCase(job_type)}</Tag>
            </div>
          </div>
          <div className="label-value-row">
            <div className="appt-label">Status: </div>
            <div className="appt-value">
              <Tag color={statusColor}>{startCase(status)}</Tag>
            </div>
          </div>
          <div className="label-value-row">
            <div className="appt-label">Address: </div>
            <div className="appt-value">
              {Address.getOneLineAddress(address)}
            </div>
          </div>
          <div className="label-value-row">
            <div className="appt-label">Date time: </div>
            <div className="appt-value">
              {moment(date).format("ll")} (
              {AvailabilitySlot.getDisplaySlot(timerange)})
            </div>
          </div>
        </div>
        <Button
          href={`/manifest/appointment/${apptID}`}
          target="_blank"
          size="small"
        >
          Manifest <PaperClipOutlined />
        </Button>
      </div>
    );
  };

  return (
    <div className="appointment-details card">
      {renderTitle()}
      {appointments.map((appt) => renderAppointment(appt))}
    </div>
  );
};

AppointmentDetails.propTypes = {
  order: PropTypes.object.isRequired,
  onCreateAppointmentSuccess: PropTypes.func.isRequired,
};

export default AppointmentDetails;
