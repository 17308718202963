import React, { useCallback, useEffect, useState } from "react";
import {
  PackageDetails,
  getReturnablePackages,
} from "@secondcloset/web-components";
import { Checkbox, Space, Typography } from "antd";

// hooks & utils
import useShipmentCreateFlow from "../../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import { isEmpty } from "lodash-es";

// styles
import * as S from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type Shipment = Fulfillment.Shipment;
type ShipmentItem = Fulfillment.ShipmentItem;

const PackageDetailsStep: React.FC = () => {
  const {
    order,
    selectedOrderItemIDs,
    isReturnWithOriginalPackage,
    isPartialReturn,
    isEndy,
    dispatchSetPackageDetails,
    dispatchResetPackageDetails,
    dispatchSetReturnWithOriginalPackage,
  } = useShipmentCreateFlow();
  const [isReturnProductAsIs, setIsReturnProductAsIs] = useState(isEndy);
  const packages = getReturnablePackages(order.shipments);

  const constructShipmentItemIdToOrderItemId = useCallback(() => {
    type ShipmentToOrderID = Record<string, string>;
    const shipmentItems = order.shipments.flatMap(
      (s: Shipment) => s.shipment_items
    );
    return shipmentItems.reduce((acc: ShipmentToOrderID, cur: ShipmentItem) => {
      return { ...acc, [cur.id]: cur.order_item_id };
    }, {} as ShipmentToOrderID);
  }, [order.shipments]);

  useEffect(() => {
    if (isEndy) return;
    const hasNoReturnablePackage = isEmpty(packages);
    if (isReturnWithOriginalPackage === undefined)
      dispatchSetReturnWithOriginalPackage(!hasNoReturnablePackage);
    if (isPartialReturn || hasNoReturnablePackage)
      dispatchSetReturnWithOriginalPackage(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isPartialReturn, packages, isReturnWithOriginalPackage, isEndy]);

  useEffect(() => {
    if (isEndy) {
      if (isReturnProductAsIs) {
        dispatchSetReturnWithOriginalPackage(false);
      } else {
        dispatchSetReturnWithOriginalPackage(isReturnWithOriginalPackage);
      }
    }
  }, [isReturnProductAsIs, isEndy, selectedOrderItemIDs]);

  useEffect(() => {
    if (!isReturnWithOriginalPackage) return;
    const shipmentToOrderID = constructShipmentItemIdToOrderItemId();
    const packageDetails = packages.reduce((acc, cur) => {
      if (!cur.shipment_item_ids) return acc;
      return [
        ...acc,
        {
          length_value: cur.length_value,
          width_value: cur.width_value,
          height_value: cur.height_value,
          weight_value: cur.weight_value,
          length_unit: "inches",
          width_unit: "inches",
          height_unit: "inches",
          weight_unit: "lb",
          order_item_ids: cur.shipment_item_ids.map(
            (i) => shipmentToOrderID[i]
          ),
          package_type: cur.package_type ?? "box",
        },
      ];
    }, [] as Partial<Fulfillment.Package>[]);
    dispatchSetPackageDetails(packageDetails);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isReturnWithOriginalPackage]);

  const buildReturnAsIsCheckbox = () => {
    if (!isEndy) return;
    return (
      <Checkbox
        checked={isReturnProductAsIs}
        onChange={(e) => {
          setIsReturnProductAsIs(e.target.checked);
        }}
      >
        <Space direction="vertical">
          <Typography.Text>Return product as-is</Typography.Text>
          <Typography.Text type="secondary" style={{ fontWeight: "normal" }}>
            Customer is returning product individually. (A label will be
            generated per product)
          </Typography.Text>
        </Space>
      </Checkbox>
    );
  };

  const buildProductDetails = () => {
    if (isReturnProductAsIs && isEndy) return;
    return (
      <S.PackageDetailsWrapper isPadded={!isReturnProductAsIs}>
        <PackageDetails
          shipmentPackages={packages}
          selectedOrderItemIDs={selectedOrderItemIDs}
          isPartialReturn={isPartialReturn}
          isReturnWithOriginalPackage={isReturnWithOriginalPackage}
          setReturnWithOriginalPackage={dispatchSetReturnWithOriginalPackage}
          setPackageDetails={dispatchSetPackageDetails}
          resetPackageDetails={dispatchResetPackageDetails}
        />
      </S.PackageDetailsWrapper>
    );
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {buildReturnAsIsCheckbox()}
      {buildProductDetails()}
    </Space>
  );
};

export default PackageDetailsStep;
