import {
  CUSTOMERS_FETCHING,
  ORDERS_FETCHING,
  APPOINTMENT_FETCHING,
} from "../actions";

const INITIAL_STATE = {
  customerFetching: false,
  orderFetching: false,
  appointmentFetching: false,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case CUSTOMERS_FETCHING:
      return { ...state, customerFetching: payload };
    case ORDERS_FETCHING:
      return { ...state, orderFetching: payload };
    case APPOINTMENT_FETCHING:
      return { ...state, appointmentFetching: payload };
    default:
      return state;
  }
}
