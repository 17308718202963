export default class ShipmentTags {
  static getShipmentTagText = (tag) => {
    if (tag === "ready_to_fulfill") return "Ready to Fulfill";
    if (tag === "unfulfilled_items") return "Unfulfilled";
    if (tag === "processing_items") return "Processing Delivery";
    if (tag === "items_fulfilled") return "Fulfilled";
    if (tag === "items_partially_fulfilled") return "Partially fulfilled";
    if (tag === "processing_return_items") return "Processing Return";
    if (tag === "items_returned") return "Returned";
    if (tag === "internal_booking") return "Internal Booking";
    if (tag === "customer_booking") return "Customer Booking";
    if (tag === "items_removed") return "Items Removed";
    if (tag === "pending_inventory_receiving")
      return "Pending Inventory Receiving";
    else return "";
  };

  static formatShipmentTags = (tags) =>
    tags.map((tag) => ({
      label: ShipmentTags.getShipmentTagText(tag),
      value: tag,
    }));

  static getShipmentTagColor = (tag) => {
    switch (tag) {
      case "unfulfilled_items":
      case "items_removed":
        return "red";
      case "items_partially_fulfilled":
      case "ready_to_fulfill":
        return "green";
      case "items_fulfilled":
        return "blue";
      case "processing_items":
      case "processing_return_items":
        return "gold";
      case "items_returned":
        return "purple";
      case "pending_inventory_receiving":
        return "volcano";
      default:
        return;
    }
  };

  static getShipmentTagsDescriptions = () => {
    return [
      {
        tag: "unfulfilled_items",
        description:
          "There are items in your order where a delivery shipment has not been booked",
      },
      {
        tag: "ready_to_fulfill",
        description: "Items in your order are ready to be fulfilled",
      },
      {
        tag: "processing_items",
        description:
          "There are items in your order that have been booked in a delivery shipment",
      },
      {
        tag: "items_fulfilled",
        description:
          "All or some shipments in your order have been successfully delivered",
      },
      {
        tag: "processing_return_items",
        description:
          "There are items in your order that have been booked for a return",
      },
      {
        tag: "items_returned",
        description:
          "All or some return shipments in your order that have been successful received",
      },
      {
        tag: "customer_booking",
        description: "The customer needs to book an appointment",
      },
      {
        tag: "internal_booking",
        description:
          "All or some shipment items are given a range of days to be delivered",
      },
      {
        tag: "pending_inventory_receiving",
        description: "All or some shipment items need to be processed",
      },
    ];
  };
}
