export const getInvoiceStatusColor = (status) => {
  switch (status) {
    case "draft":
      return "blue";
    case "open":
      return "orange";
    case "paid":
      return "green";
    case "uncollectible":
      return "red";
    case "void":
    default:
      return "grey";
  }
};
