import React from "react";

// components
import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";

// styles
import {
  Container,
  Title,
  OrderIDWrap,
  IconWrap,
  SuccessMsg,
  ButtonWrap,
  CustomerInfoWrap,
} from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentI = Fulfillment.Shipment;
type Order = Fulfillment.Order;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: ShipmentI;
  order: Order;
  onDoneClick: () => any;
}

const ConfirmEmailSentView: React.FC<Props> = ({
  shipment,
  order,
  onDoneClick,
}) => {
  const shippingMethod = shipment?.shipping_method as SecondClosetShippingMethod;
  const isReturn = shippingMethod?.job_type === "reverse_logistics";
  const orderNumber = order?.external_order_number;
  const customerName = order?.customer?.name;
  const customerEmail = order?.customer?.email_address;

  return (
    <Container>
      <Title>Schedule {isReturn ? "Return" : "Delivery"} Appointment</Title>
      <OrderIDWrap>ORDER NUMBER: {orderNumber || "-"}</OrderIDWrap>
      <IconWrap>
        <CheckOutlined className="check-icon" />
      </IconWrap>
      <SuccessMsg>
        We have <span>successfully</span> sent an email to your customer
      </SuccessMsg>

      <CustomerInfoWrap>
        <div className="name">{customerName || "-"}</div>
        <div className="email">{customerEmail || "-"}</div>
      </CustomerInfoWrap>

      <ButtonWrap>
        <Button onClick={onDoneClick} size="large" type="primary">
          Done
        </Button>
      </ButtonWrap>
    </Container>
  );
};

export default ConfirmEmailSentView;
