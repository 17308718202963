// WAREHOUSE RECEIVING ORDER
export const WROS_REQUESTED = "WROS_REQUESTED";
export const WROS_RECEIVED = "WROS_RECEIVED";
export const WROS_ERRORED = "WROS_ERRORED";

export const WRO_DETAIL_REQUESTED = "WRO_DETAIL_REQUESTED";
export const WRO_DETAIL_RECEIVED = "WRO_DETAIL_RECEIVED";
export const WRO_DETAIL_ERRORED = "WRO_DETAIL_ERRORED";

export const WRO_DETAILS_REQUESTED = "WRO_DETAILS_REQUESTED";
export const WRO_DETAILS_RECEIVED = "WRO_DETAILS_RECEIVED";
export const WRO_DETAILS_ERRORED = "WRO_DETAILS_ERRORED";

export const WRO_DELETE_REQUESTED = "WRO_DELETE_REQUESTED";
export const WRO_DELETE_SUCCESS = "WRO_DELETE_SUCCESS";
export const WRO_DELETE_ERRORED = "WRO_DELETE_ERRORED";

// WRO CREATE
export const WRO_STEP_SELECT_LOCATION_COMPLETED =
  "WRO_STEP_SELECT_LOCATION_COMPLETED";
export const WRO_STEP_SELECT_ADDRESS_COMPLETED =
  "WRO_STEP_SELECT_ADDRESS_COMPLETED";
export const WRO_STEP_ADD_PRODUCTS_COMPLETED =
  "WRO_STEP_ADD_PRODUCTS_COMPLETED";
export const WRO_STEP_SHIPMENT_DETAILS_COMPLETED =
  "WRO_STEP_SHIPMENT_DETAILS_COMPLETED";
export const WRO_STEP_PACKING_DETAILS_COMPLETED =
  "WRO_STEP_PACKING_DETAILS_COMPLETED";
export const WRO_STEP_PACKING_SLIPS_COMPLETED =
  "WRO_STEP_PACKING_SLIPS_COMPLETED";
export const WRO_STEP_ORDER_REVIEW_COMPLETED =
  "WRO_STEP_ORDER_REVIEW_COMPLETED";

export const WRO_STEP_SELECT_LOCATION_MODIFIED =
  "WRO_STEP_SELECT_LOCATION_MODIFIED";
export const WRO_STEP_SELECT_ADDRESS_MODIFIED =
  "WRO_STEP_SELECT_ADDRESS_MODIFIED";
export const WRO_STEP_ADD_PRODUCTS_MODIFIED = "WRO_STEP_ADD_PRODUCTS_MODIFIED";
export const WRO_STEP_SHIPMENT_DETAILS_MODIFIED =
  "WRO_STEP_SHIPMENT_DETAILS_MODIFIED";
export const WRO_STEP_PACKING_DETAILS_MODIFIED =
  "WRO_STEP_PACKING_DETAILS_MODIFIED";
export const WRO_STEP_PACKING_SLIPS_MODIFIED =
  "WRO_STEP_PACKING_SLIPS_MODIFIED";
export const WRO_STEP_ORDER_REVIEW_MODIFIED = "WRO_STEP_ORDER_REVIEW_MODIFIED";

export const WRO_STEP_SELECT_LOCATION_SELECTED =
  "WRO_STEP_SELECT_LOCATION_SELECTED";
export const WRO_STEP_SELECT_ADDRESS_SELECTED =
  "WRO_STEP_SELECT_ADDRESS_SELECTED";
export const WRO_STEP_ADD_PRODUCTS_SELECTED = "WRO_STEP_ADD_PRODUCTS_SELECTED";
export const WRO_STEP_SHIPMENT_DETAILS_SELECTED =
  "WRO_STEP_SHIPMENT_DETAILS_SELECTED";
export const WRO_STEP_PACKING_DETAILS_SELECTED =
  "WRO_STEP_PACKING_DETAILS_SELECTED";
export const WRO_STEP_PACKING_SLIPS_SELECTED =
  "WRO_STEP_PACKING_SLIPS_SELECTED";
export const WRO_STEP_ORDER_REVIEW_SELECTED = "WRO_STEP_ORDER_REVIEW_SELECTED";

// WRO SELECT LOCATION
export const WRO_SELECT_LOCATION = "WRO_SELECT_LOCATION";

// WRO SELECT ADDRESS
export const WRO_SELECT_ADDRESS = "WRO_SELECT_ADDRESS";

// WRO ADD PRODUCT
export const WRO_ADD_PRODUCT_TO_CART = "WRO_ADD_PRODUCT_TO_CART";
export const WRO_MODIFY_PRODUCT_QUANTITY = "WRO_MODIFY_PRODUCT_QUANTITY";
export const WRO_UPDATE_PRODUCT_LOT_NUMBER = "WRO_UPDATE_PRODUCT_LOT_NUMBER";
export const WRO_SET_PRODUCT_LOT_NUMBER = "WRO_SET_PRODUCT_LOT_NUMBER";
export const WRO_REMOVE_PRODUCT_FROM_CART = "WRO_REMOVE_PRODUCT_FROM_CART";
export const WRO_REMOVE_ALL_PRODUCTS_FROM_CART =
  "WRO_REMOVE_ALL_PRODUCTS_FROM_CART";

// WRO SHIPMENT DETAILS
export const WRO_SELECT_SHIPMENT_SIZE = "WRO_SELECT_SHIPMENT_SIZE";
export const WRO_SELECT_SHIPMENT_PACKAGE = "WRO_SELECT_SHIPMENT_PACKAGE";
export const WRO_SELECT_SHIPMENT_DATE = "WRO_SELECT_SHIPMENT_DATE";

// WRO PACKING DETAILS
export const WRO_UPDATE_BOX_QUANTITY = "WRO_UPDATE_BOX_QUANTITY";
export const WRO_UPDATE_PRODUCT_BOX_QUANTITY =
  "WRO_UPDATE_PRODUCT_BOX_QUANTITY";
export const WRO_UPDATE_PRODUCT_SPECIFIC_BOX_QUANTITY =
  "WRO_UPDATE_PRODUCT_SPECIFIC_BOX_QUANTITY";

// WRO PACKING SLIPS
export const WRO_CHECK_TRACKING_NUMBER_ALL = "WRO_CHECK_TRACKING_NUMBER_ALL";
export const WRO_MODIFY_PRODUCT_TRACKING_NUMBER =
  "WRO_MODIFY_PRODUCT_TRACKING_NUMBER";
export const WRO_MODIFY_PRODUCT_CONTAINER_NUMBER =
  "WRO_MODIFY_PRODUCT_CONTAINER_NUMBER";
export const WRO_MODIFY_PRODUCT_CARRIER_NAME =
  "WRO_MODIFY_PRODUCT_CARRIER_NAME";
export const WRO_MODIFY_PRODUCT_TRACKING_NUMBER_ALL =
  "WRO_MODIFY_PRODUCT_TRACKING_NUMBER_ALL";
export const WRO_MODIFY_PRODUCT_CONTAINER_NUMBER_ALL =
  "WRO_MODIFY_PRODUCT_CONTAINER_NUMBER_ALL";
export const WRO_MODIFY_PRODUCT_CARRIER_NAME_ALL =
  "WRO_MODIFY_PRODUCT_CARRIER_NAME_ALL";
export const WRO_SETUP_DATA_FOR_PACKING_SLIP =
  "WRO_SETUP_DATA_FOR_PACKING_SLIP";

// WRO UPDATE
export const WRO_UPDATE_REQUESTED = "WRO_UPDATE_REQUESTED";
export const WRO_UPDATE_SUCCESS = "WRO_UPDATE_SUCCESS";
export const WRO_UPDATE_ERRORED = "WRO_DELETE_ERRORED";
export const WRO_UPDATE_RESET = "WRO_DELETE_RESET";

// WRO CREATE
export const WRO_POST_REQUESTED = "WRO_POST_REQUESTED";
export const WRO_POST_SUCCESS = "WRO_POST_SUCCESS";
export const WRO_POST_ERRORED = "WRO_POST_ERRORED";

// WRO CREATE RESET
export const WRO_CREATE_RESET = "WRO_CREATE_RESET";

export const WRO_LABEL_REQUESTED = "WRO_LABEL_REQUESTED";
export const WRO_LABEL_SUCCESS = "WRO_LABEL_SUCCESS";
export const WRO_LABEL_ERRORED = "WRO_LABEL_ERRORED";

// WRO SHIPPING LABELS
export const WRO_LABELS_REQUESTED = "WRO_LABELS_REQUESTED";
export const WRO_LABELS_SUCCESS = "WRO_LABELS_SUCCESS";
export const WRO_LABELS_ERRORED = "WRO_LABELS_ERRORED";
