export const getCapitalizedString = (input_string) => {
  if (!input_string) {
    return "";
  }

  const string_array = input_string.trim().split(/\s+/);
  const cap_string = string_array.map((string) => {
    return (
      string[0].toUpperCase() + string.slice(1, string.length).toLowerCase()
    );
  });
  return cap_string.join(" ");
};
