import { FETCH_PRODUCTS } from "../actions";

export default function (state = [], { type, payload }) {
  switch (type) {
    case FETCH_PRODUCTS: {
      let data = payload.data;
      data =
        data &&
        data.filter((obj) => {
          const { metadata } = obj;
          return (
            metadata && metadata.adminHidden && metadata.adminHidden === "false"
          );
        });
      return data || [];
    }
    default:
      return state;
  }
}
