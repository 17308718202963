import React, { useMemo, useState } from "react";

// Components

// Interfaces
import { useQuery, useMutation } from "react-query";

import { Fulfillment } from "@secondcloset/types";
import { ActivityHistoryExportModal, ActivityHistoryList, CheckboxDropdown } from "@secondcloset/web-components";

import { Button, Modal, Row, Typography } from "antd";
import { isEmpty } from "lodash-es";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";

import * as S from "./styles";

import { fetchOrderLogs } from "../../api/fulfillment/order";
import { exportProductLogs, fetchProductLogs } from "../../api/fulfillment/product";
import { useFacilityIndex } from "../../hooks/api/common/useFacilityIndex";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 5;

export enum ActivityHistoryType {
  PRODUCT = "product",
  ORDER = "order",
}

const productTabList = [
  { key: "all", tab: "All" },
  { key: "manual", tab: "Manual" },
  { key: "asn", tab: "ASN" },
  { key: "order", tab: "Order" },
  { key: "system", tab: "System" },
];
const orderTabList = [
  { key: "all", tab: "All" },
  { key: "manual", tab: "Manual" },
  { key: "system", tab: "System" },
];

const getDataFetcher = (type: ActivityHistoryType) => {
  return type === ActivityHistoryType.ORDER ? fetchOrderLogs : fetchProductLogs;
};

const getTabList = (type: ActivityHistoryType) => {
  return type === ActivityHistoryType.ORDER ? orderTabList : productTabList;
};

const dateFormat = "YYYY/MM/DD";
const defaultEndDate = moment();
const defaultStartDate = moment(defaultEndDate).subtract(6, "M").startOf("day");

interface Props {
  id: string;
  type: ActivityHistoryType;
  title?: string;
  orgID?: string;
  enableFetch: boolean;
}
type ActivityEvent = Fulfillment.ActivityEvent;

const ActivityHistory: React.FC<Props> = ({ id, type, title, enableFetch, orgID }) => {
  const [event, setEvent] = useState<ActivityEvent>();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [filterFacilities, setFilterFacilities] = useState<string[]>([]);
  const [exportFacilities, setExportFacilities] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [dateRange, setDateRange] = useState<RangeValue<Moment>>([
    moment(defaultStartDate, dateFormat),
    moment(defaultEndDate, dateFormat),
  ]);
  const query = {
    id,
    event,
    page,
    per_page: pageSize,
    ...(type === ActivityHistoryType.PRODUCT && { facility: filterFacilities }),
  };
  const activityHistory = useQuery([`${type}ActivityHistory`, query], () => getDataFetcher(type)(query), {
    enabled: enableFetch,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const facilityIndex = useFacilityIndex({
    enabled: !isEmpty(activityHistory.data),
    select: (response) => response.filter((f) => f.name !== "unknown"),
  });

  const { mutateAsync: submitProductLogsExport, ...productLogsExport } = useMutation(exportProductLogs, {
    onMutate: () => setIsVisible(false),
    onSuccess: ({ message }) => {
      Modal.success({
        title: "Product log request submitted",
        content: message,
      });
    },
  });

  const facilityOptions = useMemo(() => {
    return facilityIndex.data?.map((f) => ({
      value: f.name,
      key: f.name,
      label: `${f.name.toUpperCase()} ${f.address.city}`,
    }));
  }, [facilityIndex.data]);

  const getRedirectUrl = (id: string, type: string) => {
    switch (type) {
      case "user":
        return `/organizations/${orgID}/users/${id}/edit`;
      case "asn":
        return `/fulfillment/asns/${id}`;
      case "order":
        return `/fulfillment/orders/${id}`;
      case "shipment":
        return `/fulfillment/orders/${query.id}/shipment/${id}`;
    }
    return "/";
  };

  const handleTabChange = (key: string) => {
    setEvent((key !== "all" ? key : undefined) as ActivityEvent);
    setPage(1);
  };

  const handleSubmitExport = () => {
    const [date_from, date_to] = dateRange as [Moment, Moment];
    if (!date_from || !date_to || !exportFacilities) return;
    submitProductLogsExport({
      productID: id,
      date_from: moment(date_from).format(dateFormat),
      date_to: moment(date_to).format(dateFormat),
      ...(!isEmpty(exportFacilities) && { facility: exportFacilities }),
    });
  };

  const renderTabActionButtons = () => {
    if (type !== ActivityHistoryType.PRODUCT) return;
    return (
      <Row justify="space-between">
        <CheckboxDropdown
          options={facilityOptions ?? []}
          defaultText="Filter by Warehouse"
          selectedOptionKeys={filterFacilities}
          setSelectedOptionKeys={setFilterFacilities}
          loading={facilityIndex.isLoading}
        />
        <Button type="primary" onClick={() => setIsVisible(true)}>
          Export
        </Button>
      </Row>
    );
  };

  const renderExportModal = () => (
    <ActivityHistoryExportModal
      visible={isVisible}
      confirmLoading={productLogsExport.isLoading}
      selectWarehouseProps={{
        options: facilityOptions ?? [],
        selectedOptionKeys: exportFacilities,
        setSelectedOptionKeys: setExportFacilities,
        loading: facilityIndex.isLoading,
        disabled: productLogsExport.isLoading,
      }}
      dateRangeProps={{
        value: dateRange ?? [null, null],
        onCalendarChange: setDateRange,
        format: dateFormat,
        disabled: productLogsExport.isLoading,
      }}
      onOk={handleSubmitExport}
      okButtonProps={{
        disabled: isEmpty(dateRange),
      }}
      onCancel={() => setIsVisible(false)}
    />
  );

  return (
    <S.Container>
      <Typography.Title level={4}>{title ?? "ACTIVITY HISTORY"}</Typography.Title>
      <ActivityHistoryList
        isAdmin
        activityHistory={activityHistory.data ?? []}
        loading={activityHistory.isLoading}
        tabList={getTabList(type)}
        activeTabKey={event ?? "all"}
        onTabChange={handleTabChange}
        getRedirectUrl={getRedirectUrl}
        tabBarExtraContent={renderTabActionButtons()}
        pagination={{
          current: page,
          pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize ?? DEFAULT_PAGE_SIZE);
          },
        }}
      />
      {renderExportModal()}
    </S.Container>
  );
};

export default ActivityHistory;
