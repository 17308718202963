import React from "react";
import useShipmentCreateFlow from "../../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import { Select } from "antd";
import Shipment from "../../../../lib/fulfillment/shipment";
import { cloneDeep } from "lodash-es";

const SelectReturnReason = (props) => {
  const {
    order,
    dispatchSetReturnReasons,
    returnReasons,
  } = useShipmentCreateFlow();
  if (!order) return null;

  const renderSelectOptions = () => {
    const reasons = Shipment.getEndyReturnReasons();
    return reasons.map((reason, index) => {
      return (
        <Select.Option key={`reason-${index}`} value={reason}>
          {reason}
        </Select.Option>
      );
    });
  };

  const updateReturnReason = (value, itemID) => {
    const updatedReasons = cloneDeep(returnReasons);
    const foundReason = updatedReasons?.find((r) => r.itemID === itemID);
    if (foundReason) foundReason.reason = value;
    dispatchSetReturnReasons(updatedReasons);
  };

  const renderReturnReasonSelect = (returnReason) => {
    return (
      <Select
        defaultValue="-----------"
        style={{ width: 180 }}
        onChange={(value) => {
          updateReturnReason(value, returnReason.itemID);
        }}
      >
        {renderSelectOptions()}
      </Select>
    );
  };

  const renderItemsTable = () => {
    const rows = returnReasons.map((reason) => {
      const { itemID, productName, productSku } = reason;
      return (
        <tr key={itemID}>
          <td>{productName}</td>
          <td>{productSku}</td>
          <td>{renderReturnReasonSelect(reason)}</td>
        </tr>
      );
    });

    return (
      <div className="items-table">
        <table>
          <tbody>
            <tr>
              <th>Item Name</th>
              <th>SKU</th>
              <th>Return Reason</th>
            </tr>
            {rows}
          </tbody>
        </table>
      </div>
    );
  };

  return <div className="select-return-reason">{renderItemsTable()}</div>;
};

export default SelectReturnReason;
