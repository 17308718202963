import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";
import { cloneDeep } from "lodash-es";
import Appointment from "../../../lib/regular/appointment";
let requestQueue = [];

const useAppointmentIndex = () => {
  const [appointmentIndex, setAppointmentIndex] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const fetchAppointmentIndex = (query) => {
    const requestID = Math.random();
    setAppointmentIndex({ ...appointmentIndex, loading: true, error: null });
    requestQueue = [requestID, ...requestQueue];
    const urlBuilder = new UrlBuilder("/appointments");
    const url = urlBuilder.applyQueryStringFromObject(query);
    const api = getAPIFromDomain();
    return api
      .get(url)
      .then((res) => {
        if (requestQueue[0] === requestID) {
          setAppointmentIndex({ data: res.data, loading: false, error: null });
        }
        return res.data;
      })
      .catch((error) => {
        setAppointmentIndex({ ...appointmentIndex, loading: false, error });
        return error;
      });
  };

  return { appointmentIndex, fetchAppointmentIndex };
};

const useAppointmentDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain();

  const fetchAppointmentDetails = (apptID) => {
    const url = `/appointments/${apptID}`;

    setLoading(true);
    setError(null);
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const updateAppointmentDetails = (apptID, body) => {
    const url = `/appointments/${apptID}`;
    setLoading(true);
    setError(null);
    return api
      .put(url, { appointment: body })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const updateAppointmentStatus = (apptID, status, options) => {
    const action = Appointment.getAppointmentActionFromStatus(status);
    const url = `/appointments/${apptID}/${action}`;
    const body = options;
    setLoading(true);
    setError(null);
    return api
      .put(url, body)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const setAppointmentLoading = (loading) => {
    setLoading(loading);
  };

  const appointmentDetails = { data, loading, error };

  return {
    appointmentDetails,
    fetchAppointmentDetails,
    updateAppointmentDetails,
    updateAppointmentStatus,
    setAppointmentLoading,
  };
};

const useAppointmentNotes = () => {
  const [appointmentNotes, setAppointmentNotes] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchAppointmentNotes = (apptID) => {
    const url = `/appointments/${apptID}/notes`;

    setAppointmentNotes({ ...appointmentNotes, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setAppointmentNotes({
          data: res.data.notes,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentNotes({ ...appointmentNotes, loading: false, error });
        return false;
      });
  };

  const createAppointmentNote = (apptID, text) => {
    const url = `/appointments/${apptID}/notes`;
    setAppointmentNotes({ ...appointmentNotes, loading: true, error: null });
    return api
      .post(url, { note: { text } })
      .then((res) => {
        setAppointmentNotes({
          data: [res.data.note, ...appointmentNotes.data],
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentNotes({ ...appointmentNotes, loading: false, error });
        return false;
      });
  };

  const updateAppointmentNote = (apptID, noteID, text) => {
    const url = `/appointments/${apptID}/notes/${noteID}`;
    setAppointmentNotes({ ...appointmentNotes, loading: true, error: null });
    return api
      .put(url, { note: { text } })
      .then((res) => {
        const targetNoteIndex = appointmentNotes.data.findIndex(
          (n) => n.id === noteID
        );
        const newNotes = cloneDeep(appointmentNotes.data);
        newNotes.splice(targetNoteIndex, 1, res.data.note);
        setAppointmentNotes({
          data: newNotes,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentNotes({ ...appointmentNotes, loading: false, error });
        return false;
      });
  };

  const deleteAppointmentNote = (apptID, noteID) => {
    const url = `/appointments/${apptID}/notes/${noteID}`;
    setAppointmentNotes({ ...appointmentNotes, loading: true, error: null });
    return api
      .delete(url)
      .then(() => {
        const targetNoteIndex = appointmentNotes.data.findIndex(
          (n) => n.id === noteID
        );
        const newNotes = cloneDeep(appointmentNotes.data);
        newNotes.splice(targetNoteIndex, 1);
        setAppointmentNotes({
          data: newNotes,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentNotes({ ...appointmentNotes, loading: false, error });
        return false;
      });
  };

  const resetAppointmentNoteError = () => {
    setAppointmentNotes({ ...appointmentNotes, error: null });
  };

  return {
    appointmentNotes,
    fetchAppointmentNotes,
    createAppointmentNote,
    updateAppointmentNote,
    deleteAppointmentNote,
    resetAppointmentNoteError,
  };
};

const useAppointmentTasks = () => {
  const [appointmentTasks, setAppointmentTasks] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchAppointmentTasks = (apptID) => {
    const url = `/appointments/${apptID}/tasks`;

    setAppointmentTasks({ ...appointmentTasks, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setAppointmentTasks({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setAppointmentTasks({ ...appointmentTasks, loading: false, error });
        return false;
      });
  };

  const createAppointmentTask = (apptID, body) => {
    const url = `/appointments/${apptID}/tasks`;
    setAppointmentTasks({ ...appointmentTasks, loading: true, error: null });
    return api
      .post(url, { task: body })
      .then((res) => {
        setAppointmentTasks({
          data: [res.data, ...appointmentTasks.data],
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentTasks({ ...appointmentTasks, loading: false, error });
        return false;
      });
  };

  const updateAppointmentTask = (apptID, taskID, body) => {
    const url = `/appointments/${apptID}/tasks/${taskID}`;
    setAppointmentTasks({ ...appointmentTasks, loading: true, error: null });
    return api
      .put(url, { task: body })
      .then((res) => {
        const targetTaskIndex = appointmentTasks.data.findIndex(
          (n) => n.id === taskID
        );
        const newTasks = cloneDeep(appointmentTasks.data);
        newTasks.splice(targetTaskIndex, 1, res.data);
        setAppointmentTasks({
          data: newTasks,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentTasks({ ...appointmentTasks, loading: false, error });
        return false;
      });
  };

  const deleteAppointmentTask = (apptID, taskID) => {
    const url = `/appointments/${apptID}/tasks/${taskID}`;
    setAppointmentTasks({ ...appointmentTasks, loading: true, error: null });
    return api
      .delete(url)
      .then(() => {
        const targetTaskIndex = appointmentTasks.data.findIndex(
          (n) => n.id === taskID
        );
        const newTasks = cloneDeep(appointmentTasks.data);
        newTasks.splice(targetTaskIndex, 1);
        setAppointmentTasks({
          data: newTasks,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) => {
        setAppointmentTasks({ ...appointmentTasks, loading: false, error });
        return false;
      });
  };

  return {
    appointmentTasks,
    fetchAppointmentTasks,
    createAppointmentTask,
    updateAppointmentTask,
    deleteAppointmentTask,
  };
};

const useAppointment = () => {
  const [appointment, setAppointment] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const createAppointment = (body) => {
    setAppointment({ ...appointment, loading: true, error: null });
    const api = getAPIFromDomain();
    const url = "/appointments";
    return api
      .post(url, body)
      .then((res) => {
        setAppointment({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setAppointment({ ...appointment, loading: false, error });
        return false;
      });
  };

  return { appointment, createAppointment };
};

const useAppointmentManifests = () => {
  let initialState = {
    data: null,
    loading: false,
    error: null,
  };
  const [appointmentManifests, setAppointmentManifests] = useState(
    initialState
  );

  const clearAppointManifests = () => {
    setAppointmentManifests(initialState);
  };

  const fetchAppointmentManifests = (date, service_area) => {
    const requestID = Math.random();
    setAppointmentManifests({
      ...appointmentManifests,
      loading: true,
      error: null,
    });

    const baseUrl = `/appointments/manifest/${date}`;
    const url = new UrlBuilder(baseUrl).applyServiceArea(service_area);
    const api = getAPIFromDomain("logistics");

    requestQueue = [requestID, ...requestQueue];
    let headers = api?.header?.headers ?? {};
    api.header.headers = Object.assign(headers, {
      Accept: "text/html",
      "Content-Type": "text/html",
    });

    return api
      .get(url)
      .then((res) => {
        if (requestQueue[0] === requestID) {
          setAppointmentManifests({
            data: res.data,
            loading: false,
            error: null,
          });
        }
        return true;
      })
      .catch((error) => {
        setAppointmentManifests({
          ...appointmentManifests,
          loading: false,
          error,
        });
        return false;
      });
  };

  return {
    appointmentManifests,
    fetchAppointmentManifests,
    clearAppointManifests,
  };
};

const useAppointmentIndexCsv = () => {
  const [appointmentIndexCsv, setAppointmentIndexCsv] = useState({
    data: null,
    loading: false,
    error: false,
  });

  const fetchAppointmentIndexCsv = (query) => {
    setAppointmentIndexCsv({
      ...appointmentIndexCsv,
      loading: true,
      error: null,
    });
    const urlBuilder = new UrlBuilder("/appointments.csv");
    const url = urlBuilder.applyQueryStringFromObject(query);
    const api = getAPIFromDomain();
    return api
      .get(url)
      .then((res) => {
        setAppointmentIndexCsv({
          data: res.data,
          loading: false,
        });
        return res.data;
      })
      .catch((error) => {
        setAppointmentIndexCsv({
          ...appointmentIndexCsv,
          loading: false,
          error,
        });
        return error;
      });
  };

  return { appointmentIndexCsv, fetchAppointmentIndexCsv };
};

export {
  useAppointmentIndex,
  useAppointmentDetails,
  useAppointmentNotes,
  useAppointmentTasks,
  useAppointment,
  useAppointmentManifests,
  useAppointmentIndexCsv,
};
