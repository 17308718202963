import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const useProducts = () => {
  const [products, setProducts] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();
  const baseUrl = `/products`;

  const getUrlWithQuery = (query) => {
    if (!query || !query.service_area) return baseUrl;
    const serviceArea = query.service_area;
    const urlBuilder = new UrlBuilder(baseUrl);
    return urlBuilder.applyServiceArea(serviceArea);
  };

  const fetchProducts = (query) => {
    const url = getUrlWithQuery(query);
    setProducts({ ...products, loading: true, error: null });
    return api
      .get(url)
      .then((res) =>
        setProducts({ data: res.data, loading: false, error: null })
      )
      .catch((error) => setProducts({ ...products, loading: false, error }));
  };

  return { products, fetchProducts };
};

export { useProducts };
