import React, { useMemo } from "react";

// libs
import Organization from "../../../../lib/organization";

// components
import ShipmentCard from "./ShipmentCard";
import {
  ToBeDeliveredItemsCard,
  RemovedItemsCard,
} from "@secondcloset/web-components";

// styles
import {
  Container,
  ShipmentCardWrap,
  ShipmentSectionWrap,
  RemovedItemsWrap,
} from "./styles";

//types
import { Fulfillment } from "@secondcloset/types";
import { isEmpty, startCase } from "lodash-es";
import { Facility, FacilityCode } from "@secondcloset/fulfillment-utils";
import { Typography } from "antd";
import { useFacilityIndex } from "../../../../hooks/api/common/useFacilityIndex";
type Order = Fulfillment.Order;
type Shipment = Fulfillment.Shipment;
type FulfillmentShipmentType = Fulfillment.AppointmentType;
interface Props {
  order: Order;
  orderLoading: boolean;
  onRefetch: () => any;
  onShipItemsClick: () => unknown;
}

const OrderShipments: React.FC<Props> = ({
  order,
  orderLoading,
  onRefetch,
  onShipItemsClick,
}) => {
  const shipments = order?.shipments || [];
  const isIkea = Organization.getIkeaOrgID() === order?.organization?.id;

  const facilityList = Facility.getFulfillmentFacilityList(); // will be deprecated and moved to api/v2/facilities
  const facilityIndex = useFacilityIndex({ enabled: !isEmpty(shipments) });

  const facilities = useMemo(() => {
    const facilityByCode = facilityIndex.data?.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.id }),
      {} as Record<FacilityCode, string>
    );
    return facilityList.map((f) => ({
      ...f,
      id: facilityByCode ? facilityByCode[f.code] : undefined,
    }));
  }, [facilityIndex.data, facilityList]);

  const renderShipments = (title: string, shipments_: Shipment[]) => {
    if (shipments_.length === 0) return null;

    return (
      <ShipmentSectionWrap key={title}>
        <Typography.Title level={4}>{title}</Typography.Title>
        {shipments_.map((shipment) => (
          <ShipmentCardWrap key={shipment.id} id={shipment.id}>
            <ShipmentCard
              order={order}
              shipment={shipment}
              isLoading={orderLoading}
              onRefetch={onRefetch}
              facilities={facilities}
            />
          </ShipmentCardWrap>
        ))}
      </ShipmentSectionWrap>
    );
  };

  const renderUntrackedShipments = () => {
    const untrackedShipments = shipments.filter(
      (shipment) => shipment?.shipping_method_type === "untracked_shipment"
    );
    return renderShipments("Untracked Delivery Shipments", untrackedShipments);
  };

  const renderAllShipmentTypes = () => {
    const shipmentTypes: FulfillmentShipmentType[] = [
      "reverse_logistics",
      "delivery",
      "cross_dock_delivery",
      "inventory_pick_up",
      "return_to_sender",
      "inventory_warehouse_receiving",
    ];

    return shipmentTypes.map((type) => {
      const shipment = shipments.filter(
        (s) =>
          s?.shipping_method_type !== "untracked_shipment" &&
          s?.action_summary?.includes(type)
      );
      const title =
        type === "cross_dock_delivery" ? "Delivery" : startCase(type);
      return renderShipments(
        `${title} Shipments (${shipment?.length})`,
        shipment
      );
    });
  };

  const goToProductDetailsPage = (productID: string) => {
    window.open(
      `/organizations/${order?.organization?.id}/products/${productID}`
    );
  };

  return (
    <Container>
      <br />
      <ToBeDeliveredItemsCard
        order={order}
        onProductClick={goToProductDetailsPage}
        onShipItemsClick={onShipItemsClick}
        isIkea={isIkea}
      />
      <RemovedItemsWrap>
        <RemovedItemsCard
          order={order}
          onProductClick={goToProductDetailsPage}
        />
      </RemovedItemsWrap>
      {renderUntrackedShipments()}
      {renderAllShipmentTypes()}
    </Container>
  );
};

export default OrderShipments;
