import React, { useEffect } from "react";

// lib
import { get } from "lodash-es";

// components
import { Spin, notification } from "antd";
import CustomerInfoSection from "./CustomerInfoSection";
import CustomerAppointmentsTable from "./CustomerAppointmentsTable";
import CustomerAddresses from "./CustomerAddresses";
import CustomerNotes from "./CustomerNotes";

// hooks
import { useParams } from "react-router";
import { useCustomerDetails } from "../../../hooks/api/regular/customer";

const CustomerDetailsPage = () => {
  const params = useParams();
  const userID = get(params, "userID", "");
  const { customerDetails, fetchCustomerDetails } = useCustomerDetails();

  const onMount = () => {
    fetchCustomerDetails(userID);
  };

  const onCustomerDetailsError = () => {
    if (customerDetails.error) {
      notification.error({
        message: "Error",
        description: customerDetails.error,
      });
    }
  };

  useEffect(onMount, []);
  useEffect(onCustomerDetailsError, [customerDetails]);

  const role = get(customerDetails.data, "role");
  const isStorageUser = role !== "is_fulfillment_client" && role !== "is_admin";

  const renderTables = () => {
    return (
      <>
        <CustomerNotes userID={userID} />
        <CustomerAppointmentsTable userID={userID} />
        <CustomerAddresses userID={userID} />
      </>
    );
  };

  return (
    <Spin spinning={customerDetails.loading} tip="fetching customer details...">
      <div id="customer-details-page" className="page-container">
        {customerDetails.data && (
          <CustomerInfoSection
            user={customerDetails.data}
            onUpdateContactSuccess={() => fetchCustomerDetails(userID)}
          />
        )}
        {customerDetails.data && isStorageUser && renderTables()}
      </div>
    </Spin>
  );
};

export default CustomerDetailsPage;
