import React, { Component } from "react";
import { Table, Button, Tooltip } from "antd";
import { lowerCase } from "lodash-es";
import PropTypes from "prop-types";
import AssignQrModal from "../../../../containers/regular/appointment/AssignQrModal";
import EditNameModal from "../../../../containers/regular/appointment/EditNameModal";
import ItemColor from "../../../../components/regular/_common/inventoryItems/ItemColor";
import Appointment from "../../../../lib/regular/appointment";
import ViewToggleButton from "./ViewToggleButton";
import GridView from "./GridView";

const MODALS = {
  assign_qr: "assign_qr",
  edit_name: "edit_name",
};

class AppointmentItemTable extends Component {
  state = {
    selectedFilter: "all_items",
    search: "",
    selectedRowKeys: [],
    showModal: null,
    isListView: true,
  };

  applyFilter = (items) =>
    items.filter((i) => {
      if (this.state.selectedFilter === "all_items") return true;
      return i.name === this.state.selectedFilter;
    });

  applySearch = (items) =>
    items.filter((i) => {
      const { search } = this.state;

      if (!search) return true;
      return (
        (i.category_name &&
          lowerCase(i.category_name).includes(lowerCase(search))) ||
        (i.name && lowerCase(i.name).includes(lowerCase(search))) ||
        (i.qr_code && lowerCase(i.qr_code).includes(lowerCase(search))) ||
        (i.description &&
          lowerCase(i.description).includes(lowerCase(search))) ||
        (i.location && lowerCase(i.location).includes(lowerCase(search))) ||
        (i.material && lowerCase(i.material).includes(lowerCase(search))) ||
        (i.color && lowerCase(i.color).includes(lowerCase(search))) ||
        (i.size && lowerCase(i.size).includes(lowerCase(search))) ||
        (i.brand && lowerCase(i.brand).includes(lowerCase(search)))
      );
    });

  applyTableSelection = (items) =>
    items.filter((i) => {
      const { selectedRowKeys } = this.state;
      return selectedRowKeys.includes(i.key);
    });

  selectTableRow = (selectedRowKeys) => this.setState({ selectedRowKeys });

  deselectTableRow = (key) => {
    const selectedRowKeys = this.state.selectedRowKeys.filter((k) => k !== key);
    this.setState({ selectedRowKeys });
  };

  getTableColumns = () => {
    const renderItemName = (categoryName, record) => {
      if (categoryName) return categoryName;
      const isProduct = record?.item_type === "Product";
      const quantity = isProduct ? record?.quantity : 0;
      return `${record.name} ${isProduct ? `(Product) x ${quantity}` : ""}`;
    };

    return [
      {
        title: "Item Name",
        dataIndex: "category_name",
        render: renderItemName,
        width: 175,
      },
      { title: "QR Code", dataIndex: "qr_code", width: 125 },
      { title: "Nickname", dataIndex: "nickname", width: 150 },
      { title: "Description", dataIndex: "description", width: 175 },
      { title: "Location", dataIndex: "location", width: 175 },
      { title: "Material", dataIndex: "material", width: 100 },
      {
        title: "Color",
        dataIndex: "color",
        render: (color) => {
          if (!color) return;
          let colorObj;
          try {
            colorObj = JSON.parse(color);
          } catch (err) {
            colorObj = { name: "", hexcode: "" };
          }
          const { name, hexcode } = colorObj;
          return <ItemColor name={name} hexCode={hexcode} />;
        },
        width: 100,
      },
      { title: "Size", dataIndex: "size", width: 100 },
      { title: "Brand", dataIndex: "brand", width: 100 },
    ];
  };

  getRowSelection = () => {
    const { selectedRowKeys } = this.state;

    return {
      selectedRowKeys,
      onChange: (selectedRowKeys) => this.setState({ selectedRowKeys }),
      hideDefaultSelections: true,
    };
  };

  getFilteredItems = () => {
    const itemsWithKeys = Appointment.getAppointmentItems(
      this.props.appointment
    ).map((i) => ({
      ...i,
      key: i.item_id,
    }));
    const filteredItems = this.applyFilter(itemsWithKeys);
    return this.applySearch(filteredItems);
  };

  getSuppliesAndSpacePlanProducts = () =>
    this.props.products.filter(
      (p) => p.object === "product" || p.metadata.spacePlan === "true"
    );

  hideModal = () => {
    this.setState({ showModal: null });
  };

  onModalDone = () => {
    this.setState({ showModal: null });
    this.props.fetchAppointmentDetails(this.props.appointment.id);
  };

  buildHeader = () => {
    const { space_order } = this.props.appointment;
    const planType = space_order ? "Space" : "Item";

    return (
      <div className="appointment-table-header">
        <h3>{`${planType} Plan Inventory`}</h3>
        <ViewToggleButton
          isListView={this.state.isListView}
          setIsListView={(view) => this.setState({ isListView: view })}
        />
      </div>
    );
  };

  buildSingleFilter = ({ name, quantity }) => {
    const selected = name === this.state.selectedFilter;
    const color = selected ? "green" : "blue";

    return (
      <div
        onClick={() => this.setState({ selectedFilter: name })}
        className="table-filter inl-flex-row"
        key={name}
      >
        <div className="name-container">
          <p>{name.replace(/_/g, " ").toUpperCase()}</p>
        </div>
        <div className={`${color} quantity-container`}>
          <p>{quantity}</p>
        </div>
      </div>
    );
  };

  buildFilters = () => {
    const items = Appointment.getAppointmentItems(this.props.appointment);
    const totalQuantity = items.reduce((acc, item) => {
      acc += item.quantity;
      return acc;
    }, 0);

    const namesWithQuantity = items.reduce((acc, item) => {
      const { name, quantity } = item;
      if (acc[name]) acc[name] += quantity;
      else acc[name] = quantity;

      return acc;
    }, {});

    const namesWithQuantityMappable = Object.keys(namesWithQuantity).map(
      (name) => ({
        name,
        quantity: namesWithQuantity[name],
      })
    );

    return (
      <div className="filter-container flex-row">
        <div>
          {this.buildSingleFilter({
            name: "all_items",
            quantity: totalQuantity,
          })}
        </div>
        <div className="flex-row">
          {namesWithQuantityMappable.map(this.buildSingleFilter)}
        </div>
      </div>
    );
  };

  buildSearchBar = () => (
    <input
      value={this.state.search}
      onChange={(e) => this.setState({ search: e.target.value })}
      placeholder="Search Items"
    />
  );

  buildEditSection = () => {
    const items = this.getFilteredItems();
    const selectedStripeIds = this.applyTableSelection(items).map(
      (i) => i.stripe_id
    );
    const noEditStripeIds = this.getSuppliesAndSpacePlanProducts().reduce(
      (acc, p) => {
        acc[p.id] = true;
        return acc;
      },
      {}
    );
    const disableEditButton = selectedStripeIds.some(
      (id) => noEditStripeIds[id]
    );

    return (
      <div className="flex-row edit-section">
        {this.buildSearchBar()}
        <div>
          <Tooltip
            placement="bottom"
            title={"Do not select space plans/products"}
            visible={disableEditButton}
            overlayStyle={{ fontSize: "10px" }}
          >
            <Button
              type="primary"
              onClick={() => this.setState({ showModal: MODALS.assign_qr })}
              disabled={disableEditButton}
            >
              Edit/Add QR Code
            </Button>
          </Tooltip>
          <Button
            onClick={() => this.setState({ showModal: MODALS.edit_name })}
            disabled={disableEditButton}
          >
            Edit Item Name
          </Button>
        </div>
      </div>
    );
  };

  buildTable = () => {
    const { isListView } = this.state;
    if (isListView) {
      return (
        <Table
          dataSource={this.getFilteredItems()}
          columns={this.getTableColumns()}
          rowSelection={this.getRowSelection()}
          pagination={false}
          scroll={{ x: 1200, y: 500 }}
          bordered={true}
        />
      );
    }

    return <GridView items={this.getFilteredItems()} />;
  };

  buildAssignQrModal = () => {
    const { user_id, user_code } = this.props.appointment;
    const items = this.getFilteredItems();
    const selectedItems = this.applyTableSelection(items);

    return (
      <AssignQrModal
        visible={this.state.showModal === MODALS.assign_qr}
        items={selectedItems}
        userId={user_id}
        userCode={user_code}
        onCancel={this.hideModal}
        onDone={this.onModalDone}
      />
    );
  };

  buildEditNameModal = () => {
    const { user_id } = this.props.appointment;
    const items = this.getFilteredItems();
    const selectedItems = this.applyTableSelection(items);

    return (
      <EditNameModal
        visible={this.state.showModal === MODALS.edit_name}
        items={selectedItems}
        userId={user_id}
        onCancel={this.hideModal}
        onDone={this.onModalDone}
      />
    );
  };

  render() {
    return (
      <div className="col-xs-12 appointment-details-item-table">
        {this.buildHeader()}
        {this.buildFilters()}
        {this.buildEditSection()}
        {this.buildTable()}
        {this.buildAssignQrModal()}
        {this.buildEditNameModal()}
      </div>
    );
  }
}

AppointmentItemTable.propTypes = {
  appointment: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  fetchAppointmentDetails: PropTypes.func.isRequired,
};

export default AppointmentItemTable;
