import { updateInventoryDetails } from "../../../api/inventory";
import {
  ASSIGN_QR_MODAL_UPDATE_REQUEST,
  ASSIGN_QR_MODAL_UPDATE_RESPONSE,
  ASSIGN_QR_MODAL_CLEAR_DATA,
} from "../types";

export const assignQrToMultipleItems = (userId, itemIds, qrCodes) => (
  dispatch
) => {
  dispatch({ type: ASSIGN_QR_MODAL_UPDATE_REQUEST });

  const requests = itemIds.map((id) => {
    const qr_code = qrCodes[id].userCode + "-" + qrCodes[id].itemCode;
    const body = { inventory_item: { qr_code } };
    return updateInventoryDetails(userId, id, body)
      .then(() => ({ [id]: "success" }))
      .catch((e) => ({ [id]: e }));
  });

  return Promise.all(requests).then((res) => {
    const successKeyMap = res.reduce((acc, r) => {
      const newAcc = { ...acc, ...r };
      return newAcc;
    }, {});

    return dispatch({
      type: ASSIGN_QR_MODAL_UPDATE_RESPONSE,
      data: successKeyMap,
    });
  });
};

export const clearModalData = () => (dispatch) =>
  dispatch({ type: ASSIGN_QR_MODAL_CLEAR_DATA });
