import React from "react";
import { Spin } from "antd";
import "antd/lib/spin/style";
const Loader = (props) => {
  const { noMargin } = props;
  const style = noMargin ? undefined : { marginTop: "20vh" };
  return (
    <div className="col-xs-12 text-center" style={style}>
      <Spin tip="Loading..." />
    </div>
  );
};
export default Loader;
