import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Table, Modal, Spin, Alert } from "antd";
import SelectNewSuppliesCard from "../../../../components/regular/order/dependencies/SelectNewSuppliesCard";
import Order from "../../../../lib/regular/order";
import { get } from "lodash-es";

const SuppliesTable = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const { orderDetails, productIndex } = props;

  const orderItems = get(orderDetails, "data.items");
  const supplies = orderItems.filter(
    (i) => i.item_type === "Product" && !i.stripe_id.includes("recall")
  );

  const onMount = () => {
    const existingSupplies = supplies.reduce((acc, cv) => {
      acc[cv.stripe_id] = cv;
      return acc;
    }, {});

    setAddedProducts(existingSupplies);
  };

  useEffect(onMount, [orderDetails.data]);

  const buildColumns = () => {
    return [
      { title: "Name", dataIndex: "name", key: "name" },
      { title: "Stripe ID", dataIndex: "stripe_id", key: "stripe_id" },
      { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    ];
  };

  const onAddItems = () => {
    const isSpaceOrder = get(orderDetails, "data.space_order");
    const userID = get(orderDetails, "data.user_id");
    const orderID = get(orderDetails, "data.id");
    const getBody = isSpaceOrder
      ? Order.getSpaceStorageEditSuppliesBody
      : Order.getStorageEditSuppliesBody;
    const body = getBody(orderDetails.data, addedProducts);
    props.updateOrderDetails(userID, orderID, body).then((res) => {
      if (res) setModalVisible(false);
    });
  };

  const renderError = () => {
    if (!props.orderDetails.error) return null;
    return <Alert type="error" message={props.orderDetails.error} banner />;
  };

  const buildSuppliesModal = () => {
    return (
      <Modal
        title="Add Items"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={onAddItems}
        width={800}
      >
        <Spin spinning={props.orderDetails.loading} tip="updating...">
          {renderError()}
          <SelectNewSuppliesCard
            products={productIndex.data}
            addedProducts={addedProducts}
            onProductChange={(newAddedProducts) =>
              setAddedProducts(newAddedProducts)
            }
          />
        </Spin>
      </Modal>
    );
  };

  return (
    <div className="order-edit-supplies-table">
      <div className="table-title">
        <div className="title">Supplies</div>
        <div className="action-buttons">
          <Button
            onClick={() => setModalVisible(true)}
            loading={productIndex.loading}
          >
            Edit Supplies
          </Button>
        </div>
      </div>
      <div className="supplies-table">
        <Table
          rowKey={(r) => r.stripe_id}
          dataSource={supplies}
          columns={buildColumns()}
          size="small"
        />
      </div>
      {buildSuppliesModal()}
    </div>
  );
};

SuppliesTable.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  productIndex: PropTypes.object.isRequired,
  updateOrderDetails: PropTypes.func.isRequired,
};

export default SuppliesTable;
