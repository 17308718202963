import { useSelector, useDispatch } from "react-redux";
import {
  actionSetActiveStepKey,
  actionSetActiveLocation,
  actionSetExternalPlatformVersion,
  actionSetActiveCustomer,
  actionSetActiveAddress,
  actionSetItemList,
  actionSetExternalOrderNumber,
  actionSetNotes,
  actionReset,
  actionSetAutoCreateDeliveryShipment,
  actionSetInsuranceValue,
  actionSetFiles,
  actionAddFile,
  actionRemoveFile,
} from "../../../ducks/orderCreateFlow";

import { State as StateProp, Step } from "../../../ducks/orderCreateFlow";

interface State {
  fulfillment: {
    orderCreateFlow: StateProp;
  };
}

//selectors
const selectActiveStepKey = (state: State) =>
  state.fulfillment.orderCreateFlow.activeStepKey;
const selectActiveLocation = (state: State) =>
  state.fulfillment.orderCreateFlow.activeLocation;
const selectExternalPlatformVersion = (state: State) =>
  state.fulfillment.orderCreateFlow.externalPlatformVersion;
const selectActiveCustomer = (state: State) =>
  state.fulfillment.orderCreateFlow.activeCustomer;
const selectActiveAddress = (state: State) =>
  state.fulfillment.orderCreateFlow.activeAddress;
const selectItemList = (state: State) =>
  state.fulfillment.orderCreateFlow.itemList;
const selectExternalOrderNumber = (state: State) =>
  state.fulfillment.orderCreateFlow.externalOrderNumber;
const selectNotes = (state: State) => state.fulfillment.orderCreateFlow.notes;
const selectIsAutoCreateDeliveryShipment = (state: State) =>
  state.fulfillment.orderCreateFlow.isAutoCreateDeliveryShipment;
const selectInsuranceValue = (state: State) =>
  state.fulfillment.orderCreateFlow.insuranceValue;
const selectFiles = (state: State) => state.fulfillment.orderCreateFlow.files;

const useOrderCreateFlow = () => {
  const dispatch = useDispatch();

  const activeStepKey = useSelector(selectActiveStepKey);
  const activeLocation = useSelector(selectActiveLocation);
  const externalPlatformVersion = useSelector(selectExternalPlatformVersion);
  const activeCustomer = useSelector(selectActiveCustomer);
  const activeAddress = useSelector(selectActiveAddress);
  const itemList = useSelector(selectItemList);
  const externalOrderNumber = useSelector(selectExternalOrderNumber);
  const notes = useSelector(selectNotes);
  const isAutoCreateDeliveryShipment = useSelector(
    selectIsAutoCreateDeliveryShipment
  );
  const insuranceValue = useSelector(selectInsuranceValue);
  const files = useSelector(selectFiles);

  const dispatchSetActiveStepKey = (stepKey: StateProp["activeStepKey"]) =>
    dispatch(actionSetActiveStepKey(stepKey));
  const dispatchSetExternalPlatformVersion = (
    version: StateProp["externalPlatformVersion"]
  ) => {
    dispatch(actionSetExternalPlatformVersion(version));
  };
  const dispatchActiveLocation = (location: StateProp["activeLocation"]) => {
    dispatch(actionSetActiveLocation(location));
  };
  const dispatchActiveCustomer = (customer: StateProp["activeCustomer"]) => {
    dispatch(actionSetActiveCustomer(customer));
  };
  const dispatchActiveAddress = (address: StateProp["activeAddress"]) => {
    dispatch(actionSetActiveAddress(address));
  };
  const dispatchItemList = (items: StateProp["itemList"]) =>
    dispatch(actionSetItemList(items));
  const dispatchSetNotes = (notes: StateProp["notes"]) =>
    dispatch(actionSetNotes(notes));
  const dispatchIExternalOrderNumber = (
    externalOrderNumber: StateProp["externalOrderNumber"]
  ) => dispatch(actionSetExternalOrderNumber(externalOrderNumber));
  const dispatchSetAutoCreateDeliveryShipment = () =>
    dispatch(actionSetAutoCreateDeliveryShipment());
  const dispatchResetOrderCreateFlow = () => dispatch(actionReset());
  const dispatchSetInsuranceValue = (
    insuranceValue: StateProp["insuranceValue"]
  ) => dispatch(actionSetInsuranceValue(insuranceValue));
  const dispatchSetFiles = (files: StateProp["files"]) =>
    dispatch(actionSetFiles(files));
  const dispatchAddFile = (file: StateProp["files"][0]) =>
    dispatch(actionAddFile(file));
  const dispatchRemoveFile = (uid: string) => dispatch(actionRemoveFile(uid));

  const steps = {
    selectLocation: { key: "selectLocation", title: "Select Location", seq: 1 },
    searchCustomer: { key: "searchCustomer", title: "Search Customer", seq: 2 },
    selectAddress: { key: "selectAddress", title: "Select Address", seq: 3 },
    selectProducts: { key: "selectProducts", title: "Select Products", seq: 4 },
    provideExternalInfo: {
      key: "provideExternalInfo",
      title: "Provide External Info",
      seq: 5,
    },
    orderOverview: { key: "orderOverview", title: "Order Overview", seq: 6 },
  } as { [index: string]: Step };

  return {
    steps,
    activeStepKey,
    activeLocation,
    externalPlatformVersion,
    activeCustomer,
    activeAddress,
    itemList,
    externalOrderNumber,
    notes,
    isAutoCreateDeliveryShipment,
    insuranceValue,
    files,
    dispatchSetActiveStepKey,
    dispatchActiveLocation,
    dispatchSetExternalPlatformVersion,
    dispatchActiveCustomer,
    dispatchActiveAddress,
    dispatchItemList,
    dispatchIExternalOrderNumber,
    dispatchSetNotes,
    dispatchResetOrderCreateFlow,
    dispatchSetAutoCreateDeliveryShipment,
    dispatchSetInsuranceValue,
    dispatchSetFiles,
    dispatchAddFile,
    dispatchRemoveFile,
  };
};

export default useOrderCreateFlow;
