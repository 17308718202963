import React from "react";
import { get, cloneDeep } from "lodash-es";
import { Table, InputNumber } from "antd";

const ItemsTable = ({ selectedItemIDs, setSelectedItemIDs, order }) => {
  const isInventoryPickupItem = (item) => {
    const availableActions = get(item, "available_actions");
    return availableActions.includes("inventory_pick_up");
  };

  const groupItemsByInventoryPickupAndName = (items) => {
    return items.reduce((acc, item) => {
      if (!isInventoryPickupItem(item)) return acc;
      const name = get(item, "product.name");
      if (!acc[name]) acc[name] = { name, itemIDs: [] };
      acc[name].itemIDs.push(item.id);
      return acc;
    }, {});
  };

  const renderItemInputBox = (_, record) => {
    const name = get(record, "name");
    const itemIDs = get(record, "itemIDs");
    const selectedIDs = get(selectedItemIDs, `[${name}]`, []);
    const quantity = selectedIDs.length;

    const onChange = (newQuantity) => {
      const newSelectedItemIDs = cloneDeep(selectedItemIDs);
      newSelectedItemIDs[name] = itemIDs.slice(0, newQuantity);
      setSelectedItemIDs(newSelectedItemIDs);
    };

    return (
      <InputNumber
        value={quantity}
        onChange={onChange}
        maxLength={itemIDs.length}
      />
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "itemIDs",
      key: "quantity",
      render: renderItemInputBox,
    },
  ];

  const groupedItems = groupItemsByInventoryPickupAndName(order.items);
  const dataSource = Object.values(groupedItems);
  return (
    <div className="item-table">
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={false}
        rowKey={(r) => r.name}
      />
    </div>
  );
};

export default ItemsTable;
