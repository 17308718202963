import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { signIn, setAuthLoading, authError } from "../actions";
import { validateEmail } from "../helperFunctions";
import { requestPasswordReset } from "../axiosCalls";
import { InputPassword, InputText } from "../components/Input";
import "./Login.css";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      loading: "",
      loadingPasswordReset: false,
      done: "",
      login: true,
      success: null,
    };
  }

  setDone(done) {
    this.setState({
      done: done,
    });
  }

  setEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  setPassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  clearInput() {
    this.setState({
      password: "",
    });
  }

  clearError() {
    this.props.authError("");
  }

  handleLogin() {
    this.props.setAuthLoading();
    this.props.signIn(this.state.email, this.state.password, "");
    // TODO: find out why Email field gets cleared
    this.clearInput();
  }

  handlePasswordReset() {
    const { email } = this.state;

    this.setState({ loadingPasswordReset: true });
    requestPasswordReset(email, (err) => {
      this.setState({ success: !err, loadingPasswordReset: false });
    });
  }

  handleKeyPress(e) {
    const { success, login } = this.state;
    if (e.keyCode === 13 || e.which === 13) {
      if (success === null && !login) {
        this.handlePasswordReset();
      } else if (login) {
        this.handleLogin();
      }
    }
  }

  renderSuccessFail = (success) => {
    const { login } = this.state;
    if (success) {
      return (
        <div style={{ display: "block" }}>
          <p>
            Your password request has been submitted! Please check your email
            account for our password reset instructions.
          </p>
          <div className="visible-xs">
            <button
              className="loginForgotPassword"
              onClick={() => {
                this.setState({ login: !login });
                this.clearError();
              }}
            >
              have an account?
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "block" }}>
          <p>
            {
              "We're sorry. Looks like there is no account associated with the email address you provided. Please try again."
            }
          </p>
          <button
            style={{ display: "block" }}
            onClick={() => this.setState({ success: null })}
          >
            RESET
          </button>
        </div>
      );
    }
  };

  render() {
    if (this.props.auth.authenticated && !this.props.location) {
      return <Redirect to={this.props.location.state.from} />;
    }
    const { error } = this.props;
    const { login, email, password, success } = this.state;

    let check;

    if (email !== undefined && email !== "") {
      if (validateEmail(email)) {
        check = <CheckCircleOutlined className="input-icon" color="#404041" />;
      } else {
        check = <WarningOutlined className="input-icon" color="#404041" />;
      }
    }

    return (
      <div className="loginContainer">
        <div className="loginDark">
          <div className={login ? "loginSmallContent" : "loginBigContent"}>
            {login ? (
              <div>
                <h3>Forgot your password?</h3>
                <p>
                  {
                    "No problem. Just click on 'Reset' and provide your email in the form. We'll send you reset instructions right away!"
                  }
                </p>
                <button onClick={() => this.setState({ login: !login })}>
                  RESET
                </button>
              </div>
            ) : (
              <div className="loginForm">
                <h3>RESET PASSWORD</h3>
                {success !== null ? (
                  this.renderSuccessFail(success)
                ) : (
                  <div style={{ display: "block" }}>
                    <InputText
                      validationCheck={check}
                      placeholder="Email"
                      value={email}
                      onChange={this.setEmail.bind(this)}
                      onKeyPress={this.handleKeyPress.bind(this)}
                    />
                    <div className="visible-xs">
                      <button
                        className="loginForgotPassword"
                        onClick={() => {
                          this.setState({ login: !login });
                          this.clearError();
                        }}
                      >
                        have an account?
                      </button>
                    </div>
                  </div>
                )}
                <button
                  onClick={() => this.handlePasswordReset()}
                  type="submit"
                  style={{ display: success !== null ? "none" : "block" }}
                >
                  SUBMIT
                </button>
              </div>
            )}
          </div>
          <div
            className={
              login
                ? "loginBigContent is-visible"
                : "loginSmallContent is-visible"
            }
          >
            {login ? (
              <div className="loginForm">
                <h3>LOG IN</h3>
                <div className={error ? "alert loginAlert" : "hidden"}>
                  {error}
                </div>
                <InputText
                  onKeyPress={this.handleKeyPress.bind(this)}
                  validationCheck={check}
                  placeholder="Email"
                  value={email}
                  onChange={this.setEmail.bind(this)}
                />
                <InputPassword
                  onKeyPress={this.handleKeyPress.bind(this)}
                  placeholder="Password"
                  value={password}
                  onChange={this.setPassword.bind(this)}
                />
                <div className="visible-xs">
                  <button
                    className="loginForgotPassword"
                    onClick={() =>
                      this.setState({ login: !login, success: null })
                    }
                  >
                    forgot password?
                  </button>
                </div>
                <button
                  onClick={this.handleLogin.bind(this)}
                  style={{ display: "block" }}
                >
                  LOG IN
                </button>
              </div>
            ) : (
              <div>
                <h3>Have an account?</h3>
                <p>Good for you!</p>
                <button
                  onClick={() =>
                    this.setState({ login: !login, success: null })
                  }
                >
                  LOG IN
                </button>
              </div>
            )}
          </div>
          <div
            className={`loginCard animated ${
              login ? "shiftRight" : "shiftLeft"
            }`}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    loading: state.auth.loading,
    error: state.auth.error_message,
  };
}

export default withRouter(
  connect(mapStateToProps, { signIn, setAuthLoading, authError })(Login)
);

Login.propTypes = {
  auth: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  signIn: PropTypes.func.isRequired,
  setAuthLoading: PropTypes.func.isRequired,
  authError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
