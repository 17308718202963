import React from "react";

// components
import { LoadingOutlined } from "@ant-design/icons";

// styles
import {
  Container,
  Title,
  Description,
  ButtonWrap,
  BlueButton,
  YellowButton,
} from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type Shipment = Fulfillment.Shipment;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: Shipment;
  loading: boolean;
  onSendEmailClick: () => any;
  onScheduleNowClick: () => any;
}

const SelectingView: React.FC<Props> = ({
  shipment,
  loading,
  onSendEmailClick,
  onScheduleNowClick,
}) => {
  const shippingMethod = shipment?.shipping_method as SecondClosetShippingMethod;
  const hasAppointmentDate = !!shippingMethod?.date;
  const isReturn = shippingMethod?.job_type === "reverse_logistics";

  const renderButtons = () => {
    if (loading) return <LoadingOutlined />;
    return (
      <ButtonWrap>
        <BlueButton onClick={onSendEmailClick}>
          Send {hasAppointmentDate ? "Reschedule" : "Scheduling"} Email to
          Customer
        </BlueButton>
        <YellowButton onClick={onScheduleNowClick}>
          {hasAppointmentDate ? "Reschedule" : "Schedule"}{" "}
          {isReturn ? "Return" : "Delivery"} Appointment Now
        </YellowButton>
      </ButtonWrap>
    );
  };

  return (
    <Container>
      <Title>{hasAppointmentDate ? "Reschedule" : "Scheduling"} Method</Title>
      <Description>
        How would you like to {hasAppointmentDate ? "reschedule" : "scheduling"}{" "}
        your {isReturn ? "return" : "delivery"} appointment?
      </Description>
      {renderButtons()}
    </Container>
  );
};

export default SelectingView;
