import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Popconfirm, notification } from "antd";
import { useAppointmentDetails } from "../../../hooks/api/regular/appointment";
import { useShipmentDetails } from "../../../hooks/api/fulfillment/shipment";
import { get } from "lodash-es";

const CancelAppointmentButton = (props) => {
  const {
    appointmentDetails,
    updateAppointmentStatus,
  } = useAppointmentDetails();
  const { shipmentDetails, updateShipmentStatus } = useShipmentDetails();
  const shipmentID = get(props.shipment, "id");
  const isShipment = !!shipmentID;
  const error = appointmentDetails.error || shipmentDetails.error;
  const loading = appointmentDetails.loading || shipmentDetails.loading;

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: error,
      });
    }
  }, [error]);

  const onUpdateAppointmentStatus = async () => {
    const request = isShipment ? updateShipmentStatus : updateAppointmentStatus;
    const id = isShipment ? shipmentID : props.appointment.id;
    const appt = await request(id, props.newStatus);
    if (appt) props.onRefetchAppointment();
  };

  return (
    <div className="cancel-appointment-button">
      <Popconfirm
        title={
          <div>
            Are you sure you want to set the appointment as{" "}
            <b>{props.newStatus}?</b>
          </div>
        }
        onConfirm={onUpdateAppointmentStatus}
        okText="Yes"
        cancelText="No"
        disabled={props.antBtnProps.disabled}
      >
        <Button {...props.antBtnProps} loading={loading}>
          {props.children}
        </Button>
      </Popconfirm>
    </div>
  );
};

CancelAppointmentButton.propTypes = {
  appointment: PropTypes.object,
  shipment: PropTypes.object,
  newStatus: PropTypes.string.isRequired,
  antBtnProps: PropTypes.object,
  onRefetchAppointment: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

export default CancelAppointmentButton;
