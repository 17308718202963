import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { SearchOutlined, DeleteFilled } from "@ant-design/icons";
import { Spin, Input, Empty, InputNumber, Button } from "antd";
import { useCategoryIndex } from "../../../../hooks/api/regular/category";
import { isEmpty, cloneDeep } from "lodash-es";

const SelectNewSpaceItemsCard = (props) => {
  const {
    categoryIndex: categoryList,
    fetchCategoryIndex: searchCategory,
    setCategoryIndexLoading,
  } = useCategoryIndex();
  const [timer, setTimer] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchBox = useRef();

  const onMount = () => {
    setCategoryIndexLoading(false);
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  };

  useEffect(onMount, []);

  const handleOutsideClick = (e) => {
    if (searchBox.current && !searchBox.current?.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  const attachItemIfNotExist = (categoryItem) => {
    const addedItems = cloneDeep(props.addedItems);
    if (!addedItems[categoryItem.id]) {
      addedItems[categoryItem.id] = cloneDeep(categoryItem);
      addedItems[categoryItem.id].quantity = 0;
    }
    return addedItems;
  };

  const onAddCategory = (category, quantity = 1) => {
    if (quantity === "") return;
    quantity = isFinite(quantity) ? quantity : 0;
    const addedItems = attachItemIfNotExist(category);
    addedItems[category.id].quantity = quantity;
    if (addedItems[category.id].quantity <= 0) delete addedItems[category.id];
    props.onItemsChange(addedItems);
  };

  const buildItemDropdown = () => {
    const { data, loading } = categoryList;
    if (!dropdownVisible || (!data && !loading)) return null;

    let content;
    if (loading) {
      content = <Spin />;
    } else if (isEmpty(data)) {
      content = <Empty description="no data" />;
    } else {
      content = data.map((category) => (
        <div
          className="category-row"
          key={category.id}
          onClick={() => onAddCategory(category)}
        >
          {category.name}
        </div>
      ));
    }

    return <div className="item-cart-dropdown">{content}</div>;
  };

  const onSearch = (e) => {
    if (timer) clearTimeout(timer);
    const value = e.target.value;
    const newTimer = setTimeout(() => {
      searchCategory({ q: value, per_page: "ALL" });
      setDropdownVisible(true);
    }, 200);
    setTimer(newTimer);
    setSearchValue(value);
  };

  const handleDeleteItem = (itemID) => {
    const selectedItems = Object.assign({}, props.addedItems);
    delete selectedItems[itemID];
    props.onItemsChange(selectedItems);
  };

  const buildAddedItems = () => {
    let items = Object.values(props.addedItems)
      .reverse()
      .map((item) => {
        return (
          <div className="added-item-row" key={item.id}>
            <div className="added-item-name">{item.name}</div>
            <InputNumber
              value={item.quantity}
              min={0}
              defaultValue={1}
              onChange={(quantity) => onAddCategory(item, quantity)}
            />
            <Button
              onClick={() => handleDeleteItem(item.id)}
              icon={<DeleteFilled />}
              type="text"
              title="Delete item"
              danger
            />
          </div>
        );
      });
    return <div className="added-items">{items}</div>;
  };

  return (
    <div className="select-new-space-items-card">
      <div className="title">Search Items</div>
      <br />
      <div className="item-card-search">
        <div ref={searchBox}>
          <Input
            value={searchValue}
            placeholder="Bike, Nightstand..."
            onChange={onSearch}
            onFocus={() => setDropdownVisible(true)}
            suffix={<SearchOutlined />}
          />
        </div>
        {buildItemDropdown()}
      </div>

      <br />
      {!isEmpty(Object.values(props.addedItems)) && (
        <>
          <div className="title small">Items Added</div>
          {buildAddedItems()}
        </>
      )}
    </div>
  );
};

SelectNewSpaceItemsCard.propTypes = {
  addedItems: PropTypes.object.isRequired,
  onItemsChange: PropTypes.func.isRequired,
};

export default SelectNewSpaceItemsCard;
