import styled from "styled-components";
import colors from "css/colors";

export const Button = styled.div`
  cursor: pointer;
  color: ${colors.BLUE};

  &:hover {
    color: ${colors.BLUE_LIGHT};
  }
`;
