import styled from "styled-components";

export const Container = styled.div`
  margin: -20px 0 40px;
  h4,
  span {
    font-weight: normal;
  }

  .ant-radio-button-wrapper > span p {
    line-height: 1.5;
  }
`;
