import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Tag, Spin, Input, Select, Modal, Typography } from "antd";
import { toLower, startCase, isEmpty } from "lodash-es";
import PropTypes from "prop-types";
import moment from "moment";

const InventoryTable = (props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedItemIDs, setSelectedItemIDs] = useState([]);
  const [newClosetID, setNewClosetID] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const onClosetIDChange = () => {
    setSelectedItemIDs([]);
  };

  useEffect(onClosetIDChange, [props.selectedClosetID]);

  const getItemType = (stripe_id) => {
    const targetProduct = props.products.data.find((p) => p.id === stripe_id);
    return targetProduct.name;
  };

  const getCategoryName = (item) => {
    const name = item.category_item && item.category_item.name;
    return name || "-";
  };

  const renderStatus = (status) => {
    let color = "red";
    if (status === "requested") color = "gold";
    if (status === "in_storage") color = "green";
    if (status === "returned") color = "blue";
    if (status === "with_client") color = "cyan";
    return <Tag color={color}>{startCase(toLower(status))}</Tag>;
  };

  const buildColumns = () => {
    const renderTxt = (text) => text || "-";
    const renderItemType = (stripe_id, record) => (
      <a
        href={`/customers/${props.userID}/inventory-show/${record.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {getItemType(stripe_id)}
      </a>
    );

    const renderCategory = (_, record) => getCategoryName(record);
    const renderCreatedAt = (created_at) =>
      created_at ? moment(created_at).format("ll") : "-";
    return [
      {
        title: "QR Code",
        dataIndex: "qr_code",
        key: "qr_code",
        render: renderTxt,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: renderStatus,
      },
      {
        title: "Item Type",
        dataIndex: "stripe_plan_id",
        key: "type",
        render: renderItemType,
      },
      { title: "Category", key: "category", render: renderCategory },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: renderTxt,
      },
      {
        title: "Nickname",
        dataIndex: "nickname",
        key: "nickname",
        render: renderTxt,
      },
      {
        title: "Barcode",
        dataIndex: "barcode",
        key: "barcode",
        render: renderTxt,
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: renderCreatedAt,
      },
    ];
  };

  const buildCityTag = (city) => {
    const color = city === "Toronto" ? "purple" : "magenta";
    return <Tag color={color}>{city}</Tag>;
  };

  const buildMoveToClosetDropdown = () => {
    const { selectedClosetID, products, closets } = props;
    if (
      selectedClosetID === "all-items" ||
      selectedClosetID === "item-plan" ||
      !products.data ||
      closets.length === 1
    ) {
      return null;
    }

    const currentCloset = closets.find((c) => c.id === selectedClosetID);
    const currentClosetProduct = products.data.find(
      (p) => p.id === currentCloset.stripe_plan_id
    );
    const currentServiceArea = currentClosetProduct.metadata.pricing_segment;

    const rows = props.closets.map((closet) => {
      const { id, closet_name, name, stripe_plan_id } = closet;
      if (id === selectedClosetID) return null;
      const displayName = closet_name || name;
      const targetProduct = products.data.find((p) => p.id === stripe_plan_id);
      const serviceArea = targetProduct.metadata.pricing_segment;
      const city = serviceArea === "yvr" ? "Vancouver" : "Toronto";
      return (
        <Select.Option
          key={id}
          value={id}
          disabled={serviceArea !== currentServiceArea}
        >
          <div className="new-closet-row">
            <div>
              {displayName} - <span>{name}</span>
            </div>
            {buildCityTag(city)}
          </div>
        </Select.Option>
      );
    });

    return (
      <Select
        value="Move Items To"
        className="move-items-dropdown"
        disabled={isEmpty(selectedItemIDs) || isEmpty(rows)}
        onSelect={(closetID) => {
          setNewClosetID(closetID);
          setModalVisible(true);
        }}
      >
        {rows}
      </Select>
    );
  };

  const buildStatusChangeDropdown = () => {
    const rows = props.statusList.map((s) => (
      <Select.Option key={s} value={s}>
        {renderStatus(s)}
      </Select.Option>
    ));
    return (
      <Select
        value="Change Status To"
        disabled={isEmpty(selectedItemIDs)}
        onSelect={(status) => {
          setNewStatus(status);
          setStatusModalVisible(true);
        }}
      >
        {rows}
      </Select>
    );
  };

  const buildSearchAndDropdown = () => {
    return (
      <div className="closet-action">
        <div className="closet-search">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            suffix={<SearchOutlined />}
            placeholder="search items..."
          />
        </div>
        <div className="closet-buttons">
          {buildMoveToClosetDropdown()}
          {buildStatusChangeDropdown()}
        </div>
      </div>
    );
  };

  const getFilteredItems = () => {
    if (!searchText) return props.items;
    return props.items.filter((item) => {
      const {
        qr_code,
        barcode,
        status,
        category_item,
        nickname,
        name,
        description,
      } = item;
      const category = category_item && category_item.name;
      const dataString = [
        qr_code,
        barcode,
        category,
        status,
        nickname,
        name,
        description,
        startCase(toLower(status)),
      ]
        .filter(Boolean)
        .join(" ");
      return dataString.toLowerCase().includes(searchText.trim().toLowerCase());
    });
  };

  const buildMoveItemsModal = () => {
    if (!newClosetID || !props.products.data) return null;
    const targetCloset = props.closets.find(
      (closet) => closet.id === newClosetID
    );
    const targetClosetName = targetCloset.closet_name || targetCloset.name;
    const targetProduct = props.products.data.find(
      (p) => p.id === targetCloset.stripe_plan_id
    );
    const serviceArea = targetProduct.metadata.pricing_segment;
    const city = serviceArea === "yvr" ? "Vancouver" : "Toronto";
    const title = (
      <Typography.Text type="warning">
        Move items to {targetClosetName} {buildCityTag(city)}?
      </Typography.Text>
    );
    const rows = selectedItemIDs.map((itemID) => {
      const targetItem = props.items.find((i) => i.id === itemID);
      if (!targetItem) return null;
      const { id, qr_code, stripe_plan_id } = targetItem;
      const categoryName = getCategoryName(targetItem);
      const itemType = getItemType(stripe_plan_id);

      return (
        <div className="item-row" key={id}>
          <div>{qr_code || "-"}</div>
          <div>{itemType}</div>
          <div>{categoryName}</div>
        </div>
      );
    });

    return (
      <Modal
        visible={modalVisible}
        title={title}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          props.onSelectNewClosetWithItems(newClosetID, selectedItemIDs);
          setModalVisible(false);
        }}
      >
        <div className="move-items-to-new-closet-modal">
          <div className="title">
            <div>QR Code</div>
            <div>Item Type</div>
            <div>Category</div>
          </div>
          {rows}
        </div>
      </Modal>
    );
  };

  const buildChangeStatusModal = () => {
    const title = (
      <Typography.Text type="secondary">
        Change status to {renderStatus(newStatus)} ?
      </Typography.Text>
    );
    const rows = selectedItemIDs.map((itemID) => {
      const targetItem = props.items.find((i) => i.id === itemID);
      if (!targetItem) return null;
      const { id, qr_code, stripe_plan_id, status } = targetItem;
      const categoryName = getCategoryName(targetItem);
      const itemType = getItemType(stripe_plan_id);

      return (
        <div className="item-row" key={id}>
          <div className="status">{qr_code || "-"}</div>
          <div className="status">{itemType}</div>
          <div className="status">{categoryName}</div>
          <div className="status">{renderStatus(status)}</div>
        </div>
      );
    });

    return (
      <Modal
        visible={statusModalVisible}
        title={title}
        onCancel={() => setStatusModalVisible(false)}
        onOk={() => {
          props.onItemsStatusChange(newStatus, selectedItemIDs);
          setStatusModalVisible(false);
        }}
      >
        <div className="move-items-to-new-closet-modal">
          <div className="title">
            <div className="status">Qrcode</div>
            <div className="status">Item Type</div>
            <div className="status">Category</div>
            <div className="status">Current Status</div>
          </div>
          {rows}
        </div>
      </Modal>
    );
  };

  if (!props.products.data && props.products.loading) {
    return (
      <div className="inventory-table spinning">
        <Spin tip="fetching props.products..." />
      </div>
    );
  }

  if (!props.products.data) return null;

  return (
    <div className="inventory-table">
      {buildSearchAndDropdown()}
      <Table
        rowKey={(r) => r.id}
        dataSource={getFilteredItems()}
        columns={buildColumns()}
        size="small"
        scroll={{ x: 800 }}
        rowSelection={{
          selectedRowKeys: selectedItemIDs,
          onChange: (ids) => setSelectedItemIDs(ids),
          hideDefaultSelections: true,
        }}
      />
      {buildMoveItemsModal()}
      {buildChangeStatusModal()}
    </div>
  );
};

InventoryTable.propTypes = {
  items: PropTypes.array,
  userID: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired,
  selectedClosetID: PropTypes.string.isRequired,
  closets: PropTypes.array.isRequired,
  onSelectNewClosetWithItems: PropTypes.func.isRequired,
  statusList: PropTypes.array.isRequired,
  onItemsStatusChange: PropTypes.func.isRequired,
};

export default InventoryTable;
