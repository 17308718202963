import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { PlusOutlined } from "@ant-design/icons";
import CreateIkeaShipmentsModal from "../CreateIkeaShipmentsModal";
import { Button } from "antd";

const CreateIkeaShipmentsButton = ({ order, onRefetchOrder }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)}>
        Create Shipments For IKEA
      </Button>
      <CreateIkeaShipmentsModal
        order={order}
        onRefetchOrder={onRefetchOrder}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

CreateIkeaShipmentsButton.propTypes = {
  order: PropTypes.object,
  onRefetchOrder: PropTypes.func.isRequired,
};

export default CreateIkeaShipmentsButton;
