import { updateInventoryDetails } from "../../../api/inventory";
import {
  EDIT_NAME_MODAL_UPDATE_REQUEST,
  EDIT_NAME_MODAL_UPDATE_RESPONSE,
  EDIT_NAME_MODAL_CLEAR_DATA,
} from "../types";

export const editMultipleItemsCategoryAndDescription = (
  userId,
  itemIds,
  categoryIdsAndNames
) => (dispatch) => {
  dispatch({ type: EDIT_NAME_MODAL_UPDATE_REQUEST });

  const requests = itemIds.map((id) => {
    const thisItem = categoryIdsAndNames[id];
    let body = {
      inventory_item: { category_item_id: null, description: null },
    };
    if (thisItem.categoryItemId)
      body.inventory_item.category_item_id = thisItem.categoryItemId;
    if (thisItem.description)
      body.inventory_item.description = thisItem.description;

    return updateInventoryDetails(userId, id, body)
      .then(() => ({ [id]: "success" }))
      .catch((e) => ({ [id]: e }));
  });

  return Promise.all(requests).then((res) => {
    const successKeyMap = res.reduce((acc, r) => {
      const newAcc = { ...acc, ...r };
      return newAcc;
    }, {});

    return dispatch({
      type: EDIT_NAME_MODAL_UPDATE_RESPONSE,
      data: successKeyMap,
    });
  });
};

export const clearModalData = () => (dispatch) =>
  dispatch({ type: EDIT_NAME_MODAL_CLEAR_DATA });
