import React from "react";

// Components
import { StatusTag } from "./WROStatusTag.styles";

// Helpers
import { startCase } from "lodash-es";

// Interfaces
import { OrderStatus } from "interfaces/warehouseReceivingOrders";

interface Props {
  status: OrderStatus;
}

const WROStatusTag: React.FC<Props> = ({ status }) => {
  const getColorFromWROStatus = () => {
    if (status === "awaiting") return "orange";
    if (status === "processing") return "orange";
    if (status === "completed") return "green";
    if (status === "on_hold") return "grey";
    if (status === "partially_arrived") return "orange";
    if (status === "received") return "green";
    return "";
  };

  const color = getColorFromWROStatus();

  return <StatusTag color={color}>{startCase(status)}</StatusTag>;
};

export default WROStatusTag;
