import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { omit } from "lodash-es";
import { notification } from "antd";

import { Common, Fulfillment } from "@secondcloset/types";
import { EditShippingInfoModal } from "@secondcloset/web-components";
import {
  useCustomerAddressCreate,
  useCustomerAddresses,
  useCustomerCreate,
  useCustomerIndex,
} from "../../../../../hooks/api/fulfillment/customer";
import { customerUpdate } from "../../../../../api/fulfillment/order";
import { useOrderUpdate } from "../../../../../hooks/api/fulfillment/orders";
import { CreateCustomerBodyInfo } from "../../../../../pages/fulfillment/FulfillmentOrderCreatePage/SearchCustomerStep";

type Order = Fulfillment.Order;
type Customer = Fulfillment.Customer;
type CustomerAddress = Fulfillment.CustomerAddress;
type Address = Fulfillment.Order["address"];
type UpdateOrderBody = {
  customerID?: Customer["id"];
  addressID?: CustomerAddress["id"];
};

type FormData = {
  isPropagateToShipments: boolean;
  customer: Partial<Customer>;
  address: Partial<Address & { unit: string }>;
};
interface EditShippingInfoProps {
  orderID: Order["id"];
  orgID: Common.Organization["id"];
  address: CustomerAddress;
  customer: Customer;
  orderPlatform: Order["platform"];
  isModalVisible: boolean;
  isChangeCustomerDisabled: boolean;
  onIsModalVisibleChange: (visible: boolean) => unknown;
  onUpdateSuccessCallback: () => unknown;
}

const throwError = (e: Error | string) => {
  notification.error({
    message: "Oops something went wrong.",
    description: typeof e === "string" ? e : e.message,
  });
};

export const EditShippingInfo: React.FC<EditShippingInfoProps> = ({
  orgID,
  orderID,
  address,
  customer,
  orderPlatform,
  isModalVisible,
  isChangeCustomerDisabled,
  onIsModalVisibleChange,
  onUpdateSuccessCallback,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>();
  const [searchedCustomer, setSearchedCustomer] = useState("");

  const { orderUpdateDetails, updateOrder } = useOrderUpdate();
  const { mutate: updateCustomer, ...customerDetails } = useMutation(
    customerUpdate,
    {
      onSuccess: (response, variables) => {
        const isPropagateToShipments = variables?.body?.propagate_to_shipments;
        if (response?.warning_message)
          notification.warning({
            message: "Shipment(s) not updated",
            description: response.warning_message,
          });
        else
          notification.success({
            message: "Order updated successfully",
            description: `Shipping customer/address information were updated. ${
              isPropagateToShipments
                ? "Changes are applied to existing shipments."
                : ""
            }`,
          });
        onUpdateSuccessCallback();
        setSelectedCustomer(undefined);
        setSelectedAddress(undefined);
        setSearchedCustomer("");
        onIsModalVisibleChange(false);
      },
      onError: throwError,
    }
  );
  const { customerIndex, fetchCustomerIndex } = useCustomerIndex(orgID);
  const { createCustomer } = useCustomerCreate(orgID);
  const { createCustomerAddress } = useCustomerAddressCreate();
  const { customerAddresses, fetchCustomerAddresses } = useCustomerAddresses();

  const formAddress = selectedAddress ?? address;
  const formCustomer = selectedCustomer ?? customer;

  useEffect(() => {
    if (!isModalVisible) return;
    fetchCustomerIndex({ q: searchedCustomer.trim() });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isModalVisible, searchedCustomer]);

  useEffect(() => {
    if (!isModalVisible || !selectedCustomer) return;
    fetchCustomerAddresses(selectedCustomer.id);
  }, [isModalVisible, selectedCustomer]);

  const parseAddress = (
    a: Partial<(Address | CustomerAddress) & { unit: string }>
  ) => {
    const address = {
      ...a,
      address: a.unit ? `${a.unit} - ${a.address}` : a.address,
    };
    return omit(address, "unit");
  };

  const handleUpdateOrder = ({ customerID, addressID }: UpdateOrderBody) => {
    return updateOrder(orderID, {
      orders: {
        fulfillment_customer_id: customerID,
        fulfillment_customer_address_id: addressID,
      },
    })
      .then(onUpdateSuccessCallback)
      .then(() => onIsModalVisibleChange(false))
      .catch(throwError);
  };

  const handleSubmit = async (formData: FormData) => {
    const customer = formData.customer;
    const address = parseAddress(formData.address);
    return await updateCustomer({
      orderID: orderID,
      body: {
        propagate_to_shipments: !!formData?.isPropagateToShipments,
        fulfillment_customer: {
          id: formCustomer.id,
          user_id: formCustomer.user_id,
          ...customer,
          customer_address: {
            id: formAddress.id,
            ...address,
          },
        },
      },
    });
  };

  const handleCreateCustomer = ({
    name,
    email_address,
    phone_number,
  }: CreateCustomerBodyInfo) => {
    return createCustomer(name, email_address, phone_number).then(
      fetchCustomerIndex
    );
  };

  const handleCreateCustomerAddress = (
    address: Partial<CustomerAddress & { unit: string }>
  ) => {
    const body = parseAddress(address);
    return createCustomerAddress(selectedCustomer?.id, body).then(() =>
      fetchCustomerAddresses(selectedCustomer?.id)
    );
  };

  const searchCustomerProps = {
    customers: (customerIndex.data ?? []) as Customer[],
    selectedCustomerId: selectedCustomer?.id,
    customerSearchLoading: customerIndex.loading,
    onCustomerSearch: setSearchedCustomer,
    onCustomerSelect: setSelectedCustomer,
    onCreateCustomer: handleCreateCustomer,
  };

  // set select address props only when a customer is selected
  const selectAddressProps = selectedCustomer && {
    customer: selectedCustomer,
    customerAddresses: (customerAddresses.data ?? []) as CustomerAddress[],
    selectedAddress: selectedAddress,
    createCustomerAddress: handleCreateCustomerAddress,
    onAddressSelect: setSelectedAddress,
  };

  return (
    <EditShippingInfoModal
      address={formAddress}
      customer={formCustomer}
      orderPlatform={orderPlatform}
      visible={isModalVisible}
      onCancel={() => onIsModalVisibleChange(false)}
      onSubmit={handleSubmit}
      isLoading={customerDetails.isLoading || orderUpdateDetails.loading}
      isChangeCustomerDisabled={isChangeCustomerDisabled}
      onUpdateOrder={handleUpdateOrder}
      searchCustomerProps={searchCustomerProps}
      selectAddressProps={selectAddressProps}
      destroyOnClose
    />
  );
};

export default EditShippingInfo;
