import React, { useEffect, useRef } from "react";

// Components
import SearchResult from "./SearchResult";
import { SearchResultsWrapper } from "./SearchResults.styles";
import SearchResultsHeader from "./SearchResultsHeader/SearchResultsHeader";

interface Props {
  searchResults: any[];
  showResults?: boolean;
  setShowResultsValue?: (value: boolean) => void;
  handleOnSelect: (product: any) => void;
  inputID?: string;
  width?: number;
  maxHeight?: number;
  reverse?: boolean;
  bottom?: number;
}

const SearchResults: React.FC<Props> = ({
  searchResults,
  showResults,
  setShowResultsValue,
  handleOnSelect,
  inputID,
  width,
  maxHeight,
  reverse,
  bottom,
}) => {
  const searchResultsRef = useRef<any | null>(null);

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current?.contains(e.target)
      ) {
        setShowResultsValue && setShowResultsValue(false);
      } else if ((e.target as HTMLInputElement)?.id === inputID) {
        setShowResultsValue && setShowResultsValue(true);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 27) setShowResultsValue && setShowResultsValue(false);
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  return showResults && searchResults.length ? (
    <SearchResultsWrapper
      ref={searchResultsRef}
      width={width}
      maxHeight={maxHeight}
      reverse={reverse}
      bottom={bottom}
    >
      <SearchResultsHeader reverse={reverse} />
      {searchResults.map((searchResult) => (
        <SearchResult
          key={searchResult.id}
          searchResult={searchResult}
          handleOnSelect={handleOnSelect}
          width={width}
          reverse={reverse}
        />
      ))}
    </SearchResultsWrapper>
  ) : null;
};

export default SearchResults;
