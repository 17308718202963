export default class User {
  static getRoles = () => {
    return [
      { name: "Admin", value: "is_admin" },
      { name: "Client", value: "is_client" },
      { name: "Fulfillment Client", value: "is_fulfillment_client" },
    ];
  };

  static getSubroles = () => {
    return [
      { name: "Operation Specialist", value: "operation_specialist" },
      { name: "Support", value: "support" },
      { name: "Supervisor", value: "supervisor" },
      { name: "Operation Manager", value: "operation_manager" },
      { name: "Super", value: "super" },
      { name: "Driver", value: "driver" },
      { name: "Mover", value: "mover" },
    ];
  };

  static getRoleName = (role) => {
    const foundRole = User.getRoles().find((obj) => obj.value === role);
    return foundRole ? foundRole.name : "-";
  };

  static getSubroleName = (subrole) => {
    const foundSubrole = User.getSubroles().find(
      (obj) => obj.value === subrole
    );
    return foundSubrole ? foundSubrole.name : "-";
  };

  static isEndy(userID) {
    return userID === "9331e042-ef88-11e9-8f16-fbdc15083104";
  }
}
