import styled from "styled-components";
import colors from "css/colors";

export const QuantityWrapper = styled.div`
  display: flex;

  .ant-input {
    min-width: 56px;
    max-width: 56px;
    border-color: ${colors.GREY_LIGHT};
    border-radius: 6px;
    background-color: ${colors.BLUE_EXTRA_LIGHT};
  }
`;

export const ProductNameSearchWrapper = styled.div`
  .ant-select {
    min-width: 304px;
    width: 100%;
  }
`;
