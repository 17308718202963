import styled from "styled-components";
import colors from "../../../../../../../css/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 25px;
`;

export const OrderIDWrap = styled.div`
  color: ${colors.GREY};
  margin-top: 10px;
`;

export const Description = styled.div`
  margin: 10px 0 20px 0;
`;

export const ButtonWrap = styled.div`
  margin-top: 20px;
`;
