import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { PrinterOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { useAppointmentManifests } from "../../../../hooks/api/regular/appointment";
import { connect } from "react-redux";

const AppointmentManifestPrintButton = (props) => {
  const {
    appointmentManifests,
    fetchAppointmentManifests,
    clearAppointManifests,
  } = useAppointmentManifests();
  const iframeRef = useRef(null);
  const [printRequested, setPrintRequested] = useState(false);
  const mountNode =
    iframeRef.current && iframeRef.current?.contentWindow?.document;

  const clearIframe = () => {
    if (iFrameIsReady()) {
      clearAppointManifests();
      writeContentToIFrame("");
    }
  };

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(clearIframe, [props.appointments]);
  useEffect(() => {
    if (appointmentManifests.error) {
      clearAppointManifests();
      notification.error({
        message: `Unable to print manifests for ${props.date}`,
        description: appointmentManifests.error,
      });
    }
  }, [appointmentManifests]);

  const printManifest = () => {
    setPrintRequested(true);
    fetchAppointmentManifests(props.date, props.serviceArea);
  };

  const iFrameIsReady = () => {
    return (
      appointmentManifests.data && !appointmentManifests.loading && mountNode
    );
  };

  const setManifestContent = () => {
    if (!iFrameIsReady()) return;

    writeContentToIFrame(appointmentManifests.data);
  };

  const writeContentToIFrame = (content) => {
    if (!mountNode) return null;

    mountNode.open();
    mountNode.write(content);
    mountNode.close();
  };

  const onLoad = () => {
    if (!printRequested || !iFrameIsReady()) return;

    setPrintRequested(false);
    if (iframeRef.current?.contentWindow?.print)
      iframeRef.current.contentWindow.print();
  };

  const disablePrintButton = () => {
    return (
      props.appointments.loading ||
      (props.appointments.data && props.appointments.data.length < 1)
    );
  };

  return (
    <div className="AppointmentManifestsButton">
      <Button
        type="default"
        onClick={printManifest}
        disabled={disablePrintButton()}
        loading={appointmentManifests.loading}
      >
        Manifest <PrinterOutlined />
      </Button>
      <iframe
        onLoad={onLoad}
        title="manifest"
        ref={iframeRef}
        className="manifest-iframe"
      >
        {setManifestContent()}
      </iframe>
    </div>
  );
};

AppointmentManifestPrintButton.propTypes = {
  appointments: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }),
  date: PropTypes.string.isRequired,
  serviceArea: PropTypes.string.isRequired,
};

const mapStateToProps = ({ activeFacility: { service_area } }) => ({
  serviceArea: service_area,
});
export default connect(mapStateToProps)(AppointmentManifestPrintButton);
