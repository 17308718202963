import styled from "styled-components";
import colors from "css/colors";

export const EmptyNotes = styled.div`
  color: ${colors.GREY};
`;

export const NoteContainer = styled.div`
  height: 100%;
  white-space: pre-line; // for \n to form a new line
  width: 100%;
  word-break: break-word;
  min-height: 30px;
  max-height: 200px;
  overflow-y: auto;

  // for showing the scroll bar on mac without needing to hover over
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
