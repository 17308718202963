import React, { useState } from "react";
import PropTypes from "prop-types";
import { getOrderColor, isSupportGroup } from "../../../../helperFunctions";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tag, Button, Modal } from "antd";
import moment from "moment";
import { startCase, toLower } from "lodash-es";
import { connect } from "react-redux";

const OrderInfo = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    number,
    created_at,
    space_order,
    status,
    kind,
    temporary,
  } = props.order.data;
  const { id: orderID, user_id: userID } = props.order.data;
  const createdAt = moment(created_at).format("ll");
  const { role, subrole } = props.auth.user;
  const isReadOnly = !isSupportGroup(role, subrole);
  const cancellable =
    status !== "fulfilled" && status !== "cancelled_by_user" && !isReadOnly;
  const isExtension = props.order?.data?.closet_extension;

  const renderModal = () => {
    return (
      <Modal
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          props.onCancelOrder();
          setModalVisible(false);
        }}
        visible={modalVisible}
      >
        Are you sure you want to cancel this order?
      </Modal>
    );
  };

  return (
    <div className="order-info">
      <div className="left">
        <div className="order-number"> Order # {number}</div>
        <div className="label-value">
          <div className="field-label">Placed on:</div> {createdAt}
        </div>
        <div className="label-value">
          <div className="field-label">Status:</div>
          <Tag color={getOrderColor("status", status)}>
            <span className="label-value-status">
              {startCase(toLower(status))}
            </span>
          </Tag>
        </div>
        <div className="label-value">
          <div className="field-label">Type:</div>{" "}
          <Tag color={getOrderColor("type", kind)}>
            {startCase(toLower(kind))}
          </Tag>
          {space_order && !isExtension && <Tag color="purple">Space</Tag>}
          {temporary && <Tag color="cyan">Temporary</Tag>}
          {isExtension && <Tag color="pink">Closet Extension</Tag>}
        </div>
      </div>
      <div className="right">
        {!isReadOnly && (
          <Button
            className="order-button"
            href={`/orders/${orderID}/user/${userID}/order_edit`}
          >
            Edit Items <EditOutlined />
          </Button>
        )}
        {cancellable && (
          <Button
            danger
            className="order-button"
            onClick={() => setModalVisible(true)}
            loading={props.order.loading}
          >
            Cancel Order <ExclamationCircleOutlined />
          </Button>
        )}
      </div>

      {renderModal()}
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(OrderInfo);
