export function getFullAddressString(addressObj) {
  if (
    !addressObj ||
    !(typeof addressObj === "object") ||
    Array.isArray(addressObj)
  ) {
    return "";
  }
  const {
    country,
    city,
    address,
    province,
    postal_code,
    apartment_number,
  } = addressObj;

  return `${apartment_number || ""} - ${address || ""} ${city || ""} ${
    province || ""
  } ${postal_code || ""} ${country || ""}`;
}
