import React, { useState } from "react";

// helpers
import Organization from "../../../../lib/organization";
import { controlCenterApptStatusColorMapping } from "../../../../constants/badgeColors";

// components
import { DatePicker, Modal, Button, Checkbox, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

// hooks
import { useAppointmentIndexCsv } from "../../../../hooks/api/regular/appointment";

// styles
import * as S from "./styles";

const { RangePicker } = DatePicker;

interface OtifQuery {
  date_from: string;
  date_to: string;
  show_items: boolean;
  per_page: string;
  service_area: string;
  status: string;
  organization_id: string;
  excluded_organization_id: string;
}

interface Props {
  showItems: boolean;
  serviceArea: string;
  isIkea: boolean;
}

type Moment = moment.Moment;

const DownloadOtifReport: React.FC<Props> = ({
  showItems,
  serviceArea,
  isIkea,
}) => {
  const [otifReportDates, setOtifReportDates] = useState<Moment[]>([]);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [completedOnly, setCompletedOnly] = useState(true);
  const {
    appointmentIndexCsv,
    fetchAppointmentIndexCsv,
  } = useAppointmentIndexCsv();

  const disabledDate = (current: Moment) => {
    if (!otifReportDates || otifReportDates.length === 0) {
      return false;
    }
    const tooLate =
      otifReportDates[0] && current.diff(otifReportDates[0], "days") > 7;
    const tooEarly =
      otifReportDates[1] && otifReportDates[1].diff(current, "days") > 7;
    return tooEarly || tooLate;
  };

  const downloadOtifReport = async () => {
    const startDate = otifReportDates[0].format("YYYY-MM-DD");
    const endDate = otifReportDates[1].format("YYYY-MM-DD");
    setIsDownloadDisabled(true);

    const query = {
      date_from: startDate,
      date_to: endDate ? endDate : startDate,
      show_items: showItems,
      per_page: "ALL",
      service_area: serviceArea,
    } as OtifQuery;

    if (completedOnly) query.status = "completed";

    const ikeaOrgID = Organization.getIkeaOrgID();

    if (isIkea) query.organization_id = ikeaOrgID;
    else query.excluded_organization_id = ikeaOrgID;

    const appointmentsCsv = await fetchAppointmentIndexCsv(query);
    setIsDownloadDisabled(false);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(appointmentsCsv)
    );
    element.setAttribute(
      "download",
      `OTIF_Report_${startDate}_-_${endDate}.csv`
    );

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  };

  const renderDownloadModal = () => {
    return (
      <Modal
        visible={isModalVisible}
        title="Download OTIF Report"
        onOk={() => {}}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={appointmentIndexCsv.loading}
            onClick={() => {
              downloadOtifReport().then(() => setIsModalVisible(false));
            }}
          >
            Download
          </Button>,
        ]}
      >
        <S.ModalContainer>
          <Checkbox
            onChange={(e) => setCompletedOnly(e.target.checked)}
            checked={completedOnly}
          >
            <Tag
              color={controlCenterApptStatusColorMapping("completed")}
              style={{ cursor: "pointer" }}
            >
              <S.TagText>Completed</S.TagText>
            </Tag>
            appointments only
          </Checkbox>
        </S.ModalContainer>
      </Modal>
    );
  };

  return (
    <S.DownloadOtifWrapper>
      <RangePicker
        disabledDate={disabledDate}
        onCalendarChange={(dates) => {
          const [from, to] = dates ?? [];
          if (from && to) setIsDownloadDisabled(false);
          else setIsDownloadDisabled(true);
          setOtifReportDates(dates as Moment[]);
        }}
      />
      <S.DownloadOtifButton
        onClick={() => {
          setCompletedOnly(true);
          setIsModalVisible(true);
        }}
        icon={<DownloadOutlined />}
        disabled={isDownloadDisabled}
        loading={appointmentIndexCsv.loading}
      >
        Download OTIF Report
      </S.DownloadOtifButton>
      {renderDownloadModal()}
    </S.DownloadOtifWrapper>
  );
};

export default DownloadOtifReport;
