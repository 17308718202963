import React, { useState } from "react";

import { Fulfillment } from "@secondcloset/types";

import { AmazonOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { CreateShipmentDevToolModal } from "./CreateShipmentDevToolModal";

interface CreateShipmentDevToolButtonProps {
  order: Fulfillment.Order;
  onRefetchOrder: () => void;
}

export const CreateShipmentDevTool: React.FC<CreateShipmentDevToolButtonProps> = ({ order, onRefetchOrder }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsVisible(true)} icon={<AmazonOutlined />}>
        Create Shipment Dev Tool
      </Button>
      <CreateShipmentDevToolModal
        order={order}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onRefetchOrder={onRefetchOrder}
      />
    </>
  );
};
