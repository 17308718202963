import React from "react";
import PropTypes from "prop-types";
import "./InputTextWithOptions.css";

const InputTextWithOptions = ({
  value,
  onChange,
  placeholder,
  className,
  selectOptions,
}) => {
  return (
    <div className="form-group textInputContainer">
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`form-control ${className || ""}`}
      />
      <ul>{selectOptions}</ul>
    </div>
  );
};

InputTextWithOptions.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.node).isRequired,
};

InputTextWithOptions.defaultProps = {
  onChange: () => {},
  placeholder: "",
};

export default InputTextWithOptions;
