import { combineReducers } from "redux";

import stepsTracker from "./create/stepsTracker";
import selectLocation from "./create/selectLocation";
import selectAddress from "./create/selectAddress";
import addProducts from "./create/addProducts";
import shipmentDetails from "./create/shipmentDetails";
import packingDetails from "./create/packingDetails";
import packingSlips from "./create/packingSlips";

const warehouseReceivingOrderCreate = combineReducers({
  stepsTracker,
  selectLocation,
  selectAddress,
  addProducts,
  shipmentDetails,
  packingDetails,
  packingSlips,
});

export default warehouseReceivingOrderCreate;
