import { connect } from "react-redux";
import EditNameModal from "../../../pages/_common/AppointmentDetailsPage/AppointmentItemTable/EditNameModal";
import {
  editMultipleItemsCategoryAndDescription,
  clearModalData,
} from "../../../actions/regular/appointment/editNameModal";

const mapStateToProps = ({
  regular: {
    editNameModal: { updateLoading, updateResults, updateComplete },
  },
}) => ({
  updateLoading,
  updateResults,
  updateComplete,
});

export default connect(mapStateToProps, {
  editNames: editMultipleItemsCategoryAndDescription,
  clearModalData,
})(EditNameModal);
