import styled from "styled-components";
import { Typography } from "antd";

export const CreateIkeaShipmentModal = styled.div`
  .item-table {
    margin-top: 20px;
  }

  .date-picker {
    margin-top: 20px;
  }
`;

export const InOutBountSelect = styled.div`
  margin: 20px 0;
`;

export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
`;

export const SelectLabel = styled(Typography.Text)`
  grid-column-start: auto;
  font-weight: 600;
`;
