import React, { FC, ChangeEvent, useState } from "react";

// Components
import {
  CardWrapper,
  Header,
  HeaderSection,
  PalletNumber,
  StatusWrapper,
  TrackingNumberWrapper,
  Text,
  TableWrapper,
  QuantityWrapper,
} from "./IncomingShipmentCard.styles";
import StatusTag from "./StatusTag";
import { Button, Table, Input, InputNumber } from "antd";
import {
  DownloadOutlined,
  VerticalAlignBottomOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

// Hooks
import { useWroDetails } from "hooks/api/fulfillment/wro";

// Interfaces
import {
  IncomingShipment,
  ShipmentContent,
} from "interfaces/warehouseReceivingOrders";
import { APIResponse } from "@secondcloset/api-utils";

// Libs
import { startCase } from "lodash-es";
import colors from "css/colors";
import { Quantity } from "../ItemSummaryTable/ItemSummaryTable.styles";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import moment from "moment";

interface Props {
  shipment: IncomingShipment;
  index: number;
  getWROShippingLabel: (packageID: string) => Promise<APIResponse<any>>;
  isEditing: boolean;
  fetchOrder: () => void;
  handleDeleteShipment: (shipmentID: string) => void;
  handleUpdateShipment: (
    key: string,
    value: string | number,
    shipmentContentID?: string
  ) => void;
  isSingleTracking?: boolean;
  isRemovable?: boolean;
}

const IncomingShipmentCard: FC<Props> = ({
  shipment,
  index,
  getWROShippingLabel,
  isEditing,
  fetchOrder,
  handleDeleteShipment,
  handleUpdateShipment,
  isSingleTracking,
  isRemovable,
}) => {
  const [fetchingLabel, setFetchingLabel] = useState(false);
  const [updatingShipment, setUpdatingShipment] = useState(false);
  const [inputColor, setInputColor] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { updateWroDetails } = useWroDetails();
  const { parcel_type: parcelType } = shipment;

  const handleMarkAsReceived = async () => {
    setUpdatingShipment(true);
    const body = {
      warehouse_receiving_order: {
        incoming_shipments: [
          {
            id: shipment.id,
            status: "arrived",
            arrival_date: moment().format("D-M-YYYY"),
          },
        ],
      },
    };
    await updateWroDetails(shipment.warehouse_receiving_order_id, body);
    await fetchOrder();
    setUpdatingShipment(false);
  };

  const updateInputColor = (quantity: number) => {
    const shipmentQuantity = Number(
      shipment.shipment_contents[0].quantity_of_packages
    );

    if (quantity > shipmentQuantity) setInputColor(colors.GREEN);
    else if (quantity < shipmentQuantity) setInputColor(colors.RED);
    else setInputColor(colors.CHARCOAL);
  };

  const buildColumns = () => {
    const renderQuantity = (quantity: number, record: ShipmentContent) =>
      isEditing ? (
        <QuantityWrapper color={inputColor}>
          <InputNumber
            defaultValue={quantity}
            onChange={(quantity) => {
              handleUpdateShipment(
                "quantity_of_packages",
                Number(quantity) > 0 ? Number(quantity) : 1,
                record.id
              );
              updateInputColor(Number(quantity));
            }}
            min={1}
            max={
              parcelType === "pallet" &&
              shipment.shipment_contents[0].product_packaging_level.level ===
                "pallet"
                ? 1
                : undefined
            }
          />
        </QuantityWrapper>
      ) : (
        <Quantity>{quantity}</Quantity>
      );

    const renderName = (_: string, record: ShipmentContent) =>
      `${record.product.name}-${startCase(
        record?.product_packaging_level?.level?.replace("_", " ")
      ).replace(" ", "")}-QTY-${
        record.product_packaging_level.products_per_package
      }`;

    return [
      {
        title: "Qty",
        dataIndex: "quantity_of_packages",
        key: "quantity_of_packages",
        render: renderQuantity,
        width: "10%",
      },
      { title: "Product Name", key: "name", render: renderName, width: "50%" },
      {
        title: "SKU",
        dataIndex: ["product_packaging_level", "sku"],
        key: "sku",
        width: "20%",
      },
      {
        title: "SCID",
        dataIndex: ["product", "scid"],
        key: "scid",
        width: "20%",
      },
      // {
      //   title: `${parcelType === "pallet" ? "Pallet" : "Box"} ID`,
      //   dataIndex: "pallet_id",
      //   key: "pallet_id",
      //   render: (text: string) => (text ? text : "N/A"),
      // },
      // {
      //   title: `Pallet Location`,
      //   dataIndex: "pallet_location",
      //   key: "pallet_location",
      //   render: (text: string) => (text ? text : "N/A"),
      // },
    ];
  };

  const handleOnChangeTrackingNumber = (e: ChangeEvent<HTMLInputElement>) => {
    handleUpdateShipment(
      "tracking_info",
      e.target.value,
      shipment.shipment_contents[0].id
    );
  };

  const renderButton = () => {
    if (isEditing)
      return (
        <Button
          danger
          type="primary"
          onClick={() => setIsModalVisible(true)}
          icon={<DeleteOutlined />}
          disabled={!isRemovable}
        >
          Delete {parcelType === "pallet" ? "Pallet" : "Box"}
        </Button>
      );
    const { status } = shipment;
    if (status === "awaiting")
      return (
        <Button
          type="primary"
          onClick={handleMarkAsReceived}
          icon={<VerticalAlignBottomOutlined />}
          loading={updatingShipment}
        >
          Mark as Received
        </Button>
      );
    // To be enabled when we have an inspection flow
    // if (status === "arrived")
    //   return (
    //     <Button
    //       type="primary"
    //       onClick={() => console.log("inspect")}
    //       icon={<EyeOutlined />}
    //     >
    //       Inspect
    //     </Button>
    //   );
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <CardWrapper>
      <Header>
        <HeaderSection>
          <PalletNumber>
            {parcelType === "pallet" ? "Pallet" : "Box"} #{index + 1}
          </PalletNumber>
          <StatusWrapper>
            <StatusTag status={shipment.status} />
          </StatusWrapper>
          {isSingleTracking ? null : (
            <TrackingNumberWrapper>
              <Text minWidth={62}>Tracking # </Text>
              {isEditing ? (
                <Input
                  onChange={handleOnChangeTrackingNumber}
                  defaultValue={shipment.tracking_info}
                />
              ) : (
                <Text fontWeight={400}>{` ${shipment.tracking_info}`}</Text>
              )}
            </TrackingNumberWrapper>
          )}
        </HeaderSection>
        <HeaderSection>
          {renderButton()}
          <Button
            onClick={async () => {
              setFetchingLabel(true);
              await getWROShippingLabel(shipment.id);
              setFetchingLabel(false);
            }}
            icon={<DownloadOutlined />}
            loading={fetchingLabel}
          >
            Label
          </Button>
        </HeaderSection>
      </Header>
      <TableWrapper overflowAuto>
        <Table
          dataSource={shipment.shipment_contents}
          columns={buildColumns()}
          pagination={false}
          rowKey="id"
          bordered
        />
      </TableWrapper>

      <ConfirmDeleteModal
        palletNumber={index + 1}
        handleDeleteShipment={() => handleDeleteShipment(shipment.id)}
        shipment={shipment}
        visible={isModalVisible}
        onCloseModal={onCloseModal}
      />
    </CardWrapper>
  );
};

export default IncomingShipmentCard;
