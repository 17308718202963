import React from "react";
import { Drawer, Button, Collapse } from "antd";
import { ButtonWrapper } from "./styles";
import { ExperimentOutlined } from "@ant-design/icons";
import DevToolsFeatureFlags from "./DevToolsSubItems";

const { Panel } = Collapse;

const DevToolsDrawer: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return process.env.REACT_APP_ENV === "staging" ? (
    <>
      <ButtonWrapper>
        <Button
          title="DevTools"
          type="primary"
          shape="circle"
          onClick={showDrawer}
          icon={<ExperimentOutlined />}
        />
      </ButtonWrapper>
      <Drawer
        width={400}
        title="Developer Tools"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Collapse defaultActiveKey={["featureFlags"]}>
          <Panel key="featureFlags" header="Feature Flags">
            <DevToolsFeatureFlags />
          </Panel>
        </Collapse>
      </Drawer>
    </>
  ) : null;
};

export default DevToolsDrawer;
