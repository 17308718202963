import React, { useState, useEffect } from "react";

// components
import {
  Modal,
  Input,
  Button,
  Alert,
  notification,
  Checkbox,
  Select,
} from "antd";
import BlueEditButton from "../BlueEditButton";

// hooks
import { useCustomerDetails } from "../../../../../hooks/api/regular/customer";
import { useMutation } from "react-query-legacy";

// types
import { ItemCoveragePlan } from "../../../../../api/storage/itemCoverage";
import { Common } from "@secondcloset/types";

// api
import {
  updateCoveragePlanPreference,
  updateCoveragePlanSubscription,
} from "../../../../../api/storage/itemCoverage";

type User = Common.User;
const { Option } = Select;

interface Props {
  user: User;
  onUpdateUserSuccess: () => any;
  userSubscriptionID?: string | null;
  userPlanPreferenceID?: string | null;
  itemCoveragePlanIndex?: ItemCoveragePlan[];
  onUpdateProtectionPlanSuccess: () => any;
}

const EditUserDetailsButton: React.FC<Props> = ({
  user,
  onUpdateUserSuccess,
  userSubscriptionID,
  userPlanPreferenceID,
  itemCoveragePlanIndex,
  onUpdateProtectionPlanSuccess,
}) => {
  const activeCoverageID = userSubscriptionID || userPlanPreferenceID;
  const [modalVisible, setModalVisible] = useState(false);
  const [newIsBusinessUser, setNewIsBusinessUser] = useState(false);
  const [newBusinessName, setNewBusinessName] = useState("");
  const [newBusinessType, setNewBusinessType] = useState("");
  const [newCoveragePlanID, setNewCoveragePlanID] = useState(activeCoverageID);
  const [submitError, setSubmitError] = useState("");
  const {
    customerDetails,
    updateCustomerDetails,
    setCustomerDetailsLoading,
  } = useCustomerDetails();

  const onError = (error: string | null) => {
    if (!error) return;
    return notification.error({
      message: "Error",
      description: error,
    });
  };

  const [
    onUpdateCoveragePlanPreference,
    updatedCoveragePlanPreference,
  ] = useMutation(updateCoveragePlanPreference, {
    onSuccess: () => {
      setModalVisible(false);
      onUpdateProtectionPlanSuccess();
    },
    onError,
  });
  const [
    onUpdateCoveragePlanSubscription,
    updatedCoveragePlanSubscription,
  ] = useMutation(updateCoveragePlanSubscription, {
    onSuccess: () => {
      setModalVisible(false);
      onUpdateProtectionPlanSuccess();
    },
    onError,
  });

  useEffect(() => {
    setCustomerDetailsLoading(false);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setSubmitError("");
  }, [newIsBusinessUser, newBusinessName, newBusinessType]);
  useEffect(() => {
    if (customerDetails.error) onError(customerDetails.error);
  }, [customerDetails.error]);

  const renderField = (
    label: string,
    onChange: (value: string) => any,
    value: string
  ) => {
    return (
      <div className="field-input">
        <div className="label">{label}</div>
        <Input value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
    );
  };

  const onChangeCoverageID = async () => {
    if (newCoveragePlanID === activeCoverageID) return true;
    const body = {
      userID: user.id,
      coveragePlanID: newCoveragePlanID as string,
    };
    if (!userSubscriptionID) await onUpdateCoveragePlanPreference(body);
    else await onUpdateCoveragePlanSubscription(body);
    return true;
  };

  const onSave = async () => {
    const business_user = newIsBusinessUser;
    const business_name = newBusinessName?.trim();
    const business_type = newBusinessType?.trim();
    if (!business_name && business_user)
      return setSubmitError("Business name cannot be empty");
    if (!business_type && business_user)
      return setSubmitError("Business type cannot be empty");
    const body = { user: { business_user, business_name, business_type } };
    const updatedCustomerDetails = await updateCustomerDetails(user.id, body);
    const updatedCoverageID = await onChangeCoverageID();
    if (updatedCustomerDetails) onUpdateUserSuccess();
    if (updatedCustomerDetails && updatedCoverageID) setModalVisible(false);
  };

  const renderError = () => {
    if (submitError) {
      return <Alert message={submitError} type="error" banner />;
    }
  };

  const getPriceString = (amountInCents: number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    const formattedPrice = formatter.format(amountInCents / 100);
    return formattedPrice + "/mo.";
  };

  const getOptionString = (option: ItemCoveragePlan) => {
    const { name, price, description } = option;
    return `${name} - ${getPriceString(price)} - ${description}`;
  };

  const renderCoveragePlanDropdown = () => {
    return (
      <div className="field-input">
        <div className="label">Protection Plan</div>
        <Select
          value={newCoveragePlanID || "Select Protection Plan"}
          onChange={setNewCoveragePlanID}
        >
          {itemCoveragePlanIndex?.map((p) => (
            <Option key={p.id} value={p.id}>
              {getOptionString(p)}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderModal = () => {
    const coveragePlanLoading =
      updatedCoveragePlanPreference.status === "loading" ||
      updatedCoveragePlanSubscription.status === "loading";

    return (
      <Modal
        title="User Details"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="edit-user-details-modal">
          {renderError()}
          <Checkbox
            checked={!!newIsBusinessUser}
            onChange={(e) => setNewIsBusinessUser(e.target.checked)}
            className="is-business-checkbox"
          >
            Is Business User?
          </Checkbox>
          {renderField(
            "Business name",
            (v) => setNewBusinessName(v),
            newBusinessName
          )}
          {renderField(
            "Business type",
            (v) => setNewBusinessType(v),
            newBusinessType
          )}
          {renderCoveragePlanDropdown()}
          <div className="action-buttons">
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button
              onClick={onSave}
              type="primary"
              loading={customerDetails.loading || coveragePlanLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const onEditClick = () => {
    const { business_user, business_name, business_type } = user;
    setNewIsBusinessUser(business_user);
    setNewBusinessName(business_name || "");
    setNewBusinessType(business_type || "");
    setSubmitError("");
    setNewCoveragePlanID(activeCoverageID);
    setModalVisible(true);
  };

  return (
    <div>
      <BlueEditButton onClick={onEditClick}>Change</BlueEditButton>
      {renderModal()}
    </div>
  );
};

export default EditUserDetailsButton;
