import React from "react";

import { ServiceLevel } from "@secondcloset/fulfillment-utils";
import { Fulfillment } from "@secondcloset/types";

import { Select } from "antd";

import * as S from "./styles";

type ServiceCode = Fulfillment.ScServiceCode;

interface SelectServiceLevelProps {
  serviceLevel: ServiceCode;
  onSelectServiceLevel: (serviceLevel: ServiceCode) => void;
  isIkea?: boolean;
}

export const SelectServiceLevel: React.FC<SelectServiceLevelProps> = ({
  serviceLevel,
  onSelectServiceLevel,
  isIkea,
}) => {
  const SERVICE_LEVEL_OPTIONS = ServiceLevel.getServiceLevelList(isIkea ? "ikea" : "second_closet");
  const { Option } = Select;

  return (
    <S.SelectContainer>
      <S.SelectLabel>Select Service Level:</S.SelectLabel>
      <Select defaultValue={serviceLevel} onChange={onSelectServiceLevel} style={{ width: 275 }}>
        {SERVICE_LEVEL_OPTIONS.map((serviceLevelOption) => (
          <Option key={serviceLevelOption.key} value={serviceLevelOption.key} label={serviceLevelOption.label}>
            {serviceLevelOption.label}
          </Option>
        ))}
      </Select>
    </S.SelectContainer>
  );
};
