import React, { FC } from "react";

// Components
import { Wrapper, StatusText } from "./StatusTag.styles";

// Interfaces
import { OrderStatus } from "interfaces/warehouseReceivingOrders";
import colors from "css/colors";

interface Props {
  status: OrderStatus;
}

const StatusTag: FC<Props> = ({ status }) => {
  const getTextAndColorFromOrderStatus = () => {
    if (status === "awaiting")
      return { color: colors.GREY, backgroundColor: "", text: "Awaiting" };
    if (status === "arrived")
      return { color: colors.BLUE, backgroundColor: "", text: "Received" };
    if (status === "completed")
      return {
        color: colors.GREEN_ANTD,
        backgroundColor: colors.GREEN_ANTD_LIGHT,
        text: "Completed",
      };
    return { color: "", text: "N/A" };
  };

  return (
    <Wrapper
      color={getTextAndColorFromOrderStatus().color}
      backgroundColor={getTextAndColorFromOrderStatus().backgroundColor}
    >
      <StatusText color={getTextAndColorFromOrderStatus().color}>
        {getTextAndColorFromOrderStatus().text}
      </StatusText>
    </Wrapper>
  );
};

export default StatusTag;
