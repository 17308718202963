import React, { useState, useEffect } from "react";

// hooks
import { Link, useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query-legacy";
import { useOrganizationDetails } from "hooks/api/fulfillment/organization";

// helpers
import { objectToFormData } from "object-to-formdata";

// api
import {
  fetchASNDetails,
  updateASN,
  deleteASN,
  fetchASNProcessLogs,
} from "../../../api/fulfillment/asn";

// hooks
import { useASNLabelDownloadURL } from "../../../hooks/api/fulfillment/asn";
import {
  useFacilityIndex,
  useFacilitiesV2ToV1Adaptor,
} from "../../../hooks/api/common/useFacilityIndex";

// components
import { ASNDetails } from "@secondcloset/web-components";
import UploadBolModal from "./UploadBolModal/UploadBolModal";
import { message, notification } from "antd";

// styles
import * as S from "./styles";

const FulfillmentASNDetailsPage: React.FC = () => {
  const history = useHistory();
  const { asnID } = useParams<{ asnID: string }>();
  if (!asnID) history.push("/fulfillment/asns");
  const [isBolModalVisible, setIsBolModalVisible] = useState(false);
  const facilityIndex = useFacilityIndex();
  const facilities = useFacilitiesV2ToV1Adaptor(facilityIndex.data ?? []);

  const {
    fetchASNLabelDownloadURL,
    ASNLabelDownloadURL,
  } = useASNLabelDownloadURL();

  const {
    organizationDetails,
    fetchOrganizationDetails,
  } = useOrganizationDetails();

  const goToOrgASNIndex = (orgID: string) =>
    history.push(`/organizations/${orgID}/asns`);

  const asnQueryOptions = {
    with_items: true,
    with_timeline: true,
  };

  const asnDetails = useQuery(
    ["asnDetails", { asnID, query: asnQueryOptions }],
    fetchASNDetails
  );

  const organizationID = (asnDetails?.data as any)?.organization_id;

  useEffect(() => {
    if (organizationID) fetchOrganizationDetails(organizationID);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [organizationID]);

  const [
    getAsnLogs,
    { data: asnLogs, status: asnLogsStatus, error: asnLogsError },
  ] = useMutation(fetchASNProcessLogs);

  const [
    doUpdateAsnDetails,
    { status: asnUpdateStatus, error: asnUpdateError },
  ] = useMutation(updateASN, {
    onSuccess: () => {
      message.success(`${asnDetails.data?.number} updated successfully`);
    },
    onSettled: async () => {
      await asnDetails.refetch();
    },
  });

  const [
    doDeleteASN,
    { status: asnDeleteStatus, error: asnDeleteError },
  ] = useMutation(deleteASN, {
    onSuccess: () => {
      goToOrgASNIndex(organizationID);
      message.success(`${asnDetails.data?.number} deleted successfully`);
    },
  });

  const isAsnUpdating = asnUpdateStatus === "loading";
  const isAsnDeleting = asnDeleteStatus === "loading";

  const loading =
    asnDetails.isFetching ||
    facilityIndex.isFetching ||
    organizationDetails.loading ||
    isAsnUpdating ||
    isAsnDeleting;

  const error =
    asnDetails.error ||
    facilityIndex.error ||
    organizationDetails.error ||
    ASNLabelDownloadURL.error ||
    asnUpdateError ||
    asnDeleteError ||
    asnLogsError;

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error",
        description: error,
      });
    }
  }, [error]);

  const onDownloadAsnLabel = async () => {
    const downloadURL = await fetchASNLabelDownloadURL(asnID);
    if (downloadURL) {
      const link = document.createElement("a");
      link.href = downloadURL;
      link.setAttribute(
        "download",
        `${asnDetails.data?.number || "ASN_Label"}.pdf`
      );
      link.dispatchEvent(
        new MouseEvent(`click`, {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    }
  };

  const onBolSubmit = async (file: File) => {
    const asnOptions = {
      asn: { bols_attributes: [{ file }] },
    };
    const body = objectToFormData(asnOptions);
    await doUpdateAsnDetails({
      body,
      asnID,
      options: { with_response: false },
    });
    setIsBolModalVisible(false);
  };

  return (
    <S.Container>
      <ASNDetails
        key={asnDetails.data?.id}
        isAdmin
        asnDetails={asnDetails.data}
        isLoading={loading}
        isDownloadingLabels={ASNLabelDownloadURL.loading}
        facilities={facilities}
        organizationDetails={organizationDetails.data}
        onDeleteASN={() => doDeleteASN(asnID)}
        onUpdateASN={(body: { [key: string]: any }) =>
          doUpdateAsnDetails({ body, asnID, options: { with_response: false } })
        }
        onContinueASNClick={() =>
          history.push(`/inventory/asn/create/${asnID}`)
        }
        onUploadBolClick={() => setIsBolModalVisible(true)}
        onAsnIndexClick={() => goToOrgASNIndex(organizationID)}
        onDownloadAsnLabel={onDownloadAsnLabel}
        renderProductLink={(id, name) => (
          <Link to={`/organizations/${organizationID}/products/${id}`}>
            {name}
          </Link>
        )}
        logs={asnLogs?.items}
        loadingLogs={asnLogsStatus === "loading"}
        logsPagination={asnLogs?.meta}
        onPageClick={(pageNumber: number) =>
          getAsnLogs({ external_asn_id: asnID, page: `${pageNumber}` })
        }
        asnProcessLogVisible={true}
      />
      <UploadBolModal
        isVisible={isBolModalVisible}
        onClose={() => setIsBolModalVisible(false)}
        onSubmit={onBolSubmit}
        isLoading={loading}
      />
    </S.Container>
  );
};

export default FulfillmentASNDetailsPage;
