/**
 * Subroles
 */
const GOD = "god";
const SUPER = "super";
const OPS_SPECIALIST = "operation_specialist";
const OPS_MANAGER = "operation_manager";
const SUPPORT = "support";
const SUPERVISOR = "supervisor";
const DRIVER = "driver";
const MOVER = "mover";

/**
 * Subrole Combinations -- need to rethink how to write this part,
 * currently manually inputting the possible combinations
 */
const GODS_ONLY = [GOD];
const SUPER_ONLY = [GOD, SUPER];
const MANAGEMENT = [GOD, SUPER, OPS_MANAGER];
const OPS_GROUP = [GOD, SUPER, OPS_MANAGER, OPS_SPECIALIST];
const SUPPORT_GROUP = [GOD, SUPER, OPS_MANAGER, SUPPORT, SUPERVISOR];
const SUPPORT_ESCALATED = [GOD, SUPER, OPS_MANAGER, SUPERVISOR];
// not actually used for auth, just useful to have a list of all roles
const FULL_ACCESS = [
  GOD,
  SUPER,
  OPS_SPECIALIST,
  OPS_MANAGER,
  SUPPORT,
  SUPERVISOR,
  DRIVER,
  MOVER,
];

/**
 * Roles
 */
const ADMIN = "is_admin";
const CLIENT = "is_client";
const FULFILLMENT_CLIENT = "is_fulfillment_client";

/**
 * Role Combinations
 */
const ALL_ROLES = [ADMIN, CLIENT, FULFILLMENT_CLIENT];
const ADMINS = [ADMIN];

export {
  GODS_ONLY,
  SUPER_ONLY,
  MANAGEMENT,
  OPS_GROUP,
  SUPPORT_GROUP,
  SUPPORT_ESCALATED,
  FULL_ACCESS,
  ALL_ROLES,
  ADMINS,
};
