import React, { useEffect } from "react";

// lib
import Shipment from "../../../../../../lib/fulfillment/shipment";

// components
import { Modal, notification } from "antd";
import { Notes } from "@secondcloset/web-components";

// hooks
import { useUpdateShipment } from "../../../../../../hooks/api/fulfillment/shipment";

// styles
import { Container } from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => any;
  shipment: ShipmentType;
  onRefetch: () => any;
}

const NotesModal: React.FC<Props> = ({
  visible,
  setVisible,
  shipment,
  onRefetch,
}) => {
  const { updateShipmentStatus, updateShipment } = useUpdateShipment();

  const error = updateShipmentStatus.error;

  useEffect(() => {
    if (error) notification.error({ message: error });
  }, [error]);

  const onUpdateNotes = (newNotes: string) => {
    const body = { notes_attributes: [{ text: newNotes }] };
    updateShipment(shipment?.id || "", body).then(() => {
      setVisible(false);
      onRefetch();
    });
  };

  const renderNotes = () => {
    const lastWrittenNote = Shipment.getLastWrittenNote(shipment?.notes);
    return (
      <Notes
        internalNotes={lastWrittenNote}
        notesTitle={"Notes"}
        onUpdateNotes={onUpdateNotes}
        isUploadDisabled
        minRows={2}
        size="small"
        noteLoading={updateShipmentStatus.loading}
      />
    );
  };

  return (
    <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
      <Container>{renderNotes()}</Container>
    </Modal>
  );
};

export default NotesModal;
