export function formatNumber(number) {
  if (!number) {
    return "n/a";
  }
  const noHyphenNumber = number.replace(/-/g, "");
  if (noHyphenNumber.length === 10) {
    let m = noHyphenNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  } else {
    return number;
  }
}
