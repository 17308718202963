import { connect } from "react-redux";
import AssignQrModal from "../../../pages/_common/AppointmentDetailsPage/AppointmentItemTable/AssignQrModal";
import {
  assignQrToMultipleItems,
  clearModalData,
} from "../../../actions/regular/appointment/assignQrModal";

const mapStateToProps = ({
  regular: {
    assignQrModal: { updateLoading, updateResults, updateComplete },
  },
}) => ({
  updateLoading,
  updateResults,
  updateComplete,
});

export default connect(mapStateToProps, {
  assignQrToMultipleItems,
  clearModalData,
})(AssignQrModal);
