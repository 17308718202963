import { ServiceArea } from "@secondcloset/types/src/_common";

import { getAPIFromDomain } from "../../../lib/api";

export type ServiceAreaResponse = {
  id: number;
  facilities: ServiceArea[];
  name: ServiceArea;
  timezone: string;
  address: { country: string; province: string };
  standard_boundary: { latitude: number; longitude: number }[];
  extended_boundary: { latitude: number; longitude: number }[];
  humanized_name: string;
  created_at: string;
  updated_at: string;
};

export const fetchServiceArea = async () => {
  const api = getAPIFromDomain("logistics");
  const url = "/service_areas";
  const response = await api.get(url);

  return response.data as ServiceAreaResponse[];
};
