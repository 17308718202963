// Action types
const SET_IS_RETURN = "SHIPMENT_CREATE_FLOW/SET_IS_RETURN";
const SET_ACTIVE_STEP_KEY = "SHIPMENT_CREATE_FLOW/SET_ACTIVE_STEP_KEY";
const SET_ORDER = "SHIPMENT_CREATE_FLOW/SET_ORDER";
const SET_SELECTED_ORDER_ITEM_IDS =
  "SHIPMENT_CREATE_FLOW/SET_SELECTED_ORDER_ITEM_IDS";
const SET_SHIPMENT_CARRIER = "SHIPMENT_CREATE_FLOW/SET_SHIPMENT_CARRIER";
const SET_SHIPMENT_DATE = "SHIPMENT_CREATE_FLOW/SET_SHIPMENT_DATE";
const SET_SHIPMENT_TIME = "SHIPMENT_CREATE_FLOW/SET_SHIPMENT_TIME";
const SET_IS_SEND_EMAIL = "SHIPMENT_CREATE_FLOW/SET_IS_SEND_EMAIL";
const SET_RETURN_REASONS = "SHIPMENT_CREATE_FLOW/SET_RETURN_REASONS";
const SET_IS_FULL_REFUND = "SHIPMENT_CREATE_FLOW/SET_IS_FULL_REFUND";
const SET_IS_EXCHANGE = "SHIPMENT_CREATE_FLOW/SET_IS_EXCHANGE";
const SET_IS_UNOPENED_CANCELLATION =
  "SHIPMENT_CREATE_FLOW/SET_IS_UNOPENED_CANCELLATION";
const SET_IS_RETURN_WARNING_VISIBLE =
  "SHIPMENT_CREATE_FLOW/SET_IS_RETURN_WARNING_VISIBLE";
const SET_SERVICE_CATEGORY = "SHIPMENT_CREATE_FLOW/SET_SERVICE_CATEGORY";
const SET_PARTNER = "SHIPMENT_CREATE_FLOW/SET_PARTNER";
const SET_NOTES = "SHIPMENT_CREATE_FLOW/SET_NOTES";
const SET_PACKAGE_DETAILS = "SHIPMENT_CREATE_FLOW/SET_PACKAGE_DETAILS";
const RESET_PACKAGE_DETAILS = "SHIPMENT_CREATE_FLOW/RESET_PACKAGE_DETAILS";
const SET_IS_RETURN_WITH_ORIGINAL_PACKAGE =
  "SHIPMENT_CREATE_FLOW/SET_IS_RETURN_WITH_ORIGINAL_PACKAGE";
const SET_IS_PARTIAL_RETURN = "SHIPMENT_CREATE_FLOW/SET_IS_PARTIAL_RETURN";

const RESET = "SHIPMENT_CREATE_FLOW/RESET";

// Action Creators
export function actionSetIsReturn(isReturn) {
  return { type: SET_IS_RETURN, isReturn };
}

export function actionSetActiveStepKey(stepKey) {
  return { type: SET_ACTIVE_STEP_KEY, stepKey };
}

export function actionSetOrder(order) {
  return { type: SET_ORDER, order };
}

export function actionSetSelectedOrderItemIDs(itemIDs) {
  return { type: SET_SELECTED_ORDER_ITEM_IDS, itemIDs };
}

export function actionSetShipmentCarrier(carrier) {
  return { type: SET_SHIPMENT_CARRIER, carrier };
}

export function actionSetShipmentDate(date) {
  return { type: SET_SHIPMENT_DATE, date };
}

export function actionSetShipmentTime(time) {
  return { type: SET_SHIPMENT_TIME, time };
}

export function actionSetIsSendEmail(isSendEmail) {
  return { type: SET_IS_SEND_EMAIL, isSendEmail };
}

export function actionReset() {
  return { type: RESET };
}

export function actionSetReturnReasons(returnReasons) {
  return {
    type: SET_RETURN_REASONS,
    returnReasons,
  };
}

export function actionSetIsFullRefund(isFullRefund) {
  return {
    type: SET_IS_FULL_REFUND,
    isFullRefund,
  };
}

export function actionSetIsExchange(isExchange) {
  return {
    type: SET_IS_EXCHANGE,
    isExchange,
  };
}

export function actionSetIsUnopenedCancellation(isUnopenedCancellation) {
  return {
    type: SET_IS_UNOPENED_CANCELLATION,
    isUnopenedCancellation,
  };
}

export function actionSetIsReturnWarningVisible(isVisible) {
  return {
    type: SET_IS_RETURN_WARNING_VISIBLE,
    isVisible,
  };
}

export function actionSetServiceCategory(serviceCategory) {
  return { type: SET_SERVICE_CATEGORY, serviceCategory };
}

export function actionSetPartner(partner) {
  return { type: SET_PARTNER, partner };
}

export function actionSetNotes(notes) {
  return { type: SET_NOTES, notes };
}

export function actionSetPackageDetails(packageDetails) {
  return { type: SET_PACKAGE_DETAILS, packageDetails };
}

export function actionResetPackageDetails() {
  return { type: RESET_PACKAGE_DETAILS };
}

export const actionSetIsReturnWithOriginalPackage = (
  isReturnWithOriginalPackage
) => ({
  type: SET_IS_RETURN_WITH_ORIGINAL_PACKAGE,
  isReturnWithOriginalPackage,
});

export const actionSetIsPartialReturn = (isPartialReturn) => ({
  type: SET_IS_PARTIAL_RETURN,
  isPartialReturn,
});

// Reducer
export default function reducer(state, action) {
  const INITIAL_STATE = {
    isReturn: false,
    activeStepKey: null,
    selectedOrderItemIDs: [],
    order: null,
    shipmentDate: null,
    shipmentTime: null,
    isSendEmail: true,
    shipmentCarrier: null,
    returnReasons: [],
    isFullRefund: false,
    isExchange: false,
    isReturnWarningVisible: false,
    isUnopenedCancellation: false,
    serviceCategory: undefined,
    partner: "",
    notes: "",
    packageDetails: [],
    isReturnWithOriginalPackage: undefined,
  };
  if (!state) state = INITIAL_STATE;

  switch (action.type) {
    case SET_IS_RETURN:
      return { ...state, isReturn: action.isReturn };
    case SET_ACTIVE_STEP_KEY:
      return { ...state, activeStepKey: action.stepKey };
    case SET_ORDER: {
      const order = action.order;
      return { ...state, order };
    }
    case SET_SELECTED_ORDER_ITEM_IDS: {
      return {
        ...state,
        selectedOrderItemIDs: action.itemIDs,
        shipmentCarrier: null,
        shipmentDate: null,
        shipmentTime: null,
      };
    }
    case SET_SHIPMENT_CARRIER:
      return {
        ...state,
        shipmentCarrier: action.carrier,
        shipmentDate: null,
        shipmentTime: null,
      };
    case SET_SHIPMENT_DATE:
      return { ...state, shipmentDate: action.date };
    case SET_SHIPMENT_TIME:
      return { ...state, shipmentTime: action.time };
    case SET_IS_SEND_EMAIL:
      return {
        ...state,
        isSendEmail: action.isSendEmail,
        shipmentDate: null,
        shipmentTime: null,
      };
    case RESET:
      return INITIAL_STATE;
    case SET_RETURN_REASONS: {
      const returnReasons = action.returnReasons;
      return {
        ...state,
        returnReasons,
      };
    }
    case SET_IS_FULL_REFUND:
      return {
        ...state,
        isFullRefund: action.isFullRefund,
        isExchange: false,
        isReturnWarningVisible: false,
      };
    case SET_IS_EXCHANGE:
      return {
        ...state,
        isExchange: action.isExchange,
        isFullRefund: false,
        isReturnWarningVisible: false,
      };
    case SET_IS_UNOPENED_CANCELLATION:
      return {
        ...state,
        isUnopenedCancellation: action.isUnopenedCancellation,
      };
    case SET_IS_RETURN_WARNING_VISIBLE:
      return {
        ...state,
        isReturnWarningVisible: action.isVisible,
      };
    case SET_SERVICE_CATEGORY:
      return {
        ...state,
        serviceCategory: action.serviceCategory,
      };
    case SET_PARTNER:
      return {
        ...state,
        partner: action.partner,
      };
    case SET_NOTES:
      return {
        ...state,
        notes: action.notes,
      };
    case SET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: [...action.packageDetails],
      };
    case RESET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: INITIAL_STATE.packageDetails,
      };
    case SET_IS_RETURN_WITH_ORIGINAL_PACKAGE:
      return {
        ...state,
        isReturnWithOriginalPackage: action.isReturnWithOriginalPackage,
      };
    case SET_IS_PARTIAL_RETURN:
      return { ...state, isPartialReturn: action.isPartialReturn };

    default:
      return state;
  }
}
