import React from "react";

// components
import { Modal } from "antd";
import { PackageSummary } from "@secondcloset/web-components";

// styles
import * as S from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type Package = Fulfillment.Package;

interface Props {
  packages: Package[];
  isVisible?: boolean;
  onSubmit: () => any;
  onClose: () => any;
}

const PackageSummaryModal: React.FC<Props> = ({
  packages,
  isVisible,
  onSubmit,
  onClose,
}) => {
  const renderInstructions = () => {
    return (
      <S.Instructions direction="vertical">
        <S.InfoText>
          You will be redirected to{" "}
          <a
            href="http://app.freightcom.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Freightcom
          </a>{" "}
          soon. If not, please visit the link directly.
        </S.InfoText>
        <S.InfoText>
          Please refer to package summary table to create BOL on Freightcom.
        </S.InfoText>
      </S.Instructions>
    );
  };
  return (
    <Modal
      visible={isVisible}
      style={{ minWidth: "800px" }}
      title="Create BOL"
      onCancel={onClose}
      okText="Complete Creating"
      onOk={onSubmit}
    >
      <S.Content>
        {renderInstructions()}
        <S.Container>
          <PackageSummary packages={packages} />
        </S.Container>
      </S.Content>
    </Modal>
  );
};

export default PackageSummaryModal;
