import { Common } from "@secondcloset/types";
import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

export interface FetchOrganizationDetailsQuery {
  with_facility_service_area?: boolean;
}

const useOrganizationIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("accounts");

  const fetchOrganizationIndex = (query: any) => {
    setLoading(true);
    setError(null);
    const urlBuilder = new UrlBuilder("/organizations");
    const url = urlBuilder.applyQueryStringFromObject(query);

    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const organizationIndex = { data, loading, error };

  return { fetchOrganizationIndex, organizationIndex };
};

const useOrganizationDetails = () => {
  const [data, setData] = useState<Common.Organization>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("accounts");

  const fetchOrganizationDetails = (
    organizationID: string,
    query?: FetchOrganizationDetailsQuery
  ) => {
    const url = `/organizations/${organizationID}`;
    setLoading(true);
    setError(null);
    return api
      .get(url, { params: query })
      .then((res) => {
        const org = res.data.organization;
        setData(org);
        setLoading(false);
        return org;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const createOrganization = (body: any) => {
    const url = `/organizations`;
    setLoading(true);
    setError(null);
    return api
      .post(url, body)
      .then((res) => {
        const org = res.data.organization;
        setData(org);
        setLoading(false);
        return org;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const updateOrganization = (organizationID: string, body: any) => {
    const url = `/organizations/${organizationID}`;
    setLoading(true);
    setError(null);
    return api
      .put(url, body, { params: { with_facility_service_area: true } })
      .then((res) => {
        const org = res.data.organization;
        setData(org);
        setLoading(false);
        return org;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        return false;
      });
  };

  const reset = () => {
    setData(undefined);
    setLoading(false);
    setError(null);
  };

  const organizationDetails = { data, loading, error };

  return {
    organizationDetails,
    fetchOrganizationDetails,
    createOrganization,
    updateOrganization,
    reset,
  };
};

const useOrganizationUserIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain();

  const fetchOrganizationUserIndex = (query: any) => {
    setLoading(true);
    setError(null);

    const urlBuilder = new UrlBuilder("/users");
    const url = urlBuilder.applyQueryStringFromObject(query);

    return api
      .get(url)
      .then((res) => {
        const org = res.data;
        setData(org);
        setLoading(false);
        return org;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const organizationUserIndex = { data, error, loading };

  return { organizationUserIndex, fetchOrganizationUserIndex };
};

const useOrganizationUser = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = getAPIFromDomain("accounts");

  const createOrganizationUser = (user: any) => {
    const url = "/users";
    setLoading(true);
    setError(null);
    return api
      .post(url, user)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        return false;
      });
  };

  const organizationUserDetails = { data, loading, error };

  return { organizationUserDetails, createOrganizationUser };
};

export {
  useOrganizationDetails,
  useOrganizationUser,
  useOrganizationIndex,
  useOrganizationUserIndex,
};
