import React, { FC } from "react";
import { Wrapper, HeaderSection } from "./SearchResultsHeader.styles";

interface Props {
  reverse?: boolean;
}

const SearchResultsHeader: FC<Props> = ({ reverse }) => {
  return (
    <Wrapper reverse={reverse}>
      <HeaderSection flex={2}>Name</HeaderSection>
      <HeaderSection flex={1}>SKU</HeaderSection>
      <HeaderSection flex={1}>SCID</HeaderSection>
    </Wrapper>
  );
};

export default SearchResultsHeader;
