import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Empty, Table, Button, Modal, Alert, Input, Popconfirm } from "antd";
import { isEmpty } from "lodash-es";
import moment from "moment";

const CustomerNotesTable = ({ notes, loading, onSubmit, onDelete }) => {
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editedNote, setEditedNote] = useState();

  const onCancel = () => {
    setError(null);
    setModalVisible(false);
  };

  const handleOnSubmit = () => {
    if (!editedNote.text) {
      setError("Please enter a note");
    } else {
      onSubmit(editedNote);
      setModalVisible(false);
    }
  };

  const renderFooter = [
    <Button key="back" type="default" onClick={onCancel}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleOnSubmit}>
      Submit
    </Button>,
  ];

  const buildColumns = () => {
    const renderNote = (text) => <div>{text}</div>;
    const renderDate = (date) => <span>{moment(date).format("ll")}</span>;
    const renderCreatedBy = (user) => (
      <div>{`${user.first_name} ${user.last_name}`}</div>
    );
    const renderDelete = (record) => (
      <Popconfirm
        title="Are you sure you want to delete this note?"
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={() => {
          onDelete(record);
        }}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined style={{ color: "#ff0000", fontSize: "20px" }} />
      </Popconfirm>
    );
    const renderEdit = (record) => (
      <Button
        className="notes-action-button"
        onClick={() => {
          setEditedNote(record);
          setModalVisible(true);
        }}
        icon={<EditOutlined />}
      />
    );

    return [
      {
        title: "Note",
        dataIndex: "text",
        render: renderNote,
      },
      {
        title: "Last Updated",
        dataIndex: "updated_at",
        render: renderDate,
        width: 120,
      },
      {
        title: "Created By",
        dataIndex: "user",
        render: renderCreatedBy,
        width: 200,
      },
      {
        title: "Delete",
        key: "delete",
        render: renderDelete,
      },
      {
        title: "Edit",
        key: "edit",
        render: renderEdit,
      },
    ];
  };

  return (
    <div className="notes-table">
      {isEmpty(notes) ? (
        <Empty description="No notes" className="no-notes" />
      ) : (
        <Table
          loading={loading}
          columns={buildColumns()}
          dataSource={notes}
          size="small"
          rowKey={(note) => note.id}
        />
      )}

      <Modal
        visible={modalVisible}
        onCancel={onCancel}
        title="Edit Note"
        footer={renderFooter}
        destroyOnClose
        width="528px"
      >
        <div className="new-address-modal">
          {error ? (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ margin: "0 0 8px 0" }}
            />
          ) : null}
          <Input
            value={editedNote ? editedNote.text : ""}
            onChange={(e) => {
              setEditedNote({ ...editedNote, text: e.target.value });
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

CustomerNotesTable.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default CustomerNotesTable;
