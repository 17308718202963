import React from "react";
import PropTypes from "prop-types";
import logo from "../../../../public/sc-logo-black.png";
import ManifestNotesAndTasks from "./ManifestNotesAndTasks";

const ManifestLayout = ({
  recipient,
  address,
  date,
  phone,
  type,
  apptNum,
  time,
  items,
  customTask,
  notes,
}) => {
  const renderDetailSegment = (title, details) => {
    return (
      <div className="detail-segment">
        <div className="title">{title}:</div>
        <div className="details">{details}</div>
      </div>
    );
  };

  const buildAppointmentInfo = () => (
    <div className="appointment-info">
      <div className="details-section">
        <div className="details-column">
          {renderDetailSegment("To", recipient)}
          {renderDetailSegment("Phone", phone)}
        </div>
        <div className="details-column">
          {renderDetailSegment("Address", address)}
        </div>
        <div className="details-column">
          {renderDetailSegment("Delivery Date", date)}
          {renderDetailSegment("Time", time)}
        </div>
        <div className="details-column">
          {renderDetailSegment("Appointment No", apptNum)}
        </div>
      </div>
      <div className="immediate-assistance">
        For immediate assistance, please contact hello@secondcloset.com or call
        1-888-800-2824.
      </div>
    </div>
  );

  const buildManifestHeader = () => (
    <div className="manifest-header">
      <div className="logo-with-appt-type">
        <div className="logo-container">
          <img className="sc-logo" src={logo} alt="" />
        </div>
        <div className="appointment-type">{type}</div>
      </div>
      {buildAppointmentInfo()}
    </div>
  );

  const buildManifestTable = () => {
    const apptItems =
      items?.reduce((acc, item) => {
        const { item_type, name, quantity, qr_codes } = item;
        const isProduct = item_type.toLowerCase() === "product";
        const formattedName = `${name}${isProduct ? " (Product)" : ""}`;
        if (!acc[formattedName]) {
          acc[formattedName] = {
            name: formattedName,
            quantity: quantity,
            qr_codes: qr_codes
          };
        } else {
          acc[formattedName].quantity += quantity;
          acc[formattedName].qr_codes.push(qr_codes);
        }
        return acc;
      }, {}) || [];

    const appointmentItems = Object.keys(apptItems).map((key, index) => (
      <tr key={index}>
        <td width="10%">{index + 1}</td>
        <td width="30%">{apptItems[key].name}</td>
        <td width="50%">{apptItems[key].qr_codes?.join(", ")}</td>
        <td width="10%">{apptItems[key].quantity}</td>
      </tr>
    ));

    return (
      <div className="manifest-table-wrapper">
        <table className="manifest-table">
          <tbody>
            <tr>
              <th>No</th>
              <th>Description</th>
              <th>QR Codes</th>
              <th>Quantity</th>
            </tr>
            {appointmentItems}
          </tbody>
        </table>
      </div>
    );
  };

  const buildFillSection = (label) => (
    <div className="fill-section">
      <label>{label}</label>
    </div>
  );

  const buildManifestFooter = () => {
    return (
      <div className="manifest-footer">
        <div className="time-in-out">
          {buildFillSection(
            "Time In/Out: _________________________/_________________________"
          )}
        </div>
        <div className="sign-section">
          {buildFillSection("Recipient")}
          {buildFillSection("Signature")}
          {buildFillSection("Driver")}
        </div>
      </div>
    );
  };

  return (
    <div className="manifest-layout">
      {buildManifestHeader()}
      {buildManifestTable()}
      <ManifestNotesAndTasks notes={notes} tasks={customTask} />
      {buildManifestFooter()}
    </div>
  );
};

ManifestLayout.propTypes = {
  recipient: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  apptNum: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  customTask: PropTypes.array.isRequired,
  notes: PropTypes.array.isRequired,
};

export default ManifestLayout;
