import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BackButton from "../../../components/fulfillment/_common/BackButton";
import SelectAddressCard from "../../../components/regular/_common/address/SelectAddressCard";
import SelectAppointmentDatesCard from "../../../components/regular/_common/dates/SelectAppointmentDatesCard";
import ReturnCustomerClosetCard from "../../../components/storage/ReturnCustomerClosetCard";
import BillingDetailCard from "../../../components/regular/_common/billing/BillingDetailCard";
import ReturnSummaryCard from "./ReturnSummaryCard";
import SelectServiceAreaCard from "../../../components/regular/order/dependencies/SelectServiceAreaCard";
import { Button, Affix, Modal, Spin } from "antd";
import { useProducts } from "../../../hooks/api/regular/products";
import { useReturnOrder } from "../../../hooks/api/regular/order";
import { useCustomerDetails } from "../../../hooks/api/regular/customer";
import { useCustomerBilling } from "../../../hooks/api/regular/customer";

import Order from "../../../lib/regular/order";
import { isEmpty, get } from "lodash-es";
import ErrorBox from "../../../components/fulfillment/_common/ErrorBox";

const ReturnOrderCreatePage = (props) => {
  const { userID } = props.match.params;
  const [temporary, setTemporary] = useState(false);
  const [hasReturnFee, setHasReturnFee] = useState(true);
  const [selectedServiceArea, setSelectedServiceArea] = useState(null);
  const [selectedConsumerSegment, setSelectedConsumerSegment] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [appointmentDates, setAppointmentDates] = useState({
    date1: null,
    date2: null,
    time1: null,
    time2: null,
    immediate: false,
  });
  const [isSendInvoice, setIsSendInvoice] = useState(true);
  const [errors, setErrors] = useState(null);
  const [returnCart, setReturnCart] = useState([]);
  const [notes, setNotes] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const { products, fetchProducts } = useProducts();
  const {
    returnOrder,
    createReturnOrder,
    previewReturnOrder,
    reset: resetReturnOrder,
  } = useReturnOrder();
  const { customerDetails, fetchCustomerDetails } = useCustomerDetails();
  const { customerBilling, fetchCustomerBilling } = useCustomerBilling();

  const onMount = () => {
    fetchCustomerDetails(userID);
    fetchCustomerBilling(userID);
    window.scrollTo(0, 0);
  };

  const onCustomerBillingChange = () => {
    if (hasCreditCardOnFile(customerBilling.data) && isSendInvoice)
      setIsSendInvoice(false);
  };

  const onResetOrderAndError = () => {
    setErrors(null);
    resetReturnOrder();
  };

  const onCustomerDetailChange = () => {
    const { data } = customerDetails;
    if (data && isStager()) setTemporary(true);
  };

  const onServiceAreaChange = () => {
    fetchProducts({ service_area: selectedServiceArea });
  };

  useEffect(onMount, []);
  useEffect(onCustomerBillingChange, [customerBilling.data]);
  useEffect(onResetOrderAndError, [appointmentDates, temporary, hasReturnFee]);
  useEffect(onCustomerDetailChange, [customerDetails.data]);
  useEffect(onServiceAreaChange, [selectedServiceArea]);

  const goToCustomerDetail = () => props.history.push(`/customers/${userID}`);

  const isStager = () => {
    const { data: details } = customerDetails;
    if (!details) return false;
    const type =
      details && details.business_type && details.business_type.toLowerCase();
    return (
      type && (type.includes("stager") || type.includes("asset-management"))
    );
  };

  const hasCreditCardOnFile = (billing) =>
    billing && billing.sources.data.length > 0;

  const toggleHasReturnFee = (hasReturnFee) => {
    if (!hasReturnFee) setModalVisible(true);
    else setHasReturnFee(hasReturnFee);
  };

  const buildSettingOption = (
    description,
    attr,
    value1,
    value2,
    text1,
    text2
  ) => {
    const currentValue = attr === "temporary" ? temporary : hasReturnFee;
    const setValue = attr === "temporary" ? setTemporary : toggleHasReturnFee;
    return (
      <div className="setting-option">
        <div className="question">{description}</div>
        <Button.Group>
          <Button
            type={currentValue === value1 ? "primary" : "default"}
            onClick={() => setValue(value1)}
          >
            {text1}
          </Button>
          <Button
            type={currentValue === value2 ? "primary" : "default"}
            onClick={() => setValue(value2)}
          >
            {text2}
          </Button>
        </Button.Group>
      </div>
    );
  };

  const buildSettingsCard = () => {
    const { loading, error } = customerDetails;
    let content;
    if (loading) {
      content = <Spin tip="fetching customer detail..." />;
    } else {
      content = (
        <>
          <ErrorBox error={error} /> <br />
          {buildSettingOption(
            "Is this a temporary return?",
            "temporary",
            false,
            true,
            "No",
            "Yes"
          )}
          {buildSettingOption(
            "Should there be a fee on this return?",
            "returnFee",
            false,
            true,
            "No",
            "Yes"
          )}
        </>
      );
    }

    return (
      <div className="settings">
        <div className="title">Select Options</div>
        {content}
      </div>
    );
  };

  const buildSelectServiceAreaCard = () => {
    return (
      <div className="select-service-area">
        <SelectServiceAreaCard
          selectedServiceArea={selectedServiceArea}
          setSelectedServiceArea={setSelectedServiceArea}
          selectedConsumerSegment={selectedConsumerSegment}
          setSelectedConsumerSegment={setSelectedConsumerSegment}
        />
      </div>
    );
  };

  const buildAddressCard = () => {
    return (
      <div className="select-address">
        <SelectAddressCard
          onSelectAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          selectedServiceArea={selectedServiceArea}
        />
      </div>
    );
  };

  const isBulkyOnly = () => {
    const { data } = products;
    const retrievableStripeIDs =
      data &&
      data.filter((p) => p.metadata.retrievable === "true").map((p) => p.id);
    const hasRetrievableItems = returnCart.some(
      (item) =>
        retrievableStripeIDs?.includes(item.stripe_plan_id) &&
        item.item_type !== "space_item"
    );

    return !isEmpty(returnCart) && !hasRetrievableItems && !temporary;
  };

  const buildAppointmentDatesCard = () => {
    const { loading, error } = products;
    if (error) {
      return (
        <div className="select-dates">
          <ErrorBox error={products.error} />
        </div>
      );
    }

    const isBulky = isBulkyOnly();
    const addressID = get(selectedAddress, "id", null);

    const compressedItems = returnCart
      ? returnCart.reduce((acc, cv) => {
          const stripe_plan_id = cv.stripe_plan_id;
          if (!acc[stripe_plan_id]) acc[stripe_plan_id] = 0;
          acc[stripe_plan_id] += 1;
          return acc;
        }, {})
      : null;

    const orderItems = compressedItems
      ? Object.keys(compressedItems).map((stripe_plan_id) => ({
          stripe_plan_id,
          quantity: compressedItems[stripe_plan_id],
        }))
      : [];

    return (
      <div className="select-dates">
        <SelectAppointmentDatesCard
          onDateTimeSelect={setAppointmentDates}
          isStorage={false}
          isBulky={isBulky}
          appointmentDates={appointmentDates}
          externalLoading={loading}
          disableImmediate={temporary}
          selectedConsumerSegment={selectedConsumerSegment}
          addressID={addressID}
          orderItems={orderItems}
          isReturn
        />
      </div>
    );
  };

  const buildCustomerCloset = () => {
    return (
      <div className="select-return-items">
        <ReturnCustomerClosetCard
          returnCart={returnCart}
          setReturnCart={setReturnCart}
        />
      </div>
    );
  };

  const buildBillingDetailCard = () => {
    return (
      <div className="billing-details">
        <BillingDetailCard
          userID={userID}
          onUpdateBillingSuccess={() => fetchCustomerBilling(userID)}
        />
      </div>
    );
  };

  const buildBillingTypeCard = () => {
    const { data, loading } = customerDetails;
    if (loading) {
      return (
        <div className="billing-type spinning">
          <Spin tip="fetching customer detail..." />
        </div>
      );
    }

    if (!data || !data.business_user) return null;

    return (
      <div className="billing-type">
        <div className="title">Billing Types</div>
        <div className="button-group">
          <Button
            type={isSendInvoice ? "primary" : "default"}
            onClick={() => setIsSendInvoice(true)}
          >
            Send Invoice
          </Button>
          <Button
            type={isSendInvoice ? "default" : "primary"}
            onClick={() => setIsSendInvoice(false)}
          >
            Charge Automatically
          </Button>
        </div>
      </div>
    );
  };

  const getErrors = () => {
    const { date1, date2, time1, time2, immediate } = appointmentDates;
    const hasAddress = !!selectedAddress;
    const requiredTwoDates = !isBulkyOnly() && !immediate;
    const hasDates = requiredTwoDates
      ? date1 && date2 && time1 && time2
      : date1 && time1;
    const hasCreditCard = hasCreditCardOnFile(customerBilling.data);
    const isBusinessUser =
      customerDetails.data && customerDetails.data.business_user;
    if (!selectedServiceArea) return "please select service area";
    if (!selectedConsumerSegment) return "please select customer segment";
    if (!hasAddress) return "please select an address";
    if (!hasDates) return "please select appointment date & time";
    if (!isBusinessUser && !hasCreditCard) return "please add billing info";
    return null;
  };

  const prepareOrderBody = () => {
    return {
      addressID: selectedAddress.id,
      ...appointmentDates,
      billingType: isSendInvoice ? "send_invoice" : "charge_automatically",
      returnCart,
      isBulkyOnly: isBulkyOnly(),
      customerSegment: selectedConsumerSegment,
      serviceArea: selectedServiceArea,
    };
  };

  const onSubmit = (isPreview) => {
    const errors = getErrors();
    if (errors) return setErrors(errors);

    const optionalFields = { temporary, notes };

    const body = Order.getReturnOrderBody(prepareOrderBody(), optionalFields);

    if (isPreview) {
      return previewReturnOrder(userID, body);
    }

    return createReturnOrder(userID, body, !hasReturnFee).then((res) => {
      if (res) goToCustomerDetail();
    });
  };

  const buildSummary = () => {
    const { data, loading } = returnOrder;
    const returnFee = data && data.subtotal;
    return (
      <Affix offsetTop={50}>
        <div className="summary">
          <ReturnSummaryCard
            errors={returnOrder.error || errors}
            onSubmit={() => onSubmit()}
            onPreview={() => onSubmit(true)}
            returnCart={returnCart}
            notes={notes}
            onNotesChange={setNotes}
            hasReturnFee={hasReturnFee}
            returnFee={returnFee}
            loadingReturnOrder={loading}
          />
        </div>
      </Affix>
    );
  };

  const buildLeftPanel = () => {
    return (
      <div className="left-panel">
        {buildSettingsCard()}
        {buildSelectServiceAreaCard()}
        {buildCustomerCloset()}
        {buildAddressCard()}
        {buildAppointmentDatesCard()}
        {buildBillingDetailCard()}
        {buildBillingTypeCard()}
      </div>
    );
  };

  const buildRightPanel = () => {
    return <div className="right-panel">{buildSummary()}</div>;
  };

  const buildModal = () => {
    return (
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        title="Attention"
        onOk={() => {
          setHasReturnFee(false);
          setModalVisible(false);
        }}
      >
        <p>
          This option will <b>NOT CHARGE</b> fees on this return.
        </p>
        <p>Please confirm your selection.</p>
      </Modal>
    );
  };

  return (
    <div className="page-container">
      <BackButton onClick={goToCustomerDetail}>Customer Detail</BackButton>
      <h1>Return Items</h1>
      <div className="order-return-create">
        {buildLeftPanel()}
        {buildRightPanel()}
        {buildModal()}
      </div>
    </div>
  );
};

ReturnOrderCreatePage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ReturnOrderCreatePage);
