import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { userTokenCookieName, userInfoCookieName } from "../../../lib/Cookies";

import secondClosetLogo from "../../../public/scIcon.png";

/**
 * Config
 */
import { navigationLinks } from "../../../config/navigationConfig";
import { clearScroll } from "../../../helperFunctions";

import { DownOutlined } from "@ant-design/icons";

import { Button, Dropdown, Menu, message } from "antd";
import { NavigationMenu } from "../../NavMenu";
import { version } from "../../../../package.json";

// Helpers
import { capitalize } from "lodash-es";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      openedNav: undefined,
    };
  }

  onLogOut() {
    cookie.remove(userTokenCookieName, { path: "/" });
    cookie.remove(userInfoCookieName, { path: "/" });
    this.props.signOut();
  }

  clickMenuItem(link) {
    return () => {
      clearScroll();
      this.props.history.push(link);
    };
  }

  handleNavbarToggle = () => {
    const { openedNav } = this.state;
    this.setState({ openedNav: !openedNav });
  };

  renderNavigationMenu = () => {
    const { auth, history } = this.props;

    const dropdownLinks = navigationLinks.filter(
      (link) =>
        !link.subroles.length || link.subroles.includes(auth.user.subrole)
    );
    const currentPathname = history.location.pathname;
    return (
      <NavigationMenu
        links={dropdownLinks}
        isDesktop={false}
        currentPathname={currentPathname}
        handleNavbarToggle={() => this.handleNavbarToggle()}
        history={history}
      />
    );
  };

  buildFacilitiesMenu = () => {
    const { facilityList, activeFacility, setActiveFacility } = this.props;
    const filteredFacilities = facilityList.filter(
      (f) => f.code !== activeFacility.code
    );

    return (
      <Menu>
        {filteredFacilities.map((facility) => {
          const { code, name } = facility;

          const handleClick = () => {
            setActiveFacility(facility);
            message.success(`You are now viewing ${name}`, 2);
          };

          return (
            <Menu.Item key={code} onClick={handleClick}>
              {name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  buildFacilitySelector = () => {
    const { name } = this.props.activeFacility;

    return (
      <div className="header-facility-selector inl-flex-row">
        <p className="facility-label">Facility: </p>
        <Dropdown overlay={this.buildFacilitiesMenu()} trigger={["click"]}>
          <Button>
            <p>
              {name}
              <DownOutlined />
            </p>
          </Button>
        </Dropdown>
      </div>
    );
  };

  render() {
    const { auth, history } = this.props;
    const { openedNav } = this.state;
    const userDetailsMenu = (
      <Menu onClick={() => this.handleNavbarToggle()}>
        <Menu.Item
          className="ant-dropdown-menu-item"
          title="Account Details"
          eventKey={3.1}
          onClick={() => history.push("/account")}
        >
          Account Details
        </Menu.Item>
        <Menu.Item
          className="ant-dropdown-menu-item"
          title="Log Out"
          eventKey={3.3}
          onClick={this.onLogOut.bind(this)}
        >
          Log out
        </Menu.Item>
      </Menu>
    );
    const userName =
      capitalize(auth.user.first_name.charAt(0)) +
      capitalize(auth.user.last_name.charAt(0));
    return (
      <div className={openedNav ? "topBar opened" : "topBar"}>
        <div className="topBar-container">
          <div className="inline-block w-25">
            <div className="brandLogo">
              <Link className="logoLink" to="/">
                <img
                  src={secondClosetLogo}
                  alt="logo"
                  width="50px"
                  height="50px"
                />
              </Link>
            </div>
            <div className="brandVersion">
              {version}
              <span className="navbar-env">
                {process.env.REACT_APP_ENV.toUpperCase()}
              </span>
            </div>
          </div>
          <div className="inline-block w-75 text-right">
            {this.buildFacilitySelector()}
            <button
              type="button"
              className={openedNav ? "menu-toggle is-active" : "menu-toggle"}
              onClick={() => this.handleNavbarToggle()}
            />
            <Dropdown overlay={userDetailsMenu} trigger={["click"]}>
              <Button className="navbar-account-btn">{userName}</Button>
            </Dropdown>
          </div>
        </div>
        <div className="navbar-mobile-menu">
          <div className="navbar-mobile-container">
            <div className="inline-block w-50">
              {version}
              <span className="navbar-env">
                {process.env.REACT_APP_ENV.toUpperCase()}
              </span>
            </div>
            <div className="inline-block w-50 text-right">
              <Dropdown overlay={userDetailsMenu} trigger={["click"]}>
                <Button className="navbar-account-btn">{userName}</Button>
              </Dropdown>
            </div>
          </div>
          {this.renderNavigationMenu()}
        </div>
      </div>
    );
  }
}

export default Header;

Header.propTypes = {
  history: PropTypes.object.isRequired,
  auth: PropTypes.object,
  activeFacility: PropTypes.object.isRequired,
  facilityList: PropTypes.arrayOf(PropTypes.object),
  signOut: PropTypes.func.isRequired,
  setActiveFacility: PropTypes.func.isRequired,
};
