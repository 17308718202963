import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, notification } from "antd";
import StartEndTimeModal from "./StartEndTimeModal";
import { useAppointmentDetails } from "../../../../../hooks/api/regular/appointment";
import { get } from "lodash-es";

const SetDoneButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const {
    appointmentDetails,
    updateAppointmentStatus,
  } = useAppointmentDetails();

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (appointmentDetails.error) {
      notification.error({
        message: "Error",
        description: appointmentDetails.error,
      });
    }
  }, [appointmentDetails.error]);

  const onUpdateAppointmentStatus = async (...args) => {
    const appt = await updateAppointmentStatus(...args);
    if (appt) props.onRefetchAppointment();
  };

  const renderStartEndTimeModal = () => {
    return (
      <StartEndTimeModal
        appointment={props.appointment}
        modalProps={{
          visible: modalVisible,
          onCancel: () => setModalVisible(false),
        }}
        onSubmit={(options) => {
          onUpdateAppointmentStatus(props.appointment.id, "done", options);
          setModalVisible(false);
        }}
      />
    );
  };

  const status = get(props.appointment, "status");
  if (status === "done" || status === "signed") return null;
  const disablingStatues = ["completed", "incomplete", "failed", "cancelled"];
  const isDisabled = disablingStatues.includes(status);

  return (
    <>
      <Button
        className="force-button"
        type="primary"
        onClick={() => setModalVisible(true)}
        disabled={isDisabled}
        loading={appointmentDetails.loading}
      >
        Set Done
      </Button>
      {renderStartEndTimeModal()}
    </>
  );
};

SetDoneButton.propTypes = {
  appointment: PropTypes.object.isRequired,
  onRefetchAppointment: PropTypes.func.isRequired,
};

export default SetDoneButton;
