import React, { Component } from "react";
import { Spin, Input } from "antd";
import PropTypes from "prop-types";

import { lookupCategoryItems } from "../../../../../api/inventory";

const SEARCH_DELAY_IN_MS = 200;

class CategorySearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      searchDelayTimer: null,
      loading: false,
      categoryItems: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.searchBar && !this.searchBar.contains(e.target)) {
      const search = !!this.props.selectedCategoryItemId
        ? this.state.search
        : "";
      this.setState({ search, categoryItems: null });
    }
  };

  searchForCategoryItems = () => {
    const { search } = this.state;
    lookupCategoryItems(search)
      .then((res) =>
        this.setState({
          categoryItems: res.data.category_items,
          loading: false,
        })
      )
      .catch(() => this.setState({ loading: false }));
  };

  handleType = (e) => {
    this.setState({ loading: true });
    clearTimeout(this.state.searchDelayTimer);
    this.props.clearSelectedItem();
    const searchDelayTimer = setTimeout(
      this.searchForCategoryItems,
      SEARCH_DELAY_IN_MS
    );

    this.setState({
      search: e.target.value,
      searchDelayTimer,
      categoryItems: null,
    });
  };

  dropdownClick = (categoryItem) => {
    this.props.onSelectItem(categoryItem.id);
    this.setState({
      search: categoryItem.name,
      categoryItems: null,
    });
  };

  buildLoader = () => {
    return (
      <div className="spinner" onClick={this.toggleDropdown}>
        <Spin size="small" />
      </div>
    );
  };

  buildDropdownOptions = () =>
    this.state.categoryItems &&
    this.state.categoryItems.map((ci) => {
      const { id, name } = ci;

      return (
        <div
          key={id}
          className="search-option"
          onClick={() => this.dropdownClick(ci)}
        >
          <p>{name}</p>
        </div>
      );
    });

  buildDropdown = () => (
    <div className="dropdown">{this.buildDropdownOptions()}</div>
  );

  render() {
    const { loading, categoryItems } = this.state;

    return (
      <div className="input-with-dropdown" ref={(n) => (this.searchBar = n)}>
        <div>
          <Input
            value={this.state.search}
            onChange={this.handleType}
            placeholder="Search names"
          />
          {loading && this.buildLoader()}
          {categoryItems && this.buildDropdown()}
        </div>
      </div>
    );
  }
}

CategorySearchBar.propTypes = {
  selectedCategoryItemId: PropTypes.string,

  onSelectItem: PropTypes.func.isRequired,
  clearSelectedItem: PropTypes.func.isRequired,
};

export default CategorySearchBar;
