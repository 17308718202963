/**
 * Authentication types
 */
export const SIGNIN_SUCCESS = "signin_success";
export const SET_AUTH_ERROR = "set_auth_error";
export const SET_AUTH_LOADING = "set_auth_loading";
export const SET_TOKEN = "set_token";
export const SET_USER_INFORMATION = "set_user_information";
export const SIGNOUT = "signout";

/**
 * Order types
 */
export const CANCEL_ORDER = "cancel_order";

/**
 * Customer types
 */
export const FETCH_CUSTOMER_DETAILS = "fetch_customer_details";
export const FETCH_CUSTOMER_ADDRESSES = "fetch_customer_addresses";
export const CLEAR_CUSTOMER_DETAILS = "clear_customer_details";
export const FETCH_CUSTOMER_APPOINTMENTS = "fetch_customer_appointments";

/**
 * Loading and Errors
 */
export const SET_LOADING = "set_loading";
export const SET_ERROR = "set_error";

/**
 * fetching
 */
export const CUSTOMERS_FETCHING = "customer_fetching";
export const ORDERS_FETCHING = "order_fetching";
export const APPOINTMENT_FETCHING = "appointment_fetching";

/**
 * fetched
 */
export const CUSTOMERS_FETCHED = "customer_fetched";
export const ORDERS_FETCHED = "order_fetched";
export const APPOINTMENT_FETCHED = "appointment_fetched";

/**
 * updated time
 */

export const CUSTOMERS_UPDATE_TIME = "customer_UPDATE_TIME";
export const ORDERS_UPDATE_TIME = "order_UPDATE_TIME";
export const APPOINTMENT_UPDATE_TIME = "appointment_UPDATE_TIME";

/**
 * Products
 */
export const FETCH_PRODUCTS = "fetch_products";
export const FETCH_PRODUCTS_ALL = "fetch_products_all";

/**
 * Tasks
 */
export const FETCH_TASKS = "fetch_tasks";
export const CLEAR_TASKS = "clear_tasks";
export const CREATE_TASK = "create_task";
export const FETCH_TASK_DETAILS = "fetch_task_details";
export const CLEAR_TASK_DETAILS = "clear_task_details";

/**
 * Order Edit
 */
export const FETCH_ORDER_EDIT_RETURN_DATA = "FETCH_ORDER_EDIT_RETURN_DATA";

/**
 * Current Search
 */
export const SET_CUSTOMER_SEARCH = "SET_CUSTOMER_SEARCH";

/**
 * Custom Task
 */
export const CREATE_CUSTOM_TASK = "CREATE_CUSTOM_TASK";
