/**
 * Role filtering groups
 */
import { SUPPORT_GROUP } from "./roleConfig";

const navigationLinks = [
  {
    to: "/dashboard",
    icon: "fa fa-bar-chart",
    text: "Dashboard",
    subroles: [],
  },
  {
    to: "/customers",
    icon: "fa fa-user-o",
    text: "Customers",
    subroles: [],
  },
  {
    to: "/orders",
    icon: "fa fa-usd",
    text: "Orders",
    subroles: [],
  },
  {
    to: "/appointments",
    icon: "fa fa-address-card",
    text: "Appointments",
    subroles: [],
  },
  {
    to: "/organizations",
    icon: "fa fa-globe",
    text: "Organizations",
    subroles: [],
  },
  {
    to: "/shipping-costs",
    icon: "fa fa-truck",
    text: "Shipping Costs",
    subroles: [],
    billingEngine: true,
  },
  {
    to: "#",
    icon: "fa fa-map-o",
    text: "PLANNING/TOOLS",
    subroles: [],
    items: [
      {
        to: "/control-center",
        text: "Control Center",
        subroles: [],
      },
      {
        to: "/category-management",
        text: "Category Management",
        subroles: SUPPORT_GROUP,
      },
    ],
  },
  {
    to: "#",
    icon: "fa fa-laptop",
    text: "FULFILLMENT",
    subroles: SUPPORT_GROUP,
    items: [
      {
        to: "/fulfillment/asns",
        text: "Advance Shipping Notice",
        subroles: SUPPORT_GROUP,
      },
      {
        to: "/fulfillment/orders",
        text: "Orders",
        subroles: SUPPORT_GROUP,
      },
      {
        to: "/fulfillment/shipments",
        text: "Shipments",
        subroles: SUPPORT_GROUP,
      },
    ],
  },
];

export { navigationLinks };
