import styled from "styled-components";
import colors from "../../../../../../../css/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 25px;
`;

export const OrderIDWrap = styled.div`
  color: ${colors.GREY};
  margin-top: 10px;
`;

export const IconWrap = styled.div`
  margin-top: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.GREEN};
  border-radius: 100%;

  .check-icon {
    color: white;
    font-size: 25px;
  }
`;

export const SuccessMsg = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  span {
    color: ${colors.GREEN};
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 20px;
`;

export const CustomerInfoWrap = styled.div`
  display: flex;
  padding: 40px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 2px solid ${colors.GREY_LIGHT};
  margin: 40px 0;

  .name {
    font-weight: 600;
    font-size: 20px;
  }

  .email {
    margin-top: 10px;
    font-size: 18px;
  }
`;
