import * as actionTypes from "../../../actions/fulfillment/types/wro";

const INITIAL_STATE = {
  loading: true,
  wro: null,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_DETAILS_REQUESTED,
    WRO_DETAILS_RECEIVED,
    WRO_DETAILS_ERRORED,
  } = actionTypes;

  switch (action.type) {
    case WRO_DETAILS_REQUESTED:
      return { ...state, loading: true, wro: null, error: null };
    case WRO_DETAILS_RECEIVED:
      return { ...state, wro: action.data, loading: false };
    case WRO_DETAILS_ERRORED:
      return {
        ...state,
        loading: false,
        wro: null,
        error: "Error getting WRO details",
      };
    default:
      return state;
  }
}
