import { getAPIFromDomain } from "../../lib/api";
import { Fulfillment, Warehouse } from "@secondcloset/types";
import { stringify, StringifyOptions } from "query-string";
import {
  PaginatedData,
  PaginationOptions,
} from "../warehouse/commonInterfaces";

type ASNProcessLog = Warehouse.ASNProcessLog;

export const createASN = async (body: unknown): Promise<Fulfillment.ASN> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns`;
  const res = await api.post(url, body);
  return res.data;
};

export interface FetchASNQuery {
  per_page?: number;
  page?: number;
  organization_id?: string;
  requires?: string[];
  q?: string;
  sku?: string;
  organization_name?: string;
}

export const updateASN = async ({
  body,
  asnID,
  options,
}: {
  body: unknown;
  asnID: string;
  options?: Record<string, unknown>;
}): Promise<Fulfillment.ASN> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns/${asnID}${options ? "?" + stringify(options) : ""}`;
  const res = await api.put(url, body);
  return res.data;
};

export const deleteASN = async (asnID: string): Promise<void> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/asns/${asnID}`;
  await api.delete(url);
};

export const fetchASNDetails = async (
  key: string,
  options: { asnID: string; query?: Record<string, unknown> }
): Promise<Fulfillment.ASN> => {
  const api = getAPIFromDomain("fulfillment");
  const { asnID, query } = options;
  const url = `/asns/${asnID}${query ? "?" + stringify(query) : ""}`;
  const res = await api.get(url);
  return res.data;
};

export const fetchASNIndex = async (
  query: FetchASNQuery
): Promise<Fulfillment.ASN[]> => {
  const api = getAPIFromDomain("fulfillment");
  const options: StringifyOptions = { arrayFormat: "bracket" };
  const url = `/asns${query ? "?" + stringify(query, options) : ""}`;
  const res = await api.get(url);
  return res.data;
};

export interface ProcessLogQuery {
  external_asn_id?: string;
  external_asn_number?: string;
  user_email?: string;
}

export const fetchASNProcessLogs = async (
  query: PaginationOptions & ProcessLogQuery
): Promise<PaginatedData<ASNProcessLog>> => {
  const api = getAPIFromDomain("november");
  const url = `/asn-process-logs?${stringify(query || {})}`;
  const response = await api.get(url);
  return response.data as PaginatedData<ASNProcessLog>;
};
