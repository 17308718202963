import { PackagingLevel } from "../../interfaces/products";
import {
  ShipmentSize,
  PackagingOption,
  IncomingShipment,
} from "interfaces/warehouseReceivingOrders";

export default class WRO {
  static isBox(shipmentSize: ShipmentSize) {
    return (
      shipmentSize === this.getShipmentTypes().standardDelivery || !shipmentSize
    );
  }

  static getShipmentTypes = () => {
    return {
      standardDelivery: "standard_delivery",
      palletizedContainer: "less_than_truckload",
      floorLoadedContainer: "full_container_load",
      oneSkuPerBox: "one_sku_per_box",
      oneSkuPerPallet: "one_sku_per_pallet",
    };
  };

  static getShipmentPackageDisplay = (shipmentPackage: PackagingOption) => {
    switch (shipmentPackage) {
      case "one_sku_per_box":
        return "One SKU Per Box";
      case "one_sku_per_pallet":
        return "One SKU Per Pallet";
      default:
        return "";
    }
  };

  static getDeliveryType = (shipmentSize: ShipmentSize) => {
    switch (shipmentSize) {
      case "standard_delivery":
        return "Standard Parcel";
      case "less_than_truckload":
        return "LTL";
      case "full_container_load":
        return "Full Container Load (FLC)";
      default:
        return "-";
    }
  };

  static getCreateOrderBody = (
    locationCode: string,
    shipmentSize: string,
    shipmentPackage: PackagingOption,
    shipmentDate: string,
    incomingShipments: IncomingShipment[],
    // address,
    organizationID: string
  ) => {
    return {
      warehouse_receiving_order: {
        draft: false,
        destination_warehouse: locationCode,
        shipment_size: shipmentSize,
        have_shipping_label: true,
        packaging_option: shipmentPackage,
        arrival_date: shipmentDate,
        incoming_shipments: incomingShipments,
        // from_address_id: address,
        organization_id: organizationID,
      },
    };
  };

  static getPackagingLevelText = (packagingLevel: PackagingLevel["level"]) => {
    if (!packagingLevel) return;
    const packagingLevels = {
      single_item: "Single Item",
      inner_pack: "Inner Pack",
      master_pack: "Master Pack",
      pallet: "Pallet",
    };
    return packagingLevels[packagingLevel];
  };
}
