import React, { useState, useEffect } from "react";

// components
import { Modal, Select, Input, notification } from "antd";

// hooks
import { useAppointmentDetails } from "../../../hooks/api/regular/appointment";
import { useShipmentDetails } from "../../../hooks/api/fulfillment/shipment";

// helpers
import {
  STORAGE_FAILED_REASONS,
  FULFILLMENT_FAILED_REASONS,
} from "../../../constants/appointmentConstants";

// styles
import { FailedTitle, Container } from "./styles";

// types
import { Fulfillment, Storage } from "@secondcloset/types";
type Shipment = Fulfillment.Shipment;
type Appointment = Storage.Appointment;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => any;
  shipment?: Shipment;
  appointment?: Appointment;
  onRefetch: () => any;
}

const MarkAsIncompleteModal: React.FC<Props> = ({
  visible,
  setVisible,
  shipment,
  appointment,
  onRefetch,
}) => {
  const isShipment = !!shipment;
  const failedReasons = isShipment
    ? FULFILLMENT_FAILED_REASONS
    : STORAGE_FAILED_REASONS;
  const [selectedReason, setSelectedReason] = useState(failedReasons[0].value);
  const [notes, setNotes] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const {
    appointmentDetails,
    updateAppointmentStatus,
  } = useAppointmentDetails();
  const { shipmentDetails, updateShipmentStatus } = useShipmentDetails();

  const shipmentID = shipment?.id;
  const error = appointmentDetails.error || shipmentDetails.error;
  const loading = appointmentDetails.loading || shipmentDetails.loading;

  useEffect(() => {
    if (error) notification.error({ message: error });
  }, [error]);

  const renderFailReasonsDropdown = () => {
    return (
      <div>
        <FailedTitle>Select Failed Reason:</FailedTitle>
        <Select
          value={selectedReason}
          onSelect={(v) => setSelectedReason(v)}
          style={{ width: 230 }}
        >
          {failedReasons.map((reason) => {
            const isCustomerRefusal = reason.value === "customer_refusal";
            const isPickup =
              appointment?.job_type === "pick_up" ||
              shipment?.action_summary.includes("inventory_pick_up");
            if (isCustomerRefusal && isPickup) return "";
            return (
              <Select.Option key={reason.value} value={reason.value}>
                {reason.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const renderNotes = () => {
    return (
      <Input.TextArea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        autoSize={{ minRows: 5 }}
        className="reason-notes"
        placeholder="Please describe why this appointment couldn't be completed"
      />
    );
  };

  const buildClaimNumber = () => {
    if (!isShipment) return null;
    return (
      <Input
        value={claimNumber}
        onChange={(e) => setClaimNumber(e.target.value)}
        placeholder="Please input Claims # (SAMS #) "
      />
    );
  };

  const handleSubmit = async () => {
    const emptyNotes = !notes.trim();
    if (selectedReason === "other" && emptyNotes) {
      return notification.warning({
        message: "Warning",
        description: "Notes are required",
      });
    }

    const options = {
      failed_reason: selectedReason,
      failed_notes: emptyNotes ? undefined : notes,
      claim_number: claimNumber,
    };

    const request = isShipment ? updateShipmentStatus : updateAppointmentStatus;
    const id = isShipment ? shipmentID : appointment?.id;
    if (shipment?.shipping_method?.status === "confirmed") {
      await request(id, "on_load");
    }
    const res = await request(id, "incomplete", options);
    if (res) {
      setVisible(false);
      onRefetch();
    }
  };

  return (
    <Container>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Mark as Incomplete"
        onOk={handleSubmit}
        confirmLoading={loading}
      >
        {renderFailReasonsDropdown()}
        {renderNotes()}
        {buildClaimNumber()}
      </Modal>
    </Container>
  );
};

export default MarkAsIncompleteModal;
