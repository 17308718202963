import { get } from "lodash-es";

export default class Billing {
  static hasCreditCardOnFile = (billing) => {
    const sources = get(billing, "sources.data", []);
    return sources && sources.length > 0;
  };

  static getCreditCardInfo = (billing) => {
    return get(billing, "sources.data[0]", null);
  };

  static getCreditCardString = (billing) => {
    const info = Billing.getCreditCardInfo(billing);
    if (!info) return "-";
    return `XXXX-XXXX-XXXX${info.last4}`;
  };

  static getCreditCardString = (billing) => {
    const info = Billing.getCreditCardInfo(billing);
    if (!info) return "-";
    return `XXXX - XXXX - XXXX - ${info.last4}`;
  };

  static getExpiryDateString = (billing) => {
    const info = Billing.getCreditCardInfo(billing);
    if (!info) return "-";
    return `${info.exp_month}/${info.exp_year}`;
  };

  static getBrand = (billing) => {
    const info = Billing.getCreditCardInfo(billing);
    if (!info) return "-";
    return info.brand;
  };
}
