import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { WRO_DELETE_ERRORED } from "actions/fulfillment/types/wro";
import {
  getWROShippingLabel,
  getWROShippingLabels,
  deleteWarehouseReceivingOrder,
} from "actions/fulfillment/wros";
import WRODetail from "components/fulfillment/inventory/warehouseReceivingOrders/detail/WRODetail";

const mapStateToProps = (state) => {
  const { fulfillment } = state;
  const {
    wroLabelGet,
    wroLabelsGet,
    warehouseReceivingOrderDelete,
  } = fulfillment;
  // const {
  //   loading: fetchingOrder,
  //   wro: order,
  //   error: fetchOrderError,
  // } = wroDetails;
  const { fetching: fetchingLabel, error: labelError } = wroLabelGet;
  const { fetching: fetchingLabels, error: labelsError } = wroLabelsGet;
  const {
    fetching: deletingOrder,
    error: deleteOrderError,
  } = warehouseReceivingOrderDelete;

  return {
    deletingOrder,
    deleteOrderError,
    fetchingLabel,
    labelError,
    fetchingLabels,
    labelsError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { order_id } = ownProps.match.params;

  return {
    getWROShippingLabel: (package_id) =>
      dispatch(getWROShippingLabel(order_id, package_id)),
    getWROShippingLabels: () => dispatch(getWROShippingLabels(order_id)),
    // fetchOrder: () => dispatch(fetchWroDetails(order_id)),
    deleteOrder: () =>
      dispatch(deleteWarehouseReceivingOrder(order_id)).then((res) => {
        if (res.type !== WRO_DELETE_ERRORED) {
          ownProps.history.push("/inventory/warehouse-receiving-orders");
        }
      }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WRODetail)
);
