import styled from "styled-components";

export const Container = styled.div`
  background: #fbfbfb;
  min-height: 81vh;
  width: 100%;
  padding: 30px;
  .ant-spin-container > div > div:not(:first-child) {
    background-color: white;
  }
`;
