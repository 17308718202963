import { get, isEmpty } from "lodash-es";
import Item from "./item";

const buildStructuredVirtualKits = (
  virtualKits,
  baseProductsByVirtualKitId,
  facility
) => {
  const structuredVirtualKits = Item.getStructuredItems(virtualKits, facility);

  return Object.entries(structuredVirtualKits).reduce(
    (acc, [virtualProductId, kit]) => {
      const baseProducts = kit.itemIDs.reduce(
        (acc, id) => acc.concat(baseProductsByVirtualKitId[id]),
        []
      );

      const structuredBaseProducts = Item.getStructuredItems(
        baseProducts,
        facility
      );

      acc[virtualProductId] = {
        ...kit,
        baseProducts: structuredBaseProducts,
      };

      return acc;
    },
    {}
  );
};

export default class Order {
  static getCreateOrderBody = ({
    itemList,
    customerInformation,
    addressInformation,
    external_order_number,
    external_platform_version,
    internal_notes,
    fulfilled_from,
    platform = "manual",
    insurance_value,
  }) => {
    const items = itemList.map((item) => ({
      fulfillment_product_id: item.id,
      quantity: item.quantity,
    }));
    const body = {
      orders: {
        fulfillment_customer_id: customerInformation.id,
        fulfillment_customer_address_id: addressInformation.id,
        parcel_type: "boxes",
        fulfilled_from,
        items,
        external_order_number,
        external_platform_version,
        internal_notes,
        platform,
        insurance_value,
      },
    };

    return body;
  };

  static getBaseProducts = (orderItems, facility) => {
    const unshippedBaseProducts = orderItems.filter(
      (item) => !Item.isPartOfVirtualKit(item)
    );

    return Item.getStructuredItems(unshippedBaseProducts, facility);
  };

  static getVirtualKits = (orderItems, facility) => {
    const baseProductsByVirtualKitId = orderItems.reduce((acc, item) => {
      if (Item.belongsToVirtualKit(item)) {
        acc[item.virtual_kit_id] = acc[item.virtual_kit_id] || [];
        acc[item.virtual_kit_id].push(item);
      }

      return acc;
    }, {});
    const virtualKitIds = new Set(Object.keys(baseProductsByVirtualKitId));
    const virtualKits = orderItems.filter((item) => virtualKitIds.has(item.id));

    return buildStructuredVirtualKits(
      virtualKits,
      baseProductsByVirtualKitId,
      facility
    );
  };

  static getUnshippedItems = (order) => {
    const getItemHistory = (item) => get(item, "actions_history", []);
    const orderItems = get(order, "items", []);

    const unshippedItems = orderItems.filter(
      (item) =>
        (!item.removed && isEmpty(getItemHistory(item))) ||
        Item.isVirtualKit(item)
    );
    const facility = get(order, "fulfilled_from", "yyz3");

    return {
      ...this.getVirtualKits(unshippedItems, facility),
      ...this.getBaseProducts(unshippedItems, facility),
    };
  };

  static getReturnableItems = (order) => {
    const availableActions = (item) => get(item, "available_actions", []);
    const orderItems = get(order, "items", []);

    const returnableItems = orderItems.filter(
      (item) =>
        availableActions(item).includes("reverse_logistics") ||
        Item.isVirtualKit(item)
    );
    const facility = get(order, "fulfilled_from", "yyz3");

    return {
      ...this.getVirtualKits(returnableItems, facility),
      ...this.getBaseProducts(returnableItems, facility),
    };
  };
}
