import styled from "styled-components";
import COLORS from "@secondcloset/web-components/dist/styles/colors";

export const FlexContainer = styled.div`
  display: flex;
  div:first-child > label {
    padding: 0 8px 0 0px;
  }
`;

export const FlexColumnContainer = styled(FlexContainer)`
  flex-direction: column;
`;

export const FiltersContainer = styled(FlexContainer)`
  align-items: center;
  margin-top: 1em;
`;

export const Label = styled.label`
  padding: 0 8px 0 24px;
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 400;
  align-self: center;
`;

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5em;
`;

export const FlexRowContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  .ant-btn-link {
    padding: 0;
  }
`;

export const PageTitle = styled.div`
  font-size: 25px;
`;

export const LinkyLink = styled.div`
  color: ${COLORS.BLUE};
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: ${COLORS.BLUE_LIGHT};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 1em;

  .ant-picker {
    display: flex;
    flex-basis: 300px;
  }

  .ant-input-search,
  .search-box {
    display: flex;
    flex-basis: 300px;
    .ant-select-selector {
      width: 100%;
    }
  }
  button:last-child {
    margin-left: auto;
  }
`;

export const OrganizationName = styled.div`
  color: ${COLORS.BLUE};
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: ${COLORS.BLUE_LIGHT};
  }
`;
