import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, notification, Alert, Progress } from "antd";
import { useInventoryIndex } from "../../../hooks/api/regular/inventory";
import { useCustomerDetails } from "../../../hooks/api/regular/customer";
import {
  useClosetIndexWithDetails,
  useItemPlanCloset,
} from "../../../hooks/api/regular/closets";
import { useProducts } from "../../../hooks/api/regular/products";
import ClosetSelector from "./ClosetSelector";
import InventoryTable from "./InventoryTable";
import ItemFilterButton from "../../../components/regular/customer/dependencies/ItemFilterButton";
import ClosetNameModifier from "./ClosetNameModifier";
import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";

const STATUS = {
  requested: "requested",
  in_storage: "in_storage",
  being_returned: "being_returned",
  with_client: "with_client",
  returned: "returned",
};

const CustomerInventoryPage = (props) => {
  const { userID } = props.match.params;
  const [closetSearch, setClosetSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedClosetID, setSelectedClosetID] = useState("all-items");
  const { products, fetchProducts } = useProducts();
  const { customerDetails, fetchCustomerDetails } = useCustomerDetails();
  const {
    inventoryIndex,
    fetchInventoryIndex,
    batchUpdateInventoryItems,
  } = useInventoryIndex();
  const { itemPlanCloset, fetchItemPlanCloset } = useItemPlanCloset();
  const {
    closetIndexWithDetails,
    fetchClosetIndexWithDetails,
    fetchClosetDetails,
    updateClosetDetails,
  } = useClosetIndexWithDetails(userID);

  const onMount = () => {
    fetchCustomerDetails(userID);
    fetchInventoryIndex(userID);
    fetchProducts();
    window.scrollTo(0, 0);
  };

  const onError = (error) =>
    notification.error({
      message: "Error",
      description: error,
    });

  const error = closetIndexWithDetails.error || inventoryIndex.error;
  useEffect(() => {
    if (error) onError(error);
  }, [error]);

  useEffect(onMount, []);

  const buildCustomerName = () => {
    const { first_name, last_name, id } = customerDetails.data;
    return (
      <div className="title">
        <a href={`/customers/${id}`}>{`${first_name} ${last_name}`}</a>
        {"'s - Inventory"}
      </div>
    );
  };

  const onClosetSelect = (closetID) => {
    setSelectedClosetID(closetID);
    switch (closetID) {
      case "all-items":
        return;
      case "item-plan": {
        if (!!itemPlanCloset.data) return;
        return fetchItemPlanCloset(userID);
      }
      default: {
        const targetCloset = closetIndexWithDetails.data.find(
          (c) => c.id === closetID
        );
        const itemFetched = !!targetCloset.details;
        if (itemFetched) return;
        return fetchClosetDetails(closetID);
      }
    }
  };

  const buildClosetFilter = () => {
    return (
      <ClosetSelector
        searchValue={closetSearch}
        onSearch={(v) => setClosetSearch(v)}
        closetIndexWithDetails={closetIndexWithDetails}
        selectedClosetID={selectedClosetID}
        onClosetSelect={onClosetSelect}
      />
    );
  };

  const getItems = () => {
    const { data: itemPlanInventory } = itemPlanCloset;
    const { data: closets } = closetIndexWithDetails;
    const { data: inventory } = inventoryIndex;

    try {
      let items;
      switch (selectedClosetID) {
        case "all-items":
          items = inventory;
          break;
        case "item-plan":
          items = itemPlanInventory;
          break;
        default: {
          const targetCloset = closets.find((c) => c.id === selectedClosetID);
          items = targetCloset.details.items;
        }
      }

      return items || [];
    } catch (_) {
      return [];
    }
  };

  const buildClosetNameModifier = () => {
    if (!closetIndexWithDetails.data) return null;
    const selectedCloset = closetIndexWithDetails.data.find(
      (c) => c.id === selectedClosetID
    );
    return (
      <ClosetNameModifier
        products={products}
        selectedCloset={selectedCloset}
        selectedClosetID={selectedClosetID}
        onUpdateClosetDetails={updateClosetDetails}
        closetLoading={closetIndexWithDetails.loadingSingleCloset}
      />
    );
  };

  const buildVolumeCalculator = () => {
    if (selectedClosetID === "all-items" || selectedClosetID === "item-plan")
      return null;
    const targetCloset = closetIndexWithDetails.data.find(
      (c) => c.id === selectedClosetID
    );
    if (!targetCloset.details || !products.data) {
      return (
        <div className="closet-volume-bar spinning">
          <LoadingOutlined className="loading-icon" />
        </div>
      );
    }

    const targetProduct = products.data.find(
      (p) => p.id === targetCloset.stripe_plan_id
    );
    const totalQuantity = +targetCloset.quantity;
    const volume = +targetProduct.metadata.size || 0;
    const totalVolume =
      totalQuantity > 0 ? volume * totalQuantity : totalQuantity;
    const items = targetCloset.details.items || targetCloset.items || [];
    const itemVolume = items.reduce((acc, cv) => {
      if (cv?.status === "returned") return acc;
      return acc + (cv.category_item.volume || 0);
    }, 0);

    const percent = totalVolume ? (itemVolume / totalVolume) * 100 : 0;

    return (
      <div className="closet-volume-bar">
        <div className="description"> Space Taken:</div>
        <div className="progress-bar-wrap">
          <Progress
            percent={percent}
            status="active"
            className="bar"
            showInfo={false}
          />
          <div className="percent-text">{percent.toFixed(2)} %</div>
        </div>
      </div>
    );
  };

  const buildItemFilters = () => {
    const items = getItems();
    if (isEmpty(items)) return null;
    const filterButtons = Object.keys(STATUS).map((status) => {
      const count = items.filter((i) => i.status === status).length;
      return (
        <ItemFilterButton
          key={status}
          text={status.replace(/_/g, " ")}
          selected={selectedFilter === status}
          count={count}
          onClick={() => setSelectedFilter(status)}
        />
      );
    });
    return (
      <div className="item-filters">
        <ItemFilterButton
          key="All"
          text="All"
          selected={selectedFilter === "All"}
          count={items.length}
          onClick={() => setSelectedFilter("All")}
        />
        {filterButtons}
      </div>
    );
  };

  const onSelectNewClosetWithItems = (newClosetID, itemIDs) => {
    batchUpdateInventoryItems(userID, {
      ids: itemIDs,
      closet_id: newClosetID,
    }).then((res) => {
      if (res) {
        setSelectedClosetID("all-items");
        fetchClosetIndexWithDetails();
        notification.success({
          message: "Update Success",
          description: "successfully updated items to new closet",
        });
      }
    });
  };

  const onItemsStatusChange = (newStatus, itemIDs) => {
    batchUpdateInventoryItems(userID, { ids: itemIDs, status: newStatus }).then(
      (res) => {
        if (res) {
          setSelectedClosetID("all-items");
          fetchClosetIndexWithDetails();
          fetchItemPlanCloset(userID);
          notification.success({
            message: "Update Success",
            description: `successfully updated items status to ${newStatus}`,
          });
        }
      }
    );
  };

  const buildInventoryTable = () => {
    if (!inventoryIndex.data) return null;
    const items =
      selectedFilter === "All"
        ? getItems()
        : getItems().filter((i) => i.status === selectedFilter);
    const loading =
      itemPlanCloset.loading ||
      inventoryIndex.loading ||
      closetIndexWithDetails.loadingSingleCloset ||
      closetIndexWithDetails.loading;

    return (
      <Spin spinning={loading} tip="fetching inventory items...">
        <InventoryTable
          items={items}
          userID={userID}
          products={products}
          selectedClosetID={selectedClosetID}
          closets={closetIndexWithDetails.data}
          onSelectNewClosetWithItems={onSelectNewClosetWithItems}
          statusList={Object.keys(STATUS)}
          onItemsStatusChange={onItemsStatusChange}
        />
      </Spin>
    );
  };

  if (!customerDetails.data && customerDetails.loading) {
    return (
      <div className="customer-inventory spinning">
        <Spin tip="fetching customer details..." />
      </div>
    );
  }

  if (customerDetails.error)
    return <Alert message={customerDetails.error} type="error" />;

  return (
    <div className="page-container">
      <div className="customer-inventory" id="customer-inventory-page">
        {buildCustomerName()}
        {buildClosetFilter()}
        {buildClosetNameModifier()}
        {buildVolumeCalculator()}
        {buildItemFilters()}
        {buildInventoryTable()}
      </div>
    </div>
  );
};

CustomerInventoryPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(CustomerInventoryPage);
