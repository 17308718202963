import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";

const useCustomerNotes = () => {
  const [customerNotes, setCustomerNotes] = useState({
    data: null,
    loading: true,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchCustomerNotes = (userID) => {
    const url = `/users/${userID}/notes`;
    setCustomerNotes({ ...customerNotes, loading: true, error: null });
    return api
      .get(url)
      .then((res) =>
        setCustomerNotes({
          data: res.data.notes,
          loading: false,
          error: null,
        })
      )
      .catch((error) =>
        setCustomerNotes({ ...customerNotes, loading: false, error })
      );
  };

  const createNote = (userID, note) => {
    const url = `/users/${userID}/notes`;
    setCustomerNotes({ ...customerNotes, loading: true, error: null });
    return api
      .post(url, { note: { text: note } })
      .then((res) =>
        setCustomerNotes({
          data: res.data.notes,
          loading: false,
          error: null,
        })
      )
      .catch((error) => {
        setCustomerNotes({ ...customerNotes, loading: false, error });
        return false;
      });
  };

  const editNote = (userID, noteID, note) => {
    const url = `/users/${userID}/notes/${noteID}`;
    setCustomerNotes({ ...customerNotes, loading: true, error: null });
    return api
      .put(url, { note: { text: note } })
      .then((res) =>
        setCustomerNotes({
          data: res.data.notes,
          loading: false,
          error: null,
        })
      )
      .catch((error) => {
        setCustomerNotes({ ...customerNotes, loading: false, error });
        return false;
      });
  };

  const deleteNote = (userID, noteID) => {
    const url = `/users/${userID}/notes/${noteID}`;
    setCustomerNotes({ ...customerNotes, loading: true, error: null });
    return api
      .delete(url)
      .then((res) =>
        setCustomerNotes({
          data: res.data.notes,
          loading: false,
          error: null,
        })
      )
      .catch((error) => {
        setCustomerNotes({ ...customerNotes, loading: false, error });
        return false;
      });
  };

  return {
    customerNotes,
    fetchCustomerNotes,
    createNote,
    editNote,
    deleteNote,
  };
};

const useCustomerAddresses = () => {
  const [customerAddresses, setCustomerAddresses] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchCustomerAddresses = (userID) => {
    const url = `/users/${userID}/addresses`;
    setCustomerAddresses({ ...customerAddresses, loading: true, error: null });
    return api
      .get(url)
      .then((res) =>
        setCustomerAddresses({
          data: res.data.addresses,
          loading: false,
          error: null,
        })
      )
      .catch((error) =>
        setCustomerAddresses({ ...customerAddresses, loading: false, error })
      );
  };

  const createAddress = (userID, address) => {
    const url = `/users/${userID}/addresses`;
    setCustomerAddresses({ ...customerAddresses, loading: true, error: null });
    return api
      .post(url, { address })
      .then(() => api.get(url))
      .then((res) =>
        setCustomerAddresses({
          data: res.data.addresses,
          loading: false,
          error: null,
        })
      )
      .catch((error) => {
        setCustomerAddresses({ ...customerAddresses, loading: false, error });
        return false;
      });
  };

  const updateCustomerAddress = (
    customerID,
    addressID,
    fullName,
    phoneNumber
  ) => {
    const url = `/users/${customerID}/addresses/${addressID}`;

    const body = {
      address: {
        full_name: fullName,
        phone_number: phoneNumber,
      },
    };

    setCustomerAddresses({ ...customerAddresses, loading: true, error: null });
    return api
      .put(url, body)
      .then((response) => {
        const updatedAddress = response.data.address;
        const newCustomerAddresses = customerAddresses.data.filter(
          (address) => address.id !== addressID
        );
        newCustomerAddresses.push(updatedAddress);
        setCustomerAddresses({
          data: newCustomerAddresses,
          loading: false,
          error: null,
        });
        return updatedAddress;
      })
      .catch((error) => {
        setCustomerAddresses({ ...customerAddresses, loading: false, error });
        return false;
      });
  };

  return {
    customerAddresses,
    fetchCustomerAddresses,
    createAddress,
    updateCustomerAddress,
  };
};

const useCustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const fetchCustomerDetails = (userID) => {
    setCustomerDetails({ ...customerDetails, loading: true, error: null });

    const api = getAPIFromDomain();
    const url = `/users/${userID}`;
    return api
      .get(url)
      .then((res) =>
        setCustomerDetails({ data: res.data.user, loading: false, error: null })
      )
      .catch((error) =>
        setCustomerDetails({ ...customerDetails, loading: false, error })
      );
  };

  const updateCustomerDetails = (userID, body) => {
    setCustomerDetails({ ...customerDetails, loading: true, error: null });

    const api = getAPIFromDomain();
    const url = `/users/${userID}`;
    return api
      .put(url, body)
      .then((res) => {
        setCustomerDetails({
          data: res.data.user,
          loading: false,
          error: null,
        });
        return true;
      })
      .catch((error) =>
        setCustomerDetails({ ...customerDetails, loading: false, error })
      );
  };

  const updateCustomerRoles = (userID, role, subrole) => {
    setCustomerDetails({ ...customerDetails, loading: true, error: null });
    const api = getAPIFromDomain();
    const url = `/users/${userID}/role`;
    const user = {};
    if (role) user.role = role;
    user.subrole = subrole || "";
    return api
      .put(url, { user })
      .then(() => setCustomerDetails({ data: {}, loading: false, error: null }))
      .catch((error) =>
        setCustomerDetails({ ...customerDetails, loading: false, error })
      );
  };

  const setCustomerDetailsLoading = (loading) => {
    setCustomerDetails({ ...customerDetails, loading });
  };

  return {
    customerDetails,
    fetchCustomerDetails,
    updateCustomerDetails,
    updateCustomerRoles,
    setCustomerDetailsLoading,
  };
};

const useCustomerBilling = () => {
  const [customerBilling, setCustomerBilling] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchCustomerBilling = (userID) => {
    let url = `/users/${userID}/stripe_customer`;
    setCustomerBilling({ ...customerBilling, loading: true, error: null });
    return api
      .get(url)
      .then((res) =>
        setCustomerBilling({ data: res.data, loading: false, error: null })
      )
      .catch((error) =>
        setCustomerBilling({ ...customerBilling, loading: false, error })
      );
  };

  const updateCustomerStripeToken = (userID, token) => {
    const url = `/users/${userID}/stripe_token?stripe_token=${token}`;
    return api.post(url, null);
  };

  const addCreditCard = (stripe, userID) => {
    setCustomerBilling({ ...customerBilling, loading: true, error: null });
    return stripe
      .createToken()
      .then((res) => {
        if (res.error) throw res.error;
        return updateCustomerStripeToken(userID, res.token.id);
      })
      .then(() => fetchCustomerBilling(userID))
      .catch((error) => {
        if (typeof error !== "string") error = error.message;
        setCustomerBilling({ ...customerBilling, loading: false, error });
      });
  };

  const reset = () =>
    setCustomerBilling({ data: null, loading: false, error: null });

  return { customerBilling, fetchCustomerBilling, addCreditCard, reset };
};

const useCustomerItemCsv = () => {
  const [customerItemCsv, setCustomerItemCsv] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();

  const fetchCustomerItemCsv = (userID, quantity) => {
    setCustomerItemCsv({ data: null, loading: true, error: null });
    const url = `/users/${userID}/qr_codes/${quantity}.csv`;
    return api
      .get(url)
      .then((res) => {
        setCustomerItemCsv({ data: res.data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setCustomerItemCsv({ data: null, loading: false, error });
        return false;
      });
  };

  return { customerItemCsv, fetchCustomerItemCsv };
};

const useCustomerCreate = () => {
  const [customerCreateStatus, setCustomerCreateStatus] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const createCustomer = (body) => {
    setCustomerCreateStatus({
      ...customerCreateStatus,
      loading: true,
      error: null,
    });
    const api = getAPIFromDomain();
    const url = `/users`;
    return api
      .post(url, body)
      .then((res) =>
        setCustomerCreateStatus({ data: res.data, loading: false, error: null })
      )
      .catch((error) =>
        setCustomerCreateStatus({
          ...customerCreateStatus,
          loading: false,
          error,
        })
      );
  };

  return { customerCreateStatus, createCustomer };
};

export {
  useCustomerNotes,
  useCustomerAddresses,
  useCustomerDetails,
  useCustomerBilling,
  useCustomerItemCsv,
  useCustomerCreate,
};
