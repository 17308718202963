import styled from "styled-components";

interface SearchResultsWrapperProps {
  width?: number;
  maxHeight?: number;
  reverse?: boolean;
  bottom?: number;
}

export const SearchResultsWrapper = styled.div<SearchResultsWrapperProps>`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  min-width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  max-width: 800px;
  min-height: 51px;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : "initial"};
  overflow: auto;
  bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "initial")};
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;
