import { getAPIFromDomain } from "../../lib/api";
import { Warehouse } from "@secondcloset/types";
import queryString from "query-string";

export const fetchShipmentProductsTrackings = async (
  query: Record<string, unknown>
): Promise<Warehouse.ShipmentProductsTracking[]> => {
  const api = getAPIFromDomain("november");
  const url = `/shipment-products-trackings?${queryString.stringify(query)}`;
  const response = await api.get(url);
  return response.data.items;
};
