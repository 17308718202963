export const STORAGE_FAILED_REASONS = [
  { name: "Unable to Contact Customer", value: "unable_to_contact_customer" },
  { name: "Customer Refused Package", value: "customer_refusal" },
  { name: "Items Not Staged", value: "items_not_staged" },
  { name: "Unable to Park Vehicle ", value: "unable_to_park_vehicle" },
  { name: "Unsafe Drop Off Area", value: "unsafe_drop_off_area" },
  { name: "Other", value: "other" },
];

export const FULFILLMENT_FAILED_REASONS = [
  {
    name: "All Items Damaged",
    value: "all_items_damaged",
  },
  {
    name: "All Items Not Staged",
    value: "all_items_not_staged",
  },
  {
    name: "All Items Wrong",
    value: "all_items_wrong",
  },
  {
    name: "Customer Changed Mind",
    value: "customer_changed_mind",
  },
  {
    name: "Elevator Not Booked",
    value: "elevator_not_booked",
  },
  {
    name: "Unsafe Drop Off Area",
    value: "unsafe_drop_off_area",
  },
  {
    name: "Customer Not At Home/Not Responding",
    value: "customer_not_home_or_not_responding",
  },
  {
    name: "Wrong Address",
    value: "wrong_address",
  },
  {
    name: "Other",
    value: "other",
  },
];
