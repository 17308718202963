import Products from "./products";
import { uniq } from "lodash-es";
import moment from "moment";

export default class Order {
  static getItemPlanOrderBody = (requiredFields, optionalFields) => {
    let {
      addressID,
      date1,
      date2,
      time1,
      time2,
      customerSegment,
      serviceArea,
    } = requiredFields;
    let { billingType, immediate, itemList, isBulkyOnly } = requiredFields;
    let coupon, notes;
    if (optionalFields) {
      coupon = optionalFields.coupon;
      notes = optionalFields.notes;
    }

    const order_items = itemList.map((item) => {
      const isSupply = Products.isSupply(item);
      const isFee = Products.isFee(item);
      const result = { quantity: item.quantity };
      if (isSupply || isFee) result.stripe_product_id = item.id;
      else result.stripe_plan_id = item.id;
      return result;
    });

    date2 = date2 || date1;
    time2 = time2 || time1;

    const body = {
      order: {
        pick_up_address_id: addressID,
        drop_off_address_id: addressID,
        pick_up_date: date2,
        pick_up_time_range: time2,
        drop_off_date: date1,
        drop_off_time_range: time1,
        billing_type: billingType,
        immediate,
        order_items,
        bulky_order_only: isBulkyOnly,
        customer_segment: customerSegment,
        service_area: serviceArea,
      },
    };

    if (coupon) {
      body.order.stripe_coupon_id = coupon;
    }

    if (notes && !!notes.trim()) {
      body.order.notes = notes;
    }

    return body;
  };

  static getReturnOrderBody = (requiredFields, optionalFields) => {
    let {
      addressID,
      date1,
      date2,
      time1,
      time2,
      customerSegment,
      serviceArea,
    } = requiredFields;
    let { billingType, immediate, returnCart, isBulkyOnly } = requiredFields;
    let { temporary, notes } = optionalFields;

    date2 = date2 || date1;
    time2 = time2 || time1;

    const body = {
      order: {
        temporary,
        pick_up_address_id: addressID,
        drop_off_address_id: addressID,
        pick_up_date: date2,
        pick_up_time_range: time2,
        drop_off_date: date1,
        drop_off_time_range: time1,
        billing_type: billingType,
        immediate,
        bulky_order_only: isBulkyOnly,
        order_items: returnCart.map((item) => ({ inventory_item_id: item.id })),
        customer_segment: customerSegment,
        service_area: serviceArea,
      },
    };

    if (notes && !!notes.trim()) {
      body.order.notes = notes;
    }

    return body;
  };

  static getSpacePlanOrderBody = (requiredFields, optionalFields) => {
    let {
      addressID,
      date1,
      date2,
      time1,
      time2,
      customerSegment,
      serviceArea,
    } = requiredFields;
    let {
      billingType,
      immediate,
      itemList,
      isBulkyOnly,
      spaceItems,
    } = requiredFields;
    let coupon, notes;
    if (optionalFields) {
      coupon = optionalFields.coupon;
      notes = optionalFields.notes;
    }

    const order_items = itemList.map((item) => {
      const isSupply = Products.isSupply(item);
      const isFee = Products.isFee(item);
      const result = { quantity: item.quantity };
      if (isSupply || isFee) result.stripe_product_id = item.id;
      else result.stripe_plan_id = item.id;
      return result;
    });

    const space_items = spaceItems.map((item) => ({
      category_item_id: item.id,
      quantity: item.quantity,
    }));

    date2 = date2 || date1;
    time2 = time2 || time1;

    const body = {
      order: {
        pick_up_address_id: addressID,
        drop_off_address_id: addressID,
        pick_up_date: date2,
        pick_up_time_range: time2,
        drop_off_date: date1,
        drop_off_time_range: time1,
        billing_type: billingType,
        immediate,
        order_items,
        space_order: true,
        bulky_order_only: isBulkyOnly,
        space_items,
        customer_segment: customerSegment,
        service_area: serviceArea,
      },
    };

    if (coupon) {
      body.order.stripe_coupon_id = coupon;
    }

    if (notes && !!notes.trim()) {
      body.order.notes = notes;
    }

    return body;
  };

  static getStorageAddItemsBody = (originalOrder, newProducts) => {
    const originalOrderItemsStripeIDs = originalOrder.items.map(
      (i) => i.stripe_id
    );
    const newItemsStripeIDs = Object.keys(newProducts);
    const allStripeIDs = uniq(
      originalOrderItemsStripeIDs.concat(newItemsStripeIDs)
    );

    const order_items = allStripeIDs.reduce((acc, stripe_id) => {
      const targetItem = originalOrder.items.find(
        (i) => i.stripe_id === stripe_id
      );
      const isSupply = targetItem && targetItem.item_type === "Product";
      if (isSupply) {
        //acc.push({ stripe_product_id: stripe_id, quantity: targetItem.quantity });
        return acc;
      }
      const originalQuantity = originalOrderItemsStripeIDs.filter(
        (id) => id === stripe_id
      ).length;
      const addedQuantity = newProducts[stripe_id]
        ? newProducts[stripe_id].quantity
        : 0;
      const totalNewQuantity = originalQuantity + addedQuantity;
      acc.push({ stripe_plan_id: stripe_id, quantity: totalNewQuantity });
      return acc;
    }, []);

    return { order: { order_items } };
  };

  static getStorageEditSuppliesBody = (originalOrder, updatedSupplies) => {
    const originalStripeIDs = originalOrder.items.reduce(
      (acc, cv) => {
        if (cv.item_type !== "Product") acc.regularIDs.push(cv.stripe_id);
        else acc.suppliesIDs.push(cv.stripe_id);
        return acc;
      },
      { regularIDs: [], suppliesIDs: [] }
    );

    const allStripeIDs = uniq(originalStripeIDs.regularIDs);
    const regularItems = allStripeIDs.map((stripe_id) => {
      const quantity = originalOrder.items.filter(
        (i) => i.stripe_id === stripe_id
      ).length;
      return { stripe_plan_id: stripe_id, quantity };
    });

    const newSuppliesStripeIDs = Object.keys(updatedSupplies);
    const allSuppliesIDs = uniq(
      originalStripeIDs.suppliesIDs.concat(newSuppliesStripeIDs)
    );
    const supplies = allSuppliesIDs.map((stripe_id) => {
      const quantity = updatedSupplies[stripe_id]
        ? updatedSupplies[stripe_id].quantity
        : 0;
      return { stripe_product_id: stripe_id, quantity };
    });

    return { order: { order_items: [...regularItems, ...supplies] } };
  };

  static getSpaceStorageAddItemsBody = (originalOrder, newProducts) => {
    const spaceItems = originalOrder.items.filter(
      (i) => i.item_type !== "Product" && i.item_type !== "Closet"
    );

    const originalOrderItemsCategoryIDs = spaceItems.map((i) => i.category_id);
    const newItemsCategoryIDs = Object.keys(newProducts);
    const allCategoryIDs = uniq(
      originalOrderItemsCategoryIDs.concat(newItemsCategoryIDs)
    );

    const space_items = allCategoryIDs.reduce((acc, category_id) => {
      const originalQuantity = originalOrderItemsCategoryIDs.filter(
        (id) => id === category_id
      ).length;
      const addedQuantity = newProducts[category_id]
        ? newProducts[category_id].quantity
        : 0;
      const totalNewQuantity = originalQuantity + addedQuantity;
      acc.push({ category_item_id: category_id, quantity: totalNewQuantity });
      return acc;
    }, []);

    return { order: { space_items } };
  };

  static getSpaceStorageEditSuppliesBody = (originalOrder, updatedSupplies) => {
    const originalIDs = originalOrder.items.reduce(
      (acc, cv) => {
        if (cv.item_type === "Closet") return acc;
        if (cv.item_type !== "Product") acc.categoryIDs.push(cv.category_id);
        else acc.suppliesIDs.push(cv.stripe_id);
        return acc;
      },
      { categoryIDs: [], suppliesIDs: [] }
    );

    const originalCategoryIDs = originalIDs.categoryIDs;
    const allCategoryIDs = uniq(originalCategoryIDs);

    const space_items = allCategoryIDs.map((category_id) => {
      const quantity = originalOrder.items.filter(
        (i) => i.category_id === category_id
      ).length;
      return { category_item_id: category_id, quantity };
    });

    const originalSuppliesStripeIDs = originalIDs.suppliesIDs;
    const newSuppliesStripeIDs = Object.keys(updatedSupplies);
    const allSuppliesStripeIDs = uniq(
      originalSuppliesStripeIDs.concat(newSuppliesStripeIDs)
    );

    const order_items = allSuppliesStripeIDs.map((stripe_id) => {
      const quantity = updatedSupplies[stripe_id]
        ? updatedSupplies[stripe_id].quantity
        : 0;
      return {
        stripe_product_id: stripe_id,
        quantity,
      };
    });

    return { order: { space_items, order_items } };
  };

  static getStorageRemoveItemsBody = (originalOrder, removingItems) => {
    const originalIDs = originalOrder.items.map((i) => i.stripe_id);
    const order_items = originalIDs
      .map((stripe_id) => {
        const targetItem = originalOrder.items.find(
          (i) => i.stripe_id === stripe_id
        );
        if (targetItem.item_type === "Product") {
          //return { stripe_product_id: stripe_id, quantity: targetItem.quantity };
          return null;
        }

        const foundRemovingItems = removingItems.filter(
          (i) => i.stripe_id === stripe_id
        );
        const removingQuantity = foundRemovingItems.length;
        const originalQuantity = originalOrder.items.filter(
          (i) => i.stripe_id === stripe_id
        ).length;
        const newQuantity = originalQuantity - removingQuantity;
        return { stripe_plan_id: stripe_id, quantity: newQuantity };
      })
      .filter(Boolean);

    const inventory_items_ids = removingItems.map((item) => item.item_id);

    return { order: { order_items, inventory_items_ids } };
  };

  static getSpaceStorageRemoveItemsBody = (originalOrder, removingItems) => {
    const spaceItems = originalOrder.items.filter(
      (i) => i.item_type !== "Product" && i.item_type !== "Closet"
    );
    const originalOrderItemsCategoryIDs = spaceItems.map((i) => i.category_id);
    const allCategoryIDs = uniq(originalOrderItemsCategoryIDs);

    const space_items = allCategoryIDs.reduce((acc, category_id) => {
      const originalQuantity = originalOrderItemsCategoryIDs.filter(
        (id) => id === category_id
      ).length;
      const removingQuantity = removingItems.filter(
        (item) => item.category_id === category_id
      ).length;

      const totalNewQuantity = originalQuantity - removingQuantity;
      acc.push({ category_item_id: category_id, quantity: totalNewQuantity });
      return acc;
    }, []);

    const inventory_items_ids = removingItems.map((item) => item.item_id);

    return { order: { space_items, inventory_items_ids } };
  };

  static getReturnAddItemsBody = (originalOrder, newItemIDs) => {
    const orderItemIDs = originalOrder.items
      .filter((i) => !["early-recall", "recall"].includes(i.stripe_id))
      .map((i) => i.item_id);

    const order_items = [...orderItemIDs, ...newItemIDs].map((i) => ({
      inventory_item_id: i,
    }));

    return { order: { order_items } };
  };

  static getReturnRemoveItemsBody(originalOrder, removingItemIDs) {
    const orderItemIDs = originalOrder.items
      .filter((i) => !["early-recall", "recall"].includes(i.stripe_id))
      .map((i) => i.item_id);

    const order_items = orderItemIDs
      .filter((i) => removingItemIDs.indexOf(i) === -1)
      .map((i) => ({ inventory_item_id: i }));

    return { order: { order_items } };
  }

  static hasAppointmentWithinHours = (order, hours) => {
    if (!order) return true;
    const allAppointmentDates = order.appointments?.map((a) => a.date);
    const date = moment().add(hours, "hours");
    return allAppointmentDates.some((d) =>
      moment(d, "YYYY-MM-DD").isBefore(date)
    );
  };
}
