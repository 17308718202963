import React from "react";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button, Spin, Alert } from "antd";

const StripeElement = (props) => {
  return (
    <div className="stripe-element">
      <Spin tip="updating..." spinning={props.loading}>
        {props.error && <Alert type="error" message={props.error} banner />}
        <CardElement hidePostalCode={true} />
        <div className="button-group">
          <Button onClick={props.onCancel} danger>
            Cancel
          </Button>
          <Button onClick={() => props.onAddCreditCard(props.stripe)}>
            Submit
          </Button>
        </div>
      </Spin>
    </div>
  );
};

StripeElement.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAddCreditCard: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default injectStripe(StripeElement);
