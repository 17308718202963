import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { Spin, Alert, notification } from "antd";
import AppointmentItemTable from "./AppointmentItemTable/AppointmentItemTable";
import { useAppointmentDetails } from "../../../hooks/api/regular/appointment";
import { useProducts } from "../../../hooks/api/regular/products";
import { Helmet } from "react-helmet";

import FailedAppointmentBanner from "./FailedAppointmentBanner";
import AppointmentActionsButtons from "./AppointmentActionsButtons";
import ButtonsRow from "./ButtonsRow";
import AppointmentInfo from "./AppointmentInfo";
import CustomerInfo from "./CustomerInfo";

const AppointmentDetailsPage = ({ match }) => {
  const {
    appointmentDetails,
    updateAppointmentDetails,
    updateAppointmentStatus,
    fetchAppointmentDetails,
  } = useAppointmentDetails();
  const { products, fetchProducts } = useProducts();

  const getAppointmentDetails = () => {
    fetchAppointmentDetails(match.params.apptID);
  };

  const onAppointmentError = () => {
    if (appointmentDetails.error) {
      notification.error({
        message: "Error",
        description: appointmentDetails.error,
      });
    }
  };

  const onAppointmentDetailChange = () => {
    if (
      appointmentDetails.data &&
      !products.data &&
      appointmentDetails.data.type !== "fulfillment"
    ) {
      fetchProducts({
        service_area: appointmentDetails.data.address.service_area,
      });
    }
  };

  useEffect(getAppointmentDetails, [match.params.apptID]);
  useEffect(onAppointmentDetailChange, [appointmentDetails.data]);
  useEffect(onAppointmentError, [appointmentDetails.error]);

  if (!appointmentDetails.data && appointmentDetails.loading) {
    return (
      <div className="page-spinner">
        <Spin tip="fetching appointment..." />
      </div>
    );
  }

  if (!appointmentDetails.data) return null;

  const buildAppointmentItems = () => {
    if (!appointmentDetails.data || !appointmentDetails.data.items) return null;

    const { data, loading, error } = products;
    let content;
    if (loading) {
      return (
        <div className="appointment-items-card card spinning">
          <Spin tip="Fetching products..." />
        </div>
      );
    }

    if (data) {
      content = (
        <AppointmentItemTable
          appointment={appointmentDetails.data}
          products={data}
          fetchAppointmentDetails={fetchAppointmentDetails}
        />
      );
    }

    return (
      <div className="appointment-items-card card">
        {error && <Alert message={error} type="error" />}
        {content}
      </div>
    );
  };

  if (
    appointmentDetails.data &&
    appointmentDetails.data.type === "fulfillment"
  ) {
    const orderID = appointmentDetails.data.source.id;
    return <Redirect to={`/fulfillment/orders/${orderID}`} />;
  }

  return (
    <Spin spinning={appointmentDetails.loading} tip="updating appointment...">
      <Helmet title="Sierra - Appointment" />
      <div className="page-container">
        <div className="appointment-details" id="appointment-details-page">
          <FailedAppointmentBanner
            appointment={appointmentDetails.data}
            onRefetchAppointment={getAppointmentDetails}
          />
          <AppointmentActionsButtons
            appointment={appointmentDetails.data}
            onRefetchAppointment={getAppointmentDetails}
          />
          <ButtonsRow
            appointment={appointmentDetails.data}
            onRefetchAppointment={getAppointmentDetails}
          />
          <AppointmentInfo
            appointment={appointmentDetails.data}
            onUpdateAppointmentStatus={updateAppointmentStatus}
            loading={appointmentDetails.loading}
          />
          <CustomerInfo
            appointment={appointmentDetails.data}
            onUpdateAppointmentDetails={updateAppointmentDetails}
          />
          {buildAppointmentItems()}
        </div>
      </div>
    </Spin>
  );
};

AppointmentDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AppointmentDetailsPage);
