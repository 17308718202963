const setScroll = (pageName) => {
  const position = window.scrollY;
  sessionStorage.setItem("scrollPage", pageName);
  sessionStorage.setItem("scrollPosition", position);
};

/**
 * @description deletes saved scroll data
 */
const clearScroll = () => {
  sessionStorage.removeItem("scrollPage");
  sessionStorage.removeItem("scrollPosition");
};

export { setScroll, clearScroll };
