import { Facility } from "@secondcloset/fulfillment-utils";
const facilities = Facility.getFacilityList();

const INITIAL_STATE = {
  loading: true,
  data: facilities,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
