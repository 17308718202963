import * as actionTypes from "../../../actions/fulfillment/types";

const INITIAL_STATE = {
  fetching: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    FULFILLMENT_PRODUCT_POST_REQUESTED,
    FULFILLMENT_PRODUCT_POST_SUCCESS,
    FULFILLMENT_PRODUCT_POST_ERRORED,
  } = actionTypes;

  switch (action.type) {
    case FULFILLMENT_PRODUCT_POST_REQUESTED:
      return { ...state, fetching: true };
    case FULFILLMENT_PRODUCT_POST_SUCCESS:
      return { ...state, fetching: false, error: null };
    case FULFILLMENT_PRODUCT_POST_ERRORED:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
}
