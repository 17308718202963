export function compressItems(array) {
  /**
   * function(array) -> array
   *
   * compressing items with the same name into
   * one item and add up their quantities
   */

  if (!array || !Array.isArray(array)) {
    return [];
  }

  let result = array.reduce((acc, obj) => {
    if (!acc[obj.name]) {
      acc[obj.name] = JSON.parse(JSON.stringify(obj));
      acc[obj.name].inventoryItemIDs = [];
      acc[obj.name].barcodes = [];
      acc[obj.name].quantity = 0;
    }
    acc[obj.name].quantity += obj.quantity;
    acc[obj.name].inventoryItemIDs.push(obj.item_id);
    if (obj.barcode && obj.barcode !== "") {
      acc[obj.name].barcodes.push(obj.barcode);
    }
    return acc;
  }, {});

  return result;
}
