import { getAPIFromDomain } from "../lib/api";
import { toast } from "react-toastify";

import { getErrorMsg } from "./actionHelperFunctions";

export function invoiceCreate(userID, invoice, cb = () => {}) {
  const api = getAPIFromDomain();
  const body = { invoice };
  return function () {
    api
      .post(`/users/${userID}/invoices`, body)
      .then((response) => {
        // successful response from the server.
        toast.success("Success! New invoice created", {
          position: toast.POSITION.TOP_RIGHT,
        });
        cb(null, response);
      })
      .catch((error) => {
        // something went wrong with the request
        const message = getErrorMsg(error);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        cb(message, null);
      });
  };
}
