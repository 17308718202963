import { getAPIFromDomain } from "../lib/api";

export const updateInventoryDetails = (
  userId: string,
  itemId: string,
  body: unknown
) => {
  const api = getAPIFromDomain();
  const url = `/users/${userId}/inventory_items/${itemId}`;
  return api.put(url, body);
};

// Category items
export const lookupCategoryItems = (text: string) => {
  const api = getAPIFromDomain();
  const url = `/category_items?q=${text}`;
  return api.get(url);
};
