import { useSelector } from "react-redux";
import { Common } from "@secondcloset/types";
type User = Common.User;

//selectors
const selectUser = (state: any) => {
  return state.auth.user;
};

const useUser = () => {
  const user = useSelector(selectUser) as User;
  return { user };
};

export default useUser;
