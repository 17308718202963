import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Alert, notification, Select } from "antd";
import { useCustomerDetails } from "../../../../../hooks/api/regular/customer";
import BlueEditButton from "../BlueEditButton";
import User from "../../../../../lib/regular/user";

const EditRolesButton = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [newSubrole, setNewSubrole] = useState("");
  const [submitError, setSubmitError] = useState("");
  const {
    customerDetails,
    updateCustomerRoles,
    setCustomerDetailsLoading,
  } = useCustomerDetails();

  const onMount = () => {
    setCustomerDetailsLoading(false);
  };

  const onFieldChange = () => {
    setSubmitError("");
  };

  const onCustomerDetailsUpdate = () => {
    if (customerDetails.data) {
      setModalVisible(false);
      props.onUpdateSuccess();
    }

    if (customerDetails.error) {
      notification.error({
        message: "Error",
        description: customerDetails.error,
      });
    }
  };

  useEffect(onMount, []);
  useEffect(onFieldChange, [newRole, newSubrole]);
  useEffect(onCustomerDetailsUpdate, [customerDetails]);

  const renderSelectBox = (
    label,
    options,
    onChange,
    value,
    disabled = false
  ) => {
    return (
      <div className="field-input">
        <div className="label">{label}</div>
        <Select
          disabled={disabled}
          value={value || "------------"}
          onChange={onChange}
          style={{ width: 150 }}
        >
          {options.map((opt) => (
            <Select.Option value={opt.value} key={opt.value}>
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const onSave = () => {
    const role = newRole;
    const subrole = newSubrole;
    updateCustomerRoles(props.user.id, role, subrole);
  };

  const renderError = () => {
    if (submitError) {
      return <Alert message={submitError} type="error" banner />;
    }
  };

  const onRoleChange = (role) => {
    if (role !== "is_admin") {
      setNewSubrole(null);
    }
    setNewRole(role);
  };

  const renderModal = () => {
    const isSubroleDisabled = newRole !== "is_admin";
    return (
      <Modal
        title="User Role"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="edit-user-role-modal">
          {renderError()}
          {renderSelectBox("Role", User.getRoles(), onRoleChange, newRole)}
          {renderSelectBox(
            "Subrole",
            User.getSubroles(),
            (v) => setNewSubrole(v),
            newSubrole,
            isSubroleDisabled
          )}
          <div className="action-buttons">
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button
              onClick={onSave}
              type="primary"
              loading={customerDetails.loading}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const onEditClick = () => {
    const { role, subrole } = props.user;
    setNewRole(role);
    setNewSubrole(subrole);
    setSubmitError("");
    setModalVisible(true);
  };

  return (
    <div>
      <BlueEditButton onClick={onEditClick}>Change</BlueEditButton>
      {renderModal()}
    </div>
  );
};

EditRolesButton.propTypes = {
  user: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default EditRolesButton;
