import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DashOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

const DotButtonDropdown = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdown = useRef();

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const handleOutsideClick = (e) => {
    if (!dropdown.current?.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  const buildList = () => {
    return (
      <div className={`dropdown-list ${dropdownVisible ? "" : "collapsed"}`}>
        {props.options.map((option) => (
          <div
            key={option}
            className="dropdown-row"
            onClick={() => props.onRowClick(option)}
          >
            {option}
          </div>
        ))}
      </div>
    );
  };

  if (!props.disabled) {
    return (
      <div className="dot-button-dropdown" ref={dropdown}>
        <Button
          icon={<EllipsisOutlined />}
          shape="circle"
          onClick={() => setDropdownVisible(true)}
        />
        {buildList()}
      </div>
    );
  }

  return (
    <Tooltip
      trigger={["click"]}
      title={props.disabledText || "Disabled"}
      placement="bottomLeft"
    >
      <div className="dot-button-dropdown" ref={dropdown}>
        <Button icon={<DashOutlined />} shape="circle" onClick={() => {}} />
      </div>
    </Tooltip>
  );
};

DotButtonDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
};

export default DotButtonDropdown;
