import React, { useState, useEffect, useMemo } from "react";
import { startCase } from "lodash-es";
import moment from "moment";

// lib
import Shipment from "../../../../../../lib/fulfillment/shipment";
import { AppointmentsAvailability } from "@secondcloset/logistics-utils";
import { isSupportEscalated } from "../../../../../../helperFunctions/user";
import Organization from "../../../../../../lib/organization";
import {
  fetchShipmentAvailabilities,
  createShipment,
} from "../../../../../../api/fulfillment/shipment";

// hooks
import useUser from "../../../../../../hooks/reduxContainer/_common/useUser";
import { useMutation } from "react-query";

// components
import { Modal, notification, Spin } from "antd";
import { DateTimeSelector } from "@secondcloset/web-components";

// types
import { Fulfillment } from "@secondcloset/types";

type ShipmentType = Fulfillment.Shipment;
type PackageType = Fulfillment.Package;
type Order = Fulfillment.Order;
type SecondClosetShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => any;
  shipment: ShipmentType;
  order: Order;
  onRefetch: () => any;
  // when clicking return to sender button, different from recreating a return to sender shipment
  isNewReturnToSender?: boolean;
  returnToSenderAddressID?: string;
}

const RecreateShipmentModal: React.FC<Props> = ({
  visible,
  setVisible,
  shipment,
  order,
  onRefetch,
  isNewReturnToSender,
  returnToSenderAddressID,
}) => {
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const { mutateAsync: doCreateShipment, ...shipmentDetails } = useMutation(
    createShipment
  );
  const {
    mutate: doFetchShipmentAvailabilities,
    ...shipmentAvailabilities
  } = useMutation(fetchShipmentAvailabilities);

  const { user } = useUser();

  const ikeaOrgID = Organization.getIkeaOrgID();
  const isIkea = order?.organization?.id === ikeaOrgID;

  const role = user?.role || null;
  const subrole = user?.subrole || null;

  // when it is ikea, all user will have admin ability to reschedule
  const isAdmin = isSupportEscalated(role, subrole) || isIkea;

  useEffect(() => {
    if (!visible) return;
    const today = moment().format("YYYY-MM-DD");
    const daysAhead = moment().add(90, "days").format("YYYY-MM-DD");
    const options = {
      shipment_id: shipment?.id,
      date_from: today,
      date_to: daysAhead,
    };
    doFetchShipmentAvailabilities(options);
    // eslint-disable-next-line
  }, [visible]);

  const isSecondClosetAppointment =
    shipment?.shipping_method_type === "appointment";
  const shippingMethod = shipment?.shipping_method as SecondClosetShippingMethod;

  const availabilityInstance = useMemo(() => {
    return new AppointmentsAvailability(shipmentAvailabilities.data || [], {
      isAdmin,
      serviceArea: shippingMethod?.location?.service_area || "yyz",
    });
    // eslint-disable-next-line
  }, [shipmentAvailabilities.data]);

  if (!isSecondClosetAppointment) return null;

  const jobType = isNewReturnToSender
    ? "return_to_sender"
    : shippingMethod?.job_type;

  const isInventoryPickupOrReturnToSender =
    jobType === "inventory_pick_up" || jobType === "return_to_sender";

  const modalTitle = `Re-create ${startCase(jobType)} Shipment`;

  const getFormattedPackages = () => {
    return shipment?.packages.reduce((acc: any, pkg: PackageType) => {
      const orderItemIDs = pkg.shipment_item_ids.map((shipmentItemID) => {
        const matchingShipmentItem = shipment.shipment_items.find(
          (i) => i.id === shipmentItemID
        );
        const matchingItemOrderID = matchingShipmentItem?.order_item_id;
        return matchingItemOrderID;
      });

      const packageDetails = orderItemIDs.reduce(
        (acc, orderItemID) => {
          const orderItem = order?.items?.find(
            (item) => item.id === orderItemID
          );
          const product = orderItem?.product;
          acc.weight_value += product?.weight || 0;
          acc.length_value += product?.length || 0;
          acc.height_value += product?.height || 0;
          acc.width_value += product?.width || 0;
          return acc;
        },
        {
          tracking_number: pkg.tracking_number,
          weight_value: 0,
          weight_unit: "lb",
          length_value: 0,
          length_unit: "in",
          height_value: 0,
          height_unit: "in",
          width_value: 0,
          width_unit: "in",
        }
      );
      return [
        ...acc,
        {
          order_item_ids: orderItemIDs,
          ...packageDetails,
        },
      ];
    }, []);
  };

  const onCreateShipment = async () => {
    if (!selectedTime || !selectedDate) {
      return notification.error({ message: "Please select date and time" });
    }
    const orderItemIDs = shipment?.shipment_items?.map((i) => i?.order_item_id);
    const isIkeaV2 = order?.external_platform_version === 2;

    let addressID;
    if (isNewReturnToSender) {
      // when user click return to sender button on a delivery
      addressID = returnToSenderAddressID;
    } else if (isInventoryPickupOrReturnToSender) {
      // use shipping method address for ikea warehouse because it should stay the same
      addressID = shippingMethod?.location?.id;
    } else {
      // use order address for customer address in case address is changed
      addressID = order?.address?.id;
    }

    if (!addressID) {
      return notification.error({ message: "Address not found" });
    }

    const serviceLevel = shippingMethod.delivery_service_level;

    const options = {
      orderItemIDs,
      externalShipmentID: shipment?.external_shipment_id,
      date: selectedDate,
      timeSlot: selectedTime,
      addressID,
      serviceLevel,
      packages: isIkeaV2 ? getFormattedPackages() : undefined,
    };

    const body = Shipment.getCreateIkeaShipmentBody(
      order?.id,
      jobType,
      options
    );
    const result = await doCreateShipment(body);
    if (result) {
      notification.success({
        message: "Success!",
        description: `New ${startCase(jobType)} shipment created`,
      });
      onRefetch();
    }

    setVisible(false);
  };

  const isDateDisabled = (date: string) => {
    return availabilityInstance.isDateOneDisabled(date);
  };

  const isSlotDisabled = (timeslot: string) => {
    return availabilityInstance.isSlotOneDisabled(selectedDate || "", timeslot);
  };

  const renderDateTimeSelector = () => {
    return (
      <div className="date-picker">
        <DateTimeSelector
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onSelectDate={(date: string | null) =>
            setSelectedDate(date || undefined)
          }
          onSelectTime={setSelectedTime}
          disabledDate={isDateDisabled}
          disabledTime={(slot) => {
            if (isAdmin) return false;
            return isSlotDisabled(slot || "");
          }}
          slots={availabilityInstance.getTimeslots(selectedDate || null)}
        />
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title={modalTitle}
      onOk={onCreateShipment}
      width={600}
      confirmLoading={shipmentDetails.isLoading}
    >
      <Spin
        tip="Fetching availability..."
        spinning={shipmentAvailabilities.isLoading}
      >
        {renderDateTimeSelector()}
      </Spin>
    </Modal>
  );
};

export default RecreateShipmentModal;
