import * as actionTypes from "../../../actions/fulfillment/types/wro";

const INITIAL_STATE = {
  loading: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_UPDATE_REQUESTED,
    WRO_UPDATE_SUCCESS,
    WRO_UPDATE_ERRORED,
    WRO_UPDATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_UPDATE_REQUESTED:
      return { ...state, loading: true, error: null };
    case WRO_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case WRO_UPDATE_ERRORED:
      return { ...state, loading: false, error: action.error };
    case WRO_UPDATE_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
