import { useQuery } from "react-query";

import { fetchServiceArea } from "./serviceArea";

export const useServiceArea = () => {
  const { data, isLoading, error } = useQuery(["serviceArea"], () => fetchServiceArea(), {
    retry: 2,
    staleTime: Infinity,
    select: (responses) => {
      let facilityList: string[] = [];

      if (responses) {
        facilityList = responses.reduce((acc: string[], cv) => acc.concat(cv.facilities), []);
      }

      return {
        facilityList,
        responses,
      };
    },
  });

  const serviceArea = {
    facilityList: data?.facilityList || [],
    data: data?.responses || [],
    loading: isLoading,
    error: error,
  };

  return { serviceArea };
};
