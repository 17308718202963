import React, { FC, useEffect, useState } from "react";

// Components
import { Table, Input, InputNumber, Button } from "antd";
import { InputContainer } from "./styles";

// Libs
import { get } from "lodash-es";
import {
  Order,
  OrderItem,
  ShipmentItem,
} from "@secondcloset/types/src/fulfillment";

interface DataSource extends ShipmentItem {
  quantity: number;
  name: string;
}

interface Package {
  trackingNumber?: string;
  [name: string]: any;
}

interface Props {
  order: Order;
  packages: Package[];
  handleSetPackages: (newPackages: Props["packages"]) => void;
}

const CreatePackagesTable: FC<Props> = ({
  order,
  packages,
  handleSetPackages,
}) => {
  const [tableData, setTableData] = useState<
    (
      | DataSource
      | {
          name: string;
          itemIDs: string[];
        }
    )[]
  >([]);

  const isInventoryPickupItem = (item: OrderItem) => {
    const availableActions = get(item, "available_actions");
    return availableActions.includes("inventory_pick_up");
  };

  const groupItemsByInventoryPickupAndName = (items: OrderItem[]) => {
    return items.reduce((acc, item) => {
      if (!isInventoryPickupItem(item)) return acc;
      const name = item?.product?.name;
      if (!acc[name]) acc[name] = { name, itemIDs: [] };
      acc[name].itemIDs.push(item.id);
      return acc;
    }, {} as { [key: string]: { name: string; itemIDs: string[] } });
  };

  const setDefaultPackage = (
    dataSource: {
      name: string;
      itemIDs: string[];
    }[]
  ) => {
    const newPackage = {
      key: 0,
    } as any;
    const firstItemName = dataSource[0].name;
    newPackage[firstItemName] = dataSource[0].itemIDs;
    handleSetPackages([newPackage]);
  };

  useEffect(() => {
    const groupedItems = groupItemsByInventoryPickupAndName(order.items);
    const dataSource = Object.values(groupedItems);
    setTableData(dataSource);
    setDefaultPackage(dataSource);
    // eslint-disable-next-line
  }, []);

  const renderItemInputBox = (record: unknown, key: number) => {
    const name = get(record, "name");
    const itemIDs = get(record, "itemIDs");
    const currentPackage = packages[key] || {};

    const allSelectedItemIDs = packages.reduce((acc: string[], pkg) => {
      const keys = Object.keys(pkg);
      const packageItemIDs = keys.reduce((acc, key) => {
        if (key === "key" || key === "trackingNumber") return acc;
        return [...acc, ...pkg[key]];
      }, [] as string[]);
      return [...acc, ...packageItemIDs];
    }, []);

    const availableItemIDs = itemIDs.filter(
      (itemID: string) =>
        currentPackage[name]?.includes(itemID) ||
        !allSelectedItemIDs.includes(itemID)
    );

    const onChange = (newQuantity: number) => {
      const newSelectedIDs = availableItemIDs.slice(0, newQuantity);
      const newPackages = [...packages];
      newPackages[key][name] = newSelectedIDs;
      handleSetPackages(newPackages);
    };

    return (
      <InputNumber
        value={currentPackage[name]?.length || 0}
        onChange={(value?: string | number) => onChange(Number(value || 0))}
        max={availableItemIDs.length}
      />
    );
  };

  const handleAdd = () => {
    const newPackage = {
      key: packages.length,
    };

    handleSetPackages([...packages, newPackage]);
  };

  const handleRemove = (key: number) => {
    const newPackages = packages
      .filter((pkg) => pkg.key !== key)
      .map((pkg, index) => {
        return {
          ...pkg,
          key: index,
        };
      });
    handleSetPackages(newPackages);
  };

  const onTrackingNumberChange = (
    trackingNumber: string,
    packageKey: number
  ) => {
    const newPackages: Package[] = [...packages];
    newPackages[packageKey]["trackingNumber"] = trackingNumber;
    handleSetPackages(newPackages);
  };

  const expandedRowRender = (parentRecord: Props["packages"][0]) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Quantity in Package",
        dataIndex: "itemIDs",
        key: "quantity",
        render: (_: string, record: unknown) =>
          renderItemInputBox(record, parentRecord.key),
      },
      {
        title: "Total Quantity",
        dataIndex: ["itemIDs", "length"],
        key: "total_quantity",
      },
      {
        title: "Remove Package",
        dataIndex: "remove",
        key: "remove",
      },
    ];

    return (
      <div>
        <Table
          dataSource={tableData}
          columns={columns}
          size="small"
          pagination={false}
          rowKey={(r) => r.name}
        />
        <InputContainer>
          <Input
            placeholder="Add a Tracking Number"
            onChange={(e) => {
              e.stopPropagation();
              onTrackingNumberChange(e.target.value, parentRecord.key);
            }}
          />
        </InputContainer>
      </div>
    );
  };

  const onExpand = (expanded: boolean, record: Package) => {
    if (!expanded) handleRemove(record.key);
  };

  const columns = [
    {
      title: "Package",
      dataIndex: "key",
      key: "key",
      render: (key: number) => key + 1,
    },
  ];

  return (
    <div className="item-table">
      <Button onClick={handleAdd} type="primary" style={{ margin: "0 0 8px" }}>
        Add a Package
      </Button>
      <Table
        dataSource={packages}
        columns={columns}
        size="small"
        pagination={false}
        rowKey={(r) => r.key}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
          expandedRowKeys: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          onExpand,
        }}
      />
    </div>
  );
};

export default CreatePackagesTable;
