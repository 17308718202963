import React from "react";
import PropTypes from "prop-types";

const InputPassword = ({
  modClass,
  placeholder,
  value,
  onChange,
  onKeyPress,
}) => (
  <input
    type="password"
    className={modClass ? "form-control " + modClass : "form-control"}
    placeholder={placeholder}
    onChange={onChange}
    onKeyPress={onKeyPress}
    value={value}
  />
);

InputPassword.propTypes = {
  modClass: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default InputPassword;
