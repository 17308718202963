import React, { useEffect, useState } from "react";

// Components
import { Button, DatePicker, Select, Space, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import { SearchInput, WROsTable } from "@secondcloset/web-components";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";

// Hooks
import { useQuery } from "react-query";
import useUrlState from "hooks/application/useUrlState";
import { useOrganizationContext } from "../../../contextProviders/organization/OrganizationProvider";

// Libs
import { fetchWROIndex, WROQueryOption } from "../../../api/fulfillment/wro";

// Styles
import * as S from "../FulfillmentASNIndexPage/styles";

const PAGE_SIZE = 15;

const STATUS_FILTER_OPTIONS = [
  { value: "all", label: "All Status" },
  { value: "awaiting", label: "Awaiting" },
  { value: "processing", label: "Processing" },
  { value: "completed", label: "Completed" },
  { value: "on_hold", label: "On Hold" },
];

const FulfillmentWROIndexPage: React.FC = () => {
  const { data: orgDetails } = useOrganizationContext();
  const history = useHistory();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchWRO, setSearchWRO] = useUrlState({
    name: "search",
    initialValue: "",
  });
  const [searchSKU, setSearchSKU] = useUrlState({
    name: "sku",
    initialValue: "",
  });
  const [searchOrgName, setSearchOrgName] = useUrlState({
    name: "org",
    initialValue: "",
  });
  const [page, setPage] = useUrlState({ name: "page", initialValue: 1 });
  const [arrivalDate, setArrivalDate] = useUrlState({
    name: "arrivalDate",
    initialValue: "",
  });
  const [status, setStatus] = useUrlState({
    name: "status",
    initialValue: "all",
  });

  const queryOptions: WROQueryOption = {
    per_page: PAGE_SIZE,
    page: page ?? 1,
    ...(orgDetails && { organization_id: orgDetails.id }),
    ...(searchWRO && { q: String(searchWRO).trim() }),
    ...(searchSKU && { sku: String(searchSKU).trim() }),
    ...(searchOrgName && { organization_name: String(searchOrgName).trim() }),
    ...(status && status !== "all" && { status }),
    ...(arrivalDate && { arrival_date: arrivalDate }),
  };

  const WROIndex = useQuery(
    ["WROIndex", queryOptions],
    () => fetchWROIndex(queryOptions),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setPage(1);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [searchWRO, searchSKU, searchOrgName, status, arrivalDate]);

  const renderHeaderSection = () => (
    <Space size="large" align="center">
      <Typography.Title level={3}>Warehouse Receiving Orders</Typography.Title>
      <Link
        to={
          orgDetails
            ? `/organizations/${orgDetails.id}/asns`
            : `/fulfillment/asns`
        }
      >
        Switch to ASN
      </Link>
    </Space>
  );

  const renderMainSearchSection = () => (
    <S.FilterWrapper>
      <SearchInput
        className="search-input"
        placeholder="Search for WRO"
        defaultValue={searchWRO}
        onSearch={setSearchWRO}
        onClear={() => setSearchWRO("")}
      />
      {!orgDetails && (
        <SearchInput
          className="search-input"
          placeholder="Search for Organization"
          defaultValue={searchOrgName}
          onSearch={setSearchOrgName}
          onClear={() => setSearchOrgName("")}
        />
      )}
      <Button
        icon={<FilterOutlined />}
        onClick={() => setIsFilterVisible((state) => !state)}
        style={{ marginLeft: "auto" }}
        type="text"
      >
        {isFilterVisible ? "Hide" : "Show"} Filter
      </Button>
    </S.FilterWrapper>
  );

  const renderFilterSection = () => (
    <S.FilterWrapper>
      <SearchInput
        placeholder="Search for SKU"
        defaultValue={searchSKU}
        onSearch={setSearchSKU}
        onClear={() => setSearchSKU("")}
        style={{ width: 200 }}
      />
      <Select value={status} onChange={setStatus} style={{ width: "200px" }}>
        {STATUS_FILTER_OPTIONS.map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
      <DatePicker
        placeholder="Arrival Date"
        onChange={(date: any, dateString: string) => setArrivalDate(dateString)}
        style={{ width: "200px" }}
      />
    </S.FilterWrapper>
  );

  return (
    <S.Container>
      <Space direction="vertical" style={{ width: "100%" }}>
        {orgDetails && (
          <Button
            className="back-btn"
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push(`/organizations/${orgDetails.id}`)}
          >
            {orgDetails.name}
          </Button>
        )}
        {renderHeaderSection()}
        {renderMainSearchSection()}
        {isFilterVisible && renderFilterSection()}
      </Space>
      <WROsTable
        page={page}
        onPageChange={setPage}
        pageSize={PAGE_SIZE}
        showOrganizationColumn={!orgDetails?.id}
        wros={WROIndex.data ?? []}
        isLoading={WROIndex.isPreviousData || WROIndex.isLoading}
        renderWroLinkComponent={(displayText: string, wroID: string) => (
          <Link to={`/fulfillment/wros/${wroID}`}>{displayText}</Link>
        )}
        renderOrgLinkComponent={(displayText: string, id: string) => (
          <Link to={`/organizations/${id}`}>{displayText}</Link>
        )}
      />
    </S.Container>
  );
};

export default FulfillmentWROIndexPage;
