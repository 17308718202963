import { getAPIFromDomain } from "../../lib/api";
import { Fulfillment, Logistics } from "@secondcloset/types";

export const fetchShipmentAvailabilities = async (
  body: Record<string, unknown>
): Promise<Logistics.Availability[]> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments/availabilities`;
  const response = await api.post(url, body);
  return response.data;
};

export const createShipment = async (
  body: Record<string, unknown>
): Promise<Fulfillment.Shipment> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments`;
  const response = await api.post(url, body);
  return response.data;
};

interface FetchShipmentRatesBody {
  order_item_ids: string[];
  job_type: string;
  include_default_carriers: boolean;
  provider_scope?: string[];
  packages?: unknown[];
  ship_to_address_id?: string;
}

export const fetchShipmentRates = async ({
  orderID,
  body,
}: {
  orderID: string;
  body: FetchShipmentRatesBody;
}): Promise<{
  errors: string[];
  rates: Logistics.ShippingRate[];
}> => {
  const api = getAPIFromDomain("fulfillment");
  const url = `/orders/${orderID}/shipping_rates`;
  const response = await api.post(url, body);
  return response.data;
};

export const updateShipmentMethod = async (
  options: Record<string, unknown>
) => {
  const { shipmentID, body } = options;
  const api = getAPIFromDomain("fulfillment");
  const url = `/shipments/${shipmentID}/update_shipping_method`;
  const response = await api.put(url, body);
  return response.data;
};
