import styled from "styled-components";
import COLORS from "../../css/colors";
import { Button, Menu } from "antd";
const Item = Menu.Item;

export const DropdownTriggerButton = styled(Button)`
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItemWrapper = styled(Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 80px;
`;

interface MenuItemIconWrapperProps {
  active?: boolean;
}

export const MenuItemIconWrapper = styled.div<MenuItemIconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${(props) => (props.active ? COLORS.YELLOW : COLORS.GREY_LIGHT)};
  background-color: ${(props) => (props.active ? COLORS.YELLOW : null)};
  border-radius: 2px;
`;

export const MenuItemIcon = styled.img`
  width: 12px;
  height: 12px;
`;
