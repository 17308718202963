import React from "react";
import PropTypes from "prop-types";
import { PictureOutlined } from "@ant-design/icons";

const ImageBox = (props) => {
  const { imageUrl, name } = props;
  const imageSource = imageUrl ? (
    <img src={imageUrl} alt={imageUrl} />
  ) : (
    <PictureOutlined className="default-icon" />
  );

  return (
    <div id="image-box">
      <div className="image-square">{imageSource}</div>
      <div className="description">{name || "-"}</div>
    </div>
  );
};

ImageBox.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
};

export default ImageBox;
