import * as actionTypes from "actions/fulfillment/types";
import { STEPS } from "components/fulfillment/inventory/warehouseReceivingOrders/wroConfig";

const getInitialAvailableSteps = () => {
  const initialSteps = Object.keys(STEPS).reduce((acc, step) => {
    acc[step] = false;
    return acc;
  }, {});

  initialSteps.selectLocation = true;
  return initialSteps;
};

const INITIAL_STATE = {
  activeStep: STEPS.selectLocation.key,
  availableSteps: getInitialAvailableSteps(),
};

export default function (state = INITIAL_STATE, action) {
  const {
    WRO_STEP_SELECT_LOCATION_COMPLETED,
    WRO_STEP_SELECT_ADDRESS_COMPLETED,
    WRO_STEP_ADD_PRODUCTS_COMPLETED,
    WRO_STEP_SHIPMENT_DETAILS_COMPLETED,
    WRO_STEP_PACKING_DETAILS_COMPLETED,
    WRO_STEP_PACKING_SLIPS_COMPLETED,

    WRO_STEP_ADD_PRODUCTS_MODIFIED,
    WRO_STEP_SHIPMENT_DETAILS_MODIFIED,
    WRO_STEP_PACKING_DETAILS_MODIFIED,
    WRO_STEP_PACKING_SLIPS_MODIFIED,

    WRO_STEP_SELECT_LOCATION_SELECTED,
    WRO_STEP_SELECT_ADDRESS_SELECTED,
    WRO_STEP_ADD_PRODUCTS_SELECTED,
    WRO_STEP_SHIPMENT_DETAILS_SELECTED,
    WRO_STEP_PACKING_DETAILS_SELECTED,
    WRO_STEP_PACKING_SLIPS_SELECTED,
    WRO_STEP_ORDER_REVIEW_SELECTED,

    WRO_CREATE_RESET,
  } = actionTypes;

  switch (action.type) {
    case WRO_STEP_SELECT_LOCATION_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.addProducts.key] = true;

      return { ...state, activeStep: STEPS.addProducts.key, availableSteps };
    }

    case WRO_STEP_SELECT_ADDRESS_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.addProducts.key] = true;

      return { ...state, activeStep: STEPS.addProducts.key, availableSteps };
    }

    case WRO_STEP_ADD_PRODUCTS_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.shipmentDetails.key] = true;

      return {
        ...state,
        activeStep: STEPS.shipmentDetails.key,
        availableSteps,
      };
    }

    case WRO_STEP_SHIPMENT_DETAILS_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.packingDetails.key] = true;

      return { ...state, activeStep: STEPS.packingDetails.key, availableSteps };
    }

    case WRO_STEP_PACKING_DETAILS_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.packingSlips.key] = true;

      return { ...state, activeStep: STEPS.packingSlips.key, availableSteps };
    }

    case WRO_STEP_PACKING_SLIPS_COMPLETED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.orderReview.key] = true;

      return { ...state, activeStep: STEPS.orderReview.key, availableSteps };
    }

    case WRO_STEP_ADD_PRODUCTS_MODIFIED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.shipmentDetails.key] = false;
      availableSteps[STEPS.packingDetails.key] = false;
      availableSteps[STEPS.packingSlips.key] = false;
      availableSteps[STEPS.orderReview.key] = false;

      return { ...state, availableSteps };
    }

    case WRO_STEP_SHIPMENT_DETAILS_MODIFIED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.packingDetails.key] = false;
      availableSteps[STEPS.packingSlips.key] = false;
      availableSteps[STEPS.orderReview.key] = false;

      return { ...state, availableSteps };
    }

    case WRO_STEP_PACKING_DETAILS_MODIFIED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.packingSlips.key] = false;
      availableSteps[STEPS.orderReview.key] = false;

      return { ...state, availableSteps };
    }

    case WRO_STEP_PACKING_SLIPS_MODIFIED: {
      const availableSteps = { ...state.availableSteps };
      availableSteps[STEPS.orderReview.key] = false;

      return { ...state, availableSteps };
    }

    case WRO_STEP_SELECT_LOCATION_SELECTED: {
      return { ...state, activeStep: STEPS.selectLocation.key };
    }

    case WRO_STEP_SELECT_ADDRESS_SELECTED: {
      return { ...state, activeStep: STEPS.selectAddress.key };
    }

    case WRO_STEP_ADD_PRODUCTS_SELECTED: {
      return { ...state, activeStep: STEPS.addProducts.key };
    }

    case WRO_STEP_SHIPMENT_DETAILS_SELECTED: {
      return { ...state, activeStep: STEPS.shipmentDetails.key };
    }

    case WRO_STEP_PACKING_DETAILS_SELECTED: {
      return { ...state, activeStep: STEPS.packingDetails.key };
    }

    case WRO_STEP_PACKING_SLIPS_SELECTED: {
      return { ...state, activeStep: STEPS.packingSlips.key };
    }

    case WRO_STEP_ORDER_REVIEW_SELECTED: {
      return { ...state, activeStep: STEPS.orderReview.key };
    }

    case WRO_CREATE_RESET:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
