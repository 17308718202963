import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const ITEMS_PER_BATCH_REQUEST = 50;

const useInventoryDetails = () => {
  const [inventoryDetails, setInventoryDetails] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();
  const getURL = (userID, inventoryID) =>
    `/users/${userID}/inventory_items/${inventoryID}`;

  const fetchInventoryDetails = (userID, inventoryID) => {
    setInventoryDetails({ ...inventoryDetails, loading: true, error: null });
    const url = getURL(userID, inventoryID);
    return api
      .get(url)
      .then((res) => {
        const data = res.data.inventory_item;
        setInventoryDetails({ data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setInventoryDetails({ ...inventoryDetails, loading: false, error });
        return false;
      });
  };

  const updateInventoryDetails = (userID, inventoryID, body) => {
    setInventoryDetails({ ...inventoryDetails, loading: true, error: null });
    const url = getURL(userID, inventoryID);
    return api
      .put(url, body)
      .then((res) => {
        const data = res.data.inventory_item;
        setInventoryDetails({ data, loading: false, error: null });
        return true;
      })
      .catch((error) => {
        setInventoryDetails({ ...inventoryDetails, loading: false, error });
        return false;
      });
  };

  return { inventoryDetails, fetchInventoryDetails, updateInventoryDetails };
};

const useInventoryIndex = () => {
  const [inventoryIndex, setInventoryIndex] = useState({
    loading: true,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain();
  const getBaseURL = (userID) => `/users/${userID}/inventory_items`;

  const fetchInventoryIndex = async (userID) => {
    setInventoryIndex((inventory) => ({ ...inventory, loading: true }));

    const fetchInventoryByPage = (page) => {
      const baseURL = getBaseURL(userID);
      const options = { page, per_page: ITEMS_PER_BATCH_REQUEST };
      const url = new UrlBuilder(baseURL).applyQueryStringFromObject(options);
      return api.get(url).then((res) => {
        return res.data.inventory_items;
      });
    };

    const getInventoryBatch = async (inventoryItems, page) => {
      try {
        const newBatch = await fetchInventoryByPage(page);
        const batchedInventory = [...newBatch, ...inventoryItems];
        setInventoryIndex((inventory) => ({
          ...inventory,
          data: batchedInventory,
        }));
        if (!newBatch.length || newBatch.length < ITEMS_PER_BATCH_REQUEST) {
          setInventoryIndex((inventory) => ({ ...inventory, loading: false }));
          return inventoryIndex;
        } else getInventoryBatch(batchedInventory, page + 1);
      } catch (error) {
        setInventoryIndex((inventory) => ({
          ...inventory,
          loading: false,
          error,
        }));
      }
    };

    return await getInventoryBatch([], 1);
  };

  const batchUpdateInventoryItems = (userID, body) => {
    setInventoryIndex({ ...inventoryIndex, loading: true, error: null });
    const url = `/users/${userID}/inventory_items/batch_update`;
    return api
      .put(url, { inventory_items: body })
      .then(() => fetchInventoryIndex(userID))
      .catch((error) => {
        setInventoryIndex({ ...inventoryIndex, loading: false, error });
        return false;
      });
  };

  return { inventoryIndex, fetchInventoryIndex, batchUpdateInventoryItems };
};

export { useInventoryDetails, useInventoryIndex };
