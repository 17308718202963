import cookie from "react-cookies";
export const userTokenCookieName = "sierra/user_token";
export const userInfoCookieName = "sierra/user_information";

export default class Cookies {
  static setUserToken(token) {
    const days = 1;
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * days);
    return cookie.save(userTokenCookieName, token, { path: "/", expires });
  }

  static getUserToken() {
    return cookie.load(userTokenCookieName);
  }

  static deleteUserToken() {
    return cookie.remove(userTokenCookieName, { path: "/" });
  }

  static setUserInfo(info) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 2);
    return cookie.save(userInfoCookieName, info, { path: "/", expires });
  }

  static getUserInfo() {
    return cookie.load(userInfoCookieName);
  }

  static deleteUserInfo() {
    return cookie.remove(userInfoCookieName, { path: "/" });
  }

  static isUserAuthenticated() {
    return !!this.getUserToken() && !!this.getUserInfo();
  }

  static deleteAll() {
    this.deleteUserToken();
    this.deleteUserInfo();
  }
}
