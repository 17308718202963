import React from "react";

// components
import { Modal, Button } from "antd";
import { ImageCarousel } from "@secondcloset/web-components";

// styles
import * as S from "./styles";

// types
import { Fulfillment } from "@secondcloset/types";
type ShipmentType = Fulfillment.Shipment;
type ShippingMethod = Fulfillment.SecondClosetShippingMethod;

interface Props {
  shipment: ShipmentType;
  verificationImageIndex: number;
  visible: boolean;
  setVisible: (visible: boolean) => unknown;
  setVerificationImageIndex: (index: number) => unknown;
}

const ProofOfDeliveryModal: React.FC<Props> = ({
  shipment,
  verificationImageIndex,
  visible,
  setVisible,
  setVerificationImageIndex,
}) => {
  const renderImages = () => {
    const shippingMethod = shipment?.shipping_method as ShippingMethod;
    const verificationImagesUrls = shippingMethod?.verification_images?.map(
      (image) => image.url
    );
    const hasVerificationImages = !!verificationImagesUrls?.length;
    if (!hasVerificationImages)
      return <S.EmptyImageContainer>No Images</S.EmptyImageContainer>;
    return (
      <S.ImageContainer>
        <ImageCarousel
          imageUrls={verificationImagesUrls ?? []}
          slidesToShow={1}
          currentSlide={verificationImageIndex}
          setCurrentSlide={setVerificationImageIndex}
          height={350}
          width={350}
        />
      </S.ImageContainer>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title="Proof of Delivery"
      footer={[
        <Button key="done" onClick={() => setVisible(false)} type="primary">
          OK
        </Button>,
      ]}
    >
      {renderImages()}
    </Modal>
  );
};

export default ProofOfDeliveryModal;
