import { useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import UrlBuilder from "../../../lib/UrlBuilder";

const useProductIndex = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const api = getAPIFromDomain();
  const baseUrl = `/products`;

  const getUrlWithQuery = (query) => {
    if (!query || !query.service_area) return baseUrl;
    const serviceArea = query.service_area;
    const urlBuilder = new UrlBuilder(baseUrl);
    return urlBuilder.applyServiceArea(serviceArea);
  };

  const fetchProductIndex = (query) => {
    const url = getUrlWithQuery(query);

    setError(null);
    setLoading(true);
    return api
      .get(url)
      .then((res) => {
        const products = res.data;
        setData(products);
        setLoading(false);
        return products;
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        return null;
      });
  };

  const productIndex = { data, loading, error };
  return { productIndex, fetchProductIndex };
};

export { useProductIndex };
