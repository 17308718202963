import { combineReducers } from "redux";

import assignQrModal from "./appointment/assignQrModal";
import editNameModal from "./appointment/editNameModal";

const regular = combineReducers({
  assignQrModal,
  editNameModal,
});

export default regular;
