export default class ShipmentCreate {
  static constructOrderItems = (
    selectedOrderItemIDs,
    {
      isFullRefund,
      isExchange,
      isUnopenedCancellation,
      isReturn,
      returnReasons,
    }
  ) => {
    const constructedOrderItems = selectedOrderItemIDs.map((id) => {
      const item = { id };
      if (isReturn) {
        const attributes = [];
        if (isFullRefund) attributes.push("Return");
        if (isExchange) attributes.push("Exchange");
        if (isUnopenedCancellation) attributes.push("Cancellation");
        item.attributes = attributes;
        const returnReason = returnReasons.find((r) => r.itemID === id);
        if (returnReason) item.return_reasons = [returnReason.reason];
      }
      return item;
    }, []);
    return constructedOrderItems;
  };

  static getCreateShipmentBody = (
    fields,
    { isReturn = false, isPickupPartner, isSecondCloset, isFreight }
  ) => {
    const {
      orderID,
      date,
      time,
      rate,
      serviceCode,
      orderItems,
      partnerID,
      notes,
      useOriginalPackage,
      packageDetails,
    } = fields;

    const body = {
      freight: isFreight,
      order_id: orderID,
      use_original_package: useOriginalPackage,
      job_type: isReturn ? "reverse_logistics" : "delivery",
      order_items: orderItems,
    };

    if (rate) {
      body.rate = {
        rate_id: rate.rateID,
        external_platform: rate.externalPlatform,
        shipment_id: rate.shipmentID,
      };
    } else if (isPickupPartner) {
      body.untracked = true;
      body.carrier_id = partnerID;
    } else if (isSecondCloset) {
      body.date = date;
      body.time_range = time;
      body.service_code = serviceCode;
    }
    if (notes) {
      body.notes_attributes = [{ text: notes }];
    }
    if (packageDetails?.length) body.packages = packageDetails;
    return body;
  };
}
