import { ReactText, useState } from "react";
import { getAPIFromDomain } from "../../../lib/api";
import {
  Product,
  ProductActivityHistory,
  PackagingLevel,
} from "../../../interfaces/products";
import { Product as CreateProductBase } from "../../../interfaces/products";
import queryString from "query-string";
import { Common } from "@secondcloset/types";

interface CreateProduct extends CreateProductBase {
  organization_id: string;
  images_attributes: { file: File }[];
  packaging_levels_attributes?: PackagingLevel[];
}

export type CreateProductBody = {
  product: CreateProduct;
};

const useProducts = () => {
  const [products, setProducts] = useState<{
    loading: boolean;
    data: Product[] | null;
    error: string | null;
  }>({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const fetchProducts = (query: {
    product_type?: string;
    q?: string;
    organization_id?: string;
    per_page?: number;
  }): Promise<Product[]> => {
    const url = `/products?${queryString.stringify(query)}`;

    setProducts({ ...products, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setProducts({ data: res.data, loading: false, error: null });
        return res.data;
      })
      .catch((error) => {
        setProducts({ ...products, loading: false, error });
      });
  };

  const clearProducts = () => {
    setProducts({
      loading: false,
      data: null,
      error: null,
    });
  };

  const setLoading = (loading: boolean) =>
    setProducts({ ...products, loading });

  return { products, fetchProducts, clearProducts, setLoading };
};

const useProductDetails = () => {
  const [productDetails, setProductDetails] = useState<{
    loading: boolean;
    data: Product | null;
    error: string | null;
  }>({
    loading: false,
    data: null,
    error: null,
  });

  const api = getAPIFromDomain("fulfillment");

  const fetchProduct = (productID: string): Promise<Product> => {
    const url = `/products/${productID}`;

    setProductDetails({ ...productDetails, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        setProductDetails({ data: res.data, loading: false, error: null });
        return res.data;
      })
      .catch((error) => {
        setProductDetails({ ...productDetails, loading: false, error });
      });
  };

  const updateProduct = async (
    productID: string,
    body: CreateProductBase | FormData | { product: CreateProductBase }
  ) => {
    const url = `/products/${productID}`;
    setProductDetails({ ...productDetails, loading: true, error: null });
    try {
      const res = await api.put(url, body);
      setProductDetails({ data: res.data, loading: false, error: null });
      return res.data;
    } catch (error) {
      setProductDetails({ ...productDetails, loading: false, error });
    }
  };

  const createProduct = (body: CreateProductBody | FormData) => {
    const url = `/products`;
    setProductDetails({ ...productDetails, loading: true, error: null });

    return api
      .post(url, body)
      .then((res) => {
        const product = res.data;
        setProductDetails({ data: product, loading: false, error: null });
        return product;
      })
      .catch((error) => {
        setProductDetails({ ...productDetails, loading: false, error });
      });
  };

  const productLookup = (query: {
    sku?: string;
    product_type: "base" | "kit";
  }) => {
    const url = `/products?${queryString.stringify(query)}`;

    setProductDetails({ ...productDetails, loading: true, error: null });
    return api
      .get(url)
      .then((res) => {
        const product = res.data?.[0];
        setProductDetails({ data: res.data, loading: false, error: null });
        return product;
      })
      .catch((error) => {
        setProductDetails({ ...productDetails, loading: false, error });
        return false;
      });
  };

  return {
    productDetails,
    fetchProduct,
    updateProduct,
    createProduct,
    productLookup,
  };
};

const useProductIndex = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const fetchProductIndex = (query: {
    per_page: number;
    q?: string;
    page?: number;
    shipment_tags?: {
      values: string[];
      condition: string;
    };
    cancelled?: boolean;
    field?: string | number | ReactText[];
    direction?: string;
    organization_id?: string;
    active?: boolean;
  }) => {
    const url = `/products?${queryString.stringify(query)}`;

    setLoading(true);
    setError(null);
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const productIndex = { data, loading, error };

  return { productIndex, fetchProductIndex };
};

const useProductActivityHistory = () => {
  const [data, setData] = useState<ProductActivityHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const api = getAPIFromDomain("fulfillment");

  const fetchProductActivityHistory = (productID: string) => {
    const url = `/products/${productID}/activity_history?page=1&&per_page=1000`;

    setLoading(true);
    setError(null);

    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const productActivityHistory = { data, loading, error };

  return { productActivityHistory, fetchProductActivityHistory };
};

const useProductIncomingQuantity = () => {
  const [data, setData] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchProductIncomingQuantity = async (
    productID: string,
    facility?: Common.FacilityCode
  ): Promise<number> => {
    const api = getAPIFromDomain("fulfillment");
    const url = `/products/${productID}/incoming_quantity${
      facility ? `?facility=${facility}` : ""
    }`;
    return api
      .get(url)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        return res.data?.incoming_quantity;
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  const productIncomingQuantity = { data, loading, error };
  return { productIncomingQuantity, fetchProductIncomingQuantity };
};

export {
  useProducts,
  useProductDetails,
  useProductIndex,
  useProductActivityHistory,
  useProductIncomingQuantity,
};
