//PRODUCTS
export const FULFILLMENT_PRODUCTS_REQUESTED = "FULFILLMENT_PRODUCTS_REQUESTED";
export const FULFILLMENT_PRODUCTS_RECEIVED = "FULFILLMENT_PRODUCTS_RECEIVED";
export const FULFILLMENT_PRODUCTS_ERRORED = "FULFILLMENT_PRODUCTS_ERRORED";
export const FULFILLMENT_PRODUCTS_CLEAR_DATA =
  "FULFILLMENT_PRODUCTS_CLEAR_DATA";

export const FULFILLMENT_PRODUCT_DETAILS_REQUESTED =
  "FULFILLMENT_PRODUCT_DETAILS_REQUESTED";
export const FULFILLMENT_PRODUCT_DETAILS_RECEIVED =
  "FULFILLMENT_PRODUCT_DETAILS_RECEIVED";
export const FULFILLMENT_PRODUCT_DETAILS_ERRORED =
  "FULFILLMENT_PRODUCT_DETAILS_ERRORED";
export const FULFILLMENT_PRODUCT_DETAILS_RESET =
  "FULFILLMENT_PRODUCT_DETAILS_RESET";

export const FULFILLMENT_PRODUCT_PUT_REQUESTED =
  "FULFILLMENT_PRODUCT_PUT_REQUESTED";
export const FULFILLMENT_PRODUCT_PUT_SUCCESS =
  "FULFILLMENT_PRODUCT_PUT_SUCCESS";
export const FULFILLMENT_PRODUCT_PUT_ERRORED =
  "FULFILLMENT_PRODUCT_PUT_ERRORED";
export const FULFILLMENT_PRODUCT_PUT_RESET = "FULFILLMENT_PRODUCT_PUT_RESET";

export const FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_REQUESTED =
  "FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_REQUESTED";
export const FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_RECEIVED =
  "FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_RECEIVED";
export const FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_ERRORED =
  "FULFILLMENT_PRODUCT_ACTIVITY_HISTORY_ERRORED";

export const FULFILLMENT_PRODUCT_POST_REQUESTED =
  "FULFILLMENT_PRODUCT_POST_REQUESTED";
export const FULFILLMENT_PRODUCT_POST_SUCCESS =
  "FULFILLMENT_PRODUCT_POST_SUCCESS";
export const FULFILLMENT_PRODUCT_POST_ERRORED =
  "FULFILLMENT_PRODUCT_POST_ERRORED";

export const FULFILLMENT_PRODUCT_DELETE_REQUESTED =
  "FULFILLMENT_PRODUCT_DELETE_REQUESTED";
export const FULFILLMENT_PRODUCT_DELETE_SUCCESS =
  "FULFILLMENT_PRODUCT_DELETE_SUCCESS";
export const FULFILLMENT_PRODUCT_DELETE_ERRORED =
  "FULFILLMENT_PRODUCT_DELETE_ERRORED";
export const FULFILLMENT_PRODUCT_DELETE_RESET =
  "FULFILLMENT_PRODUCT_DELETE_RESET";

export const FULFILLMENT_PRODUCT_GET_CSV_REQUESTED =
  "FULFILLMENT_PRODUCT_GET_CSV_REQUESTED";
export const FULFILLMENT_PRODUCT_GET_CSV_RECEIVED =
  "FULFILLMENT_PRODUCT_GET_CSV_RECEIVED";
export const FULFILLMENT_PRODUCT_GET_CSV_ERRORED =
  "FULFILLMENT_PRODUCT_GET_CSV_ERRORED";
