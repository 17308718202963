import React from "react";
import useShipmentCreateFlow from "../../../../hooks/reduxContainer/fulfillment/shipmentCreateFlow";
import { ShipmentOverview } from "@secondcloset/web-components";
import { Fulfillment } from "@secondcloset/types";
type Product = Fulfillment.Product;

const ShipmentOverviewStep = () => {
  const {
    order,
    selectedOrderItemIDs,
    shipmentCarrier,
    serviceCategory,
    partner,
    isReturn,
    isEndy,
    returnReasons,
    isFullRefund,
    isExchange,
    isUnopenedCancellation,
    notes,
    dispatchSetNotes,
  } = useShipmentCreateFlow();

  const organizationID = order?.organization?.id;

  return (
    <ShipmentOverview
      order={order}
      selectedOrderItemIDs={selectedOrderItemIDs}
      shipmentCarrier={shipmentCarrier}
      serviceCategory={serviceCategory}
      partner={partner}
      isReturn={isReturn}
      isEndy={isEndy}
      returnReasons={returnReasons}
      isFullRefund={isFullRefund}
      isExchange={isExchange}
      isUnopenedCancellation={isUnopenedCancellation}
      notes={notes}
      setNotes={dispatchSetNotes}
      onSkuClick={(product: Product) =>
        window.open(
          `/organizations/${organizationID}/products/${product?.id}`,
          "_blank"
        )
      }
    />
  );
};

export default ShipmentOverviewStep;
