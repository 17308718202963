import React, { useState } from "react";
import { objectToFormData } from "object-to-formdata";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { Spin, Modal, Upload, Alert, Button, Popconfirm } from "antd";
import Cloudinary from "../../../../lib/Cloudinary";
import StandardCard from "../../../StandardCard";

import { useInventoryDetails } from "../../../../hooks/api/regular/inventory";
import useMount from "../../../../hooks/useMount";

const PhotoManager = ({ userID, inventoryID }) => {
  const {
    inventoryDetails,
    fetchInventoryDetails,
    updateInventoryDetails,
  } = useInventoryDetails();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImg, setImg] = useState(null);

  useMount(() => {
    fetchInventoryDetails(userID, inventoryID);
  });

  const buildPhotoBox = (image) => {
    return (
      <div
        className="image-box"
        onClick={() => {
          setImg(image);
          setModalVisible(true);
        }}
        key={image.id}
      >
        <img
          src={Cloudinary.getThumbnailLink(image.url)}
          alt="imageLink"
          className="actual-image"
        />
      </div>
    );
  };

  const onDeleteImg = () => {
    setModalVisible(false);
    updateInventoryDetails(userID, inventoryID, {
      inventory_item: {
        images_attributes: [{ id: selectedImg.id, _destroy: true }],
      },
    });
  };

  const buildModal = () => {
    if (!selectedImg) return null;
    return (
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
        style={{ height: "100%" }}
      >
        <img
          src={selectedImg.url}
          className="modal-image"
          alt={selectedImg.url}
          onClick={() => (window.location.href = selectedImg.url)}
        />
        <div className="delete-button-row">
          <Popconfirm
            title="Are you sure you want to delete this photo?"
            onConfirm={onDeleteImg}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </div>
      </Modal>
    );
  };

  const { data, loading, error } = inventoryDetails;
  if (loading) {
    return (
      <StandardCard>
        <div className="photo-manager">
          <Spin tip="loading images..." />
        </div>
      </StandardCard>
    );
  }

  if (error) {
    return (
      <StandardCard>
        <div className="photo-manager">
          <Alert message={error} type="error" />
        </div>
      </StandardCard>
    );
  }

  const images = data.images;
  const adminImages = images.filter((image) => image.access === "internal");
  const customerImages = images.filter((image) => image.access === "external");

  const onImageUpload = (file, isAdmin) => {
    const payload = {
      inventory_item: {
        images_attributes: [
          { file, access: isAdmin ? "internal" : "external" },
        ],
      },
    };
    const formData = objectToFormData(payload);
    updateInventoryDetails(userID, inventoryID, formData);
  };

  const buildAddImageBubble = (isAdmin) => {
    return (
      <Upload
        customRequest={() => {}}
        showUploadList={false}
        action={(file) => onImageUpload(file, isAdmin)}
      >
        <div className="add-img-bubble">
          <PlusOutlined />
        </div>
      </Upload>
    );
  };

  return (
    <StandardCard>
      <div className="photo-manager">
        <div className="photo-controller">
          <p>Customer Photos</p>
          <div className="bubbles">
            {customerImages.map((image) => buildPhotoBox(image))}
            {buildAddImageBubble()}
          </div>
        </div>

        <div className="photo-controller">
          <p>Admin Photos</p>
          <div className="bubbles">
            {adminImages.map((image) => buildPhotoBox(image))}
            {buildAddImageBubble(true)}
          </div>
        </div>
      </div>
      {buildModal()}
    </StandardCard>
  );
};

PhotoManager.propTypes = {
  userID: PropTypes.string.isRequired,
  inventoryID: PropTypes.string.isRequired,
};

export default PhotoManager;
