import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  Modal,
  Button,
  Dropdown,
  Menu,
  notification,
  Spin,
  Radio,
  Alert,
} from "antd";
import { useInventoryItemDetails } from "../../../hooks/api/storage/inventoryItem";
import useMount from "../../../hooks/useMount";
import PhotoManager from "../../../components/regular/customer/dependencies/PhotoManager";
import InputTextWithColors from "../../../components/Input/InputTextWithColors";
import colorSelection from "../../../config/colors.json";
import { get, startCase } from "lodash-es";

const InventoryItemDetailsPage = (props) => {
  const [newItemStatus, setNewItemStatus] = useState("");
  const [newMaterial, setNewMaterial] = useState("");
  const [newColor, setNewColor] = useState("");
  const [newBrand, setNewBrand] = useState("");
  const [newSize, setNewSize] = useState("");
  const [newNickname, setNewNickname] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newNotes, setNewNotes] = useState("");

  const [modalActive, setModalActive] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [colorError, setColorError] = useState(null);

  const history = useHistory();
  const params = useParams();
  const {
    inventoryItemDetails,
    fetchInventoryItemDetails,
    updateInventoryItemDetails,
  } = useInventoryItemDetails();

  useMount(() => {
    fetchInventoryItemDetails(params.user_id, params.inventory_id);
  });

  useEffect(() => {
    if (inventoryItemDetails.data) {
      const {
        material,
        color,
        brand,
        size,
        nickname,
        description,
        notes,
      } = inventoryItemDetails.data;
      setNewMaterial(material);
      setNewColor(color);
      setNewBrand(brand);
      setNewSize(size);
      setNewNickname(nickname);
      setNewDescription(description);
      setNewNotes(notes);
    }
  }, [inventoryItemDetails.data]);

  useEffect(() => {
    setColorError(null);
  }, [modalActive]);

  useEffect(() => {
    if (inventoryItemDetails.error) {
      notification.error({
        message: "Error",
        description: inventoryItemDetails.error,
      });
    }
  }, [inventoryItemDetails.error]);

  if (!inventoryItemDetails.data) return null;

  const renderSectionHeader = (title, btnRenderFunc) => {
    return (
      <div className="section-header">
        <div className="title">{title}</div>
        {btnRenderFunc()}
      </div>
    );
  };

  const renderInfoWithLabel = (label, info, onclick) => {
    return (
      <div className="info-with-label">
        <div className="label">{label}:</div>
        <div
          className={`info${onclick ? " link" : ""}`}
          onClick={onclick || null}
        >
          {info ? startCase(info) : "-"}
        </div>
      </div>
    );
  };

  const handleStatusMenuClick = (e) => {
    setNewItemStatus(e.key);
    setCurrentModal("status");
    setModalActive(true);
  };

  const renderStatusChangeBtn = () => {
    const statusOptions = [
      "requested",
      "in_storage",
      "being_returned",
      "returned",
    ];

    const itemStatus = get(inventoryItemDetails, "data.status");

    const menu = (
      <Menu onClick={handleStatusMenuClick}>
        {statusOptions.map((option) => (
          <Menu.Item key={option}>{startCase(option)}</Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown trigger={["click"]} overlay={menu}>
        <Button type="primary" ghost>
          {startCase(itemStatus)}
          <CaretDownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const buildIdentifiers = () => {
    const itemDetails = inventoryItemDetails.data;
    const customerName = `${get(itemDetails, "customer.first_name")} ${get(
      itemDetails,
      "customer.last_name"
    )}`;
    const { stripe_plan_id, category_name } = itemDetails;
    const isRegularItem = itemDetails.item_type === "regular_item";
    const itemType = isRegularItem ? stripe_plan_id : category_name;

    return (
      <div className="item-identifiers">
        {renderSectionHeader("IDENTIFIERS", renderStatusChangeBtn)}
        <div className="info-group">
          {renderInfoWithLabel(
            "CUSTOMER NAME",
            customerName,
            goToCustomerInventoryPage
          )}
          {renderInfoWithLabel("QR CODE", itemDetails.qr_code)}
          {renderInfoWithLabel("BARCODE", itemDetails.barcode)}
          {renderInfoWithLabel("LOCATION", itemDetails.location)}
          {renderInfoWithLabel("ITEM TYPE", itemDetails.item_type)}
          {renderInfoWithLabel("TYPE", itemType)}
          {renderInfoWithLabel("STATUS", itemDetails.status)}
        </div>
      </div>
    );
  };

  const renderEditBtn = (section) => {
    const openModal = () => {
      setModalActive(true);
      setCurrentModal(section);
    };

    return (
      <Button type="default" onClick={openModal}>
        Edit
      </Button>
    );
  };

  const buildPhysicalAttributes = () => {
    const itemDetails = inventoryItemDetails.data;

    return (
      <div className="physical-attributes">
        {renderSectionHeader("PHYSICAL ATTRIBUTES", () =>
          renderEditBtn("attributes")
        )}
        <div className="info-group">
          {renderInfoWithLabel("MATERIAL", itemDetails.material)}
          {renderInfoWithLabel("COLOR", itemDetails.color)}
          {renderInfoWithLabel("SIZE", itemDetails.size)}
          {renderInfoWithLabel("BRAND", itemDetails.brand)}
        </div>
      </div>
    );
  };

  const buildOtherInfo = () => {
    const itemDetails = inventoryItemDetails.data;

    return (
      <div className="other-info">
        {renderSectionHeader("OTHER", () => renderEditBtn("other"))}
        <div className="info-group">
          {renderInfoWithLabel("NICKNAME", itemDetails.nickname)}
          {renderInfoWithLabel("DESCRIPTION", itemDetails.description)}
          {renderInfoWithLabel("NOTES", itemDetails.notes)}
        </div>
      </div>
    );
  };

  const renderFormInputSection = (title, defaultValue = "", setValue) => {
    return (
      <div className="form-input-section">
        <label>{title}:</label>
        <input
          className="form-input"
          value={defaultValue}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  };

  const renderSizeSelect = () => {
    const allSizes = ["small", "medium", "large", "extra_large"];
    return (
      <div className="form-input-section">
        <label>Size:</label>
        <Radio.Group
          defaultValue={newSize}
          onChange={(e) => setNewSize(e.target.value)}
        >
          {allSizes.map((size) => {
            return (
              <Radio.Button value={size} key={size}>
                {startCase(size)}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    );
  };

  const renderColorSelect = () => {
    return (
      <div className="form-input-section">
        <label>Color:</label>
        <InputTextWithColors
          value={newColor}
          onChange={(e) => setNewColor(e.target.value)}
        />
      </div>
    );
  };

  const renderInvalidColorMsg = () => {
    return <Alert message={colorError} type="error" banner />;
  };

  const renderEditModal = () => {
    const { user_id, inventory_id } = params;

    const updateItem = () => {
      let newDetails = {};
      switch (currentModal) {
        case "status":
          newDetails = { status: newItemStatus };
          break;
        case "attributes":
          if (
            newColor &&
            !colorSelection.find((color) => color.name === newColor.trim())
          ) {
            setColorError(
              `"${newColor}" is an invalid option. Please select a color from the list.`
            );
            return null;
          }
          setColorError(null);
          newDetails = {
            materialId: newMaterial,
            color: newColor,
            brand: newBrand,
            size: newSize,
          };
          break;
        case "other":
          newDetails = {
            nickname: newNickname,
            description: newDescription,
            notes: newNotes,
          };
          break;
        default:
          console.log("no modal chosen.");
      }
      updateInventoryItemDetails(user_id, inventory_id, newDetails);
      setModalActive(false);
    };

    const renderModalContent = () => {
      switch (currentModal) {
        case "status":
          return (
            <p>
              Are you sure you want to change the status on this item to{" "}
              <strong>{startCase(newItemStatus)}</strong> ?
            </p>
          );
        case "attributes":
          return (
            <div className="item-edit-form">
              {!!colorError && renderInvalidColorMsg()}
              {renderFormInputSection("Material", newMaterial, setNewMaterial)}
              {renderColorSelect()}
              {renderFormInputSection("Brand", newBrand, setNewBrand)}
              {renderSizeSelect()}
            </div>
          );
        case "other":
          return (
            <div className="item-edit-form">
              {renderFormInputSection("Nickname", newNickname, setNewNickname)}
              {renderFormInputSection(
                "Description",
                newDescription,
                setNewDescription
              )}
              {renderFormInputSection("Notes", newNotes, setNewNotes)}
            </div>
          );
        default:
          console.log("no modal chosen.");
      }
    };

    return (
      <Modal
        title="Edit Item"
        visible={modalActive}
        onOk={updateItem}
        confirmLoading={inventoryItemDetails.loading}
        onCancel={() => setModalActive(false)}
      >
        <div className="inventory-edit-item-modal-wrap">
          {renderModalContent()}
        </div>
      </Modal>
    );
  };

  const goToCustomerInventoryPage = () => {
    const { user_id } = params;
    history.push(`/customers/${user_id}/inventory`);
  };

  if (inventoryItemDetails.loading) {
    return (
      <div id="inventory-items-details-page">
        <div className="back-btn" onClick={goToCustomerInventoryPage}>
          {"< Go Back"}
        </div>
        <div className="details-card">
          <div className="page-title">Inventory Item Details</div>
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div id="inventory-items-details-page">
      <div className="back-btn" onClick={goToCustomerInventoryPage}>
        {"< Go Back"}
      </div>
      <div className="basic-info details-card">
        <div className="page-title">Inventory Item Details</div>
        {buildIdentifiers()}
        {buildPhysicalAttributes()}
        {buildOtherInfo()}
        {renderEditModal()}
      </div>
      <PhotoManager userID={params.user_id} inventoryID={params.inventory_id} />
    </div>
  );
};

export default InventoryItemDetailsPage;
