import styled from "styled-components";
import { Button } from "antd";

export const DownloadOtifWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DownloadOtifButton = styled(Button)`
  margin-left: 16px;
`;

export const ModalContainer = styled.div`
  min-height: 80px;
  padding: 20px 0;
  display: flex;
  align-items: center;
`;

export const TagText = styled.div`
  font-weight: 400;
`;
