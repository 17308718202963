import React from "react";
import PropTypes from "prop-types";
import { withRouter, Prompt } from "react-router-dom";
import { Spin } from "antd";
import { notification } from "antd";
import "antd/lib/notification/style";

const openNotification = (title, msg) => {
  notification.error({
    message: title,
    description: msg,
  });
};

const defaultInvoice = {
  tax_percent: 13,
  description: "",
  email_invoice: true,
  due_date: undefined,
  days_until_due: 30,
  invoice_items: [
    {
      item_name: "",
      quantity: 1,
      item_price: 0,
    },
  ],
};

/**
 * @class CreateInvoiceForm
 * @extends {React.Component}
 */
export class CreateInvoiceForm extends React.Component {
  /**
   *Creates an instance of CreateInvoiceForm.
   * @memberof CreateInvoiceForm
   */
  constructor() {
    super();

    const {
      tax_percent,
      description,
      email_invoice,
      due_date,
      days_until_due,
      invoice_items,
    } = defaultInvoice;

    this.state = {
      tax_percent,
      description,
      email_invoice,
      due_date,
      days_until_due,
      invoice_items,
      loading: false,
      show: false,
    };
  }

  /**
   * @description Handles submission of the form by calling the `invoiceCreate` prop if any items
   * are complete
   * @param {*} e The event that triggered this method
   * @memberof CreateInvoiceForm
   */
  onClick(e) {
    e && e.preventDefault();
    const {
      tax_percent,
      description,
      email_invoice,
      due_date,
      days_until_due,
      invoice_items,
    } = this.state;
    const { invoiceCreate, customer, history, location } = this.props;
    const userID = customer.details.user.id;
    let invoice = {
      tax_percent,
      description,
      email_invoice,
      due_date,
      days_until_due,
      invoice_items,
    };

    this.setState({ loading: true });

    invoice.invoice_items = invoice_items.filter(
      (item) => item.item_name && item.quantity > 0
    );
    invoice.invoice_items = invoice_items.map((item) => ({
      ...item,
      item_price: Math.round(item.item_price),
    }));

    if (!email_invoice) {
      invoice.due_date = undefined;
      invoice.days_until_due = undefined;
    }

    if (
      invoice_items.some(
        (item) =>
          (!item.item_name && item.quantity) ||
          (item.item_name && item.quantity === 0) ||
          (item.item_price > 0 && (!item.item_name || item.quantity === 0))
      )
    ) {
      this.setState({ loading: false, itemError: true });
      return;
    }

    invoiceCreate(userID, invoice, (error) => {
      if (error) {
        this.setState({ loading: false });
      } else {
        history.push(location.pathname.replace("/new", ""));
      }
    });
  }

  /**
   * @description Generates the table of invoice items, input fields, and totals
   * @returns {JSX.Element}
   * @memberof CreateInvoiceForm
   */
  renderInvoiceItems() {
    const {
      invoice_items,
      tax_percent,
      taxError,
      loading,
      itemError,
    } = this.state;
    const subtotal = invoice_items.reduce(
      (sum, item) => sum + (item.item_price * item.quantity) / 100,
      0
    );
    const taxValue = (tax_percent * subtotal) / 100;
    const total = subtotal + taxValue;

    return (
      <div className="invoiceItems">
        <p>
          <b>Invoice Items</b>
        </p>

        <table>
          <tbody>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit price</th>
              <th>Amount</th>
              <th />
            </tr>

            {invoice_items.map((item, index) => {
              const { item_name, quantity, item_price } = item;
              return (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control input-sm input-description"
                      value={item_name}
                      onChange={(e) => {
                        e.preventDefault();
                        this.updateInvoiceItem(
                          index,
                          e.target.value,
                          quantity,
                          item_price
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control input-sm input-qty"
                      value={quantity === 0 ? undefined : quantity}
                      onChange={(e) => {
                        e.preventDefault();
                        this.updateInvoiceItem(
                          index,
                          item_name,
                          e.target.value,
                          item_price
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control input-sm input-price"
                      placeholder="$0.00"
                      step="0.01"
                      value={item_price === 0 ? undefined : item_price / 100}
                      onChange={(e) => {
                        e.preventDefault();
                        this.updateInvoiceItem(
                          index,
                          item_name,
                          quantity,
                          e.target.value * 100
                        );
                      }}
                    />
                  </td>
                  <td>
                    <p>${((item_price * quantity) / 100).toFixed(2)}</p>
                  </td>
                  <td>
                    <button
                      className={invoice_items.length === 1 ? "hide" : ""}
                      disabled={loading ? "disabled" : false}
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeInvoiceItem(index);
                      }}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td>
                <p className={itemError ? "warning" : "hide"}>
                  <small>
                    One or more items above are missing data. Please review
                    before submitting.
                  </small>
                </p>
              </td>
            </tr>

            <tr className="summaryRow">
              <td>
                <button
                  className={loading ? "disabled" : ""}
                  onClick={this.addInvoiceItem.bind(this)}
                >
                  <i className="fa fa-plus" /> Add another item
                </button>
              </td>
              <td>
                <p>Subtotal</p>
              </td>
              <td />
              <td>
                <p>${subtotal.toFixed(2)}</p>
              </td>
              <td />
            </tr>
            <tr className="summaryRow">
              <td />
              <td>
                <p>Tax</p>
              </td>
              <td>
                <input
                  type="number"
                  className="form-control input-sm"
                  placeholder="0"
                  value={tax_percent}
                  onChange={this.onTaxUpdate.bind(this)}
                />
                <span>%</span>
                <p className={taxError ? "warning" : "hidden"}>
                  <small>From 0% and 100%</small>
                </p>
              </td>
              <td>
                <p>${taxValue.toFixed(2)}</p>
              </td>
              <td />
            </tr>
            <tr className="summaryRow">
              <td />
              <td>
                <p>
                  <b>Total</b>
                </p>
              </td>
              <td />
              <td>
                <p>
                  <b>${total.toFixed(2)}</b>
                </p>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  /**
   * @description Updates the values for the specified `invoice_item`
   * @param {number} index The current index of the item to update within the `invoice_items`
   * array
   * @param {string} item_name A string representing the item
   * @param {number|string} quantity A number indicating the quantity of that item to be invoiced
   * @param {number|string} item_price A value (CAD) indicating the cost/item
   * @memberof CreateInvoiceForm
   */
  updateInvoiceItem(index, item_name, quantity, item_price) {
    let invoice_items = JSON.parse(JSON.stringify(this.state.invoice_items));
    let isInteger =
      Number.isInteger(+quantity) && !quantity.toString().includes(".");
    quantity !== "" &&
      !isInteger &&
      openNotification("Error", "Qty must be an integer");
    invoice_items[index] = {
      item_name,
      quantity: Number(
        isInteger ? quantity : quantity.slice(0, quantity.length - 1)
      ),
      item_price: Number(item_price),
    };
    this.setState({ invoice_items, itemError: false });
  }

  /**
   * @description Pushes a new empty invoice item onto the end of the `invoice_items` array
   * @param {*} e The event that triggered this method
   * @memberof CreateInvoiceForm
   */
  addInvoiceItem(e) {
    e && e.preventDefault();
    const { loading } = this.state;

    if (!loading) {
      let invoice_items = JSON.parse(JSON.stringify(this.state.invoice_items));
      invoice_items.push({ item_name: "", quantity: 1, item_price: 0 });
      this.setState({ invoice_items, itemError: false });
    }
  }

  /**
   * @description Removes the item indicated by the provided index from the `invoice_items` array
   * @param {number} index The current index of the item to be removed
   * @memberof CreateInvoiceForm
   */
  removeInvoiceItem(index) {
    const { loading } = this.state;

    if (!loading) {
      let invoice_items = JSON.parse(JSON.stringify(this.state.invoice_items));
      invoice_items.splice(index, 1);
      this.setState({ invoice_items, itemError: false });
    }
  }

  /**
   * @description Updates the `tax_percent` value, and ensures that it is between 0 and 100
   * (inclusive)
   * @param {*} e The event that triggered this method
   * @memberof CreateInvoiceForm
   */
  onTaxUpdate(e) {
    e && e.preventDefault();
    const tax_percent = e.target.value;

    if (tax_percent < 0) {
      this.setState({ tax_percent: 0, taxError: true });
    } else if (tax_percent > 100) {
      this.setState({ tax_percent: 100, taxError: true });
    } else {
      this.setState({ tax_percent: e.target.value, taxError: false });
    }
  }

  /**
   * @description Generates the Memo section of the form, for the admin to leave a message in the
   * invoice
   * @returns {JSX.Element}
   * @memberof CreateInvoiceForm
   */
  renderMemo() {
    const { description } = this.state;
    const charactersLeft = 500 - description.length;
    const charactersLeftText =
      description.length <= 500
        ? `${charactersLeft} characters remaining`
        : `${-1 * charactersLeft} too many characters`;

    return (
      <div className="memo">
        <p>
          <b>Memo</b>
        </p>

        <textarea
          className="form-control"
          rows="2"
          onChange={this.onMemoUpdate.bind(this)}
          value={description}
          placeholder="Thanks for your business!"
          maxLength="500"
        />

        <div>
          <p>
            <small>This will appear on the invoice and receipt.</small>
          </p>
          <p className={charactersLeft < 0 ? "warning" : ""}>
            <small>{charactersLeftText}</small>
          </p>
        </div>
      </div>
    );
  }

  /**
   * @description Updates the `description` state, and sets its max length at 500 characters
   * (the first 500 characters of the string only)
   * @param {*} e The event that triggered this method
   * @memberof CreateInvoiceForm
   */
  onMemoUpdate(e) {
    e && e.preventDefault();
    let description = e.target.value;

    if (description.length > 500) {
      description = description.substring(0, 500);
    }

    this.setState({ description });
  }

  /**
   * @description Generates the Billing section, which lets the admin specify when they would
   * like the invoice to be charged
   * @returns {JSX.Element}
   * @memberof CreateInvoiceForm
   */
  renderBilling() {
    const { email_invoice, dueDateError, days_until_due } = this.state;

    return (
      <div className="billing">
        <p>
          <b>Billing</b>
        </p>

        <div>
          <label>
            <input
              className="radio-charge"
              type="radio"
              checked={!email_invoice}
              onChange={() => {
                this.onBillingClick(false);
              }}
            />
            Automatically charge the default payment source on file
          </label>
        </div>

        <div>
          <label>
            <input
              className="radio-email"
              type="radio"
              checked={email_invoice}
              onChange={() => {
                this.onBillingClick(true);
              }}
            />
            Email invoice to the customer to pay manually
          </label>
        </div>

        {email_invoice ? (
          <div className="dueDate">
            <p>
              <b>Payment due</b>
            </p>

            <div>
              <input
                type="number"
                className="form-control input-sm"
                placeholder="30"
                value={days_until_due}
                onChange={this.onDueDateUpdate.bind(this)}
              />
              <span>
                <small>days</small>
              </span>
              <p>after invoice is sent</p>
            </div>
            <p className={dueDateError ? "warning" : "hide"}>
              <small>Must choose between 0 and 365 days</small>
            </p>
          </div>
        ) : (
          <span />
        )}
      </div>
    );
  }

  /**
   * @description Updates the `email_invoice` to the clicked value
   * @param {boolean} email_invoice
   * @memberof CreateInvoiceForm
   */
  onBillingClick(email_invoice) {
    this.setState({ email_invoice });
  }

  /**
   * @description Updates the due date based on the input value, with a hard limit at 365 days
   * @param {*} e The event that triggered this method
   * @memberof CreateInvoiceForm
   */
  onDueDateUpdate(e) {
    const days_until_due = e.target.value;

    if (days_until_due > 365) {
      this.setState({ days_until_due: 365, dueDateError: true });
    } else if (days_until_due < 0) {
      this.setState({ days_until_due: 0, dueDateError: true });
    } else {
      this.setState({ days_until_due, dueDateError: false });
    }
  }

  /**
   * @description Determines whether or not any fields have been altered
   * @param {Object} invoice The current invoice object, provided by the `state`
   * @returns {boolean} Returns true if any values have been altered, false if not
   * @memberof CreateInvoiceForm
   */
  shouldPromptWhenLeaving(invoice) {
    return (
      !invoice.loading &&
      Object.keys(defaultInvoice).some(
        (key) => invoice[key] !== defaultInvoice[key]
      )
    );
  }

  /**
   * @memberof CreateInvoiceForm
   */
  render() {
    const { loading } = this.state;
    const isInProgress = this.shouldPromptWhenLeaving(this.state);

    return (
      <div className="createInvoiceForm">
        <Prompt
          when={isInProgress}
          message="Are you sure you want to leave? Your changes will not be saved."
        />

        <form onSubmit={this.onClick.bind(this)} id="submitForm">
          <fieldset disabled={loading ? "disabled" : false}>
            {this.renderInvoiceItems()}
            <hr />
            {this.renderMemo()}
            <hr />
            {this.renderBilling()}
            <hr />
            <div className="submitRow">
              {loading ? (
                <div>
                  <Spin />
                </div>
              ) : (
                <span />
              )}
              <button className="btn">SUBMIT</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateInvoiceForm);

CreateInvoiceForm.propTypes = {
  customer: PropTypes.object.isRequired,
  invoiceCreate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
