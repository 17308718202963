export default class Address {
  static getAddressLines = (address) => {
    if (!address) return [];
    return [
      (address.apartment_number ? `${address.apartment_number} - ` : "") +
        address.address,
      address.city + " " + address.province + " " + address.postal_code,
      address.country,
    ];
  };

  static getOneLineAddress = (address) => {
    if (!address) return "";
    return Address.getAddressLines(address)[0];
  };
}
