import React from "react";
import PropTypes from "prop-types";

const PlusMinusInput = (props) => {
  return (
    <div className="plus-minus-input">
      <div className="minus" onClick={props.onMinusClick}>
        -
      </div>
      <input
        className="quantity"
        value={props.value}
        onChange={(e) => props.onQuantityChange(+e.target.value)}
      />
      <div className="plus" onClick={props.onPlusClick}>
        +
      </div>
    </div>
  );
};

PlusMinusInput.propTypes = {
  onMinusClick: PropTypes.func.isRequired,
  onPlusClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default PlusMinusInput;
