import {
  CUSTOMERS_FETCHED,
  ORDERS_FETCHED,
  APPOINTMENT_FETCHED,
} from "../actions";

const INITIAL_STATE = {
  customerFetched: false,
  orderFetched: false,
  appointmentFetched: false,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case CUSTOMERS_FETCHED:
      return { ...state, customerFetched: payload };
    case ORDERS_FETCHED:
      return { ...state, orderFetched: payload };
    case APPOINTMENT_FETCHED:
      return { ...state, appointmentFetched: payload };
    default:
      return state;
  }
}
